<app-modal-basic #modalRelatorio [modalContentId]="'modal-ra'" (closed)="onClose()">
  <div class="app-modal-header">
    <h4 class="modal-title">Relatório de atendimentos</h4>
  </div>
<div class="app-modal-body">
    <form [formGroup]="impressaoForm" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Período</label>
          <div class="input-group">
            <app-data [calendarPosition]="'left'" formControlName="periodoDe"></app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="periodoAte"></app-data>
          </div>
          <div class='row'>
            <div class='col-sm-6'>
              <show-errors [control]="impressaoForm.controls.periodoDe"></show-errors>
            </div>
            <div class='col-sm-6'>
              <show-errors [control]="impressaoForm.controls.periodoAte"></show-errors>
            </div>
          </div>
        </div>
        <div class="form-group col-sm-12">
          <label>Tipo de atendimento</label>
          <ng-select appendTo="#modal-ra" required [searchable]="false" formControlName="opcao"
            placeholder="Selecione" [items]="relatorioOptions" bindLabel="name" bindValue="id">
          </ng-select>
          <div class='row'>
            <div class='col-sm-12'>
              <show-errors [control]="impressaoForm.controls.opcao"></show-errors>
            </div>
          </div>
        </div>
        <div class="form-group col-sm-12">
          <label>Atendente</label>
          <ng-select appendTo="#modal-ra" [searchable]="false" formControlName="usuarioId" placeholder="Selecione"
            [items]="usuariosOptions" bindLabel="nome" bindValue="id">
          </ng-select>
        </div>
        <div class="form-group col-sm-12">
          <label>Rota</label>
          <ng-select appendTo="#modal-ra" #select [searchable]="false" formControlName="rotaID"
            placeholder="Selecione" [items]="rotasMap" bindLabel="rota" bindValue="id" [loading]="loadingRotas"
            [typeahead]="rotaInput" [disableControl]="isEnabled(1)">
          </ng-select>
        </div>
        <div class="form-group col-sm-12">
          <label>Atendimentos em vermelho &nbsp;</label>
          <input type="checkbox" class="form-grouP" formControlName="atencao" [disableControl]="isEnabled(2)" />
        </div>
      </div>
      <button type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
        <i class="icofont icofont-print"> </i> Imprimir
      </button>
      <button type="button" class="btn btn-danger ripple" (click)="modalRelatorio.hide()"><i class="ti ti-close"></i>
        Cancelar
      </button>
    </form>
    </div>
    <div class="app-modal-footer">
    </div>
</app-modal-basic>