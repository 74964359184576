import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs/internal/observable/throwError';
import { Injectable } from '@angular/core';

import { API_URL } from '../../general/constants';
import { IBaseServiceV2 } from './base-service-v2.interface';
import { ConsultaPaginadaViewModel } from '@models';
import { GetPaginatedViewModel } from 'models/genericas/GetPaginatedViewModel';

@Injectable()
export class BaseServiceV2 implements IBaseServiceV2 {
  // Protótipo de uma nova base service. Pode ser mais útil e prática do que a atual.

  baseUrl = API_URL;
  controllerUrl = '';

  constructor(
    controllerUrl: string,
    private http: HttpClient,
  ) {
    this.controllerUrl = controllerUrl;
  }

  private get urlRoute() {
    return this.baseUrl + this.controllerUrl;
  }

  get<ResponseType>(route = '') {
    return this.http.get(`${this.urlRoute}/${route}`)
      .pipe(map((res: DimHttpServerResponse) => res.data as ResponseType), catchError(this.handleError));
  }

  getAsPromise<ResponseType>(route = ''): Promise<ResponseType> {
    return new Promise((next, error) => this.get<ResponseType>(route).subscribe({ next, error }));
  }

  put<PayloadType, ResponseType>(payload: PayloadType, route = '') {
    return this.http.put(`${this.urlRoute}/${route}`, payload)
      .pipe(map((res: DimHttpServerResponse) => res.data as ResponseType), catchError(this.handleError));
  }

  putAsPromise<PayloadType, ResponseType>(payload: PayloadType, route = ''): Promise<ResponseType> {
    return new Promise((next, error) => this.put<PayloadType, ResponseType>(payload, route).subscribe({ next, error }));
  }

  post<PayloadType, ResponseType>(payload: PayloadType, route = '') {
    return this.http.post(`${this.urlRoute}/${route}`, payload)
      .pipe(map((res: DimHttpServerResponse) => res.data as ResponseType), catchError(this.handleError));
  }

  postAsPromise<PayloadType, ResponseType>(payload: PayloadType, route = ''): Promise<ResponseType> {
    return new Promise((next, error) => this.post<PayloadType, ResponseType>(payload, route).subscribe({ next, error }));
  }

  downloadBlob<PayloadType>(payload: PayloadType, route = '') {
    return this.http.post(`${this.urlRoute}/${route}`, payload, { responseType: 'blob' })
      .pipe(map((res: Blob) => res), catchError(this.handleError));
  }

  downloadBlobAsPromise<PayloadType>(payload: PayloadType, route = ''): Promise<Blob> {
    return new Promise((next, error) => this.downloadBlob<PayloadType>(payload, route).subscribe({ next, error }));
  }

  save<PayloadType extends BaseViewModel, ResponseType>(payload: PayloadType, route = '') {
    if (payload.id) {
      return this.put<PayloadType, ResponseType>(payload, route);
    } else {
      return this.post<PayloadType, ResponseType>(payload, route);
    }
  }

  saveAsPromise<PayloadType, ResponseType>(payload: PayloadType, route = ''): Promise<ResponseType> {
    return new Promise((next, error) => this.save<PayloadType, ResponseType>(payload, route).subscribe({ next, error }));
  }

  delete<PayloadType, ResponseType>(payload: PayloadType, route: string) {
    return this.http.delete(this.url([this.urlRoute, route, payload]))
      .pipe(map((res: DimHttpServerResponse) => res.data as ResponseType), catchError(this.handleError));
  }

  deleteAsPromise<PayloadType, ResponseType>(payload: PayloadType, route = ''): Promise<ResponseType> {
    return new Promise((next, error) => this.delete<PayloadType, ResponseType>(payload, route).subscribe({ next, error }));
  }

  getPaginated<ResponseType>(queryParams: GetPaginatedViewModel, route = 'getPaginated') {
    return this.http.get(`${this.urlRoute}/${route}?${this.toQueryString(queryParams)}`)
      .pipe(map((res: DimHttpServerResponse) => res.data as ConsultaPaginadaViewModel<ResponseType>), catchError(this.handleError));
  }

  getPaginatedAsPromise<ResponseType>(queryParams: GetPaginatedViewModel, route = 'getPaginated'): Promise<ConsultaPaginadaViewModel<ResponseType>> {
    return new Promise((next, error) => this.getPaginated<ResponseType>(queryParams, route).subscribe({ next, error }));
  }

  postPaginated<PayloadType, ResponseType>(payload: PayloadType, route = 'getPaginated') {
    return this.http.post(`${this.urlRoute}/${route}`, payload)
      .pipe(map((res: DimHttpServerResponse) => res.data as ResponseType), catchError(this.handleError));
  }

  postPaginatedAsPromise<PayloadType, ResponseType>(payload: PayloadType, route = 'getPaginated'): Promise<ResponseType> {
    return new Promise((next, error) => this.postPaginated<PayloadType, ResponseType>(payload, route).subscribe({ next, error }));
  }

  formatValueArrayToQueryParam(paramName: string, valueArray: string[] | number[]): string {
    const queryParams = valueArray
      .map((item: number | string) => `${paramName}=${encodeURIComponent(item)}`).join('&');

    return queryParams;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  url(arr: any[]) {
    return arr.filter(a => a).join('/');
  }

  toQueryString(viewModel: GetPaginatedViewModel) {
    const queryParams = Object.entries(viewModel)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, value]) => value !== undefined && value !== null)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
      .join('&');

    return queryParams;
  }

  private handleError(response: HttpErrorResponse) {
    let errors: string[];

    switch (response.status) {
      case 0: // server fora
        errors = ['Falha ao conectar no servidor.'];
        break;
      case 403: // forbbiden
        errors = ['Acesso negado.'];
        break;
      case 400:
        errors = (response.error as DimHttpServerResponseError).errors;
        break;
      default: // retornou DomainNotification
        errors = ['Erro desconhecido.'];
        break;
    }

    return throwError(() => new DimHttpServerResponseError(false, errors));
  }

}

export interface DimHttpServerResponse {
  success: boolean;
  data: unknown;
  token?: string;
  messages?: string[];
  errors?: string[];
}

export class DimHttpServerResponseError {
  success: boolean;
  errors: string[];

  constructor(success: boolean, errors: string[]) {
    this.success = success;
    this.errors = errors;
  }
}

export interface BaseViewModel {
  id?: number;
}
