<div>
  <div (click)="show = !show" class="cursor-pointer">
    <i *ngIf="!show" class="fa fa-chevron-down mr-1"></i>
    <i *ngIf="show" class="fa fa-chevron-up mr-1"></i>
    <ng-content select="[title]"></ng-content>
  </div>

  <div [@slideInOut] *ngIf="show" class="mt-2 ml-3">
    <ng-content></ng-content>
  </div>
</div>