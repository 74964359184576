<app-modal-basic #modalRelatorio (closed)="destroyComponent()" [modalContentId]="'modal-comissoes-saldos'">
  <div class="app-modal-header">
    <h4 class="modal-title text-center">Relatório de Comissões e Saldos por Vendedor</h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="row">
        <div
          class="form-group col-sm-12"
          rem5
          combobox
          [type]="'vendedor'"
          [(form)]="form"
          formControlName="vendedor"
          appendTo="#modal-comissoes-saldos"
        ></div>
      </div>

      <div class="row form-group col-sm-12">
        <div class="form-radio form-control col-sm-6" style="border: unset; padding-left: 0px">
          <div class="m-b-10">
            <span>Filtro</span>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="ordenado" value="Emissao" />
              <i class="helper"></i><span> Data de Emissão</span>
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="ordenado" value="Vencimento" />
              <i class="helper"></i><span> Data de Vencimento</span>
            </label>
          </div>
          <div class="radio radio-inline">
            <label style="margin-bottom: 0px">
              <input type="radio" formControlName="ordenado" value="Pagamento" />
              <i class="helper"></i><span> Data de Pagamento</span>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-12">
          <label>Período</label>
          <div class="input-group">
            <app-data [calendarPosition]="'left'" formControlName="periodoIni"></app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="periodoFim"></app-data>
          </div>
        </div>
      </div>

      <div class="alert alert-danger" *ngIf="err">
        <span>{{ err }}</span>
      </div>

      <button type="submit" class="btn btn-primary ripple light">
        <i class="icofont icofont-print"> </i> Imprimir
      </button>
      <button type="button" class="btn btn-danger ripple" (click)="modalRelatorio.hide()">
        <i class="ti ti-close"></i> Cancelar
      </button>
    </form>
  </div>
  <div class="app-modal-footer"></div>
</app-modal-basic>
