import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class MotivosMovimentacaoEstoqueService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getWithFilters(consulta: any) {
        return this.http
            .post(this.UrlService + 'MotivosMovimentacaoEstoque/buscar', consulta)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError))
    }

    getById(id: number) {
        return this.http
            .get(this.UrlService + `MotivosMovimentacaoEstoque/${id}`)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    delete(id: number) {
        return this.http
            .delete(this.UrlService + "MotivosMovimentacaoEstoque/" + id)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    salvar(form: any) {
        if (form.id == null || form.id == 0) {
            return this.postForm(form);
        } else {
            return this.putForm(form);
        }
    }

    private postForm(form: any) {
        return this.http
            .post(this.UrlService + 'MotivosMovimentacaoEstoque/salvar', form)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError))
    }

    private putForm(form: any) {
        return this.http
            .put(this.UrlService + 'MotivosMovimentacaoEstoque/alterar', form)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError))
    }

    getPaginado(pagina: number, nome: string) {
        return this.http
            .get(this.UrlService + `MotivosMovimentacaoEstoque/paginado?pageIndex=` + pagina + "&search=" + encodeURIComponent(nome))
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    obterTodos() {
        return this.http
            .get(this.UrlService + 'MotivosMovimentacaoEstoque/obterTodos')
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }
}
