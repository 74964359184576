import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { fieldRequired, markFormGroupTouched } from '../../../general/utils';
import { EdicaoMascaras } from '../../../shared/EdicaoMascaras';
import { ContasContabeisService } from '../../../services/contas-contabeis.service';
import { HistoricosContabeisPadraoService } from '../../../services/historicos-contabeis-padrao.service';
import { EventosContabeisEntidadeService } from '../../../services/eventos-contabeis-entidade.service';
import { LancamentosContabeisService } from '../../../services/lancamentos-contabeis.service';
import * as moment from 'moment';

@Component({
  selector: 'lote-lancamentos-contabeis',
  templateUrl: './lote-lancamentos-contabeis.component.html',
  styleUrls: ['./lote-lancamentos-contabeis.component.css'],
  providers: [EventosContabeisEntidadeService, ContasContabeisService, HistoricosContabeisPadraoService, LancamentosContabeisService]
})

export class LoteLancamentosContabeisComponent implements OnInit {
  @ViewChild("modalLarge", { static: false }) modalBase;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  formLote: FormGroup;
  lote: string = '';
  mensagemLote: string=''

  constructor(
    private lancamentosContabeisService: LancamentosContabeisService,
    private formBuilder: FormBuilder) { }
  edicaoMascaras = new EdicaoMascaras();

  ngOnInit() {
    this.novoLote();
  }

  onClose() {
    this.closed.emit(this.lote);
  }

  public show(): void {
    this.modalBase.show();
    this.novoLote();
  }

  validaForm() {
    markFormGroupTouched(this.formLote);
  }

  gerarLote() {
    this.lote = 'DIM' + moment(this.formLote.value.dataInicio, 'YYYY-MM-DDTHH:mm').format('YYMMDD') + moment(this.formLote.value.dataFim, 'YYYY-MM-DDTHH:mm').format('YYMMDD')

    this.lancamentosContabeisService.validarLote(this.lote)
      .subscribe((res) => {
        if (res==true){
          this.mensagemLote="O Lote '" + this.lote + "' já existe, deseja gerá-lo novamente?"
        } else {
          this.mensagemLote="Confirma geração do lote '" + this.lote + "'?"
        }

        Swal({
          title: this.mensagemLote,
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          showLoaderOnConfirm: true,
          cancelButtonText: 'Não',
          confirmButtonClass: 'btn btn-success btn-swal-modal',
          cancelButtonClass: 'btn btn-danger mr-sm',
          focusCancel: true,
          preConfirm: () => {
            return new Promise((resolve, reject) => {

              if (this.formLote.valid) {
                this.lancamentosContabeisService.gerarLote(this.lote,this.formLote.value)
                  .subscribe(res => {
                    Swal("Sucesso", "Lote gerado com sucesso!", "success")
                      .then(e => {
                        // Salvou
                      })
                    this.operacaoRealizada();
                  },
                    response => {
                      Swal("Erro", "Um erro ocorreu ao tentar gerar o lote", "error");
                    }
                  );
              }
            })
              .catch((error) => {
                Swal.showValidationError(`${error.msg.errors[0]}`)
              })
          },
          allowOutsideClick: () => !Swal.isLoading()
        })

      });
  }

  operacaoRealizada() {
    this.modalBase.hide();
  }

  novoLote() {

    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    this.formLote = this.formBuilder.group({
      dataInicio: [firstDay, [Validators.required]],
      dataFim: [lastDay, [Validators.required]]
    })
  }

  fieldRequired(prop): boolean {
    return fieldRequired(this.formLote, prop);
  }
}
