<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader">
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
    <div class="row">
      <div class="form-group col-sm-6">
        <label class="label-required">Alíquota ICMS</label>
        <input appAutofocus [disableControl]="loading['registro']" type="number" class="form-control"
          formControlName="aliquotaICMS" />
        <show-errors [control]="cadastroForm.controls.aliquotaICMS"></show-errors>
      </div>

      <div class="form-group col-sm-6">
        <label>Redução BC</label>
        <input [disableControl]="loading['registro']" type="number" class="form-control"
          formControlName="percentualRedutorICMS" />
        <show-errors [control]="cadastroForm.controls.percentualRedutorICMS"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <label>Mensagem para NF</label>
        <input [disableControl]="loading['registro']" type="text" class="form-control" formControlName="observacoes" />
        <show-errors [control]="cadastroForm.controls.observacoes"></show-errors>
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="errors">
      <span>{{ errors }}</span>
    </div>

    <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'" [loading]="loading['registro']"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalCadastro.hide()">Cancelar</button>
  </form>
</app-temp-modal-cadastro>
