import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class UnidadesMedidaService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get() {
    return this.http.get(this.UrlService + 'UnidadesMedida')
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getById(id: number) {
    return this.http
      .get(this.UrlService + "UnidadesMedida/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getPaginado(pagina: number, nome: string) {
      return this.http
          .get(this.UrlService + `UnidadesMedida/paginado?pageIndex=` + pagina + "&search=" + encodeURIComponent(nome))
          .pipe(map((res: any) => res.data)
              , catchError(super.serviceError));
  }

}