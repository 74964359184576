import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class HistoricosContabeisPadraoService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get(form) {
    return this.http
      .post(this.UrlService + 'HistoricosContabeisPadrao/buscar', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getHistoricosContabeisPadrao(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + "HistoricosContabeisPadrao/Paginado?pageIndex=" + pagina + "&search=" + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getHistoricosContabeisComboBox(pagina: number, nome: string, filters: any[]) {
    return this.http
      .post(this.UrlService + `HistoricosContabeisPadrao/getHistoricosContabeisComboBox?pageIndex=${pagina}&search=${encodeURIComponent(nome)}`, filters)
      .pipe(map((res: any) => res.data)
          , catchError(super.serviceError));
  }

  getAll() {
    return this.http
    .get(this.UrlService + 'HistoricosContabeisPadrao/buscar')
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getContaById(id: number) {
    return this.http
      .get(this.UrlService + "HistoricosContabeisPadrao/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  post(form) {
    return this.http.post(this.UrlService + 'HistoricosContabeisPadrao/salvar', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  put(form) {
    return this.http.put(this.UrlService + 'HistoricosContabeisPadrao/atualizar', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  delete(id: number) {
    return this.http
      .delete(this.UrlService + "HistoricosContabeisPadrao/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}