import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { PedidosService } from '../../../../services/pedidos.service';
import { finalize } from 'rxjs/operators';
import swal from 'sweetalert2';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-relacao-vendas-produto',
  templateUrl: './relacao-vendas-produto.component.html',
  styleUrls: ['./relacao-vendas-produto.component.css']
})
export class RelacaoVendasProdutoComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalRelatorio", { static: true }) modalRelatorio;
  impressaoForm: FormGroup;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  loading: boolean = false;

  constructor(private pedidosService: PedidosService) { super() }

  statusPedido =
    [
      { id: 'E', name: 'Em Andamento', },
      { id: 'F', name: 'Faturado' },
      { id: 'P', name: 'Faturado Parcialmente' },
      { id: 'X', name: 'Recebido' },
      { id: 'Y', name: 'Recebido Parcialmente' },
      { id: 'Z', name: 'Orçamento efetivado' }
    ];

  ordenacao =
    [
      { id: 1, name: 'Código', },
      { id: 2, name: 'Descrição' },
      { id: 3, name: 'Valor' },
      { id: 4, name: 'Vendedor' }
    ];


  ngOnInit() {
    this.impressaoForm = new FormGroup({
      periodo1: new FormControl(null),
      periodo2: new FormControl(null),
      vendedor: new FormControl(null),
      status: new FormControl([]),
      ordenacao: new FormControl(1),
      planilha: new FormControl(false)
    })
  }

  destroyComponent() {
    this.closed.emit();
  }

  show(): void {
    this.modalRelatorio.show();
  }

  submitForm() {
    if (this.impressaoForm.valid) {
      this.loading = true;
      this.modalRelatorio.hide();
      this.pedidosService.imprimirRelacaoVendasProduto(this.impressaoForm.value)
        .pipe(finalize(() => this.loading = false))
        .subscribe(res => {
          const formato = this.impressaoForm.get('planilha').value ? 'xlsx' : 'pdf';
          saveAs(res, `relatorio_vendas_por_produto_pedido.${formato}`);
          this.destroyComponent();
        }, (error) => {
          swal("Erro ao emitir o relatório: Relação de vendas por produto", error.errors[0], "error");
          this.destroyComponent();
        })
    }
  }

}
