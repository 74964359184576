import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { ExibirVideosUsuarioService } from '../../../services/exibirVideosUsuario.service';
import { getDeviceType, getOriantation } from '../../../general/utils';
import { setFormValue } from '../../../general/utils';
import reframe from 'reframe.js';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-exibir-video',
  templateUrl: './exibir-video.component.html',
  styleUrls: ['./exibir-video.component.css']
})
export class ExibirVideoComponent extends ModalCadastroComponent implements OnInit {

  constructor(private exibirVideosUsuarioService: ExibirVideosUsuarioService) {
    super();
  }

  // iframe control
  YT: any;
  video: any;
  player: any;
  reframed: Boolean = false;
  //

  ocultarExibirNovamente = false;
  naoExibirNovamente = false;
  form: FormGroup;
  defaultImg = '../../../../assets/images/lucasdelara.jpg';
  listaVideos = {
    'EmissaoNFCe': {
      video: 'https://www.youtube.com/embed/vKhQr4-_m2Y',
      videoID: 'vKhQr4-_m2Y',
      image: this.defaultImg
    },
    'Pedido': {
      video: 'https://www.youtube.com/embed/rmmLqMdibAs',
      videoID: 'rmmLqMdibAs',
      image: this.defaultImg
    },
    'MovimentaEstoque': {
      video: 'https://www.youtube.com/embed/7vtm8_naxMM',
      videoID: '7vtm8_naxMM',
      image: this.defaultImg
    },
    'GerenciamentoUsuarios': {
      video: 'https://www.youtube.com/embed/U6HmVhgdZtM',
      videoID: 'U6HmVhgdZtM',
      image: this.defaultImg
    },
    'CadastroProduto': {
      video: 'https://www.youtube.com/embed/GGCDSdxqak0',
      videoID: 'GGCDSdxqak0',
      image: this.defaultImg
    },
    'CadastroClientes': {
      video: 'https://www.youtube.com/embed/641-Gyd2ivs',
      videoID: '641-Gyd2ivs',
      image: this.defaultImg
    },
    'LancamentoDespesas': {
      video: 'https://www.youtube.com/embed/pA38VSo-068',
      videoID: 'pA38VSo-068',
      image: this.defaultImg
    },
    'MovimentacaoFinanceira': {
      video: 'https://www.youtube.com/embed/8G36-cgjty4',
      videoID: '8G36-cgjty4',
      image: this.defaultImg
    },
    'CadastroCOntasAPagarContasAReceber': {
      video: 'https://www.youtube.com/embed/8aqzFxfKNw4',
      videoID: '8aqzFxfKNw4',
      image: this.defaultImg
    },
    'EmissaoNFe': {
      video: 'https://www.youtube.com/embed/PvtwC5z-U-M',
      videoID: 'PvtwC5z-U-M',
      image: this.defaultImg
    },
    'CadastrarCertificadoDigital': {
      video: 'https://www.youtube.com/embed/mOF94Hki1T0',
      videoID: 'mOF94Hki1T0',
      image: this.defaultImg
    },
    'CadastroTabelaPreco': {
      video: 'https://www.youtube.com/embed/Ov3dCPEIStY',
      videoID: 'Ov3dCPEIStY',
      image: this.defaultImg
    }
  };

  @ViewChild('modal', { static: true }) modal;

  ngOnInit() {
    this.init();
    if(this.prop.ocultarExibirNovamente){
      this.ocultarExibirNovamente = true;
    }
    this.form = new FormGroup({
      id: new FormControl(0),
      nomeVideo: new FormControl(this.prop.video),
      exibir: new FormControl(true)
    });
  }

  init() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  show() {

    this.exibirVideosUsuarioService.getByVideoUser(this.prop.video)
      .subscribe(res => {
        if (res !== null) {
          setFormValue(this.form, res);
        }
        this.naoExibirNovamente = !this.form.get('exibir').value;
      });

    this.modal.show();
    this.video = this.listaVideos[this.prop.video].videoID; // video id

    if (window['onYouTubeIframeAPIReady']) {
      this.setVideo();
    } else {
      window['onYouTubeIframeAPIReady'] = (e) => {
        this.setVideo();
      };
    }
  }

  setVideo() {
    this.YT = window['YT'];
    this.reframed = false;
    this.player = new window['YT'].Player('player', {
      videoId: this.video,
      events: {
        'onReady': (e) => {
          if (!this.reframed) {
            this.reframed = true;
            // reframe(e.target.a); comentado pois estava retornando erro, deixado assim para verificar se não ocorrera mais o problema
          }
          this.player.seekTo(this.prop.time, true);
        }
      }
    });
  }

  hide() {
    this.salvaNaoAbrirNovamente();
    this.modal.hide();
  }

  clicouFora() {
    this.salvaNaoAbrirNovamente();
  }

  salvaNaoAbrirNovamente() {
    if (this.form.get('exibir').value !== !this.naoExibirNovamente) {
      this.form.get('exibir').setValue(!this.naoExibirNovamente);
      this.exibirVideosUsuarioService.salvar(this.form.value)
        .subscribe(res => {
          // salvou
        });
    }

    let value = 0;
    if (this.player.getCurrentTime() < this.player.getDuration()) {
      value = this.player.getCurrentTime();
    }
    this.closed.emit(value);
  }

  getOriantation() {
    return getDeviceType() + getOriantation();
  }

  getDevice() {
    return getDeviceType();
  }
}
