import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { CondicoesPagamentoService } from '../../../services/condicoes-pagamento.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TempModalCadastroComponent } from '../../../shared/templates/temp-modal-cadastro/temp-modal-cadastro.component';
import { setFieldFormValue, setFormValue } from '../../../general/utils';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-condicao-pagamento',
  templateUrl: './condicao-pagamento.component.html',
  styleUrls: ['./condicao-pagamento.component.css']
})
export class CondicaoPagamentoComponent extends ModalCadastroComponent implements OnInit{

  constructor(private condicoesPagamentoService: CondicoesPagamentoService,
  ) {
    super();
  }

  @ViewChild('modalCadastro', { static: true }) modalCadastro: TempModalCadastroComponent;
  exibirCheckbox = false;

  cadastroForm = new FormGroup({
    id: new FormControl(null),
    condicao: new FormControl(null, [Validators.required]),
    prazos: new FormControl(null, [Validators.required]),
    inativo: new FormControl(false),
  });

  ngOnInit(): void {
    if(this.prop){
      this.editar(this.prop.id);
      this.exibirCheckbox = this.prop.exibirCheckbox;
    }
  }
  
  show(){
    this.modalCadastro.show();
  }

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    setFieldFormValue(this.cadastroForm, 'condicao', searchParam);
    this.modalCadastro.show();
  }

  carregaDados() {
    if (this.id) {
      this.alteraLoading(true);
      this.condicoesPagamentoService.getCadastroCondicaoPagamentoById(this.id)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe((res) => {
          setFormValue(this.cadastroForm, res);
        });
    }
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      this.alteraLoading(true);

      this.condicoesPagamentoService.salvar(this.cadastroForm.getRawValue(), this.id)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe(res => {
          this.id = res.generatedId || null;
          this.modalCadastro.hide();
        }, (error) => {
          this.errors = error.msg.errors;
        });
    }
  }
}
