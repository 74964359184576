<div class="form-group">
  <div class="input-group">
    <input class="form-control customDatalist" id="{{id}}" placeholder="{{place}}" name="input"
      [value]="currentValue" (input)="search($event.target.value)"
      [disabled]="isDisabled" autocomplete="off" />
    <div class="input-group-append" *ngIf="hasItem && !isDisabled">
      <span class="input-group-text">
        <button class="btn btn-danger" type="button" (click)="remove()">
          <i class='ti ti-close'></i>
        </button>
      </span>
    </div>
  </div>
  <ul class="list-group" *ngIf="items">
      <li class="list-group-item" *ngFor="let item of items" (click)="select(item)"> {{ item.name }} </li>
    </ul>
</div>