<app-modal-basic #modalRelatorio (closed)="onClose()" [modalContentId]="'modal-rv'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      Exportação CT-e
    </h4>
    <button type="button" class="close basic-close" (click)="hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="app-modal-body">
    <form [formGroup]="form">
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Emissão</label>
          <div class="input-group">
            <app-data [calendarPosition]="'left'" formControlName="emissao1">
            </app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="emissao2"></app-data>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Cliente</label>
          <ng-select #select appendTo="#modal-rv" [items]="clientes" formControlName="cliente" placeholder="Selecione"
            [virtualScroll]="true" bindLabel="razaoSocial" bindValue="id"
            (scrollToEnd)="fetchMoreClientes(select.searchTerm)" [loading]="loading.clientes"
            [typeahead]="clienteInput">
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Status</label>
          <app-combobox-status-documento-modal formControlName="status"
            appendTo="#modal-rv"></app-combobox-status-documento-modal>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>
            Destinatários
            <span ngbTooltip="Para inserir um novo destinatário, digite-o no campo abaixo e tecle enter."
              placement="right">
              <i class="fa fa-question-circle help-icon"></i>
            </span>
          </label>
          <app-email-box formControlName="destinatarios" #emailBox></app-email-box>
          <show-errors [control]="form.controls['destinatarios']"></show-errors>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-12">
          <app-dim-checkbox label="Gerar DACTE" formControlName="gerarDacte"></app-dim-checkbox>
        </div>
      </div>
    </form>

    <button type="submit" class="btn btn-info ripple light custom-form-button" (click)="exportar()"
      [disabled]="loading.exportacao">
      <app-loading [loading]="loading.exportacao" [icone]="'ti ti-new-window'" [texto]="'Exportar'"
        [textoLoading]="'Exportando'"></app-loading>
    </button>
  </div>
</app-modal-basic>
