import { Component, Input, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as $ from 'jquery';
import { MessageService } from '../../services/message.service';
import { getFormValidationErrors } from '../../general/utils';

@Component({
  selector: 'app-dim-tabswipe',
  templateUrl: './dim-tabswipe.component.html',
  styleUrls: ['./dim-tabswipe.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class DimTabswipeComponent implements OnInit, OnDestroy {

  @Input() selected: FormControl = new FormControl(0);

  @Input() inputs: any[];
  @Input() class: String = '';
  @Input() form: FormGroup;
  private tabsFields = [];

  public loadingValidacoes: boolean;

  private elementAfter: Element;
  private elementBefore: Element;
  private elementSubmit: Element;

  constructor(private el: ElementRef, private message: MessageService) { }

  ngOnInit(): void {
    this.loadingValidacoes = true;

    setTimeout(() => {
      this.addEventArrowClick();
      this.addEventSubmit();

      const tabs = (this.el.nativeElement as HTMLElement).querySelectorAll(".dim-tabswipe-content");

      if (tabs && tabs.length > 0) {
        for (var tabIndex = 0; tabIndex < tabs.length; tabIndex++) {
          const tabInputs = tabs[tabIndex].querySelectorAll("[formcontrolname]");


          for (var tabInputIndex = 0; tabInputIndex < tabInputs.length; tabInputIndex++) {
            this.tabsFields.push({ tabIndex, item: tabInputs[tabInputIndex].getAttribute('formcontrolname') });
          }
        }
      }

      this.loadingValidacoes = false;
    });
  }

  addEventArrowClick() {
    this.elementAfter = this.el.nativeElement.querySelector(".mat-mdc-tab-header-pagination-after");
    this.elementBefore = this.el.nativeElement.querySelector(".mat-mdc-tab-header-pagination-before");
    this.elementAfter.addEventListener("click", this.onArrowClick.bind(this, 'R'));
    this.elementBefore.addEventListener("click", this.onArrowClick.bind(this, 'L'));
  }

  addEventSubmit() {
    const form = (this.el.nativeElement as HTMLElement).querySelector("form");
    if (form) {
      const submitBtn = form.querySelector("button.submit-form");
      if (submitBtn) {
        this.elementSubmit = submitBtn;
        this.elementSubmit.addEventListener('click', this.onSubmit.bind(this));
      }
    }
  }

  verificaTabValidacao() {
    const formErrors = getFormValidationErrors(this.form);
    if (formErrors) {
      const tabErrors = this.tabsFields.filter(el => formErrors.includes(el.item));
      if (tabErrors && tabErrors.length > 0) {
        const firstError = tabErrors[0];
        this.setTab(firstError.tabIndex);
        this.message.error('Campos obrigatórios não preenchidos.');
        setTimeout(() => {
          $(`[formcontrolname='${firstError.item}']`).focus();
        });
      }
    }
  }

  onSubmit() {
    this.verificaTabValidacao();
  }

  onArrowClick(dir) {
    if (dir == 'R') {
      if (this.selected.value + 1 < this.inputs.length) {
        if (!this.nextTabDisabled()) {
          this.selected.setValue(this.selected.value + 1);
        }
      }
    }
    if (dir == 'L') {
      if (this.selected.value > 0) {
        this.selected.setValue(this.selected.value - 1);
      }
    }
  }

  public setTab(tab: number) {
    this.selected.setValue(tab);
  }

  private swipeCoord?: [number, number];
  private swipeTime?: number;
  private horizontalDir: string = "";

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [e.changedTouches[0].pageX, e.changedTouches[0].pageY];
    const time = new Date().getTime();

    if (this.blockSwipe(e.composedPath())) return;

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {

      const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
      const duration = time - this.swipeTime;

      if ((duration < 1000 && (Math.abs(direction[0]) > 50)) // Long enough
        && (Math.abs(direction[0]) > Math.abs(direction[1] * 3))) { // Horizontal enough

        direction[0] < 0 ? this.horizontalDir = "L" : this.horizontalDir = "R";

        if (this.horizontalDir == "L" && this.selected.value != (this.inputs.length - 1)) {
          if (!this.nextTabDisabled()) {
            this.selected.setValue(this.selected.value + 1);
          }
        };
        if (this.horizontalDir == "R" && this.selected.value != 0) {
          this.selected.setValue(this.selected.value - 1);
        };
      }
    }
  }

  nextTabDisabled(): boolean {
    return document.querySelector(".mat-mdc-tab-labels").children.item(this.selected.value + 1).attributes.getNamedItem('aria-disabled').value == 'true';
  }

  blockSwipe(target: EventTarget[]): boolean {
    const notSwipe = ['app-dim-datatable', 'mat-mdc-tab-header'];
    for (let item of target) {
      if (item instanceof Element) {
        if (notSwipe.some(t => t == (item as Element).localName)) return true;
      }
    }
    return false;
  }


  ngOnDestroy(): void {
    if (this.elementAfter) {
      this.elementAfter.removeEventListener('click', this.onArrowClick, true);
    }
    if (this.elementBefore) {
      this.elementBefore.removeEventListener('click', this.onArrowClick, true);
    }
    if (this.elementSubmit) {
      this.elementSubmit.removeEventListener('click', this.onSubmit, true);
    }
  }
}
