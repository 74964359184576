<div class="input-group input-group-data">

  <ng-container *ngTemplateOutlet="calendarPosition == 'left' ? addOn : null"></ng-container>

  <input [id]="id" class="form-control" (blur)="onTouched()" [disabled]="disabled" type="text" [ngModel]="date"
    (ngModelChange)="onChange($event)" triggers="manual" #dp="bsDatepicker" bsDatepicker [outsideClick]="true"
    [bsConfig]="bsConfig" [placement]="calendarPlacement" [maxDate]="maxDate" [minDate]="minDate" #input>

  <ng-container *ngTemplateOutlet="calendarPosition == 'right' ? addOn : null"></ng-container>

  <ng-template #addOn>
    <span [ngStyle]="{'pointer-events': disabled ? 'none' : 'auto' }" (click)="dp.toggle()"
      class="input-group-addon default-color-addon">
      <span class="fa fa-calendar" *ngIf="!loading"></span>
      <span class="fa fa-circle-o-notch fa-spin" *ngIf="loading"></span>
    </span>
  </ng-template>

</div>
