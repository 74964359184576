export class OrigemMercadoria {

    id: number;
    descricao: string;

    constructor() {

    }

    ListarOrigem() {
        return [
            { id: 0, descricao: '0 - Nacional' },
            { id: 1, descricao: "1 - Estrangeira - Importação Direta" },
            { id: 3, descricao: "3 - Nacional, mercadoria ou bem com Conteúdo de Importação superior a 40% até 70%" },
            { id: 2, descricao: "2 - Estrangeira - adquirida no mercado Interno" },
            { id: 4, descricao: "4 - Nacional, cuja produção tenha sido feita em conformidade com os processos produtivos básicos de qualidade que tratam as legislações citadas nos Ajustes" },
            { id: 5, descricao: "5 - Nacional, mercadoria ou bem com Conteúdo de importação inferior ou igual a 70%" },
            { id: 6, descricao: "6 - Estrangeira - Importação Direta, sem similar nacional, constante em lista da CAMEX" },
            { id: 7, descricao: "7 - Estrangeira - Adquirida no mercado interno - sem similar nacional, constante em lista da CAMEX" },
            { id: 8, descricao: "8 - Nacional, mercadoria ou bem com conteúdo de importação superior a 70%" }
        ].sort((a, b) => a.descricao.localeCompare(b.descricao));
    }
}