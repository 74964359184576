import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseServiceV2 } from '@servicesv2';
import { GetCFOPDevolucaoByIdResponseViewModel } from '../../models/codigos-fiscais/GetCFOPDevolucaoByIdViewModel';

@Injectable()
export class CodigosFiscaisServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('CodigosFiscais', http);
  }

  getCFOPDevolucaoById(codigoFiscalID: number[]) {
    return this.getAsPromise<GetCFOPDevolucaoByIdResponseViewModel[]>(
      `getCFOPDevolucaoById?${this.formatValueArrayToQueryParam('codigoFiscalID', codigoFiscalID)}`
    );
  }

}
