<app-temp-modal-cadastro #modalConsultar [textHeader]="'Configurações de impressão'" (closed)="onClose()">
  <div class="app-modal-body">
    <form [formGroup]="form">
      <div class="form-control group" style="border: unset; padding-left: 0px;">
        <div class="row">
          <div class="col-sm-12 form-group">
            <label style="font-weight: bold;">Opções de visualização:</label>
          </div>
        </div>
        <div class="row">
          <div class="form-radio col-sm-12">
            <div class="radio radio-inline col-sm-12" style="display: flex; gap: 20px; justify-content: center;">
              <label>
                <input type="radio" formControlName="formato" value="pdf">
                <i class="helper"></i>Formato .PDF
              </label>
              <label>
                <input type="radio" formControlName="formato" value="xls">
                <i class="helper"></i>Formato .XLS
              </label>
            </div>
          </div>
        </div>
      </div>
    </form>
    <button type="button" (click)="imprimir()" class="btn btn-primary"><i class="fa fa-print"></i>
      Imprimir</button>
  </div>
</app-temp-modal-cadastro>
<app-abrir-modal #abrirModal>
</app-abrir-modal>
