import { Injectable } from '@angular/core';
import { ToastaService, ToastOptions, ToastData } from 'ngx-toasta';

@Injectable()
export class MessageService {

  constructor(private toasty: ToastaService) { }

  /**
   * Exibe um toast de sucesso na tela utilizando a biblioteca ngx-toasta.
   *
   * @param {string} [message='Operação realizada com sucesso!'] - A mensagem a ser exibida no toast (opcional, padrão: 'Operação realizada com sucesso!').
   * @param {number} [timeout=4500] - O tempo em milissegundos que o toast permanecerá visível (opcional, padrão: 4.5 segundos).
   *
   * @returns {void}
   */
  success(message = 'Operação realizada com sucesso!', timeout = 4500, clear = true): void {
    if (clear) {
      this.toasty.clearAll();
    }
    const toastOptions: ToastOptions = {
      title: message,
      showClose: true,
      showDuration: false,
      timeout: timeout,
    };
    this.toasty.success(toastOptions);
  }

  /**
   * Exibe um toast de error na tela utilizando a biblioteca ngx-toasta.
   *
   * @param {string} [msg] - A mensagem a ser exibida no toast.
   * @param {number} [timeout=2500] - O tempo em milissegundos que o toast permanecerá visível (opcional, padrão: 2.5 segundos).
   * @param {string} [titulo=''] - O título a ser exibido no toast (opcional, padrão: '').
   * @param {boolean} [closeOthers=false] - Flag para fechar outros toast antes de exibir o erro (opcional, padrão: true).
   *
   * @returns {void}
   */
  error(msg: string, timeout = 2500, titulo = '', closeOthers = true): void {
    if (closeOthers) { this.toasty.clearAll(); }

    const toastOptions: ToastOptions = {
      title: titulo === '' ? msg : titulo,
      msg: titulo === '' ? null : msg,
      showClose: true,
      showDuration: false,
      timeout: timeout
    };
    this.toasty.error(toastOptions);
  }

  clear(id) {
    this.toasty.clear(id);
  }

  clearAll() {
    this.toasty.clearAll();
  }

  addError(options) {
    let idToast;

    if (options.closeOther) {
      this.toasty.clearAll();
    }
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      showDuration: options.showDuration || false,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        idToast = toast.id;
      }
    };

    this.toasty.error(toastOptions);
    return idToast;
  }

  addWarning(options) {
    let idToast;

    if (options.closeOther) {
      this.toasty.clearAll();
    }
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      showDuration: options.showDuration || false,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        idToast = toast.id;
      }
    };

    this.toasty.warning(toastOptions);
    return idToast;
  }

  addToast(options) {
    let idToast;

    if (options.closeOther) {
      this.toasty.clearAll();
    }
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      showDuration: options.showDuration || false,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        idToast = toast.id;
      }
    };

    this.toasty.wait(toastOptions);
    return idToast;
  }

  addSuccess(options) {
    let idToast;

    if (options.closeOther) {
      this.toasty.clearAll();
    }
    const toastOptions: ToastOptions = {
      title: options.title,
      msg: options.msg,
      showClose: options.showClose,
      showDuration: options.showDuration || false,
      timeout: options.timeout,
      theme: options.theme,
      onAdd: (toast: ToastData) => {
        idToast = toast.id;
      },
      onRemove: options.onRemove
    };

    this.toasty.success(toastOptions);
    return idToast;
  }

  info(message, timeout = 20000, titulo = 'Dica') {
    this.toasty.clearAll();
    const toastOptions: ToastOptions = {
      title: titulo,
      msg: message,
      showClose: true,
      showDuration: false,
      timeout: timeout
    };
    this.toasty.info(toastOptions);
  }

  /**
   * Exibe um toast de warning na tela utilizando a biblioteca ngx-toasta.
   *
   * @param {string} message - A mensagem de aviso a ser exibida.
   * @param {number} [timeout=20000] - Tempo em milissegundos que a mensagem permanece visível (opcional, padrão: 20 segundos).
   * @param {string} [titulo='Dica'] - Título da mensagem de aviso (opcional, padrão: 'Dica').
   * @param {boolean} [clear=true] - Flag para fechar outros toast antes de exibir o erro (opcional, padrão: true).
   *
   * @returns {void}
   */
  warning(message: string, timeout = 20000, titulo = 'Dica', clear = true): void {
    if (clear) {
      this.toasty.clearAll();
    }
    const toastOptions: ToastOptions = {
      title: titulo,
      msg: message,
      showClose: true,
      showDuration: false,
      timeout: timeout
    };
    this.toasty.warning(toastOptions);
  }

  wait(msg, timeout = 20000, titulo = '') {
    this.toasty.clearAll();
    const toastOptions: ToastOptions = {
      title: titulo === '' ? msg : titulo,
      msg: titulo === '' ? null : msg,
      showClose: true,
      showDuration: false,
      timeout: timeout
    };
    this.toasty.wait(toastOptions);
  }
}
