import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { catchError, map } from 'rxjs';

@Injectable()
export class UnidadesConsumoService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get() {
    return this.http
      .get(this.UrlService + "unidadesConsumo")
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  salvarUnidadesConsumo(form) {
    return this.http
      .post(this.UrlService + "unidadesConsumo", form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getPaginado(form) {
    return this.http
      .post(this.UrlService + "UnidadesConsumo/GetPaginado", form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  delete(id) {
    return this.http
      .delete(this.UrlService + `unidadesConsumo/${id}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getPaginadoCombobox(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + "UnidadesConsumo/GetPaginadoCombobox?pageIndex=" + pagina + "&search=" + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getById(id: number) {
    return this.http
      .get(this.UrlService + `unidadesConsumo/${id}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
