import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { SegmentosUnicosService } from '../../../services/segmentos-unicos.service';
import { lastValueFrom } from 'rxjs';
import { SegmentosService } from '../../../services/segmentos.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-modal-segmentos-vinculados',
  templateUrl: './modal-segmentos-vinculados.component.html',
  styleUrls: ['./modal-segmentos-vinculados.component.css']
})
export class ModalSegmentosVinculadosComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal;
  empresas: any[];
  segmento: any;

  constructor(
    private segmentosService: SegmentosService,
    private segmentosUnicosService: SegmentosUnicosService,
  ) {
    super();
  }

  ngOnInit() {
    this.empresas = this.prop.empresas;
    this.segmento = this.prop.segmento;
  }

  show() {
    this.modal.show();
  }

  async removerVinculos() {
    try {
      this.loading['delete'] = true;
      await lastValueFrom(this.segmentosService.removerVinculos(this.segmento.id));
      await lastValueFrom(this.segmentosUnicosService.delete(this.segmento.id));
      swal('Segmento removido com sucesso!', '', 'success');
      this.fechar(true);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading['delete'] = false;
    }
  }

  fechar(removeu = false) {
    this.closed.emit(removeu);
  }
}
