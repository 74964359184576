<app-modal-basic #modalCarta
  (closed)="onClose()"
  modalContentId="carta-correcao"
  customClass="modal-lg">
  <div class="app-modal-header">
    <h4 class="modal-title">Carta de Correção</h4>
    <button type="button" class="close basic-close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col">
        <h5>Texto da correção:</h5>
      </div>
    </div>
    <div class="row mt-2" *ngIf="cartaCorrecao">
      <div class="col">
        <p>{{cartaCorrecao.descricao || "-" }}</p>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <button class="btn btn-info custom-form-button" (click)="close()" type="button">
          <i class="ti ti-back-left"></i> Voltar
        </button>
      </div>
    </div>
  </div>
  <div class="app-modal-footer"></div>
</app-modal-basic>
