<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader">
    <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="form-group col-sm-12">
          <label class="label-required">Descrição</label>
          <input [disableControl]="loading['registro']" type="text" class="form-control" formControlName="descricao"/>
          <show-errors [control]="cadastroForm.controls.descricao"></show-errors>
        </div>
      </div>
  
      <div class="alert alert-danger" *ngIf="errors">
        <span>{{ errors }}</span>
      </div>
  
      <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
        <app-loading [texto]="'OK'" [loading]="loading['registro']"></app-loading>
      </button>
      <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
        (click)="modalCadastro.hide()">Cancelar</button>
    </form>
  </app-temp-modal-cadastro>