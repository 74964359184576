import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from "./base.service";
import { map, catchError } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { MessageService } from './message.service';

@Injectable()
export class RelatorioAtendimentosService extends BaseService {

  constructor(private http: HttpClient, private message: MessageService) { super(); }

  gerarRelatorioSelecionado(consulta:any){

    if (consulta.opcao==0) return this.imprimirAtendimentoContabilidade(consulta);
    if (consulta.opcao==1) return this.imprimirAtendimentoClientes(consulta);
    if (consulta.opcao==2) return this.imprimirAtendimentoIndicadores(consulta);
    if (consulta.opcao==3) return this.imprimirAtendimentoIndicados(consulta);

  }

  imprimirAtendimentoContabilidade(consulta: any) {
    let idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Atendimento às Contabilidades',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    })
    return this.http
      .post(this.UrlService + 'atendimentoContabilidade/imprimirAtendimentoContabilidade', consulta, { responseType: 'blob' }).pipe(finalize(() => this.message.clear(idToast))
        , catchError(super.parseErrorBlob))
  }

  imprimirAtendimentoClientes(consulta: any) {
    let idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Atendimento aos Clientes',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    })
    return this.http
      .post(this.UrlService + 'logAtendimentos/imprimirAtendimentoClientes', consulta, { responseType: 'blob' }).pipe(finalize(() => this.message.clear(idToast))
        , catchError(super.parseErrorBlob))
  }

  imprimirAtendimentoIndicadores(consulta: any) {
    let idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Atendimento aos Indicadores',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    })
    return this.http
      .post(this.UrlService + 'atendimentoIndicadores/imprimirAtendimentoIndicadores', consulta, { responseType: 'blob' }).pipe(finalize(() => this.message.clear(idToast))
        , catchError(super.parseErrorBlob))
  }

  imprimirAtendimentoIndicados(consulta: any) {
    let idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Atendimento aos Indicados',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    })
    return this.http
      .post(this.UrlService + 'atendimentoIndicados/imprimirAtendimentoIndicados', consulta, { responseType: 'blob' }).pipe(finalize(() => this.message.clear(idToast))
        , catchError(super.parseErrorBlob))
  }
}
