import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { CidadesService } from '../../../services/cidades.service';
import { EstadosService } from '../../../services/estados.service';
import * as $ from 'jquery';
import { TiposEnderecoCliente } from '../../../model/cliente';
import { NgSelectComponent } from '@ng-select/ng-select';
import { setFormValue } from '../../../general/utils';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-cadastro-cliente-endereco',
  templateUrl: './cadastro-cliente-endereco.component.html',
  styleUrls: ['./cadastro-cliente-endereco.component.css']
})
export class CadastroClienteEnderecoComponent extends ModalCadastroComponent implements OnInit {

  constructor(private cidadeService: CidadesService, private estadoService: EstadosService) { super(); }

  loading = {};
  estados = [];
  cidades = [];
  tiposEndereco = TiposEnderecoCliente.listar();

  @ViewChild('modalEndereco', { static: true }) modalEndereco;
  @ViewChild('selectCidade', { static: false }) selectCidade: NgSelectComponent;

  ngOnInit() {
    this.cadastroForm = this.getFormEndereco();
    this.carregaEstados();

    if (this.prop) {
      setFormValue(this.cadastroForm, this.prop);
      const estadoId = this.cadastroForm.get('estadoId').value;
      if (estadoId) {
        this.getCidadesPorEstado(estadoId);
      }
    }

    setTimeout(() => {
      $('[formcontrolname="cep"]').focus();
    });
  }

  carregaEstados() {
    this.loading['estados'] = true;
    this.estadoService.get()
      .pipe(finalize(() => this.loading['estados'] = false))
      .subscribe(res => {
        this.estados = res;
      });
  }



  getFormEndereco(): FormGroup {
    return new FormGroup({
      id: new FormControl(null),
      tipo: new FormControl(null, [Validators.required]),
      estadoId: new FormControl(null, [Validators.required]),
      cidadeId: new FormControl(null, [Validators.required]),
      endereco: new FormControl(null, [Validators.required]),
      numero: new FormControl(null, [Validators.required]),
      bairro: new FormControl(null, [Validators.required]),
      cep: new FormControl(null, [Validators.required]),
      complemento: new FormControl(null),
      observacoes: new FormControl(null),
      contato: new FormControl(null),
      cargo: new FormControl(null),
      nomeCidade: new FormControl(null),
      index: new FormControl(null)
    });
  }

  show() {
    this.modalEndereco.show();
  }

  getCidadesPorEstado(estadoId): Promise<any> {
    return new Promise((resolve) => {
      this.loading['cidades'] = true;
      this.cidadeService.getByEstado(estadoId)
        .pipe(finalize(() => {
          this.loading['cidades'] = false;
          resolve(true);
        }))
        .subscribe(result => {
          this.cidades = result;
        });
    });
  }

  getEndereco() {
    const cep = this.cadastroForm.get('cep').value || '';

    if (cep.length === 8) {
      this.loading['endereco'] = true;
      this.cidadeService.getEnderecoByCep(cep)
        .then(res => res.json())
        .then(response => {
          if (response.cep) {
            return this.estadoService.getByUf(response.uf).toPromise()
              .then(res => {
                this.cadastroForm.get('estadoId').setValue(res[0].id, { emitViewToModelChange: false });

                this.getCidadesPorEstado(res[0].id)
                  .then(() => {
                    const cidade = this.cidades.find(t => t.codigoIbge === response.ibge);
                    if (cidade) {
                      this.cadastroForm.get('cidadeId').setValue(cidade.id);
                    }

                    setTimeout(() => {
                      $('[formcontrolname="numero"]').focus();
                    });
                  });

                this.cadastroForm.controls['bairro'].setValue(response.bairro);
                this.cadastroForm.controls['endereco'].setValue(response.logradouro);
              });
          }
        })
        .then(() => {
          this.loading['endereco'] = false;
        })
        .catch(() => {
          this.loading['endereco'] = false;
        });
    }
  }

  onChangeEstado(estado) {
    this.cadastroForm.get('cidadeId').setValue(null);
    if (estado) {
      this.getCidadesPorEstado(estado);
    } else {
      this.cidades = [];
    }
  }

  salvarEndereco() {
    if (this.cadastroForm.valid) {
      const cidadeSelecionada = this.selectCidade.selectedValues[0];
      this.cadastroForm.get('nomeCidade').setValue(cidadeSelecionada['nome'] + ' - ' + cidadeSelecionada['estado']['siglaEstado']);
      this.closed.emit(this.cadastroForm.value);
    }
  }
}
