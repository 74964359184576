import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { markFormGroupTouched } from '../../../general/utils';
import { DespesasService } from '../../../services/despesas.service';
import swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-destinacao-recursos',
  templateUrl: './destinacao-recursos.component.html',
  styleUrls: ['./destinacao-recursos.component.css']
})
export class DestinacaoRecursosComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalRelatorio", { static: true }) modalRelatorio;
  impressaoForm: FormGroup;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  loading: boolean = false;

  constructor(private despesasService: DespesasService) { super() }

  ngOnInit() {
    this.impressaoForm = new FormGroup({
      dataReferencia: new FormControl(new Date(), [Validators.required]),
      detalharDespesas: new FormControl(false)
    })
  }

  destroyComponent() {
    this.closed.emit();
  }

  show(): void {
    this.modalRelatorio.show();
  }

  validaForm() {
    markFormGroupTouched(this.impressaoForm);
  }

  submitForm() {
    if (this.impressaoForm.valid) {
      this.loading = true;
      this.modalRelatorio.hide();
      this.despesasService.imprimirDestinacaoRecursos(this.impressaoForm.value)
        .pipe(finalize(() => this.loading = false))
        .subscribe(res => {
          saveAs(res, 'relatorio_destinacao_recursos.pdf');
          this.destroyComponent();
        }, (error) => {
          swal("Erro ao emitir o relatório: Destinação de recursos", error.errors[0], "error");
          this.destroyComponent();
        })
    }
  }

}
