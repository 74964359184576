import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl } from '@angular/forms';
import { ParamConsulta } from '../../../general/models/paramConsulta';
import { UsuariosService } from '../../../services/usuarios.service';

@Component({
  selector: 'app-usuarios-empresa',
  templateUrl: './usuarios-empresa.component.html',
  styleUrls: ['./usuarios-empresa.component.css']
})
export class UsuariosEmpresaComponent extends ModalCadastroComponent implements OnInit {

  constructor(
    private usuariosService: UsuariosService
  ) {
    super();
  }

  @ViewChild('modalConsultar', { static: true }) modalConsultar;
  @ViewChild('abrirModal', { static: false }) abrirModal;
  retornoUsuarios: any = null;
  consultaFormUsuarios: FormGroup;
  colunasUsuarios = [
    { label: '#', col: 'id', hide: false, type: 'button', contentClass: 'ti ti-pencil' },
    { label: 'Login', col: 'login', hide: false, clipboard: true },
    { label: 'Senha', col: 'senha', hide: false, clipboard: true },
  ];

  ngOnInit() {
  }

  show(unidadeEmpresaID) {
    this.id = unidadeEmpresaID;
    this.consultaUsuariosEmpresa(unidadeEmpresaID);
    this.modalConsultar.show();
  }

  editarUsuario(event) {
    this.abrirModal.editar('editarUsuarioClientes', event);
  }

  setPageUsuarios(paramConsulta) {
    this.consultaFormUsuarios.controls['paramConsulta'].setValue(paramConsulta);
    this.doConsultaUsuariosEmpresa();
  }

  onSortUsuarios(paramConsulta) {
    this.consultaFormUsuarios.controls['paramConsulta'].setValue(paramConsulta);
    this.doConsultaUsuariosEmpresa();
  }

  consultaUsuariosEmpresa(unidadeEmpresaID) {
    this.retornoUsuarios = [];
    this.id = unidadeEmpresaID;

    this.consultaFormUsuarios = new FormGroup({
      unidadeEmpresaID: new FormControl(this.id),
      paramConsulta: new FormControl(new ParamConsulta())
    });

    this.doConsultaUsuariosEmpresa();
  }

  doConsultaUsuariosEmpresa() {
    this.usuariosService.getUsuariosPorUnidadeEmpresa(this.consultaFormUsuarios.value)
      .subscribe(res => {
        this.retornoUsuarios = res;
      });
  }

  verificaUserAdmin() {
    return (user) => {
      if (user && user.administrador) {
        return { 'background-color': 'var(--tr-user-admin-bg-color)' };
      }

      return '';
    };
  }

}
