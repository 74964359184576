<app-temp-modal-cadastro textHeader='Cadastro endereço' #modalEndereco (closed)="onClose()">

  <form [formGroup]="cadastroForm" (ngSubmit)="salvarEndereco()">

    <div class="row">
      <div class="form-group col-sm-6">
        <label class="font-weight-bold">Tipo</label>
        <ng-select appendTo=".modal-content" formControlName="tipo" placeholder="Selecione" [items]="tiposEndereco"
          bindLabel="nome" bindValue="id" [disableControl]="loading['registro']">
        </ng-select>
        <show-errors [control]="cadastroForm.controls['tipo']"></show-errors>
      </div>

      <div class="form-group col-sm-6">
        <label class="font-weight-bold">Cep</label>
        <input type="text" formControlName="cep" class="form-control" (change)="getEndereco()"
          [disableControl]="loading['registro'] || loading['endereco']" mask='00000-000' />
        <show-errors [control]="cadastroForm.controls['cep']"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6">
        <label class="font-weight-bold">Estado</label>
        <ng-select appendTo=".modal-content" formControlName="estadoId" placeholder="Selecione" [items]="estados"
          bindLabel="nome" bindValue="id" (ngModelChange)="onChangeEstado($event)"
          [loading]="loading['endereco'] || loading['estados']"
          [disableControl]="loading['registro'] || loading['endereco'] || loading['estados']">
        </ng-select>
        <show-errors [control]="cadastroForm.controls['estadoId']"></show-errors>
      </div>

      <div class="form-group col-sm-6">
        <label class="font-weight-bold">Cidade</label>
        <ng-select #selectCidade appendTo=".modal-content" formControlName="cidadeId" placeholder="Selecione"
          [items]="cidades" bindLabel="nome" bindValue="id" [loading]="loading['endereco'] || loading['cidades']"
          [disableControl]="loading['registro'] || loading['endereco'] || loading['cidades']">
        </ng-select>
        <show-errors [control]="cadastroForm.controls['cidadeId']"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6">
        <label class="font-weight-bold">Endereço</label>
        <input type="text" formControlName="endereco" class="form-control"
          [disableControl]="loading['registro'] || loading['endereco']" />
        <show-errors [control]="cadastroForm.controls['endereco']"></show-errors>
      </div>

      <div class="form-group col-sm-6">
        <label class="font-weight-bold">Bairro</label>
        <input type="text" formControlName="bairro" class="form-control"
          [disableControl]="loading['registro'] || loading['endereco']" />
        <show-errors [control]="cadastroForm.controls['bairro']"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-4">
        <label class="font-weight-bold">Número</label>
        <input type="text" digitOnly formControlName="numero" class="form-control"
          [disableControl]="loading['registro']" />
        <show-errors [control]="cadastroForm.controls['numero']"></show-errors>
      </div>


      <div class="form-group col-sm-8">
        <label>Complemento</label>
        <input type="text" formControlName="complemento" class="form-control" [disableControl]="loading['registro']" />
        <show-errors [control]="cadastroForm.controls['complemento']"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6">
        <label>Contato</label>
        <input type="text" formControlName="contato" class="form-control" [disableControl]="loading['registro']" />
        <show-errors [control]="cadastroForm.controls['contato']"></show-errors>
      </div>

      <div class="form-group col-sm-6">
        <label>Cargo</label>
        <input type="text" formControlName="cargo" class="form-control" [disableControl]="loading['registro']" />
        <show-errors [control]="cadastroForm.controls['cargo']"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <label>Observações</label>
        <input type="text" formControlName="observacoes" class="form-control" [disableControl]="loading['registro']" />
        <show-errors [control]="cadastroForm.controls['observacoes']"></show-errors>
      </div>
    </div>

    <button type="submit" (click)="validaForm()"
      [disabled]="loading['registro'] || loading['endereco'] || loading['cidades'] || loading['estados']"
      class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'"></app-loading>
    </button>

    <button type="button" class="btn btn-default ripple" (click)="modalEndereco.hide()">Cancelar</button>


  </form>

</app-temp-modal-cadastro>