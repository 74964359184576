import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl } from '@angular/forms';
import { setFieldFormValue } from '../../../../../app/general/utils';

@Component({
  selector: 'app-lancar-estoque',
  templateUrl: './lancar-estoque.component.html',
  styleUrls: ['./lancar-estoque.component.css']
})
export class LancarEstoqueComponent extends ModalCadastroComponent implements OnInit {

  constructor() { super(); }

  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  loading = [];

  form;

  ngOnInit() {
    this.form = new FormGroup({
      almoxarifadoID: new FormControl(this.prop.almoxarifadoId.value),
      unidadeMedidaID: new FormControl(this.prop.unidadeMedidaId.value),
      motivoMovimentacaoEstoqueID: new FormControl(this.prop.motivoMovimentacaoEstoqueID.value),
      quantidade: new FormControl(this.prop.quantidade.value)
    });

  }

  show() {
    this.modalCadastro.show();
  }

  onClose() {}

  confirmar() {
    this.prop.almoxarifadoId.setValue(this.form.get('almoxarifadoID').value);
    this.prop.unidadeMedidaId.setValue(this.form.get('unidadeMedidaID').value);
    this.prop.motivoMovimentacaoEstoqueID.setValue(this.form.get('motivoMovimentacaoEstoqueID').value);
    this.prop.quantidade.setValue(this.form.get('quantidade').value);
    this.modalCadastro.hide();
  }
}
