import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, Subject } from 'rxjs';

import { IComboboxPadrao } from './combobox-padrao.interface';
import { MessageService } from '@services';
import { BaseServiceV2, BaseViewModel } from '@servicesv2';
import { mergeArrays } from '@utils';
import { ConsultaPaginadaViewModel } from '@models';
import { GetPaginatedViewModel } from '../genericas/GetPaginatedViewModel';

export class ComboboxPadrao<T extends BaseViewModel> implements IComboboxPadrao<T> {

  service: BaseServiceV2;
  message: MessageService;

  selected = new FormControl<number | string>(null);

  searchTerm = '';
  typeahead = new Subject<string>();

  required = false;
  requiredText = 'Campo obrigatório';
  id: string;
  appendTo = 'body';
  items: T[];
  bindLabel = 'nome';
  bindValue = 'id';
  label = 'Combobox padrão';
  paginated = true;
  placeholder = 'Selecione';
  notFoundText = 'Nenhum item encontrado';
  virtualScroll = true;
  debounceTime = 1000;
  clearOnBackspace = false;
  notFoundPlusIcon = true;
  pencilIcon = true;
  loadingText = 'Buscando';
  modalName = '';
  searchField = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customData: any = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatLabel: (row: any) => string = null;

  loadingStates = {
    request: false,
  };

  private page = 1;

  onChange: (value: T | number | null) => void;
  onTouched: () => void;

  constructor(
    id: string,
    message: MessageService,
    service: BaseServiceV2,
  ) {
    this.id = id;
    this.message = message;
    this.service = service;

    this.setTypeahead();
    this.setItems();

    this.selected.valueChanges.subscribe((newValue) => {
      if (!this.isSelectedInItems(newValue)) {
        this.setItems();
      }
    });
  }

  async scrollToEndSearchPaginated() {
    const response = await this.searchPaginated();
    this.items = this.unique(mergeArrays([this.items, response.items]));
  }

  async typeaheadSearchPaginated() {
    const response = await this.searchPaginated();
    this.items = response.items;
  }

  async setItems() {
    if (this.paginated) {
      const response = await this.searchPaginated();
      this.items = response?.items || [];
      if (this.selected.value && !this.isSelectedInItems(this.selected.value)) {
        const missingItem = await this.service.getAsPromise<T>(`${this.selected.value}`);
        this.items = this.unique(mergeArrays([[missingItem], this.items]));
      }
    } else {
      const response = await this.search();
      this.items = response || [];
    }
  }

  async search() {
    let response: T[];
    try {
      this.loadingStates.request = true;
      response = await this.service.getAsPromise<T[]>();
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingStates.request = false;
    }
    return [...response];
  }

  async searchPaginated() {
    let response: ConsultaPaginadaViewModel<T>;
    try {
      const payload: GetPaginatedViewModel = {
        pageIndex: this.page,
        searchField: this.searchField,
        searchValue: this.searchTerm,
      };
      if (this.customData) {
        payload.customData = { ...this.customData };
      }
      this.loadingStates.request = true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      response = await this.service.postPaginatedAsPromise<any, ConsultaPaginadaViewModel<T>>(payload);
      if (this.formatLabel) {
        response.items = response.items.map(t => {
          t[this.bindLabel] = this.formatLabel(t);
          return t;
        });
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.loadingStates.request = false;
    }
    return {...response};
  }

  handleScrollToEnd() {
    if (this.paginated) {
      this.page ++;
      this.scrollToEndSearchPaginated();
    }
  }

  setTypeahead() {
    if (!this.paginated) {
      return;
    }

    this.typeahead.pipe(
      debounceTime(this.debounceTime),
      distinctUntilChanged(),
      map(searchTerm => searchTerm),
    ).subscribe((searchTerm) => {
      this.page = 1;
      this.items = [];
      this.searchTerm = searchTerm;
      this.typeaheadSearchPaginated();
    });
  }

  /**
   * Filtra os itens com base no predicate informado.
   *
   * @template T - O tipo dos itens na lista.
   * @param {(item: T) => boolean} predicate - A função predicate usada para filtrar os itens.
   * @returns {T[]} - Retorna um array de itens filtrados.
   */
  async filter(predicate: (item: T) => boolean) {
    return [...this.items.filter(predicate)];
  }

  /**
   * Encontra o primeiro item com base no predicate informado.
   *
   * @template T - O tipo dos itens na lista.
   * @param {(item: T) => boolean} predicate - A função predicate usada para encontrar o item.
   * @returns {T | undefined} - Retorna o primeiro item encontrado com base no predicate informado, ou undefined se nenhum item for encontrado.
   */
  async find(predicate: (item: T) => boolean): Promise<T | undefined> {
    const item = this.items.find(predicate);
    if (!item) {
      return item;
    }
    return {...item};
  }

  async getItem(id: number) {
    const item = await this.find((item: T) => item.id === id);
    if (!item) {
      return this.service.get(`${id}`);
    }
    return {...item};
  }

  getItems() {
    return [...this.items];
  }

  isSelectedInItems(selected: string | number) {
    if (!this.items || !this.items.length) {
      return true;
    }
    return this.items.find(item => item[this.bindValue] === selected);
  }

  private unique(arr: T[]) {
    return arr.reduce((accumulator, current) => {
      const found = accumulator.find(item => item.id === current.id);
      if (!found) {
        accumulator.push(current);
      }
      return accumulator;
    }, [] as T[]);
  }

}

export interface INgSelect {
  open: () => void;
  close: () => void;
  focus: () => void;
  blur: () => void;
}
