import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { IAliquotaICMS } from '../model/IAliquotaICMS';
import { Observable } from 'rxjs';

@Injectable()
export class AliquotasIcmsService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getById(id: Number) {
        return this.http.get(this.UrlService + 'aliquotasICMS/' + id)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    getAliquotasIcms(): Observable<IAliquotaICMS[]> {
        return this.http.get(this.UrlService + 'aliquotasICMS')
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    getPaginado(pagina: number, nome: string) {
        return this.http
            .get(this.UrlService + `aliquotasICMS/Paginado?pageIndex=` + pagina + '&search=' + encodeURIComponent(nome))
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    salvar(form: any) {
        if (form.id == null || form.id === 0) {
            return this.post(form);
        } else {
            return this.put(form);
        }
    }

    private post(form) {
        return this.http.post(this.UrlService + 'aliquotasICMS', form)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    private put(form) {
        return this.http.put(this.UrlService + 'aliquotasICMS', form)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }
}
