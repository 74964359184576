import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, range } from 'rxjs';
import { filter, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-dim-pagination',
  templateUrl: './dim-pagination.component.html',
  styleUrls: ['./dim-pagination.component.css']
})
export class DimPaginationComponent implements OnInit {

  constructor() { }


  @Input() offset: number = 0;
  @Input() registersPage: number = 0;
  @Input() totalRegisters: number = 0;
  @Input() range: number = 3;
  pages: Observable<number[]>;
  @Input() currentPage: number = 1;
  totalPages: number;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  ngOnInit() {
    this.getPages(this.offset, this.registersPage, this.totalRegisters);
  }

  ngOnChanges(event) {

    if (event.currentPage !== undefined)
      this.getPages(event.currentPage.currentValue, this.registersPage, this.totalRegisters);
    else
      this.getPages(1, this.registersPage, this.totalRegisters);
  }

  getPages(offset: number, limitByPage: number, total: number) {
    //this.currentPage = this.getCurrentPage(offset);
    this.totalPages = this.getTotalPages();

    let control = 1;
    if (this.currentPage % this.range == 0) control = this.currentPage
    else if (this.currentPage / this.range < 1) control = 1;
    else control = this.currentPage - this.currentPage % this.range;

    this.pages = range(control, this.range)
      .pipe(filter(offset => this.isValidPageNumber(offset, this.totalPages)))
      .pipe(toArray());
  }

  changePage(page) {

    if (this.isValidPageNumber(page, this.totalPages)) {
      this.pageChange.emit(page);
    }
  }

  getCurrentPage(offset: number): number {
    return offset
  }

  getTotalPages(): number {
    if (this.totalRegisters % this.registersPage === 0)
      return Math.floor(this.totalRegisters / this.registersPage);
    else
      return Math.floor(this.totalRegisters / this.registersPage + 1);
  }
  isValidPageNumber(page: number, totalPages: number): boolean {
    return page > 0 && page <= totalPages;
  }
}
