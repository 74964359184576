import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, concat, Observable } from 'rxjs';
import { map, catchError, finalize, concatAll, toArray } from 'rxjs/operators';

import {
  UnidadeEmpresa,
  TitulosCobranca,
  DescontosFormaPagamento,
  CalculosPagamentoTitulo,
  ConsultaPaginadaViewModel,
} from '@models';
import {
  MessageService,
  BaseService,
  DimHttpServerResponse
} from '@services';
import { CadastroTitulosCobrancaViewModel } from 'models/titulos-cobranca/CadastroTitulosCobrancaViewModel';
import { TitulosCobrancaViewModel } from 'models/titulos-cobranca/TitulosCobrancaViewModel';


@Injectable()
export class TitulosCobrancaService extends BaseService {

  constructor(private http: HttpClient, private message: MessageService) {
    super();
  }

  imprimir(consulta, tipoRelatorio: number) {
    return this.http
      .post(this.UrlService + `titulosCobranca/imprimir/${tipoRelatorio}`, consulta, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  printRecibo(titulos: number[]) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/recibo', titulos, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  baixarTitulos(titulos) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/baixarMultiplosTitulos', titulos)
      .pipe(map((res: DimHttpServerResponse) => res.data)
        , catchError(super.serviceError));
  }

  getTitulosEmpresa(unidadeEmpresa: UnidadeEmpresa) {
    return this.http.post(this.UrlService + 'titulosCobranca/unidadeEmpresa/', unidadeEmpresa)
      .pipe(map((res: DimHttpServerResponse) => res.data as CadastroTitulosCobrancaViewModel[])
        , catchError(super.serviceError));
  }

  getGroupTitulos(ids: number[]) {
    const list = [];
    for (let i = 0; i < ids.length; i++) {
      list.push(this.getTituloById(ids[i]));
    }
    return forkJoin(list);
  }

  getWithFilters(consulta) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/buscar', consulta)
      .pipe(map((res: DimHttpServerResponse) => res.data as BuscarTitulosCobrancaResponseViewModel)
        , catchError(super.serviceError));
  }

  estornarTitulo(tituloDataCredito) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/estornar', tituloDataCredito)
      .pipe(map((res: DimHttpServerResponse) => res.data)
        , catchError(super.serviceError));
  }

  deleteTitulo(id: number) {
    return this.http
      .delete(this.UrlService + 'titulosCobranca/' + id)
      .pipe(map((res: DimHttpServerResponse) => res.data)
        , catchError(super.serviceError));
  }

  getTituloById(id: number) {
    return this.http
      .get(this.UrlService + 'titulosCobranca/' + id)
      .pipe(map((res: DimHttpServerResponse) => res.data as CadastroTitulosCobrancaViewModel)
        , catchError(super.serviceError));
  }

  deleteGroupTitulos(ids: number[]) {
    const listToDelete = [];
    for (let i = 0; i < ids.length; i++) {
      listToDelete.push(this.deleteTitulo(ids[i]));
    }
    return concat(listToDelete).pipe(concatAll(), toArray());
  }

  estornarGroupTitulos(titulosAndDataCredito) {
    const listToEstornar = [];
    for (let i = 0; i < titulosAndDataCredito.length; i++) {
      listToEstornar.push(this.estornarTitulo(titulosAndDataCredito[i]));
    }
    return concat(listToEstornar).pipe(concatAll(), toArray());
  }

  imprimirBoletoSafe2Pay(idTitulo: number) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/imprimir/boleto/safe2pay', idTitulo)
      .pipe(map((res: DimHttpServerResponse) => res.data as ImpressaoBoletoSafe2PayResponseViewModel), catchError(super.serviceError));
  }

  imprimirBoleto(idTitulo: number) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/imprimir/boleto', idTitulo, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  imprimirXML(idNotaFiscal: number, tipo: number) {
    return this.http
      .post(this.UrlService + `titulosCobranca/imprimir/XML/${tipo}`, idNotaFiscal, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  imprimirPDF(idNotaFiscal: number, tipo: number) {
    return this.http
      .post(this.UrlService + `titulosCobranca/imprimir/PDF/${tipo}`, idNotaFiscal, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  imprimirDuplicata(id: number) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/imprimir/duplicata', id, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  postForm(form) {
    return this.http.post(this.UrlService + 'titulosCobranca/salvar/', form)
      .pipe(map((res: DimHttpServerResponse) => res.data)
        , catchError(super.serviceError));
  }

  putForm(form) {
    return this.http.put(this.UrlService + 'titulosCobranca/alterar/', form)
      .pipe(map((res: DimHttpServerResponse) => res.data)
        , catchError(super.serviceError));
  }

  postMultipleForm(form) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/salvarLista', form)
      .pipe(map((res: DimHttpServerResponse) => res.data)
        , catchError(super.serviceError));
  }

  enviarEmail(titulo: TitulosCobranca, unidade: UnidadeEmpresa) {
    const param = {
      'Titulo': {
        id: titulo.id
      },
      'Unidade': {
        id: unidade.id,
        email: unidade.email
      }
    };

    return this.http.post(this.UrlService + 'titulosCobranca/envio', param)
      .pipe(map((res: DimHttpServerResponse) => res.data));
  }

  getTitulosMensalidadeDimensao(consulta, historico: boolean) {
    return this.http
      .post(this.UrlService + `titulosCobranca/mensalidadeDimensao?historico=${historico}`, consulta)
      .pipe(map((res: DimHttpServerResponse) => res.data as ConsultaPaginadaViewModel<TitulosCobrancaViewModel>)
        , catchError(super.serviceError));
  }

  imprimirRelatorioComissoes(form) {
    const idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Comissões',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    });
    return this.http
      .post(
        this.UrlService + 'titulosCobranca/relatorioComissoes',
        form,
        {
          responseType: 'blob'
        })
      .pipe(finalize(() =>
        this.message.clear(idToast)), catchError(super.parseErrorBlob));
  }

  imprimirRelatorioComissoesComSaldo(form) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/relatorioComissoesComSaldo', form, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  getDescontosFormaPagamento(): Observable<DescontosFormaPagamento[]> {
    return this.http
      .get(this.UrlService + 'titulosCobranca/GetDescontosFormaPagamento')
      .pipe(map((res: DimHttpServerResponse) => res.data as DescontosFormaPagamento[])
        , catchError(super.serviceError));
  }

  gerarPagamentoMensalidadePix(titulos: CadastroTitulosCobrancaViewModel[]) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/GerarPagamentoPix', titulos.map(x => x.id))
      .pipe(map((res: DimHttpServerResponse) => res.data)
        , catchError(super.serviceError));
  }

  gerarPagamentoMensalidadeCredito(form) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/GerarPagamentoCredito', form)
      .pipe(map((res: DimHttpServerResponse) => res.data as GerarPagamentoCreditoResponseViewModel)
        , catchError(super.serviceError));
  }

  gerarPagamentoMensalidadeBoleto(titulos: CadastroTitulosCobrancaViewModel[]) {
    const body = {
      titulos: titulos.map(x => x.id)
    };

    return this.http
      .post(this.UrlService + 'titulosCobranca/GerarPagamentoBoleto', body)
      .pipe(map((res: DimHttpServerResponse) => res.data)
        , catchError(super.serviceError));
  }

  calcularPagamentoTitulosCredito(titulos: CadastroTitulosCobrancaViewModel[]): Observable<CalculosPagamentoTitulo[]> {
    return this.http
      .post(this.UrlService + 'titulosCobranca/CalcularPagamentoTitulosCredito', titulos.map(x => x.id))
      .pipe(map((res: DimHttpServerResponse) => res.data as CalculosPagamentoTitulo[])
        , catchError(super.serviceError));
  }

  calcularFaturasEmAbertoCredito(): Observable<CalculosPagamentoTitulo[]> {
    return this.http
      .post(this.UrlService + 'titulosCobranca/CalcularFaturasEmAbertoCredito', {})
      .pipe(map((res: DimHttpServerResponse) => res.data as CalculosPagamentoTitulo[])
        , catchError(super.serviceError));
  }

  uploadComprovantePagamentoMensalidade(form: FormData) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/UploadComprovantePagamentoMensalidade', form)
      .pipe(map((res: DimHttpServerResponse) => res.data as { desbloquearSistema: boolean })
        , catchError(super.serviceError));
  }

  visualizouBoleto(tituloCobrancaId: number) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/VisualizouBoleto', tituloCobrancaId)
      .pipe(map((res: DimHttpServerResponse) => res.data)
        , catchError(super.serviceError));
  }

  consultarTitulosABaixarIntermediario(dados) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/ConsultarTitulosABaixarIntermediario', dados)
      .pipe(map((res: DimHttpServerResponse) => res.data)
        , catchError(super.serviceError));
  }

  baixarTitulosIntermediario(dados) {
    return this.http
      .post(this.UrlService + 'titulosCobranca/BaixarTitulosIntermediario', dados)
      .pipe(map((res: DimHttpServerResponse) => res.data)
        , catchError(super.serviceError));
  }

  enviarBoletoPorEmail(titulos: number[], emails: string[]) {
    const dados = {
      titulos,
      emails,
    };
    return this.http
      .post(this.UrlService + 'titulosCobranca/EnviarBoletoPorEmail', dados)
      .pipe(map((res: DimHttpServerResponse) => res.data)
        , catchError(super.serviceError));
  }
}

interface BuscarTitulosCobrancaResponseViewModel {
  dados: ConsultaPaginadaViewModel<CadastroTitulosCobrancaViewModel>;
  calculo: CalculosTitulosCobrancaResponseViewModel;
}

interface CalculosTitulosCobrancaResponseViewModel {
  valorTotal: number;
  valorRecebido: number;
}

interface ImpressaoBoletoSafe2PayResponseViewModel {
  error: string;
  urlBoleto: string;
}

interface GerarPagamentoCreditoResponseViewModel {
  responseDetail: GerarPagamentoCreditoResponseDetailViewModel;
  hasError?: boolean;
  errorCode?: string;
  error?: string;
  code?: string;
}

interface GerarPagamentoCreditoResponseDetailViewModel {
  idTransaction: number;
  token?: string;
  description?: string;
  status: number;
  returnCode?: string;
  message?: string;
  creditCard: { returnCode: string; };
}

