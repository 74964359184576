<app-modal-basic #modalRelatorio (closed)="destroyComponent()">
  <div class="app-modal-header">
    <h4 class="modal-title">Resumo de Saídas por Estado</h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="impressaoForm" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Emissão</label>
          <div class="input-group">
            <app-data [calendarPosition]="'left'" formControlName="dataInicio"></app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="dataFim"></app-data>
          </div>
        </div>
      </div>

      <div class="row form-group col-sm-12">
        <div>
          <span>Orientação da Impressão</span>
        </div>
  
        <div class="form-radio form-control" style="border: unset; padding-left: 0px;">
          <div class="radio radio-inline">
            <label style="margin-bottom: 0px;">
              <input type="radio" formControlName="orientacao" value='vertical'
                [disableControl]="loading['disable'] || loading['request']">
              <i class="helper"></i><span> Retrato</span>
            </label>
          </div>
          <div class="radio radio-inline">
            <label style="margin-bottom: 0px;">
              <input type="radio" formControlName="orientacao" value='horizontal'
                [disableControl]="loading['disable'] || loading['request']">
              <i class="helper"></i><span> Paisagem</span>
            </label>
          </div>
        </div>
      </div>

      <button type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
        <i class="icofont icofont-print"> </i> Imprimir
      </button>
      <button type="button" class="btn btn-danger ripple" (click)="modalRelatorio.hide()"><i class="ti ti-exit"></i>
        Cancelar
      </button>
    </form>
  </div>
  <div class="app-modal-footer">
  </div>
</app-modal-basic>
