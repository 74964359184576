<app-modal-basic #modalRelatorio modalContentId="modal-rvp" (closed)="close()">
  <div class="app-modal-header">
    <h4 class="modal-title text-center">Vendas por Produto</h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="form" (ngSubmit)="imprimir()">
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Emissão</label>
          <div class="input-group">
            <app-data calendarPosition="left" formControlName="emissaoDe"></app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="emissaoAte"></app-data>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-12" rem5 combobox [type]="'vendedor'"
          [(form)]="form" appendTo="#modal-rvp" formControlName="vendedorID">
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-12">
          <label>Tipo de relatório</label>
          <ng-select [clearable]="false" appendTo="#modal-rvp" placeholder="Selecione" [items]="tiposRelatorio"
            bindLabel="label" bindValue="id" formControlName="tipoRelatorio">
          </ng-select>
        </div>
      </div>

      <div class="row" *ngIf="form.get('tipoRelatorio').value === 'vendasPorProduto'">
        <div class="form-group col-sm-12" rem5 combobox type="cliente" formControlName="clienteID"
          label="Cliente" [(form)]="form" appendTo="#modal-rvp">
        </div>
      </div>

      <div class="row form-group col-sm-12">
        <div>
          <span>Formato</span>
        </div>

        <div class="form-radio form-control" style="border: unset; padding-left: 0px;">
          <div class="radio radio-inline">
            <label style="margin-bottom: 0px;">
              <input type="radio" formControlName="formato" value='pdf' [disableControl]="loading['disable'] || loading['request']">
              <i class="helper"></i><span> PDF</span>
            </label>
          </div>
          <div class="radio radio-inline">
            <label style="margin-bottom: 0px;">
              <input type="radio" formControlName="formato" value='xlsx' [disableControl]="loading['disable'] || loading['request']">
              <i class="helper"></i><span> XLSX</span>
            </label>
          </div>
        </div>
      </div>

      <div class="row form-group col-sm-12" *ngIf="form.get('formato').value === 'pdf'">
        <div>
          <span>Orientação</span>
        </div>

        <div class="form-radio form-control" style="border: unset; padding-left: 0px;">
          <div class="radio radio-inline">
            <label style="margin-bottom: 0px;">
              <input type="radio" formControlName="orientacao" value='horizontal' [disableControl]="loading['disable'] || loading['request']">
              <i class="helper"></i><span> Paisagem</span>
            </label>
          </div>
          <div class="radio radio-inline">
            <label style="margin-bottom: 0px;">
              <input type="radio" formControlName="orientacao" value='vertical' [disableControl]="loading['disable'] || loading['request']">
              <i class="helper"></i><span> Retrato</span>
            </label>
          </div>
        </div>
      </div>

      <div class="alert alert-danger background-danger" *ngIf="errorMsg">
        <span>{{ errorMsg }}</span>
      </div>

      <button [disabled]="loading" type="submit" class="btn btn-primary ripple light">
        <app-loading
          [loading]="loading"
          texto="Imprimir"
          textoLoading="Aguarde"
          icone="icofont icofont-print"
        ></app-loading>
      </button>
      <button type="button" class="btn btn-danger ripple" (click)="modalRelatorio.hide()">
        <i class="ti ti-close"></i> Cancelar
      </button>
    </form>
  </div>
</app-modal-basic>
