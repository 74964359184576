<ng-select #select appendTo="body"
  [disabled]="disabled || loading['enderecos'] || !clienteSelecionado || loading['cadastroEndereco']"
  [ngModel]="enderecoSelecionado" [loading]="loading['enderecos'] || loading['cadastroEndereco']"
  (ngModelChange)="enderecoChanged($event)" placeholder="Selecione" [items]="enderecos" bindValue="id">

  <ng-template ng-option-tmp let-item="item">
    <div style="display: flex; align-items: center">
      <span class="ng-option-label" style="overflow: hidden; width: 100%; text-overflow: ellipsis">
        {{templateDescricao(item)}}</span>
      <i (click)="subscribeModal(); abrirModal.abrir('cadastroClienteEndereco', item); $event.stopPropagation(); select.close($event);"
        style="float: right; font-size: 17px; color: #3F88C5" class="fa fa-edit"></i>
    </div>
  </ng-template>

  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option"
      (click)="subscribeModal(); cadastrarNovoEndereco(); $event.stopPropagation(); select.close($event);">
      <div style="display: flex; align-items: center" *ngIf="searchTerm; else emptyList">
        <span style="overflow: hidden; width: 100%; text-overflow: ellipsis">{{searchTerm}}</span>
        <i style="float: right; cursor: pointer; font-size: 17px; color: #3F88C5" class="fa fa-plus"></i>
      </div>
      <ng-template #emptyList>
        <div><span>Adicionar novo endereço...</span></div>
      </ng-template>
    </div>
  </ng-template>

  <ng-template ng-label-tmp let-item="item">
    {{select.loading ? 'Carregando...' : templateDescricao(item)}}
  </ng-template>
</ng-select>

<app-abrir-modal #abrirModal></app-abrir-modal>