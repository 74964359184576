import { NgControl } from '@angular/forms';
import { Directive, Input, OnChanges, OnInit } from '@angular/core';

@Directive({
  selector: '[disableControl]',
})
export class DisableControlDirective implements OnInit, OnChanges {
  @Input() disableControl?: boolean | null;

  constructor(private ngControl: NgControl) { }

  ngOnInit() {
    this.update(!!this.disableControl);
  }

  ngOnChanges() {
    this.update(!!this.disableControl);
  }

  private update(disable: boolean) {
    if (this.ngControl?.control) {
      const action = disable ? 'disable' : 'enable';
      this.ngControl.control[action]({ emitEvent: false });
    }
  }
}
