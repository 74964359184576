import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable()
export class AdminLayoutEventBusService  {
	@Output() atualizarInformacaoFaturasEvento = new EventEmitter<boolean>();
	@Output() atualizarStatusBoletoDimEvento = new EventEmitter<any>();
	@Output() atualizarMenuEvento = new EventEmitter<any>();

	atualizarInformacaoFaturas() {
		this.atualizarInformacaoFaturasEvento.emit(true);
	}

	atualizarStatusBoletoDim() {
		this.atualizarStatusBoletoDimEvento.emit();
	}
}
