<app-temp-modal-cadastro #modalReplicacao (closed)="closed.emit()" [textHeader]="'Replicação'" [modalContentId]="'modal-rv'">
  <form [formGroup]="form">
    <div class="row">
      <div class="form-group col-sm-3">
        <label>Horário</label>
        <app-time [disabled]="loading['geral']" formControlName="periodo" [parseToInt]="true" (change)="hourChanged()"></app-time>
      </div>
      <div class="form-group col-sm-9" rem5 combobox [type]="'cliente'" appendTo="#modal-rv"
        [(loading)]="loading" [disabled]="loading['geral']" [(form)]="form" formControlName="clienteId" (change)="buscaDadosCliente()">
      </div>
    </div>
    <div class="row align-items-end">
      <div class="form-group col-sm-8">
        <label>Nova data</label>
        <app-data [disabled]="loading['geral']" [loading]="loading['date']" formControlName="novaData" (change)="novaDataChanged()"></app-data>
      </div>
      <div class="form-group col-sm-4">
        <button type="button" [disabled]="!availableDate || loading['geral'] || this.loading['date']" class="btn btn-info w-100" (click)="adicionar()">
          <app-loading [loading]="loading['newDate']" texto="Agendar" textoLoading=" Agendar" icone="fa fa-plus"></app-loading>
        </button>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-sm-12">
      <app-dim-datatable [loading]="loading['geral']" [columns]="colunas" [data]="ocupacoes" [showPagination]="false" [acoes]="true">
        <ng-template #buttonsMaxTmpl let-row="row" let-index="index">
          <button type="button" [disabled]="loading['geral']" (click)="remover(row.id, index)" class="btn btn-danger btn-mini waves-effect waves-light" placement="top"
            ngbTooltip="Excluir">
            <i class="ti ti-trash"> </i>
          </button>
        </ng-template>
      </app-dim-datatable>
    </div>
  </div>
</app-temp-modal-cadastro>
