<app-temp-modal-cadastro #modalConsultar [textHeader]="'Acessos Usuário'">

    <div class="row">
        <div class="col-sm-12 form-group col-12">
            <label><b>Filtro:</b></label>
            <input type="text" class="form-control" [(ngModel)]="filtro" placeholder="Search"
                (ngModelChange)="filterAcessos()">
        </div>
    </div>

    <div class="app-modal-body maxHeigth">
        <table class="table">
            <thead>
                <td> <b> Tela </b> </td>
                <td style="text-align: center;"> <b> Acesso </b> </td>
            </thead>
            <tbody>
                <tr *ngFor="let acesso of acessosFilter">
                    <td>{{ acesso.nome }}</td>
                    <td style="text-align: center;" id="{{getTipoAcesso(acesso.acesso)}}">
                        {{ getTipoAcesso(acesso.acesso) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</app-temp-modal-cadastro>