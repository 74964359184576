<app-modal-basic #modalRelatorio [modalContentId]="'modal-rv'" (closed)="close()">
  <div class="app-modal-header">
    <h4 class="modal-title">Impressão relatório de contas a pagar</h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="form" (ngSubmit)="imprimir()">
      <div class="row">
        <div class="form-group col-sm-12">
          <label class="label-required">Tipo de relatório:</label>
          <ng-select [clearable]="false" appendTo="#modal-rv" placeholder="Selecione" [items]="tiposRelatorio"
            bindLabel="label" bindValue="id" formControlName="tipoRelatorio">
          </ng-select>
          <show-errors [control]="form.controls.tiposRelatorio"></show-errors>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 form-group" style="margin-bottom: unset;">
          <label>Orientação:</label>
        </div>
      </div>
      <div class="checkbox-space">
        <div class="row">
          <div class="form-radio col-sm-12">
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="orientacao" [value]="false">
                <i class="helper"></i>Paisagem
              </label>
            </div>
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="orientacao" [value]="true">
                <i class="helper"></i>Retrato
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 form-group" style="margin-bottom: unset;">
          <label>Modo:</label>
        </div>
      </div>
      <div class="checkbox-space">
        <div class="row">
          <div class="form-radio col-sm-12">
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="modoDetalhado" [value]="false">
                <i class="helper"></i>Simplificado
              </label>
            </div>
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="modoDetalhado" [value]="true">
                <i class="helper"></i>Detalhado
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 form-group" style="margin-bottom: unset;">
          <label>Formato:</label>
        </div>
      </div>
      <div class="checkbox-space">
        <div class="row">
          <div class="form-radio col-sm-12">
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="formato" [value]="false">
                <i class="helper"></i>PDF
              </label>
            </div>
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="formato" [value]="true">
                <i class="helper"></i>XLSX
              </label>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary"><i class="icofont icofont-print"> </i> Imprimir</button>
      <button type="button" class="btn btn-danger" (click)="modalRelatorio.hide()">
        <i class="ti ti-close"></i> Cancelar
      </button>
    </form>
  </div>
</app-modal-basic>