import { FormControl } from "@angular/forms";
import { TiposMovimentacoesEnum } from "./MovimentacoesVendedores";

export interface CadastroMovimentacoesVendedoresViewModel {
  id: number;
  tipo: TiposMovimentacoesEnum;
  data: Date;
  valor: number;
  representanteId: number;
  observacao: string;
}

export type CadastroMovimentacoesVendedoresForm = {
  id: FormControl<number>;
  tipo: FormControl<TiposMovimentacoesEnum>;
  data: FormControl<Date>;
  valor: FormControl<number>;
  representanteId: FormControl<number>;
  observacao: FormControl<string>;
}
