import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { PedidosService } from '../../../services/pedidos.service';
import { setFormValue } from '../../../general/utils';
import swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-pedido',
  templateUrl: './pedido.component.html',
  styleUrls: ['./pedido.component.css']
})
export class PedidoComponent extends ModalCadastroComponent {

  @ViewChild('modalConsultar', { static: true }) modalConsultar;
  pedido;

  constructor(private pedidosService: PedidosService) {
    super();
  }

  form = new FormGroup({
    numeroPedido: new FormControl(null)
  });

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalConsultar.show();
  }

  carregaDados() {
    if (this.id) {
      this.alteraLoading(true);
      this.pedidosService.getCadastroPedidoById(this.id)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe((res) => {
          setFormValue(this.form, res);
          this.pedido = res;
        });
    }
  }

  destroyComponent() {
    this.closed.emit();
  }

  imprimirPedido(pedido) {
    this.loading[`impressaoPedido${pedido.id}`] = true;
    this.pedidosService.imprimirPedido(pedido.id)
      .pipe(finalize(() =>  this.loading[`impressaoPedido${pedido.id}`] = false))
      .subscribe(res => {
        if (res && (res as Blob).type.includes('pdf')) {
          saveAs(res, `pedido_${pedido.numeroPedido}.pdf`);
          this.destroyComponent();
        }
      }, (error) => {
        swal("Erro ao emitir o relatório do Pedido", /*error.errors[0]*/ "Pedido não encontrado.", "error");
        this.destroyComponent();
      })
  }
}
