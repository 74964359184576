import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ParamConsulta } from '../../general/models/paramConsulta';

@Component({
  selector: 'app-dim-bintable',
  templateUrl: './dim-bintable.component.html',
  styleUrls: ['./dim-bintable.component.css']
})
export class DimBintableComponent implements OnInit {

  @Input() data: any[];
  @Output() pageChanged: EventEmitter<ParamConsulta> = new EventEmitter<ParamConsulta>();
  @Input() paramConsulta: ParamConsulta = new ParamConsulta();
  @Input() currentPage = 1;
  @Input() totalItems: number;
  @Input() route: any;
  @Input() loading = false;

  ngOnInit() {

  }

  changePage(event) {
    this.currentPage = event;
    this.paramConsulta.index = event;
    this.pageChanged.emit(this.paramConsulta);
  }

  getData() {
    return this.data;
  }

  getDesciption(row) {
    return row.unidadeConsumo ?? row.cliente.razaoSocial;
  }
}
