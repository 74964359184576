<form [formGroup]="formGroup">
  <span #dimDataRangeLabel>
    <ng-content select="label"></ng-content>
  </span>
  <ng-container *ngIf="!dimDataRangeLabel.hasChildNodes()">Período</ng-container>
  <div class="input-group">
    <app-data calendarPosition="left" [formControlName]="controlNameMin" [disabled]="disabled" [maxDate]="maxDate" (change)="onDateChange($event)"></app-data>
    <span class="input-group-addon">até</span>
    <app-data [formControlName]="controlNameMax" [disabled]="disabled" [maxDate]="maxDate" (change)="onDateChange($event)"></app-data>
  </div>
  <app-dim-error-message [formGroup]="formGroup"></app-dim-error-message>
</form>
