import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class EstadosService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get() {
    return this.http.get(this.UrlService + 'estados')
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getByUf(uf: any) {
    return this.http.get(this.UrlService + 'estados/' + uf)
      .pipe(map((res: any) => res.data));
  }

  getByUfID(ufid: any) {
    return this.http.get(this.UrlService + 'estados/GetUFID/' + ufid)
      .pipe(map((res: any) => res.data));
  }

  getDateFuso() {
    return this.http.get(this.UrlService + 'estados/fusoHorario')
      .pipe(map((res: any) => res.data));
  }
}
