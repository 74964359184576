import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { ParametrosSistemaService } from '../../../services/parametros-sistema.service';
import { TitulosCobrancaService } from '../../../services/titulos-cobranca.service';
import { DescontosFormaPagamento } from '../../../model/descontosFormaPagamento';
import { ParametrosSistema } from '../../../model/ParametrosSistema';
import { MessageService } from '../../../services/message.service';
import { UnidadesEmpresaService } from '../../../services/unidades-empresa.service';
import { ConfiguracoesUsuarioService } from '../../../services/configuracoes-usuario.service';

@Component({
  selector: 'app-aviso-faturas',
  templateUrl: './aviso-faturas.component.html',
  styleUrls: ['./aviso-faturas.component.css']
})
export class AvisoFaturasComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal;

  titulos: any[];
  bloqueio: any;
  parametrosSistema: ParametrosSistema;
  descontosFormaPagamento: DescontosFormaPagamento[];
  naoExibirNovamente = false;
  dadosEmpresa: any;

  constructor(
    private router: Router,
    private unidadesEmpresaService: UnidadesEmpresaService,
    private parametrosSistemaService: ParametrosSistemaService,
    private titulosCobrancaService: TitulosCobrancaService,
    private messageService: MessageService,
    private configuracoesUsuarioService: ConfiguracoesUsuarioService,
  ) {
    super();
  }

  async ngOnInit() {
    this.loading['tela'] = true;
    this.titulos = this.prop.faturas;

    try {
      this.dadosEmpresa = await this.unidadesEmpresaService.getUnidadeEmpresa().toPromise();
      this.bloqueio = await this.unidadesEmpresaService.verificaBloqueio().toPromise();
      this.parametrosSistema = await this.parametrosSistemaService.get().toPromise();
      this.descontosFormaPagamento = await this.titulosCobrancaService.getDescontosFormaPagamento().toPromise();
    } catch (err) {
      console.error(err);
      this.messageService.error("Falha ao buscar parâmetros do sistema.", 10000);
    }
    this.loading['tela'] = false;
  }

  show() {
    this.modal.show();
  }

  fechar() {
    if (!this.faturaVencida && this.naoExibirNovamente) {
      this.configuracoesUsuarioService.naoExibirAvisoFaturasEmAberto().subscribe();
    }
    if (this.faturaVencida && !this.sistemaBloqueado) {
      this.configuracoesUsuarioService.naoExibirAvisoFaturasVencidas().subscribe();
    }

    this.closed.emit();
  }

  abrirPortal() {
    this.router.navigate(['/gerenciamento/portal-do-cliente']);
    this.fechar();
  }

  get faturaVencida() {
    return this.titulos && this.titulos.some(x => x.vencido);
  }

  get sistemaBloqueado() {
    if (!this.bloqueio || !this.parametrosSistema)
      return false;

    return this.bloqueio.sistemaBloqueado;
  }

  get diasAteBloqueio() {
    if (!this.bloqueio || !this.parametrosSistema)
      return false;

    return this.parametrosSistema.diasAtrasoToleranciaBloqueio - this.bloqueio.diasBloqueioSistema;
  }

  get infoDescontoPix() {
    if (!this.descontosFormaPagamento)
      return '';

    const forma = this.descontosFormaPagamento.find(x => x.formaPagamentoMensalidade === 'P');
    if (!forma || forma.descontoMensalidade <= 0)
      return '';

    return forma.valorPercentual === 'V'
      ? `R$ ${forma.descontoMensalidade.toFixed(2)}`
      : `${forma.descontoMensalidade.toFixed(2)}%`
  }
}
