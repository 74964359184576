<app-temp-modal-cadastro #modalEditarUsuario [textHeader]="'Dados Usuário'" (closed)="onClose()">
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">

    <div class="row">
      <div class="col-sm-6 form-group col-6">
        <label><b>Código</b></label><br />
        <label>{{cadastroForm.controls['id'].value}}</label>
      </div>
      <div class="col-sm-6 form-group col-6">
        <label><b>Nome</b></label><br />
        <label>{{cadastroForm.controls['nome'].value}}</label>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-9 form-group col-9">
        <label><b>E-mail</b></label><br />
        <label>{{cadastroForm.controls['email'].value}}</label>
      </div>

      <div class="col-sm-3 form-group col-3">
        <label><b>Adm</b></label><br />
        <div class="checkbox-fade fade-in-default">
          <label>
            <input type="checkbox" formControlName="administrador">
            <span class="cr">
              <i class="cr-icon icofont icofont-ui-check txt-default"></i>
            </span>
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 form-group col-6">
        <label><b>Login</b></label>
        <input type="text" class="form-control" formControlName="login"
          (ngModelChange)="this.inputOnSearch.next($event); this.disableSave = true" #user>
        <span *ngIf="loginInvalido" class="text-danger error">Login já está em uso!</span>
        <show-errors [control]="cadastroForm.controls.login"></show-errors>
      </div>

      <div class="col-sm-6 form-group col-6">
        <label><b>Senha</b></label>
        <input type="text" class="form-control" formControlName="senha">
        <show-errors [control]="cadastroForm.controls.senha"></show-errors>
      </div>
    </div>

    <button [disabled]='loading["registro"]' type="button" class="btn btn-primary ripple" (click)="alteraEditar()"
      *ngIf="permiteEditar && !emEdicao"><i class="ti-pencil-alt"></i> Editar
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-info ripple"
      (click)="modalEditarUsuario.hide();" *ngIf="!emEdicao"><i class="ti-back-left"></i> Voltar
    </button>
    <button type="button" class="btn btn-info ripple" (click)="componentAcessosUsuario.instance.show(id)"
      *ngIf="!emEdicao"><i class="ti-menu-alt"></i> Acessos
    </button>

    <button [disabled]='loading["registro"] || disableSave' type="submit" (click)="validaForm()"
      class="btn btn-primary ripple light" *ngIf="emEdicao">
      <app-loading [texto]="'Salvar'" [loading]="loading['registro']" [icone]="'ti ti-save'"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-danger ripple" (click)="cancelaEdicao()"
      *ngIf="emEdicao"><i class="ti ti-close"></i> Cancelar
    </button>

  </form>
</app-temp-modal-cadastro>

<template #container></template>