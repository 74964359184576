import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseServiceV2 } from '@servicesv2';

import { UnidadeEmpresaParametrosGeraisViewModel } from 'models/unidade-empresa-parametros/UnidadeEmpresaParametrosViewModel';

@Injectable()
export class UnidadeEmpresaParametrosServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('UnidadeEmpresaParametros', http);
  }

  getParametrosGerais() {
    return this.getAsPromise<UnidadeEmpresaParametrosGeraisViewModel>('parametrosGerais');
  }

}
