import { Component, ElementRef, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-dim-currency-range',
  templateUrl: './dim-currency-range.component.html',
  styleUrls: ['./dim-currency-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DimCurrencyRangeComponent),
      multi: true
    }
  ]
})
export class DimCurrencyRangeComponent implements ControlValueAccessor {

  constructor(public controlContainer: ControlContainer) { }
  private onChanged = (_: any) => { };
  private onTouched = (_: any) => { };
  writeValue(_: any): void { }
  registerOnChange(_: any): void { this.onChanged = _; }
  registerOnTouched(_: any): void { this.onTouched = _; }

  ngOnInit() {
    this.form.setValidators([this.validateMinMaxValue()]);
  }

  @ViewChild('dimDataRangeLabel') dimDataRangeLabel: ElementRef<any>;

  @Input() disabled: boolean = false;
  @Input() controlNameMin: string = 'minimo';
  @Input() controlNameMax: string = 'maximo';
  @Input() validacao: boolean = true;
  @Input() mostrarErro: boolean = true;
  @Input() mensagemErro: string = 'Valor mínimo deve ser menor ou igual ao valor máximo';

  get form() {
    const fg = this.controlContainer.control as FormGroup;
    return fg;
  }

  /**
   * Validador personalizado para verificar se o valor mínimo é maior que ao valor máximo em um FormGroup.
   *
   * @returns {ValidatorFn} Uma função validadora que retorna `ValidationErrors` se a validação falhar, ou `null` se a validação passar.
   *
   * @description
   * Este método privado cria um validador que compara os valores de dois controles específicos em um FormGroup,
   *  identificados pelas propriedades `controlNameMin` e `controlNameMax` do componente.
   *
   * @remarks
   * - A validação só é executada se a propriedade Input `validacao` do componente for verdadeira.
   * - **Importante:** Para garantir que a validação seja reavaliada após mudança na propriedade Input `validacao`,
   *    utilize o método `updateValueAndValidity` do `FormGroup` após a alteração.
   *    Mais informações: https://v17.angular.io/api/forms/AbstractControl#updateValueAndValidity
   */
  validateMinMaxValue(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      if (!this.validacao || !this.controlNameMin || !this.controlNameMax) {
        return;
      }

      const min = group.controls[this.controlNameMin];
      const max = group.controls[this.controlNameMax];

      if (!min || !max) {
        return;
      }

      min.setErrors(null);
      if (min.value > max.value) {
        min.setErrors({ custom: { message: this.mensagemErro }});
      }

      return;
    }
  }
}
