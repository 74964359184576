<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" textHeader="Editar CFOP" modalContentId="modal-editar-cfop-nota-entrada" customClass="modal-lg">
  <div class="row">
    <div class="col-12">
      <div class="alert alert-info background-info w-100">
        <p class="mb-0">A <strong>Edição de CFOP</strong> pode ser usada quando houver uma Carta de Correção alterando o CFOP dos itens
          da Nota de Entrada. <br />
          Ao <strong>Salvar</strong>, todos os itens da nota de entrada possuindo o <strong>CFOP Entrada</strong> serão
          ajustados para o <strong>Novo CFOP</strong> selecionado. <br />
          Essa alteração não é visível na impressão da Nota de Entrada.
        </p>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col">
      <p><strong>Fornecedor:</strong> {{ fornecedorCnpj }} - {{ fornecedorNome }}</p>
      <p><strong>Nota de Entrada:</strong> {{ numeroNotaFiscalEntrada }}</p>
    </div>
  </div>
  <ng-container *ngIf="loading['cfop']">
    <div class="row flex justify-content-center mt-2">
      <i class="fa fa-circle-o-notch fa-spin fa-lg"></i>
    </div>
  </ng-container>
  <div class="row" *ngFor="let cfopEntrada of dados.cfopsNotaEntrada">
    <div class="col-6">
      <div class="form-group">
        <label class="">CFOP Entrada</label>
        <input type="text" class="form-control" readonly [value]="cfopEntrada.codigo + ' - ' + cfopEntrada.descricao"
          [ngbTooltip]="cfopEntrada.codigo + ' - ' + cfopEntrada.descricao">
      </div>
    </div>
    <div class="col-6">
      <div class="form-group">
        <label>Novo CFOP</label>
        <ng-select placeholder="Não alterar" appendTo="#modal-editar-cfop-nota-entrada"
          [items]="dados.cfopsDisponiveis" bindValue="codigo" [searchFn]="buscaCfop"
          [(ngModel)]="cfopEntrada.novoCodigo" [loading]="loading['cfop']"
          [disabled]="loading['cfop'] || loading['salvar']" >
          <ng-template ng-label-tmp ng-option-tmp let-item="item">
            <span container="body" [ngbTooltip]="item.codigo + ' - ' + item.descricao">{{item.codigo}} - {{item.descricao}}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <button type="submit" (click)="salvar()" class="btn btn-primary ripple light" [disabled]="loading['cfop'] || loading['salvar'] || !alterouCfop">
        <app-loading [loading]="loading['salvar']" texto="Salvar" textoLoading="Salvando" icone="icofont icofont-save"></app-loading>
      </button>
      <button type="button" class="btn btn-danger ripple" (click)="modalCadastro.hide()" [disabled]="loading['salvar']">
        <i class="ti ti-close"></i> Cancelar
      </button>
    </div>
  </div>
</app-temp-modal-cadastro>