import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { MessageService } from '@services';
import { UnidadesMedidaServiceV2 } from '@servicesv2';
import { ComboboxPadrao } from 'models/combobox-padrao/combobox-padrao';
import { UnidadesMedidaViewModel } from 'models/unidades-medida/UnidadesMedidaViewModel';

@Component({
  selector: 'app-combobox-unidades-medida',
  templateUrl: './combobox-unidades-medida.component.html',
  styleUrls: ['./combobox-unidades-medida.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxUnidadesMedidaComponent),
      multi: true
    }
  ]
})
export class ComboboxUnidadesMedidaComponent extends ComboboxPadrao<UnidadesMedidaViewModel> implements ControlValueAccessor {
  override bindLabel = 'descricao';
  @Input() override label = 'Unidade de Medida';
  @Input() override required = false;
  @Input() override requiredText = 'A unidade de medida é obrigatória';

  @Input() loading = false;
  @Input() disabled = false;
  @Input() notFoundPlusIcon = false;
  @Input() pencilIcon = false;
  @Output() changed = new EventEmitter();

  constructor(
    service: UnidadesMedidaServiceV2,
    message: MessageService,
  ) {
    super('combobox_unidade_medida', message, service);
  }

  writeValue(value: number): void {
    this.selected.setValue(value);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  handleOnChanged(value: number) {
    this.selected.setValue(value);
    this.changed.emit(value);
    this.onChange(value);
  }

  handleOnAdded(value: number) {
    this.selected.setValue(value);
    this.changed.emit(value);
    this.onChange(value);
  }

  handleOnEdited(value: number) {
    this.selected.setValue(value);
    this.changed.emit(value);
    this.onChange(value);
  }
}
