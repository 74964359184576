import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class UsuariosService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  fetch() {
    return this.http.get(this.UrlService + 'account')
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  fetchId(id: number) {
    return this.http.get(this.UrlService + `account/${id}/${true}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  fetchCurrentUser() {
    return this.http.get(this.UrlService + `account/currentUser`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  fetchIdDimensao(id: number) {
    return this.http.get(this.UrlService + `account/${id}/${false}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  updateGrupo(id: number, usuario: any) {
    return this.http.put(this.UrlService + `account/grupo/${id}`, JSON.stringify(usuario))
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  alterarSenha(id: number, usuario: any) {
    return this.http.put(this.UrlService + `account/password/${id}`, JSON.stringify(usuario))
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  updateUser(id: number, usuario: any) {
    return this.http.put(this.UrlService + `account/update/${id}`, JSON.stringify(usuario))
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  alteraLogin(usuario: any) {
    return this.http.put(this.UrlService + `account/alteraLogin/`, JSON.stringify(usuario))
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  createPreCadastro(pre: any) {
    return this.http.post(`${this.UrlService}PreCadastroUsuario/create`, JSON.stringify(pre))
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getPreCadastro(guid: any) {
    return this.http.get(`${this.UrlService}PreCadastroUsuario/get/${guid}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  create(form: any) {
    return this.http.post(`${this.UrlService}Account/create/`, JSON.stringify(form))
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getTelasLiberadas(grupoUsuarioId) {
    return this.http.get(this.UrlService + 'Acessos/grupo/liberadas/' + grupoUsuarioId)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getTelasBloqueadas(grupoUsuarioId) {
    return this.http.get(this.UrlService + 'Acessos/grupo/bloquadas/' + grupoUsuarioId)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getAcessoTela(telasId, grupoUsuariosId) {
    return this.http.get(this.UrlService + `Acessos/acessoTelas/${telasId}/${grupoUsuariosId}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getUsuariosPorUnidadeEmpresa(form: any) {
    return this.http.post(this.UrlService + 'account/buscaUsuariosPorEmpresa/', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  verificaSenha(senha) {
    return this.http.get(this.UrlService + 'account/verificaSenha/' + senha)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  verificaLogin(userId, login) {
    return this.http.get(this.UrlService + 'account/verificaLogin/' + login + '/' + userId)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + `account/usuariosVendedoresPaginado?pageIndex=` + pagina + '&search=' + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
