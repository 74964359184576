import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { getErrorMessage, setFieldFormValue, setFormValue } from '@utils';
import { lastValueFrom } from 'rxjs';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { UnidadesConsumoService } from '../../../services/unidades-consumo.service';
import { MessageService } from '@services';

@Component({
  selector: 'app-modal-cadastro-unidades-consumo',
  templateUrl: './modal-cadastro-unidades-consumo.component.html',
  styleUrls: ['./modal-cadastro-unidades-consumo.component.css']
})
export class ModalCadastroUnidadesConsumoComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalCadastro", { static: true }) modalCadastro;

  constructor(private unidadesConsumoService: UnidadesConsumoService,
    private messageService: MessageService
  ) {
    super();
  }
  cadastroForm = new FormGroup({
    id: new FormControl(0),
    descricao: new FormControl(null, [Validators.required]),
  });

  ngOnInit() {
  }

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    setFieldFormValue(this.cadastroForm, 'descricao', searchParam);
    this.modalCadastro.show();
  }

  async carregaDados() {
    if (!this.id) {
      return;
    }

    try {
      this.alteraLoading(true);
      const res = await lastValueFrom(this.unidadesConsumoService.getById(this.id));
      setFormValue(this.cadastroForm, res);
    } catch(err) {
      this.messageService.error('Falha ao buscar dados da Unidade de Consumo' + getErrorMessage(err), 8000);
    } finally {
      this.alteraLoading(false);
    }
  }

  async submitForm() {
    if (this.cadastroForm.invalid) {
      return;
    }

    try {
      this.alteraLoading(true);
      const res = await lastValueFrom(this.unidadesConsumoService.salvarUnidadesConsumo(this.cadastroForm.value));
      this.id = res?.generatedId;
      this.modalCadastro.hide();
    } catch(err) {
      this.messageService.error('Falha ao salvar: ' + getErrorMessage(err), 8000);
    } finally {
      this.alteraLoading(false);
    }
  }
}