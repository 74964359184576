import { Component, OnInit, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.css'],
  animations: [
    trigger('smoothCollapse', [
      state('collapsed', style({
        height: '0',
        overflow: 'hidden',
        opacity: '0'
      })),
      state('opened', style({
        overflow: 'hidden',
        opacity: '1'
      })),
      transition('collapsed=>opened', animate('200ms')),
      transition('opened=>collapsed', animate('200ms'))
    ]),
  ]
})
export class CollapseComponent implements OnInit {

  isCollapsed = true;

  @Input() header: string;
  @Input() openOnStart: boolean;

  constructor() { }

  ngOnInit() {
    if (this.openOnStart)
      this.isCollapsed = false;
  }

}
