<div [ngStyle]="containerStyle" class="row m-0">
  <div class="datatable-loading" *ngIf="loading">
    <div class="datatable-loader"></div>
  </div>
  <table class="col-md-12 table table-bordered table-striped" >
    <thead>
      <th *ngIf="selectable" class="customCheckbox">
        <div *ngIf="multiple" class="checkbox-fade fade-in-default">
          <label>
            <input [ngModel]="allSelected" (ngModelChange)="selectAll($event)" type="checkbox">
            <span class="cr">
              <i class="cr-icon icofont icofont-ui-check txt-default"></i>
            </span>
          </label>
        </div>
      </th>
      <th class="hidden" *ngIf="showInfoMobile"> # </th>
      <th *ngFor="let column of columns" (click)="sorter(column)" [ngClass]="{'hide': column.hide, 'invisible': column.invisible, 'centeredText': centeredText}"
        data-toggle="collapse" data-target="#collapse">
        {{ column.label }}
        <i *ngIf="sort.prop === column.col && sort.dir === 'asc'" class="ti ti-arrow-up"></i>
        <i *ngIf="sort.prop === column.col && sort.dir === 'desc'" class="ti ti-arrow-down">
        </i>
      </th>
      <th [ngClass]="{'hide' : !showAcoesMobile}" *ngIf="route || acoes" style="width: 1px;">
        {{acoesCustomName || 'Ações'}}</th>
    </thead>
    <tbody>
      <tr *ngFor="let row of getData(); let i = index" class="table-row" [ngClass]="{pressed: (row === pressedRow), hovered: contextable}" [ngStyle]="getLyneStyle(row)" (mouseup)="mouseUnpressHandle()" (mousedown)="mousePressHandle(row, $event)" (contextmenu)="openContext($event, row)">
        <ng-container (contextmenu)="$event.stopPropagation()">
          <td *ngIf="selectable" class="customCheckbox">
            <div class="checkbox-fade fade-in-default">
              <label>
                <input (change)="onSelectFn(row)" [ngModel]="selecionado(row.id)" type="checkbox" (click)="selecao(row)">
                <span class="cr">
                <i class="cr-icon icofont icofont-ui-check txt-default"></i>
              </span>
              </label>
            </div>
          </td>
          <td class="hidden" *ngIf="showInfoMobile">
            <button type="button" class="btn-mini btn-info radio" (click)="expand($event, row)">
              <i class="ti ti-plus"></i>
            </button>
          </td>
          <td *ngFor='let column of columns' [ngbTooltip]="colunaTooltip(row, column)" container="body" [class]="column.class" nowrap [ngClass]="{'hide': column.hide, 'invisible': column.invisible}"
              [ngStyle]="colunaStyle(row, column)"  (click)="rowClick(row)">

            <ng-container *ngIf="customTmpl != null && column.customTmpl; else default">
              <ng-template *ngTemplateOutlet="customTmpl; context: { row: row, column: column.col, index: i }"></ng-template>
            </ng-container>

            <ng-template #default>
              <div [ngStyle]="column.style" *ngIf="!column.type" ngbTooltip="{{retornaValor(row, column, true)}}"
                   placement="right" class="input-group" [ngClass]="{centeredText: centeredText}" ngxClipboard [cbContent]="getClipboardValue(row, column)"
                   (cbOnSuccess)="successClipboard($event)"
                   [class.text-oneline]="column.nowrap === true" [class.text-overflow]="column.nowrap !== true">
                {{column.date ? (retornaValor(row, column) | date:'dd/MM/yyyy')
                : column.dateTime ? (retornaValor(row, column) | date:'dd/MM/yyyy HH:mm')
                  : column.currency ? (retornaValor(row, column) | currency:'BRL':'symbol': isPrice ? pipeDecimal : '1.2-2')
                    : column.getText ? getTextOfMethod(row, column)
                      : retornaValor(row, column)}}
              </div>

              <div [ngStyle]="column.style" *ngIf="column.type == 'button'">
                <button class="btn btn-info btn-mini transparent-background"
                        (click)="buttonClick(retornaValor(row, column))">
                  <i [ngClass]="column.contentClass" *ngIf="column.contentClass"> </i>
                </button>
              </div>
            </ng-template>

          </td>

          <td class="button-wrapper" *ngIf="route || acoes" [ngClass]="{'hide': !showAcoesMobile}"
              style="width: auto; text-align: center;" (contextmenu)="$event.stopPropagation()">
            <button *ngIf="showDetails(row) && route" class="btn btn-primary btn-mini waves-effect waves-light"
                    placement="left" ngbTooltip="Detalhar" [routerLink]="[route, row.id]">
              <i class="icofont icofont-info"> </i>
            </button>

            <ng-template *ngTemplateOutlet="buttonsMaxTmpl; context: {row: row, index: i }"></ng-template>

          </td>
        </ng-container>
      </tr>
    </tbody>
  </table>
    <app-dim-pagination *ngIf="showPagination && totalItems > rowsOnPage" [offset]="1" [currentPage]="currentPage" 
      [registersPage]="rowsOnPage"
      [totalRegisters]="totalItems" (pageChange)="changePage($event)">
    </app-dim-pagination>
</div>

<app-modal-basic #modal [showNow]="isExpanded" [dialogClass]="'modal-md'" *ngIf="itemExpanded"
  (closed)="collapse($event)">
  <div class="app-modal-header">
    <h4 class="modal-title"> Detalhes </h4>
    <button type="button" class="close basic-close" (click)="collapse($event)">
      <span class="btn-close" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <div class="col-md-12 col-sm-12">
      <ul>
        <li *ngFor="let column of columns" class="col-md-6 pull-left">
          <h5> {{ column.label }} </h5>
          <p>
            <ng-container *ngIf="customTmpl != null && column.customTmpl; else default">
              <ng-template *ngTemplateOutlet="customTmpl; context: { row: itemExpanded, column: column.col }">
              </ng-template>
            </ng-container>

            <ng-template #default>
              {{column.date ? (retornaValor(itemExpanded, column) | date:'dd-MM-yyyy')
            : column.currency ? (retornaValor(itemExpanded, column) | currency:'BRL':'symbol')
            : retornaValor(itemExpanded, column)}}
            </ng-template>
          </p>
        </li>
        <li *ngIf="route || acoes" class='button-wrapper' style="width: 100%">
          <button *ngIf="showDetails(itemExpanded) && route" class="btn btn-primary waves-effect waves-light"
            [routerLink]="[route, itemExpanded?.id]">
            <i class="icofont icofont-info"> </i> Detalhes
          </button>

          <ng-template *ngTemplateOutlet="buttonMinTmpl; context: {row: itemExpanded }"></ng-template>
        </li>
      </ul>
    </div>
  </div>
  <div class="app-modal-footer">
  </div>
</app-modal-basic>
