import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { getErrorMessage, mergeArray, mergeArrays, setFormValue } from '../../../../general/utils';
import { ClientesService } from '../../../../services/clientes.service';
import { DownloadExportacaoService } from '../../../../services/downloadExportacaoController.service';
import { UnidadesEmpresaService } from '../../../../services/unidades-empresa.service';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-exportar-nfce',
  templateUrl: './exportar-nfce.component.html',
  styleUrls: ['./exportar-nfce.component.css']
})
export class ExportarNfceComponent extends ModalCadastroComponent implements OnInit {

  constructor(
    private downloadExportacaoService: DownloadExportacaoService,
    private unidadesEmpresaService: UnidadesEmpresaService,
    private clientesService: ClientesService
  ) {
    super();
  }

  @Input() form: FormGroup;
  @ViewChild('modal', { static: true }) modal;
  @ViewChild('selecaoColunasPDF', { static: true }) selecaoColunasPDF: ElementRef;
  @ViewChild('inputDestinatarios') inputDestinatarios: ElementRef;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  formComplemetar: FormGroup;
  dadosEmpresa;
  loading = [];
  emails: Array<string> = [];
  clienteInput = new Subject<string>();
  clientes: any = [];

  statusOptions: NFCeStatusOption[] =
    [
      { id: 'A', name: 'Aprovada', },
      { id: 'C', name: 'Cancelada' },
      { id: 'I', name: 'Inutilizada' },
      { id: 'D', name: 'Denegada' },
    ];

  // FIXME Pendência técnica. Migrar para um componente de checkboxes.
  impressaoNFCe: Coluna[] = [
    { label: 'Numero', value: 'numero', selected: true, category: 'nfce' },
    { label: 'Status', value: 'status', selected: true, category: 'nfce' },
    { label: 'Série', value: 'serie', selected: true, category: 'nfce' },
    { label: 'Emissão', value: 'emissao', selected: true, category: 'nfce' },
    { label: 'Cliente', value: 'cliente', selected: true, category: 'cliente' },
    { label: 'Valor da NFC-e', value: 'valor', selected: true, category: 'nfce' },
    { label: 'Valor Total', value: 'valorTotal', selected: true, category: 'nfce' },
  ]

  ngOnInit() {

    this.form = new FormGroup({
      emissao1: new FormControl(''),
      emissao2: new FormControl(''),
      cliente: new FormControl(''),
      status: new FormControl(['A', 'C', 'I', 'D']),
      destinatarios: new FormControl(''),
      arquivoUnico: new FormControl(false),
      arquivoZip: new FormControl(false),
      arquivoPdf: new FormControl(false),
      arquivoExcel: new FormControl(false),
      arquivoPdfColunas: new FormGroup({
        numero: new FormControl(true),
        status: new FormControl(true),
        serie: new FormControl(true),
        emissao: new FormControl(true),
        cliente: new FormControl(true),
        valor: new FormControl(true),
        valorTotal: new FormControl(true),
      }),
      notasEntrada: new FormControl(true),
      paramConsulta: new FormControl(null),
    });

    this.form.setValidators([this.validaTipoArquivoExportacao()]);
    this.formArquivoPdfColunas.setValidators([this.validaColunasSelecionadasExportacao()]);

    if (this.prop != null) {
      if (this.prop.status.length >= 1) {
        const statusDisponiveis: string[] = this.statusOptions.map((status: NFCeStatusOption) => status.id);
        const statusFiltrados: string[] = this.prop.status.filter((status: string) => statusDisponiveis.includes(status));
        this.prop.status = statusFiltrados;
      }
      setFormValue(this.form, this.prop);
    }

    this.setDownloadFileTypesCheckboxes();
    this.carregaDestinatarios();
    this.carregaClientes();

    this.onSearchCliente();
  }

  public validaTipoArquivoExportacao(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const { arquivoPdf: pdf, arquivoExcel: excel, arquivoZip: zip, arquivoUnico: unico } = group.controls;

      if (![pdf.value, excel.value, zip.value, unico.value].includes(true)) {
        pdf.setErrors({ custom: { message: "Selecione um tipo de arquivo." } });
      } else {
        pdf.setErrors(null);
      }
      return;
    }
  }

  public validaColunasSelecionadasExportacao(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const { numero, status, serie, emissao, cliente, valor } = group.controls;

      const selecionados = [numero, status, serie, emissao, cliente, valor].filter(coluna => coluna.value);
      if (selecionados.length <= 1) {
        numero.setErrors({ custom: { message: "Selecione pelo menos duas colunas." } });
      } else {
        numero.setErrors(null);
      }

      return;
    }
  }

  public validaDestinatariosEmail(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const { destinatarios } = group.controls;

      if (destinatarios.value.length == 0) {
        destinatarios.setErrors({ custom: { message: "Informe pelo menos um destinatário" }});
      } else {
        destinatarios.setErrors(null);
      }

      return;
    }
  }

  get arquivoPdf() {
    return this.form.get('arquivoPdf');
  }

  get arquivoExcel() {
    return this.form.get('arquivoExcel');
  }

  get arquivoZip() {
    return this.form.get('arquivoZip');
  }

  get arquivoUnico() {
    return this.form.get('arquivoUnico');
  }

  get formArquivoPdfColunas() {
    return this.form.get('arquivoPdfColunas');
  }

  get tipoImpressao(): string | undefined {
    if (this.arquivoPdf.value) {
      return "pdf";
    } else if (this.arquivoExcel.value) {
      return "xlsx";
    } else if (this.arquivoUnico.value) {
      return "unico";
    } else if (this.arquivoZip.value) {
      return "zip";
    }
  }

  carregaDestinatarios() {
    this.unidadesEmpresaService.getUnidadeEmpresa()
      .subscribe({
        next: (response: any) => {
          if (!response) {
            return;
          }
          const delimitadores: RegExp = /[;, ]+/;

          const emailDim: string = response.email;
          const emailContabilidade: string = response.contabilidade?.email;

          let arrayEmailsDim: string[] = emailDim?.split(delimitadores) || [];
          let arrayEmailsContabilidade: string[] = emailContabilidade?.split(delimitadores) || [];

          this.emails = mergeArrays([arrayEmailsDim, arrayEmailsContabilidade]);

          if (this.emails.length) {
            this.form.get('destinatarios').setValue(this.emails);
          }
        }
      });
  }

  carregaClientes() {
    this.loading['clientes'] = true;
    this.clientesService.getClientesPaginado(1, '')
      .subscribe({
        next: (data: any) => {
          this.clientes = data;
        },
        complete: () => {
          this.loading['clientes'] = false
        }
      });
  }

  onSearchCliente() {
    this.clienteInput.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => this.loading['clientes'] = true),
      switchMap(term => this.clientesService.getClientesPaginado(1, term || '').pipe(
        tap(() => this.loading['clientes'] = false)
      ))
    )
      .subscribe(data => {
        this.clientes = data;
      });
  }

  fetchMoreClientes(term) {
    this.loading['clientes'] = true;
    this.clientesService.getClientesPaginado(((this.clientes.length / 20) + 1), term || '')
      .pipe(finalize(() => this.loading['clientes'] = false))
      .subscribe(result => {
        this.clientes = mergeArray(this.clientes, result);
      });
  }

  download() {
    this.form.setValidators([this.validaTipoArquivoExportacao()]);
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      return;
    }

    this.loading['download'] = true;

    this.downloadExportacaoService.exportarNfcs(this.form.value)
      .subscribe({
        next: (response: Blob | undefined) => {
          if (response && response.size) {
            const [ _, typeSplit ] = response.type.split("/");
            const type = typeSplit.indexOf(".") == -1 ? typeSplit : typeSplit.split(".").pop();
            if (["pdf", "xml", "zip", "sheet"].includes(type)) {
              const fileType = type == "sheet" ? "xlsx" : type;
              saveAs(response, `nfce.${fileType}`);
              Swal('Sucesso', 'Download efetuado, verifique sua pasta de downloads.', 'success');
            } else {
              Swal("Erro", "Um erro ocorreu ao tentar baixar as NFC\'s: Formato de arquivo não suportado.", "error");
            }
          } else {
            Swal("Erro", "Um erro ocorreu ao tentar baixar as NFC\'s: Arquivo não encontrado.", "error");
          }
        },
        error: (error) => {
          Swal("Erro", "Um erro ocorreu ao tentar baixar as NFC\'s: " + getErrorMessage(error), "error");
        },
        complete: () => {
          this.loading['download'] = false;
        }
      });
  }

  email() {
    this.form.setValidators([this.validaTipoArquivoExportacao(), this.validaDestinatariosEmail()]);
    this.form.updateValueAndValidity();
    if (!this.form.valid) {
      return;
    }

    this.loading['email'] = true;

    this.downloadExportacaoService.enviarNfcsEmail(this.form.value)
      .subscribe({
        next: () => {
          Swal('Sucesso', 'E-mail enviado com sucesso!', 'success');
          this.hide();
        },
        error: (error) => {
          Swal('Erro', 'Um erro ocorreu ao tentar enviar as nfc\'s por e-mail: ' + getErrorMessage(error), 'error');
        },
        complete: () => {
          this.loading['email'] = false;
        }
      })
  }
  addEmail(_event: any, bypass = false): void {
    const event = _event as KeyboardEvent;
    const target = _event.target as HTMLInputElement;
    const emailValue = target.value;

    if (emailValue && (bypass || ['Space', 'Slash', 'Enter', 'NumpadEnter'].includes(event.code))) {
      const newEmail = emailValue.replace(';', '').replace(' ', '');
      const regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
      if (newEmail.length !== 0 && regex.exec(newEmail) && !this.emails.includes(newEmail)) {
        this.emails.push(newEmail);
        this.form.controls['destinatarios'].setErrors(null);
        this.form.controls['destinatarios'].setValue(this.emails);
        this.inputDestinatarios.nativeElement.value = "";
      } else {
        this.form.controls['destinatarios'].setErrors({ custom: { message: "Formato de e-mail válido: exemplo@exemplo.com" }});
      }
    }
  }

  deleteEmail(number: number) {
    this.emails.splice(number, 1);
    this.form.controls['destinatarios'].setValue(this.emails);
  }

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
  }

  handleDownloadFileTypeChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const { checked, value } = target; // event target value is on

    if (!checked) {
      this.setDownloadFileTypesCheckboxes();
      setTimeout(() => { // Workaround para executar por último
        this.selecaoColunasPDF.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    } else {
      if (value === "arquivoPdf") {
        this.setDownloadFileTypesCheckboxes()
        setTimeout(() => { // Workaround para executar por último
          this.selecaoColunasPDF.nativeElement.scrollIntoView({ behavior: 'smooth' });
        }, 0);
      } else if (value == "arquivoExcel") {
        this.setDownloadFileTypesCheckboxes({ pdf: false, excel: true, zip: false, unico: false })
      } else if (value === "arquivoZip") {
        this.setDownloadFileTypesCheckboxes({ pdf: false, excel: false, zip: true, unico: false })
      } else if (value === "arquivoUnico") {
        this.setDownloadFileTypesCheckboxes({ pdf: false, excel: false, zip: false, unico: true })
      }
    }
  }

  setDownloadFileTypesCheckboxes(options: DownloadTypeCheckboxes = { pdf: true, excel: false, zip: false, unico: false }): void {
    this.arquivoPdf.setValue(options.pdf);
    this.arquivoExcel.setValue(options.excel);
    this.arquivoZip.setValue(options.zip);
    this.arquivoUnico.setValue(options.unico);
  }

}
interface NFCeStatusOption {
  id: string;
  name: string;
}

interface Coluna {
  value: string;
  selected: boolean;
  label: string;
  category: string;
}

interface DownloadTypeCheckboxes {
  pdf: boolean;
  excel: boolean;
  zip: boolean;
  unico: boolean;
}
