import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { TabelasPrecoService } from '../../../../services/tabelas-preco.service';
import { setFieldFormValue, setFormValue } from '../../../../general/utils';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-tabela-preco-detalhe',
  templateUrl: './tabela-preco-detalhe.component.html',
  styleUrls: ['./tabela-preco-detalhe.component.css']
})
export class TabelaPrecoDetalheComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalCadastro", { static: true }) modalCadastro;
  grupoDespesas = [];
  contas = [];
  id;

  @Output() closed: EventEmitter<any> = new EventEmitter();

  constructor(private tabelasPrecoService: TabelasPrecoService) {
    super();
  }

  cadastroForm = new FormGroup({
    id: new FormControl(0),
    nome: new FormControl(null, [Validators.required]),
  })

  ngOnInit() {
  }

  show() {
    this.modalCadastro.show();
  }

  carregaDados() {
    if (this.id) {
      this.alteraLoading(true);
      this.tabelasPrecoService.getTabelaPrecoById(this.id)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe((res) => {
          setFormValue(this.cadastroForm, res);
        })
    }
  }

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    setFieldFormValue(this.cadastroForm, 'nome', searchParam);
    this.modalCadastro.show();
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      this.alteraLoading(true);
      this.tabelasPrecoService.salvar(this.cadastroForm.value)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe(res => {
          this.id = res.generatedId || null;
          this.modalCadastro.hide();
        }, (error) => {
          this.errors = error.msg.errors[0];
        })
    }
  }
}
