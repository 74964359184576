import { Directive, ElementRef, Input, Output, EventEmitter, OnChanges, Renderer2, HostListener } from '@angular/core';
import { CurrencyPipe, DatePipe } from '@angular/common';
import * as moment from 'moment';

@Directive({
  selector: '[contenteditableModel]'
})

export class ContentEditableDirective implements OnChanges {
  @Input('contenteditableModel') contenteditableModel: any;
  @Input() currency = false;
  @Input() useCurrencySymbol = true;
  @Input() decimals = 2;
  @Input() date = false;
  @Output('contenteditableModelChange') contenteditableModelChange = new EventEmitter();
  @Output('contenteditableValueChange') contenteditableValueChange = new EventEmitter();
  @Output() contenteditableValueChangePairwise = new EventEmitter();

  constructor(
    private elementRef: ElementRef,
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private renderer: Renderer2
  ) {
  }

  private refreshCurrencyView() {
    const symbol = this.useCurrencySymbol ? 'symbol' : '';
    this.refreshView(() => this.renderer.setProperty(this.elementRef.nativeElement, 'textContent',
      this.currencyPipe.transform(this.contenteditableModel, 'BRL', symbol, `1.${this.decimals}-${this.decimals}`)));
  }

  private refreshDateView() {
    this.refreshView(() => this.renderer.setProperty(this.elementRef.nativeElement, 'textContent',
      this.datePipe.transform(this.contenteditableModel, 'dd/MM/yyyy')));
  }

  ngOnChanges(changes) {
    if (this.currency) {
      this.refreshCurrencyView();
    }

    if (this.date) {
      this.refreshDateView();
    }

    if (changes.contenteditableModel.previousValue !== changes.contenteditableModel.currentValue) {
      this.contenteditableModelChange.emit(this.contenteditableModel);
      if (!changes.contenteditableModel.firstChange) {
        this.contenteditableValueChange.emit(this.contenteditableModel);
      }
    }
  }

  @HostListener('blur') onEdit() {
    const value = this.elementRef.nativeElement.innerText;

    if (this.currency) {
      this.formatCurrency(value);
      this.refreshCurrencyView();
    }

    if (this.date) {
      this.formatDate(value);
      this.refreshDateView();
    }
  }

  @HostListener('focus') onFocus() {
    setTimeout(function () {
      document.execCommand('selectAll', false, null);
    }, 0);
  }

  private formatCurrency(value) {
    const number = Number(value.replace('.', '').replace(',', '.').replace(/[^0-9.-]+/g, '')).toFixed(2);
    this.contenteditableModelChange.emit(+number);
  }

  private formatDate(value) {
    if (value.match(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i)) {
      this.contenteditableModelChange.emit(moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'));
    }
  }

  private refreshView(formatFunction: Function) {
    try {
      formatFunction();
    } catch (err) {
      this.elementRef.nativeElement.textContent = '';
    }
  }
}
