import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class SeriesAlternativasService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getByID(id: number) {
        return this.http.get(this.UrlService + `SeriesAlternativas/${id}`)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    getPaginado(pagina: number, nome: string) {
        return this.http
          .get(this.UrlService + `SeriesAlternativas/GetPaginado?pagina=` + pagina + '&search=' + encodeURIComponent(nome))
          .pipe(map((res: any) => res.data)
            , catchError(super.serviceError));
    }

    getPaginadoConsulta(form) {
        return this.http
          .post(this.UrlService + 'SeriesAlternativas/GetPaginado', form)
          .pipe(map((res: any) => res.data)
            , catchError(super.serviceError));
    }

    getSeriesUtilizadas() {
        return this.http
          .get(this.UrlService + 'SeriesAlternativas/GetSeriesUtilizadas')
          .pipe(map((res: any) => res.data)
            , catchError(super.serviceError));
    }

    excluir(id: number) {
        return this.http.delete(this.UrlService + 'SeriesAlternativas/' + id)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    salvar(form) {
      if ((form.id || 0) === 0) {
        return this.http.post(this.UrlService + 'SeriesAlternativas/', form)
          .pipe(map((res: any) => res.data),
            catchError(super.serviceError));
      } else {
        return this.http.put(this.UrlService + 'SeriesAlternativas/', form)
          .pipe(map((res: any) => res.data),
            catchError(super.serviceError));
      }
    }
}
