import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class WebHookService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  retornaStatusPagamento(transactionId: number) {
    return this.http
      .get(this.UrlService + `webHook/Pooling/${transactionId}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));;
  }
}