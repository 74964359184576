import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class AlmoxarifadosService extends BaseService {

  constructor(private http: HttpClient) { super() }

  get() {
    return this.http.get(this.UrlService + 'Almoxarifado')
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  post(form) {
    return this.http.post(this.UrlService + 'Almoxarifado', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  getAlmoxarifadoById(id: number) {
    return this.http
      .get(this.UrlService + "Almoxarifado/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + `Almoxarifado/paginado?pageIndex=` + pagina + "&search=" + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
