import { tap } from "rxjs/operators";

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError as observableThrowError } from "rxjs";
import { StorageFunctions } from '../general/storage';


@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private storageFunctions: StorageFunctions
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const storage = this.storageFunctions.getStorage();
    let token = storage.getItem("token");
    if (request.body instanceof FormData) {
      //envio de arquivos
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    } else {
      request = request.clone({
        setHeaders: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (event.status === 200) {
              if (event.body.token) {
                storage.setItem("token", event.body.token);
              }
            }
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              case 401:
                this.router.navigate(["/authentication/login"]);
                break;

              case 404:
                this.router.navigate(["not-found"]);
                break;
              default:
                return observableThrowError(err);
            }
          } else {
            return observableThrowError(err);
          }
        }
      )
    );
  }
}
