<app-temp-modal-cadastro #modalConsultar [textHeader]="'Pesquisa DIM Cloud'" [customClass]="'modal-lg'" (closed)="onClose()" (scrolled)="onScroll()">
  <div class="app-modal-body">
    <div class="row">
        <div class="col-sm-12 form-group col-12">
        <form [formGroup]="obterClassificacoesForm" (ngSubmit)="buscarClassificacoes()">
            <label><b>Descrição do item:</b></label>
            <input id="ncmSearch" autofocus style="margin-bottom: 1.25rem" type="text" class="form-control" formControlName="wordFilter" placeholder="Search" [disableControl]="loading['classificacoes']">
            <div class="row">
                <div class="col-sm-12 form-group col-12">
                    <button [disabled]='loading["classificacoes"]' type="submit" class="btn btn-primary ripple light">
                        <app-loading [texto]="'Pesquisar'" [loading]="loading['classificacoes']"></app-loading>
                    </button>
                </div>
            </div>
        </form>
        </div>
    </div>
    <div class="row">
     <div id="tabelaNCMs" class="col-sm-12 form-group col-12" style="margin: 0px;">
      <ng-container *ngIf="dados">
        <app-dim-datatable style="margin: 0px;" [data]="dados" [showAcoesMobile]="true" [showInfoMobile]="false" [columns]="colunas"
          [totalItems]="dados.length" [serverPaginated]='false'
          [loading]="loading['classificacoes']" [acoes]="true" [rowsOnPage]="10000"
          *ngIf="dados.length > 0; else elseBlock" [showPagination]="false">
          <ng-template #buttonsMaxTmpl let-row="row">
            <button (click)="detalhar(row.classificacaoFiscal, $event)"
              [disabled]='loading["classificacoesDetalhes"]'
              class="btn btn-primary btn-mini waves-effect waves-light"
              placement="left" ngbTooltip="Detalhar">
              <i class="fa fa-plus"> </i>
            </button>
            <button (click)="utilizar(row.classificacaoFiscal)"
              [disabled]='loading["classificacoesDetalhes"]'
              class="btn btn-primary btn-mini waves-effect waves-light"
              placement="left" ngbTooltip="Utilizar NCM">
              <i class="fa fa-check"> </i>
            </button>
          </ng-template>
        </app-dim-datatable>
        <ng-template #elseBlock>Não foi encontrado nenhum registro para a descrição informada!</ng-template>
      </ng-container>
     </div>
    </div>
    <div class="row">
      <div id="tabelaDetalhes" class="col-sm-12 form-group col-12" *ngIf="dadosDetalhes.length > 0">
        <div class="row">
          <div class="col-sm-12 form-group col-12">
            <button (click)="utilizar(rowClassificacao)" type="submit" class="btn btn-primary ripple light">
              Utilizar NCM <b>{{rowClassificacao}}</b>
            </button>
          </div>
        </div>
        <app-dim-datatable [data]="dadosDetalhes" [showInfoMobile]="false" [columns]="colunasDetalhes"
          [totalItems]="dadosDetalhes.length" [serverPaginated]='false'
          [rowsOnPage]="6" [loading]="loading['classificacoesDetalhes']" [acoes]="false"
          *ngIf="dadosDetalhes.length > 0">
        </app-dim-datatable>
      </div>
    </div>
    <div class="scrollToNcm" *ngIf="dadosDetalhes.length > 0 && showToTop" (click)="toTop()">
        <button>
            <i class="fa fa-chevron-up"></i>
        </button>
    </div>
  </div>
  <div class="app-modal-footer">
  </div>
</app-temp-modal-cadastro>
<app-abrir-modal #abrirModal>
</app-abrir-modal>
