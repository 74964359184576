<app-card [title]="header" [showMinimize]="false">
  <div *ngIf="loading['dadosGerais']; else dadosGerais">
    <app-loading [loading]="true" [textoLoading]="'Buscando dados'" [icone]="'icofont icofont-refresh'">
    </app-loading>
  </div>

  <ng-template #dadosGerais>
    <div class="row" *ngIf="unidadeEmpresa">
      <div class="form-group col-sm-6">
        <label class="font-weight-bold">CRT Empresa Emitente</label>
        <input readonly disabled class="form-control" type="text" [value]="getCRT(unidadeEmpresa.crt)">
      </div>
      <div class="form-group col-sm-6" *ngIf="unidadeEmpresa && cliente">
        <label class="font-weight-bold">Tipo de Pedido</label>
        <input readonly disabled class="form-control" type="text" [value]="getTipoPedido()">
      </div>
    </div>
    <div class="row" *ngIf="cliente && unidadeEmpresa">
      <div class="col-sm-12">
        <label class="font-weight-bold">Dados do Cliente</label>
      </div>
      <div class="form-group col-sm-4 col-md-3 col-lg-2">
        <div class="form-control checkbox-fade fade-in-default check-box-automacao">
          <label class="mb-0">
            <input type="checkbox" [(ngModel)]="cliente.clienteFinal" (change)="alteraDadoCliente()"
              [disabled]="verificaBloqueio()">
            <span class="cr">
              <i class="cr-icon icofont icofont-ui-check txt-default"></i>
            </span>
            <span>Cliente Final</span>
          </label>
        </div>
      </div>
      <div class="form-group col-sm-4 col-md-3 col-lg-2">
        <div class="form-control checkbox-fade fade-in-default check-box-automacao">
          <label class="mb-0">
            <input type="checkbox" [(ngModel)]="cliente.simples" (change)="alteraDadoCliente()"
              [disabled]="verificaBloqueio()">
            <span class="cr">
              <i class="cr-icon icofont icofont-ui-check txt-default"></i>
            </span>
            <span>SIMPLES</span>
          </label>
        </div>
      </div>
      <div class="form-group col-sm-4 col-md-3 col-lg-2">
        <div class="form-control checkbox-fade fade-in-default check-box-automacao">
          <label class="mb-0">
            <input type="checkbox" [checked]="verificaDentroEstado()" disabled readonly>
            <span class="cr">
              <i class="cr-icon icofont icofont-ui-check txt-default"></i>
            </span>
            <span>Dentro Estado</span>
          </label>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="table-responsive mt-1" id="divTabe" (scroll)="scrollHandler($event)">
    <table *ngIf="itemsConsulta.length > 0" class="table table-striped table-bordered m-0" [mfData]="itemsConsulta"
      #data="mfDataTable">
      <thead>
        <tr>
          <th>
            Referência
          </th>
          <th>
            Nome
          </th>
          <th class="text-nowrap">
            Bonificação/Brinde
          </th>
          <th class="text-nowrap">
            Tipo Item
          </th>
          <th class="text-nowrap">
            Sujeito ST
          </th>
          <th class="text-nowrap">
            Isenção ICMS
          </th>
          <th>
            Red.B.ICMS
          </th>
          <th>
            CFOP
          </th>
          <th>
            CST/CSOSN
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor='let item of data.data; let indexItem = index;' [ngClass]="verificaAutomacao(indexItem)">
          <td>
            {{ item.produto.referenciaItem }}
          </td>
          <td class="text-produto" [ngbTooltip]="item.produto.nome" container="body">
            {{ item.produto.nome }}
          </td>
          <td class="p-0 text-center">
            <div class="form-control checkbox-fade fade-in-default check-box-automacao">
              <label class="mb-0">
                <input type="checkbox" [(ngModel)]="item.bonificacaoOuBrinde"
                  [disabled]="item.consultando || loading['salvando']"
                  (change)="verificaTabelaAutomacao($event, indexItem, 'bonificacaoOuBrinde')">
                <span class="cr m-0">
                  <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span>
              </label>
            </div>
          </td>
          <td class="p-0 text-center td-tipo-item">
            <ng-select appendTo="body" placeholder="Selecione" [disabled]="item.consultando || loading['salvando']"
              (change)="verificaTabelaAutomacao($event, indexItem)" [(ngModel)]="item.produto.tipoItemid"
              [items]="tipoItens" bindLabel="descricao" bindValue="id">
            </ng-select>
          </td>
          <td class="p-0 text-center">
            <div class="form-control checkbox-fade fade-in-default check-box-automacao">
              <label class="mb-0">
                <input type="checkbox" [(ngModel)]="item.produto.substituicaoTributaria"
                  [disabled]="item.consultando || loading['salvando']"
                  (change)="verificaTabelaAutomacao($event, indexItem, 'sujeitoST')">
                <span class="cr m-0">
                  <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span>
              </label>
            </div>
          </td>
          <td class="p-0 text-center">
            <div class="form-control checkbox-fade fade-in-default check-box-automacao">
              <label class="mb-0">
                <input type="checkbox" [(ngModel)]="item.produto.isentoIcms"
                  [disabled]="item.consultando || loading['salvando']"
                  (change)="verificaTabelaAutomacao($event, indexItem, 'isencaoICMS')">
                <span class="cr m-0">
                  <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span>
              </label>
            </div>
          </td>
          <td class="p-0 text-center">
            <div>
              {{getValueRedIcms(item.produto.aliquotaicms?.percentualRedutorICMS)}}
            </div>
          </td>
          <td class="text-center">
            <div *ngIf="!item.consultando; else colsultandoItemTemp">
              {{ item.automacao?.codigoCFOP }}
            </div>
          </td>
          <td class="text-center">
            <div *ngIf="!item.consultando; else colsultandoItemTemp">
              {{ item.automacao?.codigoSTICMS }}
            </div>
          </td>

          <ng-template #colsultandoItemTemp>
            <app-loading [loading]="true" [textoLoading]="''" [icone]="'icofont icofont-refresh'">
            </app-loading>
          </ng-template>

        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="loading['dadosItem']">
    <app-loading [loading]="true" [textoLoading]="'Buscando Itens'" [icone]="'icofont icofont-refresh'">
    </app-loading>
  </div>

  <div class="row mt-3">
    <div class="form-group col-sm-12">
      <button *ngIf="itemsConsulta.length > 0" (click)="salvar()" class="btn btn-primary custom-form-button submit-form"
        type="button" [disabled]="loading['dadosItem'] || loading['salvando']">
        <app-loading [loading]="loading['dadosItem'] || loading['salvando']" [textoLoading]="'Aguarde'" [texto]="'Salvar'"
          [icone]="'icofont icofont-refresh'">
        </app-loading>
      </button>

      <button (click)="fechar()" type="button" class="btn btn-warning custom-form-button"
        [disabled]="loading['salvando']"><i class="icofont icofont-close-circled"></i>
        Cancelar
      </button>

      <button *ngIf="itemsConsulta.length > 0" type="button"
        class="btn btn-primary custom-form-button submit-form btn-salvar-emitir" (click)="salvar(true)"
        [disabled]="loading['dadosItem'] || loading['salvando']">
        <app-loading [loading]="loading['dadosItem'] || loading['salvando']" [textoLoading]="'Aguarde'" [texto]="'Salvar + emitir'"
          [icone]="'fa fa-calculator'">
        </app-loading>
      </button>
    </div>
  </div>
</app-card>