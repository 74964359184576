<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader">

  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
    <div class="row">
      <div class="form-group col-sm-12">
        <label>Intermediador <i class="fa fa-info-circle tooltip-estoque" [ngbPopover]="popContent1" container="body"
            triggers="mouseenter:mouseleave" popoverTitle="Intermediador" #p="ngbPopover" (click)="p.open()"></i>
        </label>
        <ng-template #popContent1>Nome do intermediador/marketplace. Será utilizado para identificação ao selecionar o
          registro na NFe (controle interno).</ng-template>
        <input type="text" class="form-control" formControlName="tag" placeholder="Intermediador">
        <show-errors [control]="cadastroForm.get('intermediador')"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <label>CNPJ do Intermediador <i class="fa fa-info-circle tooltip-estoque" [ngbPopover]="popContent2"
            container="body" triggers="mouseenter:mouseleave" popoverTitle="CNPJ do Intermediador" #p="ngbPopover"
            (click)="p.open()"></i> </label>
        <ng-template #popContent2>Informar o CNPJ do Intermediador da Transação (agenciador, plataforma de delivery,
          marketplace e similar) de serviços e de negócios.</ng-template>
        <input type="text" class="form-control" formControlName="cnpj" placeholder="CNPJ do Intermediador">
        <show-errors [control]="cadastroForm.get('cnpj')"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <label>Identificação <i class="fa fa-info-circle tooltip-estoque" [ngbPopover]="popContent3" container="body"
            triggers="mouseenter:mouseleave" popoverTitle="Identificação" #p="ngbPopover" (click)="p.open()"></i>
        </label>
        <ng-template #popContent3>Nome do usuário ou identificação do perfil do vendedor no site do intermediador
          (agenciador, plataforma de delivery, marketplace e similar)</ng-template>
        <input type="text" class="form-control" formControlName="identificador" placeholder="Identificação">
        <show-errors [control]="cadastroForm.get('identificador')"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 form-group check-form">
        <div class="checkbox-fade fade-in-default">
          <label>
            <input type="checkbox" formControlName="ativo">
            <span class="cr">
              <i class="cr-icon icofont icofont-ui-check txt-default"></i>
            </span> <span>Ativo </span><i class="fa fa-info-circle tooltip-estoque" [ngbPopover]="popContent4"
              container="body" triggers="mouseenter:mouseleave" popoverTitle="Status" #p="ngbPopover"
              (click)="p.open()"></i>
          </label>
          <ng-template #popContent4>Status do Intermediador. Quando ativo, será exibido na NFe para seleção. Caso
            contrário, o registro será oculto.</ng-template>
        </div>
      </div>
    </div>
    <div class="form-button-row">
      <div class="alert alert-danger" *ngIf="errors">
        <span>{{ errors }}</span>
      </div>

      <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()"
        class="btn btn-primary ripple light" [disabled]="!cadastroForm.valid">
        <app-loading [texto]="'Salvar'" [loading]="loading['registro']"></app-loading>
      </button>
      <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
        (click)="modalCadastro.hide()">Cancelar</button>
    </div>
  </form>

</app-temp-modal-cadastro>