import { FormControl, FormGroup } from "@angular/forms";
import { TiposMovimentacoesEnum, MovimentacoesVendedoresViewModel } from "./MovimentacoesVendedores";
import { IParamConsulta, TParamConsultaForm } from "@generals";
import { ConsultaPaginadaViewModel, PeriodoConsultaForm, ValorConsultaForm } from "@models";
import { PeriodoConsulta, ValorConsulta } from "@models";

export interface ConsultaMovimentacoesVendedoresViewModel {
  tipo: TiposMovimentacoesEnum;
  representanteId: number;
  observacao: string;
  periodo: PeriodoConsulta;
  valor: ValorConsulta;
  paramConsulta: IParamConsulta
}

export type ConsultaMovimentacoesVendedoresForm = {
  tipo: FormControl<TiposMovimentacoesEnum>;
  representanteId: FormControl<number>;
  observacao: FormControl<string>;
  periodo: FormGroup<PeriodoConsultaForm>;
  valor: FormGroup<ValorConsultaForm>;
  paramConsulta: FormGroup<TParamConsultaForm>

}

export interface ConsultaMovimentacoesVendedoresResponse {
  dados: ConsultaPaginadaViewModel<MovimentacoesVendedoresViewModel>;
  calculo: ConsultaMovimentacoesVendedoresCalculo;
}

export interface ConsultaMovimentacoesVendedoresCalculo {
  entradas: number;
  saidas: number;
  saldoFinal: number;
  saldoInicial: number;
}

export interface ExpotarConsultaMovimentacoesVendedoresColumns {
  tipo: boolean;
  representanteId: boolean;
  observacao: boolean;
  data: boolean;
  valor: boolean;
  totalEntrada: boolean;
  totalSaida: boolean;
  saldoInicial: boolean;
  saldoFinal: boolean;
}

export type ExpotarConsultaMovimentacoesVendedoresColumnsForm = {
  tipo: FormControl<boolean>;
  representanteId: FormControl<boolean>;
  observacao: FormControl<boolean>;
  data: FormControl<boolean>;
  valor: FormControl<boolean>;
  totalEntrada: FormControl<boolean>;
  totalSaida: FormControl<boolean>;
  saldoInicial: FormControl<boolean>;
  saldoFinal: FormControl<boolean>;
}

export interface ExpotarConsultaMovimentacoesVendedoresViewModel {
  colunas: ExpotarConsultaMovimentacoesVendedoresColumns;
  tipoArquivo: string;
  consulta: ConsultaMovimentacoesVendedoresViewModel,
}
