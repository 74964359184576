import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { forkJoin } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MessageService } from './message.service';

@Injectable()
export class DespesasService extends BaseService {

  constructor(private http: HttpClient, private message: MessageService) { super(); }

  getWithFilters(consulta: any) {
    return this.http
      .post(this.UrlService + 'despesas/buscar', consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  imprimir(consulta: any) {
    return this.http
      .post(this.UrlService + 'despesas/imprimir', consulta, { responseType: 'blob' }).pipe(catchError(super.parseErrorBlob))
  }

  getDespesaById(id: number) {
    return this.http
      .get(this.UrlService + "despesas/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  deleteDespesa(id: number) {
    return this.http
      .delete(this.UrlService + "despesas/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  deleteGroupDespesa(ids: number[]) {
    let listToDelete = [];
    for (let i = 0; i < ids.length; i++) {
      listToDelete.push(this.deleteDespesa(ids[i]))
    }
    return forkJoin(listToDelete);
  }

  postForm(form: any) {
    return this.http
      .post(this.UrlService + 'despesas/salvar', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  putForm(form: any) {
    return this.http
      .put(this.UrlService + 'despesas/alterar', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  imprimirDestinacaoRecursos(form) {    
    let idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Destinação de recursos',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    })
    return this.http
      .post(this.UrlService + 'despesas/destinacaoRecursos', form, { responseType: 'blob' }).pipe(finalize(() => this.message.clear(idToast))
        , catchError(super.parseErrorBlob))
  }
}
