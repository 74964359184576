import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ContascorrentesService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get(filtrarInativos: boolean = true) {
    return this.http.get(this.UrlService + 'ContasCorrentes?filtrarInativos=' + filtrarInativos)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getContaById(id: number) {
    return this.http
      .get(this.UrlService + "ContasCorrentes/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  private post(form) {
    return this.http.post(this.UrlService + 'ContasCorrentes/salvar', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  private put(form) {
    return this.http.put(this.UrlService + 'ContasCorrentes/atualizar', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  getSaldoAtual() {
    return this.http
      .get(this.UrlService + "ContasCorrentes/saldoAtual")
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  salvar(form, id: number) {
    if (id > 0) {
      return this.put(form);
    } else {
      return this.post(form);
    }
  }

  delete(id: number) {
    return this.http
      .delete(this.UrlService + "ContasCorrentes/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + "ContasCorrentes/Paginado?pageIndex=" + pagina + "&search=" + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  contratarIntermediadorRecebimento(dados) {
    return this.http
      .post(this.UrlService + 'ContasCorrentes/ContratarIntermediadorRecebimento', dados)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  atualizarDadosIntermediadorRecebimento() {
    return this.http
      .post(this.UrlService + 'ContasCorrentes/AtualizarDadosIntermediadorRecebimento', null)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  desativarIntermediadorRecebimento() {
    return this.http
      .post(this.UrlService + 'ContasCorrentes/DesativarIntermediadorRecebimento', null)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
