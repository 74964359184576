import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faturamento-detalhado-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({
          height: '0',
          overflow: 'hidden',
          opacity: '0',
        }),
        animate('200ms ease-in', style({
          height: '*',
          opacity: '1',
        }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({
          height: '0',
          overflow: 'hidden',
          opacity: '0',
        }))
      ])
    ])
  ]
})
export class CollapseComponent {
  show = false;

  constructor() { }
}
