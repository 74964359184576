<app-modal-basic #modalRelatorio (closed)="destroyComponent()">
  <div class="app-modal-header">
    <h4 class="modal-title">Impressão relatório de produtos</h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="form" (ngSubmit)="enviarConfig()">
      <div class="row">
        <div class="col-sm-12 form-group" style="margin-bottom: unset;">
          <label style="font-weight: bold;">Modo:</label>
        </div>
      </div>
      <div class="checkbox-space">
        <div class="row">
          <div class="form-radio col-sm-12">
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="modoDetalhado" [value]="false">
                <i class="helper"></i>Simplificado
              </label>
            </div>
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="modoDetalhado" [value]="true">
                <i class="helper"></i>Detalhado
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 form-group" style="margin-bottom: unset;">
          <label for="" style="font-weight: bold;">Formato:</label>
        </div>
      </div>
      <div class="checkbox-space">
        <div class="row">
          <div class="form-radio col-sm-12">
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="impressaoPDF" [value]="true">
                <i class="helper"></i>PDF
              </label>
            </div>
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="impressaoPDF" [value]="false">
                <i class="helper"></i>XLSX
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6 check-form">
          <div checkbox class="checkbox-fade fade-in-default">
            <label>
              <input type="checkbox" formControlName="ocultarPrecoVenda">
              <span class="cr">
                <i class="cr-icon icofont icofont-ui-check txt-default"></i>
              </span>
              <span class="text-inverse">
                Ocultar preços de venda
              </span>
            </label>
          </div>
        </div>
        <div class="form-group col-sm-6 check-form">
          <div checkbox class="checkbox-fade fade-in-default">
            <label>
              <input type="checkbox" formControlName="exibirCodigoEAN">
              <span class="cr">
                <i class="cr-icon icofont icofont-ui-check txt-default"></i>
              </span>
              <span class="text-inverse">
                Exibir código EAN
              </span>
            </label>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary ripple light">
        <i class="icofont icofont-print"> </i> Imprimir
      </button>
      <button type="button" class="btn btn-danger ripple" (click)="modalRelatorio.hide()"><i class="ti ti-close"></i>
        Cancelar
      </button>
    </form>
  </div>
</app-modal-basic>