<app-modal-basic [allowClickOutside]="allowClickOutside" #modalCadastro (closed)="onClose()" (scrolled)="onScroll()"
  [dialogClass]="customClass" [dialogClassMobile]="customClassMobile" [dialogClassContent]="customClassContent"
  [dialogClassContentMobile]="customClassContentMobile" [modalContentId]="modalContentId">
  <div class="app-modal-header">
    <h4 class="modal-title">
      {{textHeader}}
    </h4>
    <button type="button" class="close basic-close" (click)="modalCadastro.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="app-modal-body">
    <ng-content></ng-content>
  </div>

  <div class="app-modal-footer">
  </div>

</app-modal-basic>
