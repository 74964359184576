import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MessageService } from '../../../services/message.service';
import { NotasFiscaisService } from '../../../services/notas-fiscais.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-notas-fiscais-venda-por-produto',
  templateUrl: './notas-fiscais-venda-por-produto.component.html',
  styleUrls: ['./notas-fiscais-venda-por-produto.component.css'],
})
export class NotasFiscaisVendaPorProdutoComponent extends ModalCadastroComponent implements OnInit {
  constructor(private nfeService: NotasFiscaisService, private message: MessageService) {
    super();
  }

  @ViewChild('modalRelatorio', { static: true }) modalRelatorio;
  form: FormGroup;
  errorMsg: string;
  loading = false;

  tiposRelatorio = [
    { id: "vendasPorProduto", label: "Vendas por Produto" },
    { id: "agrupadoPorCliente", label: "Agrupado por Cliente" },
  ];

  ngOnInit(): void {
    const inicioMes = moment().startOf('month');
    const hoje = new Date();

    this.form = new FormGroup({
      emissaoDe: new FormControl(inicioMes, [Validators.required]),
      emissaoAte: new FormControl(hoje, [Validators.required]),
      tipoRelatorio: new FormControl("vendasPorProduto"),
      clienteID: new FormControl(null),
      formato: new FormControl('pdf'),
      orientacao: new FormControl('vertical'),
      vendedorID: new FormControl(null),
    });
  }

  async imprimir(): Promise<void> {
    if (this.form.invalid) {
      this.errorMsg = 'Preencha o período de Emissão.';
      return;
    }

    this.errorMsg = '';
    this.loading = true;
    try {
      const formato = this.form.get('formato').value;
      const res = await this.nfeService.imprimirVendasPorProduto(this.form.value).toPromise();

      saveAs(res, `relatorio_vendas_por_produto.${formato}`);
      this.message.success('Relatório gerado com sucesso!');
      this.close();
    } catch (err) {
      console.error(err);
      if (err.errors && err.errors[0])
        this.errorMsg = err.errors[0];
      else if (err.msg && err.msg.errors)
        this.errorMsg = err.msg.errors[0];
      else
        this.errorMsg = err.message;
    } finally {
      this.loading = false;
    }
  }

  show(): void {
    this.modalRelatorio.show();
  }

  close(): void {
    this.closed.emit();
  }
}
