import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-temp-option-item',
  templateUrl: './temp-option-item.component.html',
  styleUrls: ['./temp-option-item.component.css']
})
export class TempOptionItemComponent {

  constructor() { }

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @Input() name: string;
  @Input() icon: string = "fa fa-edit";

  click() {
    this.onClick.emit();
  }

}
