import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, finalize, map, mergeMap } from 'rxjs/operators';
import { VerificarDadosItensDespachoViewModel } from '../model/VerificarDadosItensDespachoViewModel';
import { BaseService } from './base.service';
import { MessageService } from './message.service';
import { NotasFiscaisService } from './notas-fiscais.service';


@Injectable()
export class PedidosService extends BaseService {

  constructor(private http: HttpClient, private nfeService: NotasFiscaisService, private message: MessageService) { super(); }

  getPedidosCliente(id) {
    return this.http.get(this.UrlService + `pedidos/GetPedidosCliente/` + id)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getPedidosPaginado(form) {
    return this.http.post(this.UrlService + `pedidos/GetPedidosPaginado`, form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getPedidosPaginadoEscaninho(form) {
    return this.http.post(this.UrlService + `pedidos/GetPedidosPaginadoEscaninho`, form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getParametrosPedido() {
    return this.http.get(this.UrlService + `pedidos/getParametrosPedido`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  private postPedido(form) {
    return this.http.post(this.UrlService + 'pedidos/postPedido', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  private putPedido(form) {
    return this.http.put(this.UrlService + 'pedidos/putPedido', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  adicionarItemPedido(item) {
    return this.http.post(this.UrlService + 'pedidos/adicionarPedidoItem', item)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  salvarPedido(id: number, form) {
    if (id) {
      return this.putPedido(form);
    } else {
      return this.postPedido(form);
    }
  }

  fecharPedido(form) {
    return this.http.put(this.UrlService + 'pedidos/fecharPedido', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  fecharParcialmentePedido(form) {
    return this.http.put(this.UrlService + 'pedidos/fecharParcialmentePedido', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getCadastroPedidoById(pedidoId) {
    return this.http.get(this.UrlService + `pedidos/GetCadastroPedidoById/` + pedidoId)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getItensPedido(pedidoId) {
    return this.http.get(this.UrlService + `pedidos/GetItensPedido/` + pedidoId)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getItensDespachoPedido(pedidoId) {
    return this.http.get(this.UrlService + `pedidos/GetItensDespachoPedido/` + pedidoId)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getAutomacaoDespachoPedido(ids: number[]) {
    return this.http.post(this.UrlService + `pedidos/GetAutomacaoDespachoPedido`, { ids })
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getItensPendentes(params: any) {
    return this.http.post(this.UrlService + 'pedidos/GetItensPendentes', params)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  alteraStatusItem(pedidoItemId, status) {
    const param: Object = {
      'PedidoItemId': pedidoItemId,
      'Status': status
    };

    return this.http.post(this.UrlService + 'pedidos/AlteraStatusItem', param)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getSimulaPagamentosPrazo(condicaoPagamento, valorTotal, dataVencimento) {
    return this.http.get(`${this.UrlService}pedidos/SimulaPagamentosPrazo?condicaoPagamento=${condicaoPagamento}&valorTotal=${valorTotal}&dataVencimento=${dataVencimento}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getSimulaPagamentosPrazoDespacho(form) {
    return this.http.post(`${this.UrlService}pedidos/SimulaPagamentosPrazoDespacho`, form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  cancelarPedido(pedidoId) {
    return this.http.post(this.UrlService + 'pedidos/cancelarPedido', pedidoId)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  estornarCancelamento(pedidoId) {
    return this.http.post(this.UrlService + 'pedidos/estornarCancelamento', pedidoId)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  estornarFechamento(pedidoId) {
    return this.http.post(this.UrlService + 'pedidos/estornarFechamento', pedidoId)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getFechamentoPedido(pedidoId) {
    return this.http.get(this.UrlService + `pedidos/getFechamentoPedido/` + pedidoId)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  imprimirRelatorioPedidos(consulta: any, detalhado: boolean) {
    return this.http
      .post(this.UrlService + `pedidos/imprimirRelatorioPedidos?detalhado=${detalhado}`, consulta, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  imprimirPedidosEntrega(pedidos: number[]) {
    return this.http
      .post(this.UrlService + 'pedidos/imprimirRelatorioPedidosEntrega', pedidos, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  imprimirPedido(pedidoId, colunas: any = undefined, movimentaEstoque: boolean = false) {
    const idToast = this.message.addToast({
      title: 'Emitindo Relatório do Pedido',
      msg: 'Carregando...',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    });
    return this.http
      .post(this.UrlService + `pedidos/imprimirPedido`, { pedidoId, colunas, movimentaEstoque },
        { responseType: 'blob' }).pipe(finalize(() => this.message.clear(idToast)),
          catchError(super.parseErrorBlob));
  }

  imprimirTicket(pedidoItemID: number) {
    return this.http
      .post(this.UrlService + 'pedidos/imprimirTicket', pedidoItemID, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  preVisualizar(form) {
    return this.http.post(this.UrlService + 'pedidos/preVisualizar', form, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  email(pedidoId: number, email: string[]) {
    const param: Object = {
      'PedidoId': pedidoId,
      'Email': email
    };

    return this.http.post(this.UrlService + 'pedidos/email', param)
      .pipe(map((res: any) => res.data));
  }

  imprimirEtiquetasPedido(pedidoId, volumes, especie) {
    return this.http
      .post(this.UrlService + `pedidos/imprimirEtiquetasPedido?pedidoId=${pedidoId}
      &volumes=${volumes}&especie=${encodeURIComponent(especie)}`, null, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  imprimirRelacaoVendasProduto(form) {
    const idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Relação de vendas por produto',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    });

    return this.http
      .post(this.UrlService + 'pedidos/RelacaoVendasProduto', form,
        { responseType: 'blob' }).pipe(finalize(() => this.message.clear(idToast))
          , catchError(super.parseErrorBlob));
  }

  faturarPedido(formPedido) {
    return this.http.post(this.UrlService + 'notasfiscais/faturarPedido', formPedido)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  preVisualizarFaturamento(formPedido) {
    return this.http.post(this.UrlService + 'notasfiscais/preVisualizarNotaPedido', formPedido, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  despacharFaturarPedido(despachoForm) {
    return this.despacharPedido(despachoForm).pipe(
      mergeMap(generatedId => this.nfeService.enviarNfe(generatedId))
    ).pipe(catchError(super.serviceError));
  }

  private despacharPedido(despachoForm) {
    return this.http.post(this.UrlService + 'notasfiscais/despacharPedido', despachoForm)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  preVisualizarDespacho(formPedido) {
    return this.http.post(this.UrlService + 'notasfiscais/preVisualizarDespacho', formPedido, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  importarXls(file: any) {
    return this.http
      .post(this.UrlService + 'pedidos/ImportarXls', file).pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  private impressaoPedido = new BehaviorSubject<Object>(null);
  solicitacaoImpressao = this.impressaoPedido.asObservable();

  organizarImpressao(id?, param?) {
    if (id != null && param != null) {
      this.impressaoPedido.next({ id: id, param: param });
    } else {
      this.impressaoPedido.next(null);
    }
  }

  baixarBoleto(pedidoId: any) {
    return this.http
      .post(this.UrlService + `pedidos/BaixarBoletos`, pedidoId).pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  imprimirOrdemDeServico(pedidoId) {
    return this.http
      .post(this.UrlService + 'pedidos/imprimirOrdemDeServico', pedidoId, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  gerarPedido(form) {
    return this.http.post(this.UrlService + 'pedidos/gerarPedido', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  atualizarParcialPedido(pedidoAtualizacao) {
    return this.http
      .patch(this.UrlService + `pedidos/AtualizarParcialPedido`, pedidoAtualizacao)
      .pipe(catchError(super.serviceError));
  }

  atualizarComplementoPedido(pedidoAtualizacao) {
    return this.http
      .patch(this.UrlService + `pedidos/AtualizarParcialComplementoPedido`, pedidoAtualizacao)
      .pipe(catchError(super.serviceError));
  }

  verificarUnidadesConsumoVinculada(id: number) {
    return this.http.get(this.UrlService + `pedidos/GetUnidadesConsumoVinculada/` + id)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  removerVinculos(id: number) {
    return this.http
      .post(this.UrlService + 'pedidos/removerVinculos/', id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  estornarPagamento(pedidoId: number, pagamentoId: number, status: string, itens: any[]) {
    const obj = { pedidoId, pagamentoId, status, itens }
    return this.http
      .post(this.UrlService + 'pedidos/EstornarPagamento/', obj)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  estornarTituloCobranca(pedidoId: number, tituloCobrancaId: number,) {
    const obj = { pedidoId, tituloCobrancaId }
    return this.http
      .post(this.UrlService + 'pedidos/EstornarTituloCobranca/', obj)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  estornarTodosTitulosCobranca(pedidoId: number) {
    return this.http
      .post(this.UrlService + 'pedidos/EstornarTodosTitulosCobranca/', pedidoId)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  estornarEstoque(pedidoId: number) {
    return this.http
      .post(this.UrlService + 'pedidos/EstornarEstoque', pedidoId)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  conferenciaItensPedido(pedidoId: number) {
    return this.http
      .post(this.UrlService + 'pedidos/ConferenciaItensPedido', pedidoId)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  ajustarAlmoxarifadoItens(formValue: any) {
    return this.http
      .post(this.UrlService + 'pedidos/AjustarAlmoxarifadoItens', formValue)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  verificarDadosItensDespacho(form): Observable<VerificarDadosItensDespachoViewModel> {
    return this.http
      .post(this.UrlService + 'pedidos/verificarDadosItensDespacho', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  aplicarDescontoPedido(obj) {
    return this.http
      .post(this.UrlService + 'pedidos/aplicaDescontoPedidoItens', obj)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}