import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseServiceV2 } from '@servicesv2';
import {
  ConsultaTitulosFornecedoresViewModel,
  ConsultaTitulosFornecedoresResponseViewModel
} from 'models/titulos-fornecedores/ConsultaTitulosFornecedoresViewModel';

@Injectable()
export class TitulosFornecedoresServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('TitulosFornecedores', http);
  }

  buscar(payload: ConsultaTitulosFornecedoresViewModel) {
    return this.postAsPromise<ConsultaTitulosFornecedoresViewModel, ConsultaTitulosFornecedoresResponseViewModel>(payload, 'buscar');
  }

  estornar(tituloFornecedorId: number) {
    return this.putAsPromise<undefined, { id: number }>(undefined, `estornar/${tituloFornecedorId}`);
  }

}
