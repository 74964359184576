import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { EmailBoxComponent } from '../../../../dim-componentes/email-box/email-box.component';
import { convertB64ToBlob, setFormValue } from '../../../../general/utils';
import { MessageService } from '../../../../services/message.service';
import { NotasFiscaisServicoService } from '../../../../services/notas-fiscais-servico.service';
import { UnidadesEmpresaService } from '../../../../services/unidades-empresa.service';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-exportar-nfse',
  templateUrl: './exportar-nfse.component.html',
  styleUrls: ['./exportar-nfse.component.css']
})
export class ExportarNFSEComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modalRelatorio', { static: true }) modalRelatorio;
  @ViewChild('emailBox', { static: false }) emailBox: EmailBoxComponent;
  impressaoForm: FormGroup;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  loading = {};
  form: FormGroup;

  constructor(
    private message: MessageService,
    private unidadesEmpresaService: UnidadesEmpresaService,
    private notasFiscaisServicoService: NotasFiscaisServicoService,
  ) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      emissao1: new FormControl(''),
      emissao2: new FormControl(''),
      destinatarios: new FormControl(''),
    });

    if (this.prop != null) {
      setFormValue(this.form, this.prop);
    }

    this.unidadesEmpresaService.getUnidadeEmpresa()
      .subscribe(res => {
        if (res.email) {
          this.emailBox.insertEmailString(res.email);
        }

        if (res.contabilidade && res.contabilidade.email) {
          this.emailBox.insertEmailString(res.contabilidade.email);
        }
      });
  }

  show(prop): void {
    this.modalRelatorio.show();
  }

  hide() {
    this.modalRelatorio.hide();
  }

  async exportar() {
    this.message.clearAll();
    this.loading['exportacao'] = true;

    const idToast = this.message.addToast({
      title: 'Exportando...',
      msg: '',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    });

    try {
      const response = await this.notasFiscaisServicoService.exportarNfse(this.form.value).toPromise();
      response.forEach(file => {
        saveAs(convertB64ToBlob(file.fileContents, file.contentType), file.fileDownloadName);
      });

      this.message.clear(idToast);
      this.message.addSuccess({
        title: 'Sucesso!',
        msg: 'Documentos exportados com êxito.',
        timeout: 6000
      });
    } catch (err) {
      this.message.addError({
        msg: err.error.errors[0],
        showClose: true, timeout: 10000, closeOther: true
      });
    } finally {
      this.message.clear(idToast);
      this.loading['exportacao'] = false
    }
  }
}
