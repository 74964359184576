import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class RegrasTributariasService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getAll() {
    return this.http
      .get(this.UrlService + 'RegrasTributarias')
      .pipe(map((res: any) => res.data), 
      catchError(super.serviceError));
  }

  getRegrasTributariasById(id) {
    return this.http.get(this.UrlService + `RegrasTributarias/${id}`)
      .pipe(map(super.extractData), 
      catchError(super.serviceError));
  }

  postRegrasTributarias(form) {
    if ((form.id || 0) === 0) {
      return this.http.post(this.UrlService + 'RegrasTributarias/', form)
        .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
    }else {
      return this.http.put(this.UrlService + 'RegrasTributarias/', form)
        .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
    }
  }

  delete(id: number){
    return this.http.delete(this.UrlService + 'RegrasTributarias/' + id)
      .pipe(map((res: any) => res.data), 
      catchError(super.serviceError));
  }
}


