import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-senha',
  templateUrl: './input-senha.component.html',
  styleUrls: ['./input-senha.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSenhaComponent),
      multi: true,
    },
  ],
})
export class InputSenhaComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() form: FormGroup;
  @Input() formControlName: string;
  @Input() placeholder: string;
  @Input() maxlength: number;
  @Input() disabled: boolean;
  @Input() mascara: string;
  @Input() customClasses: string = '';
  @Input() autofocus = false;
  @Output() blurEvent: EventEmitter<any> = new EventEmitter();
  verSenha = false;
  value: any;

  private onChange: (value: any) => void;
  private onTouch: () => void;

  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      document.getElementById('input-senha').focus()
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onInputChange(event: any): void {
    this.onChange(event.target.value);
    this.onTouch();
  }

  handleEyeClick() {
    this.verSenha = !this.verSenha
  }

  onBlur() {
    this.blurEvent.emit();
  }
}
