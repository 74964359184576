import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-temp-not-found-item',
  templateUrl: './temp-not-found-item.component.html',
  styleUrls: ['./temp-not-found-item.component.css']
})
export class TempNotFoundItemComponent {

  constructor() { }

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @Input() searchTerm: string;

  click() {
    this.onClick.emit();
  }

}
