<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader">

  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
    <div class="row">
      <div class="form-group col-sm-12">
        <label for="condicao_pagamento_nome" class="label-required">Descrição do prazo</label>
        <input id="condicao_pagamento_nome" appAutofocus [disableControl]="loading['registro']" type="text" class="form-control"
          formControlName="condicao" />
        <show-errors [control]="cadastroForm.controls.condicao"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <label for="condicao_pagamento_prazos" class="label-required">
          Prazos
          <i class="fa fa-info-circle tooltip-prazos"
            ngbTooltip="Utilize as teclas 'espaço' ou 'enter' para separar cada prazo." container="body"
            [autoClose]="true" triggers="manual" #t="ngbTooltip" (mouseleave)="t.close()" (mouseenter)="t.open()"
            (click)="t.open()" aria-hidden="true"></i>
        </label>
        <tag-input id="condicao_pagamento_prazos" [modelAsStrings]="true" [disable]="loading['registro']" placeholder='+ Prazo'
          secondaryPlaceholder='Prazos' formControlName="prazos" [separatorKeyCodes]="[32]" theme='bootstrap'>
        </tag-input>
        <show-errors [control]="cadastroForm.controls.prazos"></show-errors>
      </div>
    </div>

    <div class="row mb-3" *ngIf="id !== null && exibirCheckbox">
      <div class="col-12">
        <app-dim-checkbox label="Desativar" formControlName="inativo"></app-dim-checkbox>
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="errors">
      <span>{{ errors }}</span>
    </div>

    <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'" [loading]="loading['registro']"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalCadastro.hide()">Cancelar</button>
  </form>

</app-temp-modal-cadastro>
