import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { setFieldFormValue, setFormValue, validarCNPJ, validarCPF } from '../../../general/utils';
import { EstadosService } from '../../../services/estados.service';
import * as $ from 'jquery';
import { CidadesService } from '../../../services/cidades.service';
import { ClientesService } from '../../../services/clientes.service';
import { MessageService } from '../../../services/message.service';
import { BuscaAutomaticaCNPJService } from '../../../services/busca-automatica-CNPJ.service';
import Swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class ClienteComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalCadastro', { static: true }) modalCadastro;

  constructor(
    private clienteService: ClientesService,
    private estadosService: EstadosService,
    private cidadesService: CidadesService,
    private messageService: MessageService,
    private buscaAutomaticaCNPJService: BuscaAutomaticaCNPJService) {
    super();
  }

  cadastroForm = new FormGroup({
    id: new FormControl(null),
    razaoSocial: new FormControl(null, [Validators.required]),
    cnpj: new FormControl('', [Validators.required]),
    indicadorIE: new FormControl(9, [Validators.required]),
    inscricaoEstadual: new FormControl(null, [Validators.required]),
    cep: new FormControl(null),
    bairro: new FormControl(null),
    estado: new FormControl(null),
    cidadeId: new FormControl(null),
    endereco: new FormControl(null),
    numero: new FormControl(null),
    email: new FormControl(null),
    telefone: new FormControl(null),
    celular: new FormControl(null),
    clienteFinal: new FormControl(null),
    produtorRural: new FormControl(null)
  });

  estados = [];
  cidades = [];
  clienteIsento: boolean;
  tipoIE = [
    { id: 1, name: 'Contribuinte ICMS' },
    { id: 2, name: 'Contribuinte isento' },
    { id: 9, name: 'Não Contribuinte' }
  ];

  ngOnInit() {
    this.watchTipoContribuinte();
  }

  getInfEmpresaByCpfCnpj() {
    this.messageService.clearAll();
    const cpfCnpj = (this.cadastroForm.get('cnpj').value || '').replace(/\D/g, '');

    return new Promise((resolve, reject) => {
      if (!validarCPF(cpfCnpj) && !validarCNPJ(cpfCnpj)) {
        this.messageService.error('Atenção! Verifique o CPF/CNPJ digitado.', 15000);
        resolve({});
        return;
      }

      if (validarCNPJ(cpfCnpj)) {
        this.loading['consultaCnpj'] = true;
        this.buscaAutomaticaCNPJService.consultarCnpj(cpfCnpj)
          .pipe(finalize(() => {
            this.loading['consultaCnpj'] = false;
            resolve({});
          }))
          .subscribe(res => {
            if (res.erro) {
              this.messageService.error(res.erro, 15000);
              return;
            }
            this.cadastroForm.controls['cep'].setValue(res.address.zip.replace(/\D/g, ''));
            this.cadastroForm.controls['numero'].setValue(res.address.number);
            this.cadastroForm.controls['razaoSocial'].setValue(res.company.name);
            this.cadastroForm.controls['endereco'].setValue(res.address.street);
            this.cadastroForm.controls['bairro'].setValue(res.address.district);
            if (res.registrations?.length > 0) {
              const inscricao = res.registrations.filter(x => x.state === res.address.state && x.enabled);
              if (inscricao.length > 0) {
                this.cadastroForm.controls['inscricaoEstadual'].setValue(inscricao[0].number || null);
              }
            }
            this.carregaViaCep();
            this.verificaInformacoesFiscais();
          },
            error => {
              this.messageService.error('Verifique se foi digitado corretamente ou preencha os campos manualmente...',
                15000, 'Não foram encontrados dados para o CNPJ informado!');
            });
      } else {
        this.verificaInformacoesFiscais();
        resolve({});
      }
    });
  }

  // carregaInscricaoEstadual() {
  //   if (this.cpfCnpj && this.cpfCnpj.length <= 11) {
  //     this.cadastroForm.get('inscricaoEstadual').setValue('ISENTO');
  //     this.cadastroForm.get('inscricaoEstadual').disable();
  //     $('#cep').focus();
  //   } else {
  //     this.cadastroForm.get('inscricaoEstadual').reset();
  //     this.cadastroForm.get('inscricaoEstadual').enable();
  //     this.getInfEmpresaByCpfCnpj();
  //   }
  // }

  loadData() {
    this.loading['estados'] = true;
    this.estadosService.get()
      .pipe(finalize(() => this.loading['estados'] = false))
      .subscribe(res => {
        this.estados = res;
      });

    this.tipoContribuinteChanged();
  }

  editar(id: number) {
    this.loadData();
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.loadData();
    this.id = null;
    this.modalCadastro.show();
    this.carregaCadastro(searchParam);
  }

  carregaCadastro(searchParam) {
    if (!validarCNPJ(searchParam) && !validarCPF(searchParam)) {
      setFieldFormValue(this.cadastroForm, 'razaoSocial', searchParam);
      setTimeout(() => {
        $('#cpfCnpj').focus();
      });
    } else {
      setFieldFormValue(this.cadastroForm, 'cnpj', searchParam);
      setTimeout(() => {
        $('#razaoSocial').focus();
      });
    }
  }

  carregaDados() {
    if (this.id) {
      this.alteraLoading(true);
      this.clienteService.getCadastroClienteById(this.id)
        .pipe(finalize(() => {
          this.alteraLoading(false)
        }))
        .subscribe((res) => {
          setFormValue(this.cadastroForm, res);

          if (res.cidade) {
            this.cadastroForm.get('estado').setValue(res.cidade.estadoId, { emitViewToModelChange: false });
            this.carregaCidades(res.cidade.estadoId);
          }
        });
    }
  }

  carregaCidades(estado) {
    this.loading['cidades'] = true;
    this.cidadesService.getByEstado(estado)
      .pipe(finalize(() => this.loading['cidades'] = false))
      .subscribe(res => this.cidades = res);
  }

  carregaCidadePorIbge(ibge) {
    this.loading['cidadeIbge'] = true;
    this.cidadesService.getByIbge(ibge)
      .pipe(finalize(() => this.loading['cidadeIbge'] = false))
      .subscribe(res => {
        if (res && res.length > 0) {
          this.cadastroForm.get('cidadeId').setValue(res[0].id);
        }
      });
  }

  alteraEstado(estado) {
    this.cadastroForm.get('cidadeId').setValue(null);
    this.carregaCidades(estado);
    this.cadastroForm.get('cidadeId').markAsPristine();
    this.cadastroForm.get('cidadeId').markAsUntouched();
  }

  carregaViaCep() {
    this.loading['endereco'] = true;
    this.cidadesService.getEnderecoByCep((this.cadastroForm.controls['cep'].value) || '')
      .then(res => res.json())
      .then(response => {
        if (response.bairro) { this.cadastroForm.get('bairro').setValue(response.bairro); }
        if (response.logradouro) { this.cadastroForm.get('endereco').setValue(response.logradouro); }
        if (response.uf && response.ibge) {
          const estado = this.estados.find(t => t.siglaEstado.toLowerCase() === response.uf.toLowerCase());
          this.cadastroForm.get('estado').setValue(estado.id);
          this.carregaCidadePorIbge(response.ibge);
        }
        this.loading['endereco'] = false;
      })
      .catch(() => {
        this.loading['endereco'] = false;
      });
  }

  get cpfCnpj(): string {
    return this.cadastroForm.get('cnpj').value;
  }

  retornaMascaraCpfCnpj() {
    if (this.cpfCnpj && this.cpfCnpj.length <= 11) {
      return '000.000.000-009';
    } else {
      return '00.000.000/0000-00';
    }
  }

  persistir() {
    this.alteraLoading(true);
    this.clienteService.postSalvar(this.cadastroForm.getRawValue())
      .pipe(finalize(() => this.alteraLoading(false)))
      .subscribe(res => {
        this.id = res.generatedId || null;
        this.modalCadastro.hide();
      }, (error) => {
        this.errors = error.msg.errors;
      });
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      if (!this.cadastroForm.value.endereco || !this.cadastroForm.value.cep || !this.cadastroForm.value.estado ||
        !this.cadastroForm.value.cidadeId || !this.cadastroForm.value.bairro || !this.cadastroForm.value.numero) {
        Swal({
          html: '<small>Informações de endereço são obrigatórias para emissão de documentos fiscais.</small>' +
            '<br /><strong>Deseja salvar mesmo assim?</strong><br />',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '<small>Salvar mesmo assim</small>',
          cancelButtonText: '<small>Editar antes de salvar</small>'
        }).then((result) => {
          if (result.value) {
            this.persistir();
          }
        });
      } else {
        this.persistir();
      }
    }
  }

  verificaNumeroTelefone(e) {
    if (e.target.value.length === 14 && e.key !== 'Backspace') {
      this.messageService.warning('Use o campo "Celular" para informar esse contato.', 5000, 'Atenção!')
    }
  }

  watchTipoContribuinte() {
    this.cadastroForm.get('indicadorIE').valueChanges.subscribe(() => {
      this.tipoContribuinteChanged();
    });
  }

  tipoContribuinteChanged() {
    if ([2, 9].includes(this.cadastroForm.get('indicadorIE').value)) {
      this.clienteIsento = true;
      this.cadastroForm.controls['inscricaoEstadual'].setValue('ISENTO');
    } else {
      this.clienteIsento = false;
    }
  }

  verificaInformacoesFiscais() {
    const cpfCnpj = (this.cadastroForm.get('cnpj').value || '').replace(/\D/g, '');
    const produtorRural = this.cadastroForm.controls['produtorRural'].value || false;

    if (validarCPF(cpfCnpj) && !produtorRural) {
      this.cadastroForm.controls['indicadorIE'].setValue(9); // nao contribuinte
      this.cadastroForm.controls['inscricaoEstadual'].setValue('ISENTO');
      this.cadastroForm.controls['clienteFinal'].setValue(true);
    }

    if (validarCNPJ(cpfCnpj)) {
      const inscricaoEstadual = this.cadastroForm.controls['inscricaoEstadual'].value || '';
      if (inscricaoEstadual && inscricaoEstadual !== 'ISENTO') {
        this.cadastroForm.controls['indicadorIE'].setValue(1); // contribuinte icms
        this.cadastroForm.controls['clienteFinal'].setValue(false);
      } else {
        this.cadastroForm.controls['indicadorIE'].setValue(2); // isento
        this.cadastroForm.controls['clienteFinal'].setValue(true);
      }
    }
  }
}
