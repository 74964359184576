import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { saveAs } from 'file-saver';
import { finalize } from 'rxjs/operators';
import { TipoFrete } from '../../../model/tipoFrete';
import { MessageService } from '../../../services/message.service';
import { NotasFiscaisService } from '../../../services/notas-fiscais.service';
import { ModalBasicComponent } from '../../../shared/modal-basic/modal-basic.component';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { getErrorMessage } from '../../../general/utils';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-detalhes-nfe',
  templateUrl: './detalhes-nfe.component.html',
  styleUrls: ['./detalhes-nfe.component.css']
})
export class DetalhesNfeComponent extends ModalCadastroComponent implements OnInit {

  constructor(
    private notasFiscaisService: NotasFiscaisService,
    private currencyPipe: CurrencyPipe,
    private tipoFrete: TipoFrete,
    private messageService: MessageService
  ) {
    super();
  }

  @ViewChild('modalConsulta', { static: true }) modalConsulta: ModalBasicComponent;

  nfe;
  clienteTab;
  valoresTab;
  despachoTab;
  notasFilhasTab;
  tabs;
  
  loading = [];

  colunasTitulos = [
    { label: 'Vencimento', col: 'vencimento', date: true, hide: false },
    { label: 'Valor', col: 'valorParcela', currency: true, hide: false }
  ];

  ngOnInit() {
    this.tabs = [
      ['Destino', this.clienteTab],
      ['Valores e Dados Adicionais', this.valoresTab],
      ['Despacho', this.despachoTab],
      ['Itens'],
      ['Notas Filhas', this.notasFilhasTab],
    ];

    if (this.prop) {
      this.loading['detalhes'] = true;
      this.modalConsulta.show();

      this.notasFiscaisService.getDetalhesNfe(this.prop.id).subscribe(res => {
        if (res) {
          this.nfe = res;
          this.generateInfo();
        }
      });
    }
  }

  generateInfo() {
    this.configuraCamposClientes({ ...this.nfe.cliente, representante: this.nfe.representante, pedido: this.nfe.pedido });
    this.configuraCamposValores(this.nfe);
    this.configuraCamposDespacho(this.nfe);
    this.configuraTabelNotasFilhas(this.nfe);
    this.loading['detalhes'] = false;
  }

  configuraTabelNotasFilhas(dados) {
    const status = {
      'A': 'Aprovada',
      'C': 'Cancelada',
      'R': 'Rejeitada',
      'I': 'Inutilizada',
      'D': 'Denegada',
      'U': 'Rascunho',
      '0': 'Não enviada',
    }

    this.tabs[4][1] = dados.notasFilhas?.map(nota => {
      let statusDescricao = "";
      if (nota.status === 'A' && nota.notaFiscalEstornoID) {
        statusDescricao = 'Estornada';
      } else {
        statusDescricao = status[nota.status] || 'Desconhecido';
      }

      return {
        ...nota,
        statusDescricao: statusDescricao,
      }
    });
  }

  async imprimirNfe(notaFiscalID, numeroNotaFiscal) {
    this.loading['impressao' + notaFiscalID] = true;

    try {
      const res = await firstValueFrom(this.notasFiscaisService.imprimirNfe(notaFiscalID, 'pdf'));
      if (res && (res as Blob).type.includes('pdf')) {
        saveAs(res, `nfe_${numeroNotaFiscal}.pdf`);
      }
    } catch (err) {
      this.messageService.error(getErrorMessage(err), 5000);
    }

    this.loading['impressao' + notaFiscalID] = false;
  }

  configuraCamposClientes(dados) {
    this.tabs[0][1] = [
      { label: 'Nome', value: dados.razaoSocial },
      { label: 'Endereço', value: dados.endereco },
      [{ label: 'Bairro', value: dados.bairro }, { label: 'CNPJ', value: dados.cnpj }],
      [{ label: 'CEP', value: dados.cep }, { label: 'Cidade', value: dados.cidade.nome }],
      [{ label: 'Estado', value: dados.cidade.estado.nome }, { label: 'Telefone', value: dados.telefone }],
      [{ label: 'E-mail', value: dados.email?.replace(/;/g, '; ') }, { label: 'Vendedor', value: dados.representante ? dados.representante.nome : null }],
      { label: 'Pedido', value: dados.pedido ? dados.pedido.numeroPedido : null }
    ];
  }

  configuraCamposValores(dados) {
    this.tabs[1][1] = [
      [{
        label: 'Valor dos produtos',
        value: this.currencyPipe.transform(dados.valorProdutos, 'BRL', 'symbol', '1.2-2')
      },
      {
        label: 'Valor total da NF-e',
        value: this.currencyPipe.transform(dados.valorTotal, 'BRL', 'symbol', '1.2-2')
      }],
      { label: 'Dados adicionais', value: dados.dadosAdicionais }
    ];
  }

  configuraCamposDespacho(dados) {
    this.tabs[2][1] = [
      { label: 'Transportador', value: (dados.fornecedor ? dados.fornecedor.nome : 'N/A') },
      [{ label: 'Tipo de frete', value: dados.frete ? this.tipoFrete.retornaDescricaoTipoFrete(dados.frete) : null }, { label: 'Fone transportador', value: dados.fornecedor ? (dados.fornecedor.telefone) : 'N/A' }],
      [{ label: 'Quant. volumes', value: dados.quantidadeVolumes }, { label: 'Espécies', value: dados.especie }],
      [{ label: 'Peso bruto', value: dados.pesoBruto ? Number(dados.pesoBruto).toFixed(2) : null }, { label: 'Peso líquido', value: dados.pesoLiquido ? (Number(dados.pesoLiquido).toFixed(2)) : null }]
    ];
  }

  show() { }

  isArray(campo) {
    return Array.isArray(campo);
  }

  imprimirEtiquetas() {
    this.messageService.clearAll();

    if (!this.nfe.quantidadeVolumes) {
      this.messageService.error('Quantidade de volumes deve ser maior que zero!', 5000);
    } else {
      this.loading['etiquetas'] = true;

      this.notasFiscaisService.imprimirEtiqueta(this.nfe.id)
        .pipe(finalize(() => this.loading['etiquetas'] = false))
        .subscribe(res => {
          saveAs(res, `etiquetaNfe_${this.nfe.numeroNotaFiscal}.pdf`);
        });
    }
  }

  consultarNfe() {
    this.loading['detalhes'] = true;
    this.notasFiscaisService.consultarNfe(this.prop.id)
      .pipe(finalize(() => { this.loading['detalhes'] = true; }))
      .subscribe((res) => {
        if (res.status === 'R') {
          this.messageService.error(res.codigo + ' - ' + res.mensagem, 10000);
        } else {
          this.messageService.success(res.codigo + ' - ' + res.mensagem, 10000);
        }

        this.notasFiscaisService.getDetalhesNfe(this.prop.id).subscribe(nfe => {
          if (nfe) {
            this.nfe = nfe;
            this.generateInfo();
          }
        });
      }, (res) => {
        this.messageService.error(res.error.errors[0], 10000);
      });
  }

  async downloadXMLDI() {
    try {
      this.loading['downloadDI'] = true;
      const res = await this.notasFiscaisService.baixarXmlDI(this.nfe.id).toPromise();
      if (res) {
        const numeroDI = this.nfe.numeroDI.replace(/[^0-9]/g, '');
        saveAs(res, `di_${numeroDI}.xml`);
      }
    } catch (err) {
      console.error(err);
      this.messageService.error('Falha ao baixar o XML da DI: ' + getErrorMessage(err), 5000);
    } finally {
      this.loading['downloadDI'] = false;
    }
  }
}
