export class TipoSituacaoTributariaCOFINS { 
    tipo: string;
    descricao: string;

    constructor(tipo?: string, descricao?: string){
        this.tipo = tipo;
        this.descricao = descricao;
    }

    Listar(): TipoSituacaoTributariaCOFINS[]{
        return [
            new TipoSituacaoTributariaCOFINS("01", "COFINS 01 - Operação Tributável com Alíquota Básica"),
            new TipoSituacaoTributariaCOFINS("02", "COFINS 02 - Operação Tributável com Alíquota Diferenciada"),
            new TipoSituacaoTributariaCOFINS("03", "COFINS 03 - Operação Tributável com Alíquota por Unidade de Medida de Produto"),
            new TipoSituacaoTributariaCOFINS("04", "COFINS 04 - Operação Tributável Monofásica - Revenda a Alíquota Zero"),
            new TipoSituacaoTributariaCOFINS("05", "COFINS 05 - Operação Tributável por Substituição Tributária"),
            new TipoSituacaoTributariaCOFINS("06", "COFINS 06 - Operação Tributável a Alíquota Zero"),
            new TipoSituacaoTributariaCOFINS("07", "COFINS 07 - Operação Isenta da Contribuição"),
            new TipoSituacaoTributariaCOFINS("08", "COFINS 08 - Operação sem Incidência da Contribuição"),
            new TipoSituacaoTributariaCOFINS("09", "COFINS 09 - Operação com Suspensão da Contribuição"),
            new TipoSituacaoTributariaCOFINS("49", "COFINS 49 - Outras Operações de Saída"),
            new TipoSituacaoTributariaCOFINS("50", "COFINS 50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno"),
            new TipoSituacaoTributariaCOFINS("51", "COFINS 51 - Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno"),
            new TipoSituacaoTributariaCOFINS("52", "COFINS 52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação"),
            new TipoSituacaoTributariaCOFINS("53", "COFINS 53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"),
            new TipoSituacaoTributariaCOFINS("54", "COFINS 54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"),
            new TipoSituacaoTributariaCOFINS("55", "COFINS 55 - Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"),
            new TipoSituacaoTributariaCOFINS("56", "COFINS 56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação"),
            new TipoSituacaoTributariaCOFINS("60", "COFINS 60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno"),
            new TipoSituacaoTributariaCOFINS("61", "COFINS 61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno"),
            new TipoSituacaoTributariaCOFINS("62", "COFINS 62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação"),
            new TipoSituacaoTributariaCOFINS("63", "COFINS 63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"),
            new TipoSituacaoTributariaCOFINS("64", "COFINS 64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"),
            new TipoSituacaoTributariaCOFINS("65", "COFINS 65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"),
            new TipoSituacaoTributariaCOFINS("66", "COFINS 66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não- Tributadas no Mercado Interno, e de Exportação"),
            new TipoSituacaoTributariaCOFINS("67", "COFINS 67 - Crédito Presumido - Outras Operações"),
            new TipoSituacaoTributariaCOFINS("70", "COFINS 70 - Operação de Aquisição sem Direito a Crédito"),
            new TipoSituacaoTributariaCOFINS("71", "COFINS 71 - Operação de Aquisição com Isenção"),
            new TipoSituacaoTributariaCOFINS("72", "COFINS 72 - Operação de Aquisição com Suspensão"),
            new TipoSituacaoTributariaCOFINS("73", "COFINS 73 - Operação de Aquisição a Alíquota Zero"),
            new TipoSituacaoTributariaCOFINS("74", "COFINS 74 - Operação de Aquisição sem Incidência da Contribuição"),
            new TipoSituacaoTributariaCOFINS("75", "COFINS 75 - Operação de Aquisição por Substituição Tributária"),
            new TipoSituacaoTributariaCOFINS("98", "COFINS 98 - Outras Operações de Entrada"),
            new TipoSituacaoTributariaCOFINS("99", "COFINS 99 - Outras Operações")
        ]
    }
}