import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { CentrosCustosService } from '../../../services/centros-custos.service';
import { setFieldFormValue, setFormValue } from '../../../general/utils';
import { MessageService } from '../../../services/message.service';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-centro-custo',
  templateUrl: './centro-custo.component.html',
  styleUrls: ['./centro-custo.component.css']
})
export class CentroCustoComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalCadastro", { static: true }) modalCadastro;

  constructor(private centroCustoService: CentrosCustosService, private message: MessageService) {
    super();
  }

  cadastroForm = new FormGroup({
    id: new FormControl(null),
    ccus: new FormControl(null, [Validators.required]),
    inativo: new FormControl(false, [Validators.required])
  })

  ngOnInit() {

  }


  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    setFieldFormValue(this.cadastroForm, 'ccus', searchParam);
    this.modalCadastro.show();
  }

  carregaDados() {
    if (this.id) {
      this.alteraLoading(true);
      this.centroCustoService.getCentroCustoById(this.id)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe((res) => {
          setFormValue(this.cadastroForm, res);
        })
    }
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      this.alteraLoading(true);
      this.centroCustoService.post(this.cadastroForm.value)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe(res => {
          this.id = res.generatedId || this.id || null;
          this.message.success('Centro de custo cadastrado!');
          this.modalCadastro.hide();
        }, (error) => {
          this.errors = error.msg.errors;
        })
    }
  }

  delete() {
    this.centroCustoService.delete(this.id)
      .subscribe(res => {
        this.message.success('Centro de custo excluído!');
        this.modalCadastro.hide();
      }, e => {
        const erro = e.error && e.error.errors[0] ? e.error.errors[0] : 'Ocorreu um erro inesperado ao tentar realizar a exclusão!';
        this.message.error(erro, 5000, 'Centro de custo não pode ser excluído!');
      })
  }
}
