import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GetBandeiraCartaoCredito } from '../../../model/cartoesCredito';
import swal from 'sweetalert2';
import { TitulosCobrancaService } from '../../../services/titulos-cobranca.service';
import { WebHookService } from '../../../services/webhook.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { getDeviceType } from '../../../general/utils';
import { AdminLayoutEventBusService } from '../../../services/admin-layout-event-bus.service';
import { CalculosPagamentoTitulo } from '../../../model/CalculosPagamentoTitulo';
import { CartoesService } from '../../../services/cartoes.service';
import { CartoesViewModel } from '../../../model/CartoesViewModel';
import { fadeInOutTranslate } from '../../../shared/elements/animation';
import { CadastroCartoesViewModel } from '../../../model/CadastroCartoesViewModel';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-forma-pagamento-modal',
  templateUrl: './forma-pagamento-modal.component.html',
  styleUrls: ['./forma-pagamento-modal.component.css'],
  animations: [ fadeInOutTranslate ]
})
export class FormaPagamentoModalComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modal', { static: true }) modalPagamento;
  @Input() titulos = [];
  @Input() formaPagamentoPadrao = 'N';
  formaSelecionada = null;
  formasPagamento = FormasPagamento;
  statusPagamento = StatusPagamento.AGUARDANDO_PAGAMENTO;
  iconeStatusPagamento = IconeStatusPagamento.AGUARDANDO_PAGAMENTO;
  qrCodeUrl = null;
  codigoPix = null;
  onScreen = false;
  errorMessage = null;
  creditForm: FormGroup;
  boletoUrl: string;
  linhaDigitavel: string;
  calculos: CalculosPagamentoTitulo[];
  stopPooling = false;
  valoresDevidos: CalculosPagamentoTitulo[];
  cartoes: CartoesViewModel[];
  cadastrandoCartao = false;
  @ViewChild('cardNumberInput', { static: false }) set userContent(element: ElementRef) {
    if (element) {
      element.nativeElement.focus();
    }
  };

  constructor(
    private webHookService: WebHookService,
    private titulosCobrancaService: TitulosCobrancaService,
    private adminLayoutEventBus: AdminLayoutEventBusService,
    private cartoesService: CartoesService,
  ) {
    super();
  }

  get disableCancel() {
    return this.statusPagamento == StatusPagamento.PAGAMENTO_EFETUADO;
  }

  get totalDespesasFinanceiras() {
    return this.calculos && this.calculos.length > 0
      ? this.calculos.reduce((acc, c) => acc + c.valorDespesasFinanceiras, 0)
      : 0;
  }

  get totalDescontoPontualidade() {
    return this.calculos && this.calculos.length > 0
      ? this.calculos.reduce((acc, c) => acc + c.valorDescontoPontualidade, 0)
      : 0;
  }

  get totalJuros() {
    return this.calculos && this.calculos.length > 0
      ? this.calculos.reduce((acc, c) => acc + c.valorJuros, 0)
      : 0;
  }

  get totalPagar() {
    return this.calculos && this.calculos.length > 0
      ? this.calculos.reduce((acc, c) => acc + c.valorPagar, 0)
      : 0;
  }

  get totalPagarBoleto() {
    return this.calculos && this.calculos.length > 0
      ? this.calculos.reduce((acc, c) => acc + c.valorPagar - c.valorDesconto, 0)
      : 0;
  }

  get totalDescontoFormaPagamento() {
    return this.calculos && this.calculos.length > 0
      ? this.calculos.reduce((acc, c) => acc + c.valorDescontoFormaPagamento, 0)
      : 0;
  }

  get totalAbatimento() {
    return this.calculos && this.calculos.length > 0
      ? this.calculos.reduce((acc, c) => acc + c.valorAbatimento, 0)
      : 0;
  }

  get statusPagamentoFormatado() {
    const statusMensagens = {
      [StatusPagamento.PAGAMENTO_EFETUADO]: 'Pagamento Efetuado!',
      [StatusPagamento.AGUARDANDO_PAGAMENTO]: 'Aguardando Pagamento',
      [StatusPagamento.PAGAMENTO_ERRO]: 'Erro no Pagamento',
    };

    return statusMensagens[this.statusPagamento] || 'Pagamento Efetuado!';
  }

  get valorDevidoTotal() {
    return this.valoresDevidos && this.valoresDevidos.length > 0
      ? this.valoresDevidos.reduce((acc, x) => acc + x.valorPagar, 0)
      : 0;
  }

  ngOnInit() {
    this.creditForm = new FormGroup({
      cardNumber: new FormControl(null, [Validators.required, Validators.minLength(16)]),
      holder: new FormControl(null, [Validators.required]),
      expirationDate: new FormControl(null, [Validators.required, Validators.minLength(6)]),
      securityCode: new FormControl(null, [Validators.required, Validators.minLength(3)]),
    });

    document.getElementsByClassName('modal-dialog')[2]
      .classList.add('modal-dialog-centered');

    if (this.formaPagamentoPadrao === 'P') {
      this.setFormaPagamento(this.formasPagamento.pix);
    } else if (this.formaPagamentoPadrao === 'C') {
      this.setFormaPagamento(this.formasPagamento.credito);
    } else if (this.formaPagamentoPadrao == 'N') {
      this.setFormaPagamento(this.formasPagamento.boleto);
    } else if (this.formaPagamentoPadrao == 'B') {
      this.setFormaPagamento(this.formasPagamento.boletoNovo);
    }

    this.carregarValorDevido();
    this.carregarCartoesDeCredito();
  }

  setFormaPagamento(forma = 0) {
    this.onScreen = true;
    this.formaSelecionada = forma;
    this.errorMessage = null;

    if (this.formaSelecionada === this.formasPagamento.pix)
      this.pix();
    else if (this.formaSelecionada === this.formasPagamento.boleto)
      this.boleto();
    else if (this.formaSelecionada === this.formasPagamento.boletoNovo)
      this.boletoNovo();
    else if (this.formaSelecionada === this.formasPagamento.credito)
      this.credito();
  }

  async credito() {
    const res = await this.titulosCobrancaService.calcularPagamentoTitulosCredito(this.titulos).toPromise();
    this.calculos = res;
  }

  async boletoNovo() {
    try {
      this.resetaResposta();
      const res = await this.titulosCobrancaService.gerarPagamentoMensalidadeBoleto(this.titulos).toPromise() as GerarBoletoResponse;
      if (!res || res.error) {
        this.iconeStatusPagamento = IconeStatusPagamento.PAGAMENTO_ERRO;
        this.statusPagamento = StatusPagamento.PAGAMENTO_ERRO;
        this.errorMessage = res.error || "Erro desconhecido";
        return;
      }

      this.boletoUrl = res.urlBoleto;
      this.linhaDigitavel = res.linhaDigitavel;
      this.calculos = res.calculos;
    } catch (error) {
      this.statusPagamento = StatusPagamento.PAGAMENTO_ERRO;
      this.iconeStatusPagamento = IconeStatusPagamento.PAGAMENTO_ERRO;
      this.errorMessage = 'Falha desconhecida ao gerar boleto';
      console.error(error);
    }
  }

  async boleto() {
    try {
      for (const titulo of this.titulos) {
        const bol = await this.titulosCobrancaService
          .imprimirBoleto(titulo.id)
          .toPromise();
        saveAs(bol, 'boleto.pdf');
      }
    } catch (error) {
      console.error(error);
      swal(
        'Error',
        'Ocorreu um erro ao realizar a impressão, entre em contato com a dimensão!',
        'error'
      );
    } finally {
      this.onClose();
    }
  }

  async pix() {
    // Se o usuário já criou um QR Code antes, não irá criar novamente
    // até que a tela seja atualizada.
    if (this.qrCodeUrl && this.statusPagamento !== StatusPagamento.PAGAMENTO_ERRO) {
      return;
    }

    try {
      this.resetaResposta();
      const obj = await this.titulosCobrancaService.gerarPagamentoMensalidadePix(this.titulos).toPromise() as GerarPixResponse;
      if (!obj || obj.error) {
        this.iconeStatusPagamento = IconeStatusPagamento.PAGAMENTO_ERRO;
        this.statusPagamento = StatusPagamento.PAGAMENTO_ERRO;
        this.errorMessage = obj.error || 'Erro desconhecido';
        return;
      }

      this.calculos = obj.calculos;
      this.qrCodeUrl = obj.retornoSafe2Pay.responseDetail.qrCodeBase64;
      this.codigoPix = obj.retornoSafe2Pay.responseDetail.key;
      const idTransaction = obj.retornoSafe2Pay.responseDetail.idTransaction;

      // Espera 30 segundos para depois começar a buscar o status do pagamento
      // 15 sec aqui + 15 sec depois
      await new Promise(_ => setTimeout(_, 15000));

      let pagamentoEfetuado = false;
      while (!pagamentoEfetuado) {
        await new Promise(_ => setTimeout(_, 15000));
        const status = await this.webHookService.retornaStatusPagamento(idTransaction).toPromise();
        if (status.error) {
          this.statusPagamento = StatusPagamento.PAGAMENTO_ERRO;
          this.iconeStatusPagamento = IconeStatusPagamento.PAGAMENTO_ERRO;
          this.errorMessage = `${status.error} Toque aqui para gerar um novo código Pix.`;
          return;
        }

        pagamentoEfetuado = status;
        if (this.stopPooling) break;
      }

      if (!pagamentoEfetuado) {
        return;
      }

      this.onScreen = false;
      this.onClose(true);
      this.adminLayoutEventBus.atualizarInformacaoFaturas();
      swal('Pagamento efetuado com sucesso!', '', 'success');
    } catch (error) {
      this.statusPagamento = StatusPagamento.PAGAMENTO_ERRO;
      this.iconeStatusPagamento = IconeStatusPagamento.PAGAMENTO_ERRO;
      this.errorMessage = `Toque aqui para gerar um novo código Pix.`;
      console.error(error);
    }
  }

  resetaResposta() {
    this.iconeStatusPagamento = IconeStatusPagamento.AGUARDANDO_PAGAMENTO;
    this.statusPagamento = StatusPagamento.AGUARDANDO_PAGAMENTO;
    this.errorMessage = null;
    this.qrCodeUrl = null;
    this.codigoPix = null;
    this.calculos = null;
  }

  show() {
    this.modalPagamento.show();
  }

  onClose(atualizaPagamentos: boolean = false) {
    this.stopPooling = true;
    this.closed.emit(atualizaPagamentos);
  }

  copiar(texto: string) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', texto);
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
  }

  copiarCodigoPix() {
    this.copiar(this.codigoPix);
  }

  async copiarLinhaDigitavel() {
    await this.titulosCobrancaService.visualizouBoleto(this.titulos[0].id).toPromise();
    this.copiar(this.linhaDigitavel);
  }

  async pagamentoCredito() {
    try {
      this.loading['credito'] = true;
      const obj = {
        cartaoID: this.cartoes[0].id,
        titulos: this.titulos.map(x => x.id),
      };

      const resCredito = await this.titulosCobrancaService.gerarPagamentoMensalidadeCredito(obj).toPromise();
      if (resCredito.error) {
        const message = this.retornaStatusCartao(resCredito.code || '0');
        this.errorMessage = `${resCredito.error}. ${message}`;
        return;
      }

      const titulosEmAberto = await this.titulosCobrancaService
        .getTitulosMensalidadeDimensao({ index: 1, order: { dir: '', prop: '' }}, false)
        .toPromise();
      const aVencerOuVencidos = titulosEmAberto.items.filter(x => x.vencimentoMenorOuIgualAHoje)
      if (aVencerOuVencidos.length === 0 && !this.cartoes[0].debitoAutomatico) {
        const ativarDebAut = await swal({
          title: 'Pagamento efetuado com sucesso!',
          text: 'Deseja ativar o débito automático para as próximas faturas?',
          type: 'success',
          confirmButtonText: 'Sim, ativar débito automático',
          cancelButtonText: 'Não',
          showCancelButton: true,
        });

        if (ativarDebAut.value) {
          await this.cartoesService.habilitarDebitoAutomatico().toPromise();
        }
      } else {
        swal('Pagamento efetuado com sucesso!', '', 'success');
      }

      this.onClose(true);
    } catch (error) {
      console.error(error);
      this.errorMessage = 'Erro desconhecido.';
    } finally {
      this.loading['credito'] = false;
    }
  }

  async cadastrarCartao() {
    try {
      this.cadastrandoCartao = true;
      this.loading['cadastrarCartao'] = true;
      this.errorMessage = "";
      const obj: CadastroCartoesViewModel = {
        numeroCartao: this.creditForm.get('cardNumber').value,
        nomeTitular: this.creditForm.get('holder').value,
        validade: this.creditForm.get('expirationDate').value,
        cvv: this.creditForm.get('securityCode').value,
        debitoAutomatico: false,
      };

      const res = await this.cartoesService.cadastrarCartaoFinanceiro(obj).toPromise();
      if (res.error) {
        this.loading['cadastrarCartao'] = false;
        this.errorMessage = res.error;
        return;
      }

      this.carregarCartoesDeCredito();
      this.loading['cadastrarCartao'] = false;
    } catch (error) {
      console.error(error);
      this.loading['cadastrarCartao'] = false;
      swal(
        'Erro',
        error.error.errors ? error.error.errors[0] : error.msg.errors[0],
        'error'
      );
    }
  }

  getBandeiraCartao() {
    const bandeira = this.retornaBandeiraCartao(this.creditForm.value.cardNumber);
    const ccicon = document.getElementById('ccicon');
    ccicon.innerHTML = GetBandeiraCartaoCredito(bandeira);
  }

  retornaBandeiraCartao(cardNumber: string): string {
    if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cardNumber)) return 'Visa';
    else if (/^5[1-5][0-9]{14}$/.test(cardNumber)) return 'Mastercard';
    else if (/^3[47][0-9]{13}$/.test(cardNumber)) return 'Amex';
    else if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(cardNumber)) return 'Diners';
    else if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(cardNumber)) return 'Discover';
    else if (/^(?:2131|1800|35\d{3})\d{11}$/.test(cardNumber)) return 'JCB';
    else if (/^(606282\d{10}(\d{3})?)|(3841\d{15})$/.test(cardNumber)) return 'Hiper';
    else return null;
  }

  retornaStatusCartao(cod: string): string {
    const codigos = {
      '0': '',
      '05': 'Não Autorizada.',
      '57': 'Cartão Expirado.',
      '78': 'Cartão Bloqueado.',
      '99': 'Time Out.',
      '70': 'Cartão Cancelado.',
      '77': 'Problemas com o Cartão de Crédito.',
      '83': 'Transação não autorizada.',
    };
    return codigos[cod] || '';
  }

  fechar() {
    this.onClose();
  }

  cancelarPagamento() {
    swal({
      title: 'Deseja mesmo fechar?',
      text: 'Se o pagamento já foi efetuado é recomendado aguardar a confirmação do pagamento (cerca de um minuto).',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Não',
      cancelButtonText: 'Sim',
      allowOutsideClick: false,
    }).then(res => {
      if (res.dismiss) {
        this.onClose();
      }
    });
  }

  cancelarPagamentoBoleto() {
    this.onClose(true);
  }

  async abrirBoleto() {
    if (!this.boletoUrl)
      return;

    await this.titulosCobrancaService.visualizouBoleto(this.titulos[0].id).toPromise();
    window.open(this.boletoUrl, '_blank');
  }

  get boletoNovoContentClass() {
    const mobile = getDeviceType() === 'mobile';

    if (mobile) {
      return {
        'd-flex': true,
        'flex-column-reverse': true,
        'align-items-center': true,
      };
    } else {
      return {
        'app-modal-double-body': true
      }
    }
  }

  async carregarValorDevido() {
    try {
      const res = await this.titulosCobrancaService.calcularFaturasEmAbertoCredito().toPromise();
      this.valoresDevidos = res;
    } catch (err) {
      console.error(err);
      swal('Erro', 'Falha ao buscar o valor das faturas em aberto.', 'error');
    }
  }

  async carregarCartoesDeCredito() {
    this.loading['cartoes'] = true;
    try {
      const res = await this.cartoesService.getCartoes().toPromise();
      this.cartoes = res;
    } catch (err) {
      console.error(err);
      swal('Erro', 'Falha ao buscar os cartões de crédito.', 'error');
    } finally {
      this.loading['cartoes'] = false;
    }
  }
}

interface PaymentResponse {
  responseDetail: {
    idTransaction: number;
    status: string;
    message: string;
    description: string;
    qrCode: string;
    qrCodeBase64: any[];
    key: string;
  };
  hasError: boolean;
}

interface GerarPixResponse {
  calculos: CalculosPagamentoTitulo[];
  retornoSafe2Pay: PaymentResponse;
  error: string;
}

interface GerarBoletoResponse {
  calculos: CalculosPagamentoTitulo[];
  urlBoleto: string;
  linhaDigitavel: string;
  error: string;
}

enum FormasPagamento {
  pix,
  credito,
  boleto,
  boletoNovo
}

enum StatusPagamento {
  PAGAMENTO_EFETUADO = 'sucesso',
  AGUARDANDO_PAGAMENTO = 'aguardando',
  PAGAMENTO_ERRO = 'erro',
}

enum IconeStatusPagamento {
  PAGAMENTO_EFETUADO = 'fa fa-check-circle',
  AGUARDANDO_PAGAMENTO = 'fa fa-clock-o slow-spin',
  PAGAMENTO_ERRO = 'fa fa-times-circle',
}
