import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class TipoNfeService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getPaginado(pagina: number, nome: string) {
        return this.http
            .get(this.UrlService + `tipoNfe/paginado?pageIndex=` + pagina + '&search=' + encodeURIComponent(nome))
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    getById(id: number) {
        if (!id) {
            return undefined;
        }
        return this.http
            .get(this.UrlService + 'tipoNfe/' + id)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }
}
