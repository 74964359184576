import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CidadesService } from '../../../services/cidades.service';
import { EstadosService } from '../../../services/estados.service';
import { NotasFiscaisEnderecosService } from '../../../services/notas-fiscais-enderecos.service';

@Component({
  selector: 'app-modal-cadastro-endereco-retirada',
  templateUrl: './modal-cadastro-endereco-retirada.component.html',
  styleUrls: ['./modal-cadastro-endereco-retirada.component.css']
})
export class ModalCadastroEnderecoRetiradaComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalCadastro", { static: false }) modalCadastro;
  estados = [];
  cidades = [];
  cadastroForm = new FormGroup({
    id: new FormControl(null),
    complemento: new FormControl(null),
    cep: new FormControl(null, [Validators.required]),
    numero: new FormControl(null, [Validators.required]),
    bairro: new FormControl(null, [Validators.required]),
    endereco: new FormControl(null, [Validators.required]),
    cidadeId: new FormControl(null, [Validators.required]),
    estadoId: new FormControl(null, [Validators.required]),
  });

  constructor(
    private nfEnderecosSerivce: NotasFiscaisEnderecosService,
    private cidadesService: CidadesService,
    private estadosService: EstadosService,
  ) {
    super();
  }

  ngOnInit() {
    this.carregaEstados();
  }

  async submitForm() {
    if (this.cadastroForm.invalid) {
      return;
    }

    try {
      this.loading['submit'] = true;
      const res = await this.nfEnderecosSerivce.addOrUpdate(this.cadastroForm.value).toPromise();
      this.id = res;
      this.onClose();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading['submit'] = false;
    }
  }

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  async carregaDados() {
    if (!this.id) {
      return;
    }
    await this.carregaEstados();

    try {
      this.loading['registro'] = true;
      const res = await this.nfEnderecosSerivce.getByIdCombobox(this.id).toPromise();
      this.cadastroForm.get('id').setValue(res.id);
      this.cadastroForm.get('complemento').setValue(res.complemento);
      this.cadastroForm.get('numero').setValue(res.numero);
      this.cadastroForm.get('bairro').setValue(res.bairro);
      this.cadastroForm.get('endereco').setValue(res.endereco);
      this.cadastroForm.get('cep').setValue(res.cep);
      if (res.estadoId) {
        this.cadastroForm.get('estadoId').setValue(res.estadoId);
        await this.carregaCidades(res.estadoId);
        this.cadastroForm.get('cidadeId').setValue(res.cidadeId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loading['registro'] = false;
    }
  }

  cadastrar(searchParam) {
    this.id = null;
    this.cadastroForm.get('endereco').setValue(searchParam);
    this.modalCadastro.show();
  }

  alteraEstado(estado) {
    this.cadastroForm.get('cidadeId').setValue(null);
    this.carregaCidades(estado);
    this.cadastroForm.get('cidadeId').markAsPristine();
    this.cadastroForm.get('cidadeId').markAsUntouched();
  }

  async carregaEstados() {
    this.loading['estados'] = true;
    this.estados = await this.estadosService.get().toPromise();
    this.loading['estados'] = false;
  }

  async carregaCidades(estado) {
    this.loading['cidades'] = true;
    this.cidades = await this.cidadesService.getByEstado(estado).toPromise();
    this.loading['cidades'] = false;
  }


  buscaEstadoNomeSigla(term: string, item: any) {
    term = term.toLowerCase();
    return item.nome.toLowerCase().indexOf(term) > -1 || item.siglaEstado.toLowerCase().indexOf(term) > -1;
  }


  async getEndereco() {
    const cep = this.cadastroForm.get('cep').value || '';
    if (cep.length !== 8) {
      return;
    }

    try {
      this.loading['endereco'] = true;
      const response = await (await this.cidadesService.getEnderecoByCep(cep)).json();
      if (!response || !response.cep) {
        this.loading['endereco'] = false;
        return;
      }

      const res = await this.estadosService.getByUf(response.uf).toPromise()
      this.cadastroForm.get('estadoId').setValue(res[0].id, { emitViewToModelChange: false });

      await this.carregaCidades(res[0].id)
      const cidade = this.cidades.find(t => t.codigoIbge === response.ibge);
      if (cidade) {
        this.cadastroForm.get('cidadeId').setValue(cidade.id);
      }

      this.cadastroForm.get('bairro').setValue(response.bairro);
      this.cadastroForm.get('endereco').setValue(response.logradouro);
    } catch (error) {
      console.error(error);
    } finally {
      this.loading['endereco'] = false;
    }
  }

}
