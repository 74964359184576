import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { catchError, map } from 'rxjs/operators';
import { MessageService } from './message.service';

@Injectable()
export class ManifestoDestinatarioService extends BaseService {
  constructor(private http: HttpClient, private message: MessageService) { super(); }

  getWithFilters(consulta: any) {
    return this.http
      .post(this.UrlService + 'manifestodestinatario/buscar', consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  atualizarMDe() {
    return this.http
      .post(this.UrlService + 'manifestodestinatario/atualizarBase', {})
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  atualizarMDePorChave(chave: string) {
    return this.http
      .post(this.UrlService + 'manifestodestinatario/atualizarBasePorChave', chave)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  consultar(chave: string) {
    return this.http
      .post(this.UrlService + 'manifestodestinatario/consultarManifesto', chave)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  manifestarMDe(nfceId: number, eventoCodigo: string, motivo: string = '') {
    const evento: Object = {
      'MdeID': nfceId,
      'Evento': eventoCodigo,
      'Motivo': motivo
    };

    return this.http
      .post(this.UrlService + 'manifestodestinatario/manifestar', evento)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  statusBuscaDocumentos() {
    return this.http
      .get(this.UrlService + 'manifestodestinatario/statusBuscaDocumentos')
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  verificarConexao() {
    return this.http
      .get(this.UrlService + 'manifestodestinatario/verificarConexao')
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  listarNotasCorrespondentes(notaFiscalEntradaID: any) {
    return this.http
      .get(this.UrlService + 'manifestodestinatario/listarNotasCorrespondentes/' + notaFiscalEntradaID)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
