import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class BuscaAutomaticaCNPJService extends BaseService {

  constructor(
    private http: HttpClient
  ) { super(); }

  // buscarCNPJ(cnpj: string, uf: any) {

  //   return this.http.post('http://www.dimensaosistemas.com/UnidadeEmpresa/ConsultaDadosSefaz',
  //     { uf: uf, cnpj: cnpj.replace(/\D/g, '') })
  //     .pipe(map((res: any) => {
  //       return res;
  //     }), catchError(super.serviceError));
  // }

  consultarCnpj(cnpj: string) {
    return this.http.get(this.UrlService + 'Clientes/consultaCnpj/' + cnpj)
      .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
  }
}
