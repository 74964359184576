import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {

  constructor() { }

  @Input() height = 5; //passar em px
  @Input() width = 0; //passar em %
  @Output() finalizeProgress = new EventEmitter();

  ngOnInit() {
  }

  get getHeight() {
    return this.height + "px";
  }

  get getWidth() {
    return this.width + "%";
  }

  // Passar em segundos
  completeProgress(time = 7) {
    let totaTime = time * 1000;
    let intervalo = totaTime / ((100 - this.width) / 2)
    let interval = setInterval(function () {
      if (this.width < 100) {
        this.width += 2;
      } else {
        this.finalizeProgress.emit(100)
        clearInterval(interval)
      }
    }.bind(this), intervalo)
  }

  zeraProgresso() {
    this.width = 0;
  }

}
