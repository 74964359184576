<form [formGroup]="form">
  <div class="row form-group my-2">
    <div *ngFor="let control of form.controls | keyvalue: originalOrder" [ngClass]="colSize">
      <app-dim-checkbox [label]="getControlLabel(control)" [formControlName]="control.key"
        (change)="handleOnChanged(control)"
        ></app-dim-checkbox>
    </div>
    <div class="col-12">
      <show-errors *ngIf="mostrarErro" [control]="firstFormControl" [validateTouched]="false"></show-errors>
    </div>
  </div>
</form>
