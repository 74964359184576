<app-temp-modal-cadastro #modalConsultar [textHeader]="'Configurações de impressão'" (closed)="onClose()">
  <div class="app-modal-body">
    <form [formGroup]="form">
      <div class="form-control group" style="border: unset; padding-left: 0px;">
        <div class="row">
          <div class="col-sm-12 form-group">
            <label style="font-weight: bold;">Opções de organização:</label>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 form-group">
            <div class="checkbox-fade fade-in-default">
              <label>
                <input type="checkbox" formControlName="ocultarPrecoCusto">
                <span class="cr">
                  <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span> <span>Ocultar preço de custo</span>
              </label>
            </div>
          </div>
          <div class="col-sm-6 form-group">
            <div class="checkbox-fade fade-in-default">
              <label>
                <input type="checkbox" formControlName="exibirNcms">
                <span class="cr">
                  <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span> <span>Exibir NCMs dos produtos</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 form-group">
            <div class="checkbox-fade fade-in-default">
              <label>
                <input type="checkbox" formControlName="ocultarPrecoVenda">
                <span class="cr">
                  <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span> <span>Ocultar preço de venda</span>
              </label>
            </div>
          </div>
          <div class="col-sm-6 form-group">
            <div class="checkbox-fade fade-in-default">
              <label>
                <input type="checkbox" formControlName="agruparAlmoxarifado">
                <span class="cr">
                  <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span> <span>Agrupar por almoxarifados</span>
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 form-group">
            <label>Ordenado por:</label>
            <ng-select [searchable]="false" formControlName="ordem" [items]="orderBy" 
              placeholder="Selecione" [disabled]="loading" [clearable]="false">
            </ng-select>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 form-group">
            <label style="font-weight: bold;">Opções de visualização:</label>
          </div>
        </div>
        <div class="row">
          <div class="form-radio col-sm-6">
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="formato" value="pdf">
                <i class="helper"></i>Formato .PDF
              </label>
            </div>
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="formato" value="xls">
                <i class="helper"></i>Formato .XLS
              </label>
            </div>
          </div>
        </div>
    </div>
    </form>
    <button type="button" (click)="imprimir()" class="btn btn-primary"><i class="fa fa-print"></i>
      Imprimir</button>
  </div>
</app-temp-modal-cadastro>
<app-abrir-modal #abrirModal>
</app-abrir-modal>
