import { Component, OnInit, ViewChild } from '@angular/core';
import { ItensService } from '../../../services/itens.service';
import { MessageService } from '../../../services/message.service';
import { NFEntradaService } from '../../../services/nfentrada.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { TempModalCadastroComponent } from '../../../shared/templates/temp-modal-cadastro/temp-modal-cadastro.component';
import { AlmoxarifadosService } from '../../../services/almoxarifados.service';
import { UnidadesEmpresaParametrosService } from '../../../services/unidades-empresa-parametros.service';
import { ParametrosGeraisViewModel } from '../../../model/ParametrosGeraisViewModel';
import { ConverterNotasFiscaisEntradaItensViewModel } from '../../../model/ConverterNotasFiscaisEntradaItensViewModel';
import NotaFiscalEntradaItem from '../../../model/NotasFiscaisEntradaItemViewModel';
import { UnidadesMedidaService } from '../../../services/unidades-medida.service';
import { UnidadesMedidaViewModel } from 'models/unidades-medida/UnidadesMedidaViewModel';
import { finalize } from 'rxjs';
import { AbrirModalComponent } from '../../../shared/abrir-modal/abrir-modal.component';

@Component({
  selector: 'app-itens-manifesto',
  templateUrl: './itens-manifesto.component.html',
  styleUrls: ['./itens-manifesto.component.css']
})
export class ItensManifestoComponent extends ModalCadastroComponent implements OnInit {

  constructor(
    private nfeEntradaService: NFEntradaService,
    private itensService: ItensService,
    private messageService: MessageService,
    private almoxarifadoService: AlmoxarifadosService,
    private unidadesMedidaService: UnidadesMedidaService,
    private parametrosService: UnidadesEmpresaParametrosService,
    ) { super(); }

  @ViewChild('abrirModal', { static: true }) abrirModal: AbrirModalComponent;
  @ViewChild('modalConsultar', { static: true }) modalConsultar: TempModalCadastroComponent;
  itens: NotaFiscalEntradaItem[];
  columns = [
    { label: 'Nome', col: 'itemFornecedor.nome', hide: false, customTmpl: true, class: 'text-overflow centeredText' },
    { label: 'Unidade', col: 'itemFornecedor.item.unidade', customTmpl: true, hide: false, class: 'centeredText' },
    { label: 'Quantidade', col: 'quantidade', customTmpl: true, hide: false, class: 'centeredText' },
    { label: 'Preço de venda', col: 'precoVenda', hide: false, customTmpl: true, class: 'centeredText' }
  ];
  selecionados = [];
  loading = false;
  loadingData = false;
  percentages = [];
  salePrices = [];
  defaultPercentage = 0;
  almoxarifados: Object[];
  almoxarifadoPadraoId: number;
  unidadesEmpresaParametrosGerais: ParametrosGeraisViewModel;
  unidadesMedida: UnidadesMedidaViewModel[];
  estoque = {
    id: "movimenta_estoque",
    selecionado: false,
  };

  statusItensDesabilitaInputsDatatable = ['cm', 'cnm'];
  statusItensDesabilitaSalvar = ['cnm1', 'cnm2'];

  dictStatusItem = {
    cadastroMovimentado: 'cm',
    cadastraoNaoMovimentado: 'cnm',
    naoCadastradoNaoMovimentado: 'ncnm'
  }

  dictStatusItemCor = {
    cm: 'primary',
    cnm: 'info',
    ncnm: 'danger',
  }

  dictStatusItemNgbTooltip = {
    cm: 'Cadastrado e movimentado',
    cnm: 'Cadastrado e não movimentado',
    ncnm: 'Não cadastrado e não movimentado',
  }

  dictStatusSelecionados = {
    ok: 'ok',
    cnmSelecionado: 'cnm1',
    cnmSelecionados: 'cnm2',
    cnmComNcnmSelecionado: 'cnm_ncnm1',
    cnmComNcnmSelecionados: 'cnm_ncnm2',
  }

  dictStatusSelecionadosError = {
    ok: '',
    cnm1: 'O item selecionado já foi cadastrado.<br> Ative a opção <b>Movimentar Estoque</b> caso deseje movimentar o estoque.',
    cnm2: 'Os itens selecionados já foram cadastrados.<br> Ative a opção <b>Movimentar Estoque</b> caso deseje movimentar o estoque.',
    cnm_ncnm1: 'Um ou mais itens selecionados já estão cadastrados.<br> Ative a opção <b>Movimentar Estoque</b> caso deseje movimentar o estoque.',
    cnm_ncnm2: 'Um ou mais itens selecionados já estão cadastrados.<br> Ative a opção <b>Movimentar Estoque</b> caso deseje movimentar o estoque.',
  }

  ngOnInit() {
    const ID = this.prop['ID'];
    this.loading = true;
    this.loadingData = true;

    this.nfeEntradaService.buscarItensFornecedores(ID).subscribe((res: RetornoBuscarItensFornecedoresViewModel[]) => {
      const _itens = res.map(i => {
        i.item.movimentouEstoque = i.movimentouEstoque;
        i.item.itemFornecedor.unidadeMedidaID;
        if (i.item.itemFornecedor.itemID) {
          i.item.unidadeMedidaID = i.item.itemFornecedor.item.unidadeMedidaID;
          i.item.unidade = i.item.itemFornecedor.item.unidade;
          i.item.precoVenda = i.item.itemFornecedor.item.precoSaida;
        } else {
          i.item.precoVenda = i.item.preco;
        }
        return i.item;
      });
      this.itens = _itens;
    }, (err) => {}, () => {
      this.loading = false;
      this.loadingData = false;
    });

    this.carregaAlmoxarifados();
    this.carregaUnidadesMedida();
    this.parametrosService.getParametrosGerais()
      .subscribe((parametros: ParametrosGeraisViewModel) => {
        this.unidadesEmpresaParametrosGerais = parametros;
        if (this.unidadesEmpresaParametrosGerais.almoxarifadoPadraoId) {
          this.almoxarifadoPadraoId = this.unidadesEmpresaParametrosGerais.almoxarifadoPadraoId;
        }
      }, error => {
      });
  }

  show() {
    this.modalConsultar.show();
  }

  destroyComponent() {
    this.closed.emit();
  }

  get possuiItensSelecionados(): boolean {
    return this.selecionados.length === 0
  }

  get itensNaoMovimentados(): NotaFiscalEntradaItem[] {
    if (!this.itens) {
      return [];
    }
    return this.itens.filter((item: NotaFiscalEntradaItem) => !item.movimentouEstoque);
  }

  importar() {
    if (!this.almoxarifadoPadraoId) {
      this.messageService.addError({
        title: 'Almoxarifado',
        msg: 'Informe um almoxarifado para os itens que serão cadastrados.',
        showClose: false,
        timeout: 7000,
        theme: 'bootstrap',
        closeOther: true
      });
      return;
    }

    this.messageService.addToast({
      title: 'Cadastrando produtos',
      msg: 'Aguarde alguns instantes...',
      showClose: true, timeout: 0, theme: 'bootstrap', closeOther: true, type: 'wait'
    });

    this.loading = true;

    const data: ConverterNotasFiscaisEntradaItensViewModel = {
      itens: this.selecionados,
      movimentarEstoque: this.estoque.selecionado,
      almoxarifadoPadraoId: this.almoxarifadoPadraoId,
      notaFiscalEntradaId: this.prop['ID'],
    };

    this.itensService.converterItensFornecedores(data)
      .subscribe(res => {
        this.messageService.success('Itens de venda cadastrados com sucesso', 5000)
      }, err => {
        this.messageService.error(err.msg.errors[0], 5000)
      }, () => {
        this.loading = false;
        this.modalConsultar.hide();
        this.closed.emit();
      });
  }

  handleUnidadeMedidaChanged(unidadeMedida: UnidadesMedidaViewModel, row: NotaFiscalEntradaItem): void {
    row.unidade = unidadeMedida.unidade;
  }

  carregaAlmoxarifados(): void {
    this.loading = true;
    this.almoxarifadoService.get()
      .pipe(finalize(() => this.loading = false))
      .subscribe(response => {
        this.almoxarifados = response;
      });
  }

  handleCheckboxClick(): void {
    this.estoque.selecionado = !this.estoque.selecionado;
  }

  statusCadastroMovimentacaoItem(item: NotaFiscalEntradaItem): string {
    if (item.itemFornecedor.itemID) {
      if (item.movimentouEstoque) {
        return this.dictStatusItem.cadastroMovimentado;
      } else {
        return this.dictStatusItem.cadastraoNaoMovimentado;
      }
    }
    return this.dictStatusItem.naoCadastradoNaoMovimentado;
  }

  statusItemCor(row: NotaFiscalEntradaItem): string {
    let status = this.statusCadastroMovimentacaoItem(row);
    if (this.dictStatusItemCor.hasOwnProperty(status)) {
      return this.dictStatusItemCor[status];
    }
    return '';
  }

  statusItemNgbTooltip(row: NotaFiscalEntradaItem): string {
    let status = this.statusCadastroMovimentacaoItem(row);
    if (this.dictStatusItemNgbTooltip.hasOwnProperty(status)) {
      return this.dictStatusItemNgbTooltip[status];
    }
    return '';
  }


  statusSelecionados(): string {
    if (!this.selecionados.length) {
      return this.dictStatusSelecionados.ok;
    }

    const itensCNM = this.selecionados.filter((item: NotaFiscalEntradaItem) => this.statusCadastroMovimentacaoItem(item) == this.dictStatusItem.cadastraoNaoMovimentado);
    const itensNCNM = this.selecionados.filter((item: NotaFiscalEntradaItem) => this.statusCadastroMovimentacaoItem(item) == this.dictStatusItem.naoCadastradoNaoMovimentado);

    if (itensCNM.length === this.selecionados.length) {
      if (itensCNM.length === 1) {
        return this.dictStatusSelecionados.cnmSelecionado;
      } else {
        return this.dictStatusSelecionados.cnmSelecionados;
      }
    } else if (itensCNM.length && itensNCNM.length) {
      if (itensNCNM.length === 1) {
        return this.dictStatusSelecionados.cnmComNcnmSelecionado;
      } else {
        return this.dictStatusSelecionados.cnmComNcnmSelecionados;
      }
    }

    return this.dictStatusSelecionados.ok;
  }

  statusSelecionadosError(): string {
    const status = this.statusSelecionados();
    if (this.dictStatusSelecionadosError.hasOwnProperty(status)) {
      return this.dictStatusSelecionadosError[status];
    }
    return '';
  }

  carregaUnidadesMedida() {
    this.loading = true;
    this.unidadesMedidaService.get()
      .pipe(finalize(() => this.loading = false))
      .subscribe((response: UnidadesMedidaViewModel[]) => {
        this.unidadesMedida = response;
      });
  }

  abrirCadastroAlmoxarifado(id, searchTerm) {
    const sub = this.abrirModal.closed.subscribe(res => {
      sub.unsubscribe();
      this.carregaAlmoxarifados();
      this.almoxarifadoPadraoId = res;
    });

    if (id) {
      this.abrirModal.editar('almoxarifado', id);
    } else {
      this.abrirModal.cadastrar('almoxarifado', searchTerm);
    }
  }
}

interface RetornoBuscarItensFornecedoresViewModel {
  item: NotaFiscalEntradaItem;
  movimentouEstoque: boolean;
}
