<app-modal-basic #modalRelatorio (closed)="destroyComponent()"  [modalContentId]="'modal-vendasnfce'">
  <div class="app-modal-header">
    <h4 class="modal-title">Vendas por produto NFC-e</h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="impressaoForm" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Emissão</label>
          <div class="input-group">
            <app-data [calendarPosition]="'left'" formControlName="periodo1"></app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="periodo2"></app-data>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Ordenar por</label>
          <ng-select appendTo="#modal-vendasnfce" [items]="ordenacao" bindLabel="name" bindValue="id"
            formControlName="order">
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12" appendTo="#modal-vendasnfce" rem5 combobox 
          [type]="'cliente'" [(form)]="impressaoForm" formControlName="clienteId">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Forma de Pagamento</label>
          <ng-select appendTo="#modal-vendasnfce" class='border-on-error' [loading]="!formasPagamento"
            [disableControl]="loading['disable'] || loading['request']" formControlName="formaPagamento" placeholder="Selecione"
            [items]="formasPagamento" bindLabel="descricao" bindValue="id">
          </ng-select>
        </div>
      </div>
      <button type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
        <i class="icofont icofont-print"> </i> Imprimir
      </button>
      <button type="button" class="btn btn-danger ripple" (click)="modalRelatorio.hide()"><i class="ti ti-exit"></i>
        Cancelar
      </button>
    </form>
  </div>
  <div class="app-modal-footer">
  </div>
</app-modal-basic>