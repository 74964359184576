import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from './../../../../shared/modal-cadastro/modal-cadastro.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-relatorio-romaneio-carga',
  templateUrl: './relatorio-romaneio-carga.component.html',
  styleUrls: ['./relatorio-romaneio-carga.component.css'],
})
export class RelatorioRomaneioCargaComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modalRelatorio', { static: true }) modalRelatorio;
  form: FormGroup;

  constructor() {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      orientacao: new FormControl(false),
      formato: new FormControl(false),
    });
  }

  show(): void {
    this.modalRelatorio.show();
  }

  close(): void {
    this.closed.emit(null);
  }

  imprimir(): void {
    this.closed.emit(this.form.value);
  }
}
