<div class="datatable-loading" *ngIf="loading">
  <div class="datatable-loader"></div>
</div>
<div class="escaninhos">
  <ng-container *ngFor="let data of getData()">
    <div [routerLink]="[route, data.id]">
      <div class="list-item">
        <div class="info" [ngbTooltip]="getDesciption(data)">
          <p style="margin: unset; font-size: 0.875rem;" *ngIf="!data.unidadeConsumo">C - {{getDesciption(data)}}</p>
          <b *ngIf="data.unidadeConsumo">UC - {{getDesciption(data)}}</b>
        </div>
        <div class="info">{{ 'P - ' + data.numeroPedido }}</div>
      </div>
    </div>
  </ng-container>
</div>
<app-dim-pagination [offset]="1" [registersPage]="20" [totalRegisters]="totalItems" (pageChange)="changePage($event)"
  [currentPage]="currentPage">
</app-dim-pagination>