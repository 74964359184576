import { BaseService } from './base.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class OcupacaoAgendaService extends BaseService {
    constructor(private http: HttpClient) { super(); }

    delete(id) {
        return this.http.delete(this.UrlService + 'OcupacaoAgenda/' + id)
            .pipe(map(super.extractData)
            , catchError(super.serviceError));
    }

    getOcupacoesByDate(form) {
        return this.http.post(this.UrlService + 'OcupacaoAgenda/GetByDate', form)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    getOcupacoesByClientAndPeriod(form) {
        return this.http.post(this.UrlService + 'OcupacaoAgenda/GetByClientAndPeriod', form)
            .pipe(map(super.extractData)
            , catchError(super.serviceError));
    }

    salvarMultiplos(form) {
        return this.http.post(this.UrlService + 'OcupacaoAgenda/postMultiple', form)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    salvar(form) {
        return this.http.post(this.UrlService + 'OcupacaoAgenda', form)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    checkDisponibility(form) {
        return this.http.post(this.UrlService + 'OcupacaoAgenda/checkDisponibility', form)
            .pipe(map(super.extractData)
            , catchError(super.serviceError));
    }

    getByClientePaginado(params) {
        return this.http.post(this.UrlService + 'OcupacaoAgenda/GetByClientePaginado/', params)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }
}
