import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { OrdenacaoMenu } from '../../services/ordenacaoMenu.service';
import Swal from 'sweetalert2';
import { Router } from '../../../../node_modules/@angular/router';
import { StorageFunctions } from '../../general/storage';
import { UsuarioService } from '../../authentication/services/usuario.service';
import * as $ from 'jquery';
import { UnidadesEmpresaService } from '../../services/unidades-empresa.service';
import { EstadosService } from '../../services/estados.service';
import { NotificacoesService } from '../../services/notificacoes.service';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { API_URL } from '../../general/constants';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { UnidadesEmpresaParametrosService } from '../../services/unidades-empresa-parametros.service';
import { UtilFunctions } from '../../general/utils-service';
import { Subject } from 'rxjs';
import { AbrirModalComponent } from '../../shared/abrir-modal/abrir-modal.component';
import { AdminLayoutEventBusService } from '../../services/admin-layout-event-bus.service';

$(function () {
  $(document).on('focus', 'input', function () {
    // Ao digitar parte do nome do cliente em um ng-select e aguardar,
    //   estava selecionando todo o texto, dificultando a busca incremental.
    if ($(this).attr("role") === "combobox")
      return;

    this.select();
  });
});

export interface Options {
  heading?: string;
  removeFooter?: boolean;
  mapHeader?: boolean;
}

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('notificationBottom', [
      state('an-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('an-animate',
        style({
          overflow: 'visible',
          height: AUTO_STYLE,
        })
      ),
      transition('an-off <=> an-animate', [
        animate('100ms ease-in-out')
      ])
    ]),
    trigger('slideInOut', [
      state('in', style({
        width: '280px',
        // transform: 'translate3d(0, 0, 0)'
      })),
      state('out', style({
        width: '0',
        // transform: 'translate3d(100%, 0, 0)'
      })),
      transition('in => out', animate('100ms ease-in-out')),
      transition('out => in', animate('100ms ease-in-out'))
    ]),
    trigger('mobileHeaderNavRight', [
      state('nav-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('nav-on',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('nav-off <=> nav-on', [
        animate('100ms ease-in-out')
      ])
    ]),
    trigger('fadeInOutTranslate', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translate(0)' }),
        animate('100ms ease-in-out', style({ opacity: 0 }))
      ])
    ]),
    trigger('mobileMenuTop', [
      state('no-block, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('yes-block',
        style({
          height: AUTO_STYLE,
        })
      ),
      transition('no-block <=> yes-block', [
        animate('100ms ease-in-out')
      ])
    ])
  ]
})

export class AdminLayoutComponent implements OnInit, OnDestroy {
  private subjectKeyUp = new Subject<any>();
  public token;
  public user;
  public nome = '';
  edicao = false;
  deviceType = 'desktop';
  verticalNavType = 'expanded';
  verticalEffect = 'shrink';
  innerHeight: string;
  isCollapsedMobile = 'no-block';
  menuClass = this.isCollapsedMobile + ' ' + this.deviceType;
  isCollapsedSideBar = 'no-block';
  toggleOn = true;
  windowWidth: number;
  menuItens: any;
  ordemMenuInicial: any;
  hasNotification = false;
  mantemAberto = true;
  profileNotification = 'an-off';
  notification = 'an-off';
  liveNotification = 'an-off';
  profileNotificationClass: string;
  notificationClass: string;
  notificacoes;
  searchingNotifications = false;
  notificationsIndex = 0;
  contas = [];
  loadingContas = true;
  loadingLogin = false;
  showDropdown = false;
  search = [];
  searchClick: boolean = false;
  proximaFatura = null;
  boletoDimAtivo = null;
  timeoutVerificacaoUsuarioLogado = null;
  isDarkMode = false;

  notificacaoCertificado: object;

  public perfectDisable: boolean = false;
  @ViewChild('abrirRelatorios', { static: false }) abrirRelatorios;
  @ViewChild('abrirModal', { static: true }) abrirModal: AbrirModalComponent;
  @ViewChild('accountList', { static: false }) accountList;
  @Input() rotaPai: string;
  @Output() functionResponse = new EventEmitter();

  constructor(private router: Router, private ordemMenuService: OrdenacaoMenu,
    private storageFunctions: StorageFunctions, private usuarioService: UsuarioService,
    private unidadeEmpresaService: UnidadesEmpresaService, private unidadeEmpresaParamService: UnidadesEmpresaParametrosService,
    private estadoService: EstadosService, private utils: UtilFunctions, private notificacoesService: NotificacoesService,
    private bottomSheet: MatBottomSheet, private adminLayoutEventBus: AdminLayoutEventBusService) {
    const scrollHeight = window.screen.height - 150;
    this.innerHeight = scrollHeight + 'px';
    this.windowWidth = window.innerWidth;
    this.setMenuAttributs(this.windowWidth);

    const storage = this.storageFunctions.getStorage();
    this.token = storage.getItem('token');
    this.user = storageFunctions.getUser();
    this.menuItens = storageFunctions.getMenuItens();

    this.timeoutVerificacaoUsuarioLogado = setTimeout(() => {
      this.verificarUsuarioLogado();
    }, 5000);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutVerificacaoUsuarioLogado);
  }

  verificarUsuarioLogado() {
    const user = this.storageFunctions.getUser();

    if (user?.id !== this.user?.id) {
      location.reload();
    } else {
      this.timeoutVerificacaoUsuarioLogado = setTimeout(() => {
        this.verificarUsuarioLogado();
      }, 5000);
    }
  }

  ngOnInit() {
    this.nome = this.user.nome;
    this.configurarNotificacoes();

    this.subjectKeyUp.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((d) => {
      this.search = this.getItens(d);
    });

    $(() => {
      const that = this;
      $(document).on('click', '[hiddenMenu]', function () {
        if (that.deviceType !== 'mobile') {
          that.verticalNavType = 'collapsed';
          that.perfectDisable = true;
          that.mantemAberto = false;
          document.querySelectorAll('.pcoded-trigger').forEach(element => {
            const elemento = <HTMLElement>element.querySelector('a');
            elemento.click();
          });
        }
      });

      $(document).on('click', 'app-menu-item', function () {
        that.verificaPosicaoLogo();
      });

      $(document).on('click', 'app-editar-menu-item', function () {
        that.verificaPosicaoLogo();
      });

      $(document).on('click', 'ng-select', function (e) {
        if (that.deviceType === 'mobile') {
          setTimeout(() => {
            const sct = $('ng-dropdown-panel').offset().top;
            const sct2 = $(this).offset().top;
            const isTop = sct < sct2;
            const scrollPosition = isTop ? sct - 60 : sct2 - 85;
            window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
          }, 200);

          if (e.target.className === 'ng-arrow-wrapper') {
            const $focused = $(':focus');
            $focused.blur();
          }
        }
      });

    });

    this.verificaPosicaoLogo();
    this.configuraNotificacao();

    this.buscarVencimentoMensalidade();
    this.adminLayoutEventBus.atualizarInformacaoFaturasEvento.subscribe(() => {
      this.buscarVencimentoMensalidade();
    });

    this.buscarStatusBoletoDim();
    this.adminLayoutEventBus.atualizarStatusBoletoDimEvento.subscribe(() => {
      this.buscarStatusBoletoDim();
    });

    this.adminLayoutEventBus.atualizarMenuEvento.subscribe(() => {
      this.menuItens = this.storageFunctions.getMenuItens();
      this.correcaoSubmenu();
    });

    this.carregarTema();

    this.correcaoSubmenu();
  }

  async buscarStatusBoletoDim() {
    try {
      this.boletoDimAtivo = await this.unidadeEmpresaService.buscarStatusBoletoDim().toPromise();
    } catch (err) {
      console.error(err);
    }
  }

  async buscarVencimentoMensalidade() {
    try {
      const res = await this.unidadeEmpresaService.boletosDimensaoEmAberto().toPromise();
      this.proximaFatura = res && res.length > 0 ? res[0] : null;
    } catch (err) {
      console.error(err);
    }
  }

  name(arr: string[] = []): string {
    return arr.join(' - ');
  }

  handleClickOutside() {
    if (this.showDropdown) {
      this.showDropdown = false;
    }
  }

  hideDropDown() {
    if (this.showDropdown) {
      this.showDropdown = false;
    } else if (this.search.length >= 1) {
      this.showDropdown = true;
    }
  }

  onSearch($event: any) {
    const value = $event.target.value;
    this.subjectKeyUp.next(value);
  }

  onClickItem(item) {
    if (item && item.rota) {
      if (item.rota[item.rota.length - 1] === 'abrirRelatorios' || item.rota[item.rota.length - 1] === 'abrirCadastros') {
        if (item.parametro === 'tabelasPreco' || item.parametro === 'condicaoPagamento' || item.parametro === 'centroCusto') {
          this.abrirModal.cadastrar(item.parametro, '');
        } else {
          this.abrirModal.abrir(item.parametro, '');
        }
      } else {
        this.router.navigate([...item.rota]);
      }
    }
  }

  arraysEqual(a, b) {
    if (a === b) {
      return true;
    }
    if (a == null || b == null) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) {
        return false;
      }
    }
    return true;
  }

  getItens(val: string) {
    let obj = this.menuItens.menuLateral;
    val = val.toLowerCase();
    let array = [];
    let btns = [
      {
        type: 3,
        nome: 'Cadastrar Contabilidades',
        btn: 'Cadastrar',
        rota: ['admin', 'cadastro-contabilidade']
      },
      {
        type: 3,
        nome: 'Cadastr Indicadores',
        btn: 'Cadastrar',
        rota: ['admin', 'indicadores', 'cadastro']
      },
      {
        type: 3,
        nome: 'Cadastrar Nova NF-e',
        btn: 'Nova NF-e',
        rota: ['fiscal', 'nfe', 'emissao']
      },
      {
        type: 3,
        nome: 'Cadastrar Nova NFC-e',
        btn: 'Nova NFC-e',
        rota: ['fiscal', 'nfce', 'emissao']
      },
      {
        type: 3,
        nome: 'Cadastrar NCM',
        btn: 'Cadastrar NCM',
        rota: ['fiscal', 'cadastros', 'classificacoes-fiscais', 'cadastro']
      },
      {
        type: 3,
        nome: 'Cadastrar Códigos Fiscais',
        btn: 'Cadastrar',
        rota: ['fiscal', 'cadastros', 'codigos-fiscais', 'cadastro']
      },
      {
        type: 3,
        nome: 'Cadastrar Séries Alternativas',
        btn: 'Cadastrar',
        rota: ['fiscal', 'cadastros', 'series-alternativas']
      },
      {
        type: 3,
        nome: 'Cadastrar Movimentações',
        btn: 'Movimentar',
        rota: ['financeiro', 'movimentacoes', 'cadastro']
      },
      {
        type: 3,
        nome: 'Lançar Despesas',
        btn: 'Lançar',
        rota: ['financeiro', 'despesas', 'cadastro']
      },
      {
        type: 3,
        nome: 'Lançar Contas a Pagar',
        btn: 'Lançar',
        rota: ['financeiro', 'contas-pagar', 'cadastro']
      },
      {
        type: 3,
        nome: 'Lançar Contas a Receber',
        btn: 'Lançar',
        rota: ['financeiro', 'contas-receber', 'cadastro']
      },
      {
        type: 3,
        nome: 'Cadastrar Contas Correntes',
        btn: 'Cadastrar',
        rota: ['financeiro', 'cadastros', 'contas-correntes', 'cadastro']
      },
      {
        type: 3,
        nome: 'Cadastrar tipos de Despesas',
        btn: 'Cadastrar',
        rota: ['financeiro', 'cadastros', 'tipo-despesa', 'cadastro']
      },
      {
        type: 3,
        nome: 'Cadastrar Eventos Bancários',
        btn: 'Cadastrar',
        rota: ['financeiro', 'cadastros', 'eventos-bancarios', 'cadastro']
      },
      {
        type: 3,
        nome: 'Cadastrar Centro de Custos',
        btn: 'Cadastrar',
        rota: ['', '', 'abrirCadastros'], //abre modal
        parametro: 'centroCusto'
      },
      {
        type: 3,
        nome: 'Cadastrar Prazos de Pagamento',
        btn: 'Cadastrar',
        rota: ['', '', 'abrirCadastros'], //abre modal
        parametro: 'condicaoPagamento'
      },
      {
        type: 3,
        nome: 'Cadastrar Contas Contábeis',
        btn: 'Cadastrar',
        rota: ['financeiro', 'integracoes', 'contabeis', 'contas-contabeis', 'cadastro']
      },
      {
        type: 3,
        nome: 'Cadastrar Históricos Contábeis Padrão',
        btn: 'Cadastrar',
        rota: ['financeiro', 'integracoes', 'contabeis', 'historico-contabeis-padrao', 'cadastro']
      },
      {
        type: 3,
        nome: 'Novo Lote de Lançamentos Contábeis',
        btn: 'Novo Lote',
        rota: ['', '', 'abrirCadastros'], //abre modal
        parametro: 'lancamentosContabeis'
      },
      {
        type: 3,
        nome: 'Cadastrar Nova Relação Clientes',
        btn: 'Novo Cliente',
        rota: ['venda', 'cliente', 'novo', 'cadastro']
      },
      {
        type: 3,
        nome: 'Lançar Pedidos NF',
        btn: 'Lançar',
        rota: ['venda', 'pedidos', 'cadastro']
      },
      {
        type: 3,
        nome: 'Cadastrar Vendedores',
        btn: 'Cadastrar',
        rota: ['venda', 'cadastros', 'vendedores', 'cadastro']
      },
      {
        type: 3,
        nome: 'Cadastrar Embalagens',
        btn: 'Cadastrar',
        rota: ['venda', 'cadastros', 'embalagens', 'cadastro']
      },
      {
        type: 3,
        nome: 'Cadastrar Tabelas de Preço',
        btn: 'Cadastrar',
        rota: ['', '', 'abrirCadastros'], //abre modal
        parametro: 'tabelasPreco'
      },
      {
        type: 3,
        nome: 'Cadastrar Produtos',
        btn: 'Cadastrar',
        rota: ['venda', 'cadastros', 'produtos', 'cadastro']
      },
      {
        type: 3,
        nome: 'Cadastrar Nova Relação de Fornecedores',
        btn: 'Novo Fornecedor',
        rota: ['compras', 'cadastros', 'fornecedores', 'cadastro']
      }
    ];

    obj = obj.concat(btns);

    if (val) {
      const objArray = Array.from(obj);
      const fn = (arr, h, c) => {
        arr.forEach(el => {
          if (el.type === 3) {
            if ((el.nome as string).toLowerCase().startsWith(val)) {
              array.push({ type: 3, nome: el.nome, rota: el.rota, parametro: el.parametro, btn: el.btn });
            }

          } else if (el.filhos && el.filhos.length > 0) {
            if ((el.nome as string).toLowerCase().startsWith(val)) {

              const recursiveChildren = (arrr, rota, caminho) => {
                let result = {};

                if (arrr.filhos && arrr.filhos.length > 0) {
                  if (arrr.filhos.length === 1) {
                    const child = arrr.filhos[0];
                    child.nome = arrr.nome + ' > ' + child.nome;
                    result = recursiveChildren(child, [...rota, child.rota], [...caminho, child.nome]);
                  } else {
                    result = { nome: arrr.nome, rota: rota, caminho: caminho, filhos: arrr.filhos.map(f => recursiveChildren(f, [...rota, f.rota], [...caminho, f.nome])) };
                  }
                } else {
                  result = { nome: arrr.nome, parametro: arrr.parametro, rota: rota, caminho: caminho, filhos: [] };
                }

                return result;
              };

              array.push({
                type: 2, nome: el.nome, rota: [...h, el.rota], caminho: [...c, el.nome], filhos: el.filhos.map((x, i) => {
                  const rota = [...h, el.rota, x.rota];
                  const caminho = [...c, el.nome, x.nome];
                  return recursiveChildren(x, rota, caminho);
                })
              });
            } else {
              fn(el.filhos, [...h, el.rota], [...c, el.nome]);
            }
          } else {
            if ((el.nome as string).toLowerCase().startsWith(val)) {
              const exist = array.find(x => this.arraysEqual([...h], x.rota));
              if (exist) {
                array.splice(array.indexOf(exist), 1);
                array.push({ type: 2, nome: exist.rota[exist.rota.length - 2], rota: exist.rota, caminho: exist.caminho, filhos: [exist.nome, el.nome] });
              } else {
                array.push({ type: 1, nome: el.nome, parametro: el.parametro, rota: [...h, el.rota], caminho: [...c, el.caminho] });
              }
            }
          }
        });
      }

      fn(objArray, [], []);
    }

    if (array.length > 0) {
      this.showDropdown = true;
    } else {
      this.showDropdown = false;
    }

    return array;
  }

  getPath(arr = []) {
    return arr.filter(x => x).join(' > ');
  }

  toggleProfileNotification() {
    this.notificationnOutsideClick();
    this.profileNotification = this.profileNotification === 'an-off' ? 'an-animate' : 'an-off';
    this.profileNotificationClass = this.profileNotification === 'an-animate' ? 'show' : '';
  }

  toggleNotification() {
    this.notificationOutsideClick();
    this.notification = this.notification === 'an-off' ? 'an-animate' : 'an-off';
    this.notificationClass = this.notification === 'an-animate' ? 'show' : '';
  }

  notificationOutsideClick() {
    if (this.profileNotification === 'an-animate') {
      this.toggleProfileNotification();
    }
  }

  notificationnOutsideClick() {
    if (this.notification === 'an-animate') {
      this.toggleNotification();
    }
  }

  hideMobileMenu(hide) {
    if (hide) {
      if (this.deviceType === 'mobile') {
        this.toggleOpened(event);
      }
    }
  }

  editar() {
    this.edicao = !this.edicao;
    this.ordemMenuInicial = JSON.parse(JSON.stringify(this.menuItens.menuLateral));
    this.verificaPosicaoLogo();
  }

  verificaOrdemFilhos(itensAtuais, itensIniciais) {
    let alteracao = false;
    const itens = [];

    itensAtuais.forEach((item, index) => {
      if (item.nome !== itensIniciais[index].nome) {
        alteracao = true;
      }

      let filhos = { alteracao: false, itens: [] };

      if (item.filhos && item.filhos.length > 0) {
        itensIniciais.some((item2) => {
          let achou = false;

          if (item2.nome === item.nome) {
            achou = true;
            filhos = this.verificaOrdemFilhos(item.filhos, item2.filhos);
          }

          return achou === true;
        });
      }

      itens.push({
        ItemDeMenuID: item.id,
        Filhos: filhos.itens,
        Alteracao: filhos.alteracao,
        Ordem: index
      });

    });

    return { alteracao: alteracao, itens: itens };
  }

  salvar() {

    const menuItens = [];

    let alteracao = false;
    this.menuItens.menuLateral.forEach((item, index) => {
      if (this.ordemMenuInicial[index].nome !== item.nome) {
        alteracao = true;
      }

      let filhos = { alteracao: false, itens: [] };

      if (item.filhos && item.filhos.length > 0) {
        this.ordemMenuInicial.some((item2) => {
          let achou = false;

          if (item2.nome === item.nome) {
            achou = true;
            filhos = this.verificaOrdemFilhos(item.filhos, item2.filhos);
          }

          return achou === true;
        });
      }

      menuItens.push({
        ItemDeMenuID: item.id,
        Filhos: filhos.itens,
        Alteracao: filhos.alteracao,
        Ordem: index
      });

    });

    this.ordemMenuService.salvarOrdenacao(menuItens, alteracao)
      .subscribe(res => {

        Swal('Sucesso', 'Ordem de Menu salvo com sucesso!', 'success')
          .then(async e => {

            this.storageFunctions.setAcessos().then(
              resp => {
                this.ordemMenuInicial = this.menuItens.menuLateral;
                this.edicao = !this.edicao;
              }
            );
          });

      },
        response => {
          Swal('Erro', 'Um erro oorreu ao tentar salvar a ordenação de menu', 'error');
          this.menuItens.menuLateral = this.ordemMenuInicial;
          this.edicao = !this.edicao;
        }
      );
    this.verificaPosicaoLogo();
  }

  cancelar() {
    this.edicao = !this.edicao;
    this.verificaPosicaoLogo();
  }

  onClickedOutside(e: MouseEvent) {
    const relatedTarget = e.relatedTarget as Element;
    if (!this.edicao && e && relatedTarget && relatedTarget.className
      && relatedTarget.className.indexOf && relatedTarget.className.indexOf('menuEdit') < 0) {
      if (this.windowWidth < 768 && this.toggleOn && this.verticalNavType !== 'offcanvas') {
        this.toggleOn = true;
        this.verticalNavType = 'offcanvas';
      }
    }

    this.verificaPosicaoLogo();
  }

  onResize(event) {
    this.innerHeight = event.target.innerHeight + 'px';
    /* menu responsive */
    this.windowWidth = event.target.innerWidth;
    let reSizeFlag = true;
    if (this.deviceType === 'tablet' && this.windowWidth >= 768 && this.windowWidth <= 1024) {
      reSizeFlag = false;
    } else if (this.deviceType === 'mobile' && this.windowWidth < 768) {
      reSizeFlag = false;
    }

    if (reSizeFlag) {
      this.setMenuAttributs(this.windowWidth);
    }

    this.verificaPosicaoLogo();
  }

  eventoFecharMenu() {
    document.addEventListener('click', (event) => {
      const element = event.target as HTMLElement; // TS da erro que event.target não possui 'className' sem isso

      const isNotMenuElement = element.className
        .split(' ')
        .filter((item) => [
          'pcoded-overlay-box', // Elemento escuro de fundo que aparece ao expandir o menu
          'user-profile-menu-option__li__a' // Elemento da lista de opções no perfil do usuário
        ].includes(item));

      if (isNotMenuElement.length && this.verticalNavType === 'expanded') {
        this.toggleOpened(event);
      }

    });
  }

  setMenuAttributs(windowWidth) {
    if (windowWidth >= 768 && windowWidth <= 1024) {
      this.deviceType = 'tablet';
      this.verticalNavType = 'collapsed';
      this.verticalEffect = 'push';
      this.perfectDisable = true;
    } else if (windowWidth < 768) {
      this.deviceType = 'mobile';
      this.verticalNavType = 'offcanvas';
      this.verticalEffect = 'overlay';
      this.eventoFecharMenu();
    } else {
      this.deviceType = 'desktop';
      this.verticalNavType = 'expanded';
      this.verticalEffect = 'shrink';
    }
    this.menuClass = this.isCollapsedMobile + ' ' + this.deviceType;
  }

  navegacaoLogo() {
    this.router.navigate(['/novidades']);
  }

  navegacaoSlogan() {
    this.router.navigate(['/novidades']);
  }

  toggleOpened(event, alteraManterAberto = false) {
    if (!this.edicao) {
      if (this.windowWidth < 768) {
        if (event.isTrusted === true) {
          this.verticalNavType = this.verticalNavType === 'expanded' ? 'offcanvas' : 'expanded';
          this.toggleOn = this.verticalNavType === 'offcanvas' ? true : this.toggleOn;
        }
      } else {
        this.verticalNavType = this.verticalNavType === 'expanded' ? 'collapsed' : 'expanded';
      }
    }

    this.perfectDisable = this.verticalNavType === 'collapsed';

    this.mantemAberto = false;
    if (this.verticalNavType === 'expanded' && alteraManterAberto === true) {
      this.mantemAberto = true;
    }

    this.verificaPosicaoLogo();
  }

  toggleOpenedSidebar() {
    this.isCollapsedSideBar = this.isCollapsedSideBar === 'yes-block' ? 'no-block' : 'yes-block';
  }

  onMobileMenu(fechar = false) {
    this.isCollapsedMobile = this.isCollapsedMobile === 'yes-block' ? 'no-block' : 'yes-block';
    this.menuClass = this.isCollapsedMobile + ' ' + this.deviceType;

    if (fechar) { this.isCollapsedMobile = 'no-block'; }

    if (this.isCollapsedMobile === 'yes-block') {
      setTimeout(function () {
        const element = <HTMLElement>document.querySelector('#userOptions');
        element.click();
      }, 300);
    }
  }

  modalEmDesenvolvimento() {
    Swal('Em desenvolvimento', 'Módulo em desenvolvimento, novidades em breve!', 'warning')
      .then(e => {
      });
  }

  executeFunction(parameter) {
    switch (parameter.funcao) {
      case 'desenvolvimento':
        this.modalEmDesenvolvimento();
        break;
      case 'abrirRelatorios':
        this.abrirModal.abrir(parameter.parametro);
        break;
      case 'abrirCadastros':
        this.abrirModal.abrir(parameter.parametro);
        break;
      case 'logOut':
        this.usuarioService.logout();
        if (this.user && this.user.nome === "Pagador") {
          this.router.navigate(['/authentication/login/financeiro']);
        } else {
          this.router.navigate(['/authentication/login']);
        }
        break;
      case 'chat':
        this.chat();
        break;
      case 'changeAccount':
        this.openAccounts();
        break;
      default:
        alert('Algo Aconteceu errado :( entre em contato com a dimensão!');
    }
  }

  hoverMenu(event, acao) {
    if (this.deviceType !== 'mobile') {
      if (acao === 'entro' && this.verticalNavType !== 'expanded' &&
        this.mantemAberto === false || acao === 'saiu' && this.verticalNavType === 'expanded'
        && this.mantemAberto === false) {
        this.toggleOpened(event);
      }

      if (acao === 'saiu' && this.mantemAberto === false) {
        document.querySelectorAll('.pcoded-trigger').forEach(element => {
          const elemento = <HTMLElement>element.querySelector('a');
          elemento.click();
        });
      }
    }
  }

  chat() {
    if (document.querySelector('script[src=\'https://embed.tawk.to/5d1c96cd7a48df6da242becb/1e7l5fd52\']') == null) {
      window['Tawk_API'] = {};
      window['Tawk_LoadStart'] = new Date();

      window['Tawk_API'].onChatEnded = function (data) {
        setTimeout(function () {
          window['Tawk_API'].toggleVisibility();
        }, 100);
      };

      (function () {
        const s1 = document.createElement('script'), s0 = document.getElementsByTagName('script')[0];
        s1.async = true;
        s1.src = 'https://embed.tawk.to/5d1c96cd7a48df6da242becb/1e7l5fd52';
        s1.charset = 'UTF-8';
        s1.setAttribute('crossorigin', '*');
        s0.parentNode.insertBefore(s1, s0);
      })();

      const Tawk_API = window['Tawk_API'] || {};

      Tawk_API.visitor = {
        name: this.user.nomeEmpresa,
        'Nome': this.user.nome,
        'Empresa': this.user.nomeEmpresa,
        'hash': this.user.email
      };

      window['Tawk_API'].onLoad = function () {
        window['Tawk_API'].maximize();

        window['Tawk_API'].setAttributes({
          name: this.user.nomeEmpresa,
          'nome': this.user.nome,
          'Empresa': this.user.nomeEmpresa,
          'hash': this.user.email
        }, function (error) { });
      }.bind(this);
    } else {
      window['Tawk_API'].toggleVisibility();
    }
  }

  verificaPosicaoLogo() {
    return;
    const fatherMenuItens = <HTMLElement>document.querySelector('#fatherMenuItens');
    const itensMenu = <HTMLElement>document.querySelector('#itensMenu');
    const logoMenuLateral = <HTMLElement>document.querySelector('#logoMenuLateral');

    if (fatherMenuItens && itensMenu && logoMenuLateral) {
      const styles = window.getComputedStyle(logoMenuLateral);
      const height = parseFloat(styles['marginTop']) +
        itensMenu.offsetHeight + logoMenuLateral.offsetHeight + 10;

      if (height >= fatherMenuItens.offsetHeight) {
        logoMenuLateral.classList.add('relativeLogoMenu');
        logoMenuLateral.classList.remove('absoluteLogoMenu');
      } else {
        logoMenuLateral.classList.add('absoluteLogoMenu');
        logoMenuLateral.classList.remove('relativeLogoMenu');
      }
    }
  }

  configurarNotificacoes() {
    const connection = new HubConnectionBuilder()
      .withUrl(API_URL + 'notificacoesHub', {
        accessTokenFactory: () => {
          const storage = this.storageFunctions.getStorage();
          return storage.getItem('token');
        }
      })
      .build();

    connection.start().then(() => {
      connection.on('atualizarNotificacoes', () => {
        this.buscarNotificacoes();
      })
    });

    this.buscarNotificacoes();
  }

  buscarNotificacoes() {
    this.notificacoesService.listarNotificacoes(0).subscribe((res: any) => {
      if (res) {
        this.notificacoes = res;
      }
    })
  }

  configuraNotificacao() {
    /* this.unidadeEmpresaService.getUnidadeEmpresaById().subscribe(res => {
      if (res.parametros[0]) {
        this.unidadeEmpresaService.getCertificado().subscribe(certificado => {
          const vencimentoCertDigital: Date = new Date(certificado.validade);
          const cidade = res.cidadeId;

          this.estadoService.getDateFuso().subscribe(data => {
            const dataAtual: Date = new Date(data);
            const dataAviso: Date = new Date(dataAtual.getTime());
            dataAviso.setMonth(dataAviso.getMonth() + 1);

            const diasRestantes = Math.round((vencimentoCertDigital.getTime() - dataAtual.getTime()) / (86.4e6));

            if (vencimentoCertDigital < dataAviso) {
              this.notificacaoCertificado = {
                'dias': diasRestantes,
                'cidade': cidade
              };

              this.hasNotification = true;
              document.querySelector('.notification-icon').classList.toggle('hasNotification')
            }
          })
        })
      }
    }); */
  }

  getNotification() {
    if (!(this.notificacoes && this.notificacoes.length > 0)) {
      return 'Não há notificações'
    }
    return null;
  }

  get notificacoesNaoLidas(): number {
    return ((this.notificacoes || []).filter(x => x.visualizada === false).length || 0);
  }

  handleNotificationScroll(event: MouseEvent) {
    const element = (event.target as HTMLElement);

    if (element.scrollTop + element.offsetHeight >= element.scrollHeight && !this.searchingNotifications) {
      this.searchingNotifications = true;
      this.notificacoesService.listarNotificacoes(this.notificationsIndex + 1)
        .pipe(finalize(() => this.searchingNotifications = false)).subscribe(res => {
          if (res && res.length > 0) {
            this.notificacoes.push(...res);
            this.notificationsIndex++;
          }
        })
    }
  }

  detalhar(notificacao) {

    if (notificacao.visualizada === false) {
      this.notificacoesService.visualizar(notificacao.id).subscribe(() => { });
      notificacao.visualizada = true;
    }

    let opt: any = {
      title: notificacao.titulo,
      text: notificacao.mensagem,
      customClass: 'notification-alert',
    };

    if (notificacao.link) {
      opt = {
        ...opt,
        cancelButtonText: 'Visitar link',
        showCancelButton: true,
      }
    }

    Swal(opt).then(res => {
      if (res.dismiss && res.dismiss === Swal.DismissReason.cancel && notificacao.link) {
        window.open(notificacao.link, '__blank')
      }
    });
  }

  retornaMensagemNotificacao(mensagem: string): string {
    return mensagem.substring(0, 200) + (mensagem.length > 200 ? '...' : '');
  }

  redirect(rota) {
    this.router.navigate(rota);
  }

  openAccounts() {
    this.loadingContas = true;
    this.bottomSheet.open(this.accountList, {
      panelClass: 'accounts-panel'
    });
    const storage = this.storageFunctions.getStorage();
    this.unidadeEmpresaParamService.getContasVinculadas(JSON.parse(storage.getItem('recentLogin')))
      .pipe(finalize(() => this.loadingContas = false))
      .subscribe(contas => {
        if (contas) {
          this.contas = contas;
        }
      });
  }

  switchAccount(account) {
    const storage = this.storageFunctions.getStorage();
    const recentLogin = { login: storage.getItem('login'), senha: storage.getItem('senha') };

    this.loadingLogin = true;
    this.usuarioService.logout();

    this.usuarioService.login(account)
      .subscribe(res => {
        this.storageFunctions.setUser(res);
        this.storageFunctions.setAcessos().then(() => {
          this.router.navigate(['dashboard', 'gerencial']).then(_ => {
            storage.setItem('login', account.login);
            storage.setItem('senha', account.senha);
            storage.setItem('recentLogin', JSON.stringify(recentLogin));
            window.location.reload();
          });
        });
      });
  }

  toggleSearch() {
    this.searchClick = !this.searchClick;
    const nav = document.getElementsByClassName('user-profile');

    if (this.deviceType === 'mobile') {

      for (let i = 0; i < nav.length; i -= -1) {
        let el = nav[i];

        if (el.id === "userOptions" && !this.searchClick) {
          (el as HTMLElement).style.setProperty("display", "flex", "important");
          continue;
        }

        setTimeout(() => {
          (el as HTMLElement).style.display = this.searchClick ? "none" : "block";
        }, 100 * i);
      }
    }
  }

  carregarTema() {
    const temaAtual = this.storageFunctions.getDataFromLocalStorage('tema');
    this.isDarkMode = temaAtual === 'dark';
    document.documentElement.setAttribute('data-theme', temaAtual ?? 'light');
  }

  trocarTema() {
    const temaAtual = document.documentElement.getAttribute('data-theme');
    this.isDarkMode = temaAtual === 'dark';
    const novoTema = temaAtual === 'dark' ? 'light' : 'dark';
    document.documentElement.setAttribute('data-theme', novoTema);
    this.storageFunctions.saveDataToLocalStorage('tema', novoTema);
    this.isDarkMode = novoTema === 'dark';
  }

  correcaoSubmenu() {
    const dashboards = this.menuItens.menuLateral.find(x => x.id === 2);

    if (dashboards.filhos.find(x => x.id === 18)) {
      // Possui rota "Dimensão"
      return;
    }

    const dashboard = dashboards.filhos.find(x => x.id === 19);
    dashboards.rota = 'dashboard/gerencial';
    dashboards.tipoDeAcesso = dashboard.tipoDeAcesso;
    dashboards.tipoDeItem = dashboard.tipoDeItem;
    dashboards.tipoDeLink = 'link';
  }
}
