import { Component, ViewChild, Input, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { AbrirModalComponent } from '../../shared/abrir-modal/abrir-modal.component';
import { ClientesEnderecosService } from '../../services/clientes-enderecos.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-combobox-cliente-endereco',
  templateUrl: './combobox-cliente-endereco.component.html',
  styleUrls: ['./combobox-cliente-endereco.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxClienteEnderecoComponent),
      multi: true
    }
  ]
})
export class ComboboxClienteEnderecoComponent implements ControlValueAccessor, OnChanges {

  @ViewChild('abrirModal', { static: false }) abrirModal: AbrirModalComponent;
  enderecos = [];
  loading = {};
  enderecoSelecionado = null;
  disabled = false;
  private modalSubscription: Subscription;
  @Input() clienteSelecionado = null;

  constructor(private clienteEnderecosService: ClientesEnderecosService) { }

  ngOnChanges(changes: SimpleChanges): void {
    const clienteChange = changes['clienteSelecionado'];
    if (clienteChange && (clienteChange.previousValue !== clienteChange.currentValue)) {
      this.enderecoChanged(null);
      if (clienteChange.currentValue) {
        this.carregarTodosEnderecos();
      } else {
        this.enderecos = [];
      }
    }
  }

  private carregarTodosEnderecos() {
    return new Promise((resolve, reject) => {
      this.loading['enderecos'] = true;
      this.clienteEnderecosService.getEnderecosByClienteTipo(this.clienteSelecionado, 2)
        .pipe(finalize(() => {
          this.loading['enderecos'] = false;
          resolve({});
        }))
        .subscribe(res => {
          this.enderecos = res;
        });
    });
  }

  private salvarEndereco(endereco) {
    return new Promise((resolve, reject) => {
      this.loading['cadastroEndereco'] = true;
      this.clienteEnderecosService.salvarEndereco(endereco)
        .pipe(finalize(() => this.loading['cadastroEndereco'] = false))
        .subscribe(res => {
          resolve(res.generatedId);
        }, () => {
          resolve(null);
        });
    });
  }

  modalClosed(endereco) {
    this.modalSubscription.unsubscribe();
    if (endereco) {
      endereco.clienteId = this.clienteSelecionado;
      this.salvarEndereco(endereco).then((enderecoId) => {
        this.carregarTodosEnderecos().then(() => {
          this.enderecoChanged(enderecoId);
        });
      });
    }
  }

  subscribeModal() {
    this.modalSubscription = this.abrirModal.closed.subscribe(this.modalClosed.bind(this));
  }

  writeValue(obj: any): void {
    this.enderecoSelecionado = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any) => { };

  onTouched = () => { };

  enderecoChanged(event) {
    this.enderecoSelecionado = event;
    this.propagateChange(this.enderecoSelecionado);
  }

  templateDescricao(item) {
    return `${item['endereco']}, ${item['numero']} - ${item['bairro']} - ${item['nomeCidade']}`;
  }

  cadastrarNovoEndereco() {
    const novoEndereco = { tipo: 2 };
    this.abrirModal.abrir('cadastroClienteEndereco', novoEndereco);
  }
}
