<app-card *ngIf="tipoVisualizacao == 'legenda'" [title]="'Legenda'" [showMinimize]="false">
  <div class="row d-flex">
  <div class="col-md-3 col-sm-3 col-xs-12 text-center" *ngFor="let item of statusOptions">

    <i [autoClose]="true" triggers="manual" #p="ngbPopover" (mouseenter)="p.open()" (mouseleave)="p.close()"
      (click)="p.open()" [ngbPopover]='item.name' popoverTitle="" container="body" [ngStyle]="{ 'color': getColor(item.id)}"
      [ngClass]="getIcon(item.id)" style="padding-right: 3px" placement="right"></i>{{item.name}}
  </div>
  </div>
</app-card>

<i *ngIf="tipoVisualizacao == 'detalhe'" [autoClose]="true" triggers="manual" #p="ngbPopover" (mouseenter)="p.open()"
  (mouseleave)="p.close()" (click)="p.open()" [ngbPopover]='retornaDescStatusDoc(documento)' popoverTitle="" container="body"
  [ngStyle]="{ 'color': getColor(documento[propStatus])}" [ngClass]="getIcon(documento[propStatus])"
  style="padding-right: 3px" placement="right"></i>
