import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

import { ConsultarPortadoresViewModel, SalvarPortadoresViewModel, PortadoresViewModel } from '@financeiro';
import { ConsultaPaginadaViewModel } from '@models';

import { BaseService, DimHttpServerResponse } from './base.service';

@Injectable()
export class PortadorService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get() {
    return this.http.get(this.UrlService + 'portadores').pipe(
      map((res: any) => res.data)
      , catchError(super.serviceError));
  }

  getPortadorById(id: number) {
    return this.http
      .get(this.UrlService + 'portadores/' + id)
      .pipe(map((res: DimHttpServerResponse) => res.data as PortadoresViewModel)
        , catchError(super.serviceError));
  }

  post(form) {
    return this.http.post(this.UrlService + 'portadores', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  public getPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + 'Portadores/Paginado?pageIndex=' + pagina + '&search=' + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  consultaPaginada(vm: ConsultarPortadoresViewModel) {
    return this.http
      .post(this.UrlService + 'Portadores/consultaPaginada', vm)
      .pipe(map((res: DimHttpServerResponse) => res.data as ConsultaPaginadaViewModel<PortadoresViewModel>)
        , catchError(super.serviceError));
  }

  salvarPortador(vm: SalvarPortadoresViewModel) {
    return this.http
      .post(this.UrlService + 'Portadores/salvarPortador', vm)
      .pipe(map((res: DimHttpServerResponse) => res.data as PortadoresViewModel)
        , catchError(super.serviceError));
  }

  // Implementação incial possuia exclusão. Descontinuado após conversar com o Edson.
  // delete(id: number) {
  //   return this.http
  //     .delete(this.UrlService + 'Portadores/' + id)
  //     .pipe(map((res: DimHttpServerResponse) => res.data)
  //       , catchError(super.serviceError));
  // }
}
