<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader">
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
    <div class="row">
      <div class="form-group col-sm-12">
        <label class="label-required">Descrição</label>
        <input [disableControl]="loading['registro']" type="text" class="form-control" formControlName="descricao" />
        <show-errors [control]="cadastroForm.controls.descricao"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <label>Grupo de Despesa</label>
        <ng-select appendTo=".modal-content" [disableControl]="loading['registro'] || loading['grupoDespesas']"
          [loading]="loading['grupoDespesas']" placeholder="Selecione" [items]="grupoDespesas" bindLabel="descricao"
          bindValue="id" formControlName="grupoDespesaId">
          <ng-template ng-option-tmp let-item="item">
            <app-temp-option-item [name]="item.descricao"
              (onClick)="subscribeModal('grupoDespesa'); abrirModal.editar('grupoDespesa', item.id)">
            </app-temp-option-item>
          </ng-template>

          <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
            <app-temp-not-found-item [searchTerm]="searchTerm"
              (onClick)="subscribeModal('grupoDespesa'); abrirModal.cadastrar('grupoDespesa', searchTerm)">
            </app-temp-not-found-item>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <label>Conta</label>
        <ng-select appendTo=".modal-content" [disableControl]="loading['registro'] || loading['contas']"
          [loading]="loading['contas']" placeholder="Selecione" [items]="contas" bindLabel="descricao" bindValue="id"
          formControlName="contaPagadoraId">
          <ng-template ng-option-tmp let-item="item">
            <app-temp-option-item [name]="item.descricao"
              (onClick)="subscribeModal('contaCorrente'); abrirModal.editar('contaCorrente', item.id)">
            </app-temp-option-item>
          </ng-template>

          <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
            <app-temp-not-found-item [searchTerm]="searchTerm"
              (onClick)="subscribeModal('contaCorrente'); abrirModal.cadastrar('contaCorrente', searchTerm)">
            </app-temp-not-found-item>
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="errors">
      <span>{{ errors }}</span>
    </div>

    <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'" [loading]="loading['registro']"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalCadastro.hide()">Cancelar</button>
  </form>
</app-temp-modal-cadastro>


<app-abrir-modal #abrirModal></app-abrir-modal>