import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { MessageService } from './message.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ItensFornecedoresService extends BaseService {

  constructor(private http: HttpClient, private message: MessageService) {
    super();
  }

  getByID(id) {
    return this.http.get(this.UrlService + 'ItensFornecedores/' + id)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getPaginado(pagina: number, nome: string, fornecedorID: number = 0, params: string = 'nome') {
    return this.http
      .get(this.UrlService + `ItensFornecedores/paginado?pageIndex=` + pagina + '&search=' + encodeURIComponent(nome) + '&fornecedor=' + fornecedorID + '&filtro=' + encodeURIComponent(params))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
