<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="'Importar NFe\'s'">
  <form [formGroup]="cadastroForm">
    <div class="alert alert-danger" *ngIf="semCertificado || certificadoVencido">
      <span *ngIf="semCertificado">Importe <button class="btn btn-mini pointer"
          (click)="abrirCertificadoDigital()">aqui</button> o certificado digital para
        consultas de status!</span>
    </div>
    <file-upload formControlName="files">
      <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
        <div class="row div-upload">
          <div class="col-sm-3 div-upload-icon"><i class="fa fa-upload upload-icon"></i></div>
          <div class="col-sm-9" *ngIf="!cadastroForm.get('files').value">
            <span><b>Arraste e solte arquivos</b> ou clique aqui</span>
          </div>
        </div>
      </ng-template>
    </file-upload>
    <div *ngIf="entradaSaida === 'E'" class="alert alert-danger mx-2 mt-2">
      A importação manual do XML não gera o NSU, número de controle da SEFAZ junto à NF Importada.<br>
      É aconselhável aguardar por 48 horas a liberação de novas manifestações antes desta importação por arquivo.
    </div>
    <div checkbox class="text-center checkbox-fade fade-in-default ml-2" *ngIf="entradaSaida == 'E'">
      <label>
        <input type="checkbox" formControlName="cadastrarItensCadProduto" [disableControl]="loading['request'] || loading['disable']">
        <span class="cr">
          <i class="cr-icon icofont icofont-ui-check txt-default"></i>
        </span>
        <span class="text-inverse">
          Cadastrar itens no seu cadastro de produtos <i class="fa fa-info-circle tooltip-cest"
          ngbTooltip="Essa opção irá cadastrar os itens da NF-e no seu cadastro de produtos, mas não serão duplicados caso já estejam cadastrados."
          container="body" [autoClose]="true" triggers="manual" #t="ngbTooltip"
          (mouseleave)="t.close()" (mouseenter)="t.open()"
          (click)="t.open()"></i>
        </span>
      </label>
    </div>
    <div class="text-center m-t-20">
      <button [disabled]="loading" class="btn btn-primary" (click)="importarNotas()">
        <app-loading texto="Importar" [loading]="loading" [icone]="'icofont icofont-refresh'"></app-loading>
      </button>
      <span *ngIf="entradaSaida == 'S'">{{arquivosImportados()}}</span>
    </div>
  </form>
</app-temp-modal-cadastro>
<app-abrir-modal #abrirModal></app-abrir-modal>
