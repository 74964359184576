import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseServiceV2 } from '@servicesv2';

@Injectable()
export class GrupoDespesasServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('GrupoDespesas', http);
  }

}
