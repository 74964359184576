import { UnidadeEmpresa } from '../model/unidadeEmpresa';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class UnidadesEmpresaService extends BaseService {
    constructor(private http: HttpClient) { super(); }

    getUnidadeEmpresa() {
        return this.http
            .get(this.UrlService + 'unidadesEmpresa')
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    getUnidadeEmpresaOData(valorFiltro: string) {
        return this.http.get(this.UrlService + `odata/unidadesEmpresa${valorFiltro}`)
            .pipe(map(super.extractODataSingleResult), catchError(super.serviceError));
    }

    updateProrrogacao(empresa: UnidadeEmpresa) {
        return this.http.put(this.UrlService + `unidadesEmpresa/prorrogacao/`, empresa)
            .pipe(map((res: any) => res)
                , catchError(super.serviceError));
    }

    updateUnidadeEmpresa(empresa: any) {
        return this.http.put(this.UrlService + 'unidadesEmpresa/', empresa)
            .pipe(map((res: any) => res)
                , catchError(super.serviceError));
    }

    uploadCertificado(form: any) {
        return this.http.post(this.UrlService + 'unidadesEmpresa/certificado', form)
            .pipe(map((res: any) => res)
                , catchError(super.serviceError));
    }

    getCertificado() {
        return this.http
            .get(this.UrlService + 'unidadesEmpresa/certificado')
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    getLogoEmpresa() {
        return this.http
            .get(this.UrlService + 'unidadesEmpresa/getLogoEmpresa')
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    uploadLogoEmpresa(arquivo) {
        return this.http.post(this.UrlService + 'unidadesEmpresa/uploadLogoEmpresa', arquivo)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    removerLogoEmpresa() {
        return this.http.delete(this.UrlService + 'unidadesEmpresa/removerLogoEmpresa')
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    verificaBloqueio(unidadeEmpresaID = 0) { // se unidadeEmpresaID estiver 0 pega o id da unidade empresa do usuario
        return this.http
            .get(this.UrlService + `unidadesEmpresa/verificaBloqueioSistema/${unidadeEmpresaID}`)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    solicitarContato(form) {
        return this.http.post(this.UrlService + 'unidadesEmpresa/contato', form);
    }

    boletosDimensaoEmAberto() {
        return this.http
            .get(this.UrlService + `unidadesEmpresa/boletosDimensaoEmAberto`)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    salvarWhatsFinanceiro(numero: string, empresa: number = null) {
        const obj = {
            numero: numero,
            unidadeEmpresaId: empresa,
        }

        return this.http
            .post(this.UrlService + 'unidadesEmpresa/salvarWhatsFinanceiro', obj)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    autenticarSenhaFinanceiro(senha: string) {
        return this.http
            .post(this.UrlService + 'unidadesEmpresa/autenticarSenhaFinanceiro', { senha })
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    calcularRbt12() {
        return this.http
            .get(this.UrlService + 'unidadesEmpresa/calcularFaturamentoRBT12')
            .pipe(map((res: any) => res.data), catchError(super.serviceError));
    }

    buscarStatusBoletoDim() {
        return this.http
            .get(this.UrlService + 'unidadesEmpresa/buscarStatusBoletoDim')
            .pipe(map((res: any) => res.data), catchError(super.serviceError));
    }

    cadastrarUnidadeEmpresa(form){
        return this.http
            .post(this.UrlService + `unidadesEmpresa`, form)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    consultarCnpj(cnpj: string): Observable<any> {
		return this.http.get(
			this.UrlService + 'unidadesEmpresa/consultarCnpj/' + cnpj)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
	}
}
