import { Component, OnInit } from '@angular/core';
import { StorageFunctions } from '@generals';

@Component({
  selector: 'app-root',
  template: '<router-outlet><spinner></spinner></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(private storageFunctions: StorageFunctions) { }

  ngOnInit(): void {
    this.carregarTema();
  }

  carregarTema() {
    const temaAtual = this.storageFunctions.getDataFromLocalStorage('tema');
    document.documentElement.setAttribute('data-theme', temaAtual ?? 'light');
  }
}