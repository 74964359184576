import { FormGroup, FormControl, Validators } from '@angular/forms';
import { totalGeralDescontos, totalGeralDescontosItensSelecionados, totalGeralItens, totalGeralItensSelecionados, totalGeralItensSelecionadosAFechar } from './itens-documentos-utils';
import { Decimal } from 'decimal.js';

export function GetFormComplemento(): FormGroup {
    return new FormGroup({
        observacao: new FormControl(null, [Validators.maxLength(2000)]),
        observacaoNF: new FormControl(null, [Validators.maxLength(2000)]),
        condicaoPagamento: new FormControl(null),
        condicaoPagamentoPrazo: new FormControl(null),
        contaRecebedoraPrazoId: new FormControl(null),
        portadorPrazoId: new FormControl(null),
        dataBaseVencimento: new FormControl(null),
        tipoComissao: new FormControl(null),
        transportador: new FormControl(null),
        dataEntrega: new FormControl(new Date()),
        valorFrete: new FormControl(null),
        numeroPedidoCliente: new FormControl(null, [Validators.maxLength(15)]),
        clienteEndereco: new FormControl(null)
    });
}

export function totalGeralLiquidoPedido(itens: any[], frete: number): number {
    return new Decimal(totalGeralItens(itens) || 0).minus(totalGeralDescontos(itens) || 0).plus(frete || 0).toNumber();
}

export function totalGeralLiquidoItensSelecionadosPedido(itens: any[], frete: number): number {
    return new Decimal(totalGeralItensSelecionados(itens) || 0).minus(totalGeralDescontosItensSelecionados(itens) || 0).plus(frete || 0).toNumber();
}

export function totalGeralLiquidoItensSelecionadosAFecharPedido(itens: any[], frete: number): number {
    return new Decimal(totalGeralItensSelecionadosAFechar(itens) || 0).minus(totalGeralDescontosItensSelecionados(itens) || 0).plus(frete || 0).toNumber();
}

export const STATUS_ANDAMENTO = 'E';
export const STATUS_FATURADO = 'F';
export const STATUS_FATURADO_PARCIALMENTE = 'P';
export const STATUS_CANCELADO = 'C';
export const STATUS_RECEBIDO = 'X';
export const STATUS_RECEBIDO_PARCIALMENTE = 'Y';
export const STATUS_ORCAMENTO_EFETIVADO = 'Z';