import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from "./base.service";
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class TabelasPrecoService extends BaseService {
  constructor(private http: HttpClient) { super(); }
  get() {
    return this.http
      .get(`${this.UrlService}TabelasPreco`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getTabelaPrecoById(id: number) {
    return this.http
      .get(this.UrlService + "TabelasPreco/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  salvar(form: any) {
    if (form.id == null || form.id == 0) {
      return this.postForm(form);
    } else {
      return this.putForm(form);
    }
  }

  delete(id: number) {
    return this.http.delete(`${this.UrlService}TabelasPreco/${id}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }


  private postForm(form: any) {
    return this.http
      .post(this.UrlService + 'TabelasPreco/', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  private putForm(form: any) {
    return this.http
      .put(this.UrlService + 'TabelasPreco/', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }
}