import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class BancosService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  public get() {
    return this.http.get(this.UrlService + 'Bancos')
      .pipe(map((res: any) => res.data))
      .pipe(catchError(err => err));
  }

  public getPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + `Bancos/Paginado?pageIndex=` + pagina + '&search=' + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  public getById(id: number) {
    return this.http
      .get(this.UrlService + "Bancos/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
