import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  CanActivateChild,
} from "@angular/router";
import Swal from "sweetalert2";
import { StorageFunctions } from "../general/storage";
import { UsuarioService } from "../authentication/services/usuario.service";
import { AcessoTelasService } from "../services/acessoTelas.service";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable()
export class AuthService implements CanActivate, CanActivateChild {
  public token: string;
  public user;

  constructor(
    private router: Router,
    private storageFunctions: StorageFunctions,
    private usuarioService: UsuarioService,
    private acessoTelasService: AcessoTelasService,
    private jwtHelperService: JwtHelperService,
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    return this.canActivate(route, state);
  }

  canActivate(
    routeAc: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    const storage = this.storageFunctions.getStorage();
    this.token = storage.getItem("token");
    this.user = this.storageFunctions.getUser();

    if (!this.token || this.jwtHelperService.isTokenExpired(this.token)) {
        this.router.navigate(['/authentication/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }

    if (routeAc.data && routeAc.data.id) {
      return this.acessoTelasService
        .verificaAcesso(routeAc.data.id)
        .pipe(map((res) => {
          if (
            res.gruposUsuariosId != this.user.grupoUsuariosId ||
            res.versaoGrupoDeUsuarios != this.user.versaoGrupoUsuario
          ) {
            this.storageFunctions.setAcessos().then(() => {
              this.user.grupoUsuariosId = res.gruposUsuariosId;
              this.user.versaoGrupoUsuario = res.versaoGrupoDeUsuarios;

              storage.setItem("user", JSON.stringify(this.user));

              alert(
                "A página será atualizada para recarregar seus acessos liberados!"
              );
              location.reload();
            });
          }

          this.usuarioService
            .gravarLog(routeAc.data.breadcrumb)
            .subscribe(() => {});

          if (!res.acesso) {
            this.router.navigate(this.storageFunctions.getRotaPadrao());
            if (res.tipoDeAcesso == "desenvolvimento") {
              Swal(
                "Acesso Negado - Tela em desenvolvimento",
                "Tela não está disponível por estar em desenvolvimento ou conter algum erro em correção!",
                "error"
              );
            } else if (res.tipoDeAcesso == "dimensao") {
              Swal(
                "Acesso Negado - Tela da dimensão",
                "Tela de uso apenas da equipe Dimensão Sistemas!",
                "error"
              );
            } else {
              Swal(
                "Acesso Negado",
                "Você não tem acesso a esta tela. Fale com o administrador da sua empresa para solicitar o acesso!",
                "error"
              );
            }
            return false;
          }
          return true;
        }))
        .pipe(catchError(() => of(true)));
    }

    return true;
  }
}
