import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseServiceV2 } from '@servicesv2';
import {
  ConsultarEstoquesMovimentacaoSaldosPaginatedResponseViewModel,
  ConsultarEstoquesMovimentacaoSaldosPaginatedViewModel,
  ExportarEstoquesMovimentacaoSaldosViewModel
} from '../../models/estoque/EstoquesViewModel';
import { ConsultaPaginadaViewModel } from '../../model/model.api';

@Injectable()
export class EstoqueMovimentacaoServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('EstoquesMovimentacao', http);
  }

  async saldosPaginated(payload: ConsultarEstoquesMovimentacaoSaldosPaginatedViewModel) {
    return await this
    .postPaginatedAsPromise<
      ConsultarEstoquesMovimentacaoSaldosPaginatedViewModel,
      ConsultaPaginadaViewModel<ConsultarEstoquesMovimentacaoSaldosPaginatedResponseViewModel>
    >(payload, 'saldosPaginated');
  }

  async imprimir(payload: ExportarEstoquesMovimentacaoSaldosViewModel) {
    return await this.downloadBlobAsPromise<
      ExportarEstoquesMovimentacaoSaldosViewModel
    >(payload, 'saldosExport');
  }
}
