export class TipoMotivoDesoneracao {
    private tipo: number;
    private descricao: string;

    get tipoDescricao() {
        return this.tipo + " - " + this.descricao;
    }

    constructor(tipo: number, descricao: string) {
        this.tipo = tipo;
        this.descricao = descricao;
    }


    public static listar(cst: string): TipoMotivoDesoneracao[] {
        let retorno: TipoMotivoDesoneracao[] = [];
        switch (cst) {
            case "20":
            case "70":
            case "90":
                retorno.push(new TipoMotivoDesoneracao(3, "Produtor Agropecuário"));
                retorno.push(new TipoMotivoDesoneracao(9, "Outros."));
                retorno.push(new TipoMotivoDesoneracao(12, "Órgão de fomento e desenvolvimento agropecuário."));
                break;
            case "30":
                retorno.push(new TipoMotivoDesoneracao(6, "Utilitários e Motocicletas da Amazônia Ocidental e Áreas de Livre Comércio"));
                retorno.push(new TipoMotivoDesoneracao(7, "SUFRAMA"));
                retorno.push(new TipoMotivoDesoneracao(9, "Outros."));
                break;
            case "40":
            case "41":
            case "50":
                retorno.push(new TipoMotivoDesoneracao(1, "Táxi"));
                retorno.push(new TipoMotivoDesoneracao(3, "Produtor Agropecuário"));
                retorno.push(new TipoMotivoDesoneracao(4, "Frotista/Locadora"));
                retorno.push(new TipoMotivoDesoneracao(5, "Diplomático/Consular"));
                retorno.push(new TipoMotivoDesoneracao(6, "Utilitários e Motocicletas da Amazônia Ocidental e Áreas de Livre Comércio"));
                retorno.push(new TipoMotivoDesoneracao(7, "SUFRAMA"));
                retorno.push(new TipoMotivoDesoneracao(8, "Venda a Orgão Publico"));
                retorno.push(new TipoMotivoDesoneracao(9, "Outros."));
                retorno.push(new TipoMotivoDesoneracao(10, "Deficiente Condutor."));
                retorno.push(new TipoMotivoDesoneracao(11, "Deficiente Não Condutor."));
                if (cst == "40") retorno.push(new TipoMotivoDesoneracao(90, "Solicitado pelo Fisco"));
                break;
        }
        return retorno;
    }
}