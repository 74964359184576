import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable()
export class RomaneiosCargaService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get() {
    return this.http
      .get(this.UrlService + "romaneiosCarga")
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getPaginado(form: any) {
    return this.http
      .post(this.UrlService + 'romaneiosCarga/paginado', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  salvar(form: any, id: number = null) {
    const obj = {
      ...form, id
    }
    return this.http
      .post(this.UrlService + 'romaneiosCarga', obj)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getById(id: number) {
    return this.http
      .get(this.UrlService + `romaneiosCarga/getById/${id}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  excluir(id: number) {
    return this.http
      .delete(this.UrlService + `romaneiosCarga/${id}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  imprimirRomaneio(id: number, params: any) {
    const model = {
      ...params, id
    }
    return this.http
      .post(this.UrlService + 'romaneiosCarga/imprimir', model, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }
}