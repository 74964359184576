<label
  *ngIf="label"
  [appDimBoldText]="required"
  [for]="id"
  >
  {{label}}
</label>
<input
  *ngIf="!maskType"
  [id]="id"
  [ngbTooltip]="getNgbTooltip()"
  [ngClass]="getNgClass()"
  class="form-control dim-input-text"
  type="text"
  [formControl]="control"
  (input)="onInputTextChange($event)"
  [maxLength]="maxLength"
  [placeholder]="placeholder"
  [ngStyle]="inputTextNgStyle()"
  [readonly]="readonly"
/>
<input
  *ngIf="maskType === 'currency' || maskType === 'quantity'"
  [id]="id"
  [ngbTooltip]="getNgbTooltip()"
  [ngClass]="getNgClass()"
  class="form-control dim-input-text"
  type="text"
  [formControl]="control"
  (keyup)="onInputTextChange($event)"
  [placeholder]="placeholder"
  [ngStyle]="inputTextNgStyle()"
  currencyMask inputmode="numeric"
  [options]="currencyMaskConfig"
  [readonly]="readonly"
/>
<show-errors *ngIf="showErrors" [control]="control"></show-errors>
<i
  *ngIf="icon"
  [ngClass]="icon"
  aria-hidden="true"
  style="position: absolute; top: 39px; left: 25px; color: grey;"
></i>
