import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-combobox-status-documento-modal',
  templateUrl: './combobox-status-documento-modal.component.html',
  styleUrls: ['./combobox-status-documento-modal.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxStatusDocumentoModalComponent),
      multi: true
    }
  ]
})
export class ComboboxStatusDocumentoModalComponent implements OnInit, ControlValueAccessor {

  disabled = false;
  status: string[] = [];
  @Input() appendTo = 'body';
  @Input() statusOptions =
    [
      { id: 'A', name: 'Aprovada', },
      { id: 'C', name: 'Cancelada' },
      { id: 'I', name: 'Inutilizada' },
      { id: 'D', name: 'Denegada' },
      { id: 'R', name: 'Reprovada' },
      { id: '0', name: 'Sem retorno' },
      { id: 'U', name: 'Rascunho' }
    ];

  writeValue(obj: any): void {
    this.status = obj || [];
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  private propagateChange = (_: any) => { };

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onTouched = () => { };

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  constructor() { }

  ngOnInit() {
  }

  selectAll(): void {
    this.status = this.statusOptions.map((val) => val.id);
    this.propagateChange(this.status);
  }

  clean(): void {
    this.status = [];
    this.propagateChange(this.status);
  }

  onChange(event) {
    this.status = event;
    this.propagateChange(this.status);
  }
}
