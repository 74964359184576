import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService } from '../../../../services/message.service';
import { TitulosCobrancaService } from '../../../../services/titulos-cobranca.service';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import swal from 'sweetalert2';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-comissoes-saldo',
  templateUrl: './comissoes-saldo.component.html',
  styleUrls: ['./comissoes-saldo.component.css'],
})
export class ComissoesSaldoComponent extends ModalCadastroComponent implements OnInit {
  constructor(
    private titulosCobrancaService: TitulosCobrancaService,
    private messageService: MessageService
  ) {
    super();
  }

  @ViewChild('modalRelatorio', { static: true }) modalRelatorio;
  form: FormGroup;
  err: string;

  ngOnInit() {
    this.form = new FormGroup({
      periodoIni: new FormControl(null),
      periodoFim: new FormControl(null),
      vendedor: new FormControl(null),
      ordenado: new FormControl('Emissao'),
    });
  }

  showErrorMessages(): boolean {
    if (!this.form.get('vendedor').value) {
      this.err = 'Informe o vendedor.';
      return true;
    }

    if (!this.form.get('periodoIni').value) {
      this.err = 'Informe o período inicial.';
      return true;
    }

    if (!this.form.get('periodoFim').value) {
      this.err = 'Informe o período final.';
      return true;
    }

    if (this.form.get('periodoIni').value > this.form.get('periodoFim').value) {
      this.err = 'O período inicial não pode ser superior ao período final.';
      return true;
    }

    return false;
  }

  async submitForm() {
    if (this.showErrorMessages()) return;
    this.err = '';

    try {
      this.modalRelatorio.hide();
      this.messageService.wait('Gerando relatório de Comissões e Saldos', 999999);
      const res = await this.titulosCobrancaService
        .imprimirRelatorioComissoesComSaldo(this.form.value)
        .toPromise()
        .catch((x) => {
          throw x.errors[0];
        });

      saveAs(res, 'relatorio_comissoes.pdf');
    } catch (err) {
      swal(
        'Erro ao emitir relatório.',
        err.errors ? err.errors.join(', ') : err.message ? err.message : err,
        'error'
      );
    } finally {
      this.messageService.clearAll();
      this.destroyComponent();
    }
  }

  destroyComponent() {
    this.closed.emit();
  }

  show(): void {
    this.modalRelatorio.show();
  }
}
