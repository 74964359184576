import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'anoFabMod'
})
export class AnoFabModPipe implements PipeTransform {

  transform(value: string | number): string {
    const valorString = value.toString();

    const anoFabricacao = valorString.slice(0, 4);
    const anoModelo = valorString.slice(4);

    return `${anoFabricacao}/${anoModelo}`;
  }
}
