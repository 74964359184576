<app-modal-basic #modalRelatorioRelacaoClientes [modalContentId]="'modal-relatoriorelacao'" (closed)="onClose()">
  <div class="app-modal-header">
    <h4 class="modal-title">Relação de Clientes</h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="impressaoForm" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="form-group col-sm-12">
          <label> Estado </label>
          <div class='input'>
            <ng-select appendTo="#modal-relatoriorelacao" formControlName="estadoId" *ngIf="estadosMap"
              placeholder="Estado" [items]="estadosMap" bindLabel="nome" bindValue="id"
              (ngModelChange)="onChangeEstado($event, false)">
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label> Cidade </label>
          <div class='input'>
            <ng-select appendTo="#modal-relatoriorelacao" formControlName="cidadeId" *ngIf="cidadesMap"
              placeholder="Cidade" [items]="cidadesMap" bindLabel="nome" bindValue="id">
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Vendedor</label>
          <div class='input'>
            <ng-select appendTo="#modal-relatoriorelacao" formControlName="representanteId" *ngIf="representantesMap"
              placeholder="Vendedor" [items]="representantesMap" bindLabel="nome" bindValue="id">
            </ng-select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Data de Inclusão</label>
          <div class="input-group">
            <app-data [calendarPosition]="'left'" formControlName="inclusaoDe"></app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="inclusaoAte"></app-data>
          </div>
          <div class='row'>
            <div class='col-sm-6'>
              <show-errors [control]="impressaoForm.controls.inclusaoDe"></show-errors>
            </div>
            <div class='col-sm-6'>
              <show-errors [control]="impressaoForm.controls.inclusaoAte"></show-errors>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Data de Última Compra</label>
          <div class="input-group">
            <app-data [calendarPosition]="'left'" formControlName="compraDe"></app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="compraAte"></app-data>
          </div>
          <div class='row'>
            <div class='col-sm-6'>
              <show-errors [control]="impressaoForm.controls.compraDe"></show-errors>
            </div>
            <div class='col-sm-6'>
              <show-errors [control]="impressaoForm.controls.compraAte"></show-errors>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6 check-form">
          <div class="checkbox-fade fade-in-default">
            <label>
              <input type="checkbox" formControlName="ativos" (click)="checkInput('ativos', $event)" >
              <span class="cr">
                <i class="cr-icon icofont icofont-ui-check txt-default"></i>
              </span>
              <span>Apenas Ativos</span>
            </label>
          </div>
        </div>
        <div class="form-group col-sm-6 check-form">
          <div class="checkbox-fade fade-in-default">
            <label>
              <input type="checkbox" formControlName="inativos" (click)="checkInput('inativos', $event)">
              <span class="cr">
                <i class="cr-icon icofont icofont-ui-check txt-default"></i>
              </span>
              <span>Apenas Desativados</span>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-12 form-group" style="margin-bottom: unset;">
          <label style="font-weight: bold;">Opções de visualização:</label>
        </div>
      </div>
      <div class="row flex">
        <div class="form-radio col-sm-12" style="margin-bottom: 20px;">
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="planilha" [value]="false">
              <i class="helper"></i>Formato .PDF
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="planilha" [value]="true">
              <i class="helper"></i>Formato .XLS
            </label>
          </div>
        </div>
      </div>
      <button type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
        <i class="icofont icofont-print"> </i> Imprimir
      </button>
      <button type="button" class="btn btn-danger ripple" (click)="modalRelatorioRelacaoClientes.hide()"><i
          class="ti ti-close"></i> Cancelar
      </button>
    </form>
  </div>
  <div class="app-modal-footer">
  </div>
</app-modal-basic>