import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class SegmentosUnicosService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get() {
    return this.http
      .get(this.UrlService + "segmentosUnicos")
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  addOrEditSegmento(form) {
    return this.http
      .post(this.UrlService + 'segmentosUnicos', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getPaginado(form: any) {
    return this.http
      .post(this.UrlService + 'segmentosUnicos/paginado', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  delete(id: number) {
    return this.http
      .delete(this.UrlService + `segmentosUnicos/${id}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}