<app-temp-modal-cadastro [allowClickOutside]="!loading['registro']" #modalEstorno (closed)="closed.emit(false)"
  [textHeader]="textHeader">
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
    <div class="row">
      <div class="form-group col-sm-12">
        <label class="label-required">Tipo de estorno</label>
        <div class="form-radio">
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="tipoEstorno" value="0">
              <i class="helper"></i>Estorno na data de crédito <i class="fa fa-info-circle" 
              container="body" [ngbTooltip]="estornoUnico||this.titulosSelecionados.length <= 1?'O lançamento na data do crédito será excluído da movimentação':'Os lançamentos na data do crédito serão excluídos da movimentação'"></i>
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="tipoEstorno" value="1" >
              <i class="helper"></i>Estorno na data informada <i class="fa fa-info-circle" 
              container="body" [ngbTooltip]="estornoUnico||this.titulosSelecionados.length <= 1?'O lançamento será estornado da movimentação através de uma saída na data informada':'Os lançamentos serão estornados da movimentação através de saídas na data informada'"></i>
            </label>
          </div>
        </div>
        <app-data formControlName="dataCredito" *ngIf="cadastroForm.controls['tipoEstorno'].value == '1'"></app-data>
        <show-errors [control]="cadastroForm.controls.dataCredito"></show-errors>
      </div>
    </div>

    <div class="alert alert-danger background-danger" *ngIf="errors">
      <span>{{ errors }}</span>
    </div>

    <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'" [loading]="loading['registro']"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalEstorno.hide()">Cancelar</button>
  </form>
</app-temp-modal-cadastro>