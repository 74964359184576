import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class LancamentosContabeisService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getLotes() {
    return this.http
      .get(this.UrlService + 'LancamentosContabeis/lotes')
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getLancamentos(form) {
    return this.http
      .post(this.UrlService + 'LancamentosContabeis/lancamentos', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getLancamentoById(id: number) {
    return this.http
      .get(this.UrlService + "LancamentosContabeis/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  validarLote(lote: string) {
    return this.http
      .get(this.UrlService + "LancamentosContabeis/validarLote/" + lote)
      .pipe(map((res: any) => res)
        , catchError(super.serviceError));
  }

  gerarLote(lote: string, form) {
    return this.http.post(this.UrlService + 'LancamentosContabeis/gerarLote/' + lote, form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  excluirLote(lote: string) {
    return this.http.delete(this.UrlService + 'LancamentosContabeis/excluirLote/' + lote)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  put(form) {
    return this.http.put(this.UrlService + 'LancamentosContabeis/atualizar', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  delete(id: number) {
    return this.http
      .delete(this.UrlService + "LancamentosContabeis/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  deleteMultiplos(selecionados) {
    return this.http
      .post(this.UrlService + "LancamentosContabeis/delete", selecionados)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  imprimirRazonete(lotes) {
    return this.http
      .post(this.UrlService + `LancamentosContabeis/impressaoRazonete`, lotes, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  imprimirImportacaoDeLancamentosContaeis(lotes) {
    return this.http
      .post(this.UrlService + `LancamentosContabeis/importacaoLancamentosContabeis`, lotes, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }
}
