import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ContasContabeisService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get(form) {
    return this.http
      .post(this.UrlService + 'ContasContabeis/buscar', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getContasContabeis(pagina: number, nome: string, somenteAceitaLancamento: boolean) {
    return this.http
      .get(this.UrlService + "ContasContabeis/Paginado?pageIndex=" + pagina + "&search=" + encodeURIComponent(nome) + "&somenteAceitaLancamento="+somenteAceitaLancamento)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getContasContabeisComboBox(pagina: number, nome: string, filters: any[]) {
    return this.http
      .post(this.UrlService + `contasContabeis/GetContasContabeisComboBox?pageIndex=${pagina}&search=${encodeURIComponent(nome)}`, filters)
      .pipe(map((res: any) => res.data)
          , catchError(super.serviceError));
  }

  getAll() {
    return this.http
    .get(this.UrlService + 'ContasContabeis/buscar')
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getContaByNivel(nivel: number) {
    return this.http
      .post(this.UrlService + "ContasContabeis/nivel", nivel)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getContaById(id: number) {
    return this.http
      .get(this.UrlService + "ContasContabeis/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  post(form) {
    return this.http.post(this.UrlService + 'ContasContabeis/salvar', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  put(form) {
    return this.http.put(this.UrlService + 'ContasContabeis/atualizar', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  delete(id: number) {
    return this.http
      .delete(this.UrlService + "ContasContabeis/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}