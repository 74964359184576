import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable()
export class ValidacaoNumeroWhatsAppService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  validarOtp(codigo: string, numero: string) {
    const model = {
      codigo: codigo,
      numero: "+55" + numero,
    }

    return this.http
      .post(this.UrlService + "ValidacaoNumeroWhatsApp/ValidarWhatsApp", model)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  };
}