<app-modal-basic #modal (closed)="onClose()" [modalContentId]="'modal-rv'" dialogClass="modal-dialog--w-650">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <i class="modal-title m-r-5"></i> Exportação NFC-e
    </h4>
    <button type="button" class="close basic-close" (click)="hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="form">
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Emissão</label>
          <div class="input-group">
            <app-data [calendarPosition]="'left'" formControlName="emissao1">
            </app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="emissao2"></app-data>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Cliente</label>
          <ng-select
            #select
            appendTo="#modal-rv"
            [items]="clientes"
            formControlName="cliente"
            placeholder="Selecione"
            [virtualScroll]="true"
            bindLabel="razaoSocial"
            bindValue="id"
            (scrollToEnd)="fetchMoreClientes(select.searchTerm)"
            [loading]="loading['clientes']"
            [typeahead]="clienteInput"
          >
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Status</label>
          <app-combobox-status-documento-modal
            [statusOptions]="statusOptions"
            formControlName="status"
            appendTo="#modal-rv"
          ></app-combobox-status-documento-modal>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>
            Destinatários
            <span
              ngbTooltip="Para inserir um novo destinatário, digite-o no campo abaixo e tecle enter."
              placement="right"
            >
              <i class="fa fa-question-circle help-icon"></i>
            </span>
          </label>
          <div class="email-box" *ngIf="emails.length > 0">
            <div class="email" *ngFor="let email of emails; let number = index">
              {{ email }}
              <button
                type="button"
                class="close"
                aria-label="Close"
                (click)="deleteEmail(number)"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <input
            #inputDestinatarios
            (keyup)="addEmail($event)"
            (blur)="addEmail($event, true)"
            class="form-control"
            type="text"
          />
          <show-errors [control]="form.controls['destinatarios']" [validateTouched]="false"></show-errors>
        </div>
      </div>
      <label
        >Opções do arquivo
        <span
          ngbTooltip="Para impedir o spam de arquivos no navegador, o download separado de múltiplos documentos só é permitido com a opção
        'Arquivo Zip' ativada."
          placement="right"
        >
          <i class="fa fa-question-circle help-icon"></i>
        </span>
      </label>
      <div class="checkbox-space">
        <div class="row">
          <div class="form-group col-sm-3">
            <div class="checkbox-fade fade-in-default">
              <label>
                <input
                  type="checkbox"
                  formControlName="arquivoPdf"
                  value="arquivoPdf"
                  (change)="handleDownloadFileTypeChange($event)"
                  [disabled]="loading['download'] || loading['email']"
                />
                <span class="cr">
                  <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span>
                <span>PDF</span>
              </label>
            </div>
          </div>
          <div class="form-group col-sm-3">
            <div class="checkbox-fade fade-in-default">
              <label>
                <input
                  type="checkbox"
                  formControlName="arquivoExcel"
                  value="arquivoExcel"
                  (change)="handleDownloadFileTypeChange($event)"
                  [disabled]="loading['download'] || loading['email']"
                />
                <span class="cr">
                  <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span>
                <span>XLSX</span>
              </label>
            </div>
          </div>
          <div class="form-group col-sm-3">
            <div class="checkbox-fade fade-in-default">
              <label>
                <input
                  type="checkbox"
                  formControlName="arquivoZip"
                  value="arquivoZip"
                  (change)="handleDownloadFileTypeChange($event)"
                  [disabled]="loading['download'] || loading['email']"
                />
                <span class="cr">
                  <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span>
                <span>Arquivo Zip</span>
              </label>
            </div>
          </div>
          <div class="form-group col-sm-3">
            <div class="checkbox-fade fade-in-default">
              <label>
                <input
                  type="checkbox"
                  formControlName="arquivoUnico"
                  value="arquivoUnico"
                  (change)="handleDownloadFileTypeChange($event)"
                  [disabled]="loading['download'] || loading['email']"
                />
                <span class="cr">
                  <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span>
                <span>Arquivo Único</span>
              </label>
            </div>
          </div>
          <div class="col-12">
            <show-errors [control]="form.controls['arquivoPdf']" [validateTouched]="false"></show-errors>
          </div>
        </div>
      </div>
      <div #selecaoColunasPDF>
        <div *ngIf="tipoImpressao === 'pdf'">
          <label>Selecione as colunas que serão exibidas no PDF</label>
          <div class="checkbox-space" [formGroup]="formArquivoPdfColunas">
            <div class="row form-group my-1">
              <div *ngFor="let coluna of impressaoNFCe" class="col-4">
                <div
                  class="form-control checkbox-fade fade-in-default text-truncate"
                  style="border: unset; padding-left: 0px"
                >
                  <label style="margin-bottom: 0px">
                    <input type="checkbox" [formControlName]="coluna.value" [value]="coluna.value" [checked]="coluna.selected"/>
                    <span class="cr">
                      <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                    </span>
                    <span>{{ coluna.label }}</span>
                  </label>
                </div>
              </div>
              <div class="col-12">
                <show-errors [control]="formArquivoPdfColunas.controls['numero']" [validateTouched]="false"></show-errors>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <button
      type="submit"
      class="btn btn-primary ripple light custom-form-button"
      (click)="download()"
      [disabled]="loading['download']"
    >
      <app-loading
        [loading]="loading['download']"
        [icone]="'fa fa-download'"
        [texto]="'Download'"
        [textoLoading]="'Montando Arquivo'"
      ></app-loading>
    </button>
    <button
      type="submit"
      class="btn btn-info ripple light custom-form-button"
      (click)="email()"
      [disabled]="loading['email']"
    >
      <app-loading
        [loading]="loading['email']"
        [icone]="'fa fa-envelope-open'"
        [texto]="'Enviar por E-mail'"
        [textoLoading]="'Enviando'"
      ></app-loading>
    </button>
  </div>
</app-modal-basic>
