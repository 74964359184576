import { Component, Input, AfterContentInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { fieldRequired, markFormGroupTouched, setFieldFormValue, getDeviceType, disableFieldsForm } from '../../general/utils';
import { SweetAlertOptions } from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../services/message.service';
import { StorageFunctions } from '../../general/storage';
import { Location } from '@angular/common';
import { UtilFunctions } from '../../general/utils-service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cadastro-padrao',
  template: ''
})
export class CadastroPadraoComponent implements AfterContentInit {

  @Input() id: number;
  @Input() form: FormGroup;
  @Input() errors = [];
  @Input() loading = {};
  @Input() disable = {};
  @Input() visible = {};
  formConsulta = '';
  cancelaVerificacaoPermissao = false;
  tempoVideo = 0;
  private nomeVideo: String = '';
  private abrirModalInterno: any = null;
  modalSubscription: Subscription;

  get novoCadastro(): boolean {
    return !this.id;
  }

  acoesTela = {};

  constructor(
    public route: ActivatedRoute,
    public utilFunctions: UtilFunctions,
    public messageService: MessageService,
    public storage: StorageFunctions,
    public location: Location
  ) { }

  ngAfterContentInit() {
    this.getAction();
  }

  voltar(consultar = true) {
    if (this.formConsulta) { this.storage.setConsultarSozinho(this.formConsulta, consultar); }
    this.location.back();
  }

  getAction() {
    if (!this.route.routeConfig.data.acoes || this.cancelaVerificacaoPermissao) { return; }
    const acoes = JSON.parse(JSON.stringify(this.route.routeConfig.data['acoes']));
    this.utilFunctions.ObterAcoesDeTela(acoes).then(res => {
      this.acoesTela = res;
      if (this.acoesTela) {
        if (this.acoesTela['cadastro'] && !this.acoesTela['cadastro'].acesso && this.novoCadastro) {
          this.messageService.error('Você não tem acesso para cadastrar, contate o administrador de sua empresa!', 5000);
          this.voltar(false);
        } else if (this.acoesTela['editar'] && !this.acoesTela['editar'].acesso && !this.novoCadastro) {
          this.loading['disable'] = true;

          const config = this.getActionByRoute('editar');
          if (config && config.voltar) {
            this.voltar(false);
          }
        } else if (this.acoesTela['baixa'] && !this.acoesTela['baixa'].acesso) {
          this.messageService.error('Você não tem acesso para baixar, contate o administrador de sua empresa!', 5000);
          this.voltar(false);
        } else if (this.acoesTela['ofx'] && !this.acoesTela['ofx'].acesso) {
          this.messageService.error('Você não tem acesso para importar OFX, contate o administrador de sua empresa!', 5000);
          this.voltar(false);
        } else if (this.acoesTela['despacho'] && !this.acoesTela['despacho'].acesso) {
          this.messageService.error('Você não tem acesso para despachar pedidos, contate o administrador de sua empresa!', 5000);
          this.voltar(false);
        }
      }

      this.OnGetAction();
    });
  }

  OnGetAction() {
    // function to override to execute a action after of get de actions of screen
  }

  verificaAcesso(nomeCampo) {
    if (this.acoesTela[nomeCampo] && this.acoesTela[nomeCampo].acesso) {
      return true;
    }
    return false;
  }

  getActionByRoute(acao) {
    const acoes = this.route.routeConfig.data['acoes'];
    return acoes.find(element => {
      return element.acao === acao;
    });
  }

  fieldRequired(prop): boolean {
    return fieldRequired(this.form, prop);
  }

  getFormValue(control) {
    if (this.form.get(control)) {
      return this.form.get(control).value;
    }
    return null;
  }

  tituloPagina(pagina): string {
    return this.id ? `Editar ${pagina}` : `Cadastrar ${pagina}`;
  }

  setFieldFormValue(key, value) {
    setFieldFormValue(this.form, key, value);
  }

  validaForm() {
    markFormGroupTouched(this.form);
  }

  alteraLoading(loading: boolean, prop = 'registro') {
    if (loading) { this.errors = null; }
    this.loading[prop] = loading;
  }

  operacaoRealizada(consultaSozinho = true) {
    this.voltar(consultaSozinho);
    this.messageService.success();
  }

  mensagemExclusao(): SweetAlertOptions {
    return {
      title: 'Confirma exclusão deste registro?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      showLoaderOnConfirm: true,
      cancelButtonText: 'Não',
      focusCancel: true,
      confirmButtonClass: 'btn btn-success btn-swal-modal',
      cancelButtonClass: 'btn btn-danger mr-sm',
    };
  }

  get deviceType() {
    return getDeviceType();
  }

  disableFieldsForm(disable: boolean) {
    return disableFieldsForm(this.form, disable);
  }

  adicionaInformacoesVideo(abrirModal: any, nomeVideo: String, tempo = 0) {
    this.abrirModalInterno = abrirModal;
    this.nomeVideo = nomeVideo;
    this.tempoVideo = tempo;

    this.verificaAbreModalAjuda();
  }

  getTimeVideo(value) {
    this.tempoVideo = value;
  }

  private verificaAbreModalAjuda() {
    if (this.nomeVideo.length > 0 && this.abrirModalInterno != null) {
      this.utilFunctions.verificaAbreVideoSozinho(this.nomeVideo)
        .then(res => {
          if (res) {
            this.abrirModalAjuda();
          }
        });
    }
  }

  abrirModalAjuda() {
    if (this.nomeVideo.length > 0 && this.abrirModalInterno != null) {
      this.modalSubscription = this.abrirModalInterno.closed.subscribe(this.getTimeVideo.bind(this));
      this.abrirModalInterno.abrir('exibirVideo', { video: this.nomeVideo, time: this.tempoVideo });
    }
  }

  /**
   * Determina o tipo de operação (edição ou cadastro) com base na rota atual.
   *
   * @returns {'edicao' | 'cadastro'} O tipo de operação, 'edicao' se a rota corresponder a um padrão de edição, 'cadastro' caso contrário.
   *
   * @description
   * Este método verifica o `path` da configuração da rota atual (`this.route.snapshot.routeConfig?.path`).
   * Se o `path` corresponder a um padrão de edição (por exemplo, 'editar/:id'), a função retorna 'edicao'.
   * Caso contrário, retorna 'cadastro', indicando que a operação é um novo cadastro.
   */
  get tipoCadastroEdicao(): 'edicao' | 'cadastro' {
    const path = this.route.snapshot.routeConfig?.path || '';
    if (path && path.includes('editar/:id')) {
      return 'edicao';
    }
    return 'cadastro';
  }

  /**
   * Define o ID a partir dos parâmetros da rota, se a operação for de edição e o ID for válido.
   *
   * @returns {boolean} Retorna `true` se o ID foi definido com sucesso, `false` caso contrário.
   *
   * @description
   * Este método privado verifica se a operação atual é de edição (utilizando o getter `tipoCadastroEdicao`).
   * Em caso afirmativo, tenta extrair o ID dos parâmetros da rota (`this.route.snapshot.params.id`).
   * Se o ID for encontrado e for um número válido, ele é convertido para número e atribuído à propriedade `id` do componente.
   * Retorna `true` para indicar sucesso na definição do ID, ou `false` se a operação não for de edição ou se o ID for inválido.
   */
  private setRouteId(): boolean {
    if (this.tipoCadastroEdicao === 'cadastro') {
      return false;
    }

    const id = this.route.snapshot.params.id;
    if (!id || isNaN(id)) {
      return false;
    }

    this.id = Number(id);

    return true;
  }

  /**
   * Obtém o ID a partir da propriedade interna `id` ou, se necessário, dos parâmetros da rota.
   *
   * @returns {number} Retorna o ID encontrado, ou 0 se nenhum ID válido for encontrado.
   *
   * @description
   * Este método público retorna o ID previamente armazenado na propriedade `id` do componente.
   * Se o `id` não estiver definido, tenta obter o ID dos parâmetros da rota chamando o método privado `setRouteId`.
   * Se a busca pelo ID falhar (indicado pelo retorno `false` de `setRouteId`), o método retorna 0.
   */
  public getRouteId(): number {
    const id = this.id;
    if (!id && !this.setRouteId()) {
      return 0;
    }
    return this.id;
  }
}
