import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseServiceV2 } from '@servicesv2';
import { ConsultaAutomacaoImpostosResponseViewModel, ConsultaAutomacaoImpostosViewModel } from 'models/itens/ConsultaItensViewModel';

@Injectable()
export class ItensServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('Itens', http);
  }

  consultaAutomacaoImpostos(consulta: ConsultaAutomacaoImpostosViewModel) {
    return this.postAsPromise<ConsultaAutomacaoImpostosViewModel, ConsultaAutomacaoImpostosResponseViewModel>(consulta, 'consultaAutomacaoImpostos');
  }

}
