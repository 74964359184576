<app-modal-basic #modalRelatorio (closed)="destroyComponent()" [modalContentId]="'modal-produtos-carregados-origem'">
	<div class="app-modal-header">
		<h4 class="modal-title">
			Impressão relatório de produtos carregados por origem
		</h4>
	</div>
	<div class="app-modal-body">
		<form [formGroup]="form">
			<div class="row">
				<div class="form-group col-sm-12">
					<label style="font-weight: bold">Período</label>
					<div class="input-group">
						<ng-select
							appendTo="#modal-produtos-carregados-origem"
							formControlName="anoI"
							placeholder="Ano inicial"
							[items]="anos"
						>
						</ng-select>
						<span class="input-group-addon">até</span>
						<ng-select
							appendTo="#modal-produtos-carregados-origem"
							formControlName="anoF"
							placeholder="Ano final"
							[items]="anos"
						>
						</ng-select>
					</div>

					<!-- GAMBI PARA APARECER SÓ UM ERRO CASO OS DOIS ESTEJAM COM ERRO -->
					<ng-container
						*ngIf="
							this.form.controls['anoI'].errors &&
								this.form.controls['anoF'].errors;
							else elseBlock
						"
						><show-errors
							[control]="this.form.controls['anoI']"
						></show-errors
					></ng-container>

					<ng-template #elseBlock>
						<show-errors
							[control]="this.form.controls['anoI']"
						></show-errors>

						<show-errors
							[control]="this.form.controls['anoF']"
						></show-errors
					></ng-template>
					<!-- FIM DA GAMBI -->
				</div>
			</div>

			<div class="row flex">
				<div class="form-radio col-sm-12" style="margin-bottom: 10px">
					<div class="radio radio-inline">
						<label>
							<input
								type="radio"
								formControlName="formato"
								[value]="true"
							/>
							<i class="helper"></i>Formato .PDF
						</label>
					</div>
					<div class="radio radio-inline">
						<label>
							<input
								type="radio"
								formControlName="formato"
								[value]="false"
							/>
							<i class="helper"></i>Formato .XLS
						</label>
					</div>
				</div>
			</div>
			<button
				class="btn btn-primary ripple light"
				[disabled]="loading['request']"
				(click)="gerarPDF()"
			>
				<i class="icofont icofont-print"> </i> Imprimir
			</button>
			<button
				type="button"
				class="btn btn-danger ripple"
				(click)="modalRelatorio.hide()"
			>
				<i class="ti ti-close"></i>
				Cancelar
			</button>
		</form>
	</div>
</app-modal-basic>
