import { Component, OnInit, forwardRef, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { mergeArray, validarCNPJ, validarCPF } from '../../general/utils';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NfConsumidorService } from '../../services/nf-consumidor.service';
import { NfceClienteDigitacao } from '../../model/nfceClienteDigitacao';
import { AbrirModalComponent } from '../../shared/abrir-modal/abrir-modal.component';

@Component({
  selector: 'app-combobox-cliente',
  templateUrl: './combobox-cliente.component.html',
  styleUrls: ['./combobox-cliente.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxClienteComponent),
      multi: true
    }
  ]
})
export class ComboboxClienteComponent implements OnInit, ControlValueAccessor {

  clientes = [];
  clienteInput = new Subject<string>();
  loading = {};
  cliente: NfceClienteDigitacao = null;
  disabled = false;
  errors = {};
  private modalSubscription: Subscription;

  @ViewChild('abrirModal', { static: false }) abrirModal: AbrirModalComponent;
  @ViewChild('select', { static: false }) comboClientes: NgSelectComponent;

  writeValue(obj: NfceClienteDigitacao): void {
    if (obj) {
      if (obj.id) {
        this.cliente = obj;
        this.carregaCliente(obj.id, true);

      } else if (obj.cnpj) {
        this.selecionaClienteSemCadastro(obj.cnpj);
      }
    } else {
      this.cliente = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any) => { };

  onTouched = () => { };

  onChange = (event) => {
    this.cliente = event;
    this.comboClientes.close();
    this.propagateChange(this.cliente);
  }

  constructor(private nfcService: NfConsumidorService) { }

  ngOnInit() {
    this.carregaClientes();
    this.onSearchCliente();
  }

  carregaClientes() {
    this.loading['clientes'] = true;
    this.nfcService.getClientesPaginadoNfce(1, '')
      .pipe(finalize(() => this.loading['clientes'] = false))
      .subscribe(res => this.clientes = mergeArray(this.clientes, res));
  }

  fetchMoreCliente(term) {
    this.loading['clientes'] = true;
    this.nfcService.getClientesPaginadoNfce(((this.clientes.length / 20) + 1), term || '')
      .pipe(finalize(() => this.loading['clientes'] = false))
      .subscribe(result => {
        this.clientes = mergeArray(this.clientes, result);
      });
  }

  onClose() {
    if (!this.cliente) {
      this.clientes = [];
      this.carregaClientes();
    }
    this.onTouched();
  }

  onSearchCliente() {
    this.clienteInput.pipe(
      debounceTime(200),
      tap(() => {
        this.errors['cpfCnpj'] = false;
        this.loading['clientes'] = true;
      }),
      switchMap(term => this.nfcService.getClientesPaginadoNfce(1, term || '').pipe(
        tap(() => this.loading['clientes'] = false)
      ))
    )
    .pipe(finalize(() => this.loading['clientes'] = false))
      .subscribe(data => {
        this.clientes = data;
      }, () => {
        this.onSearchCliente();
      });
  }

  modalClienteClosed(id) {
    this.modalSubscription.unsubscribe();
    if (id) { this.carregaCliente(id, true); }
  }

  carregaCliente(clienteId: number, setValue: boolean = false) {
    this.loading['clientes'] = true;
    if (clienteId) {
      this.nfcService.getClienteNfceById(clienteId)
        .pipe(finalize(() => this.loading['clientes'] = false))
        .subscribe((res) => {
          this.clientes.unshift(res);
          this.clientes = mergeArray(this.clientes);
          if (setValue) { this.onChange(res); }
        });
    }
  }

  descricaoCliente(item): string {
    return item['cnpj'] + ' - ' + item['razaoSocial'];
  }

  selecionaClienteSemCadastro(searchTerm) {
    if (!validarCNPJ(searchTerm) && !validarCPF(searchTerm)) {
      this.errors['cpfCnpj'] = true;
      this.comboClientes.open();
    } else {
      const object = { id: null, cnpj: searchTerm, razaoSocial: searchTerm };
      this.clientes = [object];
      setTimeout(() => {
        this.onChange(object);
      });
    }
  }

  get mensagemCpfCnpj(): string {
    return this.errors['cpfCnpj'] === true ? 'INVÁLIDO' : 'NÃO CADASTRADO';
  }

  subscribeModal() {
    this.modalSubscription = this.abrirModal.closed.subscribe(this.modalClienteClosed.bind(this));
  }
}
