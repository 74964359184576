import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { CartoesViewModel } from '../model/CartoesViewModel';
import { Observable } from 'rxjs';
import { CadastroCartoesViewModel } from '../model/CadastroCartoesViewModel';

@Injectable()
export class CartoesService extends BaseService {
  constructor(private http: HttpClient) {
    super();
  }

  getCartoes(): Observable<CartoesViewModel[]> {
    return this.http
      .get(this.UrlService + 'cartoes')
      .pipe(
        map((res: any) => res.data),
        catchError(super.serviceError)
      );
  }

  possuiDebitoAutomatico(): Observable<boolean> {
    return this.http
      .get(this.UrlService + 'cartoes/PossuiDebitoAutomatico')
      .pipe(
        map((res: any) => res.data),
        catchError(super.serviceError)
      );
  }

  cadastrarCartaoFinanceiro(form: CadastroCartoesViewModel) {
    return this.http.post(this.UrlService + 'cartoes', form).pipe(
      map((res: any) => res.data),
      catchError(super.serviceError)
    );
  }

  excluirCartaoFinanceiro(id: number) {
    return this.http.delete(this.UrlService + 'cartoes/' + id).pipe(
      map((res: any) => res.data),
      catchError(super.serviceError)
    );
  }

  editarCartao(cartao: CartoesViewModel) {
    return this.http.put(this.UrlService + 'cartoes', cartao).pipe(
      map((res: any) => res.data),
      catchError(super.serviceError)
    );
  }

  habilitarDebitoAutomatico() {
    return this.http.post(this.UrlService + 'cartoes/habilitarDebitoAutomatico', {}).pipe(
      map((res: any) => res.data),
      catchError(super.serviceError)
    );
  }
}
