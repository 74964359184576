<app-modal-basic #modalConsulta (closed)="closed.emit()">
  <div class="app-modal-header">
    <h4 class="modal-title text-center">Replicar CFOP de Saída</h4>
  </div>
  <div class="app-modal-body" style="padding-block: 1rem;">
    <div class="row">
      <div class="form-group col-sm-12">
        <label class="label-required">CFOP de Saída</label>
        <ng-select autofocus [(ngModel)]="cfopSelecionado" placeholder="Código Fiscal" (change)="cfopChanged($event)">
          <ng-option *ngFor="let opt of codigosFiscais" [value]='opt.codigo'>
            {{opt.codigo}} - {{opt.descricao}}
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div *ngIf="errors" class="alert alert-danger">
      <span>{{ errors }}</span>
    </div>

    <button type="button" (click)="replicar()" class="btn btn-primary ripple light">
      <i class="fa fa-clone"></i> Replicar
    </button>
    <button type="button" (click)="modalConsulta.hide()" class="btn btn-default ripple">
      <i class="fa fa-close"></i> Cancelar
    </button>
  </div>
</app-modal-basic>

