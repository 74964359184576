import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MessageService } from '../../../services/message.service';
import { AdminService } from '../../../admin/services/admin.service';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-evasao',
	templateUrl: './evasao.component.html',
	styleUrls: ['./evasao.component.css'],
})
export class EvasaoComponent extends ModalCadastroComponent implements OnInit {
	constructor(
		private service: AdminService,
		private message: MessageService
	) {
		super();
	}

	@ViewChild('modalRelatorio', { static: true }) modalRelatorio;
	form: FormGroup;
	meses = [
		{ ano: null, value: 'Janeiro', id: 1 },
		{ ano: null, value: 'Fevereiro', id: 2 },
		{ ano: null, value: 'Março', id: 3 },
		{ ano: null, value: 'Abril', id: 4 },
		{ ano: null, value: 'Maio', id: 5 },
		{ ano: null, value: 'Junho', id: 6 },
		{ ano: null, value: 'Julho', id: 7 },
		{ ano: null, value: 'Agosto', id: 8 },
		{ ano: null, value: 'Setembro', id: 9 },
		{ ano: null, value: 'Outubro', id: 10 },
		{ ano: null, value: 'Novembro', id: 11 },
		{ ano: null, value: 'Dezembro', id: 12 },
	];

	ngOnInit() {
		this.meses = this.getUltimosMeses();

		this.form = new FormGroup({
			ano: new FormControl(new Date().getFullYear()),
			mes: new FormControl(this.meses[0].id, Validators.required),
			formato: new FormControl(true),
		});
	}

	getUltimosMeses() {
		const dataAtual = new Date();
		const mesAtual = dataAtual.getMonth() + 1;
		const anoAtual = dataAtual.getFullYear();

		const ultimosSeisMeses = [];
		for (let i = 0; i < 6; i++) {
			const indexMes = (mesAtual - i + 11) % 12 + 1;
			const ano = mesAtual - i > 0 ? anoAtual : anoAtual - 1;

			ultimosSeisMeses.push({
				ano: ano,
				value: this.meses[indexMes - 1].value + '/' + ano,
				id: indexMes,
			});
		}

		return ultimosSeisMeses;
	}

	changeMes(mes) {
		this.form.get('ano').setValue(mes.ano);
	}

	show() {
		this.modalRelatorio.show();
	}

	gerarPDF() {
		if (!this.form.valid) return;

		this.message.wait('Gerando relatório...', 120000);
		this.destroyComponent();
		this.service.relatorioEvasao(this.form.value).subscribe(
			(response) => {
				this.message.success('Relatório gerado com sucesso!');
				const formato = this.form.get('formato').value ? 'pdf' : 'xlsx';

				saveAs(response, `relatorio_evasao.${formato}`);
			},
			(error) => {
				this.message.error(
					error.errors[0],
					10000,
					'Erro ao emitir o relatório!'
				);
			}
		);
	}

	destroyComponent() {
		this.closed.emit();
	}
}
