<app-modal-basic #modalLarge [dialogClass]="'modal-lg'" (closed)="onClose()">
  <div class="app-modal-header">

    <h4 class="modal-title">
      <i class="modal-title icofont icofont-comment m-r-5"></i> Gerar Lote
    </h4>

    <button type="button" class="close basic-close" (click)="modalLarge.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="app-modal-body">

    <form novalidate (ngSubmit)="gerarLote()" [formGroup]="formLote">
      <div class="md-float-material d-flex">
        <div class="col-md-12 btn-add-task">
          <div class="row">

            <div class="form-group col-sm-6">
              <label [style.font-weight]="'bold'">Data Início</label>
              <app-data formControlName="dataInicio"></app-data>
              <show-errors [control]="formLote.controls.dataInicio"></show-errors>
            </div>
            <div class="form-group col-sm-6">
              <label [style.font-weight]="'bold'">Data Fim</label>
              <app-data formControlName="dataFim"></app-data>
              <show-errors [control]="formLote.controls.dataFim"></show-errors>
            </div>
          </div>

          <div>
            <button class="btn btn-primary custom-form-button" type="submit" (click)="validaForm()"
              [disabled]="!formLote.valid">
              <i class="ti ti-new-window"></i> Gerar Lote
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</app-modal-basic>