import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Usuario } from "../models/usuario";

import { StorageFunctions } from '../../general/storage';
import { BaseService } from "../../services/base.service";
import LoginFinanceiroViewModel from '../models/LoginFinanceiroViewModel';


@Injectable()
export class UsuarioService extends BaseService {

    constructor(
        private http: HttpClient,
        private storageFunctions: StorageFunctions
    ) { super(); }

    login(usuario: Usuario): Observable<Usuario> {
        localStorage.removeItem('recentLogin');

        let response = this.http
            .post(this.UrlService + "account/login", usuario)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
        return response;
    };

    loginByToken(): Observable<Usuario> {
        let response = this.http
            .post(this.UrlService + "account/loginByToken", { login: "", senha: "" })
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
        return response;
    };

    gravarLog(tela?: any) {
        const storage = this.storageFunctions.getStorage();
        let user = JSON.parse(storage.getItem("user"));

        let log = {
            UsuarioId: user.id,
            tela: tela
        }

        return this.http.post(this.UrlService + 'logacessoweb/gravarLog/', JSON.stringify(log))
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    logout() {
        const storage = this.storageFunctions.getStorage();
        storage.removeItem('token');
        storage.removeItem('user');
        storage.removeItem("latestFilter");
        storage.removeItem("acessos");
    }

    loginFinanceiro(body: LoginFinanceiroViewModel) {
        localStorage.removeItem('recentLogin');

        let response = this.http
            .post(this.UrlService + "account/loginFinanceiro", body)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
        return response;
    }

    enviarEmailRecuperacaoSenha(usuario: string) {
        return this.http.post(this.UrlService + 'account/email', { usuario })
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    validarToken(token: string) {
        return this.http.get(this.UrlService + `account/validarToken/${token}`)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    alterarSenha(senha: string, userId: number, token: string) {
        const obj = { senha, userId, token }
        return this.http.post(this.UrlService + 'account/alterarSenhaUsuario', obj)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    buscarUsuariosToken(token: string) {
        const obj = { token }
        return this.http.post(this.UrlService + 'account/BuscarUsuariosToken', obj)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    obterUsuariosToken(usuariosId: any[]) {
        return this.http
            .post(this.UrlService + 'account/obterUsuariosToken', usuariosId)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }
}


