import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class EventosContabeisEntidadeService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get(form) {
    return this.http
      .post(this.UrlService + 'EventosContabeisEntidade/buscar', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getEventosContabeisEntidade(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + "EventosContabeisEntidade/Paginado?pageIndex=" + pagina + "&search=" + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getAll() {
    return this.http
      .get(this.UrlService + 'EventosContabeisEntidade/buscar')
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getEventoById(id: number) {
    return this.http
      .get(this.UrlService + "EventosContabeisEntidade/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getEventoByEntidade(form?: any) {
    return this.http
      .post(this.UrlService + 'EventosContabeisEntidade/byEntidade', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  post(form) {
    return this.http.post(this.UrlService + 'EventosContabeisEntidade/salvar', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  put(form) {
    return this.http.put(this.UrlService + 'EventosContabeisEntidade/atualizar', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  delete(id: number) {
    return this.http
      .delete(this.UrlService + "EventosContabeisEntidade/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  salvar(form) {
    if (form.id == 0) {
      return this.post(form)
    } else {
      return this.put(form)
    }
  }
}