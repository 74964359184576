/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable dot-notation */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { getDeviceType, getOriantation } from '../../general/utils';
import { DomSanitizer } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';
import { ExibirVideosUsuarioService } from '../../services/exibirVideosUsuario.service';

@Component({
  selector: 'app-dim-video-tutorial',
  templateUrl: './dim-video-tutorial.component.html',
  styleUrls: ['./dim-video-tutorial.component.scss']
})
export class DimVideoTutorialComponent implements OnInit, OnChanges {

  constructor(
    private sanitizer: DomSanitizer,
    private exibirVideosUsuarioService: ExibirVideosUsuarioService
  ) {
  }

  @Input() open = false;
  @Input() video: string;
  @Input() ocultarExibirNovamente = true;
  @Input() time = 0;

  form: FormGroup;
  videoModalOpen = false;
  naoExibirNovamente = true;

  YT: any;
  YTvideo: any;
  YTplayer: any;
  YTreframed = false;

  @Output() closed = new EventEmitter();

  defaultImg = 'assets/images/lucasdelara.jpg';
  videos = {
    'EmissaoNFCe': {
      video: 'https://www.youtube.com/embed/vKhQr4-_m2Y',
      videoID: 'vKhQr4-_m2Y',
    },
    'Pedido': {
      video: 'https://www.youtube.com/embed/rmmLqMdibAs',
      videoID: 'rmmLqMdibAs',
    },
    'MovimentaEstoque': {
      video: 'https://www.youtube.com/embed/7vtm8_naxMM',
      videoID: '7vtm8_naxMM',
    },
    'GerenciamentoUsuarios': {
      video: 'https://www.youtube.com/embed/U6HmVhgdZtM',
      videoID: 'U6HmVhgdZtM',
    },
    'CadastroProduto': {
      video: 'https://www.youtube.com/embed/GGCDSdxqak0',
      videoID: 'GGCDSdxqak0',
    },
    'CadastroClientes': {
      video: 'https://www.youtube.com/embed/641-Gyd2ivs',
      videoID: '641-Gyd2ivs',
    },
    'LancamentoDespesas': {
      video: 'https://www.youtube.com/embed/pA38VSo-068',
      videoID: 'pA38VSo-068',
    },
    'MovimentacaoFinanceira': {
      video: 'https://www.youtube.com/embed/8G36-cgjty4',
      videoID: '8G36-cgjty4',
    },
    'CadastroCOntasAPagarContasAReceber': {
      video: 'https://www.youtube.com/embed/8aqzFxfKNw4',
      videoID: '8aqzFxfKNw4',
    },
    'EmissaoNFe': {
      video: 'https://www.youtube.com/embed/PvtwC5z-U-M',
      videoID: 'PvtwC5z-U-M',
    },
    'CadastrarCertificadoDigital': {
      video: 'https://www.youtube.com/embed/mOF94Hki1T0',
      videoID: 'mOF94Hki1T0',
    },
    'CadastroTabelaPreco': {
      video: 'https://www.youtube.com/embed/Ov3dCPEIStY',
      videoID: 'Ov3dCPEIStY',
    }
  };

  ngOnInit() {
    this.form = new FormGroup({
      id: new FormControl(0),
      nomeVideo: new FormControl(this.video),
      exibir: new FormControl(true)
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.open !== undefined) {
      if (changes.open.currentValue) {
        this.init();
        this.videoModalOpen = true;
      } else {
        this.onClosed();
      }
    }
  }

  init() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  destroy() {
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.remove();
  }

  onOpened() {
    this.exibirVideosUsuarioService.getByVideoUser(this.video)
      .subscribe(res => {
        if (res !== null) {
          this.form.patchValue(res);
        }
        this.naoExibirNovamente = !this.form.get('exibir').value;
      });

      this.video = this.videos[this.video].videoID; // video id

    if (window['onYouTubeIframeAPIReady']) {
      this.setVideo();
    } else {
      window['onYouTubeIframeAPIReady'] = () => {
        this.setVideo();
      };
    }
  }

  setVideo() {
    this.YT = window['YT'];
    this.YTreframed = false;
    this.YTplayer = new window['YT'].Player('player', {
      videoId: this.video,
      events: {
        'onReady': () => {
          if (!this.YTreframed) {
            this.YTreframed = true;
            // reframe(e.target.a); comentado pois estava retornando erro, deixado assim para verificar se não ocorrera mais o problema
          }
          this.YTplayer.seekTo(this.time, true);
        }
      }
    });
  }

  salvaNaoAbrirNovamente() {
    if (this.form && this.form.get('exibir').value !== !this.naoExibirNovamente) {
      this.form.get('exibir').setValue(!this.naoExibirNovamente);
      this.exibirVideosUsuarioService.salvar(this.form.value)
        .subscribe(() => { return; });
    }

    let value = 0;
    if (this.YTplayer && this.YTplayer.getCurrentTime() < this.YTplayer.getDuration()) {
      value = this.YTplayer.getCurrentTime();
    }
    this.closed.emit(value);
  }

  onClosed() {
    this.videoModalOpen = false;
    this.salvaNaoAbrirNovamente();
  }

  videoID() {
    return this.videos[this.video]?.videoID;
  }

  videoImage() {
    return this.videos[this.video]?.image;
  }

  videoURL() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.videos[this.video]?.video);
  }

  getOriantation() {
    return getDeviceType() + getOriantation();
  }

  getDevice() {
    return getDeviceType();
  }
}
