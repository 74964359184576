import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class GruposUsuariosService extends BaseService {

  constructor(private http: HttpClient) { super() }

  public adicionar(grupo: any) {

    return this.http.post(this.UrlService + 'GruposUsuarios', JSON.stringify(grupo))
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  public buscar() {
    return this.http.get(this.UrlService + 'GruposUsuarios')
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  public buscarUm(id: number) {
    return this.http.get(this.UrlService + `GruposUsuarios/${id}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  public getGruposCombo() {
    return this.http.get(this.UrlService + 'GruposUsuarios/GetGruposCombo')
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  public atualizar(grupo: any) {
    return this.http.put(this.UrlService + `GruposUsuarios/${grupo.id}`, JSON.stringify(grupo))
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

}
