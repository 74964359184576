import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { AcessoTelasService } from '../../../services/acessoTelas.service';

@Component({
  selector: 'app-acessos-usuario',
  templateUrl: './acessos-usuario.component.html',
  styleUrls: ['./acessos-usuario.component.css']
})
export class AcessosUsuarioComponent extends ModalCadastroComponent implements OnInit {

  constructor(private acessoTelasService: AcessoTelasService) {
    super();
  }

  @ViewChild('modalConsultar', { static: false }) modalConsultar;
  filtro = '';
  acessos = [];
  acessosFilter = [];
  colunas = [
    { label: 'Nome', col: 'id', hide: false },
    { label: 'Acesso', col: 'nome', hide: false },
  ];

  ngOnInit() {
  }

  show(idUsuario) {
    this.id = idUsuario;
    this.acessoTelasService.ObterAcessosDoUsuario(idUsuario)
      .subscribe(res => {
        res.forEach(item => {
          if ((item.nome.toLowerCase() === 'consulta'
            || item.nome.toLowerCase() === 'cadastros'
            || item.nome.toLowerCase() === 'cadastrar'
            || item.nome.toLowerCase() === 'emitir')
            && item.itemDeMenuPai) {
            item.nome = item.nome + ' - ' + item.itemDeMenuPai.nome;
          }
        });

        this.acessos = res;
        this.filterAcessos();
      });
    this.modalConsultar.show();
  }

  filterAcessos() {
    this.acessosFilter = this.acessos.filter(item => {
      return item.nome.toLowerCase().indexOf(this.filtro.toLowerCase()) !== -1 && item.tipoDeItem !== 'subMenu';
    });
  }

  getTipoAcesso(acesso) {
    if (acesso) {
      return 'Liberado';
    }
    return 'Bloqueado';
  }

}
