import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { AliquotasIcmsService } from '../../../services/aliquotasIcms.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { setFieldFormValue, setFormValue } from '../../../general/utils';
import { minimumValue } from '../../../general/validations/custom-validators';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-aliquota-icms',
  templateUrl: './aliquota-icms.component.html',
  styleUrls: ['./aliquota-icms.component.css']
})
export class AliquotaIcmsComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalCadastro", { static: true }) modalCadastro;

  constructor(private aliquotasIcmsService: AliquotasIcmsService) {
    super();
  }

  cadastroForm = new FormGroup({
    id: new FormControl(0),
    aliquotaICMS: new FormControl(null, [Validators.required]),
    percentualRedutorICMS: new FormControl(null, minimumValue(0)),
    observacoes: new FormControl(null),
  })

  ngOnInit() {

  }

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = 0;
    setFieldFormValue(this.cadastroForm, 'aliquotaICMS', searchParam);
    this.modalCadastro.show();
  }

  carregaDados() {
    if (this.id) {
      this.alteraLoading(true);
      this.aliquotasIcmsService.getById(this.id)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe((res) => {
          setFormValue(this.cadastroForm, res);
          this.id = res.id
        })
    }
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      this.alteraLoading(true);
      this.aliquotasIcmsService.salvar(this.cadastroForm.value)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe(res => {
          this.id = res.generatedId || res.id;
          this.modalCadastro.hide();
        }, (error) => {
          this.errors = error.msg.errors;
        })
    }
  }
}
