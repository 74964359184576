import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'show-errors',
    template: `
    <div *ngIf="shouldShowErrors()">
        <div class="messages text-danger error" *ngFor="let error of listOfErrors()">{{error}}</div>
    </div>
 `,
})
export class ShowErrorsComponent {

    private static readonly errorMessages = {
        'required': () => 'Campo requerido',
        'minlength': (params) => 'Valor mínimo de caracteres é ' + params.requiredLength,
        'maxlength': (params) => 'Valor máximo de caracteres é ' + params.requiredLength,
        'pattern': (params) => 'The required pattern is: ' + params.requiredPattern,
        'dataInicial': (params) => params.message,
        'gt': (params) => 'Valor deve ser maior que zero',
        'quantidadeMinima': (params) => params.message,
        'minimunValue': (params) => params.message,
        'number': () => 'Somente números',
        'max': (params) => 'Valor Máximo: ' + params.max,
        'min': (params) => 'Valor Mínimo: ' + params.min,
        'custom': (params) => params.message,
    };

    @Input()
    private control: AbstractControlDirective | AbstractControl;

    @Input()
    private validateTouched: boolean = true;

    shouldShowErrors(): boolean {
        if (this.validateTouched) {
          return this.control &&
            this.control.errors &&
            (this.control.dirty || this.control.touched);
        }

        return this.control &&
            this.control.errors != null;
    }

    listOfErrors(): string[] {
        return Object.keys(this.control.errors)
            .map(field => this.getMessage(field, this.control.errors[field]));
    }

    private getMessage(type: string, params: any) {
        if (ShowErrorsComponent.errorMessages[type]) {
          return ShowErrorsComponent.errorMessages[type](params);
        }
    }
}
