<app-modal-basic #modal (closed)="onClose()" dialogClass="modal-lg" modalContentId="modal-contratar-intermediador-recebimento">
  <div class="app-modal-header">
    <h4 class="modal-title">Boleto Dim</h4>
    <button type="button" class="close basic-close" (click)="modal.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="app-modal-body">
    <ng-container *ngIf="passo === 'INTRODUCAO'">
      <div class="row">
        <div class="col">
          <p>Apresentamos o <strong>Boleto Dim</strong>, a maneira mais fácil e prática de emitir boletos!</p>
          <p><strong>Sem Conexão com seu banco:</strong> Dispensa acesso à sua conta bancária.</p>
          <p><strong>Registro Rápido e Garantido:</strong> Boleto pode ser pago imediatamente após a sua emissão.</p>
          <p>
            <strong>Atualização de controle de cobrança facilitada: </strong>
            <a href="javascript:void(0)" *ngIf="!mostrar[1]" (click)="mostrarInfo(1)">... (ver mais)</a>
            <span [@fadeInOutTranslate] *ngIf="mostrar[1]" >Confirmação de pagamento automática em 24 horas do pagamento e crédito em sua conta bancária em até 2 dias.</span>
          </p>
          <p>
            <strong>Independe de quantidade de boletos: </strong>
            <a href="javascript:void(0)" *ngIf="!mostrar[2]" (click)="mostrarInfo(2)">... (ver mais)</a>
            <span [@fadeInOutTranslate] *ngIf="mostrar[2]">Processo facilitado e viável seja qual for a quantidade de boletos emitidos.</span>
          </p>

          <p [ngClass]="mostrar[3] ? 'mb-1' : ''">
            <strong>Gera o boleto em PDF e envia a seus clientes por e-mail ou WhatsApp automaticamente ao: </strong>
            <a href="javascript:void(0)" *ngIf="!mostrar[3]" (click)="mostrarInfo(3)">... (ver mais)</a>
          </p>
          <ul class="mb-2 lista" [@fadeInOutTranslate] *ngIf="mostrar[3]">
            <li>Emitir-se uma Nota Fiscal Eletrônica - NF-e;</li>
            <li>Emitir-se um Conhecimento de Transporte Eletrônico - CT-e;</li>
            <li>Emitir-se uma Nota Fiscal de Serviços Eletrônica - NFS-e;</li>
            <li>Efetuar-se o fechamento de um pedido na condição de pagamento a prazo;</li>
            <li>Localizar-se um título no contas a receber e solicitar a geração do boleto.</li>
          </ul>

          <p>
            <strong>Dispensa burocracia: </strong>
            <a href="javascript:void(0)" *ngIf="!mostrar[4]" (click)="mostrarInfo(4)">... (ver mais)</a>
            <span [@fadeInOutTranslate] *ngIf="mostrar[4]">Dispensa negociação da empresa com seu banco para contratação do serviço de cobrança.</span>
          </p>
          <p>
            <strong>Dispensa envio ou recebimento de arquivos: </strong>
            <a href="javascript:void(0)" *ngIf="!mostrar[5]" (click)="mostrarInfo(5)">... (ver mais)</a>
            <span [@fadeInOutTranslate] *ngIf="mostrar[5]">Não necessita de arquivos de remessa ou retorno.</span>
          </p>
          <p>
            <strong>Ótima relação Custo-benefício, R$ 2,20 por boleto: </strong>
            <a href="javascript:void(0)" *ngIf="!mostrar[6]" (click)="mostrarInfo(6)">... (ver mais)</a>
            <span [@fadeInOutTranslate] *ngIf="mostrar[6]">Altamente competitivo quando comparado ao custo dos bancos tradicionais e perfeita integração à rotina de faturamento da empresa.</span>
          </p>

          <p class="mb-1"><strong>Considerações importantes:</strong></p>
          <ul class="mb-2 lista">
            <li>Não tem opção de protesto.</li>
            <li style="color: rgb(126, 119, 119);">Sacador/Avalista: SAFE2PAY INSTITUIÇÃO DE PAGAMENTO LTDA - CNPJ 31.037.942/0001-78 Observe possível restrição de seu cliente com relação a haver sacador/avalista no boleto.</li>
          </ul>
  
          <br>
          <p>Quer experimentar? É simples! Basta configurar os dados solicitados e ver como o <strong>Boleto Dim</strong> pode facilitar as suas cobranças.</p>
          <br>

          <p class="mb-1"><strong>Como funciona:</strong></p>
          <ul class="mb-2 lista">
              <li>Configure a Conta Corrente onde deseja receber os valores.</li>
              <li>Crie um Contas a Receber nesta conta corrente.</li>
              <li>Na consulta de Contas a Receber clique na ação Boleto. O boleto será gerado, e em alguns minutos está disponível para pagamento.</li>
              <li>Após a confirmação do pagamento, o contas a receber será atualizado com os valores e datas de pagamento.</li>
              <li>Em 2 dias úteis você recebe o dinheiro na conta.</li>
              <li>Para conferir os valores repassados e efetuar a baixa dos títulos, utilize a ferramenta Financeiro > Baixa Intermediário.</li>
          </ul>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col">
          <button class="btn btn-primary" type="button" (click)="irParaConfiguracao()" style="text-transform: none">
            Ir para a configuração
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="passo === 'CONFIGURACAO'">
      <div [formGroup]="form">
        <div class="row">
          <div class="form-group col-sm-6" rem5 combobox [type]="'contas-corrente'" [(loading)]="loading"
            [(form)]="form" formControlName="contaCorrenteId" [modalCadastro]="true"
            appendTo="#modal-contratar-intermediador-recebimento">
          </div>
        </div>

        <ng-container *ngIf="!possuiIntegracaoSafe2Pay">
          <div class="row mt-3">
            <div class="col">
              <h4 class="sub-title">Responsável Legal</h4>
            </div>
          </div>
          <div class="row mt-2">
            <div class="form-group col-md-6">
              <label class="control-label label-required">Nome</label>
              <input class="form-control" type="text" formControlName="responsavelNome" maxlength="200" />
              <show-errors [control]="form.controls.responsavelNome"></show-errors>
            </div>

            <div class="form-group col-md-6">
              <label class="control-label label-required">CPF</label>
              <input class="form-control" type="text" formControlName="responsavelCpf" mask="000.000.000-00" />
              <show-errors [control]="form.controls.responsavelCpf"></show-errors>
            </div>
          </div>
          <div class="row">
            <div class="form-group col">
              <label class="control-label label-required">E-mail</label>
              <input class="form-control" type="text" formControlName="email" maxlength="100" />
              <show-errors [control]="form.controls.email"></show-errors>
            </div>
          </div>
        </ng-container>

        <div class="row mt-3">
          <div class="col">
            <button class="btn btn-primary" type="button" (click)="salvar()" [disabled]="loading['dados'] || loading['salvar'] || loading['desativar']">
              <app-loading [loading]="loading['salvar']" [icone]="'fa fa-check'" [texto]="possuiIntegracaoSafe2Pay ? 'Salvar' : 'Contratar'"></app-loading>
            </button>
            <button *ngIf="possuiIntegracaoSafe2Pay" class="btn btn-danger" type="button" (click)="desativar()"
              [disabled]="loading['dados'] || loading['salvar'] || loading['desativar']">
              <app-loading [loading]="loading['desativar']" [icone]="'ti ti-close'" texto="Desativar"></app-loading>
            </button>
            <button class="btn btn-info" type="button" (click)="modal.hide()" [disabled]="loading['dados'] || loading['salvar'] || loading['desativar']">
              Fechar
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

</app-modal-basic>