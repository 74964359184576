<div cdkDropList class="drop-down-list" (cdkDropListDropped)="drop($event)">
  <div *ngFor="let item of menuItens; let indexItem = index" cdkDrag class="drop-down-box">
    <div class="drop-down-placeholder" *cdkDragPlaceholder></div>

    <ngb-accordion #acc="ngbAccordion" cdkDropList class="drop-down-list" (cdkDropListDropped)="drop($event)"
      type="drag-and-drop-menu" id="{{getId()}}">
      <ngb-panel id="{{indexItem}}">

        <ng-template ngbPanelTitle>
          <span style="color: #ffffff; cursor: pointer;" *ngIf="item.tipoDeItem == 'subMenu'"
            [ngClass]="(acc.activeIds.length > 0 && acc.activeIds == indexItem) ? 'ti-minus' : 'ti-plus'"
            class="pull-right teste">
          </span>
          <i style="color:#ffffff" class="ti-arrows-vertical"></i>
          &nbsp;{{item.nome}}
        </ng-template>

        <ng-template ngbPanelContent *ngIf="item.tipoDeItem == 'subMenu'">

          <app-editar-menu-item [menuItens]="item.filhos" [itemId]="getNextNumber()">
          </app-editar-menu-item>
        </ng-template>

      </ngb-panel>
    </ngb-accordion>
  </div>