<app-temp-modal-cadastro #modalCadastro modalContentId="modal-editar-atalhos" (closed)="onClose()"
  textHeader="Editar atalhos" customClass="modal-lg">
  <form [formGroup]="cadastroForm" (ngSubmit)="salvarAtalhos()">
    <div class="row">
      <div class="form-group col-sm-8">
        <label for="">Atalho 1</label>
        <ng-select appendTo="#modal-editar-atalhos" [items]="menuItensCombobox" bindLabel="caminho" bindValue="id"
          [searchable]="true" (search)="onSearchCombo($event)" (change)="onSelectCombo($event)" placeholder="Selecione"
          formControlName="atalho1">
        </ng-select>
      </div>
      <div class="form-group col-sm-4">
        <label for="">Ícone</label>
        <ng-select appendTo="#modal-editar-atalhos" [items]="icons" bindLabel="name" bindValue="value"
          formControlName="icone1" placeholder="Selecione um ícone" placeholder="Selecione">
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <i [ngClass]="item.value"></i> {{item.name}}
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-8">
        <label for="">Atalho 2</label>
        <ng-select appendTo="#modal-editar-atalhos" [items]="menuItensCombobox" bindLabel="caminho" bindValue="id"
          [searchable]="true" (search)="onSearchCombo($event)" (change)="onSelectCombo($event)" placeholder="Selecione"
          formControlName="atalho2">
        </ng-select>
      </div>
      <div class="form-group col-sm-4">
        <label for="">Ícone</label>
        <ng-select appendTo="#modal-editar-atalhos" [items]="icons" bindLabel="name" bindValue="value"
          formControlName="icone2" placeholder="Selecione um ícone" placeholder="Selecione">
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <i [ngClass]="item.value"></i> {{item.name}}
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-8">
        <label for="">Atalho 3</label>
        <ng-select appendTo="#modal-editar-atalhos" [items]="menuItensCombobox" bindLabel="caminho" bindValue="id"
          [searchable]="true" (search)="onSearchCombo($event)" (change)="onSelectCombo($event)" placeholder="Selecione"
          formControlName="atalho3">
        </ng-select>
      </div>
      <div class="form-group col-sm-4">
        <label for="">Ícone</label>
        <ng-select appendTo="#modal-editar-atalhos" [items]="icons" bindLabel="name" bindValue="value"
          formControlName="icone3" placeholder="Selecione um ícone" placeholder="Selecione">
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <i [ngClass]="item.value"></i> {{item.name}}
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-8">
        <label for="">Atalho 4</label>
        <ng-select appendTo="#modal-editar-atalhos" [items]="menuItensCombobox" bindLabel="caminho" bindValue="id"
          [searchable]="true" (search)="onSearchCombo($event)" (change)="onSelectCombo($event)" placeholder="Selecione"
          formControlName="atalho4">
        </ng-select>
      </div>
      <div class="form-group col-sm-4">
        <label for="">Ícone</label>
        <ng-select appendTo="#modal-editar-atalhos" [items]="icons" bindLabel="name" bindValue="value"
          formControlName="icone4" placeholder="Selecione um ícone" placeholder="Selecione">
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <i [ngClass]="item.value"></i> {{item.name}}
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-8">
        <label for="">Atalho 5</label>
        <ng-select appendTo="#modal-editar-atalhos" [items]="menuItensCombobox" bindLabel="caminho" bindValue="id"
          [searchable]="true" (search)="onSearchCombo($event)" (change)="onSelectCombo($event)" placeholder="Selecione"
          formControlName="atalho5">
        </ng-select>
      </div>
      <div class="form-group col-sm-4">
        <label for="">Ícone</label>
        <ng-select appendTo="#modal-editar-atalhos" [items]="icons" bindLabel="name" bindValue="value"
          formControlName="icone5" placeholder="Selecione um ícone" placeholder="Selecione">
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <i [ngClass]="item.value"></i> {{item.name}}
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="">
      <button class="btn btn-primary">
        <i class="fa fa-check"></i> Confirmar
      </button>
      <button type="button" class="btn btn-danger me-2" (click)="modal.hide()">
        <i class="fa fa-times"></i> Cancelar
      </button>
    </div>
  </form>
</app-temp-modal-cadastro>

<app-abrir-modal #abrirModal></app-abrir-modal>