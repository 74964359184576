<app-temp-modal-cadastro #modalConsultar [textHeader]="'Usuários Empresa'" [customClass]="'modal-lg'" (closed)="onClose()">
  <div class="app-modal-body" *ngIf="retornoUsuarios && retornoUsuarios.items">
    <app-dim-datatable [data]="retornoUsuarios.items" [columns]="colunasUsuarios" (sorting)="onSortUsuarios($event)"
      [rowsOnPage]="10" [totalItems]="retornoUsuarios.totalItems" (pageChanged)="setPageUsuarios($event)"
      (clickButton)="editarUsuario($event)" [showInfoMobile]="false" [lyneStyle]="verificaUserAdmin()">
    </app-dim-datatable>
  </div>

  <div class="app-modal-footer">
  </div>
</app-temp-modal-cadastro>
<app-abrir-modal #abrirModal (closed)="doConsultaUsuariosEmpresa()">
</app-abrir-modal>