
export * from './tipo-comissao/tipo-comissao-v2.service';
export * from './base/base-service-v2';
export * from './titulos-cobranca/titulos-cobranca-v2.service';
export * from './clientes/clientes-v2.service';
export * from './pedidos/pedidos-v2.service';
export * from './representantes/representantes-v2.service';
export * from './portadores/portadores-v2.service';
export * from './contas-correntes/contas-correntes-v2.service';
export * from './grupo-despesas/grupo-despesas-v2.service';
export * from './centros-custos/centros-custos-v2.service';
export * from './unidades-empresa/unidades-empresa-v2.service';
export * from './unidades-medida/unidades-medida-v2.service';
export * from './itens/itens-service-v2.service';
export * from './notas-fiscais/notas-fiscais-service-v2.service';
export * from './estoques-movimentacao/estoques-movimentacao-v2.service';
