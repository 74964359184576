<app-modal-basic #modalConsulta [dialogClass]="'modal-lg'" (closed)="onClose()">
  <div class="app-modal-header">
    <h4 class="modal-title text-center">
      <ng-container *ngIf="!loading['detalhes'] && nfe">
        NF-e
        Série {{nfe.serie}}
        N° {{nfe.numeroNotaFiscal}}
        <br>
        <p *ngIf="nfe.emissao" class="m-0">
          Emitida em {{nfe.emissao | date:'dd/MM/yyyy'}} |
          Saída em {{nfe.saida | date:'dd/MM/yyyy'}}
        </p>
      </ng-container>
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="loading['detalhes']"></i>
    </h4>
    <button type="button" class="close basic-close" (click)="modalConsulta.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <app-dim-tabswipe #appTab [inputs]="tabs" [class]="'remove-borders'">
      <mat-tab-group animationDuration="400ms" [selectedIndex]="appTab.selected.value"
        (selectedIndexChange)="appTab.setTab($event)">
        <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab[0]">
          <div *ngIf="nfe && !loading['detalhes']">
            <ng-container *ngIf="tab[0] === 'Notas Filhas'">
              <p *ngIf="tab[1].length === 0">Nenhuma nota filha</p>

              <div class="table-responsive">
                <table *ngIf="tab[1].length > 0" class="table table-striped">
                  <thead>
                    <tr>
                      <th>Série</th>
                      <th>Número</th>
                      <th>Emissão</th>
                      <th>Status</th>
                      <th>Valor Total</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let notaFilha of tab[1]">
                      <td>{{ notaFilha.serie }}</td>
                      <td>{{ notaFilha.numeroNotaFiscal }}</td>
                      <td>{{ notaFilha.emissao | date:'dd-MM-yyyy HH:mm' }}</td>
                      <td>{{ notaFilha.statusDescricao }}</td>
                      <td>{{ notaFilha.valorTotal | currency:'BRL':'symbol' }}</td>
                      <td class="text-center">
                        <button (click)="imprimirNfe(notaFilha.id, notaFilha.numeroNotaFiscal)"
                          class="btn btn-info btn-mini waves-effect waves-light copyCursor" placement="left" ngbTooltip="Imprimir Danfe"
                          [disabled]="loading['impressao' + notaFilha.id]">
                          <app-loading [loading]="loading['impressao' + notaFilha.id]" texto="" textoLoading="" icone="fa fa-print">
                          </app-loading>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-container>

            <ng-container *ngIf="tab[0] === 'Itens'">
              <div class="info-container">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="table-responsive">
                      <table *ngIf="nfe.itens" class="table table-striped mt-2">
                        <thead>
                          <th scope="col">Código</th>
                          <th scope="col" class="text-produto">Produto</th>
                          <th scope="col">Unidade</th>
                          <th scope="col">Quantidade</th>
                          <th scope="col">Preço</th>
                          <th scope="col">Total</th>
                        </thead>
                        <tbody>
                          <tr *ngFor="let item of nfe.itens">
                            <td>{{item.item.referenciaItem}}</td>
                            <td class="text-produto" [ngbTooltip]="item.item.nome" container="body">{{item.item.nome}}</td>
                            <td>{{item.item.unidadeMedida ? item.item.unidadeMedida.descricao : 'N/A'}}</td>
                            <td>{{item.quantidade}}</td>
                            <td>{{item.preco | currency:'BRL':'symbol':'1.2-2'}}</td>
                            <td>{{item.preco * item.quantidade | currency:'BRL':'symbol':'1.2-2'}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngIf="tab[0] !== 'Notas Filhas' && tab[0] !== 'Itens'">
              <div class="info-container" *ngIf="tab[1]" style="line-height: 1.8rem">
                <ng-container *ngFor="let linha of tab[1]">
                  <span *ngIf="isArray(linha)">
                    <div class="line">
                      <span *ngFor="let campo of linha">
                        <ng-container>{{campo.label}}: <b>{{campo.value || 'N/A'}}</b></ng-container>
                      </span>
                    </div>
                  </span>
                  <span *ngIf="!isArray(linha)">
                    <ng-container>{{linha.label}}: <b>{{linha.value || 'N/A'}}</b></ng-container>
                  </span>
                </ng-container>
                <ng-container *ngIf="index === 1 && nfe.titulosCobranca && nfe.titulosCobranca.length > 0">
                  <span>Faturas:</span>
                  <app-dim-datatable [data]="nfe.titulosCobranca" [columns]="colunasTitulos"
                    [showPagination]="false"></app-dim-datatable>
                </ng-container>
                <ng-container *ngIf="index === 2 && nfe.quantidadeVolumes">
                  <div>
                    <button class="btn btn-primary mt-3 mr-0 pull-right" (click)="imprimirEtiquetas()"
                      [disabled]="loading['etiquetas']">
                      <app-loading icone="fa fa-print" [loading]="loading['etiquetas']" texto="Imprimir etiquetas"
                        textoLoading="Imprimindo"></app-loading>
                    </button>
                  </div>
                </ng-container>
                <ng-container *ngIf="index === 0">
                  <div *ngIf="!loading['detalhes']" class="d-flex mt-2 justify-content-end">
                    <button *ngIf="nfe.numeroDI" (click)="downloadXMLDI()" [disabled]="loading['downloadDI']"
                      class="btn btn-info float-left waves-effect waves-light">
                      <app-loading [loading]="loading['downloadDI']" [icone]="'fa fa-download'"
                        [textoLoading]="'Aguarde'" [texto]="'Declaração de Importação'">
                      </app-loading>
                    </button>
                    <button (click)="consultarNfe()" [disabled]="loading['detalhes']"
                      class="btn btn-info float-left waves-effect waves-light mr-0">
                      <app-loading [loading]="loading['detalhes']" [icone]="'fa fa-copyright'"
                        [textoLoading]="'Aguarde'">
                      </app-loading>
                    </button>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
          <h4 *ngIf="loading['detalhes']" class="text-center"><i class="fa fa-circle-o-notch fa-spin"
              *ngIf="loading['detalhes']"></i></h4>
        </mat-tab>
      </mat-tab-group>
    </app-dim-tabswipe>
  </div>
</app-modal-basic>
