import { Component, OnInit, ViewChild, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { markFormGroupTouched } from '../../../../general/utils';
import { NotasFiscaisService } from '../../../../services/notas-fiscais.service';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-modal-relatorio-retencoes',
  templateUrl: './modal-relatorio-retencoes.component.html',
  styleUrls: ['./modal-relatorio-retencoes.component.css']
})
export class ModalRelatorioRetencoesComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalRelatorio", { static: true }) modalRelatorio;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  impressaoForm: FormGroup;

  constructor(private notasFiscaisService: NotasFiscaisService) { super() }

  ngOnInit() {
    this.impressaoForm = new FormGroup({
      emissaoDe: new FormControl(null, [Validators.required]),
      emissaoAte: new FormControl(null, [Validators.required]),
    })
  }

  show(): void {
    this.ngOnInit();
    this.modalRelatorio.show();
  }

  onClose(){
    this.closed.emit();
  }

  validaForm() {
    markFormGroupTouched(this.impressaoForm);
  }

  submitForm() {
    if (this.impressaoForm.valid) {
      this.modalRelatorio.hide();
      this.closed.emit();
      this.notasFiscaisService.imprimirRetencaoDeImpostos(this.impressaoForm.value)
        .subscribe(res => {
          saveAs(res, 'relatorio_retencoes_impostos.pdf');
        }, (error) => {
          Swal("Erro ao emitir o relatório: Retenção de Impostos", error.errors[0], "error");
        })
    }
  }
}
