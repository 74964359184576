import { Component, OnInit, ViewChild } from '@angular/core';
import { ItensService } from '../../../services/itens.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { MessageService } from '../../../services/message.service';
import _ from 'lodash';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-produtos-clone',
  templateUrl: './produtos-clone.component.html',
  styleUrls: ['./produtos-clone.component.css']
})
export class ProdutosCloneComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  produto;
  estadoInicial;
  vm;
  errors = [];

  constructor(
    private itensServiece: ItensService,
    private messageService: MessageService,
    private router: Router) {
    super();
  }

  ngOnInit() {
    this.produto = this.prop.produto;
    this.estadoInicial = {
      id: this.produto.id,
      nome: this.produto.nome,
      itensFornecedores: this.produto.itensFornecedores.map(x => {
        return {
          id: x.id,
          referenciaItem: x.referenciaItem,
          nome: x.nome,
          fornecedorNome: x.fornecedor.nome,
          fornecedorId: x.fornecedor.id,
        }
      }),
    };

    this.carregarVm();
    this.alteraLoading(false);
  }

  carregarVm() {
    this.errors = [];
    this.vm = _.cloneDeep(this.estadoInicial);
  }

  show() {
    this.modalCadastro.show();
  }

  destroyComponent() {
    this.closed.emit();
  }

  validar() {
    let errors = [];
    let emBranco = false;
    let muitosCaracteres = false;
    for (let itemFornecedor of this.vm.itensFornecedores) {
      if (!itemFornecedor.referenciaItem || itemFornecedor.referenciaItem === "")
        emBranco = true;
      else if (itemFornecedor.referenciaItem.length > 60)
        muitosCaracteres = true;
    }

    if (emBranco)
      errors.push("O código é obrigatório.");
    if (muitosCaracteres)
      errors.push("O código deve possuir no máximo 60 caracteres.");

    return errors;
  }

  clonar() {
    this.errors = this.validar();

    if (this.errors.length === 0) {
      this.alteraLoading(true);

      this.itensServiece.clonarProduto(this.vm)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe(res => {
          this.showSuccess(res.id, res.novoCodigo);
          this.closed.emit(res.id);
        }, error => {
          this.errors = error.msg.errors;
        });
    }
  }

  showSuccess(novoId, novoCodigo) {
    let elementAbrirCadastro: HTMLElement;
    let abrirCadastroHandler = () => {
      this.messageService.clearAll();
      this.router.navigate(['/suprimentos/cadastros/produtos/editar', novoId]);
    };

    this.messageService.addSuccess({
      title: `Produto ${novoCodigo} criado.`,
      msg: `<label id="abrirCadastro-${novoId}" class="label label-documento-aprovado">Abrir cadastro</label>`,
      showClose: true, timeout: 0, theme: 'default', closeOther: true, type: 'success',
      onRemove: () => {
        if (elementAbrirCadastro) {
          elementAbrirCadastro.removeEventListener('click', abrirCadastroHandler, true);
        }
      }
    });

    setTimeout(() => {
      elementAbrirCadastro = document.getElementById(`abrirCadastro-${novoId}`);
      elementAbrirCadastro.addEventListener('click', abrirCadastroHandler, true);
    });
  }

  removerItemFornecedor(id) {
    this.vm.itensFornecedores = this.vm.itensFornecedores.filter(x => x.id !== id);
  }

  get dadosAlterados() {
    return !_.isEqual(this.vm, this.estadoInicial);
  }

}
