import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { TempModalCadastroComponent } from '../../../shared/templates/temp-modal-cadastro/temp-modal-cadastro.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClassificacoesFiscaisService } from '../../../services/classificacoes-fiscais.service';
import { setFormValue, setFieldFormValue } from '../../../general/utils';
import * as $ from 'jquery';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-cadastro-classificacao-fiscal',
  templateUrl: './cadastro-classificacao-fiscal.component.html',
  styleUrls: ['./cadastro-classificacao-fiscal.component.css']
})
export class CadastroClassificacaoFiscalComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalCadastro', { static: true }) modalCadastro: TempModalCadastroComponent;

  constructor(private classificacoesFiscaisService: ClassificacoesFiscaisService) {
    super();
  }

  cadastroForm = new FormGroup({
    id: new FormControl(null),
    classificacaoFiscal: new FormControl(null, [Validators.required, Validators.maxLength(8)]),
    descricao: new FormControl(null, [Validators.required, Validators.maxLength(30)])
  });

  ngOnInit() {
  }

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
    setTimeout(() => {
      $('[formcontrolname="classificacaoFiscal"]').focus();
    });
  }

  cadastrar(searchParam) {
    this.id = null;
    setFieldFormValue(this.cadastroForm, 'classificacaoFiscal', (searchParam || '').replace(/\D/g, ''));
    this.modalCadastro.show();
  }

  carregaDados() {
    if (this.id) {
      this.alteraLoading(true);
      this.classificacoesFiscaisService.getById(this.id)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe(res => {
          setFormValue(this.cadastroForm, res);
        });
    }
  }

  show(ncm:number) {
    this.modalCadastro.show();
    this.cadastroForm.controls['classificacaoFiscal'].setValue(ncm);
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      this.alteraLoading(true);

      this.classificacoesFiscaisService.postCadastroResumidotNcm(this.cadastroForm.getRawValue())
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe(res => {
          this.id = res.generatedId || null;
          this.modalCadastro.hide();
        }, (error) => {
          this.errors = error.msg.errors;
        });
    }
  }

}
