import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProcessoImportacaoService } from '../../../services/processo-importacao.service';
import { AbrirModalComponent } from '../../../shared/abrir-modal/abrir-modal.component';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { firstValueFrom } from 'rxjs';
import { MessageService } from '../../../services/message.service';
import { getErrorMessage, setFormValue } from '../../..//general/utils';

@Component({
  selector: 'app-modal-processo-importacao',
  templateUrl: './modal-processo-importacao.component.html',
  styleUrls: ['./modal-processo-importacao.component.css']
})
export class ModalProcessoImportacaoComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('abrirModal', { static: true }) abrirModal: AbrirModalComponent;
  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  id;
  cadastroForm = new FormGroup({
    id: new FormControl(0),
    clienteContratanteID: new FormControl(null, [Validators.required]),
    processoDeImportacao: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
    observacoes: new FormControl(null, [Validators.maxLength(1000)]),
  });

  constructor(
    private processoImportacaoService: ProcessoImportacaoService,
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit() {
  }

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    this.cadastroForm.get('processoDeImportacao').setValue(searchParam);
    this.modalCadastro.show();
  }

  async carregaDados() {
    if (!this.id) {
      return;
    }

    try {
      this.alteraLoading(true);
      const res = await firstValueFrom(this.processoImportacaoService.obterPorId(this.id));
      setFormValue(this.cadastroForm, res);
    } catch (err) {
      this.messageService.error('Falha ao buscar dados do Processo de Importação: ' + getErrorMessage(err), 8000);
    } finally {
      this.alteraLoading(false);
    }
  }

  async submitForm() {
    if (!this.cadastroForm.valid) {
      return;
    }

    try {
      this.alteraLoading(true);

      if (this.id) {
        await firstValueFrom(this.processoImportacaoService.editar(this.cadastroForm.getRawValue()));
        this.modalCadastro.hide();
      } else {
        const res = await firstValueFrom(this.processoImportacaoService.cadastrar(this.cadastroForm.getRawValue()));
        this.id = res?.id;
        this.modalCadastro.hide();
      }
    } catch (err) {
      this.messageService.error('Falha ao salvar: ' + getErrorMessage(err), 8000);
    } finally {
      this.alteraLoading(false);
    }
  }
}
