import { Component, OnInit, Input, forwardRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControlDirective, FormControl } from '@angular/forms';

@Component({
  selector: 'app-time',
  templateUrl: './app-time.component.html',
  styleUrls: ['./app-time.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AppTimeComponent),
      multi: true
    }
  ]
})
export class AppTimeComponent implements OnInit, ControlValueAccessor {

  constructor() { }
  
  @Input('parseToInt') parseToInt = false;
  @Input() disabled: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  time;

  writeValue(value): void {
    if (value) {
      const horas = Math.floor(value / 60);
      const minutos = ((value / 60) % 1) * 60;
      this.time = `${horas.toFixed(0)}`.padStart(2, '0') + ':' + `${minutos.toFixed(0)}`.padStart(2, '0');
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  private propagateChange = (_: any) => { };

  onTouched = () => { };

  onChange = (event) => {
    if(this.parseToInt) {
      this.propagateChange(this.stringToIntTime(event));
    } else {
      this.propagateChange(event);
    }
    this.change.emit(event);
  }

  ngOnInit() {
  }

  stringToIntTime(str) {
    const splitString = str.split(':');
    const horas = parseInt(splitString[0]) * 60;
    const minutos = parseInt(splitString[1]);
    return (horas + minutos);
  }

}
