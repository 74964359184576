import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseServiceV2 } from '@servicesv2';
import { EmissaoNFeViewModel } from 'models/notas-fiscais/EmissaoNFeViewModel';

@Injectable()
export class NotasFiscaisServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('NotasFiscais', http);
  }

  gerarDevolucaoVendaNFe(id: number) {
    return this.getAsPromise<EmissaoNFeViewModel>(`devolverVendaNFe/${id}`);
  }

}
