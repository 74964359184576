import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { cardToggle, cardClose } from './card-animation';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
  animations: [cardToggle, cardClose],
  encapsulation: ViewEncapsulation.None
})
export class CardComponent implements OnInit {
  @Input() headerContent: string;
  @Input() title: string;
  @Input() blockClass: string;
  @Input() cardClass: string;
  @Input() classHeader = false;
  @Input() showMinimize = true;
  @Input() showHelp = false;
  cardToggle = 'expanded';
  cardClose = 'open';
  @Input() showClose = false;
  @Input() showLoading = false;
  @Input() startCollapsed = false;
  @Input() tooltipText?: string = null;
  @Input() id = '';

  @Output() helpClick = new EventEmitter();
  @Output() loadingClick = new EventEmitter();
  @Input() popLoading: string = '';
  @Input() btnLimparFiltros = false;
  @Output() btnLimparFiltrosFn = new EventEmitter();
  @Input() subtitle: { text: string, tooltip?: string, icon?: string };

  constructor() { }

  ngOnInit() {
    if (this.startCollapsed) {
      this.toggleCard();
    }
  }

  toggleCard() {
    this.cardToggle = this.cardToggle === 'collapsed' ? 'expanded' : 'collapsed';
  }

  closeCard() {
    this.cardClose = this.cardClose === 'closed' ? 'open' : 'closed';
  }

  clickHelp() {
    this.helpClick.emit();
  }

  clickLoading() {
    this.loadingClick.emit();
  }

  clickBtn() {
    this.btnLimparFiltrosFn.emit();
  }
}
