import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class TipoComissaoService extends BaseService {

  constructor(private http: HttpClient) { super() }

  get() {
    return this.http.get(this.UrlService + 'TipoComissao')
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  salvar(form: any) {
    if (form.id == null || form.id == 0) {
      return this.postForm(form);
    } else {
      return this.putForm(form);
    }
  }

  private postForm(form: any) {
    return this.http
      .post(this.UrlService + 'TipoComissao/', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  private putForm(form: any) {
    return this.http
      .put(this.UrlService + 'TipoComissao/', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  getTipoComissaoById(id: number) {
    return this.http
      .get(this.UrlService + "tipoComissao/GetTipoComissaoById/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + "tipoComissao/GetPaginado?pageIndex=" + pagina + "&search=" + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getTipoComissaoByCliente(clienteId: number) {
    return this.http
      .get(this.UrlService + "tipoComissao/GetTipoComissaoByClienteId/" + clienteId)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
  /////////////////////////////////// Itens Tipo Comissao ///////////////////////////////////

  getItensTipoComissao(idProduto) {
    return this.http.get(this.UrlService + `ItensTipoComissao/${idProduto}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  setItensTipoComissao(idProduto: number, idTipoComissao: number) {
    return this.http.post(this.UrlService + 'ItensTipoComissao', {
      ItemId: idProduto,
      TipoComissaoId: idTipoComissao
    })
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  updateItensTipoComissao(obj) {
    return this.http.put(this.UrlService + 'ItensTipoComissao', obj)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  deleteItensTipoComissao(id) {
    return this.http.delete(this.UrlService + 'ItensTipoComissao/' + id)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  /////////////////////////////////// Itens Tipo Comissao Represemtante ///////////////////////////////////

  getItensTipoComissaoRepresentante(idProduto) {
    return this.http.get(this.UrlService + 'ItensTipoComissaoRepresentante/' + idProduto)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }


  setItensTipoComissaoRepresentante(item) {
    return this.http.post(this.UrlService + 'ItensTipoComissaoRepresentante', item)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  updateItensTipoComissaoRepresentante(item) {
    return this.http.put(this.UrlService + 'ItensTipoComissaoRepresentante', item)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getItensTipoComissaoRepresentanteFaixas(idItem) {
    return this.http.get(this.UrlService + 'ItensTipoComissaoRepresentanteFaixas/' + idItem)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }
}
