import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import swal from 'sweetalert2';
import { TitulosCobrancaService } from '../../../../services/titulos-cobranca.service';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { RepresentantesService } from '../../../../services/representantes.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-comissoes',
  templateUrl: './comissoes.component.html',
  styleUrls: ['./comissoes.component.css']
})
export class ComissoesComponent extends ModalCadastroComponent implements OnInit {


  @ViewChild('modalRelatorio', { static: true }) modalRelatorio;
  impressaoForm: FormGroup;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  loading: boolean = false;
  modalErrors: string = null;
  checkBoxControl: FormControl = new FormControl(false);

  constructor(private titulosCobrancaService: TitulosCobrancaService, private representantesService: RepresentantesService) { super() }

  ngOnInit() {
    this.impressaoForm = new FormGroup({
      preposto: new FormControl(null),
      periodo1: new FormControl(null),
      periodo2: new FormControl(null),
      vendedor: new FormControl(null)
    });
  }

  checarPrepostoRepresentante(){
    const vendedorId = this.impressaoForm.value.vendedor;
    this.representantesService.getRepresentanteById(vendedorId)
    .subscribe(res =>{
      if(res.preposto){
        this.impressaoForm.get('preposto').setValue(res.preposto)
        this.checkBoxControl.setValue(true);
      }else
      this.checkBoxControl.setValue(false);
    });
  }

  destroyComponent() {
    this.closed.emit();
  }

  show(): void {
    this.modalRelatorio.show();
  }

  submitForm() {
    if (this.impressaoForm.valid) {
      if (!this.impressaoForm.get('periodo1').value) {
        this.modalErrors = 'Informe o período inicial.';
        return;
      }

      if (!this.impressaoForm.get('periodo2').value) {
        this.modalErrors = 'Informe o período final.';
        return;
      }

      if (this.impressaoForm.get('periodo1').value > this.impressaoForm.get('periodo2').value) {
        this.modalErrors = 'O período inicial não pode ser superior ao período final.';
        return;
      }
      this.loading = true;
      this.modalRelatorio.hide();
      this.titulosCobrancaService.imprimirRelatorioComissoes(this.impressaoForm.value)
        .pipe(finalize(() => this.loading = false))
        .subscribe(res => {
          saveAs(res, 'relatorio_comissoes.pdf');
          this.destroyComponent();
        }, (error) => {
          swal('Erro ao emitir o relatório: Relação de vendas por produto', error.errors[0], 'error');
          this.destroyComponent();
        });
    }
  }

}
