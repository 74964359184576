<app-modal-basic #modalRelatorio (closed)="destroyComponent()" [modalContentId]="'modal-rv'">
  <div class="app-modal-header">
    <h4 class="modal-title">Vendas por produto - Pedido</h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="impressaoForm" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Período</label>
          <div class="input-group">
            <app-data [calendarPosition]="'left'" formControlName="periodo1">
            </app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="periodo2"></app-data>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12" rem5 combobox [type]="'vendedor'"
          [(form)]="impressaoForm" appendTo="#modal-rv" formControlName="vendedor">
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Status</label>
          <app-combobox-status-documento appendTo="#modal-rv" formControlName="status"
            [statusOptions]="statusPedido">
          </app-combobox-status-documento>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Ordenar por</label>
          <ng-select appendTo="#modal-rv" [items]="ordenacao" bindLabel="name" bindValue="id"
            formControlName="ordenacao">
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 form-group" style="margin-bottom: unset;">
          <label style="font-weight: bold;">Opções de visualização:</label>
        </div>
      </div>
      <div class="row">
        <div class="form-radio col-sm-6">
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="planilha" [value]="false">
              <i class="helper"></i>Formato .PDF
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="planilha" [value]="true">
              <i class="helper"></i>Formato .XLS
            </label>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary ripple light">
        <i class="icofont icofont-print"> </i> Imprimir
      </button>
      <button type="button" class="btn btn-danger ripple" (click)="modalRelatorio.hide()"><i class="ti ti-close"></i>
        Cancelar
      </button>
    </form>
  </div>
  <div class="app-modal-footer">
  </div>
</app-modal-basic>