<app-modal-basic #modal [hideHeader]="true" [hideFooter]="true" (closed)="onClose()">
  <div class="app-modal-body">
    <div class="modal-privacy-body">
      <h1>Política de privacidade</h1>
      <p>Esta aplicação é mantida e operada por Dimensão Tecnologia da Informação EIRELI.</p>
      <p>
        Nós coletamos e utilizamos alguns dados da pessoa física e jurídica daqueles que utilizam
        nossa aplicação. Ao fazê-lo, agimos na qualidade de controlador desses dados e estamos
        sujeitos às disposições da Lei Federal nº 13.709/2018 (Lei Geral de Proteção de Dados
        Pessoais – LGPD).
      </p>
      <p>
        Nós cuidamos da proteção de seus dados pessoais e, por isso, disponibilizamos esta política
        de privacidade, que contém informações importantes sobre:
      </p>
      <ul>
        <li>Quem deve utilizar nossa aplicação;</li>
        <li>Quais dados coletamos e o que fazemos com eles;</li>
        <li>Seus direitos em relação aos seus dados pessoais; e</li>
        <li>Como entrar em contato conosco.</li>
      </ul>
      <ol style="padding: 0;">
        <li>Dados que coletamos e motivos da coleta</li>
        <p>
          Nossa aplicação coleta e utiliza alguns dados da pessoa física e jurídica de nossos
          usuários, através de nosso formulário de cadastro a ser acessado pelo site
          <a href="www.dimensaosistemas.com">www.dimensaosistemas.com</a>. No caso do certificado digital da pessoa jurídica, para
          operações junto ao FISCO, deve ser carregado em app.dimensaosistemas.com.
        </p>
      </ol>      
      <ol style="padding: 0;">
        <li>
          Dados da pessoa física e jurídica fornecidos expressamente pelo próprio usuário ou por seu
          representante
        </li>
        <p>
          Nós coletamos os seguintes dados da pessoa física e jurídica que nossos usuários nos
          fornecem expressamente ao utilizar nossa aplicação:
        </p>
        <ul>
          <li>CNPJ;</li>
          <li>Razão Social;</li>
          <li>Nome Fantasia;</li>
          <li>Inscrição Estadual;</li>
          <li>Inscrição Municipal;</li>
          <li>Regime Tributário da Empresa;</li>
          <li>CEP;</li>
          <li>Logradouro e Número;</li>
          <li>Bairro;</li>
          <li>Cidade;</li>
          <li>Estado;</li>
          <li>Nome do usuário administrador;</li>
          <li>Telefone da empresa;</li>
          <li>Telefone do Responsável;</li>
          <li>Email do usuário administrador;</li>
          <li>Qual o seu escritório contábil;</li>
          <li>Certificado digital de pessoa jurídica.</li>
        </ul>
        <p>A coleta destes dados ocorre nos seguintes momentos:</p>
        <ul>
          <li>Quando o usuário se cadastra no sistema;</li>
          <li>Na alteração dos dados do usuário;</li>
          <li>Em uma possível alteração dos dados da empresa.</li>
        </ul>
        <p>Os dados fornecidos por nossos usuários são coletados com as seguintes finalidades:</p>
        <ul>
          <li>Para identificação de nosso setor administrativo;</li>
          <li>
            Para emissão de nossa fatura e dos documentos e obrigações fiscais geradas pelo
            estabelecimento do próprio usuário;
          </li>
          <li>Para relatórios estatísticos e estratégias de nossas vendas;</li>
          <li>Nossas campanhas de marketing;</li>
          <li>Para eventual contato com a pessoa.</li>
        </ul>
        <li>Compartilhamento de dados da pessoa física e jurídica com terceiros</li>
        <p>
          Nós não compartilhamos os dados coletados com terceiros. Os dados são utilizados
          exclusivamente para os fins mencionados.
        </p>
        <li>Por quanto tempo seus dados da pessoa física e jurídica serão armazenados</li>
        <p>
          Os dados coletados pela aplicação são armazenados e utilizados por período de tempo
          indeterminado. Os dados anteriormente mencionados poderão ser sobrescritos por outro
          cadastro ou por caracteres indecifráveis, através de uma solicitação por escrito de seu
          titular.
        </p>
        <li>Direitos do Usuário</li>
        <p>
          O usuário da aplicação possui os seguintes direitos, conferidos pela Lei de Proteção de
          Dados Pessoais:
        </p>
        <ul>
          <li>Confirmação da existência de tratamento;</li>
          <li>Acesso aos dados</li>
          <li>Correção de dados incompletos, inexatos ou desatualizados;</li>
          <li>
            Anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em
            desconformidade com o disposto na lei;
          </li>
          <li>
            Portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição
            expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos
            comercial e industrial;
          </li>
          <li>
            Eliminação dos dados pessoais tratados com o consentimento do titular, exceto nos casos
            previstos em lei;
          </li>
          <li>
            Informação das entidades públicas e privadas com as quais o controlador realizou uso
            compartilhado de dados;
          </li>
          <li>
            Informação sobre possibilidade de não fornecer consentimento e sobre as consequências da
            negativa;
          </li>
          <li>Revogação do consentimento.</li>
        </ul>
        <p>
          É importante destacar que, nos termos da LGPD, não existe um direito de eliminação de
          dados tratados com fundamento em bases legais distintas do consentimento, a menos que os
          dados sejam desnecessários, excessivos ou tratados em desconformidade com o previsto em
          lei.
        </p>
        <li>Medidas de segurança no tratamento de dados pessoais</li>
        <p>
          Empregamos medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos
          não autorizados e de situações de destruição, perda, extravio ou alteração desses dados.
        </p>
        <p>
          As medidas que utilizamos levam em consideração a natureza dos dados, o contexto e a
          finalidade do tratamento, os riscos que uma eventual violação geraria para os direitos e
          liberdades do usuário, e os padrões atualmente empregados no mercado por empresas
          semelhantes à nossa.
        </p>
        <p>
          Nos eximimos de responsabilidade no caso de situações excepcionais como ataques de hackers
          ou crackers ou, ainda, em caso de culpa exclusiva do usuário, que ocorre, por exemplo,
          quando ele mesmo transfere ou dá acesso ao sistema para terceiros. Portando situações como
          estas fogem ao nosso controle.
        </p>
        <p>
          De qualquer forma, caso ocorra qualquer tipo de incidente de segurança que possa gerar
          risco ou dano relevante para qualquer de nossos usuários, comunicaremos os afetados e a
          Autoridade Nacional de Proteção de Dados acerca do ocorrido, em conformidade o disposto na
          Lei Geral de Proteção de Dados.
        </p>
        <li>Alterações nesta política</li>
        <p>
          Nos reservamos o direito de modificar, a qualquer momento, as presentes normas,
          especialmente para adaptá-las às eventuais alterações feitas em nossa aplicação, seja pela
          disponibilização de novas funcionalidades, seja pela supressão ou modificação daquelas já
          existentes.
        </p>
        <p>
          Sempre que houver uma modificação, nossos usuários serão notificados acerca da mudança.
        </p>
        <li>Como entrar em contato conosco</li>
        <p>
          Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade ou sobre os dados
          pessoais que tratamos, entre em contato com nossa equipe pelos canais:
        </p>
        <p>
          Email: <a href="mailto:suporte@dimensaosistemas.com">suporte@dimensaosistemas.com</a>
          <br />Fone: (47)3842-0063
        </p>
      </ol>
    </div>
  </div>
</app-modal-basic>
