import { Component, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnInit, forwardRef, AfterViewInit } from '@angular/core';
import { isNil } from 'lodash';
import { TreeviewI18n, TreeviewItem, TreeviewConfig, DropdownTreeviewComponent, TreeviewHelper } from './public-api';
import { DropdownTreeviewSelectI18n } from './dropdown-treeview-select-i18n';
import { FormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-dropdown-treeview-select',
  templateUrl: './combobox-treeview.component.html',
  styleUrls: ['./combobox-treeview.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxTreeviewComponent),
      multi: true
    }
  ]
})
export class ComboboxTreeviewComponent implements OnChanges, ControlValueAccessor {
  @Input() config: TreeviewConfig;
  @Input() items: TreeviewItem[] = [];
  @Input() disabled: boolean = false;
  @Input() loading: boolean = false;
  @Output() change = new EventEmitter();
  @ViewChild(DropdownTreeviewComponent, { static: true }) dropdownTreeviewComponent: DropdownTreeviewComponent;
  filterText: string;
  private dropdownTreeviewSelectI18n: DropdownTreeviewSelectI18n;
  value = null;

  constructor(
    public i18n: TreeviewI18n
  ) {
    this.config = TreeviewConfig.create({
      hasAllCheckBox: false,
      hasCollapseExpand: false,
      hasFilter: true,
      maxHeight: 100000
    });
    this.dropdownTreeviewSelectI18n = i18n as DropdownTreeviewSelectI18n;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateSelectedItem();
  }

  onChange = (event) => {
    let value = null;
    if (event && event.value) value = event.value;
    this.propagateChange(value);
    this.change.emit(event)
  };

  writeValue(newValue): void {
    if (newValue) {
      this.carregaItem(newValue)
    } else {
      this.value = newValue;
      this.dropdownTreeviewSelectI18n.selectedItem = newValue
    }
  }

  carregaItem(value) {
    if (this.items && this.items.length > 0 && !this.loading) {
      const selectedItem = TreeviewHelper.findItemInList(this.items, value);
      if (selectedItem) {
        this.value = value
        this.dropdownTreeviewSelectI18n.selectedItem = selectedItem;
      }
    } else {
      setTimeout(function () { this.carregaItem(value) }.bind(this), 1000)
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any) => { };

  onTouched = () => { };

  select(item: TreeviewItem) {
    if (item.children === undefined) {
      this.onChange(item)
      this.selectItem(item);
    }
  }

  private updateSelectedItem() {
    if (!isNil(this.items) && this.value) {
      const selectedItem = TreeviewHelper.findItemInList(this.items, this.value);
      if (selectedItem) {
        this.selectItem(selectedItem);
      }
    }
  }

  private selectItem(item) {
    this.dropdownTreeviewComponent.dropdownDirective.close();
    if (this.dropdownTreeviewSelectI18n.selectedItem != item) {
      this.dropdownTreeviewSelectI18n.selectedItem = item;
      if (item) {
        this.value = item.value;
        this.dropdownTreeviewComponent.buttonLabel = item.text;
      } else if (this.value != null) {
        this.value = null;
        this.dropdownTreeviewComponent.buttonLabel = 'Selecione';
      }
    }
  }

  removeItem() {
    this.dropdownTreeviewComponent.buttonLabel = 'Selecione';
    this.dropdownTreeviewSelectI18n.selectedItem = null;
    this.value = null;
    this.onChange(null)
  }

  getClass() {
    if (this.value == null) return 'btn-outline-secondary without-value'
    return 'btn-outline-secondary'
  }
}
