/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalCadastroComponent } from '../modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-dim-exportar-consulta',
  templateUrl: './dim-exportar-consulta.component.html',
  styleUrls: ['./dim-exportar-consulta.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DimExportarConsultaComponent),
      multi: true
    }
  ]
})
export class DimExportarConsultaComponent
  extends ModalCadastroComponent
  implements ControlValueAccessor
{

  constructor(
    public controlContainer: ControlContainer
  ) {
    super();
  }

  onChanged: () => void;
  onTouched: () => void;
  writeValue: () => void;
  registerOnChange(_: () => void): void { this.onChanged = _; }
  registerOnTouched(_: () => void): void { this.onTouched = _; }
  @ViewChild('modal', { static: false }) private modal: any;
  @ViewChild('title') title: ElementRef<any>;

  @Output() exportar: EventEmitter<any> = new EventEmitter<string>();
  @Input() exportando = false;
  @Input() pdfColumnSelection = true;
  @Input() xlsxColumnSelection = false;

  @Input() validacao = true;
  @Input() mostrarErro = true;
  @Input() exportarLabel = 'Exportar';
  @Input() exportandoLabel = 'Exportando';
  @Input() cancelarLabel = 'Cancelar';
  @Input() formLabels: any = {};
  @Input() colSize = 'col-6';

  @Input() xlsx = true;
  @Input() xml = false;
  @Input() zip = false;

  exportFileTypeForm = this.getDefaultExportFileTypeForm();
  exportFileTypeLabels = {
    pdf: 'PDF',
    xlsx: 'XLSX',
    xml: 'Arquivo único',
    zip: 'Arquivo zip',
  };

  /**
   * Cria e configura um FormGroup com checkboxes para opções de exportação de arquivos.
   *
   * @returns {FormGroup} Um FormGroup contendo os controles de checkbox para os tipos de exportação disponíveis.
   *
   * @description
   * Este método privado cria um FormGroup com checkboxes que representam as opções de tipo de arquivo disponíveis para exportação.
   * Por padrão, a opção "PDF" está sempre habilitada, e as opções "XLSX", "XML" e "ZIP" são opcionais,
   * dependendo das propriedades `xlsx`, `xml` e `zip` do componente.
   * Se alguma dessas propriedades for falsa, o controle correspondente é removido do FormGroup.
   */
  private getDefaultExportFileTypeForm() {
    const f = new FormGroup({
      pdf: new FormControl(true),
      xlsx: new FormControl(false),
      xml: new FormControl(false),
      zip: new FormControl(false),
    });

    if (!this.xlsx) {
      f.removeControl('xlsx');
    }
    if (!this.xml) {
      f.removeControl('xml');
    }
    if (!this.zip) {
      f.removeControl('zip');
    }

    return f;
  }

  get form() {
    const fg = this.controlContainer.control as FormGroup;
    return fg;
  }

  allowColumnSelection() {
    return (this.pdfColumnSelection && this.exportFileTypeForm.get('pdf').value)
      || (this.xlsxColumnSelection && this.exportFileTypeForm.get('xlsx').value);
  }

  abrir(): void {
    this.modal.show();
  }

  fechar(): void {
    this.modal.hide();
  }

  handleOnClose(): void {
    this.form.setValidators(null);
    this.closed.emit();
  }

  onExport(): void {
    if (!this.form.valid) {
      return;
    }

    this.form.setValidators(null);
    const selected = Object.keys(this.exportFileTypeForm.value).find(k => this.exportFileTypeForm.value[k]);
    this.exportar.emit(selected);
  }

  onCancel(): void {
    this.form.setValidators(null);
    this.fechar();
  }
}

export interface ExportFileTypeOptions {
  pdf: boolean;
  xlsx: boolean;
  xml: boolean;
  zip: boolean;
}

// Utilizar para o @ViewChild
export interface DimExportarConsultaComponentRef {
  abrir: () => void;
  fechar: () => void;
}
