import { StorageFunctions } from './../../../general/storage';
import { MessageService } from './../../../services/message.service';
import { UtilFunctions } from './../../../general/utils-service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Output, Input, AfterViewInit, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EstadosService } from '../../../services/estados.service';
import { Location } from '@angular/common';
import { BaseFactoryComponent } from '../../../shared/base-factory/base-factory.component';
import { ModalidadeBcStIcms } from '../../../model/modalidadeBcIcms';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-mva-por-estados',
  templateUrl: './mva-por-estados.component.html',
  styleUrls: ['./mva-por-estados.component.css']
})
export class MvaPorEstadosComponent extends BaseFactoryComponent implements OnInit, AfterViewInit {

  constructor(
    route: ActivatedRoute,
    utilsFunction: UtilFunctions,
    message: MessageService,
    storage: StorageFunctions,
    location: Location,
    private estadosService: EstadosService
  ) {
    super(route, utilsFunction, message, storage, location);
  }

  @Input() loadingMva;
  @Input() itensMva;
  @Output() formFinished = new EventEmitter<object>();
  @Output() formClosed = new EventEmitter<object>();

  estados = [];
  controle = false;

  ngOnInit() {
    this.form = this.getMvaForm();
    this.carregaEstados();
  }

  changeModalidade($event: any){
    $event.tipo !== 4 ? this.controle = true : this.controle = false;
  }

  modBcStIcms = ModalidadeBcStIcms.listar();

  ngAfterViewInit() {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    })
  }

  getMvaForm(): FormGroup {
    return new FormGroup({
      siglaEstado: new FormControl(null, [Validators.required]),
      mva: new FormControl(null, [Validators.required]),
      redutor: new FormControl(null),
      modBcStIcms: new FormControl(4, [Validators.required]),
      mvaAjustada: new FormControl(null),
    });
  }

  carregaEstados() {
    this.loading['estados'] = true;
    this.estadosService.get()
      .pipe(finalize(() => this.loading['estados'] = false))
      .subscribe(res => {
        this.estados = res;
      })
  }

  novoRegistro() {
    this.form.reset();
  }

  cancelarDigitacao() {
    this.formClosed.emit();
  }

  submitFormItem() {
    if (this.form.valid && this.form.value.modBcStIcms == 4) {
      this.formFinished.emit(this.form.value)
    }
  }
}
