import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseServiceV2 } from '@servicesv2';
import { CodigosBeneficiosCstUfViewModel } from 'models/codigos-beneficios-cst-uf/CodigosBeneficiosCstUfViewModel';

@Injectable()
export class CodigosBeneficiosFiscaisServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('CodigosBeneficiosFiscais', http);
  }

  getCodigosByCst(stVenda: string) {
    return this.get<CodigosBeneficiosCstUfViewModel>(`${stVenda}`);
  }

}
