import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDimBoldText]'
})
export class DimBoldTextDirective implements OnInit, OnChanges {

  @Input() appDimBoldText = true;

  constructor(
    private el: ElementRef, private renderer: Renderer2
  ) {}

  ngOnInit() {
    this.setRequiredStyle();
  }

  ngOnChanges() {
    this.setRequiredStyle();
  }

  private setRequiredStyle() {
    if (this.appDimBoldText) {
      this.renderer.setStyle(this.el.nativeElement, 'font-weight', 'bold');
    } else {
      this.renderer.removeStyle(this.el.nativeElement, 'font-weight');
    }
  }
}
