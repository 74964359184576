<div *ngIf="!disabled" [formGroup]="form" class="btn-actions-wrapper">
  <div class="btn-actions">
    <button
      *ngFor="let qtd of qtdSum"
      type="button"
      class="btn btn-mini rounded btn-outline-info"
      (click)="sum(qtd)"
      tabindex="-1"
    >
      +{{ qtd }}
    </button>
  </div>
  <div class="btn-actions">
    <ng-container *ngFor="let qtd of qtdSub">
      <button
        type="button"
        *ngIf="qtdTotal >= qtd"
        class="btn btn-mini rounded btn-outline-info"
        (click)="sub(qtd)"
        tabindex="-1"
      >
        -{{ qtd }}
      </button>
    </ng-container>
  </div>
</div>
