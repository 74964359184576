import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { ConsultarPrazosPagamentoViewModel } from '../financeiro/cadastros/prazos-pagamento/model-prazos-pagamento/ConsultaPrazosPagamento';

@Injectable()
export class CondicoesPagamentoService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getPaginado(pagina: number, nome: string, filtraInativo = true) {
    return this.http
      .get(this.UrlService + 'condicoesPagamento/GetPaginado?pageIndex=' + pagina + '&search=' + encodeURIComponent(nome) + '&filtraInativo=' + filtraInativo)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getConsultaPaginada(vm: ConsultarPrazosPagamentoViewModel) {
    return this.http
      .post(this.UrlService + 'condicoesPagamento/getConsultaPaginada', vm)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getCondicaoPagamentoById(id: number) {
    return this.http
      .get(this.UrlService + 'condicoesPagamento/GetCondicaoPagamentoById/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getCadastroCondicaoPagamentoById(id: number) {
    return this.http
      .get(this.UrlService + 'condicoesPagamento/GetCadastroCondicaoPagamentoById/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  salvar(form: any, id: number) {
    if (id) {
      return this.put(form);
    } else {
      return this.post(form);
    }
  }

  private post(form) {
    return this.http.post(this.UrlService + 'condicoesPagamento/salvar', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  private put(form) {
    return this.http.put(this.UrlService + 'condicoesPagamento/atualizar', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }
}
