import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '../../../services/message.service';
import { ParamConsulta } from '../../../general/models/paramConsulta';
import { NotasFiscaisService } from '../../../services/notas-fiscais.service';
import { ModalBasicComponent } from '../../../shared/modal-basic/modal-basic.component';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-busca-itens-retorno',
  templateUrl: './busca-itens-retorno.component.html',
  styleUrls: ['./busca-itens-retorno.component.css']
})
export class BuscaItensRetornoComponent extends ModalCadastroComponent implements OnInit {

  constructor(
    private notasFiscaisService: NotasFiscaisService,
    private messageService: MessageService
  ) { super(); }

  @ViewChild('modalConsulta', { static: true }) modalConsulta: ModalBasicComponent;
  notas;
  selecionados = [];
  paramConsulta = new ParamConsulta();
  exibirTodosDestinatarios = false;
  clienteID = null;
  buscaDadosAdicionais: boolean;
  retornaNumerosRemessa: boolean;

  colunas = [
    { label: 'Série', col: 'serie', hide: true },
    { label: 'Número', col: 'numeroNotaFiscal', hide: true },
    { label: 'Cliente', col: 'razaoSocial', hide: false, limit: 35 },
    { label: 'Emissão', col: 'emissao', hide: false, date: true },
    { label: 'Valor', col: 'valorTotal', hide: false, currency: true },
    { label: 'Quantidade', col: 'quantidadeTotal', hide: false, format: this.formatNumber },
  ];

  formatNumber(val) {
    return (val || 0).toString().replace('.', ',');
  }

  ngOnInit() {
    if (this.prop) {
      this.notas = this.prop;
      this.clienteID = this.prop.clienteID;
    }
  }

  show() {
    this.modalConsulta.show();
    this.obterOpcoesDadosAdicionais();
  }

  onSort(paramConsulta) {
    this.loading['table'] = true;
    this.paramConsulta = paramConsulta;
    this.carregarNotas();
  }

  exibirTodosDestinatariosChanged() {
    this.loading['table'] = true;
    this.carregarNotas();
  }

  carregarNotas() {
    const filtroCliente = this.exibirTodosDestinatarios ? null : this.clienteID;
    this.notasFiscaisService.buscarNotasRetornoPaginado(filtroCliente, this.paramConsulta).subscribe({
      next: res => {
        if (res) {
          this.notas = res;
        }
      },
      complete: () => { this.loading['table'] = false }
    })
  }

  salvarOpcoesDadosAdicionais() {
    let paramsBuscarItensRetorno = {
      buscaDadosAdicionais: this.buscaDadosAdicionais,
      retornaNumerosRemessa: this.retornaNumerosRemessa,
    }
    try {
      localStorage.setItem('paramsBuscarItensRetorno', JSON.stringify(paramsBuscarItensRetorno));
    } catch (QuotaExceededError) {
      console.error('Erro ao salvar dados paramsBuscarItensRetorno.', QuotaExceededError);
    }
  }

  obterOpcoesDadosAdicionais() {
    let paramsBuscar = localStorage.getItem("paramsBuscarItensRetorno");
    if (paramsBuscar !== null) {
      const obj = JSON.parse(paramsBuscar);
      this.buscaDadosAdicionais = obj.buscaDadosAdicionais;
      this.retornaNumerosRemessa = obj.retornaNumerosRemessa;
    }
  }

  carregarItens() {
    this.salvarOpcoesDadosAdicionais();
    this.loading['itens'] = true;
    this.notasFiscaisService.montarItensNFeRetorno(this.selecionados, this.buscaDadosAdicionais, this.retornaNumerosRemessa).subscribe(res => {
      if (res && res.itens.length > 0) {
        res.itens = res.itens.map(item => {
          return {
            produto: item.item.id,
            descricaoProduto: item.item.nome,
            unidadeMedida: item.unidade,
            quantidade: item.quantidade,
            preco: item.preco,
            valorDesconto: item.percentualDesconto ? item.percentualDesconto : item.valorDesconto,
            tipoDesconto: item.percentualDesconto ? 1 : 0,
            valorTotalItem: item.quantidade * item.preco,
            itensFornecedores: item.item.itensFornecedores,
            notaFiscalRetornoItemId: item.id,
            referenciaItem: item.item.referenciaItem,
          }
        })
        this.closed.emit(res);
      }
    }, err => {
      this.messageService.error('Ocorreu um erro ao carregar os itens das notas', 5000, 'Atenção!', true);
      this.loading['itens'] = false;
    });
  }
}
