import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { ConsultarCreditoComissoesViewModel, CreditarComissoesRepresentantesResponse, CreditarComissoesRepresentantesViewModel } from '@vendas';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable()
export class RepresentantesService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  public getRepresentantes() {
    return this.http.get(this.UrlService + 'representantes')
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  public getByOData() {
    const odataQuery = `?$select=Id, Nome, TipoComissaoId, Inativo&$orderBy=Nome asc`;
    return this.http.get(this.UrlService + `odata/representantes${odataQuery}`)
      .pipe(map(super.extractODataResult), catchError(super.serviceError));
  }

  public getByUser() {
    return this.http.get(this.UrlService + 'representantes/GetRepresentanteByUser')
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getWithFilters(consulta: any) {
    return this.http
      .post(this.UrlService + 'representantes/buscar', consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  public getRepresentantesPorCliente(query: GetRepresentantesPorCliente) {
    return this.http.get(this.UrlService + 'clientesrepresentantes/listar?clienteId=' + query.id + '&listarInativos=' + query.listarInativos)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getCadastroRepresentanteById(id: number) {
    return this.http
      .get(this.UrlService + 'representantes/GetCadastroRepresentanteById/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getRepresentanteById(id: number) {
    return this.http
      .get(this.UrlService + 'representantes/GetRepresentanteById/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getSequenciaCodigo() {
    return this.http
      .get(this.UrlService + 'representantes/sequenciaCodigo')
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  salvar(form: any) {
    if (form.id == null || form.id === 0) {
      return this.post(form);
    } else {
      return this.put(form);
    }
  }

  private post(form) {
    return this.http.post(this.UrlService + 'representantes', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  private put(form) {
    return this.http.put(this.UrlService + 'representantes', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  delete(id: number) {
    return this.http
      .delete(this.UrlService + 'representantes/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + 'representantes/GetPaginado?pageIndex=' + pagina + '&search=' + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  obterPrepostos(id: number) {
    return this.http
      .get(this.UrlService + `representantes/ObterPrepostos/${id}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getModalRepresentanteById(id: number) {
    return this.http
      .get(this.UrlService + 'representantes/GetModalRepresentanteById/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  salvarRegistroModal(form: any) {
    return this.http
      .post(this.UrlService + 'representantes/SalvarRepresentanteModal', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  consultarCreditoComissoes(vm: ConsultarCreditoComissoesViewModel) {
    return this.http.post(this.UrlService + 'representantes/consultarCreditoComissoes', vm)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  creditarComissoesRepresentantes(vm: CreditarComissoesRepresentantesViewModel) {
    return this.http.post(this.UrlService + 'representantes/creditarComissoesRepresentantes', vm)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  removerControleCreditoComissao(controleId: number) {
    return this.http.post(this.UrlService + 'representantes/removerControleCreditoComissao', { controleId })
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }
}

interface GetRepresentantesPorCliente {
  id: number;
  listarInativos?: boolean;
}
