import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../../services/base.service';
import { Consulta } from '../../model/consulta';
import { map, catchError, finalize } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { MessageService } from '../../services/message.service';

@Injectable()
export class AdminService extends BaseService {

  constructor(private http: HttpClient, private message: MessageService) { super(); }

  getDocumentos(consulta: Consulta) {
    return this.http
      .post(this.UrlService + 'admin', consulta).pipe(
        map((res: any) => <Consulta[]>res.data)
        , catchError(super.serviceError));
  }

  getEmissoesGrouped(): Observable<any> {
    return forkJoin([
      this.getEmissoes(),
      this.getEmissoesUntilToday()
    ]).pipe(
      map(responses => {
        return responses;
      }));
  }

  getVersaoGestor() {
    return this.http.get(this.UrlService + 'admin/getVersaoGestor/').pipe(
      map((res: any) => res.data), catchError(super.serviceError));
  }

  private getEmissoesUntilToday() {
    const today = new Date().getDate();
    return this.http
      .get(this.UrlService + `admin/getEmissoes/${today}`).pipe(
        map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  private getEmissoes() {
    return this.http
      .get(this.UrlService + 'admin/getEmissoes/').pipe(
        map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getFaturamentos() {
    return this.http.get(this.UrlService + 'dashboards/faturamentos')
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getReceitasEfetivadas() {
    return this.http.get(this.UrlService + 'dashboards/receitasEfetivadas')
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getDespesas() {
    return this.http.get(this.UrlService + 'dashboards/despesasFinanceiras')
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getVendasEfetivadas() {
    return this.http.get(this.UrlService + 'dashboards/vendasEfetivadas')
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  resumoAPagar() {
    return this.http.get(this.UrlService + 'dashboards/resumo/contasAPagar')
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  resumoAReceber() {
    return this.http.get(this.UrlService + 'dashboards/resumo/contasAReceber')
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getContasAReceber() {
    return this.http.get(this.UrlService + 'dashboards/contasAReceber')
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getDestinacaoRecursos(grafico = false) {
    return this.http.get(this.UrlService + `dashboards/destinacaoRecursos/${grafico}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  imprimirDestinacaoRecursos() {
    const idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Destinação de recursos',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    });
    return this.http
      .get(this.UrlService + 'dashboards/relatorioDestinacaoRecursos',
        { responseType: 'blob' }).pipe(finalize(() => this.message.clear(idToast))
          , catchError(super.parseErrorBlob));
  }

  getPrevisaoFinanceiraPorDia() {
    return this.http.get(this.UrlService + 'dashboards/previsaoFinanceiraPorDia')
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getPrevisaoFinanceiraPorMes(qntMeses) {
    return this.http.get(this.UrlService + `dashboards/previsaoFinanceiraPorMes/${qntMeses}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  imprimirGraficos(arquivos) {
    return this.http.post(this.UrlService + 'dashboards/imprimirGraficos', arquivos,
      { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  carregaEmpresas(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + 'admin/buscaEmpresas?pageIndex=' + pagina + '&search=' + encodeURIComponent(nome) + '&searchParcial=' + true)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getEmpresasComboBox(pagina: number, busca: string, filters: any[]) {
    return this.http.post(
      this.UrlService + `admin/getEmpresasCombobox?pageIndex=${pagina}&search=${encodeURIComponent(busca)}`, filters)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  carregaEmpresasBuscaParcial(pagina: number, nome: string, searchParcial: boolean) {
    return this.http
      .get(this.UrlService + 'admin/buscaEmpresas?pageIndex=' + pagina + '&search='
        + encodeURIComponent(nome) + '&searchParcial=' + searchParcial)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getUnidadeEmpresaByConsulta(consulta: any) {
    return this.http
      .post(this.UrlService + 'admin/consultaEmpresas', consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getUnidadeEmpresaById(id: number = null) {
    return this.http
      .get(this.UrlService + 'admin/buscaEmpresaPorId/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getClientesEmComumDimensaoContabilidade(consulta: any) {
    return this.http
      .post(this.UrlService + 'admin/consultaClientesEmComum', consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getCertificadoById(id: number) {
    return this.http
      .get(this.UrlService + `admin/buscaCertificadoPorEmpresa/${id}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  relatorioEvasao(formato) {
    return this.http.post(this.UrlService + 'admin/relatorioEvasao', formato, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  getAllInformation(estados, cidades, tabelasPreco, contabilidades, contabilidadeId, clientesFaturamento, clienteId, empresas, empresaId): Observable<any> {
    return forkJoin([estados, cidades, tabelasPreco, contabilidades, contabilidadeId, clientesFaturamento, clienteId, empresas, empresaId])
      .pipe(map(responses => {
        return responses;
      }));
  }

  getEmpresasDevedoras(consulta: any) {
    return this.http
      .post(this.UrlService + "admin/getEmpresasDevedoras/", consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getFaturamento(consulta: any) {
    return this.http.post(this.UrlService + "admin/getFaturamento/", consulta)
      .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
  }

  sendFaturamento(faturamento: any) {
    return this.http.post(this.UrlService + "admin/sendFaturamento", faturamento)
      .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
  }

  getTitulosSemNfse() {
    return this.http.get(this.UrlService + "admin/getTitulosSemNfse")
      .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
  }

  gerarNfse(titulos: any) {
    return this.http.post(this.UrlService + "admin/gerarNfse", titulos)
      .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
  }

  carregarEmailsCobranca(form: any) {
    return this.http.post(this.UrlService + "admin/getEmailCobranca", form)
      .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
  }

  carregarEmailsNotas() {
    return this.http.get(this.UrlService + "admin/getEmailNotas")
      .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
  }

  enviarEmailCobranca(listaDeClientes: any, filtroDeTitulos: any, filtroDeTitulosData: any) {
    return this.http.post(this.UrlService + "admin/sendEmailCobranca", { listaDeClientes, filtroDeTitulos, filtroDeTitulosData })
      .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
  }

  enviarEmailNotas(registros: any) {
    return this.http.post(this.UrlService + "admin/sendEmailNotas", registros)
      .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
  }

  getFaturamentoDetalhado() {
    return this.http.get(this.UrlService + "dashboards/getFaturamentoDetalhado")
      .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
  }

  enviarWhatsAppCobranca(clientes: any, filtroDeTitulos: any, filtroDeTitulosData: any) {
    return this.http.post(this.UrlService + "admin/enviarWhatsCobranca", { clientes, filtroDeTitulos, filtroDeTitulosData })
      .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
  }

  baixarXmlNfse(nfseId: number) {
    return this.http
      .get(this.UrlService + `admin/baixarXmlNfse/${nfseId}`, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob))
  }

  baixarXmlDI(nfeId: number) {
    return this.http
      .get(this.UrlService + `admin/baixarXmlDI/${nfeId}`, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob))
  }

  verificaSeNotaPossuiDI(nfeId: any) {
    return this.http
      .get(this.UrlService + `admin/verificaSeNotaPossuiDI/${nfeId}`)
      .pipe(map((res: any) => res.data), catchError(super.serviceError))
  }

  getClienteById(id: any) {
    if (!id) {
      return of(undefined);
    }
    return this.http
      .get(this.UrlService + 'admin/getClienteById/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getClientesDimensaoPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + `admin/getClientesDimensaoPaginado?pageIndex=` + pagina +
        '&search=' + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
