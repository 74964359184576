import { Component, OnInit, Input } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-editar-menu-item',
  templateUrl: './editar-menu-item.component.html',
  styleUrls: ['./editar-menu-item.component.css']
})
export class EditarMenuItemComponent implements OnInit {

  constructor() { }

  @Input() menuItens: any[];
  @Input() menuType: string = 'son';
  @Input() itemId: number = 1;

  ngOnInit() {

  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.menuItens, event.previousIndex, event.currentIndex);
  }

  getId() {

    if (this.itemId == 3)
      return 'color2';

    if (this.itemId == 2)
      return 'color1';

    return 'color3';
  }

  getNextNumber() {
    let newId = this.itemId;
    newId++;
    if (newId > 3) {
      newId = 1
    }
    return newId;
  }
}
