import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseServiceV2 } from '@servicesv2';
import {
  ConsultarTitulosCobrancaViewModel,
  ConsultarTitulosCobrancaResponseViewModel
} from 'models/titulos-cobranca/ConsultarTitulosCobrancaViewModel';

@Injectable()
export class TitulosCobrancaServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('TitulosCobranca', http);
  }

  buscar(payload: ConsultarTitulosCobrancaViewModel) {
    return this.postAsPromise<ConsultarTitulosCobrancaViewModel, ConsultarTitulosCobrancaResponseViewModel>(payload, 'buscar');
  }

}
