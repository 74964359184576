import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { markFormGroupTouched } from '../../../../general/utils';
import { ClientesService } from '../../../../services/clientes.service';
import { saveAs } from 'file-saver';
import { CidadesService } from './../../../../services/cidades.service';
import { EstadosService } from './../../../../services/estados.service';
import { RepresentantesService } from '../../../../services/representantes.service';
import { MessageService } from '../../../../services/message.service';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { finalize } from 'rxjs/operators';
import swal from 'sweetalert2';
import { Representante } from '../../../../model/RepresentantesViewModel';

@Component({
  selector: 'app-relacao-de-clientes',
  templateUrl: './relacao-de-clientes.component.html',
  styleUrls: ['./relacao-de-clientes.component.css']
})
export class RelacaoDeClientesComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalRelatorioRelacaoClientes', { static: true }) modalRelatorio;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  impressaoForm: FormGroup;
  cidadesMap = [];
  estadosMap = [];
  representantesMap = [];

  constructor(
    private estadoService: EstadosService,
    private cidadeService: CidadesService,
    private clientesService: ClientesService,
    private representantesService: RepresentantesService,
  ) { super() }

  ngOnInit() {
    this.impressaoForm = new FormGroup({
      inclusaoDe: new FormControl(null),
      inclusaoAte: new FormControl(null),
      compraDe: new FormControl(null),
      compraAte: new FormControl(null),
      cidadeId: new FormControl(null),
      estadoId: new FormControl(null),
      ativos: new FormControl(true),
      inativos: new FormControl(false),
      representanteId: new FormControl(null),
      planilha: new FormControl(false)
    })

    this.estadoService.get()
      .subscribe(res => {
        this.estadosMap = res;
      });

    this.getCidade(null);
    this.getRepresentantes();
  }

  show(): void {
    this.ngOnInit();
    this.modalRelatorio.show();
  }

  validaForm() {
    markFormGroupTouched(this.impressaoForm);
  }

  submitForm() {
    if (this.impressaoForm.valid) {
      this.modalRelatorio.hide();
      this.closed.emit();
      this.clientesService.imprimirRelacaoDeClientes(this.impressaoForm.value)
        .pipe(finalize(() => this.loading = false))
        .subscribe(res => {
          const formato = this.impressaoForm.get('planilha').value ? 'xls' : 'pdf';
          saveAs(res, `relatorio_relacao_clientes.${formato}`);
        }, (error) => {
          swal("Erro ao emitir o relatório: Relação de Clientes", error.errors[0], "error");
        })
    }
  }

  onChangeEstado(estadoId: any) {
    this.getCidade(estadoId);
    this.impressaoForm.controls['cidadeId'].setValue(null);
  }

  getCidade(estadoId) {
    if (estadoId != null) {
      this.cidadeService.getByEstado(estadoId)
        .subscribe(res => {
          this.cidadesMap = res;
        });
    } else {
      this.cidadeService.get()
        .subscribe(res => {
          this.cidadesMap = res;
        });
    }
  }

  getRepresentantes() {
    this.representantesService.getRepresentantes()
      .subscribe(res => {
        this.representantesMap = res.filter((r: Representante) => !r.inativo);
      });
  }

  checkInput(input, event) {
    if (input === 'ativos' && event.target.checked) {
      this.impressaoForm.controls['inativos'].setValue(false);
    }

    if (input === 'inativos' && event.target.checked) {
      this.impressaoForm.controls['ativos'].setValue(false);
    }
  }

  onClose(){
    this.closed.emit();
  }
}
