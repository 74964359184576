<ul class="pagination">
  <li class="page-item">
    <a class="page-link" (click)="changePage(1)" aria-label="Previous">
      <span aria-hidden="true">&laquo;</span>
      <span class="sr-only">Primeira</span>
    </a>
  </li>
  <li class="page-item" (click)="changePage(currentPage - 1)" [class.disabled]="currentPage == 1">
    <a class="page-link" title="Página anterior">&lsaquo;</a>
  </li>
  <li class="page-item" [ngClass]="{'active' : page === currentPage}" *ngFor="let page of pages | async">
    <a class="page-link" (click)="changePage(page)"> {{ page }} </a>
  </li>
  <li class="page-item" [class.disabled]="currentPage == totalPages">
    <a class="page-link" (click)="changePage(currentPage + 1)" title="Próxima página">&rsaquo;</a>
  </li>
  <li class="page-item">
    <a class="page-link" (click)="changePage(totalPages)" aria-label="Next">
      <span aria-hidden="true">&raquo;</span>
      <span class="sr-only">Última</span>
    </a>
  </li>
</ul>