import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { TipoDespesaService } from '../../../services/tipo-despesa.service';
import { BancoEventosService } from '../../../services/banco-eventos.service';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-vincaular-tipo-despesa-eventos-bancarios',
  templateUrl: './vincaular-tipo-despesa-eventos-bancarios.component.html',
  styleUrls: ['./vincaular-tipo-despesa-eventos-bancarios.component.css']
})
export class VincaularTipoDespesaEventosBancariosComponent extends ModalCadastroComponent implements OnInit {

  constructor(private tipoDespesaService: TipoDespesaService, private bancoEventosService: BancoEventosService,
    private message: MessageService) {
    super();
  }

  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  tipoDespesa: any = null;
  bancoEventos: any = null;
  colunas = [
    { label: 'Evento Bancário', col: 'eventoBancario', hide: false }
  ];
  selecionados = [];

  ngOnInit() {
  }

  show(id) {
    this.id = id;
    this.modalCadastro.show();
    this.buscar();
  }

  buscar() {
    this.selecionados = [];
    this.loading['table'] = true;
    const tipoDespesaPromisse = new Promise((resolve, reject) => {
      this.tipoDespesaService.getTipoDespesaById(this.id)
        .subscribe(res => {
          this.tipoDespesa = res;
          resolve(res);

        });
    });

    const bancoEventosPromisse = new Promise((resolve, reject) => {
      this.bancoEventosService.getAllEventos()
        .subscribe(res => {
          this.bancoEventos = res;
          resolve(res);
        })
    });

    Promise.all([tipoDespesaPromisse, bancoEventosPromisse]).then((value) => {
      this.bancoEventos.forEach(bancoEvento => {
        if (this.tipoDespesa.tipoDespesaBancoEventos != null) {
          this.tipoDespesa.tipoDespesaBancoEventos.some(tipoDepesaBancoEvento => {
            let find = false;

            if (bancoEvento.id === tipoDepesaBancoEvento.bancoEventoId) {
              this.selecionados.push(bancoEvento)
              find = true;
            }

            return find === true;
          });
        }
      });

      this.loading['table'] = false;
    });
  }

  salvar() {
    let newList = [];
    if (this.tipoDespesa.tipoDespesaBancoEventos != null) {
      this.tipoDespesa.tipoDespesaBancoEventos.forEach(tipoDespesaBancoEvento => {

        this.selecionados.some(bancoEvento => {
          let find = false;

          if (bancoEvento.id === tipoDespesaBancoEvento.bancoEventoId) {
            find = true;
            newList.push(tipoDespesaBancoEvento);
          }

          return find == true;
        });
      });
    } else {
      this.tipoDespesa.tipoDespesaBancoEventos = [];
    }

    this.selecionados.forEach(bancoEvento => {
      let find = false;

      this.tipoDespesa.tipoDespesaBancoEventos.forEach(tipoDespesaBancoEvento => {
        if (bancoEvento.id === tipoDespesaBancoEvento.bancoEventoId) {
          find = true;
        }

        return find === true;
      });

      if (!find) {
        newList.push({
          id: 0,
          tipoDespesaId: this.tipoDespesa.id,
          bancoEventoId: bancoEvento.id
        });
      }
    });

    this.tipoDespesa.tipoDespesaBancoEventos = newList;

    this.tipoDespesaService.vincularEventosBancarios(this.tipoDespesa)
      .subscribe(res => {
        this.message.success('Vinculação efetuada!', 5000);
        this.modalCadastro.hide();
      }, err => {
        this.message.error('Um erro ocorreu ao tentar fazer a vinculação!', 5000);
      })

  }

}
