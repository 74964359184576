import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { markFormGroupTouched } from '../../../../general/utils';
import { finalize } from 'rxjs/operators';
import swal from 'sweetalert2';
import { MovimentacoesService } from '../../../../services/movimentacoes.service';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-saldo-contas',
  templateUrl: './saldo-contas.component.html',
  styleUrls: ['./saldo-contas.component.css']
})
export class SaldoContasComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalRelatorio', { static: true }) modalRelatorio;
  impressaoForm: FormGroup;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  loading: boolean = false;

  constructor(public movimentacoesServices: MovimentacoesService) { super() }

  ngOnInit() {
    this.impressaoForm = new FormGroup({
      dataReferencia: new FormControl(new Date(), [Validators.required]),
      desconsiderarLancamentosTerceiros: new FormControl(false),
      desconsiderarFluxoDeCaixa: new FormControl(false),
    });
  }

  destroyComponent() {
    this.closed.emit();
  }

  show(): void {
    this.modalRelatorio.show();
  }

  validaForm() {
    markFormGroupTouched(this.impressaoForm);
  }

  submitForm() {
    if (this.impressaoForm.valid) {
      this.loading = true;
      this.modalRelatorio.hide();
      this.movimentacoesServices.imprimirSaldoContas(this.impressaoForm.value)
        .pipe(finalize(() => this.loading = false))
        .subscribe(res => {
          saveAs(res, 'relatorio_saldo_contas.pdf');
          this.destroyComponent();
        }, (error) => {
          swal('Erro ao emitir o relatório: Saldo de contas', error.errors[0], 'error');
          this.destroyComponent();
        });
    }
  }
}
