import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class TipoDespesaService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get() {
    return this.http.get(this.UrlService + 'TipoDespesa').pipe(
      map(super.extractData)
      , catchError(super.serviceError))
  }

  getTipoDespesaById(id: number) {
    return this.http
      .get(this.UrlService + "TipoDespesa/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  post(form) {
    return this.http.post(this.UrlService + 'TipoDespesa/salvar', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  vincularEventosBancarios(form) {
    return this.http.post(this.UrlService + 'TipoDespesa/salvarVinculoEventoBancario', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  getDespesas(form) {
    return this.http
      .post(this.UrlService + 'TipoDespesa/buscar', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  delete(id: number) {
    return this.http
      .delete(this.UrlService + "TipoDespesa/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + "TipoDespesa/Paginado?pageIndex=" + pagina + "&search=" + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}