import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NgxCurrencyConfig, NgxCurrencyInputMode } from 'ngx-currency';

@Component({
  selector: 'app-dim-input-text',
  templateUrl: './dim-input-text.component.html',
  styleUrls: ['./dim-input-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DimInputTextComponent),
      multi: true
    }
  ]
})
export class DimInputTextComponent implements ControlValueAccessor {
  @Input() id = 'campo_de_texto';
  @Input() label = 'Campo de texto';
  @Input() required = false;
  @Input() maxLength = 100;
  @Input() placeholder = 'Digite';
  @Input() icon: string = null;
  @Input() readonly = false;
  @Input() maxValue: number;
  @Input() showErrors = false;
  @Input() showErrorsAsNgbTooltip = false;

  // Modifica o input para usar currencyMask inputmode="numeric". Obs: Não é possível utilizar somente 'mask' como @Input pois causará conflito com a lib 'uiowa/digit-only'.
  @Input() maskType: 'currency' | 'quantity' = null;
  // Propriedades individuais para simplificar a customização da máscara.
  @Input() maskPrefix: 'R$ ' | '' = this.maskType === 'currency' ? 'R$ ' : '';
  @Input() maskThousands = '.';
  @Input() maskDecimal = ',';
  @Input() maskAlign = 'right';
  @Input() maskPrecision = 2;
  @Input() maskSuffix = '';
  @Input() maskAllowNegative = true;

  control = new FormControl<string | number>('', [this.validate()]);

  @Output() validated = new EventEmitter<boolean>();

  get currencyMaskConfig(): NgxCurrencyConfig {
    return {
      align: this.maskAlign,
      allowNegative: this.maskAllowNegative,
      allowZero: true,
      decimal: this.maskDecimal,
      precision: this.maskPrecision,
      prefix: this.maskPrefix,
      suffix: this.maskSuffix,
      thousands: this.maskThousands,
      nullable: true,
      min: null,
      max: null,
      inputMode: NgxCurrencyInputMode.Financial,
    };
  }

  onChange: (value: string | number) => void;
  onTouched: () => void;

  writeValue(value: string | number): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: (value: string | number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onInputTextChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    this.onChange(value);
    this.validated.emit(this.control.valid);
    this.onTouched();
  }

  inputTextNgStyle() {
    const style: InputTextNgStyle = {};
    if (this.icon) {
      style['padding-left'] = '30px';
    }
    return style;
  }

  getNgClass() {
    return {
      'has-error': this.control.invalid
    };
  }

  getNgbTooltip() {
    if (this.control.invalid) {
      if (this.control.errors) {
        return this.control.errors.custom?.message;
      }
      return 'Campo inválido';
    }

    return '';
  }

  private validate(): ValidatorFn {
    return (c: FormGroup): ValidationErrors => {
      if (this.maskType && this.maxValue && c.value > this.maxValue) {
        // Ajustar mensagem de erro para um @Input quando necessário.
        return { custom: { message: 'A quantidade a devolver deve ser menor que o saldo.' } };
      }

      if (this.required && !c.value) {
        return { custom: { message: 'Campo obrigatório.' } };
      }

      return null;
    };
  }
}

interface InputTextNgStyle {
  'padding-left'?: string;
}
