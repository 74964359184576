export class ModalidadeBcIcms {
    private tipo: number;
    private descricao: string;

    constructor(tipo: number, descricao: string) {
        this.tipo = tipo;
        this.descricao = descricao;
    }

    public static listar(): ModalidadeBcIcms[] {
        return [
            new ModalidadeBcIcms(0, "Margem Valor Agregado (%)"),
            new ModalidadeBcIcms(1, "Pauta (Valor)"),
            new ModalidadeBcIcms(2, "Preço Tabelado Máx. (valor)"),
            new ModalidadeBcIcms(3, "Valor da operação"),
        ]
    }
}

export class ModalidadeBcStIcms {
    private tipo: number;
    private descricao: string;

    constructor(tipo: number, descricao: string) {
        this.tipo = tipo;
        this.descricao = descricao;
    }

    public static listar(): ModalidadeBcStIcms[] {
        return [
            new ModalidadeBcStIcms(0, "Preço tabelado ou máximo sugerido"),
            new ModalidadeBcStIcms(1, "Lista Negativa (valor)"),
            new ModalidadeBcStIcms(2, "Lista Positiva (valor)"),
            new ModalidadeBcStIcms(3, "Lista Neutra (valor)"),
            new ModalidadeBcStIcms(4, "Margem Valor Agregado (%)"),
            new ModalidadeBcStIcms(5, "Pauta"),
            new ModalidadeBcStIcms(6, "Valor da Operação")
        ]
    }
}
