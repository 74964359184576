<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader">
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">

    <div class="row">
      <div class="col-sm-4 form-group">
        <label class="label-required">NCM</label>
        <input appAutofocus maxlength="8" [disableControl]="loading['registro']" digitOnly class="form-control"
          formControlName="classificacaoFiscal" />
        <show-errors [control]="cadastroForm.controls.classificacaoFiscal"></show-errors>
      </div>

      <div class="col-sm-8 form-group">
        <label class="label-required">Descrição</label>
        <input maxlength="30" class="form-control" formControlName="descricao" [disableControl]="loading['registro']">
        <show-errors [control]="cadastroForm.controls.descricao"></show-errors>
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="errors">
      <span>{{ errors }}</span>
    </div>

    <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'" [loading]="loading['registro']"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalCadastro.hide()">Cancelar</button>
  </form>
</app-temp-modal-cadastro>