import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { AdminService } from '../../../admin/services/admin.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FaturamentoDetalhadoViewModel } from './FaturamentoDetalhado';

@Component({
  selector: 'app-faturamento-detalhado',
  templateUrl: './faturamento-detalhado.component.html',
  styleUrls: ['./faturamento-detalhado.component.css'],
})
export class FaturamentoDetalhadoComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modalFaturamentoDetalhado', { static: true }) modalFaturamentoDetalhado;
  faturamentoDetalhado: FaturamentoDetalhadoViewModel = null;
  colunas = [
    { label: 'Número', col: 'numeroDocumento', hide: false },
    { label: 'Data', col: 'data', hide: false, format: this.formataData.bind(this) },
    { label: 'Valor', col: 'valor', hide: false, currency: true },
  ];

  constructor(
    private service: AdminService,
  ) {
    super();
  }

  ngOnInit() {
    this.carregar();
  }

  show() {
    this.modalFaturamentoDetalhado.show();
  }

  onClose() {
    this.closed.emit();
  }

  async carregar() {
    this.faturamentoDetalhado = null;
    this.loading['dados'] = true;

    try {
      this.faturamentoDetalhado = await this.service.getFaturamentoDetalhado().toPromise();
    } catch (err) {
      console.error(err);
    } finally {
      this.loading['dados'] = false;
    }
  }

  formataData(value) {
    if (!value)
      return "-";
    return moment(value, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY HH:mm');
  }
}
