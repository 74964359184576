import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { MessageService } from '@services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComboboxPadrao } from 'models/combobox-padrao/combobox-padrao';
import { CodigosBeneficiosCstUfViewModel } from 'models/codigos-beneficios-cst-uf/CodigosBeneficiosCstUfViewModel';
import { CodigosBeneficiosFiscaisServiceV2 } from '../../services-v2/codigos-beneficios-fiscais/codigos-beneficios-fiscais-v2.service';

@Component({
  selector: 'app-combobox-cbenef-fiscais',
  templateUrl: './combobox-cbenef-fiscais.component.html',
  styleUrls: ['./combobox-cbenef-fiscais.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxCbenefFiscaisComponent),
      multi: true
    }
  ]
})
export class ComboboxCbenefFiscaisComponent extends ComboboxPadrao<CodigosBeneficiosCstUfViewModel> implements ControlValueAccessor, OnChanges {
  override bindLabel = 'codigoDescricao';
  override bindValue = 'codigo';
  @Input() override label = 'Codigo de Benefícios Fiscais';
  @Input() override required = false;
  @Input() override requiredText = 'O código é obrigatório';
  @Input() override searchField = 'Codigo,Descricao';

  @Input() override appendTo = 'body';
  @Input() loading = false;
  @Input() disabled = false;
  @Input() override notFoundPlusIcon = false;
  @Input() override pencilIcon = false;
  @Input() override customData: { cst: string } = { cst: '' };
  @Output() changed = new EventEmitter();

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customData) {
      if (changes.customData.previousValue && changes.customData.previousValue.cst !== changes.customData.currentValue.cst) {
        this.typeaheadSearchPaginated();
      }
    }
  }

  constructor(
    service: CodigosBeneficiosFiscaisServiceV2,
    message: MessageService,
  ) {
    super('combobox_cbenef_fiscais', message, service);
  }

  writeValue(value: number): void {
    this.selected.setValue(value);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  handleOnChanged(value: number) {
    this.selected.setValue(value);
    this.changed.emit(value);
    this.onChange(value);
  }

  handleOnAdded(value: number) {
    this.selected.setValue(value);
    this.changed.emit(value);
    this.onChange(value);
  }

  handleOnEdited(value: number) {
    this.selected.setValue(value);
    this.changed.emit(value);
    this.onChange(value);
  }
}
