import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { UsuariosService } from './../../../services/usuarios.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { AcessosUsuarioComponent } from '../../consultas/acessos-usuario/acessos-usuario.component';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.css']
})
export class EditarUsuarioComponent extends ModalCadastroComponent implements OnInit {

  constructor(
    private usuariosService: UsuariosService,
    private resolver: ComponentFactoryResolver
  ) {
    super();
  }

  emEdicao = false;
  id = 0;
  componentAcessosUsuario: any;
  @ViewChild("container", { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  @ViewChild("user", { static: false }) user;
  inputOnSearch = new Subject<string>();
  usuarioAtual = null;

  cadastroForm = new FormGroup({
    id: new FormControl(null),
    login: new FormControl(null, [Validators.minLength(3)]),
    senha: new FormControl(null, [Validators.minLength(4)]),
    nome: new FormControl(null),
    administrador: new FormControl(null),
    email: new FormControl(null),
    gruposUsuariosId: new FormControl(null),
    unidadeEmpresaId: new FormControl(null)
  })

  @ViewChild("modalEditarUsuario", { static: true }) modalEditarUsuario;

  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(AcessosUsuarioComponent);
    this.componentAcessosUsuario = this.container.createComponent(factory);
    this.onSearchLogin();
    this.usuariosService.fetchCurrentUser().subscribe(res => {
      if (!res)
        return;
      this.usuarioAtual = res;
    })
  }

  editar(idUsuario) {
    this.id = idUsuario;
    this.buscarUsuario()
    this.modalEditarUsuario.show();
    this.emEdicao = false;
  }

  get permiteEditar() {
    if (!this.usuarioAtual || !this.cadastroForm || !this.cadastroForm.get('unidadeEmpresaId').value)
      return false;

    // Edição de usuários da Dimensão liberada somente para o grupo 18
    if (this.cadastroForm.get('unidadeEmpresaId').value == 3 && this.usuarioAtual.gruposUsuariosId !== 18)
      return false;

    return true;
  }

  buscarUsuario() {
    this.usuariosService.fetchIdDimensao(this.id)
      .subscribe(res => {
        this.cadastroForm.controls['id'].setValue(res.id);
        this.cadastroForm.controls['login'].setValue(res.login);
        this.cadastroForm.controls['senha'].setValue(res.senha);
        this.cadastroForm.controls['nome'].setValue(res.nome);
        this.cadastroForm.controls['administrador'].setValue(res.administrador);
        this.cadastroForm.controls['email'].setValue(res.email);
        this.cadastroForm.controls['gruposUsuariosId'].setValue(res.gruposUsuariosId);
        this.cadastroForm.controls['unidadeEmpresaId'].setValue(res.unidadeEmpresaId);
        this.estadoCampos();
      });
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      this.alteraLoading(true);
      this.usuariosService.alteraLogin(this.cadastroForm.value)
        .subscribe(res => {
          Swal("Sucesso", "Usuário salvos com sucesso!", "success")
            .then(e => {
              // Salvou
              this.alteraLoading(false);
              this.alteraEditar();
            })
        },
          error => {
            Swal("Erro", "Um erro ocorreu ao tentar salvar o usuário", "error");
            this.alteraLoading(false);
            this.alteraEditar();
          }
        );
    }
  }

  alteraEditar() {
    this.emEdicao = !this.emEdicao;
    this.estadoCampos();
  }

  estadoCampos() {
    if (this.emEdicao) {
      this.cadastroForm.controls['login'].enable()
      this.cadastroForm.controls['senha'].enable()
      this.cadastroForm.controls['administrador'].enable()
    } else {
      this.cadastroForm.controls['login'].disable()
      this.cadastroForm.controls['senha'].disable()
      this.cadastroForm.controls['administrador'].disable()
    }
  }

  cancelaEdicao() {
    this.buscarUsuario();
    this.alteraEditar();
  }

  loginInvalido = false
  disableSave = false
  onSearchLogin() {
    this.inputOnSearch.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => { this.disableSave = true, this.cadastroForm.controls['login'].disable() })
    ).subscribe(value => {
      if (this.emEdicao) {
        if (value.length >= 3) {
          this.usuariosService.verificaLogin(this.cadastroForm.get('id').value, value)
            .subscribe(res => {
              this.cadastroForm.controls['login'].enable()
              this.user.nativeElement.focus()
              if (res.loginValido) {
                this.disableSave = false;
                this.loginInvalido = false
              } else {
                this.loginInvalido = true
              }
            });
        } else {
          this.cadastroForm.controls['login'].enable()
          this.user.nativeElement.focus()
        }
      } else {
        this.disableSave = false
      }
    })
  }

}
