import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { TempModalCadastroComponent } from '../../../shared/templates/temp-modal-cadastro/temp-modal-cadastro.component';
import { MessageService } from '../../../services/message.service';
import { UnidadesEmpresaParametrosService } from '../../../services/unidades-empresa-parametros.service';

@Component({
  selector: 'app-lista-itens-faturacao',
  templateUrl: './lista-itens-fechamento.component.html',
  styleUrls: ['./lista-itens-fechamento.component.css']
})
export class ListaItensFechamentoComponent extends ModalCadastroComponent implements OnInit {
  precisaoQtd = 4; // Colocar qtd máxima e depois atualizar com o parâmetro, para não arredondar o valor em tela.

  constructor(
    private messageService: MessageService,
    private parametrosService: UnidadesEmpresaParametrosService,
  ) {
    super();
  }
  @ViewChild('modalConsultar', { static: true }) modalConsultar: TempModalCadastroComponent;

  itens: any[] = [];
  itensSelecionados: any[] = [];
  itensSelecionaveis = false;

  colunas = [
    { label: 'Nome', col: 'descricaoProduto' },
    { label: 'Quantidade a receber', col: 'quantidadeFechar', customTmpl: true },
    { label: 'Preço', col: 'preco', currency: true, decimaisParametrizado: true }
  ];

  ngOnInit() {
    this.parametrosService.getParametrosGerais().subscribe(params => {
      this.precisaoQtd = params.casasDecimaisQuantidade ? params.casasDecimaisQuantidade : 2;
      if (this.precisaoQtd > 4) this.precisaoQtd = 4;
    });
    
    this.itensSelecionaveis = this.prop.itensSelecionaveis;
    this.itens = this.prop.itens;

    this.itens.forEach(item => {
      item.quantidadeFechar = (item.quantidade || 0) - (item.quantidadeFechada || 0) - (item.quantidadeFaturada || 0);
      item.remover = item.quantidadeFechar <= 0;
    });
  }

  get itensAFechar() {
    return this.itens.filter(x => !x.remover);
  }

  show() {
    this.modalConsultar.show();
  }

  hide() {
    this.closed.emit();
  }

  validateQuantity(item: any) {
    if (item.quantidadeFechar < 0) {
      this.messageService.warning('A quantidade não pode ser menor que zero.');
      setTimeout(() => {
        item.quantidadeFechar = (item.quantidade || 0) - (item.quantidadeFechada || 0) - (item.quantidadeFaturada || 0);
      });
    } else if (item.quantidadeFechar > item.quantidade - (item.quantidadeFechada || 0) - (item.quantidadeFaturada || 0)) {
      this.messageService.warning('A quantidade não pode ser maior que a quantidade disponível a fechar.');
      setTimeout(() => {
        item.quantidadeFechar = (item.quantidade || 0) - (item.quantidadeFechada || 0) - (item.quantidadeFaturada || 0);
      });
    }
  }

  finalizar() {
    if (!this.itensSelecionaveis) {
      this.itensSelecionados = this.itens;
    }

    this.closed.emit(this.itensSelecionados);
  }

  get pipePrecisaoQtd() {
    return `1.${this.precisaoQtd}-${this.precisaoQtd}`;
  }
}
