import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from "./base.service";
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class RotasService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getRotasById(id: number) {
        return this.http
            .get(this.UrlService + "rotas/" + id)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    };

    getRotas(pagina: number, nome: string) {
        return this.http
            .get(this.UrlService + "rotas?pageIndex=" + pagina + "&search=" + encodeURIComponent(nome))
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    getConsultaRotas(consulta: any) {
        return this.http
            .post(this.UrlService + "rotas/consulta/", consulta)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    };

    update(data) {
        return this.http.put(this.UrlService + `rotas/${data.id}`, data)
            .pipe(map(super.extractData)
                , catchError(super.serviceError))
    }

    create(data) {
        return this.http.post(this.UrlService + `rotas`, data)
            .pipe(map(super.extractData)
                , catchError(super.serviceError))
    }

    delete(id: number) {
        return this.http.delete(this.UrlService + `rotas/${id}`)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }
}





