<app-modal-basic #modalRelatorio dialogClass="modal-lg" (closed)="hide()">
  <div class="app-modal-header">
    <h4 class="modal-title">Entregas de pedidos</h4>
  </div>
  <div class="app-modal-body">

    <app-dim-datatable #table [loading]="loading['consulta']"
                       [data]="dados.items" [columns]="colunas" (sorting)="onSort($event)" [rowsOnPage]="10"
                       [totalItems]="dados.totalItems" (pageChanged)="setPage($event)" [selectable]="true" [selecionados]="pedidosSelecionados"
                       [currentPage]="prop.paramConsulta.index">
    </app-dim-datatable>

    <button type="submit" class="btn btn-primary ripple light" (click)="imprimir()" [disabled]="!(pedidosSelecionados && pedidosSelecionados.length > 0) || loading['impressao']">
      <app-loading [loading]="loading['impressao']" texto="Confirmar e imprimir" textoLoading="Imprimindo" icone="icofont icofont-print"></app-loading>
    </button>
    <button type="button" class="btn btn-danger ripple" (click)="hide()"><i class="ti ti-close"></i>
      Cancelar
    </button>
  </div>
</app-modal-basic>
