import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CadastroMovimentacoesVendedoresViewModel,
  ConsultaMovimentacoesVendedoresViewModel,
  ExpotarConsultaMovimentacoesVendedoresViewModel
} from '@vendas';
import { catchError, finalize, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { MessageService } from './message.service';

@Injectable()
export class MovimentacoesService extends BaseService {

  constructor(private http: HttpClient, private message: MessageService) { super(); }

  getWithFilters(consulta: any) {
    return this.http
      .post(this.UrlService + 'movimentacoes/buscar', consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  postForm(form: any) {
    return this.http
      .post(this.UrlService + 'movimentacoes/salvar', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  putForm(form: any) {
    return this.http
      .put(this.UrlService + 'movimentacoes/alterar', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  deleteMovimentacao(id: number) {
    return this.http
      .delete(this.UrlService + 'movimentacoes/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getMovimentacaoById(id: number) {
    return this.http
      .get(this.UrlService + 'movimentacoes/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  uploadOfxFile(file: any) {
    return this.http
      .post(this.UrlService + 'movimentacoes/ofx/uploadFile', file).pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  imprimir(consulta: any, agruparPortador: boolean) {
    return this.http
      .post(this.UrlService + `movimentacoes/imprimir/${agruparPortador}`, consulta, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  visualizarSaldoContas(form) {
    return this.http
    .post(this.UrlService + 'movimentacoes/saldoContas', form)
    .pipe(map((res: any) => res.data)
      , catchError(super.serviceError));
  }

  imprimirSaldoContas(form) {
    const idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Saldo das contas',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    });
    return this.http
      .post(this.UrlService + 'movimentacoes/saldoContas', form, { responseType: 'blob' }).pipe(finalize(() => this.message.clear(idToast))
        , catchError(super.parseErrorBlob));
  }

  salvarMovimentacoesVendedores(vm: CadastroMovimentacoesVendedoresViewModel) {
    return this.http.post(this.UrlService + `movimentacoes/salvarMovimentacoesVendedores`, vm)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  deletarMovimentacoesVendedores(id: number) {
    return this.http
      .delete(this.UrlService + 'movimentacoes/deletarMovimentacoesVendedores/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  consultarMovimentacoesVendedores(vm: ConsultaMovimentacoesVendedoresViewModel) {
    return this.http.post(this.UrlService + `movimentacoes/consultarMovimentacoesVendedores`, vm)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  exportarConsultaMovimentacoesVendedores(vm: ExpotarConsultaMovimentacoesVendedoresViewModel) {
    return this.http.post(this.UrlService + `movimentacoes/exportarConsultaMovimentacoesVendedores`, vm, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }
}
