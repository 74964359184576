import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MessageService } from '../../../services/message.service';
import { NFEntradaService } from '../../../services/nfentrada.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-editar-cfop-nota-entrada',
  templateUrl: './editar-cfop-nota-entrada.component.html',
  styleUrls: ['./editar-cfop-nota-entrada.component.css']
})
export class EditarCfopNotaEntradaComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  @Input() fornecedorCnpj = '';
  @Input() fornecedorNome = '';
  @Input() numeroNotaFiscalEntrada = null;
  @Input() notaFiscalEntradaID = null;

  dados = {
    cfopsNotaEntrada: [],
    cfopsDisponiveis: []
  };

  constructor(
    private messageService: MessageService,
    private notasFiscaisEntradaService: NFEntradaService
    ) {
    super();
  }

  ngOnInit() {
    this.fornecedorCnpj = this.prop.fornecedorCnpj;
    this.fornecedorNome = this.prop.fornecedorNome;
    this.numeroNotaFiscalEntrada = this.prop.numeroNotaFiscalEntrada;
    this.notaFiscalEntradaID = this.prop.notaFiscalEntradaID;

    this.loading['cfop'] = true;
    this.notasFiscaisEntradaService.getDadosEdicaoCfopNotaEntrada(this.notaFiscalEntradaID)
      .subscribe(data => {
        this.dados = data;
        this.loading['cfop'] = false;
      }, error => {
        this.messageService.error(error.msg.errors[0], 15000);
      });
  }

  show() {
    this.modalCadastro.show();
  }

  destroyComponent() {
    this.closed.emit();
  }

  buscaCfop(term: string, item: any) : boolean {
    const desc = item.codigo + " - " + item.descricao;
    return desc.toLowerCase().includes(term.toLowerCase());
  }

  salvar() {
    this.loading['salvar'] = true;
    const dados = {
      cfopsAlteradas: this.dados.cfopsNotaEntrada.filter(i => i.novoCodigo),
    };

    this.notasFiscaisEntradaService
      .editarCfopNotaEntrada(this.notaFiscalEntradaID, dados)
      .pipe(finalize(() => {
        this.loading['salvar'] = false;
      }))
      .subscribe(() => {
        this.messageService.success("Nota de entrada alterada com sucesso!", 5000);
        this.closed.emit({ editou: true });
      }, error => {
        this.messageService.error(error.msg.errors[0], 15000);
      });
  }

  get alterouCfop() {
    return this.dados.cfopsNotaEntrada.some(i =>
      i.novoCodigo && i.novoCodigo.toString() !== i.codigo.toString());
  }
}
