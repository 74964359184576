import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseServiceV2 } from '@servicesv2';
import { RepresentantesViewModel } from 'models/representantes/RepresentantesViewModel';

@Injectable()
export class RepresentantesServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('Representantes', http);
  }

  getSequenciaCodigo() {
    return this.getAsPromise<number>('sequenciaCodigo');
  }

  getPrepostos(id: number) {
    return this.getAsPromise<RepresentantesViewModel[]>(`ObterPrepostos/${id}`);
  }

}
