<app-temp-modal-cadastro #modalConsultar textHeader="Itens a Receber" (closed)="hide()">
  <div class="app-modal-body">
    <app-dim-datatable [data]="itensAFechar" [columns]="colunas" [selectable]="itensSelecionaveis" [selecionados]="itensSelecionados">
      <ng-template #customTmpl let-column="column" let-row="row" let-index="index">
        <ng-template [ngIf]="column == 'quantidadeFechar'">
          <ng-container *ngIf="!itensSelecionaveis; else input">
            {{ row.quantidadeFechar | currency:'BRL':'':pipePrecisaoQtd }}
          </ng-container>
          <ng-template #input>
            <input class="editable-input" [(ngModel)]="row.quantidadeFechar" (ngModelChange)="validateQuantity(row)" currencyMask inputmode="numeric" [options]="{ prefix: '', precision: precisaoQtd }" />
          </ng-template>
        </ng-template>
      </ng-template>
    </app-dim-datatable>
  </div>
  <button class="btn btn-primary" (click)="finalizar()" [disabled]="!((itensSelecionados && itensSelecionados.length > 0) || !itensSelecionaveis)">
    <i class="fa fa-money"></i> Forma de Pagamento
  </button>
</app-temp-modal-cadastro>
