import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable()
export class CodigosFiscaisService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getPaginadoOperacao(index: number, operacao: string) {
        return this.http.get(this.UrlService + `codigosFiscais/GetByOperacaoPaginado?index=${index}&idOperacao=${operacao}`)
            .pipe(map((res: any) => res.data),
                catchError(super.serviceError));
    }

    getByCodigoPaginado(paramConsulta, codigo: string = '', descricao: string = '', somenteAtivos: boolean = true) {
        return this.http.post(this.UrlService + `codigosFiscais/GetByCodigoPaginado?codigo=${codigo}&descricao=${descricao}&somenteAtivos=${somenteAtivos}`, paramConsulta)
            .pipe(map((res: any) => res.data),
                catchError(super.serviceError));
    }

    get(codigo: number) {
        return this.http.get(this.UrlService + `codigosFiscais?id=${codigo}`)
            .pipe(map((res: any) => res.data),
                catchError(super.serviceError));
    }

    getByCodigo(codigo: string) {
        return this.http.get(this.UrlService + `codigosFiscais/getByCodigo?codigo=${codigo}`)
            .pipe(map((res: any) => res.data),
                catchError(super.serviceError));
    }

    buscaOuCria(codigo: any) {
        return this.http.post(this.UrlService + 'codigosFiscais/buscaOuCria', codigo)
            .pipe(map((res: any) => res.data), catchError(super.serviceError));
    }

    getPaginado(index: number, search: string) {
        return this.http.get(this.UrlService + `codigosFiscais/GetPaginado?index=${index}&search=${search}`)
            .pipe(map((res: any) => res.data),
                catchError(super.serviceError));
    }

    getById(id: number) {
        return this.http.get(this.UrlService + `codigosFiscais/GetById?id=${id}`)
            .pipe(map((res: any) => res.data),
                catchError(super.serviceError));
    }

    usoVenda(codigoCfop: number) {
        return this.http.get(this.UrlService + `codigosFiscais/usoVenda/${codigoCfop}`)
            .pipe(map((res: any) => res.data), catchError(super.serviceError));
    }

    alterarDadosFiscais(dados: any) {
        return this.http.post(this.UrlService + `codigosFiscais/AlterarDadosFiscais`, dados)
            .pipe(map((res: any) => res.data), catchError(super.serviceError));
    }

    private salvar(form) {
        return this.http.post(this.UrlService + `codigosFiscais`, form)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    private atualizar(form) {
        return this.http.put(this.UrlService + `codigosFiscais`, form)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    delete(id: number) {
        return this.http
            .delete(this.UrlService + `codigosFiscais/${id}`)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    saveForm(form, id: number) {
        if (id && id > 0) {
            return this.atualizar(form);
        } else {
            return this.salvar(form);
        }
    }
}
