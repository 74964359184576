import {ApplicationRef, Component, ComponentFactoryResolver, ElementRef, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {ContextMenu, ContextMenuOptionInterface, ContextMenuSubgroupOption, ContextMenuTitle} from '../../model/context-menu/contextMenu';
import {ContextMenuService} from '../../services/context-menu.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-dim-context-menu',
  templateUrl: './dim-context-menu.component.html',
  styleUrls: ['./dim-context-menu.component.css']
})
export class DimContextMenuComponent implements OnInit {
  @ViewChild('dropdown', { static: false }) dropdown: ElementRef;
  contextData: ContextMenu;
  @Input() contextMenuServiceInstance: ContextMenuService;
  contextMenuService: ContextMenuService;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
              private appRef: ApplicationRef,
              private injector: Injector) { }

  ngOnInit() {
    this.contextMenuService = new ContextMenuService(this.componentFactoryResolver, this.appRef, this.injector);
  }

  isTitle(option) {
    return (option instanceof ContextMenuTitle);
  }

  setPosition(x, y) {
    (this.dropdown.nativeElement as HTMLElement).style.left = x + 'px';
    (this.dropdown.nativeElement as HTMLElement).style.top = y + 'px';
  }

  titlePropsBind(title: string) {
    const bindings = title.match(RegExp(/{{.*?}}/gi));

    if (bindings) {
      bindings.map(value => {
        title = title.replace(value, this.contextData.props[value.replace(RegExp(/[{{,}}]/gi), '')]);
      });
    }

    return title;
  }

  async createContextData(context: ContextMenu) {
    this.contextData = context;
    context.bordersVerify();
    this.setPosition(context.positionX, context.positionY);
  }

  handleClick(option: ContextMenuOptionInterface, event: MouseEvent) {
    this.contextMenuService.removeContextMenuFromBody();

    if (option.action) {
      option.action(this.contextData.props);

      this.contextMenuServiceInstance.removeContextMenuFromBody();
    } else if (option.subContext) {
      option.subContext.setPosition(this.dropdown.nativeElement, event);
      option.subContext.props = this.contextData.props;
      this.contextMenuService.openSubMenu(option.subContext, this.dropdown.nativeElement);
    }
  }

  handleHover(option: ContextMenuOptionInterface, event: MouseEvent) {
    if (option.subContext) {
      option.subContext.setPosition(this.dropdown.nativeElement, event);
      option.subContext.props = this.contextData.props;
      this.contextMenuService.openSubMenu(option.subContext, this.dropdown.nativeElement);
    }
  }

  mouseLeaveHandle() {
    this.contextMenuService.removeContextMenuFromBody();
  }

  clickOutsideHandle(e: MouseEvent) {
    if (this.contextData.main) {
      const component = this.dropdown.nativeElement.querySelector('.dropdown-menu');

      if (e.target !== this.dropdown.nativeElement &&
        e.target !== component &&
        !Object.values(component.children).includes(e.target)) {
        this.contextMenuServiceInstance.removeContextMenuFromBody();
      }
    }
  }
}
