import {Component, OnInit, ViewChild } from '@angular/core';
import {ModalCadastroComponent} from '../../../shared/modal-cadastro/modal-cadastro.component';
import {finalize} from 'rxjs/operators';
import {PedidosService} from '../../../services/pedidos.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-pedidos-entrega',
  templateUrl: './pedidos-entrega.component.html',
  styleUrls: ['./pedidos-entrega.component.css']
})
export class PedidosEntregaComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalRelatorio', { static: true }) modalRelatorio;
  dados;
  pedidos = [];
  pedidosSelecionados = [];
  loading = {};
  colunas = [
    { label: 'Nº Pedido', col: 'numeroPedido' },
    { label: 'Cliente', col: 'cliente.razaoSocial' },
    { label: 'Valor total', col: 'valorTotal', hide: false, currency: true },
  ];

  constructor(
    private pedidosService: PedidosService
  ) {
    super();
  }

  ngOnInit() {
    this.consultar();
  }

  show(): void {
    this.modalRelatorio.show();
  }

  hide(): void {
    this.closed.emit();
  }

  setPage(paramConsulta) {
    this.alteraLoading(true, 'table');
    this.setParamConsulta(paramConsulta);
    this.consultar();
  }

  onSort(paramConsulta) {
    this.alteraLoading(true, 'table');
    this.setParamConsulta(paramConsulta);
    this.consultar();
  }

  setParamConsulta(paramConsulta) {
    this.prop.paramConsulta = paramConsulta;
  }

  consultar() {
    this.loading['consulta'] = true;
    this.pedidosService.getPedidosPaginado(this.prop)
      .pipe(finalize(() => {
        this.loading['consulta'] = false;
      }))
      .subscribe(res => {
        this.dados = res;
      }, error => {
        this.errors = error.msg.errors;
      });
  }

  imprimir() {
    this.loading['impressao'] = true;
    this.pedidosService.imprimirPedidosEntrega(this.pedidosSelecionados.map(x => x.id))
      .pipe(finalize(() => this.loading['impressao'] = false))
      .subscribe((file) => {
        saveAs(file,  'pedidos.pdf');
      });
  }
}
