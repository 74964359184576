<div *ngIf="menuType == 'father'; else elseBlock">
  <ul *ngFor="let item of menuItens" class="pcoded-item pcoded-left-item" item-border="none" item-border-style="solid"
    subitem-border="solid" appAccordionLink group="{{item.nome}}">

    <li class="pcoded-hasmenu" *ngIf="item.tipoDeItem == 'subMenu'" appAccordionLink style="cursor: pointer;"
      dropdown-icon="style3" subitem-icon="style6" (click)=hideMenu(false)>
      <a appAccordionToggle href="javascript:;">
        <span class="pcoded-micon"><i [class]="item.icone"></i></span>
        <span class="pcoded-mtext">{{item.nome}}</span>
        <span *ngIf="item.badgeText && item.badgeType"
          class="pcoded-badge label label-{{ item.badgeType }}">{{item.badgeText}}</span>
        <span class="pcoded-mcaret"></span>
      </a>
      <app-menu-item class="pcoded-submenu pcoded-submenu-first" [menuItens]="item.filhos" [menuType]="'son'" [rotaPai]="'/'+item.rota"
        (functionResponse)="executeFunction($event)" (hideMobileMenu)="hideMenu($event)" [level]="1">
      </app-menu-item>
    </li>

    <li [routerLinkActive]="['active']" *ngIf="item.tipoDeItem == 'menuLateral' && item.tipoDeLink == 'funcao'">
      <a href="javascript:;" (click)="executeFunction({funcao:item.rota, parametro:item.parametro})">
        <span class="pcoded-micon"><i [class]="item.icone"></i></span>
        <span class="pcoded-mtext">{{item.nome}}</span>
        <span *ngIf="item.badgeText && item.badgeType"
          class="pcoded-badge label label-{{ item.badgeType }}">{{item.badgeText}}</span>
        <span class="pcoded-mcaret"></span>
      </a>
    </li>

    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"
      *ngIf="item.tipoDeItem == 'menuLateral' && item.tipoDeLink == 'rota'" (click)=hideMenu(true)>

      <a [routerLink]="['/', item.rota]">
        <span class="pcoded-micon"><i [class]="item.icone"></i></span>
        <span class="pcoded-mtext">{{item.nome}}</span>
        <span *ngIf="item.badgeText && item.badgeType"
          class="pcoded-badge label label-{{ item.badgeType }}">{{item.badgeText}}</span>
        <span class="pcoded-mcaret"></span>
      </a>
    </li>

    <li class="pcoded-hasmenu" style="cursor: pointer;"
      *ngIf="item.tipoDeItem == 'menuLateral' && item.tipoDeLink == 'link'" (click)=hideMenu(true)>
      <a [routerLink]="item.rota">
        <span class="pcoded-micon"><i [class]="item.icone"></i></span>
        <span class="pcoded-mtext">{{item.nome}}</span>
        <span *ngIf="item.badgeText && item.badgeType"
          class="pcoded-badge label label-{{ item.badgeType }}">{{item.badgeText}}</span>
        <span class="pcoded-mcaret"></span>
      </a>
    </li>

  </ul>
</div>

<ng-template #elseBlock>
  <span *ngFor="let item of menuItens">
    <li *ngIf="item.tipoDeItem == 'subMenu'" class="pcoded-hasmenu" appAccordionLink style="cursor: pointer;"
      dropdown-icon="style3" subitem-icon="style6" group="sub-toggled" (click)=hideMenu(false)>
      <a appAccordionToggle href="javascript:;">
        <span class="pcoded-micon"><i [class]="item.icone"></i></span>
        <span class="pcoded-mtext">{{item.nome}}</span>
        <span *ngIf="item.badgeText && item.badgeType"
          class="pcoded-badge label label-{{ item.badgeType }}">{{item.badgeText}}</span>
        <span class="pcoded-mcaret"></span>
      </a>
      <app-menu-item class="pcoded-submenu"
        [ngClass]="{'pcoded-submenu-second': level == 1, 'pcoded-submenu-third': level == 2}"
        [menuItens]="item.filhos"
        [menuType]="'son'"
        [rotaPai]="montaRotaPai(item.rota)"
        (functionResponse)="executeFunction($event)"
        (hideMobileMenu)="hideMenu($event)"
        [level]="+level + 1">
      </app-menu-item>
    </li>

    <li [routerLinkActive]="['active']" *ngIf="item.tipoDeItem == 'menuLateral' && item.tipoDeLink == 'funcao'">
      <a href="javascript:;" (click)="executeFunction({funcao:item.rota, parametro:item.parametro})">
        <span class="pcoded-micon"><i [class]="item.icone"></i></span>
        <span class="pcoded-mtext">{{item.nome}}</span>
        <span *ngIf="item.badgeText && item.badgeType"
          class="pcoded-badge label label-{{ item.badgeType }}">{{item.badgeText}}</span>
        <span class="pcoded-mcaret"></span>
      </a>
    </li>

    <li [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}"
      *ngIf="item.tipoDeItem == 'menuLateral' && item.tipoDeLink == 'rota'" (click)=hideMenu(true)>

      <a [routerLink]="montarRota(item.rota)">
        <span class="pcoded-micon"><i [class]="item.icone"></i></span>
        <span class="pcoded-mtext">{{item.nome}}</span>
        <span *ngIf="item.badgeText && item.badgeType"
          class="pcoded-badge label label-{{ item.badgeType }}">{{item.badgeText}}</span>
        <span class="pcoded-mcaret"></span>
      </a>
    </li>

    <li class="pcoded-hasmenu" style="cursor: pointer;"
      *ngIf="item.tipoDeItem == 'menuLateral' && item.tipoDeLink == 'link'" (click)=hideMenu(true)>
      <a [href]="item.rota" target="item.parametro">
        <span class="pcoded-micon"><i [class]="item.icone"></i></span>
        <span class="pcoded-mtext">{{item.nome}}</span>
        <span *ngIf="item.badgeText && item.badgeType"
          class="pcoded-badge label label-{{ item.badgeType }}">{{item.badgeText}}</span>
        <span class="pcoded-mcaret"></span>
      </a>
    </li>
  </span>
</ng-template>
