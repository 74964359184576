import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ContasPagarService } from '../../../services/contas-pagar.service';

@Component({
  selector: 'app-selecionar-titulo-ofx',
  templateUrl: './selecionar-titulo-ofx.component.html',
  styleUrls: ['./selecionar-titulo-ofx.component.css']
})
export class SelecionarTituloOfxComponent extends ModalCadastroComponent implements OnInit {

  constructor(private contasPagarService: ContasPagarService) {
    super();
  }

  @ViewChild('modal', { static: true }) modal;
  filtroData = this.ListarTiposData();

  form = new FormGroup({
    valor: new FormControl(0, Validators.compose([Validators.required, Validators.min(0), Validators.max(100)])),
    data: new FormControl('E', Validators.required),
    descricao: new FormControl(''),
    valorTransacao: new FormControl(''),
  });

  titulos = [];
  tituloSelecionado = null;
  transacaoData = null;

  ngOnInit() {
  }

  editar(info) {
    this.transacaoData = info;
    this.modal.show();

    this.form.get('descricao').setValue(info.transacao.descricao);
    this.form.get('valorTransacao').setValue(info.transacao.valor);
    this.form.get('data').setValue(info.data);
    this.form.get('valor').setValue(info.valor);

    this.titulos = info.transacao.titulos;
    this.tituloSelecionado = info.transacao.tituloSelecionado;
  }

  ListarTiposData() {
    return [
      { id: 'E', descricao: 'Data exata' },
      { id: 'I', descricao: 'Data igual ou inferior' },
      { id: 'S', descricao: 'Data igual ou superior' }
    ];
  }

  submitForm() {
    const consulta = {
      contaCorrente: this.transacaoData.contaCorrente,
      transacoes: [this.transacaoData.transacao],
      variacaoValor: this.form.get('valor').value,
      tipoData: this.form.get('data').value,
    };

    this.loading['request'] = true;
    this.contasPagarService.BuscaTitulosFornecedorPorOfx(consulta)
      .subscribe(res => {
        const item = res[0];
        this.titulos = item.titulos;
        this.tituloSelecionado = item.tituloSelecionado;
        this.loading['request'] = false;
      });
  }

  select(titulo) {
    this.tituloSelecionado = titulo;
  }

  removeSeleted() {
    this.tituloSelecionado = null;
  }

  onClose() {
    const objeto = { titulos: this.titulos, tituloSelecionado: this.tituloSelecionado, index: this.transacaoData.transacao.index };
    this.closed.emit(objeto);
  }

}
