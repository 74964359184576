import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseServiceV2 } from '../base/base-service-v2';
import { ContasAReceberClienteViewModel } from 'models/clientes/ContasAReceberClienteViewModel';

@Injectable()
export class ClientesServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('Clientes', http);
  }

  /**
   * Busca as Contas a Receber vinculadas ao ID do Cliente informado.
   *
   * @param {number} clienteId - O ID do cliente para buscar as contas a receber.
   * @returns {Promise<ContasAReceberClienteViewModel>} - Retorna uma Promise que resolve para ContasAReceberClienteViewModel.
   */
  getContasReceber(clienteId: number) {
    return this.getAsPromise<ContasAReceberClienteViewModel>(`contasReceber/${clienteId}`);
  }

}
