import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UnidadesEmpresaService } from '../../../services/unidades-empresa.service';
import { MessageService } from '../../../services/message.service';
import { EstadosService } from '../../../services/estados.service';
import { Subscription } from 'rxjs';
import { UtilFunctions } from '../../../general/utils-service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-certificado-digital',
  templateUrl: './certificado-digital.component.html',
  styleUrls: ['./certificado-digital.component.css']
})
export class CertificadoDigitalComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalCertDigital', { static: true }) modalCertDigital;

  constructor(private unidadeEmpresaService: UnidadesEmpresaService,
    private estadoService: EstadosService, private message: MessageService,
    public utilFunctions: UtilFunctions) {
    super();
  }

  file: any = null;
  certificadoSelecionado = null;
  certificadoVencido = false;
  dataValidade = null;
  @ViewChild('abrirModal', { static: false }) abrirModal;
  nomeVideo = 'CadastrarCertificadoDigital';
  modalSubscription: Subscription;
  tempoVideo = 0;

  cadastroForm = new FormGroup({
    senha: new FormControl('', [Validators.required]),
    arquivo: new FormControl('', [Validators.required])
  });

  fileChanged(files) {
    this.file = files.length !== 0 ? files[0] : null;
    const elemento = this.cadastroForm.get('arquivo');
    elemento.setValue(this.file);
    elemento.markAsTouched();
  }

  ngOnInit() {
    this.alteraLoading(true);
    this.unidadeEmpresaService.getCertificado()
      .pipe(finalize(() => this.alteraLoading(false)))
      .subscribe(res => {
        this.dataValidade = new Date(res.validade);
        this.estadoService.getDateFuso().toPromise()
          .then(dataServidor => {
            if (this.dataValidade < new Date(dataServidor)) {
              this.certificadoVencido = true;
              this.certificadoSelecionado = res;
            } else {
              this.certificadoVencido = false;
              this.certificadoSelecionado = res;
            }
          });
      });
    this.verificaAbreModalAjuda();
  }

  show() {
    this.modalCertDigital.show();
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      if (!this.file.name.toLowerCase().endsWith('.pfx') && !this.file.name.toLowerCase().endsWith('.p12')) {
        this.errors = "Informar um arquivo no formato .pfx ou .p12";
        return;
      }

      this.errors = null;
      this.alteraLoading(true);
      const form = new FormData();
      form.append('arquivo', this.file);
      form.append('senha', this.cadastroForm.get('senha').value);
      this.unidadeEmpresaService.uploadCertificado(form)
        .pipe(finalize(() => { this.alteraLoading(false); }))
        .subscribe(() => {
          this.message.success('Certificado salvo com sucesso!');
          this.modalCertDigital.hide();
        }, (error) => {
          this.errors = error.msg.errors;
        });
    }
  }

  private verificaAbreModalAjuda() {
    if (this.nomeVideo.length > 0) {
      this.utilFunctions.verificaAbreVideoSozinho(this.nomeVideo)
        .then(res => {
          if (res) {
            this.abrirModalAjuda();
          }
        });
    }
  }

  abrirModalAjuda() {
    if (this.nomeVideo.length > 0) {
      this.modalSubscription = this.abrirModal.closed.subscribe(this.getTimeVideo.bind(this));
      this.abrirModal.abrir('exibirVideo', { video: this.nomeVideo, time: this.tempoVideo });
    }
  }

  getTimeVideo(value) {
    this.tempoVideo = value;
  }

}
