import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from "./base.service";
import { map, catchError } from 'rxjs/operators';


@Injectable()
export class UnidadesEmpresaParametrosNfceService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getParametros() {
        return this.http
            .get(this.UrlService + "unidadeEmpresaParametrosNfce")
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    salvarParametros(id: Number, form: any) {
        if (id > 0) {
            return this.put(form);
        } else {
            return this.post(form);
        }
    }

    getParametroPesquisaProduto() {
        return this.http
            .get(this.UrlService + "unidadeEmpresaParametrosNfce/parametroPesquisaProduto")
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    putParametrosPesquisaProduto(parametro: string) {
        return this.http.put(this.UrlService + 'UnidadeEmpresaParametrosNFCe/alterarParametroPesquisaProduto', JSON.stringify(parametro))
            .pipe(map(super.extractData)
                , catchError(super.serviceError))
    }

    private post(form) {
        return this.http.post(this.UrlService + 'UnidadeEmpresaParametrosNFCe/salvar', form)
            .pipe(map(super.extractData)
                , catchError(super.serviceError))
    }

    private put(form) {
        return this.http.put(this.UrlService + 'UnidadeEmpresaParametrosNFCe/alterar', form)
            .pipe(map(super.extractData)
                , catchError(super.serviceError))
    }

    getEnvioAutomaticoNFCe() {
        return this.http
            .get(this.UrlService + 'unidadeEmpresaParametrosNfce/getEnvioAutomaticoNFCe')
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }
}





