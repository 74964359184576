<ng-select [multiple]="true" [items]="statusOptions" [disabled]="disabled" [ngModel]="status"
  placeholder="Selecione status" bindLabel="name" bindValue="id" [closeOnSelect]="false"
  (ngModelChange)="onChange($event)" [appendTo]="appendTo">
  <ng-template ng-header-tmp>
    <button (click)="selectAll()" type="button" class="btn btn-sm btn-secondary">Selecionar
      todos</button>
    <button (click)="clean('status')" type="button" class="btn btn-sm btn-secondary">Nenhum</button>
  </ng-template>
  <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">

    <div class="ng-value" *ngFor="let item of (items && items.length <= 3 ? items.slice(0,3): [])">
      <span class="ng-value-label"> {{item.name}}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
    </div>

    <div class="ng-value" *ngIf="items.length > 3">
      <span *ngIf="items.length == statusOptions.length; else selecionados" class="ng-value-label">Todos
        selecionados</span>
      <ng-template #selecionados>
        <span class="ng-value-label">{{items.length}} selecionados</span>
      </ng-template>
    </div>
  </ng-template>
</ng-select>