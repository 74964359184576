import { Component, OnInit, ViewChild, Input, forwardRef, Output, EventEmitter, TemplateRef, ContentChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap, switchMap, finalize } from 'rxjs/operators';
import { FornecedoresService } from '../../services/fornecedores.service';
import { ClientesService } from '../../services/clientes.service';
import { EventosContabeisService } from '../../services/eventos-contabeis.service';
import { FormGroup, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { mergeArray, fieldRequired, getErrorMessage } from '../../general/utils';
import { Subject, Subscription } from 'rxjs';
import { ContasContabeisService } from '../../services/contas-contabeis.service';
import { HistoricosContabeisPadraoService } from '../../services/historicos-contabeis-padrao.service';
import { MotivosMovimentacaoEstoqueService } from '../../services/motivo-movimentacao-estoque.service';
import { ItensService } from '../../services/itens.service';
import { AlmoxarifadosService } from '../../services/almoxarifados.service';
import { UnidadesMedidaService } from '../../services/unidades-medida.service';
import { TipoDespesaService } from '../../services/tipo-despesa.service';
import { ContascorrentesService } from '../../services/contascorrentes.service';
import { RepresentantesService } from '../../services/representantes.service';
import { Representante } from '../../model/RepresentantesViewModel';
import { CondicoesPagamentoService } from '../../services/condicoes-pagamento.service';
import { TipoComissaoService } from '../../services/tipo-comissao.service';
import { UsuariosService } from '../../services/usuarios.service';
import { AbrirModalComponent } from '../../shared/abrir-modal/abrir-modal.component';
import { LinhasProdutosService } from './../../services/linhas-produtos.service';
import { ClassificacoesFiscaisService } from '../../services/classificacoes-fiscais.service';
import { AliquotasIcmsService } from '../../services/aliquotasIcms.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CentrosCustosService } from '../../services/centros-custos.service';
import { BancosService } from '../../services/bancos.service';
import { PortadorService } from '../../services/portador.service';
import { TipoNfeService } from '../../services/tipo-nfe.service';
import { CodigosFiscaisOperacaoService } from '../../services/codigos-fiscais-operacao.service';
import { SeriesAlternativasService } from '../../services/series-alternativas.service';
import { ItensFornecedoresService } from '../../services/itens-fornecedores.service';
import { TipoItensService } from '../../services/tipo-itens.service';
import { IntermediadorService } from '../../services/intermediador.service';
import { CodigosFiscaisService } from '../../services/codigos-fiscais.service';
import { AdminService } from '../../admin/services/admin.service';
import { StorageFunctions } from '../../general/storage';
import { IntermediadorRecebedorService } from '../../services/intermediador-recebedor.service';
import { NotasFiscaisEnderecosService } from '../../services/notas-fiscais-enderecos.service';
import { ProcessoImportacaoService } from '../../services/processo-importacao.service';
import { UnidadesConsumoService } from '../../services/unidades-consumo.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'div[combobox]',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxComponent),
      multi: true
    }
  ]
})
export class ComboboxComponent implements OnInit, ControlValueAccessor {

  comboInputs = {};
  constructor(private fornecedoresService: FornecedoresService,
    private clientesService: ClientesService,
    private eventosContabeisService: EventosContabeisService,
    private contasContabeisService: ContasContabeisService,
    private historicosContabeisPadraoService: HistoricosContabeisPadraoService,
    private motivosMovimentacaoEstoqueService: MotivosMovimentacaoEstoqueService,
    private itensService: ItensService,
    private almoxarifadosService: AlmoxarifadosService,
    private unidadesMedidaService: UnidadesMedidaService,
    private tipoDespesaService: TipoDespesaService,
    private contascorrentesService: ContascorrentesService,
    private repService: RepresentantesService,
    private condicaoPagamentoService: CondicoesPagamentoService,
    private intermediadorService: IntermediadorService,
    private tipoComissaoService: TipoComissaoService,
    private linhasProdutosService: LinhasProdutosService,
    private usuariosService: UsuariosService,
    private classificacoesFiscaisService: ClassificacoesFiscaisService,
    private aliquotasIcmsService: AliquotasIcmsService,
    private centrosCustosService: CentrosCustosService,
    private bancosService: BancosService,
    private portadorService: PortadorService,
    private codigosFiscaisOperacaoService: CodigosFiscaisOperacaoService,
    private codigosFiscaisService: CodigosFiscaisService,
    private seriesAlternativasService: SeriesAlternativasService,
    private tipoNfeService: TipoNfeService,
    private tipoItemService: TipoItensService,
    private itensFornecedoresService: ItensFornecedoresService,
    private intermediadorRecebedorService: IntermediadorRecebedorService,
    private notasFiscaisEnderecosService: NotasFiscaisEnderecosService,
    private unidadesConsumoService: UnidadesConsumoService,
    private storage: StorageFunctions,
    private adminService: AdminService,
    private processoImportacaoService: ProcessoImportacaoService,
  ) {
    this.comboInputs = {
      'fornecedor': {
        input: 'Fornecedor',
        nomeAtributo: 'nome',
        checkbox: { enable: false, value: false, text: 'Busca Parcial', action: inputText => this.funcaoExemploFornecedor(inputText) },
        modalCadastro: { enable: true, name: 'fornecedor' },
        selectOptions: {
          enable: true, multiple: true, lastValue: 0,
          options: [
            { attr: 'nome', value: 'Razão Social', checked: true },
            { attr: 'nomeFantasia', value: 'Nome Fantasia', checked: false },
            { attr: 'cnpj', value: 'CNPJ', checked: false }
          ],
          action: inputText => this.alteraAtributoFornecedor(inputText)
        },
        labelWithSelectValue: false,
        onSearch: inputText => this.onSearchFornecedores(),
        search: inputText => this.fetchFornecedores(inputText),
        carregaItemSelecionado: id => this.carregaFornecedorSelecionado(id)
      },
      'cliente': {
        input: 'Cliente',
        nomeAtributo: 'razaoSocial',
        desabilitarAoCarregar: false,
        checkbox: { enable: false, value: false, text: 'Busca Parcial', action: null },
        modalCadastro: { enable: true, name: 'cliente' },
        selectOptions: {
          enable: true, multiple: true, lastValue: 0,
          options: [
            { attr: 'razaoSocial', value: 'Razão Social', checked: true, format: '{id} - {razaoSocial}' },
            { attr: 'nomeFantasia', value: 'Nome Fantasia', checked: false, format: '{id} - {nomeFantasia}' },
            { attr: 'cnpj', value: 'CNPJ', checked: false },
          ],
          action: inputText => this.alteraAtributoCliente(inputText)
        },
        labelWithSelectValue: false,
        onSearch: inputText => this.onSearchClientes(),
        search: inputText => this.fetchClientes(inputText),
        carregaItemSelecionado: id => this.carregaClientesSelecionado(id)
      },
      'eventos-contabeis': {
        input: 'Evento contábil',
        nomeAtributo: '',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: null },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchEventosContabeis(),
        search: inputText => this.fetchEventosContabeis(inputText),
        carregaItemSelecionado: id => this.carregaEventoContabilSelecionada(id),
        alterDescription: true,
        newItemDescription: item => this.eventosContabeisDescription(item)
      },
      'contas-contabeis': {
        input: 'Conta Contábil',
        nomeAtributo: 'nomeConta',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: null },
        selectOptions: {
          enable: true, multiple: true, lastValue: 0,
          options: [
            { attr: 'nomeConta', value: 'Nome', checked: true, format: '{codigoSimplificado} - {nomeConta}' },
            { attr: 'codigoSimplificado', value: 'Código Simplificado', checked: false, format: '{codigoSimplificado} - {nomeConta}' },
            { attr: 'contaContabil', value: 'Nome da Conta', checked: false, format: '{codigoSimplificado} - {contaContabil}' },
          ],
          action: inputText => this.alteraAtributoContasContabeis(inputText)
        },
        labelWithSelectValue: false,
        onSearch: inputText => this.onSearchContasContabeis(),
        search: inputText => this.fetchContasContabeis(inputText),
        carregaItemSelecionado: id => this.carregaContaContabilSelecionada(id),
      },
      'historicos-contabeis': {
        input: 'Histórico Contábil',
        nomeAtributo: '',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: null },
        selectOptions: {
          enable: true, multiple: true, lastValue: 0,
          options: [
            { attr: 'descricao', value: 'Descrição', checked: true, format: '{codigoHistorico} - {descricao}' },
            { attr: 'codigoHistorico', value: 'Código', checked: false, format: '{codigoHistorico} - {descricao}' },
          ],
          action: inputText => this.alteraAtributoHistoricoContabil(inputText)
        },
        labelWithSelectValue: false,
        onSearch: inputText => this.onSearchHistoricosContabeis(),
        search: inputText => this.fetchHistoricosContabeis(inputText),
        carregaItemSelecionado: id => this.carregaHistoricosContabilSelecionado(id),
      },
      'motivo-mov-estoque': {
        input: 'Motivo',
        nomeAtributo: 'nome',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'motivoMovimentacaoEstoque' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchMotivoMovEstoque(),
        search: inputText => this.fetchMotivoMovEstoque(inputText),
        carregaItemSelecionado: id => this.carregaMotivoMovEstoqueSelecionado(id)
      },
      'produto': {
        input: 'Produto',
        nomeAtributo: 'nome',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: '' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchProduto(),
        search: inputText => this.fetchProduto(inputText),
        carregaItemSelecionado: id => this.carregaProdutoSelecionado(id)
      },
      'servico': {
        input: 'Serviço',
        nomeAtributo: 'nome',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'cadastroServico' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchServico(),
        search: inputText => this.fetchServico(inputText),
        carregaItemSelecionado: id => this.carregaServicoSelecionado(id)
      },
      'itens-fornecedores': {
        input: 'ItemFornecedor',
        nomeAtributo: 'nome',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: '' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchItemFornecedor(),
        search: inputText => this.fetchItemFornecedor(inputText),
        carregaItemSelecionado: id => this.carregaItemFornecedorSelecionado(id)
      },
      'almoxarifado': {
        input: 'Almoxarifado',
        nomeAtributo: 'nome',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'almoxarifado' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchAlmoxarifados(),
        search: inputText => this.fetchAlmoxarifados(inputText),
        carregaItemSelecionado: id => this.carregaAlmoxarifadosSelecionado(id)
      },
      'unidade-medida': {
        input: 'Unidade Medida',
        nomeAtributo: '',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: null },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchUnidadeMedida(),
        search: inputText => this.fetchUnidadeMedida(inputText),
        carregaItemSelecionado: id => this.carregaUnidadeMedidaSelecionado(id),
        alterDescription: true,
        newItemDescription: item => this.unidadeMedidaDescription(item)
      },
      'tipo-despesa': {
        input: 'Tipo de Despesas',
        nomeAtributo: 'descricao',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: null },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchTipoDespesa(),
        search: inputText => this.fetchTipoDespesa(inputText),
        carregaItemSelecionado: id => this.carregaTipoDespesaSelecionado(id)
      },
      'contas-corrente': {
        input: 'Conta Corrente',
        nomeAtributo: 'descricao',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'contaCorrente' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchContaCorrente(),
        search: inputText => this.fetchContaCorrente(inputText),
        carregaItemSelecionado: id => this.carregaContaCorrenteSelecionado(id),
      },
      'vendedor': {
        input: 'Vendedor',
        nomeAtributo: '',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'representante' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchVendedor(),
        search: inputText => this.fetchVendedor(inputText),
        carregaItemSelecionado: id => this.carregaVendedorSelecionado(id),
        alterDescription: true,
        newItemDescription: (item: Representante) => this.vendedorAtivoInativoDescription(item)
      },
      'condicao-pagamento': {
        input: 'Prazo de pagamento',
        nomeAtributo: 'nome',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'condicaoPagamento' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchCondicaoPagamento(),
        search: inputText => this.fetchCondicaoPagamento(inputText),
        carregaItemSelecionado: id => this.carregaCondicaoPagamentoSelecionado(id)
      },
      'intermediador': {
        input: 'Intermediador',
        nomeAtributo: 'tag',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'intermediadorCadastro' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchIntermediador(),
        search: inputText => this.fetchIntermediador(inputText),
        carregaItemSelecionado: id => this.carregaIntermediadorSelecionado(id)
      },
      'tipo-comissao': {
        input: 'Tipo de comissão',
        nomeAtributo: 'descricao',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'tipoComissao' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchTipoComissao(),
        search: inputText => this.fetchTipoComissao(inputText),
        carregaItemSelecionado: id => this.carregaTipoComissaoSelecionado(id)
      },
      'usuario-vendedor': {
        input: 'Usuário',
        nomeAtributo: 'nome',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: '' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchUsuarioVendedor(),
        search: inputText => this.fetchUsuarioVendedor(inputText),
        carregaItemSelecionado: id => this.carregaUsuarioVendedorSelecionado(id)
      },
      'linha-produto': {
        input: 'Linha de Produto',
        nomeAtributo: 'descricao',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: '' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchLinhaDeProduto(),
        search: inputText => this.fetchLinhaDeProduto(inputText),
        carregaItemSelecionado: id => this.carregaLinhaDeProdutoSelecionado(id)
      },
      'classificacao-fiscal': {
        input: 'NCM',
        nomeAtributo: 'classificacaoDescricao',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'cadastroClassificacaoFiscal' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: () => this.onSearchClassificacoesFiscais(),
        search: inputText => this.fetchClassificacoesFiscais(inputText),
        carregaItemSelecionado: id => this.carregaClassificacaoFiscalSelecionado(id)
      },
      'aliquotaIcms': {
        input: 'Alíquota ICMS',
        nomeAtributo: 'aliquotaICMS',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'aliquotaIcms' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: () => this.onSearchAliquotaIcms(),
        search: inputText => this.fetchAliquotaIcms(inputText),
        carregaItemSelecionado: id => this.carregaAliquotaIcmsSelecionado(id)
      },
      'unidadesEmpresa': {
        input: 'Empresa',
        nomeAtributo: 'nome',
        checkbox: { enable: false, value: false, text: 'Busca Parcial', action: null },
        modalCadastro: { enable: false, name: '' },
        selectOptions: {
          enable: true, multiple: true, lastValue: 0,
          options: [
            { attr: 'nome', value: 'Razão Social', checked: true, format: '{id} - {nome}' },
            { attr: 'nomeFantasia', value: 'Nome Fantasia', checked: false, format: '{id} - {nomeFantasia}' },
            { attr: 'cnpj', value: 'CNPJ', checked: false, format: '{cnpj} - {nome}' },
          ],
          action: inputText => this.alteraAtributoUnidadeEmpresa(inputText)
        },
        onSearch: inputText => this.onSearchUnidadesEmpresa(),
        search: inputText => this.fetchUnidadesEmpresa(inputText),
        carregaItemSelecionado: id => this.carregaUnidadesEmpresaSelecionado(id)
      },
      'centroCustos': {
        input: 'Centro de Custo',
        nomeAtributo: 'ccus',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'centroCusto' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: () => this.onSearchCentroDeCustos(),
        search: inputText => this.fetchCentroDeCustos(inputText),
        carregaItemSelecionado: id => this.carregaCentroDeCustosSelecionado(id)
      },
      'bancos': {
        input: 'Banco',
        nomeAtributo: 'nome',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: '' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: () => this.onSearchBancos(),
        search: inputText => this.fetchBancos(inputText),
        carregaItemSelecionado: id => this.carregaBancosSelecionado(id)
      },
      'forma-pagamento-portador': {
        input: 'Forma de Pagamento (Portador)',
        nomeAtributo: 'descricao',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'portador' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: () => this.onSearchFormaPagamentoPortador(),
        search: inputText => this.fetchFormaPagamentoPortador(inputText),
        carregaItemSelecionado: id => this.carregaFormaPagamentoPortadorSelecionado(id)
      },
      'tipo-nfe': {
        input: 'Tipo de Operação',
        nomeAtributo: 'nome',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: null },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: () => this.onSearchTipoNfe(),
        search: inputText => this.fetchTipoNfe(inputText),
        carregaItemSelecionado: id => this.carregaTipoNfeSelecionado(id)
      },
      'tipo-item': {
        input: 'Tipo Item',
        nomeAtributo: 'nome',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: null },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: () => this.onSearchTipoItem(),
        search: inputText => this.fetchTipoItem(inputText),
        carregaItemSelecionado: id => this.carregaTipoItemSelecionado(id)
      },
      'codigos-fiscais-operacao': {
        input: 'Códigos Fiscais - Operação',
        nomeAtributo: 'descricao',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: '' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: () => this.onSearchCodigosFiscaisOperacao(),
        search: inputText => this.fetchCodigosFiscaisOperacao(inputText),
        carregaItemSelecionado: id => this.carregaCodigoFiscalOperacaoSelecionado(id)
      },
      'series-alternativas': {
        input: 'Séries Alternativas',
        nomeAtributo: 'serieAlternativa',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'serie-alternativa' },
        format: '{1} ({2})',
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: i => null
        },
        onSearch: () => this.onSearchSeriesAlternativas(),
        search: inputText => this.fetchSeriesAlternativas(inputText),
        carregaItemSelecionado: id => this.carregaSerieAlternativaSelecionada(id)
      },
      'codigos-fiscais': {
        input: 'Códigos Fiscais',
        nomeAtributo: 'codigo',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: '' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: () => this.onSearchCodigosFiscais(),
        search: inputText => this.fetchCodigosFiscais(inputText),
        carregaItemSelecionado: id => this.carregaCodigoFiscalSelecionado(id)
      },
      'transportador': {
        input: 'Fornecedor/Transportador',
        nomeAtributo: 'nome',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'fornecedor' },
        selectOptions: {
          enable: true, multiple: false, lastValue: 0,
          options: [{ value: 'Razão Social', checked: false }, { value: 'Nome Fantasia', checked: false }],
          action: inputText => this.alteraAtributoTransportador(inputText)
        },
        labelWithSelectValue: true,
        onSearch: inputText => this.onSearchTransportadores(),
        search: inputText => this.fetchTransportadores(inputText),
        carregaItemSelecionado: id => this.carregaFornecedorSelecionado(id) // Sim, está correto.
      },
      'intermediador-recebimentos': {
        input: 'Intermediador de Recebimento',
        nomeAtributo: 'nome',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: false, name: '' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: () => this.onSearchIntermediadorRecebimentos(),
        search: inputText => this.fetchIntermediadorRecebimentos(inputText),
        carregaItemSelecionado: id => this.carregaIntermediadorRecebimentosSelecionado(id)
      },
      'endereco-retirada': {
        input: 'Endereço de Retirada',
        nomeAtributo: '',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'modalEnderecoRetirada' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchEnderecoRecebimento(),
        search: inputText => this.fetchEnderecoRecebimento(inputText),
        carregaItemSelecionado: id => this.carregaEnderecoRecebimentoSelecionado(id),
        alterDescription: true,
        newItemDescription: item => this.enderecoRetiradaDescription(item)
      },
      'processo-importacao': {
        input: 'Processo de Importação',
        nomeAtributo: 'processoDeImportacao',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'processoImportacao' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchProcessoImportacao(),
        search: inputText => this.fetchProcessoImportacao(inputText),
        carregaItemSelecionado: id => this.carregaProcessoImportacaoSelecionado(id),
        alterDescription: true,
        newItemDescription: item => this.processoImportacaoDescription(item)
      },
      'unidades-consumo': {
        input: 'Unidade de Consumo',
        nomeAtributo: 'descricao',
        checkbox: { enable: false, value: false, text: '', action: null },
        modalCadastro: { enable: true, name: 'unidadesConsumo' },
        selectOptions: {
          enable: false, multiple: false, lastValue: 0, options: [{}],
          action: inputText => null
        },
        onSearch: inputText => this.onSearchUnidadesConsumo(),
        search: inputText => this.fetchUnidadesConsumo(inputText),
        carregaItemSelecionado: id => this.carregaUnidadesConsumoSelecionado(id)
      },
    };
  }

  // Parâmetros Obrigatórios
  @Input() type;              // Tipo do campo(fornecedor, cliente, etc...)
  @Input() loading = [];     // Váriavel de loading da tela que está chamando o componente sempre chamar no formato [(loading)]="loading",
  // o campo também é desabilitado a partir do 'formControlName' por controle interno do componente, e alem disso
  // é desabilitado também com os seguintes loadings: loading['disable'] ou loading['request'] ou pela variavel disableControl

  // Parâmetros Opicionais
  @Input() label: String;    /* Nome que irá aparecer em cima do comboBox,
  Caso não informado mostra o atributo input das configurações do combo */
  @Input() showLabel = true; // Mostra ou não a label
  @Input() checkbox = true;  /* Mostra ou não o checkbox, por padrão mostrar,
  é verificado também se nas configurações do combo está habilitado o checkbox */
  @Input() placeholder = 'Selecione'; /* Label interno do campo quando nenhum valor está selecionado */
  @Input() selectOptions = true; /* Mostra ou não o seletor de opções, por padrão mostrar,
  é verificado tambem se nas configurações do combo está habilitado o seletor de opções */
  @Input() modalCadastro = false; // Mostra ou não o modal de cadastro e de editar itens no combo, por padrão não mostrar
  @Input() selectOpeningModalCadastro = true; // Irá selecionar o item ao clicar no botão do modal, por padrão selecionar
  @Input() multiple = false;       // Multipla seleção de itens, por padrão apenas 1 item
  @Input() disableControl = false; // Desabilitado, padrão não desabilitado
  @Input() deboucingTime = 1000; // Desabilitado, padrão não desabilitado
  @Input() parametro: any; // Parametro para funções diferentes de acordo com o combo, tratar quando usar para não ser obrigatório
  @Input() appendTo = 'body';
  @Input() form: FormGroup;         // Form da tela que está chamando o combo
  @Input() formControlName: string; // Nome do campo dentro do form
  @Input() grupo: string;   // Caso Esteja dentro de formGroup informar o campo
  @Input() value: any = null; // Caso não utilize form utilizar o value
  @Input() name: string; // Caso usar o value, obrigatóriamente deve ser informado o nome do campo
  @Input() nfe = false; // Flag de campo para nfe, caso for ativada classe de tratamento de layout
  @Input() closeOnSelect = true; // Ao selecionar um item, quando <<[multiple]="true">>, essa flag definirá se o combobox será fechado ou não.
  @Input() footerActions = false;
  @Input() readonly = false;
  @Input() toolTipMessage: string = null;
  @Input() tooltip: false;
  @Input() autofocus: false;
  @Input() startOpen: false;
  private modalSubscription: Subscription;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() change = new EventEmitter();

  @ContentChild('descTmpl', { static: false }) descTmpl: TemplateRef<any>;

  @ViewChild('abrirModal', { static: false }) abrirModal: AbrirModalComponent;
  @ViewChild('select', { static: false }) select: NgSelectComponent;

  inputOnSearch = new Subject<string | void>();
  disabled = false;
  itens: any[] = [];
  public backAllow = true;

  updateValue(value) {
    this.value = value;
    this.valueChange.emit(value);
  }

  ngOnInit() {
    if (localStorage.getItem('combobox-' + this.type + '-' + this.formControlName) === 'true') {
      this.comboInputs[this.type].checkbox.value = true;
    } else {
      this.comboInputs[this.type].checkbox.value = false;
    }
    this.comboInputs[this.type].search('');
    this.comboInputs[this.type].onSearch();
    if (this.autofocus) {
      setTimeout(() => {
        this.select.focus();
      }, 100);
    }

    if (this.startOpen) {
      setTimeout(() => {
        this.select.focus();
        this.select.open();
      }, 400);
    }
  }

  get classNfe() {
    if (this.nfe) { return 'nfe sub-title'; }
    return '';
  }

  blurSelect() {
    this.select.element.querySelector('input').blur();
  }

  getLabel() {
    let label = this.label || this.comboInputs[this.type].input;
    if (this.comboInputs[this.type].labelWithSelectValue) {
      label += ' (' + this.comboInputs[this.type].selectOptions.options[this.comboInputs[this.type].selectOptions.lastValue].value + ')';
    }
    return label;
  }

  itemDescription(item) {
    if (this.comboInputs[this.type].alterDescription && !(this.comboInputs[this.type].newItemDescription(item).includes('undefined'))) {
      return this.comboInputs[this.type].newItemDescription(item);
    }

    const label = this.retornaDescricao(item);

    return label || 'Carregando';
  }

  retornaDescricao(item) {
    if (this.comboInputs[this.type].selectOptions.multiple) {
      const labels = this.retornaCheckedSelectOptions().map(checked => item[checked.attr]);

      if (labels && labels.length === 1) {
        const options = this.retornaCheckedSelectOptions();
        if (options && options.length > 0) {
          let format = options[0].format;

          if (format) {
            const keys = (format as string).match(/{.+?}/gi);

            keys.forEach(key => {
              const value = item[key.replace(/[{}]/gi, '')];
              format = format.replace(key, value);
            });

            return format;
          }
        }
      }

      if (labels && labels.length > 0) {
        return labels.join(' - ');
      }
    } else {
      const options = this.comboInputs[this.type].selectOptions.options;
      let format = options[this.comboInputs[this.type].selectOptions.lastValue || 0].format;

      if (format) {
        for (let i = 0; i < options.length; i++) {
          const attrValue = item[options[i].attr];
          format = format.replace(`{${i}}`, attrValue || '');
        }

        return format;
      } else if (this.comboInputs[this.type].format) {
        let formatString = this.comboInputs[this.type].format;
        const keys = (formatString as string).match(/{.+?}/gi);
        keys.forEach(key => {
          const index = Number(key.replace('{', '').replace('}', ''));
          if (index >= 0 && index < Object.values(item).length) {
            const prop = Object.values(item)[index];
            formatString = formatString.replace(key, prop === 0 ? '0' : (prop || ''));
          }
        });

        return formatString;
      } else if (this.comboInputs[this.type].formatString) {
        // Para formatos: {id} - {nome}
        let formatString = this.comboInputs[this.type].formatString;
        const keys = formatString.match(/{.+?}/gi);

        keys.forEach(key => {
          const keyName = key.replace('{', '').replace('}', '');
          if (item.hasOwnProperty(keyName)) {
            const value = item[keyName];
            formatString = formatString.replace(key, value);
          }
        });

        return formatString;
      }
    }

    return item[this.comboInputs[this.type].nomeAtributo];
  }

  abreModalCadastro() {
    if (this.modalCadastro && this.comboInputs[this.type]
      && this.comboInputs[this.type].modalCadastro
      && this.comboInputs[this.type].modalCadastro.enable
    ) {
      return true;
    }
    return false;
  }

  mostraCheckBox() {
    if (this.comboInputs[this.type].checkbox.enable
      && this.checkbox
    ) {
      return true;
    }
    return false;
  }

  mostraSelectOptions() {
    if (this.comboInputs[this.type].selectOptions.enable
      && this.selectOptions
    ) {
      return true;
    }
    return false;
  }

  retornaCheckedSelectOptions() {
    return this.comboInputs[this.type].selectOptions.options.filter(x => x.checked);
  }

  subscribeModal() {
    this.modalSubscription = this.abrirModal.closed.subscribe(this.modalClosed.bind(this));
  }

  modalClosed(id) {
    this.modalSubscription.unsubscribe();
    this.comboInputs[this.type].search('');
    if (id) {
      this.onChange({ id }, true);
      this.writeValue(id);
    }
  }

  fieldRequired(campo) {
    if (!this.existsForm) { return; }
    let newCampo = campo;
    if (this.grupo) {
      newCampo = this.grupo + '.' + campo;
    }
    return fieldRequired(this.form, newCampo);
  }

  get existsForm() {
    return this.form != null && this.form !== undefined
      ? true : false;
  }

  carregaItemSelecionado(id) {
    this.comboInputs[this.type].carregaItemSelecionado(id);
  }

  alteraCheckBox(event) {
    this.comboInputs[this.type].checkbox.value = !this.comboInputs[this.type].checkbox.value;

    const busca = event.target.checked + '';
    localStorage.removeItem('combobox-' + this.type + '-' + this.formControlName);
    localStorage.setItem('combobox-' + this.type + '-' + this.formControlName, busca);

    if (this.comboInputs[this.type].checkbox.action) {
      this.comboInputs[this.type].checkbox.action();
    }
  }

  alteraSelectOptions(event, indexOption) {
    // Ao digitar uma palavra, trocar a opção do filtro e digitar a mesma palavra, não estava fazendo a busca.
    // Temos que limpar o input para a próxima palavra digitada ser pesquisada corretamente.
    this.inputOnSearch.next();

    this.comboInputs[this.type].selectOptions.lastValue = indexOption;

    if (this.comboInputs[this.type].selectOptions.multiple) {
      const checked = this.comboInputs[this.type].selectOptions.options[indexOption].checked;

      if (!(checked && this.retornaCheckedSelectOptions().length == 1)) {
        this.comboInputs[this.type].selectOptions.options[indexOption].checked = !checked;
      }
    }

    if (this.comboInputs[this.type].selectOptions.action) {
      this.comboInputs[this.type].selectOptions.action(indexOption);
    }
  }

  verificaSelectOptionMarcado(opcao, index) {
    if (!this.comboInputs[this.type].selectOptions.multiple && this.comboInputs[this.type].selectOptions.lastValue === index) {
      return true;
    }

    if (this.comboInputs[this.type].selectOptions.multiple && opcao.checked) {
      return true;
    }

    return false;
  }

  getItemName(item) {
    if (item[this.comboInputs[this.type].nomeAtributo].length > 17) {
      return item[this.comboInputs[this.type].nomeAtributo].substr(0, 17) + '...';
    }
    return item[this.comboInputs[this.type].nomeAtributo];
  }

  selectAll(): void {
    this.updateValue(this.itens.map(e => e.id));
    this.propagateChange(this.value);
    this.change.emit(this.value);
  }

  clean(): void {
    this.updateValue(null);
  }

  onChange = (event, force = false) => {
    let value = null;
    document.body.focus();
    if (event && event.id) {
      value = event.id;
    } else if (event instanceof Array) {
      value = this.value;
    }

    const updateValue = () => {
      this.updateValue(value);
      this.propagateChange(value);
      this.change.emit(event);
    }

    if (this.selectOpeningModalCadastro) {
      updateValue();
      return;
    }

    if (!this.modalSubscription?.closed && !force) {
      updateValue();
      return;
    }

    if (!this.modalSubscription?.closed || !force) {
      return;
    }

    updateValue();
  }

  carregouSelecionado(item) {
    this.onChange(item);
    this.itens.unshift(item);
    this.itens = mergeArray(this.itens);
  }

  writeValue(newValue): void {
    this.updateValue(newValue);
    if (newValue) {
      this.comboInputs[this.type].carregaItemSelecionado(newValue);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private propagateChange = (_: any) => { };

  onTouched = () => { };

  newFocus() {
    setTimeout(function () {
      const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
      if (inputFocus) {
        inputFocus.focus();
      }
      this.backAllow = true;
    }.bind(this), 100);
  }

  pressBackspace(e) {
    if ((e.key === 'Backspace' || e.which === 8) && this.backAllow === false) {
      e.preventDefault();
    }
  }

  //////////////  INICIO FUNÇÕES COMBO FORNECEDOR  //////////////
  loadSelectedOptionsFornecedor() {
    const optionsStorage = this.storage.getComboboxStorageFilters(this.type);
    if (optionsStorage) this.comboInputs[this.type].selectOptions.options = optionsStorage;
  }

  fetchFornecedores(term = '') {
    term = term.replace(/'/g, "''"); // replace ' por '' para o OData
    this.loading[this.formControlName] = true;

    const selectedOptions = this.getSelectOptionsChecked(this.type);
    this.fornecedoresService.getFornecedoresPaginadoCombobox(((this.itens.length / 20) + 1), term, selectedOptions)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchFornecedores() {
    this.loadSelectedOptionsCliente();
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        const selectedOptions = this.getSelectOptionsChecked(this.type);
        return this.fornecedoresService.getFornecedoresPaginadoCombobox(1, term || '', selectedOptions).pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaFornecedorSelecionado(fornecedorId) {
    if (fornecedorId) {
      this.fornecedoresService.getFornecedorById(fornecedorId)
        .subscribe({
          next: (response: any) => {
            if (!response.nomeFantasia) {
              response.nomeFantasia = "Sem Nome Fantasia"
            }
            this.carregouSelecionado(response);
          }
        });
    }
  }

  alteraAtributoFornecedor(opcao = 0) {
    switch (opcao) {
      case 0:
        this.comboInputs[this.type].nomeAtributo = 'nome';
        break;
      case 1:
        this.comboInputs[this.type].nomeAtributo = 'nomeFantasia';
        break;
      case 2:
        this.comboInputs[this.type].nomeAtributo = 'cnpj';
        break;
    }

    this.storage.setComboboxStorageFilters(this.type, this.comboInputs[this.type].selectOptions.options);
    this.itens = [];
    this.fetchFornecedores();
  }

  funcaoExemploFornecedor(parametro = null) {
    // Lógica de quando marcar o check o que deve acontecer
  }
  //////////////  FIM FUNÇÕES COMBO FORNECEDOR  //////////////

  //////////////  INICIO FUNÇÕES COMBO CLIENTE  //////////////
  loadSelectedOptionsCliente() {
    const optionsStorage = this.storage.getComboboxStorageFilters(this.type);
    if (optionsStorage) this.comboInputs[this.type].selectOptions.options = optionsStorage;
  }

  fetchClientes(term = '') {
    if (term) term = term.replace(/'/g, "''"); // replace ' por '' para o OData
    this.loading[this.formControlName] = true;

    const selectable = this.comboInputs[this.type].selectOptions.enable;

    let selectedOptions = [];

    if (selectable) {
      selectedOptions = this.comboInputs[this.type].selectOptions.options.filter(x => x.checked);
    }

    this.clientesService.getClientesPaginadoMultiplosFiltrosOData(term, selectedOptions, this.itens.length)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response.value);
      });
  }

  onSearchClientes() {
    this.loadSelectedOptionsCliente();
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        if (term) term = term.replace(/'/g, "''"); // replace ' por '' para o OData
        const selectable = this.comboInputs[this.type].selectOptions.enable;

        let selectedOptions = [];

        if (selectable) {
          selectedOptions = this.comboInputs[this.type].selectOptions.options.filter(x => x.checked);
        }

        return this.clientesService.getClientesPaginadoMultiplosFiltrosOData(term || '', selectedOptions, 0).pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data.value;
      this.newFocus();
    });
  }

  carregaClientesSelecionado(id) {
    if (id) {
      this.clientesService.getClienteById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  alteraAtributoCliente(opcao = 0) {
    switch (opcao) {
      case 0:
        this.comboInputs[this.type].nomeAtributo = 'razaoSocial';
        break;
      case 1:
        this.comboInputs[this.type].nomeAtributo = 'nomeFantasia';
        break;
      case 2:
        this.comboInputs[this.type].nomeAtributo = 'cnpj';
        break;
      default:
        this.comboInputs[this.type].nomeAtributo = 'nomeFantasia';
        break;
    }

    this.storage.setComboboxStorageFilters(this.type, this.comboInputs[this.type].selectOptions.options);
    this.itens = [];
    this.fetchClientes();
  }
  //////////////  FIM FUNÇÕES COMBO CLIENTE  //////////////

  //////////////  INICIO FUNÇÕES COMBO EVENTOS CONTÁBEIS  //////////////
  fetchEventosContabeis(term = '') {
    this.loading[this.formControlName] = true;

    this.eventosContabeisService.getEventosContabeis(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchEventosContabeis() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.eventosContabeisService.getEventosContabeis(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaEventoContabilSelecionada(id) {
    if (id) {
      this.eventosContabeisService.getEventoById(id)
        .subscribe(res => {
          this.itens.unshift(res);
          this.itens = mergeArray(this.itens);
        });
    }
  }

  eventosContabeisDescription(item) {
    return item.sigla + ' - ' + item.nome;
  }
  //////////////  FIM FUNÇÕES COMBO EVENTOS CONTÁBEIS  //////////////

  //////////////  INICIO FUNÇÕES COMBO CONTAS CONTÁBEIS  //////////////
  loadSelectedOptionsContasContabeis() {
    const optionsStorage = this.storage.getComboboxStorageFilters(this.type);
    if (optionsStorage) this.comboInputs[this.type].selectOptions.options = optionsStorage;
  }

  fetchContasContabeis(term = '') {
    this.loading[this.formControlName] = true;

    const selectable = this.comboInputs[this.type].selectOptions.enable;
    let selectedOptions = [];
    if (selectable) {
      selectedOptions = this.comboInputs[this.type].selectOptions.options.filter(x => x.checked);
      selectedOptions = selectedOptions.map(x => x.attr);
    }

    this.contasContabeisService.getContasContabeisComboBox(((this.itens.length / 20) + 1), term, selectedOptions)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchContasContabeis() {
    this.loadSelectedOptionsContasContabeis();
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        const selectable = this.comboInputs[this.type].selectOptions.enable;
        let selectedOptions = [];
        if (selectable) {
          selectedOptions = this.comboInputs[this.type].selectOptions.options.filter(x => x.checked);
          selectedOptions = selectedOptions.map(x => x.attr);
        }

        return this.contasContabeisService.getContasContabeisComboBox(1, term || '', selectedOptions).pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaContaContabilSelecionada(id) {
    if (id) {
      this.contasContabeisService.getContaById(id)
        .subscribe(res => {
          this.itens.unshift(res);
          this.itens = mergeArray(this.itens);
        });
    }
  }

  alteraAtributoContasContabeis(opcao = 0) {
    const opcoes = {
      0: 'nomeConta',
      1: 'codigoSimplificado',
      2: 'contaContabil',
    }

    this.comboInputs[this.type].nomeAtributo = opcoes[opcao] || 'nomeConta';
    this.storage.setComboboxStorageFilters(this.type, this.comboInputs[this.type].selectOptions.options);
    this.itens = [];
    this.fetchContasContabeis();
  }
  //////////////  FIM FUNÇÕES COMBO CONTAS CONTÁBEIS  //////////////

  //////////////  INICIO FUNÇÕES COMBO HISTÓRICOS CONTÁBEIS  //////////////
  fetchHistoricosContabeis(term = '') {
    this.loading[this.formControlName] = true;

    const selectable = this.comboInputs[this.type].selectOptions.enable;
    let selectedOptions = [];
    if (selectable) {
      selectedOptions = this.comboInputs[this.type].selectOptions.options.filter(x => x.checked);
      selectedOptions = selectedOptions.map(x => x.attr);
    }

    this.historicosContabeisPadraoService.getHistoricosContabeisComboBox(((this.itens.length / 20) + 1), term, selectedOptions)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchHistoricosContabeis() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        const selectable = this.comboInputs[this.type].selectOptions.enable;
        let selectedOptions = [];
        if (selectable) {
          selectedOptions = this.comboInputs[this.type].selectOptions.options.filter(x => x.checked);
          selectedOptions = selectedOptions.map(x => x.attr);
        }

        return this.historicosContabeisPadraoService.getHistoricosContabeisComboBox(1, term || '', selectedOptions).pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaHistoricosContabilSelecionado(itemId) {
    if (itemId) {
      this.historicosContabeisPadraoService.getContaById(itemId)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  alteraAtributoHistoricoContabil(opcao = 0) {
    const opcoes = {
      0: 'descricao',
      1: 'codigoHistorico',
    }

    this.comboInputs[this.type].nomeAtributo = opcoes[opcao] || 'descricao';
    this.itens = [];
    this.fetchHistoricosContabeis();
  }
  //////////////  FIM FUNÇÕES COMBO HISTÓRICOS CONTÁBEIS  //////////////

  //////////////  INICIO FUNÇÕES COMBO MOTIVO MOVIMENTAÇÃO ESTOQUE  //////////////
  fetchMotivoMovEstoque(term = '') {
    this.loading[this.formControlName] = true;

    this.motivosMovimentacaoEstoqueService.getPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchMotivoMovEstoque() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.motivosMovimentacaoEstoqueService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaMotivoMovEstoqueSelecionado(id) {
    if (id) {
      this.motivosMovimentacaoEstoqueService.getById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }
  //////////////  FIM FUNÇÕES COMBO MOTIVO MOVIMENTAÇÃO ESTOQUE  //////////////

  //////////////  INICIO FUNÇÕES COMBO PRODUTO  //////////////
  fetchProduto(term = '') {
    this.loading[this.formControlName] = true;

    this.itensService.getPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchProduto() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.itensService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaProdutoSelecionado(id) {
    if (id) {
      this.itensService.getById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }
  //////////////  FIM FUNÇÕES COMBO PRODUTO  //////////////

  //////////////  INICIO FUNÇÕES COMBO SERVICO  //////////////
  fetchServico(term = '') {
    this.loading[this.formControlName] = true;

    this.itensService.getServicosPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchServico() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.itensService.getServicosPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaServicoSelecionado(id) {
    if (id) {
      this.itensService.getById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }
  //////////////  FIM FUNÇÕES COMBO PRODUTO  //////////////

  //////////////  INICIO FUNÇÕES COMBO ITENS FORNECEDORES  //////////////
  fetchItemFornecedor(term = '') {
    this.loading[this.formControlName] = true;

    this.itensFornecedoresService.getPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchItemFornecedor() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.itensFornecedoresService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaItemFornecedorSelecionado(id) {
    if (id) {
      this.itensFornecedoresService.getByID(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }
  //////////////  FIM FUNÇÕES COMBO ITENS FORNECEDORES  //////////////

  //////////////  INICIO FUNÇÕES COMBO ALMOXARIFADOS  //////////////
  fetchAlmoxarifados(term = '') {
    this.loading[this.formControlName] = true;

    this.almoxarifadosService.getPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchAlmoxarifados() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.almoxarifadosService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaAlmoxarifadosSelecionado(id) {
    if (id) {
      this.almoxarifadosService.getAlmoxarifadoById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }
  //////////////  FIM FUNÇÕES COMBO ALMOXARIFADOS  //////////////

  //////////////  INICIO FUNÇÕES COMBO UNIDADE MEDIDA  //////////////
  fetchUnidadeMedida(term = '') {
    this.loading[this.formControlName] = true;

    this.unidadesMedidaService.getPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchUnidadeMedida() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.unidadesMedidaService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaUnidadeMedidaSelecionado(id) {
    if (id) {
      this.loading['id'] = true;
      this.unidadesMedidaService.getById(id)
        .pipe(finalize(() => this.loading['id'] = false))
        .subscribe(res => {
          if (res) {
            this.carregouSelecionado(res);
          } else {
            // Feito isso pois há itens que estão com unidade de medida de outra empresa
            this.clean();
            this.onChange(null);
          }
        });
    }
  }

  unidadeMedidaDescription(item) {
    return item.unidade + ' - ' + item.descricao;
  }
  //////////////  FIM FUNÇÕES COMBO UNIDADE MEDIDA  //////////////

  //////////////  INICIO FUNÇÕES COMBO TIPO DE DESPESAS  //////////////
  fetchTipoDespesa(term = '') {
    this.loading[this.formControlName] = true;

    this.tipoDespesaService.getPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchTipoDespesa() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.tipoDespesaService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaTipoDespesaSelecionado(id) {
    if (id) {
      this.tipoDespesaService.getTipoDespesaById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }
  //////////////  FIM FUNÇÕES COMBO TIPO DE DESPESAS  //////////////

  //////////////  INICIO FUNÇÕES COMBO CONTAS CORRENTES  //////////////
  fetchContaCorrente(term = '') {
    this.loading[this.formControlName] = true;

    this.contascorrentesService.getPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchContaCorrente() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.contascorrentesService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaContaCorrenteSelecionado(id) {
    if (id) {
      this.contascorrentesService.getContaById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }
  //////////////  FIM FUNÇÕES COMBO CONTAS CORRENTES  //////////////

  //////////////  INICIO FUNÇÕES COMBO VENDEDORES  //////////////
  async fetchVendedor(term = '') {

    const getPaginadoPromise = new Promise((next, error) => {
      this.repService.getPaginado(((this.itens.length / 20) + 1), term)
        .subscribe({ next, error });
    });
    const promises = [getPaginadoPromise];

    if (this.value) {
      const obterPorIdPromise = new Promise((next, error) => {
        this.repService.getRepresentanteById(this.value)
          .subscribe({ next, error });
      });
      promises.push(obterPorIdPromise);
    }

    this.loading[this.formControlName] = true;
    try {
      const response = await Promise.all(promises);
      const paginado = (response[0] || []) as any;
      const rep = (response[1] || {}) as any;
      this.itens = mergeArray(this.itens, paginado);
      if (rep && rep.id && !paginado.some(r => r.id == rep.id)) {
        this.itens.pop();
        this.itens = mergeArray([rep], this.itens);
      }

    } catch (e) {
      console.error(e);
    }
    this.loading[this.formControlName] = false;
  }

  onSearchVendedor() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.repService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data.filter((r: Representante) => !r.inativo);
      this.newFocus();
    });
  }


  carregaVendedorSelecionado(itemId) {
    if (itemId) {
      this.repService.getRepresentanteById(itemId)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO TIPO DE DESPESAS  //////////////

  //////////////  INICIO FUNÇÕES COMBO CONDIÇÔES DE PAGAMENTO  //////////////

  fetchCondicaoPagamento(term = '') {
    this.loading[this.formControlName] = true;

    this.condicaoPagamentoService.getPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchCondicaoPagamento() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.condicaoPagamentoService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }


  carregaCondicaoPagamentoSelecionado(id) {
    if (id) {
      this.condicaoPagamentoService.getCondicaoPagamentoById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO CONDIÇÔES DE PAGAMENTO  //////////////



  //////////////  INICIO FUNÇÕES COMBO INTERMEDIADORES  //////////////

  fetchIntermediador(term = '') {
    this.loading[this.formControlName] = true;

    this.intermediadorService.getPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchIntermediador() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.intermediadorService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }


  carregaIntermediadorSelecionado(id) {
    if (id) {
      this.intermediadorService.getById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO INTERMEDIADORES  //////////////





  //////////////  INICIO FUNÇÕES COMBO TIPOS DE COMISSÃO  //////////////

  fetchTipoComissao(term = '') {
    this.loading[this.formControlName] = true;

    this.tipoComissaoService.getPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchTipoComissao() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.tipoComissaoService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }


  carregaTipoComissaoSelecionado(id) {
    if (id) {
      this.tipoComissaoService.getTipoComissaoById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO TIPOS DE COMISSÃO  //////////////

  //////////////  INICIO FUNÇÕES COMBO USUARIO VENDEDOR  //////////////

  fetchUsuarioVendedor(term = '') {
    this.loading[this.formControlName] = true;

    this.usuariosService.getPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchUsuarioVendedor() {
    this.inputOnSearch.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.usuariosService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaUsuarioVendedorSelecionado(id) {
    if (id) {
      this.usuariosService.fetchId(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO USUARIO VENDEDOR  //////////////

  //////////////  INICIO FUNÇÕES COMBO LINHA DE PRODUTO  //////////////

  fetchLinhaDeProduto(term = '') {
    this.loading[this.formControlName] = true;

    let verificaoPedido = false;

    if (this.parametro && this.parametro.verificaoPedido) {
      verificaoPedido = this.parametro.verificaoPedido;
    }

    if (verificaoPedido) {
      return this.linhasProdutosService.getPaginadoPedido(((this.itens.length / 20) + 1), term)
        .pipe(finalize(() => this.loading[this.formControlName] = false))
        .subscribe(response => {
          this.itens = mergeArray(this.itens, response);
        });
    } else {
      this.linhasProdutosService.getPaginado(((this.itens.length / 20) + 1), term)
        .pipe(finalize(() => this.loading[this.formControlName] = false))
        .subscribe(response => {
          this.itens = mergeArray(this.itens, response);
        });
    }
  }

  onSearchLinhaDeProduto() {
    this.inputOnSearch.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {

        let verificaoPedido = false;

        if (this.parametro && this.parametro.verificaoPedido) {
          verificaoPedido = this.parametro.verificaoPedido;
        }
        if (verificaoPedido) {
          return this.linhasProdutosService.getPaginadoPedido(1, term || '').pipe(
            tap(() => this.loading[this.formControlName] = false)
          );
        } else {
          return this.linhasProdutosService.getPaginado(1, term || '').pipe(
            tap(() => this.loading[this.formControlName] = false)
          );
        }
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaLinhaDeProdutoSelecionado(id) {
    if (id) {
      this.linhasProdutosService.getLinhaProdutoById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO LINHA DE PRODUTO  //////////////

  //////////////  INICIO FUNÇÕES COMBO CLASSIFICACAO FISCAL  //////////////
  fetchClassificacoesFiscais(term = '') {
    this.loading[this.formControlName] = true;

    this.classificacoesFiscaisService.getNcmPaginado(((this.itens.length / 20) + 1), term || '')
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchClassificacoesFiscais() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.classificacoesFiscaisService.getNcmPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaClassificacaoFiscalSelecionado(id) {
    if (id) {
      this.classificacoesFiscaisService.getById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO CLASSIFICACAO FISCAL  //////////////

  //////////////  INICIO FUNÇÕES COMBO ALÍQUOTA ICMS  //////////////
  fetchAliquotaIcms(term = '') {
    this.loading[this.formControlName] = true;

    this.aliquotasIcmsService.getPaginado(((this.itens.length / 20) + 1), term || '')
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchAliquotaIcms() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.aliquotasIcmsService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaAliquotaIcmsSelecionado(id) {
    if (id) {
      this.aliquotasIcmsService.getById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        }, err => {
          if (this.itens && this.itens.length > 0) {
            const aliquota = this.itens.find(x => x.aliquotaICMS === id);
            if (aliquota) {
              this.carregaAliquotaIcmsSelecionado(aliquota.id);
            }
          } else {
            this.carregaAliquotaIcmsSelecionado(id);
          }
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO ALÍQUOTA ICMSL  //////////////

  //////////////  INICIO FUNÇÕES COMBO UNIDADES EMPRESA  //////////////
  fetchUnidadesEmpresa(term = '') {
    this.loading[this.formControlName] = true;

    const selectable = this.comboInputs[this.type].selectOptions.enable;
    let selectedOptions = [];
    if (selectable) {
      selectedOptions = this.comboInputs[this.type].selectOptions.options.filter(x => x.checked);
      selectedOptions = selectedOptions.map(x => x.attr);
    }

    this.adminService.getEmpresasComboBox((this.itens.length / 20) + 1, term || '', selectedOptions)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(res => {
        this.itens = mergeArray(this.itens, res);
      })
  }

  onSearchUnidadesEmpresa() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        const selectable = this.comboInputs[this.type].selectOptions.enable;
        let selectedOptions = [];
        if (selectable) {
          selectedOptions = this.comboInputs[this.type].selectOptions.options.filter(x => x.checked);
          selectedOptions = selectedOptions.map(x => x.attr);
        }

        return this.adminService.getEmpresasComboBox(1, term || '', selectedOptions)
          .pipe(tap(() => this.loading[this.formControlName] = false));
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaUnidadesEmpresaSelecionado(id) {
    if (id) {
      this.adminService.getUnidadeEmpresaById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  alteraAtributoUnidadeEmpresa(opcao = 0) {
    const opcoes = {
      0: 'nome',
      1: 'nomeFantasia',
      2: 'cnpj',
    }

    this.comboInputs[this.type].nomeAtributo = opcoes[opcao] || 'nomeFantasia';
    this.itens = [];
    this.fetchUnidadesEmpresa();
  }

  //////////////  FIM FUNÇÕES COMBO UNIDADES EMPRESA  //////////////

  //////////////  INICIO FUNÇÕES COMBO CENTRO DE CUSTOS  //////////////
  fetchCentroDeCustos(term = '') {
    this.loading[this.formControlName] = true;

    this.centrosCustosService.getPaginado(((this.itens.length / 20) + 1), term || '')
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchCentroDeCustos() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.centrosCustosService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaCentroDeCustosSelecionado(id) {
    if (id) {
      this.centrosCustosService.getCentroCustoById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO CENTRO DE CUSTOS  //////////////

  //////////////  INICIO FUNÇÕES COMBO BANCOS  //////////////
  fetchBancos(term = '') {
    this.loading[this.formControlName] = true;

    this.bancosService.getPaginado(((this.itens.length / 20) + 1), term || '')
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchBancos() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.bancosService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaBancosSelecionado(id) {
    if (id) {
      this.bancosService.getById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO BANCOS  //////////////

  //////////////  INICIO FUNÇÕES COMBO FORMA PAGAMENTO PORTADOR  //////////////
  fetchFormaPagamentoPortador(term = '') {
    this.loading[this.formControlName] = true;

    this.portadorService.getPaginado(((this.itens.length / 20) + 1), term || '')
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchFormaPagamentoPortador() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.portadorService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaFormaPagamentoPortadorSelecionado(id) {
    if (id) {
      this.portadorService.getPortadorById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO FORMA PAGAMENTO PORTADOR  //////////////

  //////////////  INICIO FUNÇÕES COMBO FORMA PAGAMENTO PORTADOR  //////////////
  fetchTipoNfe(term = '') {
    if (this.itens.length > 0)
      return;

    this.loading[this.formControlName] = true;

    this.tipoNfeService.getPaginado(((this.itens.length / 50) + 1), term || '')
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchTipoNfe() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.tipoNfeService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaTipoNfeSelecionado(id) {
    if (id) {
      this.tipoNfeService.getById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO FORMA PAGAMENTO PORTADOR  //////////////

  //////////////  INÍCIO FUNÇÕES COMBO TIPOS DE ITEM  //////////////

  fetchTipoItem(term = '') {
    this.loading[this.formControlName] = true;

    this.tipoItemService.getPaginado(((this.itens.length / 20) + 1), term || '')
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchTipoItem() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.tipoItemService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaTipoItemSelecionado(id) {
    if (id) {
      this.tipoItemService.getById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO TIPOS DE ITEM  //////////////


  //////////////  INÍCIO FUNÇÕES COMBO CÓDIGOS FISCAIS OPERAÇÃO  //////////////

  fetchCodigosFiscaisOperacao(term = '') {
    this.loading[this.formControlName] = true;

    this.codigosFiscaisOperacaoService.getPaginado(((this.itens.length / 20) + 1), term || '')
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchCodigosFiscaisOperacao() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.codigosFiscaisOperacaoService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaCodigoFiscalOperacaoSelecionado(id) {
    if (id) {
      this.codigosFiscaisOperacaoService.get(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }
  //////////////  FIM FUNÇÕES COMBO CÓDIGOS FISCAIS OPERAÇÃO  //////////////

  //////////////  INÍCIO FUNÇÕES COMBO COMBO SÉRIES ALTERNATIVAS  //////////////

  fetchSeriesAlternativas(term = '') {
    this.loading[this.formControlName] = true;

    this.seriesAlternativasService.getPaginado(((this.itens.length / 20) + 1), term || '')
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchSeriesAlternativas() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.seriesAlternativasService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaSerieAlternativaSelecionada(id) {
    if (id) {
      this.seriesAlternativasService.getByID(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }
  //////////////  FIM FUNÇÕES COMBO SÉRIES ALTERNATIVAS  //////////////

  //////////////  INÍCIO FUNÇÕES COMBO CÓDIGOS FISCAIS  //////////////

  fetchCodigosFiscais(term = '') {
    this.loading[this.formControlName] = true;

    this.codigosFiscaisService.getPaginado(((this.itens.length / 20) + 1), term || '')
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchCodigosFiscais() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.codigosFiscaisService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaCodigoFiscalSelecionado(id) {
    if (id) {
      this.codigosFiscaisService.getById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO CÓDIGOS FISCAIS  //////////////

  //////////////  INICIO FUNÇÕES COMBO TRANSPORTADOR  //////////////

  fetchTransportadores(term = '') {
    term = term.replace(/'/g, "''"); // replace ' por '' para o OData
    this.loading[this.formControlName] = true;
    let filter = false;

    if (this.comboInputs[this.type].selectOptions.lastValue === 1
      && this.comboInputs[this.type].selectOptions.enable
      && this.selectOptions) {
      filter = true;
    }

    this.fornecedoresService.getTransportadoresPaginado(((this.itens.length / 20) + 1), term, filter)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchTransportadores() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        let filter = false;

        if (this.comboInputs[this.type].selectOptions.lastValue === 1
          && this.comboInputs[this.type].selectOptions.enable
          && this.selectOptions) {
          filter = true;
        }
        return this.fornecedoresService.getTransportadoresPaginado(1, term || '', filter).pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  alteraAtributoTransportador(opcao = 0) {
    if (opcao === 1) {
      this.comboInputs[this.type].nomeAtributo = 'nomeFantasia';
    } else {
      this.comboInputs[this.type].nomeAtributo = 'nome';
    }
    this.itens = [];
    this.fetchTransportadores();
  }

  //////////////  FIM FUNÇÕES COMBO TRANSPORTADOR  //////////////

  //////////////  INICIO FUNÇÕES COMBO INTERMEDIADOR RECEBIMENTOS  //////////////

  fetchIntermediadorRecebimentos(term = '') {
    this.loading[this.formControlName] = true;

    this.intermediadorRecebedorService.getPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchIntermediadorRecebimentos() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.intermediadorRecebedorService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaIntermediadorRecebimentosSelecionado(id) {
    if (id) {
      this.intermediadorRecebedorService.getById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO INTERMEDIADOR RECEBIMENTOS  //////////////

  //////////////  INICIO FUNÇÕES COMBO ENREDEÇO RECEBIMENTO  //////////////

  fetchEnderecoRecebimento(term = '') {
    this.loading[this.formControlName] = true;

    this.notasFiscaisEnderecosService.getPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchEnderecoRecebimento() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.notasFiscaisEnderecosService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaEnderecoRecebimentoSelecionado(id) {
    if (id) {
      this.notasFiscaisEnderecosService.getById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  enderecoRetiradaDescription(item: any) {
    return `${item.endereco}, ${item.numero} - ${item.bairro} - ${item.nomeCidade}`;
  }

  vendedorAtivoInativoDescription(item: Representante): string {
    let tagDesativado = '';
    if (item.inativo) {
      tagDesativado = ' (Desativado)';
    }
    return `${item.nome}${tagDesativado}`;
  }

  getSelectOptionsChecked(type: string) {
    if (this.comboInputs[type].selectOptions.enable) {
      return this.comboInputs[type].selectOptions.options.filter(x => x.checked);
    }

    return [];
  }

  //////////////  FIM FUNÇÕES COMBO ENREDEÇO RECEBIMENTO  //////////////

  //////////////  INICIO FUNÇÕES COMBO UNIDADES DE CONSUMO  //////////////

  fetchUnidadesConsumo(term = '') {
    this.loading[this.formControlName] = true;

    this.unidadesConsumoService.getPaginadoCombobox(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchUnidadesConsumo() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.unidadesConsumoService.getPaginadoCombobox(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaUnidadesConsumoSelecionado(id) {
    if (id) {
      this.unidadesConsumoService.getById(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  //////////////  FIM FUNÇÕES COMBO UNIDADES DE CONSUMO  //////////////

  //////////////  INICIO FUNÇÕES COMBO PROCESSO DE IMPORTAÇÃO  //////////////

  fetchProcessoImportacao(term = '') {
    this.loading[this.formControlName] = true;

    this.processoImportacaoService.getPaginado(((this.itens.length / 20) + 1), term)
      .pipe(finalize(() => this.loading[this.formControlName] = false))
      .subscribe(response => {
        this.itens = mergeArray(this.itens, response);
      });
  }

  onSearchProcessoImportacao() {
    this.inputOnSearch.pipe(
      debounceTime(this.deboucingTime),
      distinctUntilChanged(),
      tap(() => {
        this.loading[this.formControlName] = true;
        this.backAllow = false;
        const inputFocus = <HTMLElement>document.querySelector(`[aria-owns=${this.select.dropdownId}`);
        if (inputFocus) {
          inputFocus.blur();
        }
      }),
      switchMap(term => {
        return this.processoImportacaoService.getPaginado(1, term || '').pipe(
          tap(() => this.loading[this.formControlName] = false)
        );
      })
    ).subscribe(data => {
      this.itens = data;
      this.newFocus();
    });
  }

  carregaProcessoImportacaoSelecionado(id) {
    if (id) {
      this.processoImportacaoService.obterPorId(id)
        .subscribe(res => {
          this.carregouSelecionado(res);
        });
    }
  }

  processoImportacaoDescription(item) {
    if (!item.processoDeImportacao) {
      return '';
    }

    return item.processoDeImportacao + ' - ' + item.clienteContratante.razaoSocial;
  }

  //////////////  FIM FUNÇÕES COMBO PROCESSO DE IMPORTAÇÃO  //////////////
}
