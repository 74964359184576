<app-modal-basic #modal [hideHeader]="true" [hideFooter]="true" [allowClickOutside]="false">
  <div class="app-modal-body">
    <div class="row modalheader">
      <span>Selecione um usuário para alterar a senha</span>
    </div>
    <div class="row">
      <div class="form-group col-sm-12">
        <p class="m-0">
          Foram encontrados os seguintes usuários para o e-mail informado:
        </p>
      </div>
    </div>
    <div class="row">
      <ng-container *ngFor="let user of usuarios">
        <div class="col-12">
          <div class="card social-widget-card m-b-5" style="cursor: pointer" (click)="selectUser(user.id)">
            <div class="card-block bg-panel3">
              <h3>{{ user.nome }}</h3>
              <span>{{ user.login }}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</app-modal-basic>
