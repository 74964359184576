<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader">
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">

    <div class="row">
      <div class="form-group col-sm-8">
        <label class="label-required">Descrição</label>
        <input appAutofocus [disableControl]="loading['registro'] || edicaoBloqueada" type="text" class="form-control" formControlName="descricao" />
        <show-errors [control]="cadastroForm.controls.descricao"></show-errors>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-4 checkbox-form">
        <div class="checkbox-fade fade-in-default">
          <label>
            <input [disableControl]="loading['registro'] || edicaoBloqueada" type="checkbox" formControlName="aceitaLancamento">
            <span class="cr">
              <i class="cr-icon icofont icofont-ui-check txt-default"></i>
            </span> <span>Terceiros</span>
          </label>
        </div>
      </div>
      <div class="form-group col-sm-4 checkbox-form">
        <div class="checkbox-fade fade-in-default">
          <label>
            <input [disableControl]="loading['registro'] || edicaoBloqueada" type="checkbox" formControlName="descontaTarifa">
            <span class="cr">
              <i class="cr-icon icofont icofont-ui-check txt-default"></i>
            </span> <span>Descontar tarifa</span>
          </label>
        </div>
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="errors">
      <span>{{ errors }}</span>
    </div>

    <button [disabled]='loading["registro"] || edicaoBloqueada' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'" [loading]="loading['registro']"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalCadastro.hide()">Cancelar</button>
  </form>
</app-temp-modal-cadastro>