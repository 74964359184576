<ng-template #defaultItemTemplate let-item="item" let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange">
  <div class="form-inline row-item">
    <i *ngIf="item.children" (click)="onCollapseExpand()" aria-hidden="true" [ngSwitch]="item.collapsed">
      <svg *ngSwitchCase="true" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-right-fill"
        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.14 8.753l-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
      </svg>
      <svg *ngSwitchCase="false" width="0.8rem" height="0.8rem" viewBox="0 0 16 16" class="bi bi-caret-down-fill"
        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
      </svg>
    </i>
    <div class="form-check">
      <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()"
        [disabled]="item.disabled" [indeterminate]="item.indeterminate" />
      <label class="form-check-label cc" (click)="item.checked = !item.checked; onCheckedChange()">
        {{item.text}}
      </label>
    </div>
  </div>
</ng-template>
<ng-template #defaultHeaderTemplate let-config="config" let-item="item" let-onCollapseExpand="onCollapseExpand"
  let-onCheckedChange="onCheckedChange" let-onFilterTextChange="onFilterTextChange">
  <div *ngIf="config.hasFilter" class="row row-filter">
    <div class="col-12">
      <input class="form-control" type="text" [placeholder]="i18n.getFilterPlaceholder()" [(ngModel)]="filterText"
        (ngModelChange)="onFilterTextChange($event)" />
    </div>
  </div>
  <div *ngIf="hasFilterItems">
    <div *ngIf="config.hasAllCheckBox || config.hasCollapseExpand" class="row row-all">
      <div class="col-12">
        <div class="form-check form-check-inline" *ngIf="config.hasAllCheckBox">
          <input type="checkbox" class="form-check-input" [(ngModel)]="item.checked" (ngModelChange)="onCheckedChange()"
            [indeterminate]="item.indeterminate" />
          <label class="form-check-label aa" (click)="item.checked = !item.checked; onCheckedChange()">
            {{i18n.getAllCheckboxText()}}
          </label>
        </div>
        <label *ngIf="config.hasCollapseExpand" class="float-right form-check-label bb" (click)="onCollapseExpand()">
          <i [title]="i18n.getTooltipCollapseExpandText(item.collapsed)" aria-hidden="true" [ngSwitch]="item.collapsed">
            <svg *ngSwitchCase="true" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrows-angle-expand"
              fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M1.5 10.036a.5.5 0 0 1 .5.5v3.5h3.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5z" />
              <path fill-rule="evenodd"
                d="M6.354 9.646a.5.5 0 0 1 0 .708l-4.5 4.5a.5.5 0 0 1-.708-.708l4.5-4.5a.5.5 0 0 1 .708 0zm8.5-8.5a.5.5 0 0 1 0 .708l-4.5 4.5a.5.5 0 0 1-.708-.708l4.5-4.5a.5.5 0 0 1 .708 0z" />
              <path fill-rule="evenodd"
                d="M10.036 1.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 1 1-1 0V2h-3.5a.5.5 0 0 1-.5-.5z" />
            </svg>
            <svg *ngSwitchCase="false" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrows-angle-contract"
              fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                d="M9.5 2.036a.5.5 0 0 1 .5.5v3.5h3.5a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5z" />
              <path fill-rule="evenodd"
                d="M14.354 1.646a.5.5 0 0 1 0 .708l-4.5 4.5a.5.5 0 1 1-.708-.708l4.5-4.5a.5.5 0 0 1 .708 0zm-7.5 7.5a.5.5 0 0 1 0 .708l-4.5 4.5a.5.5 0 0 1-.708-.708l4.5-4.5a.5.5 0 0 1 .708 0z" />
              <path fill-rule="evenodd"
                d="M2.036 9.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V10h-3.5a.5.5 0 0 1-.5-.5z" />
            </svg>
          </i>
        </label>
      </div>
    </div>
    <div *ngIf="config.hasDivider" class="dropdown-divider"></div>
  </div>
</ng-template>
<div class="treeview-header">
  <ng-template [ngTemplateOutlet]="headerTemplate || defaultHeaderTemplate"
    [ngTemplateOutletContext]="headerTemplateContext">
  </ng-template>
</div>
<div [ngSwitch]="hasFilterItems">
  <div *ngSwitchCase="true" class="treeview-container" [style.max-height.px]="maxHeight">
    <ngx-treeview-item *ngFor="let item of filterItems" [config]="config" [item]="item"
      [template]="itemTemplate || defaultItemTemplate" (checkedChange)="onItemCheckedChange(item, $event)">
    </ngx-treeview-item>
  </div>
  <div *ngSwitchCase="false" class="treeview-text">
    {{i18n.getFilterNoItemsFoundText()}}
  </div>
</div>
