<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" textHeader="Clonar Produto" customClass="modal-lg">
  <div class="row mb-4">
    <div class="col">
      Produto: <strong>{{vm.nome}}</strong>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col">
      Digite um novo código de entrada para os relacionamentos:
    </div>
  </div>
  <div class="table-responsive">
    <table class="table table-striped table-bordered full-data-table">
      <thead>
        <tr>
          <th style="width: 250px">Cód. Item Entrada</th>
          <th>Nome</th>
          <th>Fornecedor</th>
          <th class="text-center">Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="vm.itensFornecedores.length === 0">
          <td colspan="4">Os itens de entrada relacionados não serão clonados.</td>
        </tr>
        <tr *ngFor="let itemFornecedor of vm.itensFornecedores; let index = index">
          <td>
            <input type="text" class="form-control" name="referenciaItem-{{index}}"
              [(ngModel)]="vm.itensFornecedores[index].referenciaItem" [disabled]="this.loading['registro']"
              maxlength="60">
          </td>
          <td>{{ itemFornecedor.nome || "" }}</td>
          <td>{{ itemFornecedor.fornecedorNome || "" }}</td>
          <td class="text-center">
            <button class="btn btn-danger btn-mini waves-effect waves-light ng-star-inserted"
              (click)="removerItemFornecedor(itemFornecedor.id)" title="Remover relacionamento"
              [disabled]="this.loading['registro']"><i class="ti ti-trash"></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row" *ngIf="errors && errors.length > 0">
    <div class="col">
      <div class="alert alert-danger">
        <p class="mb-2" *ngFor="let error of errors">{{error}}</p>
      </div>
    </div>
  </div>

  <button type="submit" (click)="clonar()" class="btn btn-primary ripple light" [disabled]="this.loading['registro']">
    <i class="fa fa-copy"></i> Clonar
  </button>
  <button type="button" class="btn btn-info ripple" (click)="modalCadastro.hide()"
    [disabled]="this.loading['registro']">
    <i class="fa fa-times"></i> Cancelar
  </button>
  <button *ngIf="dadosAlterados" type="button" class="btn btn-info ripple float-right" (click)="carregarVm()"
    [disabled]="this.loading['registro']">
    <i class="ti-back-left"></i> Desfazer alterações
  </button>
</app-temp-modal-cadastro>