<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader">
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
    <div class="row">
      <div class="form-group col-sm-9">
        <label class="label-required">Descrição</label>
        <input [disableControl]="loading['registro']" type="text" class="form-control" formControlName="ccus" />
        <show-errors [control]="cadastroForm.controls.ccus"></show-errors>
      </div>
      <div class="form-group form-check col-sm-3 check-form">
        <label></label>
        <div class="checkbox-fade fade-in-default">
          <label>
            <input type="checkbox" formControlName="inativo">
            <span class="cr">
              <i class="cr-icon icofont icofont-ui-check txt-default"></i>
            </span>
            <span class="text-inverse">
              Inativo
            </span>
          </label>
        </div>
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="errors">
      <span>{{ errors }}</span>
    </div>

    <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'" [loading]="loading['registro']"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-danger ripple" (click)="delete()"
      *ngIf="id">Excluir</button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalCadastro.hide()">Cancelar</button>
  </form>
</app-temp-modal-cadastro>