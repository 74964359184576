import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {

  constructor() { }

  @Input() level: number;
  @Input() menuItens: any[];
  @Input() menuType: string;
  @Input() rotaPai: string;
  @Output() functionResponse = new EventEmitter();
  @Output() hideMobileMenu = new EventEmitter();

  ngOnInit() {
  }

  executeFunction(parameter) {
    this.hideMenu(true)
    this.functionResponse.emit(parameter)
  }

  montaRotaPai(rotaFilho) {
    return (this.rotaPai + "/" + rotaFilho);
  }

  montarRota(rotaFilho) {
    let rotaPai = this.rotaPai + "/" + rotaFilho
    var rota = rotaPai.split('/');
    return rota;
  }

  hideMenu(hide) {
    this.hideMobileMenu.emit(hide)
  }

}
