import { FormGroup, ValidationErrors, AbstractControl, ValidatorFn, Validators, FormControl } from '@angular/forms';
import * as moment from 'moment';

export class CustomValidators {

    static validaPeriodo(form: FormGroup): ValidationErrors {
        const empresaControl = form.get('empresa');
        const dataInicialControl = form.get('dataInicial');
        const dataFinalControl = form.get('dataFinal');

        if (empresaControl != null && dataInicialControl != null && dataFinalControl != null) {
            const empresa = empresaControl.value;
            const dataInicial = dataInicialControl.value;
            const dataFinal = dataFinalControl.value;

            if ((empresa == null || empresa.length === 0) && (!dataInicial || !dataFinal)) {
                const message = {
                    'dataInicial': {
                        'message': 'Período requerido'
                    }
                };
                dataInicialControl.setErrors(message);
            } else {
                dataInicialControl.setErrors(null);
            }
            return null;
        }
    }

    static validaProdutoAuto(form: FormGroup): ValidationErrors{
        const camposObrigatorios = ['estadoId', 'renavam', 'placa', 'marca', 'modelo', 'anoFabricacaoModelo', 'cor'];
        let algumCampoPreenchido = false;
        let valid = true;
    
        camposObrigatorios.forEach(campo => {
          const control = form.get(campo);
          if (control && control.value) {
            algumCampoPreenchido = true;
          }
        });
    
        if (algumCampoPreenchido) {
          camposObrigatorios.forEach(campo => {
            const control = form.get(campo);
            if (control && !control.value) {
              control.setErrors({ required: true });
              valid = false;
            } else {
              control.setErrors(null);
            }
          });
        } else {
          camposObrigatorios.forEach(campo => {
            const control = form.get(campo);
            if (control) {
              control.setErrors(null);
            }
          });
        }
    
        camposObrigatorios.forEach(campo => {
          const control = form.get(campo);
          if (control && control.errors && control.errors.required) {
            control.setErrors({ message: 'Preencha o campo corretamente' });
          }
        });
    
        return valid ? null : { camposObrigatorios: 'Preencha todos os campos obrigatórios' };
    }
    
    static validaMovimentacaoEstoque(form: FormGroup): ValidationErrors {
        const quantidade = form.get('quantidade').value;
        const quantidadeControl = form.get('quantidade');

        if (quantidade <= 0) {
            if (quantidade != null) {
                const message = {
                    'quantidadeMinima': {
                        'message': 'A quantidade deve ser maior que 0!'
                    }
                };
                quantidadeControl.setErrors(message);
            }
        } else {
            quantidadeControl.setErrors(null);
        }

        return null;
    }

  // Ref: https://github.com/yuyang041060120/ng2-validation/blob/master/src/greater-than/validator.ts
  static gt = (gt: number): ValidatorFn => {
    return (control: AbstractControl): {[key: string]: boolean} => {
      if (!isPresent(gt)) return null;
      if (isPresent(Validators.required(control))) return null;

      let v: number = +control.value;
      return v > +gt ? null : {gt: true};
    };
  };

  static dataMaiorOuIgualAtual(): ValidatorFn {
    return (control: FormControl) => {
      if (!control.value)
        return null;

      const data = moment(control.value).startOf('day');
      const dataAtual = moment().startOf('day');

      return data.isSameOrAfter(dataAtual)
        ? null
        : { custom: { message: "Data deve ser maior ou igual a atual" } };
    }
  }
}

export function minimumValue(valorMinimo): ValidatorFn {
    return (control: AbstractControl) => {
        if (control.value != null && control.value !== undefined && control.value < valorMinimo) {
            let message = 'O Valor mínimo é: ' + valorMinimo;
            if (valorMinimo === 0) {
                message = 'O Valor não pode ser negativo';
            }

            return {
                'minimunValue': {
                    'message': message
                }
            };
        }

        return null;
    };
}

// https://github.com/yuyang041060120/ng2-validation/blob/master/src/util/lang.ts
export const isPresent = (obj: any): boolean => {
  return obj !== undefined && obj !== null;
}

// https://github.com/yuyang041060120/ng2-validation/blob/master/src/util/lang.ts
export const isDate = (obj: any): boolean => {
  return !/Invalid|NaN/.test(new Date(obj).toString());
}
