import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { CadastroPadraoComponent } from '../cadastro-padrao/cadastro-padrao.component';
import { Location } from '@angular/common';
import { StorageFunctions } from '../../general/storage';
import { MessageService } from '../../services/message.service';
import { UtilFunctions } from '../../general/utils-service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-base-factory',
    template: ''
})
export class BaseFactoryComponent extends CadastroPadraoComponent implements OnInit {
    @Output() formFinished = new EventEmitter();
    @Input() factoryId: number = null;
    @Input() newName: number = null;
    @Input() cadastroFactory = false;
    @Input() component = '';
    acoesDeTela = [{
        component: 'ProdutosDetalhesComponent', acoes: [
            { acao: 'cadastro', id: 51 },
            { acao: 'editar', id: 52 },
            { acao: 'excluir', id: 53 }
        ]
    }];

    constructor(route: ActivatedRoute,
        utilFunctions: UtilFunctions,
        message: MessageService,
        storage: StorageFunctions,
        location: Location) {
        super(route, utilFunctions, message, storage, location);
    }

    finalizeFactory(item = null) {
        this.formFinished.emit(item);
    }

    getAction() {
        if (this.cadastroFactory) {
            if (this.acoesDeTela.length === 0) { return; }
            const acoes = this.acoesDeTela.find(e => e.component === this.component).acoes;
            this.utilFunctions.ObterAcoesDeTela(acoes).then(res => {
                this.acoesTela = res;
                if (this.acoesTela) {
                    if (this.acoesTela['cadastro'] && !this.acoesTela['cadastro'].acesso && this.novoCadastro) {
                        this.messageService.error('Você não tem acesso para cadastrar, contate o administrador de sua empresa!', 5000);
                        this.finalizeFactory();
                    } else if (this.acoesTela['editar'] && !this.acoesTela['editar'].acesso && !this.novoCadastro) {
                        this.loading['disable'] = true;
                        this.messageService.error('Você não tem acesso para editar, contate o administrador de sua empresa!', 5000);

                        const config = this.getActionByRoute('editar');
                        if (config && config.voltar || this.cadastroFactory) {
                            this.finalizeFactory();
                        }
                    } else if (this.acoesTela['baixa'] && !this.acoesTela['baixa'].acesso) {
                        this.messageService.error('Você não tem acesso para baixar, contate o administrador de sua empresa!', 5000);
                        this.finalizeFactory();
                    } else if (this.acoesTela['ofx'] && !this.acoesTela['ofx'].acesso) {
                        this.messageService.error('Você não tem acesso para importar OFX, contate o administrador de sua empresa!', 5000);
                        this.finalizeFactory();
                    } else if (this.acoesTela['despacho'] && !this.acoesTela['despacho'].acesso) {
                        this.messageService.error('Você não tem acesso para despachar pedidos, contate o administrador de sua empresa!',
                            5000);
                        this.finalizeFactory();
                    }
                }
            });
        } else {
            super.getAction();
        }
    }

    ngOnInit() {
    }

}
