import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-temp-modal-cadastro',
  templateUrl: './temp-modal-cadastro.component.html',
  styleUrls: ['./temp-modal-cadastro.component.css']
})
export class TempModalCadastroComponent implements OnInit {

  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @Output() scrolled: EventEmitter<any> = new EventEmitter();
  @Input() textHeader: string;
  @Input() customClass = '';
  @Input() customClassMobile = '';
  @Input() customClassContent = '';
  @Input() customClassContentMobile = '';
  @Input() allowClickOutside = true;
  @Input() modalContentId: string = '';


  constructor() { }

  public show() {
    this.modalCadastro.show();
  }

  public hide() {
    this.modalCadastro.hide();
  }

  ngOnInit() {
  }

  onClose() {
    this.closed.emit();
  }

  onScroll() {
    this.scrolled.emit();
  }

}
