import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UnidadesEmpresaParametrosNfceService } from '../../../services/unidades-empresa-parametros-nfce.service';
import { UnidadesEmpresaService } from '../../../services/unidades-empresa.service';
import { markFormGroupTouched } from '../../../general/utils';
import * as moment from 'moment';
import { ModalBasicComponent } from '../../../shared/modal-basic/modal-basic.component';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-configuracao-nfce',
  templateUrl: './configuracao-nfce.component.html',
  styleUrls: ['./configuracao-nfce.component.css'],
})
export class ConfiguracaoNfceComponent extends ModalCadastroComponent implements OnInit {

  constructor(
    private parametrosNfceService: UnidadesEmpresaParametrosNfceService,
    private renderer: Renderer2,
    private unidadeEmpresaService: UnidadesEmpresaService) { super(); }

  @ViewChild("modalConfig", { static: false }) modalConfig: ModalBasicComponent;
  @ViewChild("customFileControl", { static: false }) customFileControl: ElementRef;

  configuracoesEmpresa = null;
  certificadoEnviado = false;
  file: any = null;
  modalVisible = true;

  cadastroForm = new FormGroup({
    id: new FormControl(null),
    numeracao: new FormGroup({
      emitiu: new FormControl(true),
      numero: new FormControl('0'),
      serie: new FormControl('1')
    }),
    codigosCsc: new FormGroup({
      credenciado: new FormControl(true),
      csc: new FormControl(),
      cscId: new FormControl(),
    }),
    certificado: new FormGroup({
      arquivo: new FormControl('', [Validators.required]),
      senha: new FormControl('', [Validators.required])
    }),
    pagamento: new FormGroup({
      forma: new FormControl('01')
    })
  })

  ngOnInit() {

    this.formChangeListener();

    this.verificaCertificadoDigital();
  }

  carregaConfiguracoes(config) {
    if (config) {
      this.id = config.id;
      this.cadastroForm.get('id').setValue(config.id);

      this.cadastroForm.get('numeracao.numero').setValue(config.numero);
      this.cadastroForm.get('numeracao.serie').setValue(config.serie);

      this.cadastroForm.get('codigosCsc.csc').setValue(config.csc);
      this.cadastroForm.get('codigosCsc.cscId').setValue(config.cscId);

      this.cadastroForm.get('pagamento.forma').setValue(config.formaPagamento);
      this.configuracoesEmpresa = config;
    }

  }

  formasPagamento = [
    { valor: "", descricao: "" },
    { valor: "01", descricao: "Dinheiro" },
    { valor: "02", descricao: "Cheque" },
    { valor: "03", descricao: "Cartão de Crédito" },
    { valor: "04", descricao: "Cartão de Débito" },
    { valor: "05", descricao: "Crédito Loja" },
    { valor: "10", descricao: "Vale Alimentação" },
    { valor: "11", descricao: "Vale Refeição" },
    { valor: "12", descricao: "Vale Presente" },
    { valor: "13", descricao: "Vale Combustível" },
    { valor: "15", descricao: "Boleto Bancário" },
    { valor: "16", descricao: "Depósito Bancário" },
    { valor: "17", descricao: "Pagamento Instantâneo (PIX)" },
    { valor: "18", descricao: "Transferência bancária, Carteira Digital" },
    { valor: "19", descricao: "Programa de fidelidade, Cashback, Crédito Virtua" },
    { valor: "90", descricao: "Sem pagamento" },
    { valor: "99", descricao: "Outros" },
  ]

  verificaCertificadoDigital() {
    this.unidadeEmpresaService.getCertificado()
      .subscribe(res => {
        if (res && res.validade) {
          const date = moment(res.validade, "YYYY-MM-DDTHH:mm:ss");

          if (date.isValid() && (date.toDate() > new Date())) {
            this.certificadoEnviado = true;
          }
        }
      });
  }

  fileChanged(files) {
    this.file = files.length !== 0 ? files[0] : null;
    this.renderer.setProperty(this.customFileControl.nativeElement, 'innerHTML', this.file ? this.file.name : '');

    const elemento = this.cadastroForm.get('certificado.arquivo')
    elemento.setValue(this.file);
    elemento.markAsTouched();
  }


  get isCredenciado(): boolean {
    return this.cadastroForm.get('codigosCsc.credenciado').value;
  }

  formChangeListener() {
    this.cadastroForm.get('numeracao.emitiu').valueChanges.subscribe(val => {
      if (!val) {
        this.cadastroForm.get('numeracao.numero').disable();
        this.cadastroForm.get('numeracao.numero').setValue('0');
      } else {
        this.cadastroForm.get('numeracao.numero').enable();
        if (this.configuracoesEmpresa && this.configuracoesEmpresa.numero) {
          this.cadastroForm.get('numeracao.numero').setValue(this.configuracoesEmpresa.numero);
        }
      }
    })
  }

  etapas = [1, 2, 3, 4];
  etapaAtual = 1;

  proximaEtapa() {
    this.etapaAtual += 1;
    this.animacaoEtapas();
  }

  voltarEtapa() {
    this.etapaAtual -= 1;
    this.animacaoEtapas();
  }

  animacaoEtapas() {
    this.modalConfig.dialogClass = '';
    setTimeout(() => {
      this.modalConfig.dialogClass = 'animation-swal';
    }, 10);
  }

  show() {
    if (this.prop) {
      this.carregaConfiguracoes(this.prop);
    }

    swal({
      text: 'Precisamos de algumas informações para começar...',
      type: 'info',
      confirmButtonText: 'Iniciar',
      allowOutsideClick: false
    }).then(value => {
      if (value) {
        this.modalConfig.show();
      }
    });
  }

  enviarCertificado() {

    if (this.certificadoEnviado) {
      this.proximaEtapa();
      return;
    }

    const formCertificado = this.cadastroForm.get('certificado') as FormGroup;
    markFormGroupTouched(formCertificado);
    if (formCertificado.valid) {
      this.alteraLoading(true, 'certificado');
      let form = new FormData();
      form.append('arquivo', this.file);
      form.append('senha', this.cadastroForm.get('certificado.senha').value);
      this.unidadeEmpresaService.uploadCertificado(form)
        .pipe(finalize(() => this.alteraLoading(false, 'certificado')))
        .subscribe(() => {
          this.certificadoEnviado = true;
        }, (error) => {
          this.errors = error.msg.errors;
        })
    }
  }

  salvarConfiguracoes() {
    this.alteraLoading(true);
    this.parametrosNfceService.salvarParametros(this.id, this.cadastroForm.getRawValue())
      .pipe(finalize(() => this.alteraLoading(false)))
      .subscribe(() => {
        this.modalVisible = false;
        swal({
          text: 'Você já pode iniciar a emissão de sua primeira NFC-e!',
          type: 'success',
          confirmButtonText: 'QUERO COMEÇAR',
          allowOutsideClick: false
        }).then((result) => {
          if (result.value) this.modalConfig.hide();
        });
      }, (error) => {
        this.errors = error.msg.errors;
      })
  }
}
