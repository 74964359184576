import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { NotasFiscaisService } from '../../../../services/notas-fiscais.service';
import { markFormGroupTouched } from '../../../../general/utils';
import * as moment from 'moment';
import { MessageService } from '../../../../services/message.service';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-resumo-vendas-por-ncm',
  templateUrl: './resumo-vendas-por-ncm.component.html',
  styleUrls: ['./resumo-vendas-por-ncm.component.css']
})
export class ResumoVendasPorNcmComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalRelatorio", { static: true }) modalRelatorio;
  impressaoForm: FormGroup;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  loading: boolean = false;

  constructor(private notasFiscaisService: NotasFiscaisService, private message: MessageService) { super() }

  ngOnInit() {
    this.impressaoForm = new FormGroup({
      dataInicio: new FormControl(moment().startOf('month').toDate(), [Validators.required]),
      dataFim: new FormControl(moment().endOf('month').startOf('day').toDate(), [Validators.required]),
      orientacao: new FormControl('horizontal')
    })
  }

  destroyComponent() {
    this.closed.emit();
  }

  show(): void {
    this.modalRelatorio.show();
  }

  validaForm() {
    markFormGroupTouched(this.impressaoForm);
  }

  submitForm() {
    if (this.impressaoForm.valid) {
      this.loading = true;
      this.modalRelatorio.hide();
      this.notasFiscaisService.imprimirRelatorioResumoDeVendasPorNCM(this.impressaoForm.value)
        .pipe(finalize(() => this.loading = false))
        .subscribe(res => {
          saveAs(res, 'relatorio_resumo_vendas_por_ncm.pdf');
          this.destroyComponent();
        }, (error) => {
          this.message.addError({
            title: 'Falha ao gerar relatório',
            msg: error.errors[0],
            showClose: true, timeout: 20000, theme: 'bootstrap', closeOther: true
          });

          this.destroyComponent();
        })
    }
  }
}
