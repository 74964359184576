import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ItensPedidoPagamentoService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getById(id: number) {
    return this.http.get(this.UrlService + `itensPagamentoPedido/${id}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  adicionarPagamentoItensPedido(form) {
    return this.http.post(this.UrlService + 'itensPagamentoPedido/AdicionarQuantidadeItemPedido', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  deletar(id: number, tipoPagamento: string) {
    return this.http.delete(this.UrlService + `itensPagamentoPedido?id=${id}&tipoPagamento=${encodeURIComponent(tipoPagamento)}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  deletarTitulosPedido(ids: any[]) {
    return this.http.post(this.UrlService + 'itensPagamentoPedido/deletarTitulosPedido', ids)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

}