<app-modal-basic [allowClickOutside]="false" [hideHeader]="true" [visible]="modalVisible" [hideFooter]="true"
  #modalConfig (closed)="onClose()">

  <div class="app-modal-body">

    <form [formGroup]="cadastroForm">

      <div aria-labelledby="swal2-title" aria-describedby="swal2-content" class="swal2-popup swal2-modal swal2-show"
        tabindex="-1" role="dialog" aria-live="assertive" aria-modal="true" style="display: flex;">
        <div class="swal2-header">
          <ul class="swal2-progresssteps" style="display: flex;">
            <ng-template ngFor let-etapa [ngForOf]="etapas">
              <li [ngClass]="{'swal2-activeprogressstep': etapa == etapaAtual}" class="swal2-progresscircle">{{etapa}}
              </li>
              <li *ngIf="etapa != etapas.length" class="swal2-progressline"></li>
            </ng-template>
          </ul>
        </div>

        <div *ngIf="etapaAtual == 1">
          <div class="swal2-content">

            <h2 class="swal2-title" id="swal2-title">Numeração NFC-e</h2>

            <div id="swal2-content" style="display: block;">
              <div class="swal2-content" formGroupName="numeracao">
                <div id="swal2-content" style="display: block;">Já utilizou algum outro sistema para emissão de NFC-e?
                </div>
                <div class="swal2-radio" style="display: flex;">
                  <label><input type="radio" [value]="true" formControlName="emitiu">Sim</label>
                  <label><input type="radio" [value]="false" formControlName="emitiu">Não</label>
                </div>
                <div class="row form-input">
                  <div class="col-12">
                    <label>Número da última NFCe emitida</label>
                    <input digitOnly formControlName="numero" class="swal2-input">
                  </div>

                  <div class="col-12">
                    <label>Série utilizada</label>
                    <input digitOnly formControlName="serie" class="swal2-input">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swal2-actions" style="display: flex;">
            <button type="button" class="swal2-confirm swal2-styled" (click)="proximaEtapa()"
              style="border-left-color: rgb(48, 133, 214); border-right-color: rgb(48, 133, 214);">Próximo</button>
          </div>
        </div>

        <div *ngIf="etapaAtual == 2">
          <div class="swal2-content">

            <h2 class="swal2-title" id="swal2-title">Código de Segurança do Contribuinte (CSC)</h2>

            <div id="swal2-content" style="display: block;">
              <div class="swal2-content" formGroupName="codigosCsc">
                <div id="swal2-content" style="display: block;">Sua empresa já possui credenciamento em seu estado?
                </div>
                <div class="swal2-radio" style="display: flex;">
                  <label><input type="radio" [value]="true" formControlName="credenciado">Sim</label>
                  <label><input type="radio" [value]="false" formControlName="credenciado">Não</label>
                </div>

                <div class="row form-input" *ngIf="isCredenciado">
                  <div class="col-12">
                    <label>ID CSC</label>
                    <input formControlName="cscId" class="swal2-input">
                  </div>

                  <div class="col-12">
                    <label>CSC</label>
                    <input formControlName="csc" class="swal2-input">
                  </div>
                </div>

                <div *ngIf="!isCredenciado">
                  <div id="swal2-content" style="display: block;">Solicite o credenciamento junto a sua contabilidade
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swal2-actions" style="display: flex;">
            <button type="button" class="swal2-cancel swal2-styled" (click)="voltarEtapa()"
              style="display: inline-block;">Voltar</button>

            <button type="button" class="swal2-confirm swal2-styled" (click)="proximaEtapa()"
              style="border-left-color: rgb(48, 133, 214); border-right-color: rgb(48, 133, 214);">Próximo</button>
          </div>
        </div>


        <div *ngIf="etapaAtual == 3">
          <div class="swal2-content">

            <h2 class="swal2-title" id="swal2-title">Certificado Digital</h2>

            <div id="swal2-content" style="display: block;">
              <div class="swal2-content" formGroupName="certificado">
                <div *ngIf="!certificadoEnviado" id="swal2-content" style="display: block;">Para que sua NFC-e seja
                  aprovada, é necessário possuir
                  um certificado válido configurado no sistema!</div>

                <div *ngIf="certificadoEnviado" id="swal2-content" style="display: block; margin-top: 0.5em;">Tudo
                  certo! Seu certificado já está
                  configurado e pronto
                  para uso!</div>

                <div *ngIf="!certificadoEnviado" class="swal2-content">

                  <label class="custom-file">
                    <input type="file" [disabled]="loading['certificado']" type="file" accept=".pfx" #file
                      (change)="fileChanged(file.files)" class="custom-file-input" />
                    <span #customFileControl class="custom-file-control"
                      [ngClass]="{'disabled': loading['certificado']}"></span>
                  </label>
                  <show-errors [control]=" cadastroForm.get('certificado.arquivo')"></show-errors>

                  <input maxlength="10" [disableControl]="loading['certificado']" autocapitalize="off"
                    formControlName="senha" autocorrect="off" class="swal2-input input-senha" placeholder="Senha"
                    type="password" style="display: flex;">
                  <show-errors class="buceta" [control]="cadastroForm.get('certificado.senha')"></show-errors>

                  <div *ngIf="errors" class="swal2-validationerror" id="swal2-validationerror" style="display: flex;">
                    {{errors}}</div>

                </div>

              </div>
            </div>
          </div>

          <div *ngIf="loading['certificado']" class="swal2-actions swal2-loading cert-actions" style="display: flex;">
            <button type="button" class="swal2-confirm swal2-styled"
              style="border-left-color: rgb(48, 133, 214); border-right-color: rgb(48, 133, 214); display: flex;"></button>
          </div>

          <div *ngIf="!loading['certificado']" class="swal2-actions cert-actions" style="display: flex;">
            <button type="button" class="swal2-cancel swal2-styled" (click)="voltarEtapa()"
              style="display: inline-block;">Voltar</button>

            <button type="button" class="swal2-confirm swal2-styled" (click)="enviarCertificado()"
              style="border-left-color: rgb(48, 133, 214); border-right-color: rgb(48, 133, 214);">Próximo</button>
          </div>

          <div *ngIf="!certificadoEnviado && !loading['certificado']" class="swal2-footer" style="display: flex;"><a
              href="javascript:;" (click)="proximaEtapa()">Pular esta etapa</a>
          </div>
        </div>


        <div *ngIf="etapaAtual == 4">
          <div class="swal2-content">

            <h2 class="swal2-title" id="swal2-title">Forma de Pagamento</h2>

            <div id="swal2-content" style="display: block;">
              <div class="swal2-content" formGroupName="pagamento">
                <div id="swal2-content" style="display: block;">Selecione a forma de pagamento mais utilizada em suas
                  vendas</div>

                <select [disableControl]="loading['registro']" formControlName="forma" class="swal2-select"
                  style="display: flex;">
                  <option *ngFor="let pagamento of formasPagamento" [value]="pagamento.valor">{{pagamento.descricao}}
                  </option>
                </select>

              </div>

              <div *ngIf="errors" class="swal2-validationerror" id="swal2-validationerror" style="display: flex;">
                {{errors}}</div>

            </div>
          </div>

          <div *ngIf="loading['registro']" class="swal2-actions swal2-loading" style="display: flex;">
            <button type="button" class="swal2-confirm swal2-styled"
              style="border-left-color: rgb(48, 133, 214); border-right-color: rgb(48, 133, 214); display: flex;"></button>
          </div>

          <div *ngIf="!loading['registro']" class="swal2-actions" style="display: flex;">
            <button type="button" class="swal2-cancel swal2-styled" (click)="voltarEtapa()"
              style="display: inline-block;">Voltar</button>

            <button type="button" class="swal2-confirm swal2-styled" (click)="salvarConfiguracoes()"
              style="border-left-color: rgb(48, 133, 214); border-right-color: rgb(48, 133, 214);">Próximo</button>
          </div>
        </div>


      </div>

    </form>

  </div>


</app-modal-basic>