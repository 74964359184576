import { FormControl } from "@angular/forms";

export interface CreditoComissoesViewModel {
  id: number;
  representanteNome: string;
  representanteFornecedorId: number;
  valorComissao: number;
  saldoDaCC: number;
}

export interface CreditoComissoesErrors extends CreditoComissoesViewModel {
  motivo: string;
  controleId: number;
  dataInicial: Date;
  dataFinal: Date;
}

export type CreditoComissoesForm = {
  id: FormControl<number>;
  representanteNome: FormControl<string>;
  representanteFornecedorId: FormControl<number | null>;
  valorComissao: FormControl<number>;
  saldoDaCC: FormControl<number>;
}
