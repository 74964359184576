<app-combobox-padrao
  (added)="handleOnAdded($event)"
  [appendTo]="appendTo"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [clearOnBackspace]="clearOnBackspace"
  (changed)="handleOnChanged($event)"
  [disabled]="disabled"
  (edited)="handleOnEdited($event)"
  [formControl]="selected"
  [id]="id"
  [items]="items"
  [label]="label"
  [loading]="loading || loadingStates.request"
  [loadingText]="loadingText"
  modalName="portador"
  [notFoundText]="notFoundText"
  [notFoundPlusIcon]="notFoundPlusIcon"
  [pencilIcon]="pencilIcon"
  [placeholder]="placeholder"
  [required]="required"
  [requiredText]="requiredText"
  (scrollToEnd)="handleScrollToEnd()"
  [typeahead]="typeahead"
  >
</app-combobox-padrao>
