import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { ModalBasicComponent } from '../../../shared/modal-basic/modal-basic.component';

@Component({
  selector: 'app-selecionar-cfop-itens-replicados',
  templateUrl: './selecionar-cfop-itens-replicados.component.html',
  styleUrls: ['./selecionar-cfop-itens-replicados.component.css'],
})
export class SelecionarCfopItensReplicadosComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modalConsulta', { static: true }) modalConsulta: ModalBasicComponent;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  codigosFiscais = [];
  cfopSelecionado: number;

  constructor() {
    super();
  }

  ngOnInit() {
    this.configuracaoInicial();
  }

  show() {
    this.modalConsulta.show();
  }

  configuracaoInicial() {
    this.codigosFiscais = this.prop.cfopsVenda;
    this.codigosFiscais.sort((a, b) => (a.codigo < b.codigo ? -1 : 1));

    if (!this.cfopSelecionado) {
      this.cfopSelecionado = this.codigosFiscais[0].codigo || null;
    }
  }

  cfopChanged(cfop) {
    this.errors = cfop ? '' : 'É necessário selecionar um CFOP para replicar.';
  }

  replicar() {
    if (!this.cfopSelecionado) {
      this.errors = 'É necessário selecionar um CFOP para replicar.';
      return;
    }

    this.closed.emit({cfopSelecionado: this.cfopSelecionado});
  }
}
