import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { forkJoin, Observable, concat } from 'rxjs';
import { map, catchError, concatAll, toArray } from 'rxjs/operators';

@Injectable()
export class ContasPagarService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getWithFilters(consulta: any) {
    return this.http
      .post(this.UrlService + 'titulosfornecedores/buscar', consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  postForm(form: any) {
    return this.http
      .post(this.UrlService + 'titulosfornecedores/salvar', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  putForm(form) {
    return this.http.put(this.UrlService + `titulosfornecedores/alterar/`, form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  printRecibo(titulos: number[]) {
    return this.http
      .post(this.UrlService + 'titulosfornecedores/recibo', titulos, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  getTituloById(id: number) {
    return this.http
      .get(this.UrlService + 'titulosfornecedores/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  estornarTitulo(id: number) {
    return this.http
      .put(this.UrlService + 'titulosfornecedores/estornar/' + id, null)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  estornarGroupTitulos(ids: number[]) {
    const listToEstornar: Observable<any>[] = [];
    for (let i = 0; i < ids.length; i++) {
      listToEstornar.push(this.estornarTitulo(ids[i]));
    }
    return concat(listToEstornar).pipe(concatAll(), toArray());
  }

  postMultipleForm(form: any) {
    return this.http
      .post(this.UrlService + 'titulosfornecedores/salvarLista', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  deleteTitulo(id: number) {
    return this.http
      .delete(this.UrlService + 'titulosfornecedores/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  deleteGroupTitulos(ids: number[]) {
    const listToDelete = [];
    for (let i = 0; i < ids.length; i++) {
      listToDelete.push(this.deleteTitulo(ids[i]));
    }
    return concat(listToDelete).pipe(concatAll(), toArray());
  }

  getGroupTitulos(ids: number[]) {
    const list = [];
    for (let i = 0; i < ids.length; i++) {
      list.push(this.getTituloById(ids[i]));
    }
    return forkJoin(list);
  }

  baixarTitulos(titulos: any[]) {
    return this.http
      .post(this.UrlService + 'titulosfornecedores/baixarMultiplosTitulos', titulos)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  imprimir(consulta: any, params: { tipoRelatorio: string, modoDetalhado: boolean, orientacao: boolean, formato: boolean }) {
    return this.http
      .post(this.UrlService + `titulosfornecedores/imprimir/${params.tipoRelatorio}?modoDetalhado=${params.modoDetalhado}&orientacao=${params.orientacao}&planilha=${params.formato}`, consulta, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }

  getSerieNumeroTitulo() {
    return this.http
      .get(this.UrlService + 'titulosfornecedores/GetSerieNumeroTitulo')
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  BuscaTransacoesPorOfx(consulta: any) {
    return this.http
      .post(this.UrlService + `titulosfornecedores/BuscaTransacoesPorOfx/`, consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  BuscaTitulosFornecedorPorOfx(consulta: any) {
    return this.http
      .post(this.UrlService + `titulosfornecedores/BuscaTitulosFornecedorPorOfx/`, consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
