import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StorageFunctions } from '@generals';
import { getDeviceType } from '@utils';
import { Subscription } from 'rxjs';
import { AbrirModalComponent } from '../abrir-modal/abrir-modal.component';

@Component({
  selector: 'app-fab-atalhos',
  templateUrl: './fab-atalhos.component.html',
  styleUrls: ['./fab-atalhos.component.css']
})
export class AppFABAtalhosComponent implements OnInit {
  @ViewChild('abrirModal', { static: false }) abrirModal: AbrirModalComponent;
  modalSubscription: Subscription;

  buttons = [];

  config = {
    placment: 'br',
    effect: 'mfb-slidein',
    iconClass: 'fa fa-align-justify',
    activeIconClass: 'fa fa-align-center',
    toggle: getDeviceType() === 'mobile' ? 'click' : 'hover',
    buttons: this.buttons
  };

  constructor(
    private router: Router,
    private storageFunctions: StorageFunctions
  ) { }

  ngOnInit(): void {
    this.carregarAtalhosFAB();
  }

  carregarAtalhosFAB() {
    this.buttons = [
      {
        iconClass: 'fa fa-pencil',
        label: 'Editar atalhos',
        onClick: () => {
          this.modalSubscription = this.abrirModal.closed.subscribe(() => {
            this.modalSubscription.unsubscribe();
            this.carregarAtalhosFAB();
          });
          this.abrirModal.abrir('editarAtalhos');
        }
      },
    ];

    const atalhos = this.storageFunctions.getDataFromLocalStorage('fab-atalhos');

    for (const atalho of atalhos) {
      const obj = {
        iconClass: atalho.icone,
        label: atalho.caminho,
        onClick: () => {
          if (atalho.tipoDeLink === 'rota') {
            this.router.navigateByUrl(atalho.rotaCompleta);
          } else if (atalho.tipoDeLink === 'funcao') {
            this.abrirModal.abrir(atalho.parametro);
          }
        }
      };
      this.buttons.push(obj);
    }
  }
}
