import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class CidadesService extends BaseService {

  constructor(private http: HttpClient) { super(); }


  get() {
    return this.http.get(this.UrlService + 'cidades/' + '24')
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getByEstado(estadoId: number) {
    return this.http.get(this.UrlService + 'cidades/' + estadoId)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  async getEnderecoByCep(cep: any) {
    return await fetch('https://viacep.com.br/ws/' + cep + '/json/');
  }

  getByIbge(codIbge) {
    return this.http.get(this.UrlService + `cidades/ibge/${codIbge}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getBydId(codId) {
    return this.http.get(this.UrlService + `cidades/id/${codId}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
