import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class IntermediadorRecebedorService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + "IntermediadorRecebimentos/GetPaginado?pageIndex=" + pagina + "&search=" + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getById(id: number) {
    return this.http
      .get(this.UrlService + "IntermediadorRecebimentos/GetById/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
