import { Directive, Input, ViewContainerRef, TemplateRef } from '@angular/core';

@Directive({
  selector: '[ngHasAccess]'
})
export class NgHasAccessDirective {

  @Input() set ngHasAccess(telaId) {
    const telas = JSON.parse(localStorage.getItem('acessos'));
    let telaLiberada: boolean = telas.some(m => m.telas.some(t => t.telaId == telaId));

    if (telaLiberada) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainerRef.clear();
    }
  }

  constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>) { }

}
