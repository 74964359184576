<app-modal-basic #modal>
  <div class="app-modal-header">
    <h4 class="modal-title">
      <i class="modal-title icofont icofont-comment m-r-5"></i> Impressão Dashboard
    </h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-sm-6">
          <div class="checkbox-fade fade-in-default">
            <label>
              <input type="checkbox" formControlName="relacaoFaturamento">
              <span class="cr">
                <i class="cr-icon icofont icofont-ui-check txt-default"></i>
              </span>
              <span> Relação de faturamento</span>
            </label>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="checkbox-fade fade-in-default">
            <label>
              <input type="checkbox" formControlName="receitasDespesas">
              <span class="cr">
                <i class="cr-icon icofont icofont-ui-check txt-default"></i>
              </span>
              <span> Receitas x Despesas</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="checkbox-fade fade-in-default">
            <label>
              <input type="checkbox" formControlName="vendasEfetivadas">
              <span class="cr">
                <i class="cr-icon icofont icofont-ui-check txt-default"></i>
              </span>
              <span> Vendas Efetivadas</span>
            </label>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="checkbox-fade fade-in-default">
            <label>
              <input type="checkbox" formControlName="contasReceber">
              <span class="cr">
                <i class="cr-icon icofont icofont-ui-check txt-default"></i>
              </span>
              <span> Contas a Receber</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="checkbox-fade fade-in-default">
            <label>
              <input type="checkbox" formControlName="previsaoFinanceira">
              <span class="cr">
                <i class="cr-icon icofont icofont-ui-check txt-default"></i>
              </span>
              <span> Previsão Financeira</span>
            </label>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="checkbox-fade fade-in-default">
            <label>
              <input type="checkbox" formControlName="distribuicaoRecursos">
              <span class="cr">
                <i class="cr-icon icofont icofont-ui-check txt-default"></i>
              </span>
              <span> Distribuição dos Recursos</span>
            </label>
          </div>
        </div>
      </div>
      <button class="mt-2 btn btn-info" (click)="hide()">
        <i class="ti ti-back-left"></i> Voltar
      </button>
      <button class="mt-2 btn btn-primary pull-right" (click)="imprimir()">
        <i class="fa fa-print"></i> Imprimir
      </button>
    </form>
  </div>
</app-modal-basic>