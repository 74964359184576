import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EmbalagensDespachoService } from '../../../services/embalagens-despacho.service';
import swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-pesagem-volumes',
  templateUrl: './pesagem-volumes.component.html',
  styleUrls: ['./pesagem-volumes.component.css']
})
export class PesagemVolumesComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalVolumes', { static: true }) modalVolumes;
  volumes = [];
  editando = false;
  despachoForm = {};
  embalagens = [];
  quantidadeVolumes = 0;

  constructor(private embalagemService: EmbalagensDespachoService) {
    super();
  }

  setForm() {
    this.cadastroForm = new FormGroup({
      embalagem: new FormControl(null, [Validators.required]),
      pesoEmbalagem: new FormControl(null, [Validators.required]),
      pesoVolume: new FormControl(null, [Validators.required]),
      nomeEmbalagem: new FormControl(null),
      index: new FormControl(null)
    });
  }

  ngOnInit() {
    this.setForm();
    this.getEmbalagens();
  }

  getEmbalagens() {
    this.loading['embalagens'] = true;
    this.embalagemService.getAll()
      .pipe(finalize(() => this.loading['embalagens'] = false))
      .subscribe(res => {
        this.embalagens = res;
      });
  }

  show() {
    this.modalVolumes.show();
    this.volumes = this.prop.volumes;
    this.quantidadeVolumes = this.prop.quantidadeVolumes;
    this.despachoForm = this.prop.despachoForm;
  }

  adicionarVolume() {
    this.editando = true;
  }

  onEmbalagemChanged(event) {
    const pesoEmbalagem = (event || {})['pesoEmbalagem'];
    const nomeEmbalagem = (event || {})['nomeEmbalagem'];
    this.cadastroForm.get('pesoEmbalagem').setValue(pesoEmbalagem);
    this.cadastroForm.get('nomeEmbalagem').setValue(nomeEmbalagem);
  }

  submitFormEmbalagem() {
    if (this.cadastroForm.valid) {
      if (this.cadastroForm.value.index !== null) {
        this.volumes[this.cadastroForm.value.index] = this.cadastroForm.value;
      } else {
        this.volumes.push(this.cadastroForm.value);
      }
      this.cancelaDigitacao();
    }
  }

  cancelaDigitacao() {
    this.setForm();
    this.editando = false;
  }

  excluirVolume(index) {
    this.volumes.splice(index, 1);
  }

  editarVolume(index) {
    const volume = this.volumes[index];
    this.cadastroForm.setValue(volume);
    this.cadastroForm.get('index').setValue(index);
    this.editando = true;
  }

  finishPesagem() {
    this.closed.emit(this.volumes);
  }

  cancelarOperacao() {
    swal({
      title: 'Cancelar digitação dos volumes?',
      text: 'Ao cancelar a digitação, todos os dados preenchidos até o momento serão perdidos.',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      focusCancel: true,
      cancelButtonText: 'Não',
      confirmButtonClass: 'btn btn-success btn-swal-modal',
      cancelButtonClass: 'btn btn-danger mr-sm'
    }).then(result => {
      if (result.value) {
        this.modalVolumes.hide();
      }
    });
  }
}
