<app-temp-modal-cadastro #modalConsultar (closed)="onClose()" textHeader="Visualizar Pedido">
  <form [formGroup]="form">

    <h4 class="text-center centralized-title mt-1">Deseja visualizar o pedido (.pdf)?</h4>
    
    <div class="mt-3 text-center">
      <button type="button" class="btn btn-primary ripple light" (click)="imprimirPedido(pedido)"
      [disabled]="!pedido">Sim</button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalConsultar.hide()">Não</button>
    </div>
  </form>
</app-temp-modal-cadastro>