<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader" modalContentId="modal-processo-importacao">
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
    <div class="row">
      <div class="form-group col-sm-12" rem5 combobox [type]="'cliente'" formControlName="clienteContratanteID"
        [label]="'Cliente Contratante'" [(form)]="cadastroForm" appendTo="#modal-processo-importacao" [disableControl]="loading['request']">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12">
        <label class="label-required">
          <span>Processo Importação</span>
        </label>
        <input type="text" class="form-control" [disableControl]="loading['request']" formControlName="processoDeImportacao" />
        <show-errors [control]="cadastroForm.controls['processoDeImportacao']"></show-errors>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12">
        <label>
          <span>Observação</span>
        </label>
        <textarea class="form-control" [disableControl]="loading['request']" formControlName="observacoes" rows="3"></textarea>
        <show-errors [control]="cadastroForm.controls['observacoes']"></show-errors>
      </div>
    </div>

    <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'Salvar'" [loading]="loading['registro']" icone="ti-save"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalCadastro.hide()">Cancelar</button>
  </form>
</app-temp-modal-cadastro>


<app-abrir-modal #abrirModal></app-abrir-modal>