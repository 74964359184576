import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class AcessoTelasService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getAcessoLiberados() {
        return this.http.get(this.UrlService + 'AcessoTelas/liberadas/')
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    verificaAcesso(telaID) {
        return this.http.get(this.UrlService + `AcessoTelas/verificaAcesso/${telaID}`)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    ObterAcessosDoUsuario(usuarioID) {
        return this.http.get(this.UrlService + `AcessoTelas/acessosUsuario/${usuarioID}`)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    ObterAcessosTelasPorGrupos() {
        return this.http.get(this.UrlService + `AcessoTelas/acessosGrupos/`)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    salvarGruposDeAcessoTelas(newGrupo) {
        return this.http.post(this.UrlService + `AcessoTelas/salvar/`, newGrupo)
            .pipe(map(super.extractData)
                , catchError(e => throwError(e)));
    }

    ObterAcessoAcoesPorUsuario(acoes) {
        return this.http.post(this.UrlService + `AcessoTelas/acoesUsuario/`, acoes)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

}
