<div class="dropdown" #dropdown (mouseleave)="mouseLeaveHandle()" (body:mousedown)="clickOutsideHandle($event)">
  <ul class="dropdown-menu">
   <ng-container *ngIf="contextData && contextData.groups && contextData.groups.length > 0">
     <ng-container *ngFor="let group of contextData.groups">
       <ng-container *ngFor="let option of group.options">
         <li (click)="handleClick(option, $event)" (mouseenter)="handleHover(option, $event)" [ngClass]="{title: isTitle(option)}">
           <a>
             {{ titlePropsBind(option.label) }}

             <span class="arrow ti ti-angle-right" *ngIf="option.subContext">

            </span>
           </a>
         </li>
       </ng-container>
     </ng-container>
   </ng-container>
  </ul>
</div>
<!--
Em nenhum hipótese alterar os nomes das classes, pois elas são utilizadas em várias funções javascript.
Alterá-las significa, em primeira instância, comprometer o correto funcionamento do componente.
-->
