import { Injectable } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { AcessoTelasService } from "../services/acessoTelas.service";

@Injectable()
export class StorageFunctions {

  constructor(private acessoTelasService: AcessoTelasService) {}

  // VARIAVEIS PARA MONTAR MENU
  menuItemList: any = [];
  menuDropDownList: any = [];
  encontrado = false;
  // FIM

  private filtroDeConsultasKey = "filtroDeConsultas";

  /**
   * Obtém o armazenamento apropriado (localStorage ou sessionStorage) com base no tipo de login do usuário.
   *
   * @returns {Storage} O objeto de armazenamento (localStorage ou sessionStorage) a ser utilizado.
   *
   * @description
   * Esta função determina qual tipo de armazenamento (localStorage ou sessionStorage) deve ser usado
   * com base na presença de um token no sessionStorage.
   *
   * - Se um token for encontrado no sessionStorage, isso indica que o usuário fez login como Responsável Financeiro (Pagador),
   *   e o sessionStorage será retornado.
   * - Se nenhum token for encontrado, o login foi feito de forma padrão (via Web ou gestor), e o localStorage será retornado.
   *
   * O localStorage é usado para armazenar dados que persistem entre sessões do navegador, enquanto o sessionStorage
   * é usado para dados que são específicos para a sessão atual e são apagados quando a aba ou janela do navegador é fechada.
   */
  getStorage(): Storage {
    return sessionStorage.getItem("token") ? sessionStorage : localStorage;
  }

  getUser(): any {
    return JSON.parse(this.getStorage().getItem("user"));
  }

  getRotaPadrao(): any {
    return JSON.parse(this.getStorage().getItem("paramsWeb"))
      .caminhoPadraoAngular;
  }

  getMenuItens(): any {
    return JSON.parse(this.getStorage().getItem("itensMenu"));
  }

  getComboboxStorageFilters(comboboxType: any): any {
    let comboboxes = JSON.parse(this.getStorage().getItem("comboboxOptions"));
    if (comboboxes == null) {
      comboboxes = [];
    }

    const res = comboboxes.filter((x: any) => x.type === comboboxType)[0];
    return res ? res.options : null;
  }

  setComboboxStorageFilters(comboboxType: any, options: any): void {
    let comboboxes = JSON.parse(this.getStorage().getItem("comboboxOptions"));
    if (comboboxes == null) {
      comboboxes = [];
    }

    let hasType = false;
    comboboxes.forEach((combobox: any) => {
      if (combobox.type === comboboxType) {
        hasType = true;
        combobox.options = options;
      }
    });

    if (!hasType) {
      comboboxes.push({
        type: comboboxType,
        options: options,
      });
    }

    this.getStorage().setItem("comboboxOptions", JSON.stringify(comboboxes));
  }

  // retona um JSON { form: FormGroup, consultarSozinho: bool}
  getStorageFilters(nomeConsulta: string, form: FormGroup): StorageFilterObject | null {
    let consulta: ConsultaStorage;
    let newForm = new FormGroup({});
    for (let key of Object.keys(form.value)) {
      let control = form.controls[key];
      let copyControl = new FormControl({ ...control.value });
      newForm.addControl(key, copyControl);
    }
    let returnForm = { form: newForm, consultarSozinho: false, consulta: nomeConsulta };
    let storageConsultas = this.getFiltroDeConsultas();

    if (storageConsultas && storageConsultas.length) {
      storageConsultas.forEach((element) => {
        if (element.nomeConsulta == nomeConsulta) {
          consulta = element;
        }
      });
    }

    if (consulta) {
      Object.keys(consulta.filtros).forEach((element) => {
        newForm.controls[element].setValue(consulta.filtros[element]);
      });

      this.setConsultarSozinho(nomeConsulta, false);
      returnForm = {
        form: newForm,
        consultarSozinho: consulta.consultarSozinho,
        consulta: nomeConsulta,
      };
    }

    if (!storageConsultas || !consulta) {
      returnForm = null;
    }

    return returnForm;
  }

  /**
   * Busca as consultas armazenadas no localStorage.
   *
   * @returns {ConsultaStorage[]} Um array de objetos `ConsultaStorage` representando as consultas armazenadas,
   *  ou um array vazio se não houver consultas ou ocorrer um erro na leitura do localStorage.
   */
  private getFiltroDeConsultas(): ConsultaStorage[] {
    const cs = this.getStorage().getItem(this.filtroDeConsultasKey);
    if (!cs) {
      return [];
    }

    try {
      return JSON.parse(cs);
    } catch (e: unknown) {
      return [];
    }
  }

  /**
   * Obtém um objeto contendo os filtros de consulta armazenados.
   *
   * @param {string} consulta - O nome da consulta a ser recuperada.
   * @returns {StorageFilterObject} Um objeto contendo os dados da consulta.
   *
   * @description
   * Esta função recupera os filtros de uma consulta específica do armazenamento.
   * Se a consulta não for encontrada, o objeto retornado terá o valor `undefined` para `form` e `false` para `consultarSozinho`.
   */
  getStorageFiltersObject(consulta: string): StorageFilterObject {
    const cs: ConsultaStorage[] = this.getFiltroDeConsultas();
    if (!cs.length) {
      return { form: undefined, consultarSozinho: false, consulta };
    }

    const filtro = cs.find((c) => c.nomeConsulta == consulta);
    if (!filtro) {
      return { form: undefined, consultarSozinho: false, consulta };
    }

    this.setConsultarSozinho(consulta, false);

    const { filtros = undefined, consultarSozinho = false } = filtro;

    return { form: filtros, consultarSozinho: consultarSozinho, consulta };
  }

  /**
   * Salva ou atualiza os filtros de uma consulta no localStorage.
   *
   * @param {string} consulta - O nome da consulta a ser salva ou atualizada.
   * @param {any} formValues - Os valores dos filtros a serem armazenados.
   */
  setStorageFilters(consulta: string, formValues: any): void {
    let cs: ConsultaStorage[] = this.getFiltroDeConsultas();

    const consultaExistente = cs.some((c) => c.nomeConsulta === consulta);
    if (consultaExistente) {
      cs = cs.map((c) =>
        c.nomeConsulta === consulta ? { ...c, filtros: formValues } : c
      );
    } else {
      cs.push({
        nomeConsulta: consulta,
        consultarSozinho: false,
        filtros: formValues,
      });
    }

    this.getStorage().setItem(this.filtroDeConsultasKey, JSON.stringify(cs));
    return;
  }

  /**
   * Configura a flag 'consultarSozinho' de uma consulta armazenada no localStorage.
   *
   * @param {string} nomeConsulta - O nome da consulta a ser atualizada.
   * @param {boolean} status - O novo valor para a propriedade 'consultarSozinho' da consulta.
   */
  setConsultarSozinho(nomeConsulta: string, status: boolean): void {
    const cs: ConsultaStorage[] = this.getFiltroDeConsultas();
    if (cs.length) {
      const consultas = cs.map((c) =>
        c.nomeConsulta == nomeConsulta ? { ...c, consultarSozinho: status } : c
      );
      this.getStorage().setItem(this.filtroDeConsultasKey, JSON.stringify(consultas));
    }
    return;
  }

  setUser(responseLogin: any) {
    // Ver documentação na função getStorage
    localStorage.setItem("token", responseLogin.access_token);
    localStorage.setItem("user", JSON.stringify(responseLogin.user));
    localStorage.setItem("paramsWeb", JSON.stringify(responseLogin.paramsWeb));
  }

  setUserFinanceiro(responseLogin: any) {
    // Ver documentação na função getStorage
    sessionStorage.setItem("token", responseLogin.access_token);
    sessionStorage.setItem("user", JSON.stringify(responseLogin.user));
    sessionStorage.setItem("paramsWeb", JSON.stringify(responseLogin.paramsWeb));
  }

  setAcessos() {
    return new Promise((resolve, _) => {
      this.menuItemList = [];
      this.menuDropDownList = [];
      this.acessoTelasService.getAcessoLiberados().subscribe((resposta) => {
        let menusLaterais: any = [];
        resposta.forEach((item: any) => {
          if (item.tipoDeItem == "menuLateral") {
            menusLaterais.push(item);
          } else if (item.tipoDeItem == "subMenu") {
            menusLaterais.push(item);
          } else if (item.tipoDeItem == "dropDown") {
            if (item.tipoDeLink == "rota") {
              let rota = ["/"];
              if (item.itemDeMenuPai) {
                rota = this.montarRota(item.itemDeMenuPai, rota);
              }
              rota.push(item.rota);
              item.rota = rota;
            }
            this.menuDropDownList.push(item);
          }
        });
        this.setSubMenus(menusLaterais);

        this.removerSubMenuSemFilho();
        this.ordenarMenu();

        this.getStorage().setItem("itensMenu",
          JSON.stringify({
            menuLateral: this.menuItemList,
            menuDropDown: this.menuDropDownList,
          })
        );

        resolve(resposta);
      });
    });
  }

  private montarRota(itemPai: any, rota: any) {
    if (itemPai.itemDeMenuPai) {
      rota = this.montarRota(itemPai, rota);
    }
    rota.push(itemPai.rota);

    return rota;
  }

  private setSubMenus(subMenus: any) {
    subMenus.forEach((item: any, index: number) => {
      if (item.itemDeMenuPaiID == 0) {
        this.menuItemList.push(item);
        subMenus.splice(index, 1);
      } else {
        this.encontrado = false;
        this.menuItemList.some((itemList: any) => {
          if (itemList.id == item.itemDeMenuPaiID) {
            if (!itemList.filhos) {
              itemList.filhos = [];
            }
            itemList.filhos.push(item);
            this.encontrado = true;
          } else if (itemList.filhos) {
            itemList.filhos = this.adicionaFilho(itemList.filhos, item);
          }

          if (this.encontrado == true) {
            subMenus.splice(index, 1);
          }

          return this.encontrado == true;
        });
      }
    });

    if (subMenus.length > 0) {
      this.setSubMenus(subMenus);
    }
  }

  private adicionaFilho(filhos: any, item: any) {
    filhos.some((itemList: any) => {
      if (itemList.id == item.itemDeMenuPaiID) {
        if (!itemList.filhos) {
          itemList.filhos = [];
        }
        itemList.filhos.push(item);
        this.encontrado = true;
      } else if (itemList.filhos) {
        itemList.filhos = this.adicionaFilho(itemList.filhos, item);
      }
      return this.encontrado == true;
    });

    return filhos;
  }

  removerSubMenuSemFilho() {
    this.menuItemList.reduceRight((_: any, item: any, index: number, object: any) => {
      if (item.tipoDeItem == "subMenu") {
        if (item.filhos && item.filhos.length > 0) {
          item.filhos = this.verificaFilhosSubMenu(item.filhos);
        }

        if (!item.filhos || item.filhos.length == 0) {
          object.splice(index, 1);
        }
      }
    }, []);
  }

  verificaFilhosSubMenu(filhos: any) {
    filhos.reduceRight((_: any, item: any, index: number, object: any) => {
      if (item.tipoDeItem == "subMenu") {
        if (item.filhos && item.filhos.length > 0) {
          item.filhos = this.verificaFilhosSubMenu(item.filhos);
        }
        if (!item.filhos || item.filhos.length == 0) {
          object.splice(index, 1);
        }
      }
    }, []);

    return filhos;
  }

  private ordenarMenu() {
    this.menuItemList.forEach((item: any) => {
      if (item.filhos) {
        item.filhos = this.ordenarFilhos(item.filhos);
      }
    });

    this.menuItemList = this.menuItemList.sort(function (a: any, b: any) {
      return a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0;
    });

    this.menuDropDownList = this.menuDropDownList.sort(function (a: any, b: any) {
      return a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0;
    });
  }

  private ordenarFilhos(filhos: any) {
    filhos.forEach((item: any) => {
      if (item.filhos) {
        item.filhos = this.ordenarFilhos(item.filhos);
      }
    });

    return filhos.sort(function (a: any, b: any) {
      return a.ordem < b.ordem ? -1 : a.ordem > b.ordem ? 1 : 0;
    });
  }

  saveDataToLocalStorage(key: string, data: any): void {
    const dataStr = JSON.stringify(data);
    this.getStorage().setItem(key, dataStr);
  }

  getDataFromLocalStorage(key: string): any | null {
    const dataStr = this.getStorage().getItem(key);
    if (dataStr) {
      return JSON.parse(dataStr) as any;
    }

    return null;
  }

  removeKeyFromFiltroDeConsultas(key: string): void {
    const cs = this.getFiltroDeConsultas();
    if (!cs.length) {
      return;
    }

    const csFiltered = cs.filter(item => item.nomeConsulta !== key);
    if (csFiltered.length === cs.length) {
      return;
    }

    try {
      this.getStorage().setItem(this.filtroDeConsultasKey, JSON.stringify(csFiltered));
    } catch(e) {
      return;
    }
  }
}

/**
 * Representa uma consulta armazenada no localStorage.
 *
 * @property {string} nomeConsulta - Chave da consulta para salvar e buscar.
 * @property {any} filtros - Filtro da consulta. Pode ser um objeto ou um array de objetos, dependendo da estrutura do filtro.
 * @property {boolean} [consultarSozinho=false] - Flag para ser utilizada quando voltar de um cadastro e é chamado o método `voltar()`.
 */
interface ConsultaStorage {
  nomeConsulta: string;
  filtros: any;
  consultarSozinho: boolean;
}

/**
 * Representa o resultado da consulta salva no localStorage para utilizar em formulários de consulta.
 *
 * @property {any} form - Os filtros da consulta, ou `undefined` se a consulta não for encontrada.
 * @property {boolean} consultarSozinho - O valor da flag `consultarSozinho` da consulta, ou `false` se a consulta não for encontrada.
 * @property {string} consulta - O nome da consulta (o mesmo valor passado como parâmetro).
 */
interface StorageFilterObject {
  form: any;
  consultarSozinho: boolean;
  consulta: string;
}
