import { Decimal } from 'decimal.js';
import { roundCorrectly } from '../general/utils';

export function valorItemSemDesconto(item): number {
    return new Decimal(item.preco).times(new Decimal(item.quantidade)).toNumber();
}

export function valorItemSelecionadoSemDesconto(item): number {
    return new Decimal(item.preco).times((new Decimal(item.quantidadeFechar || 0)).plus(new Decimal(item.quantidadeFechada || 0))).toNumber();
}

export function valorItemSelecionadoSemDescontoAFechar(item): number {
    return new Decimal(item.preco).times((new Decimal(item.quantidadeFechar || 0))).toNumber();
}

export function totalGeralDescontos(itens: any[]) {
    return Number(itens.reduce((total, item) => total.plus(CalculaValorDesconto(valorItemSemDesconto(item), item.valorDesconto, item.tipoDesconto)), new Decimal(0)).toFixed(2, Decimal.ROUND_HALF_EVEN));
}

export function totalGeralDescontosItensSelecionados(itens: any[]) {
    return Number(itens.reduce((total, item) => total.plus(CalculaValorDesconto(valorItemSemDesconto(item), ((item.valorDesconto || 0) / item.quantidade), item.tipoDesconto)), new Decimal(0)).toFixed(2, Decimal.ROUND_HALF_EVEN));
}

export function CalculaValorDesconto(valorItem, valorDesconto, tipoDesconto): number {
    valorItem = new Decimal(valorItem || 0);
    valorDesconto = new Decimal(valorDesconto || 0);


    if (tipoDesconto === 0) { return valorDesconto.toNumber(); }
    if (tipoDesconto === 1) { return valorItem.times(valorDesconto.div(100)).toNumber(); }
}

export function somaDosImpostos(item): number {
    if (item.impostos) {
        // Adicionar impostos ao total conforme demanda o Edson.
        const ICMSST = item.impostos.valorICMSST || 0;
        const IPI = item.impostos.valorIPI || 0;
        return roundCorrectly(ICMSST + IPI, 2).toNumber();
    }
    return 0;
}

export function totalGeralItens(itens: any[]): number {
    return Number(itens.filter(item => !item.naoSomarTotalNFe).reduce((total, item) => {
        return roundCorrectly(total.plus(valorItemSemDesconto(item) || 0), 2);
    }, new Decimal(0)));
}

export function totalGeralItensSelecionados(itens: any[]): number {
    return Number(itens.reduce((total, item) => {
        return roundCorrectly(total.plus(valorItemSelecionadoSemDesconto(item) || 0), 2);
    }, new Decimal(0)));
}

export function totalGeralItensSelecionadosAFechar(itens: any[]): number {
    return Number(itens.reduce((total, item) => {
        return roundCorrectly(total.plus(valorItemSelecionadoSemDescontoAFechar(item) || 0), 2);
    }, new Decimal(0)));
}

export function totalGeralNota(itens: any[]): number {
    return Number(itens.reduce((total, item) => {
        if (item && (item.bonificacaoOuBrinde || item.naoSomarTotalNFe)) {
            return total.plus(new Decimal(0));
        }
        return roundCorrectly(total.plus(valorItemSemDesconto(item) || 0).plus(somaDosImpostos(item)), 2);
    }, new Decimal(0)));
}
