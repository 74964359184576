import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { MessageService } from './message.service';
import { of } from 'rxjs';


@Injectable()
export class ClientesService extends BaseService {

    constructor(private http: HttpClient, private message: MessageService) { super(); }

    getClienteById(id: number) {
        if (!id) {
            return of(undefined);
        }
        return this.http
            .get(this.UrlService + 'clientes/' + id)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    getClienteContasAReceberById(id: number) {
        if (!id) {
            return of(undefined);
        }
        return this.http
            .get(this.UrlService + 'clientes/contasReceber/' + id)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    getCadastroClienteById(id: number) {
        return this.http
            .get(this.UrlService + 'clientes/cadastro/' + id)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    getClienteByCpf(cpf: string, excluirEmpresaLogada: boolean) {
        return this.http
            .get(this.UrlService + 'clientes/cpf/' + cpf + `/?excluirEmpresaLogada=${excluirEmpresaLogada}`)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    getVerificaCpfCnpj(cpfCpnj: string) {
        return this.http
            .get(this.UrlService + 'clientes/verificaCpfCnpj/' + cpfCpnj)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    getWithFilters(consulta: any) {
        return this.http
            .post(this.UrlService + 'clientes', consulta)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    postSalvar(form: any) {
        return this.http
            .post(this.UrlService + 'clientes/salvar', form)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    getEmpresas(razaoSocial: any) {
        return this.http.get(this.UrlService + `clientes?search=${razaoSocial}`)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    getClientesPaginado(pagina: number, nome: string, nomeFantasia = false) {
        let filtro = 'razaoSocial';
        if (nomeFantasia) { filtro = 'nomeFantasia'; }

        return this.http
            .get(this.UrlService + `clientes/paginado/${filtro}?pageIndex=` + pagina + '&search=' + encodeURIComponent(nome))
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    getByOData(valorFiltro: string) {
        return this.http.get(this.UrlService + `odata/clientes${valorFiltro}`)
            .pipe(map(super.extractODataSingleResult), catchError(super.serviceError));
    }

    getClientesPaginadoOData(valorFiltro: string, filter, skip: number) {
        let filtro = 'razaoSocial';
        if (filter) { filtro = filter; }
        // TODO: passar as props por parametro dependendo do que cada tela precisa
        // tslint:disable-next-line: max-line-length
        const odataQuery = `?$select=Id, RazaoSocial, NomeFantasia, CNPJ, CondicaoPagamentoId, FornecedorTransportadorId, PortadorId, ContaRecebedoraID, PortadorID, contaRecebedoraID, cidadeId&$expand=cidade($expand=estado($select=id))
        &$orderBy=${filtro} asc&$top=20&$skip=${skip}&$filter=contains(${filtro}, '${encodeURIComponent(valorFiltro)}') and ${filtro} ne '' and ${filtro} ne null`;

        return this.http.get(this.UrlService + `odata/clientes${odataQuery}`)
            .pipe(map(super.extractODataResult), catchError(super.serviceError));
    }

    getClientesPaginadoMultiplosFiltrosOData(valorFiltro: string, filters, skip: number) {
        const filtroFormatado = (param) => {
            return `(contains(${param}, '${encodeURIComponent(valorFiltro)}') and ${param} ne '' and ${param} ne null)`;
        }
        let filterString = '';

        if (filters && filters.length > 0) {
            filterString = filters.map(filter => {
                return filtroFormatado(filter.attr);
            }).join(' or ');
        } else {
            filterString = filtroFormatado('razaoSocial');
        };

        // TODO: passar as props por parametro dependendo do que cada tela precisa
        // tslint:disable-next-line: max-line-length
        const odataQuery = `?$select=Id, RazaoSocial, NomeFantasia, CNPJ, ClienteFinal, IndicadorIE, InscricaoEstadual, Simples, CondicaoPagamentoId, FornecedorTransportadorId, PortadorId, ContaRecebedoraID, PortadorID, contaRecebedoraID, cidadeId&$expand=cidade($expand=estado($select=id, aliquotaInterna, siglaEstado, permiteClienteIsento))
        &$orderBy=${(filters && filters.length > 0) ? filters[0].attr : 'razaoSocial'} asc&$top=20&$skip=${skip}&$filter=(${filterString})`;

        return this.http.get(this.UrlService + `odata/clientes${odataQuery}`)
            .pipe(map(super.extractODataResult), catchError(super.serviceError));
    }

    updateCliente(cliente) {
        return this.http.put(this.UrlService + 'Clientes', cliente)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    post(cliente) {
        return this.http.post(this.UrlService + 'Clientes/cadastro', cliente)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    delete(id: number) {
        return this.http.delete(this.UrlService + `Clientes/${id}`)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    imprimirRelacaoDeClientes(consulta: any) {
        const idToast = this.message.addToast({
            title: 'Emitindo Relatório',
            msg: 'Relação de Clientes',
            showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
        });
        return this.http
            .post(this.UrlService + 'Clientes/imprimirRelacaoDeClientes', consulta, { responseType: 'blob' })
            .pipe(finalize(() => this.message.clear(idToast))
                , catchError(super.parseErrorBlob));
    }

    replicarClienteParaFornecedor(clienteId: any[]) {
        return this.http.post(this.UrlService + 'clientes/replicarClienteParaFornecedor', clienteId)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    verificarFornecedorExistente(cnpj: string) {
        return this.http.post(this.UrlService + 'clientes/verificarFornecedorExistente', cnpj)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    getUsuarioComoCliente() {
        return this.http.get(this.UrlService + 'clientes/cadastrarUsuarioComoCliente')
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    enviarCodigoWhatsApp(numero: string) {
        const model = {
            numero: "+55" + numero,
        }
        return this.http.post(this.UrlService + 'clientes/EnviarOTPWhatsApp', model)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }
}
