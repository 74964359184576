<p (click)="isCollapsed = !isCollapsed" class="header-button-container">
  <span>
    {{header}}
  </span>

  <i class="icofont" [ngClass]="isCollapsed ? 'icofont-rounded-down' : 'icofont-rounded-up'"></i>
</p>

<div [@smoothCollapse]="isCollapsed ? 'collapsed' : 'opened'">
  <ng-content></ng-content>
</div>