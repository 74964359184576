import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class BancoEventosService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getAllEventos(bancoId: number = null) {
    return this.http.get(this.UrlService + `bancoEventos/getAllEventos/${bancoId}`)
      .pipe(map((res: any) => res.data))
      .pipe(catchError(err => err));
  }

  getById(id) {
    return this.http.get(this.UrlService + 'bancoEventos/getById/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  delete(id) {
    return this.http.delete(this.UrlService + 'bancoEventos/delete/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  postForm(form) {
    return this.http.post(this.UrlService + 'bancoEventos/postForm/', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  putForm(form) {
    return this.http.put(this.UrlService + 'bancoEventos/putForm/', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  saveForm(form, id) {
    if (id > 0) {
      return this.putForm(form);
    } else {
      return this.postForm(form);
    }
  }
}
