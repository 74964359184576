<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader" modalContentId='modal-detalhes-cliente'>
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">

    <div class="row">
      <div class="form-group col-sm-12">
        <label class="label-required">Razão social</label>
        <input id="razaoSocial" [disableControl]="loading['registro']" type="text" class="form-control"
          formControlName="razaoSocial" />
        <show-errors [control]="cadastroForm.controls.razaoSocial"></show-errors>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-12">
        <label class="label-required">CPF/CNPJ</label>
        <input id="cpfCnpj" [mask]="retornaMascaraCpfCnpj()" (blur)="getInfEmpresaByCpfCnpj()"
          [disableControl]="loading['registro']" type="text" class="form-control" formControlName="cnpj" />
        <show-errors [control]="cadastroForm.controls.cnpj"></show-errors>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-6">
        <label class="label-required">Tipo Contribuinte</label>
        <ng-select appendTo="#modal-detalhes-cliente" [searchable]="false" formControlName="indicadorIE" 
          [items]="tipoIE" bindLabel="name" bindValue="id" placeholder="Selecione">
        </ng-select>
        <show-errors [control]="cadastroForm.controls.indicadorIE"></show-errors>
      </div>

      <div class="form-group col-sm-6">
        <label class="label-required">Insc. Est.</label>
        <input [disableControl]="loading['registro'] || clienteIsento" 
          type="text" class="form-control" formControlName="inscricaoEstadual" />
        <show-errors [control]="cadastroForm.controls.inscricaoEstadual"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6 check-form">
        <div class="checkbox-fade fade-in-default">
          <label>
            <input type="checkbox" formControlName="clienteFinal"
              [disableControl]="loading['registro'] || clienteIsento">
            <span class="cr">
              <i class="cr-icon icofont icofont-ui-check txt-default"></i>
            </span>
            <span class="text-inverse">
              Consumidor Final
            </span>
          </label>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6">
        <label>CEP</label>
        <input id="cep" (blur)="carregaViaCep()" type="text" class="form-control"
          [disableControl]="loading['registro'] || loading['endereco']" formControlName="cep" mask='00000-000'>
      </div>

      <div class="form-group col-sm-6">
        <label class="label-required">Bairro</label>
        <input [disableControl]="loading['registro'] || loading['endereco']" type="text" class="form-control"
          formControlName="bairro" />
        <show-errors [control]="cadastroForm.controls.bairro"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-8">
        <label class="label-required">Endereço</label>
        <input [disableControl]="loading['registro'] || loading['endereco']" type="text" class="form-control"
          formControlName="endereco" />
        <show-errors [control]="cadastroForm.controls.endereco"></show-errors>
      </div>

      <div class="form-group col-sm-4">
        <label class="label-required">Número</label>
        <input [disableControl]="loading['registro'] || loading['endereco']" digitOnly class="form-control"
          formControlName="numero" />
        <show-errors [control]="cadastroForm.controls.numero"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6">
        <label class="label-required">Estado</label>
        <ng-select (ngModelChange)="alteraEstado($event)" formControlName="estado"
          [disableControl]="loading['registro'] || loading['estados'] || loading['endereco']"
          [loading]="loading['estados']" placeholder="Selecione" [items]="estados" bindLabel="nome" bindValue="id">
        </ng-select>
        <show-errors [control]="cadastroForm.controls.estado"></show-errors>
      </div>

      <div class="form-group col-sm-6">
        <label class="label-required">Cidade</label>
        <ng-select
          [disableControl]="loading['registro'] || loading['cidades'] || loading['endereco'] || loading['cidadeIbge']"
          [loading]="loading['cidades'] || loading['cidadeIbge']" placeholder="Selecione" [items]="cidades"
          bindLabel="nome" bindValue="id" formControlName="cidadeId">
        </ng-select>
        <show-errors [control]="cadastroForm.controls.cidadeId"></show-errors>
      </div>
      <div class="form-group col-sm-12">
        <label>E-mail</label>
        <input type="text" class="form-control" formControlName="email" placeholder="E-mail">
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6">
        <label>Telefone</label>
        <input (keydown)="verificaNumeroTelefone($event)" placeholder="Telefone"
          class="form-control" type="text"  mask='(00) 0000-0000' formControlName="telefone">
          <show-errors [control]="cadastroForm.controls['telefone']"></show-errors>
      </div>
      <div class="form-group col-sm-6">
        <label>Celular</label>
        <input placeholder="Celular"
          class="form-control" type="text" id="celular" mask='(00) 0 0000-0000' formControlName="celular">
          <show-errors [control]="cadastroForm.controls['celular']"></show-errors>
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="errors">
      <span>{{ errors }}</span>
    </div>

    <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'" [loading]="loading['registro']"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalCadastro.hide()">Cancelar</button>
  </form>
</app-temp-modal-cadastro>