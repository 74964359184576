import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { catchError } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { MessageService } from './message.service';

@Injectable()
export class NotasFiscaisComunicacaoService extends BaseService {

  constructor(private http: HttpClient, private message: MessageService) { super(); }

  imprimirRelatorio(consulta: any) {
    let idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Notas Fiscais Comunicacão',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    })
    return this.http
      .post(this.UrlService + 'notasfiscaiscomunicacao/imprimir', consulta, { responseType: 'blob' })
      .pipe(finalize(() => this.message.clear(idToast)))
      .pipe(catchError(super.parseErrorBlob))
  }
}
