<app-modal-basic #modalRelatorio (closed)="destroyComponent()" [modalContentId]="'modal-evasao'">
	<div class="app-modal-header">
		<h4 class="modal-title">Impressão relatório de Evasão</h4>
	</div>
	<div class="app-modal-body">
		<form [formGroup]="form">
			<div class="row">
				<div class="form-group col-sm-12">
					<label style="font-weight: bold">Período</label>
					<div class="input-group">
						<ng-select
							appendTo="#modal-evasao"
							formControlName="mes"
							placeholder="Selecione o mês"
							bindLabel="value"
							bindValue="id"
              (change)="changeMes($event)"
							[items]="meses"
						>
						</ng-select>
					</div>
				</div>
			</div>
			<div class="row flex">
				<div class="form-radio col-sm-12" style="margin-bottom: 10px">
					<div class="m-b-10">
						<span>Formato</span>
					</div>
					<div class="radio radio-inline">
						<label>
							<input
								type="radio"
								formControlName="formato"
								[value]="true"
							/>
							<i class="helper"></i>.PDF
						</label>
					</div>
					<div class="radio radio-inline">
						<label>
							<input
								type="radio"
								formControlName="formato"
								[value]="false"
							/>
							<i class="helper"></i>.XLSX
						</label>
					</div>
				</div>
			</div>
			<button class="btn btn-primary ripple light" (click)="gerarPDF()">
				<i class="icofont icofont-print"> </i> Imprimir
			</button>
			<button
				type="button"
				class="btn btn-danger ripple"
				(click)="destroyComponent()"
			>
				<i class="ti ti-close"></i>
				Cancelar
			</button>
		</form>
	</div>
</app-modal-basic>
