import { Component, forwardRef, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { RepresentantesService } from '../../services/representantes.service';
import { Representante, RepresentantePorCliente } from '../../model/RepresentantesViewModel';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { mergeArray } from '../../general/utils';
import { AbrirModalComponent } from '../../shared/abrir-modal/abrir-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-combobox-vendedor-por-cliente',
  templateUrl: './combobox-vendedor-por-cliente.component.html',
  styleUrls: ['./combobox-vendedor-por-cliente.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxVendedorPorClienteComponent),
      multi: true
    }
  ]
})
export class ComboboxVendedorPorClienteComponent implements ControlValueAccessor {

  @ViewChild('abrirModal', { static: false }) abrirModal: AbrirModalComponent;

  writeValue(obj: any): void {
    this.vendedorSelecionado = obj;
    if (obj) {
      this.carregaObjetoVendedor(obj);
    }
  }

  private propagateChange = (_: any) => { };
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  onTouched = () => { };
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  disabled: boolean = false;

  vendedores = [];
  vendedorSelecionado = null;
  loading = {};
  userRepresentante = false;
  onlyCustomers = false;

  @Input() listarInativos: boolean = false;
  @Output() change = new EventEmitter();

  constructor(private representantesService: RepresentantesService) { }

  private carregaTodosVendedores() {
    this.onlyCustomers = false;
    return new Promise((resolve, reject) => {
      this.representantesService.getByOData()
        .subscribe(res => {
          this.vendedores = res.value.filter(r => (this.vendedorSelecionado && r.id == this.vendedorSelecionado) || !r.inativo);
          this.verificaVendedorNaLista();
          resolve({});
        });
    })
  }

  private carregaVendedoresPorCliente(clienteId, emitChange: boolean) {
    return new Promise((resolve, reject) => {
      this.representantesService.getRepresentantesPorCliente({ id: clienteId, listarInativos: true })
        .subscribe(async (res: []) => {
          let vendedoresCliente = res.map((vendedor: RepresentantePorCliente) => {
            const representante: Representante = vendedor.representante;
            return {
              id: representante.id,
              nome: representante.nome,
              tipoComissao: representante.tipoComissao,
              tipoComissaoId: representante.tipoComissaoId,
              preposto: representante.representantePreposto,
              inativo: representante.inativo
            }
          }).filter(r => (this.vendedorSelecionado && r.id == this.vendedorSelecionado) || !r.inativo);

          //emitChange: para casos onde ao editar um pedido o vendedor selecionado nao faz parte dos clientes do vendedor
          if (emitChange) {
            this.vendedores = vendedoresCliente;
          } else {
            this.vendedores = mergeArray(this.vendedores, vendedoresCliente);
          }

          //se cliente não tiver vendedores, carrega todos
          if (this.vendedores.length == 0) {
            await this.carregaTodosVendedores();
          } else {
            this.verificaVendedorNaLista();
          }
          resolve({});
        });
    });
  }

  async carregaVendedores(clienteId: any = null, emitChange: boolean = true) {
    this.loading['vendedores'] = true;
    this.onlyCustomers = false;
    this.userRepresentante = false;

    //se é usuario representante, não pode alterar vendedor
    await this.representantesService.getByUser().toPromise()
      .then(async vendedor => {
        if (vendedor['id']) {
          this.userRepresentante = true;
          //emitChange: para nao alterar o vendedor selecionado quando um pedido for clonado ou visualizado
          if (!this.vendedorSelecionado && emitChange) {
            this.carregaObjetoVendedor(vendedor['id']).then(() => {
              this.vendedorChanged(vendedor);
            });
          }
        } else {
          //se tiver cliente, carrega somente os vendedores do cliente
          if (clienteId) {
            this.onlyCustomers = true;
            await this.carregaVendedoresPorCliente(clienteId, emitChange);
          } else {
            //senão tiver cliente, carrega todos vendedores
            await this.carregaTodosVendedores();
          }
        }
      });

    this.loading['vendedores'] = false;
  }

  verificaVendedorNaLista() {
    if (this.vendedores.length == 1) {
      this.vendedorChanged(this.vendedores[0]);
    }

    if (this.vendedorSelecionado) {
      if (!this.vendedores.some(t => t.id == this.vendedorSelecionado)) {
        this.vendedorChanged(null);
      }
    }
  }

  vendedorChanged(vendedor) {
    const vendedorId = vendedor && vendedor.id ? vendedor.id : null;
    this.vendedorSelecionado = vendedorId;
    this.propagateChange(this.vendedorSelecionado);
    this.change.emit(vendedor);
  }

  modalClosed(id) {
    this.modalSubscription.unsubscribe();
    if (id && !this.userRepresentante) {
      this.carregaObjetoVendedor(id).then((res: any) => {
        this.vendedorChanged(res);
      })
    }
  }

  private carregaObjetoVendedor(representanteId) {
    return new Promise((resolve) => {
      this.representantesService.getRepresentanteById(representanteId)
        .subscribe((res) => {
          this.vendedores.unshift(res);
          this.vendedores = mergeArray(this.vendedores);
          resolve(res);
        }, () => {
          resolve(null);
        })
    })
  }

  private modalSubscription: Subscription;

  subscribeModal() {
    this.modalSubscription = this.abrirModal.closed.subscribe(this.modalClosed.bind(this))
  }

  nomeRepresentante(item: Representante): string {
    let tagDesativado = '';
    if (item.inativo) {
      tagDesativado = ' (Desativado)'
    }
    return `${item.nome}${tagDesativado}`;
  }
}
