import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-relatorio-contas-pagar',
  templateUrl: './relatorio-contas-pagar.component.html',
  styleUrls: ['./relatorio-contas-pagar.component.css'],
})
export class RelatorioContasPagarComponent extends ModalCadastroComponent implements OnInit {
  constructor() {
    super();
  }

  @ViewChild('modalRelatorio', { static: true }) modalRelatorio;
  form: FormGroup;

  tiposRelatorio = [
    { id: 0, label: 'Contas Contraídas' },
    { id: 1, label: 'Contas por Fornecedor' },
    { id: 2, label: 'Contas por Vencimento' },
    { id: 3, label: 'Contas Pagas' },
    { id: 4, label: 'Contas por Grupo de Despesa' },
    { id: 5, label: 'Contas por Centro de Custo' },
  ];

  ngOnInit() {
    this.form = new FormGroup({
      tipoRelatorio: new FormControl(0, [Validators.required]),
      modoDetalhado: new FormControl(false),
      orientacao: new FormControl(false),
      formato: new FormControl(false),
    });
  }

  show(): void {
    this.modalRelatorio.show();
  }

  close() {
    this.closed.emit(null);
  }

  imprimir() {
    if (this.form.invalid) {
      return;
    }
    this.closed.emit(this.form.value);
  }
}
