import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { markFormGroupTouched } from '../../../general/utils';
import swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';
import { ContabilidadesService } from '../../../services/contabilidades.service';
import { mergeArray } from '../../../general/utils';
import { distinctUntilChanged, debounceTime, switchMap, tap } from 'rxjs/operators'
import { Subject } from 'rxjs';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-conclusao-adesao',
  templateUrl: './conclusao-adesao.component.html',
  styleUrls: ['./conclusao-adesao.component.css']
})
export class ConclusaoAdesaoComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalRelatorio", { static: true }) modalRelatorio;
  impressaoForm: FormGroup;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  loading: boolean = false;
  contabilidadeOptions = [];
  loadingContabilidade: boolean = false;
  contabilidadeInput = new Subject<string>();

  constructor(private contabilidadeService: ContabilidadesService) { super() }

  ngOnInit() {
    this.impressaoForm = new FormGroup({
      periodoDe: new FormControl(),
      periodoAte: new FormControl(),
      contabilidadeID: new FormControl(),
      apenasNaoPagos: new FormControl(false)
    })

    this.loadInitialData();
    this.onSearch();
  }

  destroyComponent() {
    this.closed.emit();
  }

  show(): void {
    this.modalRelatorio.show();
  }

  validaForm() {
    markFormGroupTouched(this.impressaoForm);
  }

  submitForm() {
    if (this.impressaoForm.valid) {
      this.loading = true;
      this.modalRelatorio.hide();
      this.contabilidadeService.imprimirConclusaoAdesao(this.impressaoForm.value)
        .pipe(finalize(() => this.loading = false))
        .subscribe(res => {
          saveAs(res, 'relatorio_premiacoes.pdf');
          this.destroyComponent();
        }, (error) => {
          swal("Erro ao emitir o relatório: Premiações", error.errors[0], "error");
          this.destroyComponent();
        })
    }
  }

  fetchMore(term) {
    this.loadingContabilidade = true;
    this.contabilidadeService.getContabilidades(((this.contabilidadeOptions.length / 20) + 1), term || '')
      .pipe(finalize(() => this.loadingContabilidade = false))
      .subscribe(result => {
        this.contabilidadeOptions = mergeArray(this.contabilidadeOptions, result);
      });
  }

  onSearch() {
    this.contabilidadeInput.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => this.loadingContabilidade = true),
      switchMap(term => this.contabilidadeService.getContabilidades(1, term || '').pipe(
        tap(() => this.loadingContabilidade = false)
      ))
    )
      .subscribe(data => {
        this.contabilidadeOptions = data;
      })
  }

  private loadInitialData() {
    this.contabilidadeService.getContabilidades(1, '')
      .subscribe(results => this.contabilidadeOptions = results)
  };
}
