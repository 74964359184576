import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';

import { MessageService } from '@services';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PortadoresServiceV2 } from '@servicesv2';
import { ComboboxPadrao } from 'models/combobox-padrao/combobox-padrao';
import { PortadoresViewModel } from '@financeiro';

@Component({
  selector: 'app-combobox-portadores',
  templateUrl: './combobox-portadores.component.html',
  styleUrls: ['./combobox-portadores.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxPortadoresComponent),
      multi: true
    }
  ]
})
export class ComboboxPortadoresComponent extends ComboboxPadrao<PortadoresViewModel> implements ControlValueAccessor {
  override bindLabel = 'descricao';
  override label = 'Forma de Pagamento (Portador)';
  @Input() override required = false;
  @Input() override requiredText = 'A forma de pagamento (portador) é obrigatório';

  @Input() loading = false;
  @Input() disabled = false;
  @Input() notFoundPlusIcon = true;
  @Input() pencilIcon = true;
  @Output() changed = new EventEmitter();

  constructor(
    service: PortadoresServiceV2,
    message: MessageService,
  ) {
    super('combobox_portadores', message, service);
  }

  writeValue(value: number): void {
    this.selected.setValue(value);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  handleOnChanged(value: number) {
    this.selected.setValue(value);
    this.changed.emit(value);
    this.onChange(value);
  }

  handleOnAdded(value: number) {
    this.selected.setValue(value);
    this.changed.emit(value);
    this.onChange(value);
  }

  handleOnEdited(value: number) {
    this.selected.setValue(value);
    this.changed.emit(value);
    this.onChange(value);
  }
}
