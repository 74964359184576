import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-dim-checkbox',
  templateUrl: './dim-checkbox.component.html',
  styleUrls: ['./dim-checkbox.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DimCheckboxComponent),
      multi: true
    }
  ]
})
export class DimCheckboxComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() tooltipContainer = 'body';
  @Input() tooltipPlacement = 'top';
  @Input() tooltip: string;
  @Input() tooltipIcon: string;
  @Input() required = false;

  checked = false;
  onChange: (value: boolean) => void;
  onTouched: () => void;

  writeValue(obj: boolean): void {
    // Atualiza o estado do checkbox quando o valor externo muda
    // Por exemplo, quando você define o valor programaticamente no formulário
    this.checked = obj;
  }

  registerOnChange(fn: (value: boolean) => void): void {
    // Registra a função de callback para atualização de valor
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    // Registra a função de callback para toque (touched)
    this.onTouched = fn;
  }

  // Método chamado quando o estado do checkbox muda
  onCheckboxChange(isChecked: boolean): void {
    // Atualiza o valor interno
    this.checked = isChecked;
    // Atualiza o valor no modelo quando o checkbox é alterado
    this.onChange(isChecked);
    // Marca o controle como tocado
    this.onTouched();
  }
}
