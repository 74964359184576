<app-modal-basic #modal [dialogClass]="'modal-lg'" (closed)="fechar()" [allowClickOutside]="false">
  <div class="app-modal-header">
    <h4 class="modal-title" *ngIf="!faturaVencida">
      Faturas em aberto
    </h4>
    <h4 class="modal-title" *ngIf="faturaVencida">
      Xí! Sua fatura venceu.
    </h4>
  </div>

  <div class="app-modal-body">
    <app-loading [loading]="loading['tela']" [icone]="'icofont icofont-refresh'" [btnWrapper]="false"></app-loading>

    <ng-container *ngIf="!loading['tela']">
      <div class="row">
        <div class="col">
          <table class="table table-striped table-bordered full-data-table">
            <thead>
              <tr>
                <th>Número</th>
                <th>Parcela</th>
                <th>Emissão</th>
                <th>Vencimento</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let titulo of titulos">
                <td>{{titulo.numero}}</td>
                <td>{{titulo.parcela}}</td>
                <td>{{titulo.emissao | date:'dd/MM/yyyy'}}</td>
                <td>{{titulo.vencimento | date:'dd/MM/yyyy'}}</td>
                <td>{{titulo.valorParcela | currency:'BRL':'symbol':'1.2-2'}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <ng-container *ngIf="faturaVencida">
        <div class="row">
          <div class="col">
            <h5 class="mb-2">Com isso você:</h5>
            <ul class="unordered-list">
              <li>Perdeu o seu desconto de pontualidade</li>
              <li>Será cobrado de juros bancários; e</li>
              <li *ngIf="!sistemaBloqueado && diasAteBloqueio >= 1">Pode ser bloqueado para emitir documentos fiscais em {{diasAteBloqueio}} {{diasAteBloqueio > 1 ? 'dias' : 'dia'}}.</li>
              <li *ngIf="!sistemaBloqueado && diasAteBloqueio < 1">Pode ser bloqueado para emitir documentos fiscais após {{bloqueio.prorrogacaoBloqueio | date:'dd-MM-yyyy HH:mm:ss'}}.</li>
              <li *ngIf="sistemaBloqueado">Já está bloqueado para emitir documentos fiscais.</li>
            </ul>
          </div>
        </div>

        <div class="row mt-3">
          <div class="col">
            <h5 class="mb-2">A não ser que:</h5>
            <p>Você aproveite esta oportunidade e pague agora com PIX.</p>
            <p *ngIf="!sistemaBloqueado">
              <span *ngIf="!infoDescontoPix">Assim você garante o seu desconto de pontualidade, livra-se dos juros bancários e do risco de ser bloqueado.</span>
              <span *ngIf="infoDescontoPix">Assim você garante o seu desconto de pontualidade, livra-se dos juros bancários e do risco de ser bloqueado, e ainda ganha mais {{infoDescontoPix}} de desconto.</span>
            </p>
            <p *ngIf="sistemaBloqueado">
              <span *ngIf="!infoDescontoPix">Livra-se dos juros bancários e deste bloqueio.</span>
              <span *ngIf="infoDescontoPix">Livra-se dos juros bancários, deste bloqueio e ainda ganha mais {{infoDescontoPix}} de desconto.</span>
            </p>
          </div>
        </div>

        <p style="color: red">
          Se o pagamento já foi efetuado desconsidere essa mensagem.<br>
          A confirmação do pagamento via boleto pode levar até 24 horas.
        </p>

        <div class="row mt-3">
          <div class="col">
            <button class="btn btn-primary" (click)="abrirPortal()">Efetuar pagamento</button>
            <button class="btn btn-danger" (click)="fechar()">
              {{ sistemaBloqueado ? 'Prefiro continuar bloqueado' : 'Fechar' }}
            </button>
          </div>
        </div>
      </ng-container>
      
      <ng-container *ngIf="!faturaVencida">
        <div class="row">
          <div class="col">
            <p style="color: red" *ngIf="!infoDescontoPix">Novidade: Agora você pode pagar através de PIX ou boleto.</p>
            <p style="color: red" *ngIf="infoDescontoPix">Novidade: Agora você pode pagar através de PIX ({{infoDescontoPix}} de desconto) ou boleto.</p>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <h5 class="mb-3">Informação Importante</h5>
            <p><strong>Fatura de Serviços:</strong> A partir de agora você não receberá mais os arquivos da NFS-e e do boleto de cobrança em seu e-mail.</p>
            <p><strong>Pagamento:</strong> Agora você deverá efetuar o pagamento dos serviços através de PIX ou boleto no Portal do Cliente.</p>
            <p><strong>Nossa NFS-e:</strong> Será enviada no e-mail {{dadosEmpresa.email}} mediante liquidação da fatura.</p>
            <p *ngIf="dadosEmpresa.whatsResponsavelFinanceiro">
              <strong>Comunicação por WhatsApp:</strong> WhatsApp do responsável financeiro por sua empresa cadastrado é {{dadosEmpresa.whatsResponsavelFinanceiro | mask: '(00) 0 0000-0000'}}.
            </p>
            <p *ngIf="!dadosEmpresa.whatsResponsavelFinanceiro">
              <strong>Comunicação por WhatsApp:</strong> Você ainda não configurou o WhatsApp do responsável financeiro por sua empresa.
            </p>
            <p>Mantenha atualizados estes dados no cadastro dos dados da empresa acessando: Menu do perfil no canto superior direito > Dados Empresa.</p>
          </div>
        </div>
    
        <div class="row mt-3">
          <div class="col">
            <div class="checkbox-fade fade-in-default">
              <label>
                <input type="checkbox" [(ngModel)]="naoExibirNovamente">
                <span class="cr">
                  <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span>
                <span> Não exibir novamente para essa fatura</span>
              </label>
            </div>
          </div>
        </div>
    
        <div class="row mt-2">
          <div class="col">
            <button class="btn btn-primary" (click)="abrirPortal()">Efetuar pagamento</button>
            <button class="btn btn-danger" (click)="fechar()">Fechar</button>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</app-modal-basic>