import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ClassificacoesFiscaisService extends BaseService {

  constructor(private http: HttpClient) { super() }

  public get() {
    return this.http.get(this.UrlService + 'ClassificacoesFiscais')
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  getById(id: number) {
    return this.http.get(this.UrlService + `ClassificacoesFiscais/${id}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  getCest(codigo) {
    return this.http.get(this.UrlService + `CodigosCEST/codigo?filter=${codigo}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  getNcmPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + `ClassificacoesFiscais/GetNcmPaginado?pageIndex=` + pagina + '&search=' + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  postConsultaNcmPaginado(form) {
    return this.http.post(this.UrlService + `ClassificacoesFiscais/PostConsultaNcmPaginado`, form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  private postForm(form) {
    return this.http.post(this.UrlService + `ClassificacoesFiscais/postNcm`, form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  private putForm(form) {
    return this.http.put(this.UrlService + `ClassificacoesFiscais/putNcm`, form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  saveForm(form, id: number) {
    if (id) {
      return this.putForm(form);
    } else {
      return this.postForm(form);
    }
  }

  postCadastroResumidotNcm(form) {
    return this.http.post(this.UrlService + `ClassificacoesFiscais/postCadastroResumidotNcm`, form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getCadastroById(id: number) {
    return this.http.get(this.UrlService + `ClassificacoesFiscais/getCadastroById/${id}`)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  delete(id: number) {
    return this.http
      .delete(this.UrlService + "ClassificacoesFiscais/DeleteNcm/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  GetByNCM(description: string) {
    return this.http
      .get(this.UrlService + `ClassificacoesFiscais/GetByNCM/${encodeURIComponent(description)}`)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  GetIdByNCM(classificacao: number) {
    return this.http
      .get(this.UrlService + `ClassificacoesFiscais/GetIdByNCM/` + classificacao)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getUnidadeTributavel(ncm: string) {
    return this.http
      .get(this.UrlService + 'ClassificacoesFiscais/GetUnidadeTributavel/' + ncm)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
