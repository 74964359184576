import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl } from '@angular/forms';
import { NotasFiscaisService } from '../../../../services/notas-fiscais.service';
import { MessageService } from '../../../../services/message.service';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-relatorio-gerencial',
  templateUrl: './relatorio-gerencial.component.html',
  styleUrls: ['./relatorio-gerencial.component.css']
})
export class RelatorioGerencialComponent extends ModalCadastroComponent implements OnInit {

  constructor(
    private notasFiscaisService: NotasFiscaisService,
    private messageService: MessageService
  ) { super(); }

  @ViewChild('modalRelatorio', { static: true }) modalRelatorio;
  form: FormGroup;

  criteriosOrdenacao = [
    { id: 0, value: 'Número da nota' },
    { id: 1, value: 'Cliente' }
  ];

  ngOnInit() {
    this.form = new FormGroup({
      periodo1: new FormControl(moment().startOf('month').toDate()),
      periodo2: new FormControl(moment().endOf('month').startOf('day').toDate()),
      ordem: new FormControl(1),
      clienteID: new FormControl(null),
      formato: new FormControl('pdf'),
      imprimirNotasEntrada: new FormControl(false)
    });
  }

  show(): void {
    this.modalRelatorio.show();
  }

  submitForm(): void {
    this.loading['consulta'] = true;

    this.messageService.addToast({
      title: 'Emitindo Relatório',
      msg: 'Gerencial',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    })

    this.notasFiscaisService.imprimirRelatorioGerencial(this.form.value)
      .pipe(finalize(() => {
        this.loading['consulta'] = false;
        this.messageService.clearAll();
      }))
      .subscribe(res => {
        this.messageService.success('Relatorio gerado com sucesso', 5000);
        saveAs(res, `relatorio-gerencial.${this.form.value.formato}`)
      }, error => {
        this.messageService.error('Ocorreu um erro ao emitir o relatório', 5000, 'Atenção!')
      });
  }

  onClose() {
    this.closed.emit();
  }
}
