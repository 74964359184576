import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NotasFiscaisService } from '../../../services/notas-fiscais.service';
import { ManifestoDestinatarioService } from '../../../services/manifesto-destinatario.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { MessageService } from '../../../services/message.service';
import { finalize } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-listar-notas-correspondentes',
  templateUrl: './listar-notas-correspondentes.component.html',
  styleUrls: ['./listar-notas-correspondentes.component.css']
})
export class ListarNotasCorrespondentesComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modalCadastro', { static: true }) modalCadastro;

  @Input() notaFiscalEntradaID = null;

  colunas = [
    { label: 'Tipo', col: 'tipo', hide: false },
    { label: 'Status', col: 'status', hide: false },
    { label: 'NF', col: 'numeroNotaFiscal', hide: false },
    { label: 'Série', col: 'serie', hide: false },
    { label: 'Data', col: 'data', hide: false, dateTime: true },
    { label: 'Quantidade', col: 'quantidade', hide: false },
  ];

  vm = {
    fornecedor: {
      cnpj: '',
      nome: '',
    },
    entrada: {
      numero: 0,
      emissao: '',
      status: '',
      quantidade: 0,
    },
    notas: [],
  };

  constructor(
    private manifestoService: ManifestoDestinatarioService,
    private notasFiscaisService: NotasFiscaisService,
    private messageService: MessageService) {
    super();
  }

  ngOnInit() {
    this.notaFiscalEntradaID = this.prop.notaFiscalEntradaID;

    this.alteraLoading(true, 'consulta');
    this.manifestoService.listarNotasCorrespondentes(this.notaFiscalEntradaID)
      .pipe(finalize(() => {
        this.alteraLoading(false, 'consulta');
      }))
      .subscribe(
        dados => {
          this.vm = dados;
        },
        err => {
          this.messageService.error(err.msg.errors[0], 5000);
        }
      );
  }

  getStatusType(nota: any) {
    switch (nota.status) {
      case 'A':
        return nota.notaFiscalEstornoID > 0 ? 'Estornada' : 'Aprovada';
      case 'C':
        return 'Cancelada';
      default:
        return '-';
    }
  }

  show() {
    this.modalCadastro.show();
  }

  destroyComponent() {
    this.closed.emit();
  }

  imprimirNfe(notaFiscalID, notaFiscalNumero) {
    this.loading['impressao' + notaFiscalID] = true;

    this.notasFiscaisService.imprimirNfe(notaFiscalID, 'pdf')
      .pipe(finalize(() => {
        this.loading['impressao' + notaFiscalID] = false;
      }))
      .subscribe(res => {
        if (res && (res as Blob).type.includes('pdf')) {
          saveAs(res, `nfe_${notaFiscalNumero}.pdf`);
        }
      }, err => {
        this.messageService.error(err.msg.errors[0], 5000);
      });
  }

  formatDecimal(value) {
    return (value || 0).toString().replace('.', ',');
  }
}
