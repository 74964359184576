import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cnpjcpf'
})
export class CnpjcpfPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value.length === 14) {
      return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    }
    if (value.length === 11) {
      return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    }
    return value;
  }

}
