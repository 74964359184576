import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CancelarNFSeViewModel } from '../model/CancelarNFSeViewModel';
import { GetEmailsNFSeViewModel } from '../model/GetEmailsNFSeViewModel';
import { BaseService } from './base.service';
import { SalvarNFSeResponseViewModel } from '../model/SalvarNFSeResponseViewModel';
import { SalvarNFSeViewModel } from '../model/SalvarNFSeViewModel';
import { SalvarNFSeCompletaViewModel } from '../model/SalvarNFSeCompletaViewModel';
import { BuscarNFSePorIdViewModel } from '../model/BuscarNFSePorIdViewModel';

@Injectable()
export class NotasFiscaisServicoService extends BaseService {
  constructor(private http: HttpClient) { super(); }
  
  enviarEmail(nfseId: number, emails: string[]): Observable<any> {
    let nfse = {
      NfseId: nfseId,
      Emails: emails
    }

    return this.http.post(this.UrlService + 'notasFiscaisServico/email', nfse)
      .pipe(map((res: any) => res.data));
  }

  salvar(nfse: SalvarNFSeViewModel): Observable<SalvarNFSeResponseViewModel> {
    return this.http
      .post(this.UrlService + 'notasFiscaisServico/salvar', nfse)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  salvarCompleta(nfse: SalvarNFSeCompletaViewModel): Observable<SalvarNFSeResponseViewModel> {
    return this.http
      .post(this.UrlService + 'notasFiscaisServico/salvarCompleta', nfse)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  emitir(id: number) {
    return this.http
      .post(this.UrlService + 'notasFiscaisServico/emitir', id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  getWithFilters(consulta: any) {
    return this.http
      .post(this.UrlService + 'notasFiscaisServico/buscar', consulta)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  cancelarNfse(dados: CancelarNFSeViewModel) {
    return this.http
      .post(this.UrlService + 'notasFiscaisServico/cancelar', dados)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError))
  }

  imprimirNfse(nfseId: number) {
    return this.http
      .post(this.UrlService + `notasFiscaisServico/imprimir/${nfseId}`, null, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob))
  }

  baixarXml(nfseId: number) {
    return this.http
      .post(this.UrlService + `notasFiscaisServico/baixarXml/${nfseId}`, null, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob))
  }

  baixarXmlCancelada(nfseId: number) {
    return this.http
      .post(this.UrlService + `notasFiscaisServico/baixarXmlCancelada/${nfseId}`, null, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob))
  }

  getEmailsNfse(nfseId: number): Observable<GetEmailsNFSeViewModel> {
    return this.http.get(this.UrlService + `notasFiscaisServico/GetEmailsNFSe/${nfseId}`)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  buscarPorId(nfseId: number): Observable<BuscarNFSePorIdViewModel> {
    return this.http.get(this.UrlService + `notasFiscaisServico/BuscarPorId/${nfseId}`)
      .pipe(map(super.extractData), catchError(super.serviceError));
  }

  consultarNfse(nfseId: number) {
    return this.http
      .post(this.UrlService + `notasFiscaisServico/consultar/${nfseId}`, null)
      .pipe(map((res: any) => res.data), catchError(super.serviceError))
  }

  imprimirRelatorio(dados: any) {
    return this.http
      .post(this.UrlService + `notasFiscaisServico/imprimirRelatorio`, dados, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob))
  }

  exportarNfse(dados: any) {
    return this.http
      .post(this.UrlService + `notasFiscaisServico/exportarNfse`, dados)
      .pipe(map((res: any) => res.data));
  }
}