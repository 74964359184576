import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap, tap } from 'rxjs/operators';
import { EmailBoxComponent } from '../../../../dim-componentes/email-box/email-box.component';
import { convertB64ToBlob, mergeArray, setFormValue } from '../../../../general/utils';
import { ClientesService } from '../../../../services/clientes.service';
import { DownloadExportacaoService } from '../../../../services/downloadExportacaoController.service';
import { MessageService } from '../../../../services/message.service';
import { UnidadesEmpresaService } from '../../../../services/unidades-empresa.service';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-exportar-nfe',
  templateUrl: './exportar-nfe.component.html',
  styleUrls: ['./exportar-nfe.component.css']
})
export class ExportarNFEComponent extends ModalCadastroComponent implements OnInit {
  constructor(
    private message: MessageService,
    private clientesService: ClientesService,
    private downloadExportacaoService: DownloadExportacaoService,
    private unidadesEmpresaService: UnidadesEmpresaService
  ) {
    super();
  }

  @ViewChild('modalRelatorio', { static: true }) modalRelatorio;
  @ViewChild('emailBox', { static: true }) emailBox: EmailBoxComponent;
  impressaoForm: FormGroup;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  loading = [];
  clienteInput = new Subject<string>();
  clientes: any = [];
  form: FormGroup;

  ngOnInit() {
    this.form = new FormGroup({
      emissao1: new FormControl(''),
      emissao2: new FormControl(''),
      cliente: new FormControl(''),
      status: new FormControl(['A', 'C', 'I', 'D', 'R', '0', 'U']),
      destinatarios: new FormControl(''),
      arquivoUnico: new FormControl(false),
      arquivoZip: new FormControl(true),
      arquivoPdf: new FormControl(false),
      notasEntrada: new FormControl(true)
    });

    if (this.prop != null) {
      setFormValue(this.form, this.prop);
      this.form.controls['arquivoZip'].setValue(true);
    }

    this.unidadesEmpresaService.getUnidadeEmpresa()
      .subscribe(res => {
        if (res.email) {
          this.emailBox.insertEmailString(res.email);
        }

        if (res.contabilidade && res.contabilidade.email) {
          this.emailBox.insertEmailString(res.contabilidade.email);
        }
      });

    this.loading['clientes'] = true;
    this.clientesService.getClientesPaginado(1, '')
      .pipe(finalize(() => this.loading['clientes'] = false))
      .subscribe(data => this.clientes = data);

    this.onSearchCliente();
  }

  show(prop): void {
    this.modalRelatorio.show();
  }

  hide() {
    this.modalRelatorio.hide();
  }

  onSearchCliente() {
    this.clienteInput.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => this.loading['clientes'] = true),
      switchMap((term: string) => this.clientesService.getClientesPaginado(1, term || '').pipe(
        tap(() => this.loading['clientes'] = false)
      ))
    )
      .subscribe(data => {
        this.clientes = data;
      });
  }

  fetchMoreClientes(term) {
    this.loading['clientes'] = true;
    this.clientesService.getClientesPaginado(((this.clientes.length / 20) + 1), term || '')
      .pipe(finalize(() => this.loading['clientes'] = false))
      .subscribe(result => {
        this.clientes = mergeArray(this.clientes, result);
      });
  }

  exportar() {
    this.loading['exportacao'] = true;

    const idToast = this.message.addToast({
      title: 'Exportando...',
      msg: '',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    });

    this.downloadExportacaoService.exportarNfes(this.form.value)
    .pipe(finalize(() => this.loading['exportacao'] = false))
    .subscribe(response => {
      if (response) {
        response.forEach(file => {
          saveAs(convertB64ToBlob(file.fileContents, file.contentType), file.fileDownloadName);
        });

        this.message.clear(idToast);
        this.message.addSuccess({
          title: 'Sucesso!',
          msg: 'Documentos exportados com êxito.',
          timeout: 6000
        });
      }
    }, err => {
      this.message.clear(idToast);
      this.message.addError({
        title: 'Erro!',
        msg: err.error.errors[0],
        showClose: true, timeout: 5000, theme: 'bootstrap', closeOther: true
      });
    });
  }

  inputCheck(input, event) {
    if (input === 'zip' && !event.target.checked) {
      if (!this.form.controls['arquivoUnico'].value) {
        this.form.controls['arquivoUnico'].setValue(true);
      }
      if (this.form.controls['arquivoPdf'].value) {
        this.form.controls['arquivoPdf'].setValue(false);
      }
    }

    if (input === 'pdf' && event.target.checked) {
      if (!this.form.controls['arquivoZip'].value) {
        this.form.controls['arquivoZip'].setValue(true);
      }
    }

    if (input === 'unico' && !event.target.checked) {
      if (!this.form.controls['arquivoZip'].value) {
        this.form.controls['arquivoZip'].setValue(true);
      }
    }
  }
}
