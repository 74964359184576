import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { AuthService } from './services/auth.service';
import { NotFoundComponent } from './errors/not-found/not-found.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthService],
    canActivateChild: [AuthService],
    children: [
      {
        path: '',
        redirectTo: 'novidades',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(lc => lc.DashboardModule)
      },
      {
        path: 'financeiro',
        loadChildren: () => import('./financeiro/financeiro.module').then(lc => lc.FinanceiroModule)
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(lc => lc.AdminModule)
      },
      {
        path: 'compras',
        loadChildren: () => import('./compras/compras.module').then(lc => lc.ComprasModule)
      },
      {
        path: 'suprimentos',
        loadChildren: () => import('./compras/compras.module').then(lc => lc.ComprasModule)
      },
      {
        path: 'venda',
        loadChildren: () => import('./venda/venda.module').then(lc => lc.VendaModule)
      },
      {
        path: 'produtos',
        loadChildren: () => import('./produto/produto.module').then(lc => lc.ProdutoModule)
      },
      {
        path: 'gerenciamento',
        loadChildren: () => import('./gerenciamento/gerenciamento.module').then(lc => lc.GerenciamentoModule)
      },
      {
        path: 'fiscal',
        loadChildren: () => import('./fiscal/fiscal.module').then(lc => lc.FiscalModule)
      },
      {
        path: 'estoque',
        loadChildren: () => import('./estoque/estoque.module').then(lc => lc.EstoqueModule)
      },
      {
        path: 'ajuda',
        loadChildren: () => import('./suporte/suporte.module').then(lc => lc.SuporteModule)
      },
      {
        path: 'novidades',
        loadChildren: () => import('./atualizacoes/atualizacoes.module').then(lc => lc.AtualizacoesModule)
      },
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'authentication',
        loadChildren: () => import('./authentication/authentication.module').then(lc => lc.AuthenticationModule)
      }
    ]
  },
  {
    path: 'not-found',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: ''
  }
];
