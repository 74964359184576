import { Type } from './typelist';
import { Component, OnInit, Input, forwardRef, Output, EventEmitter } from '@angular/core';
import * as $ from 'jquery';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-datalist',
  templateUrl: './custom-datalist.component.html',
  styleUrls: ['./custom-datalist.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CustomDatalistComponent)
    }
  ]
})
export class CustomDatalistComponent implements OnInit {

  constructor() {

  }

  writeValue(obj: any) {
  }

  registerOnChange(fn: any) {

    this.changeValue.emit(fn);

    this.changeValue.subscribe( r => { 
     //Do your job
    })
  }




  registerOnTouched(fn: any) {
  }

  @Input() list: Type[];
  @Input() id: string;
  @Input() place: string;
  @Input() isDisabled: boolean;
  @Input() defaultValue?: any;
  @Output() changeValue: EventEmitter<any> = new EventEmitter();
  items = [];
  currentValue: any = "";
  hasItem: boolean;
  @Input() minLength?: number = 1;

  remove() {
    $(`#${this.id}`).val(null);
    this.registerOnChange($(`#${this.id}`).val());
    this.hasItem = false;
  }

  search(val: string) {
    setTimeout(() => {
      this.items = [];
      if (val.length >= this.minLength) {
        this.list.map(element => {
          if (element.name.toUpperCase().includes(val.toUpperCase())) {
            this.items = this.items.concat(element);
          }
        });
      }
    }, 500);

  }

  ngOnInit() {

    if (this.defaultValue !== undefined && this.defaultValue !== null) {
      this.currentValue = this.defaultValue.name;
      this.hasItem = true;
      setTimeout(() => {
        this.registerOnChange(this.defaultValue);
      }, 500);
    }

    if (this.currentValue === undefined)
      this.currentValue = "";


    $(`#${this.id}`).blur(() => {
      setTimeout(() => {
        this.items = []

        if ($(`#${this.id}`.length < this.minLength)) {
          this.remove();
        }
      }, 1000)
    })


    $(`#${this.id}`).focus(() => {
      this.search(this.getInputVal());
    })
  }

  getInputVal() {
    return $(`#${this.id}`).val();
  }

  removeItems() {
    this.items = [];
  }

  select(item) {
    this.hasItem = true;
    if (item) {
      $(`#${this.id}`).val(item.name);
      this.registerOnChange(item);
      this.items = [];
    }
  }
}