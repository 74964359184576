import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from "./base.service";
import { map, catchError } from 'rxjs/operators';
import { finalize } from 'rxjs/operators';
import { MessageService } from './message.service';

@Injectable()
export class AtendimentoContabilidadeService extends BaseService {

  constructor(private http: HttpClient, private message: MessageService) { super(); }

  getAtendimentosByContabilidadeId(idContabilidade: number) {
    return this.http
      .get(this.UrlService + "atendimentoContabilidade/" + idContabilidade)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  salvarAtendimento(atendimento) {
    return this.http
      .post(this.UrlService + "atendimentoContabilidade/", atendimento)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  };

  imprimirAtendimentoContabilidade(consulta: any) {
    let idToast = this.message.addToast({
      title: 'Emitindo Relatório',
      msg: 'Atendimento às Contabilidades',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    })
    return this.http
      .post(this.UrlService + 'atendimentoContabilidade/imprimirAtendimentoContabilidade', consulta, { responseType: 'blob' }).pipe(finalize(() => this.message.clear(idToast))
        , catchError(super.parseErrorBlob))
  }

}
