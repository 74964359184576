import { Component, forwardRef, ViewEncapsulation, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-dim-curreny-input',
  templateUrl: './dim-curreny-input.component.html',
  styleUrls: ['./dim-curreny-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DimCurrenyInputComponent),
      multi: true
    }
  ]
})
export class DimCurrenyInputComponent implements ControlValueAccessor {

  constructor() { }

  @Input()
  disabled: boolean = false

  ngOnInit() {
  }

  value = 0

  onChange(value) {
    this.propagateChange(value);
  }

  propagateChange = (_: any) => { };

  registerOnTouched() { }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  writeValue(value: any) {
    this.value = value;
  }
}
