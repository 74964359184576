import { ContasCorrente } from './ContasCorrente';
import { Fornecedor } from './Fornecedor';
import { Cidade } from './cidade';
import { Pais } from './pais';
import { Portadores } from './portadores';

export class Cliente {
    razaoSocial: string;
    cnpj: string;
    telefone: string;
    nomeFantasia: string;
    id: number;
    cidadeId: number;
    cidade: Cidade;
    inscricaoEstadual: any;
    unidadeEmpresaID: number;
    celular: string;
    whatsAppFiscalFinanceiro: string;
    observacoes: string;
    codigoSuframa: string;
    contato: string;
    email: string;
    complemento: string;
    simples: boolean;
    proEmprego: boolean;
    produtorRural: boolean;
    inativo: boolean;
    inscricaoMunicipal: string;
    clienteFinal: boolean;
    indicadorIE: number;
    cep: string;
    numero: number;
    bairro: string;
    caixaPostal: string;
    endereco: string;
    fornecedorTransportadorID: number;
    fornecedor?: Fornecedor;
    pais?: Pais;
    paisId: number;
    contaRecebedoraID: number;
    contaCorrente: ContasCorrente;
    portador?: Portadores;
    portadorID: number;
    empresaPagadora?: number;
    empresaPagadoraDetalhe?: Cliente;
    dataInclusao?: any;
    condicaoPagamentoId: number;
    enderecos: any[];
    clientesRepresentantes: any[];
    tipoComissaoId?: number;
    constructor() { }
}

export class TiposEnderecoCliente {
    id: number;
    nome: string;

    constructor(tipo: number, nome: string) {
        this.id = tipo;
        this.nome = nome;
    }


    public static listar(): TiposEnderecoCliente[] {
        return [
            new TiposEnderecoCliente(0, 'Cobrança'),
            new TiposEnderecoCliente(1, 'Correspondência'),
            new TiposEnderecoCliente(2, 'Entrega'),
            new TiposEnderecoCliente(3, 'Outros'),
        ];
    }
}
