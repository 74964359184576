export class FormaPagamento {
    id: string;
    descricao: string;
    icone: string;

    static listarFormasPagamentoPedido() {
        return [
            { id: '98', descricao: 'A prazo' },
            { id: '01', descricao: 'Dinheiro' },
            { id: '02', descricao: 'Cheque' },
            { id: '03', descricao: 'Cartão de crédito' },
            { id: '04', descricao: 'Cartão de débito' },
            { id: '05', descricao: 'Crédito loja' },
            { id: '10', descricao: 'Vale alimentação' },
            { id: '11', descricao: 'Vale refeição' },
            { id: '12', descricao: 'Vale presente' },
            { id: '13', descricao: 'Vale combustível' },
            { id: '99', descricao: 'Outros' }
        ];
    }

    ListarFormasPagamento(): FormaPagamentoViewModel[] {
        return [
            { id: '01', descricao: 'Dinheiro', icone: 'fa fa-money-bill' },
            { id: '02', descricao: 'Cheque', icone: 'fa fa-receipt' },
            { id: '03', descricao: 'Cartão de crédito', icone: 'fa fa-credit-card' },
            { id: '04', descricao: 'Cartão de débito', icone: 'fa fa-credit-card' },
            { id: '05', descricao: 'Crédito loja', icone: 'fa fa-store' },
            { id: '10', descricao: 'Vale alimentação', icone: 'fa fa-utensils' },
            { id: '11', descricao: 'Vale refeição', icone: 'fa fa-utensils' },
            { id: '12', descricao: 'Vale presente', icone: 'fa fa-gifts' },
            { id: '13', descricao: 'Vale combustível', icone: 'fa fa-gas-pump' },
            { id: '15', descricao: 'Boleto - venda a prazo', icone: 'fa fa-barcode' },
            { id: '16', descricao: 'Depósito bancário', icone: 'fa fa-university' },
            { id: '17', descricao: 'Pagamento instantâneo (PIX)', icone: 'fa fa-star'},
            { id: '18', descricao: 'Transferência bancária, Carteira digital', icone: 'fa fa-exchange-alt'},
            { id: '19', descricao: 'Programa de fidelidade, cashback, crédito virtual', icone: 'fa fa-heart'},
            { id: '90', descricao: 'Sem pagamento', icone: 'fa fa-creative-commons-nc' },
            { id: '99', descricao: 'Outros', icone: 'fa fa-hand-holding-usd' }
        ];
    }

    retornaDescricaoFormaPagamento(formaPagto): string {
        switch (formaPagto) {
            case '01':
                return 'Dinheiro';
            case '02':
                return 'Cheque';
            case '03':
                return 'Cartão de Crédito';
            case '04':
                return 'Cartão de Débito';
            case '05':
                return 'Crédito loja';
            case '10':
                return 'Vale alimentação';
            case '11':
                return 'Vale refeição';
            case '12':
                return 'Vale presente';
            case '13':
                return 'Vale combustível';
            case '15':
                return 'Boleto bancário';
            case '16':
                return 'Depósito Bancário';
            case '17':
                return 'Pagamento Instantâneo (PIX)';
            case '18':
                return 'Transferência bancária, Carteira Digital';
            case '19':
                return 'Programa de fidelidade, Cashback, Crédito Virtua';
            case '90':
                return 'Sem pagamento';
            case '99':
                return 'Outros';
        }
    }
}

export interface FormaPagamentoViewModel {
  id: string;
  descricao: string;
  icone: string;
}
