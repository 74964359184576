import * as pedidoModel from './pedidos';

export class StatusPedido {
    readonly id: string;
    readonly descricao: string;

    constructor(tipo: string, descricao: string) {
        this.id = tipo;
        this.descricao = descricao;
    }


    public static listar(): StatusPedido[] {
        return [
            new StatusPedido(pedidoModel.STATUS_ANDAMENTO, "Em andamento"),
            new StatusPedido(pedidoModel.STATUS_FATURADO, "Faturado"),
            new StatusPedido(pedidoModel.STATUS_FATURADO_PARCIALMENTE, "Faturado parcialmente"),
            new StatusPedido(pedidoModel.STATUS_CANCELADO, "Cancelado"),
            new StatusPedido(pedidoModel.STATUS_RECEBIDO, "Recebido"),
            new StatusPedido(pedidoModel.STATUS_RECEBIDO_PARCIALMENTE, "Recebido parcialmente"),
            new StatusPedido(pedidoModel.STATUS_ORCAMENTO_EFETIVADO, "Orçamento efetivado"),
        ];
    }
}
