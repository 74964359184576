import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { ParamConsulta } from '../../../general/models/paramConsulta';
import { MessageService } from '../../../services/message.service';
import { NotasFiscaisService } from '../../../services/notas-fiscais.service';
import { ModalBasicComponent } from '../../../shared/modal-basic/modal-basic.component';

@Component({
  selector: 'app-busca-itens-romaneio',
  templateUrl: './busca-itens-romaneio.component.html',
  styleUrls: ['./busca-itens-romaneio.component.css']
})
export class BuscaItensRomaneioComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalConsulta', { static: true }) modalConsulta: ModalBasicComponent;
  paramConsulta = new ParamConsulta();
  transportadorId = null;
  selecionados = [];
  notas: any;
  colunas = [
    { label: 'Série', col: 'serie', hide: true },
    { label: 'Número', col: 'numeroNotaFiscal', hide: true },
    { label: 'Valor', col: 'valorTotal', hide: false, currency: true },
    { label: 'Total Unidades', col: 'quantidadeTotal', hide: false, format: row => this.formataDecimais(row, 2, ',') },
  ];

  constructor(
    private notasFiscaisService: NotasFiscaisService,
    private messageService: MessageService
  ) {
    super();
  }

  ngOnInit() {
    if (!this.prop) {
      return;
    }

    this.notas = this.prop;
    this.transportadorId = this.prop.transportadorId;
  }

  show() {
    this.modalConsulta.show();
  }

  onSort(paramConsulta) {
    this.loading['table'] = true;
    this.paramConsulta = paramConsulta;
    this.carregarNotas();
  }

  async carregarNotas() {
    try {
      this.notas = await this.notasFiscaisService
        .buscarNotasRomaneioPaginado(this.transportadorId, this.paramConsulta)
        .toPromise();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading['table'] = false;
    }
  }

  async carregarItens() {
    this.loading['itens'] = true;

    try {
      let res = await this.notasFiscaisService
        .montarItensNFeRomaneio(this.selecionados)
        .toPromise();

      res = res.map((item) => {
        return {
          id: null,
          produtoId: item.item.id,
          produtoNome: item.item.nome,
          produtoReferencia: item.referenciaItem || item.item.referenciaItem,
          unidade: item.unidade,
          unidadeMedidaId: item.unidadeMedidaId,
          quantidade: item.quantidade,
          pesoLiquido: item.item.peso,
          pesoBruto: item.item.pesoBruto,
          tipoPreco: true,
          preco: item.preco,
        };
      });
      this.closed.emit(res);
    } catch (error) {
      console.error(error);
      this.messageService.error(
        'Ocorreu um erro ao carregar os itens das notas',
        5000,
        'Atenção!',
        true
      );
    } finally {
      this.loading['itens'] = false;
    }
  }

  formataDecimais(value: number, qtdDecimais: number, separator: string) {
    return (value || 0).toFixed(qtdDecimais).replace('.', separator);
  }

}
