import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class BandeiraCartoesService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getAll() {
    return this.http
      .get(this.UrlService + 'BandeiraCartoes')
      .pipe(map((res: any) => res.data), 
      catchError(super.serviceError));
  }
}
