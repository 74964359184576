import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class EmbalagensDespachoService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getAll() {
    return this.http.get(this.UrlService + 'EmbalagensDespacho')
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  post(form: any) {
    return this.http.post(this.UrlService + 'EmbalagensDespacho', form)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getById(id) {
    return this.http.get(this.UrlService + 'EmbalagensDespacho/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  delete(id) {
    return this.http.delete(this.UrlService + 'EmbalagensDespacho/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
