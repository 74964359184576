import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class LinhasProdutosService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get() {
    return this.http.get(this.UrlService + 'LinhasProdutos')
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getLinhaProdutoById(id: number) {
    return this.http
      .get(this.UrlService + 'LinhasProdutos/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  post(form) {
    return this.http.post(this.UrlService + 'LinhasProdutos', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + `LinhasProdutos/Paginado?pageIndex=` + pagina + '&search=' + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getPaginadoPedido(pagina: number, nome: string, verificaoPedido = false) {
    return this.http
      .get(this.UrlService + `LinhasProdutos/PaginadoPedido?pageIndex=` + pagina + '&search=' + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
