import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FornecedoresService } from '../../../services/fornecedores.service';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { setFieldFormValue, setFormValue, validarCNPJ, validarCPF } from '../../../general/utils';
import { EstadosService } from '../../../services/estados.service';
import * as $ from 'jquery';
import { CidadesService } from '../../../services/cidades.service';
import { BuscaAutomaticaCNPJService } from '../../../services/busca-automatica-CNPJ.service';
import { MessageService } from '../../../services/message.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-fornecedor',
  templateUrl: './fornecedor.component.html',
  styleUrls: ['./fornecedor.component.css']
})
export class FornecedorComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalCadastro', { static: true }) modalCadastro;

  constructor(
    private fornecedorService: FornecedoresService,
    private estadosService: EstadosService,
    private cidadesService: CidadesService,
    private messageService: MessageService,
    private buscaAutomaticaCNPJService: BuscaAutomaticaCNPJService) {
    super();
  }

  estados = [];
  cidades = [];

  cadastroForm = new FormGroup({
    id: new FormControl(null),
    nome: new FormControl(null, [Validators.required]),
    cnpj: new FormControl('', [Validators.required]),
    inscricaoEstadual: new FormControl(null, [Validators.required]),
    cep: new FormControl(null),
    bairro: new FormControl(null, [Validators.required]),
    estado: new FormControl(null, [Validators.required]),
    cidadeId: new FormControl(null, [Validators.required]),
    endereco: new FormControl(null, [Validators.required]),
    numero: new FormControl(null, [Validators.required]),
  });

  ngOnInit() {

  }

  carregaInscricaoEstadual() {
    if (this.cpfCnpj && this.cpfCnpj.length <= 11) {
      this.cadastroForm.get('inscricaoEstadual').setValue('ISENTO');
      this.cadastroForm.get('inscricaoEstadual').disable();
      $('#cep').focus();
    } else {
      this.cadastroForm.get('inscricaoEstadual').reset();
      this.cadastroForm.get('inscricaoEstadual').enable();
      this.getInfEmpresaByCpfCnpj();
    }
  }

  getInfEmpresaByCpfCnpj() {
    this.messageService.clearAll();
    const cpfCnpj = (this.cadastroForm.get('cnpj').value || '').replace(/\D/g, '');

    return new Promise((resolve, reject) => {
      if (!validarCPF(cpfCnpj) && !validarCNPJ(cpfCnpj)) {
        this.messageService.error('Atenção! Verifique o CPF/CNPJ digitado.', 15000);
        resolve({});
        return;
      }

      if (validarCNPJ(cpfCnpj)) {
        this.alteraLoading(true);
        this.buscaAutomaticaCNPJService.consultarCnpj(cpfCnpj)
          .pipe(finalize(() => {
            this.alteraLoading(false);
            resolve({});
          }))
          .subscribe(res => {
            if (res.erro) {
              this.messageService.error(res.erro, 15000);
              return;
            }
            this.cadastroForm.controls['cep'].setValue(res.address.zip.replace(/\D/g, ''));
            this.cadastroForm.controls['numero'].setValue(res.address.number);
            this.cadastroForm.controls['nome'].setValue(res.company.name);
            this.cadastroForm.controls['endereco'].setValue(res.address.street);
            this.cadastroForm.controls['bairro'].setValue(res.address.district);
            if (res.registrations?.length > 0) {
              const inscricao = res.registrations.filter(x => x.state === res.address.state && x.enabled);
              if (inscricao.length > 0) {
                this.cadastroForm.controls['inscricaoEstadual'].setValue(inscricao[0].number || null);
              }
            }
            this.carregaViaCep();
          },
            error => {
              this.messageService.error('Verifique se foi digitado corretamente ou preencha os campos manualmente...',
                15000, 'Não foram encontrados dados para o CNPJ informado!');
            });
      } else {
        resolve({});
      }
    });
  }

  loadData() {
    this.loading['estados'] = true;
    this.estadosService.get()
      .pipe(finalize(() => this.loading['estados'] = false))
      .subscribe(res => {
        this.estados = res;
      });
  }

  editar(id: number) {
    this.loadData();
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.loadData();
    this.id = null;
    setFieldFormValue(this.cadastroForm, 'nome', searchParam);
    this.modalCadastro.show();
  }

  carregaDados() {
    if (this.id) {
      this.alteraLoading(true);
      this.fornecedorService.getFornecedorById(this.id)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe((res) => {
          setFormValue(this.cadastroForm, res);
          if (res.cidade) {
            this.cadastroForm.get('estado').setValue(res.cidade.estadoId, { emitViewToModelChange: false });
            this.carregaCidades(res.cidade.estadoId);
          }
        });
    }
  }

  carregaCidades(estado) {
    this.loading['cidades'] = true;
    this.cidadesService.getByEstado(estado)
      .pipe(finalize(() => this.loading['cidades'] = false))
      .subscribe(res => this.cidades = res);
  }

  carregaCidadePorIbge(ibge) {
    this.loading['cidadeIbge'] = true;
    this.cidadesService.getByIbge(ibge)
      .pipe(finalize(() => this.loading['cidadeIbge'] = false))
      .subscribe(res => {
        if (res && res.length > 0) {
          this.cadastroForm.get('cidadeId').setValue(res[0].id);
        }
      });
  }

  alteraEstado(estado) {
    this.cadastroForm.get('cidadeId').setValue(null);
    this.carregaCidades(estado);
    this.cadastroForm.get('cidadeId').markAsPristine();
    this.cadastroForm.get('cidadeId').markAsUntouched();
  }

  carregaViaCep() {
    this.loading['endereco'] = true;
    this.cidadesService.getEnderecoByCep((this.cadastroForm.controls['cep'].value) || '')
      .then(res => res.json())
      .then(response => {
        if (response.bairro) { this.cadastroForm.get('bairro').setValue(response.bairro); }
        if (response.logradouro) { this.cadastroForm.get('endereco').setValue(response.logradouro); }
        if (response.uf && response.ibge) {
          const estado = this.estados.find(t => t.siglaEstado.toLowerCase() === response.uf.toLowerCase());
          this.cadastroForm.get('estado').setValue(estado.id);
          this.carregaCidadePorIbge(response.ibge);
        }
        this.loading['endereco'] = false;
      })
      .catch(() => {
        this.loading['endereco'] = false;
      });
  }

  get cpfCnpj(): string {
    return this.cadastroForm.get('cnpj').value;
  }

  retornaMascaraCpfCnpj() {
    if (this.cpfCnpj && this.cpfCnpj.length <= 11) {
      return '000.000.000-009';
    } else {
      return '00.000.000/0000-00';
    }
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      this.alteraLoading(true);
      this.fornecedorService.salvarResumido(this.cadastroForm.getRawValue())
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe(res => {
          this.id = res.generatedId || null;
          this.modalCadastro.hide();
        }, (error) => {
          this.errors = error.msg.errors;
        });
    }
  }
}
