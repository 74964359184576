import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { AbrirModalComponent } from '../../../shared/abrir-modal/abrir-modal.component';
import { ModalBasicComponent } from '../../../shared/modal-basic/modal-basic.component';
import { StorageFunctions } from '../../../general/storage';
import { FormControl, FormGroup } from '@angular/forms';
import { MessageService } from '@services';

@Component({
  selector: 'app-atalhos-clientes',
  templateUrl: './atalhos-clientes.component.html',
  styleUrls: ['./atalhos-clientes.component.css']
})
export class AtalhosClientesComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modalCadastro', { static: true }) modal: ModalBasicComponent;
  @ViewChild('abrirModal', { static: false }) abrirModal: AbrirModalComponent;

  menuItens: any = [];
  menuItensCombobox: ItemDeMenu[] = [];
  icons = [
    { name: 'Arquivo Texto', value: 'fa fa-file-text' },
    { name: 'Arquivo', value: 'fa fa-file' },
    { name: 'Lupa', value: 'fa fa-search' },
    { name: 'Carrinho de Compras', value: 'ti-shopping-cart' },
    { name: 'Gráfico de Linha', value: 'fa fa-line-chart' },
    { name: 'Cartão de Crédito', value: 'fa fa-credit-card' },
    { name: 'Usuário', value: 'fa fa-user' },
    { name: 'Usuários', value: 'fa fa-users' },
    { name: 'Etiqueta', value: 'icon-tag' },
    { name: 'Fone de Ouvido', value: 'ti-headphone-alt' },
    { name: 'Configurações', value: 'ti-settings' },
    { name: 'Engrenagens', value: 'fa fa-cogs' },
    { name: 'Pacote', value: 'ti-package' },
    { name: 'Dinheiro', value: 'ti-money' },
    { name: 'Calculadora', value: 'fa fa-calculator' },
    { name: 'Caminhão', value: 'fa fa-truck' },
    { name: 'Estatísticas', value: 'ti-stats-up' },
  ];

  constructor(
    private storageFunctions: StorageFunctions,
    private messageService: MessageService,
  ) {
    super();
    this.menuItens = storageFunctions.getMenuItens();
  }

  ngOnInit(): void {
    this.buscarItensCombo('');
    this.cadastroForm = new FormGroup({
      atalho1: new FormControl(null),
      icone1: new FormControl(null),
      atalho2: new FormControl(null),
      icone2: new FormControl(null),
      atalho3: new FormControl(null),
      icone3: new FormControl(null),
      atalho4: new FormControl(null),
      icone4: new FormControl(null),
      atalho5: new FormControl(null),
      icone5: new FormControl(null)
    });
    this.getAtalhos();
  }

  show(): void {
    this.modal.show();
  }

  onClose(): void {
    this.closed.emit();
  }

  organizarHierarquia(itens: ItemDeMenu[]) {
    const map = new Map<number, ItemDeMenu>();

    itens.forEach(item => {
      map.set(item.id, item);
    });

    const hierarquia: ItemDeMenu[] = [];

    const construirRotaCompleta = (item: ItemDeMenu, rotaPai: string) => {
      item.rotaCompleta = rotaPai ? `${rotaPai}/${item.rota}` : item.rota;

      item.filhos?.forEach(filho => {
        construirRotaCompleta(filho, item.rotaCompleta);
      });
    };

    map.forEach(item => {
      construirRotaCompleta(item, '');
      hierarquia.push(item);
    });

    return hierarquia;
  }

  buscarItensCombo(term): void {
    const itens: ItemDeMenu[] = this.organizarHierarquia(this.menuItens.menuLateral);
    const resultados = [];

    const buscarFilhos = (item: ItemDeMenu, caminho: string) => {
      const novoCaminho = caminho ? `${caminho} - ${item.nome}` : item.nome;

      if (item.itemDeMenuPaiID && item.tipoDeItem !== 'subMenu' && item.nome.toLowerCase().includes(term.toLowerCase())) {
        resultados.push({
          id: item.id,
          caminho: novoCaminho,
          rotaCompleta: item.rotaCompleta,
          parametro: item.parametro,
          tipoDeItem: item.tipoDeItem,
          tipoDeLink: item.tipoDeLink,
        });
      }

      item.filhos?.forEach(filho => buscarFilhos(filho, novoCaminho));
    };

    itens.forEach(item => buscarFilhos(item, ''));

    this.menuItensCombobox = resultados;
  }

  onSearchCombo(event): void {
    this.buscarItensCombo(event.term);
  }


  onSelectCombo(event: any): void {
    this.buscarItensCombo('');
  }

  salvarAtalhos(): void {
    const btns = [];
    for (let i = 1; i <= 5; i++) {
      const obj = this.menuItensCombobox.find(x => x.id === this.cadastroForm.get(`atalho${i}`).value);
      if (obj) {
        obj.icone = this.cadastroForm.get(`icone${i}`).value;
        btns.push(obj);
      }
    }
    this.storageFunctions.saveDataToLocalStorage('fab-atalhos', btns);
    this.messageService.success('Atalhos atualizados com sucesso.', 8000);
    this.onClose();
    this.modal.hide();
  }

  getAtalhos() {
    const atalhos = this.storageFunctions.getDataFromLocalStorage('fab-atalhos');
    for (let i = 0; i < atalhos.length; i++) {
      const atalho: ItemDeMenu = atalhos[i];
      this.cadastroForm.get(`atalho${i + 1}`).setValue(atalho.id);
      this.cadastroForm.get(`icone${i + 1}`).setValue(atalho.icone);
    }
  }
}

interface ItemDeMenu {
  id: number;
  nome: string;
  rota: string;
  tipoDeItem: string;
  tipoDeLink: string;
  filhos?: ItemDeMenu[];
  itemDeMenuPaiID?: number;
  rotaCompleta?: string;
  parametro?: string;
  icone?: string;
}