import { Component, OnInit, ViewChild } from '@angular/core';
import { ParamConsulta } from '../../../general/models/paramConsulta';
import { MessageService } from '../../../services/message.service';
import { NotasFiscaisService } from '../../../services/notas-fiscais.service';
import { ModalBasicComponent } from '../../../shared/modal-basic/modal-basic.component';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-busca-itens-por-conta-ordem',
  templateUrl: './busca-itens-por-conta-ordem.component.html',
  styleUrls: ['./busca-itens-por-conta-ordem.component.css']
})
export class BuscaItensPorContaOrdemComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalConsulta', { static: true }) modalConsulta: ModalBasicComponent;
  paramConsulta = new ParamConsulta();
  clienteId = null;
  industrializadorDest = null;
  selecionados = [];
  notas: any = [];
  colunas = [
    { label: 'Série', col: 'serie', hide: true },
    { label: 'Número', col: 'numero', hide: true },
    { label: 'Cliente', col: 'cliente', hide: true },
    { label: 'Emissão', col: 'emissao', date: true, hide: false },
    { label: 'Valor', col: 'valor', hide: false, currency: true },
  ];

  constructor(
    private notasFiscaisService: NotasFiscaisService,
    private messageService: MessageService
  ) {
    super();
  }

  ngOnInit() {
    if (!this.prop) {
      return;
    }

    this.clienteId = this.prop.clienteId;
    this.industrializadorDest = this.prop.industrializadorDest;
    this.carregarNotas();
  }

  show() {
    this.modalConsulta.show();
  }

  onSort(paramConsulta) {
    this.paramConsulta = paramConsulta;
    this.carregarNotas();
  }

  async carregarNotas() {
    try {
      this.loading['table'] = true;
      this.notas = await this.notasFiscaisService
        .listarNotasFiscaisPorContaOrdem(this.paramConsulta, this.clienteId, this.industrializadorDest)
        .toPromise();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading['table'] = false;
    }
  }

  async carregarItens() {
    this.loading['itens'] = true;

    try {
      let res = await this.notasFiscaisService
        .montarItensNFePorContaOrdem(this.selecionados.map(x => x.id))
        .toPromise();

      res.itens = res.itens.map((item) => {
        return {
          produto: item.item ? item.item.id : 0,
          descricaoProduto: item.item ? item.item.nome : item.descricao,
          unidadeMedida: item.unidade,
          quantidade: item.quantidade,
          preco: item.preco,
          valorDesconto: item.percentualDesconto
            ? item.percentualDesconto
            : item.valorDesconto,
          tipoDesconto: item.percentualDesconto ? 1 : 0,
          valorTotalItem: item.quantidade * item.preco,
          itensFornecedores: item.item ? item.item.itensFornecedores : [],
          referenciaItem: item.item ? item.item.referenciaItem : '',
        };
      });
      this.closed.emit(res);
    } catch (error) {
      console.error(error);
      this.messageService.error(
        'Ocorreu um erro ao carregar os itens das notas',
        5000,
        'Atenção!',
        true
      );
    } finally {
      this.loading['itens'] = false;
    }
  }
}
