<app-temp-modal-cadastro #modalCertDigital (closed)="onClose()" [textHeader]="'Certificado Digital'">

  <div class="alert alert-success background-success text-center" *ngIf="certificadoSelecionado && !certificadoVencido">
    <span>Você já possui um certificado configurado com vencimento para o dia <strong>
        {{certificadoSelecionado.validade |
        date:'dd-MM-yyyy HH:mm'}} </strong> </span>
  </div>

  <div class="alert alert-danger background-danger text-center" *ngIf="certificadoSelecionado && certificadoVencido">
    <span>O certificado digital configurado anteriormente está vencido desde o dia <strong>
        {{certificadoSelecionado.validade |
        date:'dd-MM-yyyy HH:mm'}} </strong> </span>
  </div>

  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
    <div class="row">
      <div class="form-group col-sm-12">
        <label class="label-required" style="width: 100%;">Arquivo <i class="ti-help-alt helpIcon float-right"
            ngbTooltip="Precisa de Ajuda?" placement="left" (click)="abrirModalAjuda()"
            style="cursor: pointer; font-size: 1rem; margin: auto; margin-right: 15px;"></i></label>
        <input [disabled]="loading['registro']" type="file" accept=".pfx, .p12" #file class="form-control"
          (change)="fileChanged(file.files)" />
        <show-errors [control]="cadastroForm.controls.arquivo"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <label class="label-required">Senha</label>
        <input [disableControl]="loading['registro']" type="password" class="form-control" formControlName="senha" />
        <show-errors [control]="cadastroForm.controls.senha"></show-errors>
      </div>
    </div>

    <div class="alert alert-danger background-danger" *ngIf="errors">
      <span>{{ errors }}</span>
    </div>

    <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'" [loading]="loading['registro']"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalCertDigital.hide()">Cancelar</button>
  </form>
</app-temp-modal-cadastro>

<app-abrir-modal #abrirModal></app-abrir-modal>