import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { PortadorService } from '../../../services/portador.service';
import { setFieldFormValue, setFormValue } from '../../../general/utils';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-portador',
  templateUrl: './portador.component.html',
  styleUrls: ['./portador.component.css']
})
export class PortadorComponent extends ModalCadastroComponent {

  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  edicaoBloqueada = false;

  constructor(
    private portadoresService: PortadorService,
  ) {
    super();
  }

  cadastroForm = new FormGroup({
    id: new FormControl(null),
    descricao: new FormControl(null, [Validators.required]),
    aceitaLancamento: new FormControl(null),
    descontaTarifa: new FormControl(false)
  });

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    setFieldFormValue(this.cadastroForm, 'descricao', searchParam);
    this.modalCadastro.show();
  }

  carregaDados() {
    if (this.id) {
      this.alteraLoading(true);
      this.portadoresService.getPortadorById(this.id)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe((res) => {
          if (res.descricao === 'BOLETO DIM') {
            this.edicaoBloqueada = true;
            this.errors = [ 'Este Portador não pode ser editado.' ];
          }

          setFormValue(this.cadastroForm, res);
        });
    }
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      this.alteraLoading(true);
      this.portadoresService.post(this.cadastroForm.value)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe(res => {
          this.id = res.generatedId || null;
          this.modalCadastro.hide();
          this.modalCadastro.closed.emit(this.id);
        }, (error) => {
          this.errors = error.msg.errors;
        });
    }
  }
}
