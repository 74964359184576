import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalBasicComponent } from '../../../shared/modal-basic/modal-basic.component';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { UsuarioService } from '../../../authentication/services/usuario.service';

@Component({
  selector: 'app-selecionar-usuario-alterar-senha',
  templateUrl: './selecionar-usuario-alterar-senha.component.html',
  styleUrls: ['./selecionar-usuario-alterar-senha.component.css'],
})
export class SelecionarUsuarioAlterarSenhaComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal: ModalBasicComponent;
  usuariosId = null;
  usuarios: any[] = [];

  constructor(private usuariosSerivice: UsuarioService) {
    super();
  }

  ngOnInit() {
    this.usuariosId = this.prop;
    this.buscarUsuarios();
  }

  show() {
    this.modal.show();
  }

  async buscarUsuarios() {
    try {
      this.usuarios = await this.usuariosSerivice.obterUsuariosToken(this.usuariosId).toPromise();
    } catch (error) {
      console.error(error);
    }
  }

  selectUser(id: number) {
    this.closed.emit(id);
  }
}
