export class TipoSituacaoTributariaICMS {
    tipo: string;
    descricao: string;

    constructor(tipo?: string, descricao?: string) {
        this.tipo = tipo;
        this.descricao = descricao;
    }

    Listar(): TipoSituacaoTributariaICMS[] {
        return [
            new TipoSituacaoTributariaICMS("101", "ICMS 101 - Tributada com Permissão de Credito"),
            new TipoSituacaoTributariaICMS("102", "ICMS 102 - Tributada sem Permissão de Credito"),
            new TipoSituacaoTributariaICMS("103", "ICMS 103 - Isenção do ICMS para faixa de receita Bruta"),
            new TipoSituacaoTributariaICMS("201", "ICMS 201 - Tributada com Permissão de Credito e com cobrança de Substituição Tributária"),
            new TipoSituacaoTributariaICMS("202", "ICMS 202 - Tributada sem Permissão de Credito e com cobrança de Substituição Tributária"),
            new TipoSituacaoTributariaICMS("203", "ICMS 203 - Isenção do ICMS para faixa de receita Bruta e com cobrança de Substituição Tributária"),
            new TipoSituacaoTributariaICMS("300", "ICMS 300 - Imune"),
            new TipoSituacaoTributariaICMS("400", "ICMS 400 - Não tributada"),
            new TipoSituacaoTributariaICMS("500", "ICMS 500 - ICMS Cobrado anteriormente por ST ou Antecipação"),
            new TipoSituacaoTributariaICMS("900", "ICMS 900 - Outros"),
            new TipoSituacaoTributariaICMS("00", "ICMS 00 - Tributada Integralmente"),
            new TipoSituacaoTributariaICMS("10", "ICMS 10 - Tributada e com Cobrança do ICMS por Substituição Tributária"),
            new TipoSituacaoTributariaICMS("20", "ICMS 20 - Com redução da Base de Cálculo"),
            new TipoSituacaoTributariaICMS("30", "ICMS 30 - Isenta ou não Tributada e com Cobrança do ICMS por Substituição Tributária"),
            new TipoSituacaoTributariaICMS("40", "ICMS 40 - Isenta"),
            new TipoSituacaoTributariaICMS("41", "ICMS 41 - Não Tributada"),
            new TipoSituacaoTributariaICMS("50", "ICMS 50 - Suspensão"),
            new TipoSituacaoTributariaICMS("51", "ICMS 51 - Diferimento"),
            new TipoSituacaoTributariaICMS("60", "ICMS 60 - Cobrado Anteriormente por Substituição Tributária"),
            new TipoSituacaoTributariaICMS("70", "ICMS 70 - Com redução da Base de Cálculo e Cobrança do ICMS por Substituição Tributária"),
            new TipoSituacaoTributariaICMS("90", "ICMS 90 - Outras"),
        ];
    }

    ListarSimples(): TipoSituacaoTributariaICMS[] {
        return this.Listar().filter(cst => +cst.tipo >= 101);
    }

    ListarRegimeNormal(): TipoSituacaoTributariaICMS[] {
        return this.Listar().filter(cst => +cst.tipo <= 90);
    }
}