import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { SaveResponseTipoComissaoViewModel } from 'models/tipo-comissao/TipoComissaoViewModel';
import { TipoComissaoViewModel } from 'models/tipo-comissao/TipoComissaoViewModel';
import { TipoComissaoServiceV2 } from '@servicesv2';

@Component({
  selector: 'app-tipo-comissao',
  templateUrl: './tipo-comissao.component.html',
  styleUrls: ['./tipo-comissao.component.css']
})
export class TipoComissaoComponent extends ModalCadastroComponent {

  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  grupoDespesas = [];
  contas = [];
  id;

  @Output() closed = new EventEmitter();

  constructor(
    private service: TipoComissaoServiceV2
  ) {
    super();
  }

  cadastroForm = new FormGroup({
    id: new FormControl(0),
    descricao: new FormControl(null, [Validators.required]),
    percentualComissaoCobranca: new FormControl(null),
    percentualComissaoFaturamento: new FormControl(null),
  });

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    this.cadastroForm.get('descricao').setValue(searchParam);
    this.modalCadastro.show();
  }

  async carregaDados() {
    if (!this.id) {
      return;
    }

    try {
      this.alteraLoading(true);
      const response = await this.service.getAsPromise<TipoComissaoViewModel>(this.id);
      this.cadastroForm.patchValue(response);
    } catch (e) {
      console.error(e);
    } finally {
      this.alteraLoading(false);
    }
  }

  async submitForm() {
    if (this.cadastroForm.invalid) {
      return;
    }

    const descricao = this.cadastroForm.get('descricao');
    descricao.setValue(descricao.value.trim());

    try {
      this.alteraLoading(true);
      const payload = this.cadastroForm.value as TipoComissaoViewModel;
      const response = await this.service.saveAsPromise<TipoComissaoViewModel, SaveResponseTipoComissaoViewModel>(payload);
      this.id = response.generatedId || response.id;
      this.modalCadastro.hide();
    } catch (e) {
      console.error(e);
      this.errors = e.msg.errors[0];
    } finally {
      this.alteraLoading(false);
    }
  }
}
