import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import * as moment from 'moment';
import { ContasPagarService } from '../../../services/contas-pagar.service';
import { MessageService } from '../../../services/message.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-replica-parcelas-fornecedor',
  templateUrl: './replica-parcelas-fornecedor.component.html',
  styleUrls: ['./replica-parcelas-fornecedor.component.css']
})
export class ReplicaParcelasFornecedorComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalReplicacao", { static: true }) modalReplicacao;

  @Input() primeiroVencimento: string = '';
  @Input() tituloBase: any = null;

  parcelamentos = [];
  calendarPosition = "bottom";

  show() {
    this.modalReplicacao.show();
  }

  gerarParcelas(quantidade: number) {
    this.parcelamentos = [];
    for (let i = 0; i < quantidade; i++) {
      const parcela = Object.assign({}, this.tituloBase);
      const sequenciaParcela = Number(this.tituloBase.parcela.toString().replace(/[^0-9]+/g, "")) || 1;
      parcela.parcela = sequenciaParcela + i;
      parcela.vencimento = moment(this.primeiroVencimento).add(i, 'months').startOf('day').toISOString();
      this.parcelamentos.push(parcela);
    }
  }

  isLastItem(totalParcelamento: number, index: number) {
    return (index >= totalParcelamento - 3) ? 'top' : 'bottom';
  }

  salvarTitulos() {
    this.alteraLoading(true, 'request');
    this.contasPagarService.postMultipleForm(this.parcelamentos)
      .pipe(finalize(() => this.alteraLoading(false, 'request')))
      .subscribe(() => {
        this.closed.emit(true);
        this.messageService.success();
        this.modalReplicacao.hide();
      }, error => {
        this.errors = error.msg.errors;
      });
  }

  constructor(
    private contasPagarService: ContasPagarService,
    private messageService: MessageService) {
    super();
  }

  ngOnInit() {
  }

  get ocultarEmTelaMenor() {
    return "d-none d-lg-table-cell";
  }
}
