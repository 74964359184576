import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { markFormGroupTouched } from '../../general/utils';

@Component({
  selector: 'app-modal-cadastro',
  template: ''
})
export class ModalCadastroComponent {

  cadastroForm: FormGroup;
  @Input() public prop: any = null;
  @Input() public id: number;
  @Input() public errors: any = null;
  @Input() public loading: any = {};
  @Output() closed: EventEmitter<any> = new EventEmitter();

  alteraLoading(loading: boolean, prop = 'registro') {
    if (loading) {
      this.errors = null;
    }
    this.loading[prop] = loading;
  }

  validaForm() {
    markFormGroupTouched(this.cadastroForm);
  }

  onClose() {
    this.closed.emit(this.id);
  }

  get textHeader(): string {
    return this.id ? 'Editar' : 'Cadastrar';
  }

}
