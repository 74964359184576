<app-modal-basic #modalRelatorio (closed)="onClose()" [modalContentId]="'modal-rv'">
  <div class="app-modal-header">
    <h4 class="modal-title">
      Exportação NFS-e
    </h4>
    <button type="button" class="close basic-close" (click)="hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="form">
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Emissão</label>
          <div class="input-group">
            <app-data [calendarPosition]="'left'" formControlName="emissao1">
            </app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="emissao2"></app-data>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>
            Destinatários
            <span ngbTooltip="Para inserir um novo destinatário, digite-o no campo abaixo e tecle enter." placement="right">
              <i class="fa fa-question-circle help-icon"></i>
            </span>
          </label>
          <app-email-box formControlName="destinatarios" #emailBox></app-email-box>
          <show-errors [control]="form.controls['destinatarios']"></show-errors>
        </div>
      </div>
    </form>

    <button type="submit" class="btn btn-info ripple light" (click)="exportar()"
      [disabled]="loading['exportacao']">
      <app-loading [loading]="loading['exportacao']" [icone]="''" [texto]="'Exportar'"
        [textoLoading]="'Enviando'"></app-loading>
    </button>
    <button type="button" (click)="hide()" class="btn btn-danger" [disabled]="loading['salvando']">
      <i class="ti ti-close"></i> Cancelar
    </button>
  </div>
</app-modal-basic>
