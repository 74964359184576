import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable()
export class ProcessoImportacaoService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  buscar(form: any) {
    return this.http.post(this.UrlService + 'ProcessoImportacao/Buscar', form)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  getPaginado(pagina: number, filtro: string) {
    return this.http.get(this.UrlService + 'ProcessoImportacao/GetPaginado?pageIndex=' + pagina + '&search=' + filtro)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  obterPorId(id: number) {
    return this.http.get(this.UrlService + `ProcessoImportacao/${id}`)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  cadastrar(form: any): Observable<any> {
    return this.http.post(this.UrlService + 'ProcessoImportacao', form)
      .pipe(map(super.extractData), catchError(super.serviceError))
  }

  editar(form: any) {
    return this.http.put(this.UrlService + 'ProcessoImportacao', form)
      .pipe(map(super.extractData), catchError(super.serviceError))
  }

  delete(id: number) {
    return this.http.delete(this.UrlService + `ProcessoImportacao/${id}`)
      .pipe(map(super.extractData), catchError(super.serviceError))
  }

  imprimir(form: any) {
    return this.http
      .post(this.UrlService + `ProcessoImportacao/imprimir`, form, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }
  
  visualizarProcessosImportacao(form: any) {
    return this.http.post(this.UrlService + `ProcessoImportacao/visualizarProcessosImportacao`, form)
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }

  imprimirVisualizacaoProcessosImportacao(form: any) {
    return this.http
      .post(this.UrlService + `ProcessoImportacao/imprimirVisualizacaoProcessosImportacao`, form, { responseType: 'blob' })
      .pipe(catchError(super.parseErrorBlob));
  }
}
