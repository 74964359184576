import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dim-error-message',
  templateUrl: './dim-error-message.component.html',
  styleUrls: ['./dim-error-message.component.scss'],
})
export class DimErrorMessageComponent {

  @Input() formGroup: FormGroup;
  @Input() validateTouched = true;

  get formErrors() {
    if (!this.formGroup || (this.validateTouched && !this.formGroup.touched)) {
      return [];
    }

    return Object.keys(this.formGroup.errors || {})
      .map((key: string) => {
        return { key, message: this.formGroup.errors[key].message };
      });
  }
}
