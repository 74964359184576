<app-temp-modal-cadastro [textHeader]="'Informações Fiscais CST: ' + prop.stVenda" #modalFiscal (closed)="onClose()">
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
    <div class="row">
      <div class="form-group col-sm-12">
        <label>Código Benefício Fiscal</label>
        <ng-select appendTo=".modal-content" [disableControl]="loading['codigosBeneficios']"
          [loading]="loading['codigosBeneficios']" [clearable]='false' placeholder="Selecione"
          [items]="codigosBeneficios" bindLabel="codigoDescricao" bindValue="codigo"
          formControlName="codigoBeneficioFiscal">
        </ng-select>
      </div>
    </div>

    <div class="row" *ngIf="cadastroForm.get('motivoDesoneracaoIcms') && cadastroForm.get('valorIcmsDesonerado')">
      <div class="form-group col-sm-6">
        <label>Motivo Desoneração</label>
        <ng-select appendTo=".modal-content" [clearable]='false' placeholder="Selecione" [items]="motivosDesoneracao"
          bindLabel="tipoDescricao" bindValue="tipo" formControlName="motivoDesoneracaoIcms">
        </ng-select>
      </div>

      <div class="form-group col-sm-6">
        <label>Valor ICMS Desonerado</label>
        <input type="text" class="form-control" formControlName="valorIcmsDesonerado" autoselect currencyMask inputmode="numeric" />
        <show-errors [control]="cadastroForm.controls.valorIcmsDesonerado"></show-errors>
      </div>
    </div>

    <button type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'"></app-loading>
    </button>
    <button type="button" class="btn btn-default ripple" (click)="modalFiscal.hide()">Cancelar</button>
  </form>
</app-temp-modal-cadastro>