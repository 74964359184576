import {Label} from '@amcharts/amcharts4/core';
import {ContextMenuService} from '../../services/context-menu.service';

// PRIMARY CLASSES

export class ContextMenu {
  positionX: number;
  positionY: number;

  mouseEvent: MouseEvent;
  groups: ContextMenuGroup[];
  main: boolean;
  parentContext;

  props: any;

  width: number = 200;

  constructor(mouseEvent: MouseEvent, groups: ContextMenuGroup[], props = null) {
    if (mouseEvent) {
      this.positionY = mouseEvent.pageY;
      this.positionX = mouseEvent.pageX;
      this.main = true;
      this.mouseEvent = mouseEvent;

      mouseEvent.preventDefault();
    }

    this.props = props;
    this.groups = groups;
  }

  bordersVerify() {
    if (this.mouseEvent) {
      const documentElement: Document = (this.mouseEvent.target as HTMLElement).ownerDocument;
      const window = documentElement.defaultView || documentElement['parentWindow'];

      if (window) {
        if (window.innerWidth < (this.positionX + this.width)) {
          this.positionX -= this.width;
        }
      }
    }
  }
}

export class ContextMenuGroup {
  options: ContextMenuOption[];

  constructor(options: ContextMenuOption[]) {
    this.options = options;
  }
}

class ContextMenuOption {
  label: string;
  action;
  subContext: SubContextMenu;

  constructor(label: string, subContext: SubContextMenu = null) {
    this.label = label;
    this.subContext = subContext;
  }
}

// SECONDARY CLASSES

export class ContextMenuRedirectOption extends ContextMenuOption {
  constructor(label: string, routePath: string) {
    super(label);
  }
}

export class ContextMenuTitle extends ContextMenuOption {
  constructor(title: string) {
    super(title);
  }
}

export class ContextMenuActionOption extends ContextMenuOption {
  constructor(label: string, action) {
    super(label);
    this.action = action;
  }
}

export class ContextMenuSubgroupOption extends ContextMenuOption {
  subContext: SubContextMenu;

  constructor(label: string, subContext: SubContextMenu) {
    super(label);
    if (subContext !== null) {
      this.subContext = subContext;
    }
  }
}

export class SubContextMenu extends ContextMenu {
  constructor(groups: ContextMenuGroup[]) {
    super(null, groups);
  }

  setPosition(mainContextElement: HTMLElement, event: MouseEvent) {
    const dropdown: HTMLElement = mainContextElement.querySelector('.dropdown-menu');
    this.parentContext = mainContextElement;

    this.positionY = (event.target as HTMLElement).offsetTop;
    this.positionX = dropdown.offsetWidth;

    this.mouseEvent = event;
    this.bordersVerify();
    event.preventDefault();
  }

  bordersVerify() {
    if (this.mouseEvent) {
      const documentElement: Document = (this.mouseEvent.target as HTMLElement).ownerDocument;
      const window = documentElement.defaultView || documentElement['parentWindow'];

      if (window) {
        if ((this.parentContext.offsetLeft + (this.width * 2)) > window.innerWidth) {
          this.positionX -= 200;
        }
      }
    }
  }
}

// PRIVATE METHODS

function Pixels(value: number) {
  return `${value}px`;
}

// INTERFACES

export interface ContextMenuOptionInterface {
  label: string;
  action;
  subContext: SubContextMenu;
}
