export  class EdicaoMascaras {

  id: number;
  descricao: string;

  constructor() {

  }

  // tipo:
  // 0 - Ambos
  // 1 - CPF
  // 2 - CNPJ
  static retornaMascaraCpfCnpj(cpfCnpj, tipo: number): string {
    if ((cpfCnpj && cpfCnpj.length <= 11 && tipo == 0) || (tipo == 1)) {
      return '000.000.000-009';
    }
    else {
      if (tipo == 0 || tipo == 2) {
        return '00.000.000/0000-00';
      }
    }
  }

  static retornaMascaraTelefone(telefone) {

    if (telefone && telefone.replace(/[^0-9]/g, '').length <= 10) {
      return '(00) 0000-00009';
    }
    if (telefone && telefone.length > 10) {
      return '(00) 00000-0000';
    }
  }
}