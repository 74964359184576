import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { NgSelectComponent } from '@ng-select/ng-select';

import { AbrirModalComponent } from '../abrir-modal/abrir-modal.component';
import { BaseViewModel } from '@servicesv2';

@Component({
  selector: 'app-combobox-padrao',
  templateUrl: './combobox-padrao.component.html',
  styleUrls: ['./combobox-padrao.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxPadraoComponent),
      multi: true
    }
  ]
})
export class ComboboxPadraoComponent<T extends BaseViewModel> implements ControlValueAccessor, OnChanges {

  @ViewChild('abrirModal', { static: false }) abrirModal: AbrirModalComponent;
  @ViewChild('ngSelect') ngSelect: NgSelectComponent;

  @Input() id: string;
  @Input() items: T[];
  @Input() bindLabel: string;
  @Input() bindValue: string;
  @Input() label: string;
  @Input() appendTo: string;
  @Input() placeholder: string;
  @Input() notFoundText: string;
  @Input() virtualScroll: boolean;
  @Input() loading: boolean;
  @Input() clearOnBackspace: boolean;
  @Input() disabled: boolean;
  @Input() notFoundPlusIcon: boolean;
  @Input() pencilIcon: boolean;
  @Input() modalName: string;
  @Input() loadingText: string;
  @Input() required: boolean;
  @Input() requiredText: string;

  @Output() scrollToEnd = new EventEmitter();
  @Input() typeahead = new Subject<string | void>();

  @Output() comboboxEnabled = new EventEmitter();
  @Output() comboboxDisabled = new EventEmitter();

  modalState: 'open' | 'closed' = 'closed';
  modalType: 'new' | 'edit';
  selected = new FormControl(null);
  @Output() changed = new EventEmitter();
  @Output() added = new EventEmitter();
  @Output() edited = new EventEmitter();

  onChange: (value: number) => void;
  onTouched: () => void;

  constructor(private cdr: ChangeDetectorRef) {
    this.selected.setValidators([this.validateRequired()]);
  }

  ngOnChanges(changes: SimpleChanges) {
    // https://angular.dev/api/core/OnChanges
    const { disabled } = changes;
    if (disabled) {
      if (this.disabled || disabled?.currentValue as boolean) {
        this.selected.disable();
        this.comboboxDisabled.emit();
      } else {
        this.selected.enable();
        this.comboboxEnabled.emit();
      }
    }
  }

  handleOnClickNotFoundPlusIcon(searchTerm: string) {
    if (!this.modalName) {
      console.error('modalName não informado.');
      return;
    }

    this.ngSelect.close();
    this.abrirModal.cadastrar(this.modalName, searchTerm);
    this.modalState = 'open';
    this.modalType = 'new';
  }

  handleOnClickPencilIcon(id: number) {
    if (!this.modalName) {
      console.error('modalName não informado.');
      return;
    }

    this.ngSelect.close();
    this.abrirModal.editar(this.modalName, id);
    this.modalState = 'open';
    this.modalType = 'edit';
  }

  handleKeyDown(ke: KeyboardEvent) {
    if (this.loading) {
      ke.preventDefault();
    }
  }

  handleOnChange(value?: T) {
    let newValue = null;
    if (value !== undefined && value !== null) {
      newValue = value[this.bindValue];
    }
    this.selected.setValue(newValue);
    this.changed.emit(newValue);
    this.onChange(newValue);
    this.ngSelect.blur();
  }

  handleScrollToEnd() {
    this.scrollToEnd.emit();
  }

  handleOnClosedAbrirModal(id: number) {
    this.selected.setValue(id);
    if (this.modalType === 'new') {
      this.added.emit(id);
    } else if (this.modalType === 'edit') {
      this.edited.emit(id);
    }
    this.onChange(id);
    this.modalState = 'closed';
    this.modalType = undefined;
  }

  writeValue(value: T): void {
    this.selected.setValue(value);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  validateRequired(): ValidatorFn {
    return (control: FormControl): ValidationErrors => {
      if (!this.required) {
        return null;
      }

      if (!control.value) {
        return { custom: { message: this.requiredText } };
      }

      return null;
    };
  }

  labelStyle(required: boolean) {
    const style = {};
    if (required) {
      style['font-weight'] = 'bold';
    }
    return style;
  }
}
