import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../../../services/message.service';
import { NotasFiscaisService } from '../../../services/notas-fiscais.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-notas-fiscais-venda-por-cliente',
  templateUrl: './notas-fiscais-venda-por-cliente.component.html',
  styleUrls: ['./notas-fiscais-venda-por-cliente.component.css'],
})
export class NotasFiscaisVendaPorClienteComponent extends ModalCadastroComponent implements OnInit {
  constructor(private nfeService: NotasFiscaisService, private message: MessageService) {
    super();
  }

  @ViewChild('modalRelatorio', { static: true }) modalRelatorio;
  form: FormGroup;
  errorMsg: string;
  loading = false;

  ngOnInit(): void {
    this.form = new FormGroup({
      emissao1: new FormControl(null, [Validators.required]),
      emissao2: new FormControl(null),
      fabricacaoPropria: new FormControl(false),
    });
  }

  async imprimir(): Promise<void> {
    if (this.form.invalid) {
      this.errorMsg = 'Para emitir o relatório, preencha a data de emissão inicial.';
      return;
    }

    this.errorMsg = '';
    this.loading = true;
    try {
      const res = await this.nfeService.imprimirNFesVendaPCliente(this.form.value).toPromise();
      saveAs(res, 'relacao_nfes_por_cliente.xlsx');
      this.message.success('Relatório gerado com sucesso!');
      this.close();
    } catch (error) {
      if (error.errors[0]) this.errorMsg = error.errors[0];
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  show(): void {
    this.modalRelatorio.show();
  }

  close(): void {
    this.closed.emit();
  }
}
