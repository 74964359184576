<label [ngStyle]="labelStyle(required)" [for]="id">{{ label }}</label>
<ng-select #ngSelect
  [appendTo]="appendTo"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  (change)="handleOnChange($event)"
  [clearOnBackspace]="clearOnBackspace"
  [formControl]="selected"
  [items]="items"
  [loading]="loading"
  [loadingText]="loadingText"
  [notFoundText]="notFoundText"
  [placeholder]="placeholder"
  (scrollToEnd)="handleScrollToEnd()"
  [typeahead]="typeahead"
  [virtualScroll]="virtualScroll"
  (keydown)="handleKeyDown($event)"
  >

  <ng-template ng-option-tmp let-item="item" *ngIf="notFoundPlusIcon">
    <app-temp-option-item [name]="item[bindLabel] || 'Erro ao formatar nome do item'" (onClick)="handleOnClickPencilIcon(item.id)">
    </app-temp-option-item>
  </ng-template>

  <ng-template ng-notfound-tmp let-searchTerm="searchTerm" *ngIf="pencilIcon">
    <app-temp-not-found-item [searchTerm]="searchTerm" (onClick)="handleOnClickNotFoundPlusIcon(searchTerm)">
    </app-temp-not-found-item>
  </ng-template>

</ng-select>
<show-errors *ngIf="modalState === 'closed'" [control]="selected"></show-errors>

<app-abrir-modal #abrirModal
  (closed)="handleOnClosedAbrirModal($event)"
></app-abrir-modal>
