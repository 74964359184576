import { Component, OnInit, ViewChild, AfterContentInit } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { setFieldFormValue } from '../../../general/utils';
import { TitulosCobrancaService } from '../../../services/titulos-cobranca.service';
import { MessageService } from '../../../services/message.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-estorno-contas-receber',
  templateUrl: './estorno-contas-receber.component.html',
  styleUrls: ['./estorno-contas-receber.component.css']
})
export class EstornoContasReceberComponent extends ModalCadastroComponent {

  @ViewChild("modalEstorno", { static: true }) modalEstorno;

  constructor(private titulosCobrancaService: TitulosCobrancaService, private messageService: MessageService) {
    super();
  }

  tituloSelecionado;
  titulosSelecionados: any[];
  estornoUnico: boolean;

  cadastroForm = new FormGroup({
    titulo: new FormControl(null),
    tipoEstorno: new FormControl('0'),
    dataCredito: new FormControl(null, [Validators.required])
  })

  get textHeader() {
    return this.estornoUnico || this.titulosSelecionados.length <= 1 ? 'Estorno título'
      : 'Estorno títulos';
  }

  estornoMultiplosTitulos(titulos) {
    this.estornoUnico = false;
    setFieldFormValue(this.cadastroForm, 'dataCredito', new Date());
    this.titulosSelecionados = titulos;
  }

  estornoTituloUnico(titulo) {
    this.estornoUnico = true;
    this.tituloSelecionado = titulo;
    const dataCredito = this.tituloSelecionado.dataCredito || this.tituloSelecionado.pagamento;
    setFieldFormValue(this.cadastroForm, 'titulo', this.tituloSelecionado.id);
    setFieldFormValue(this.cadastroForm, 'dataCredito', dataCredito ? new Date(dataCredito) : null);
  }

  show() {
    this.modalEstorno.show();
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      this.alteraLoading(true);
      if (this.estornoUnico) {
        this.salvarUnico();
      } else if (this.titulosSelecionados.length > 0) {
        this.salvarMultiplos();
      }
    }
  }

  private salvarUnico() {
    this.titulosCobrancaService.estornarTitulo(this.cadastroForm.value)
      .pipe(finalize(() => this.alteraLoading(false)))
      .subscribe(() => {
        this.closed.emit(true);
        this.messageService.success();
        this.modalEstorno.hide();
      }, error => {
        this.errors = error.msg.errors;
      })
  }

  private salvarMultiplos() {
    const titulosSalvar = this.titulosSelecionados.map(t => { return { titulo: t, dataCredito: this.cadastroForm.get('dataCredito').value, tipoEstorno: this.cadastroForm.get('tipoEstorno').value } })
    this.titulosCobrancaService.estornarGroupTitulos(titulosSalvar)
      .pipe(finalize(() => this.alteraLoading(false)))
      .subscribe(() => {
        this.closed.emit(true);
        this.messageService.success();
        this.modalEstorno.hide();
      }, error => {
        this.errors = error.msg.errors;
      })
  }
}
