import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ClassificacoesFiscaisService } from '../../../../services/classificacoes-fiscais.service';
import { ItensService } from '../../../../services/itens.service';
import { MessageService } from '../../../../services/message.service';

@Component({
  selector: 'app-historico-relatorio-estoque',
  templateUrl: './relatorio-historico-estoque.component.html',
  styleUrls: ['./relatorio-historico-estoque.component.css']
})
export class RelatorioHistoricoEstoqueComponent extends ModalCadastroComponent implements OnInit {
  constructor(
    private builder: FormBuilder,
    private message: MessageService,
    private itensService: ItensService,
    private classificacoesService: ClassificacoesFiscaisService,
  ) {
    super();
  }
  @Output() closed: EventEmitter<any> = new EventEmitter();
  @ViewChild('modalConsultar', { static: true }) modalConsultar;
  @ViewChild('abrirModal', { static: false }) abrirModal;

  retornoUsuarios: any = null;
  public loading = [];
  form: FormGroup;

  ngOnInit() {
    this.form = this.builder.group({
      formato: new FormControl("pdf")
    });
  }

  show() {
    this.modalConsultar.show();
  }

  imprimir(){
    this.closed.emit(this.form.value);
  }

  onClose(){
    this.closed.emit();
  }
}
