import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as saveAs from 'file-saver';
import { firstValueFrom } from 'rxjs';
import { getErrorMessage } from '../../../general/utils';
import { MessageService } from '../../../services/message.service';
import { ProcessoImportacaoService } from '../../../services/processo-importacao.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-visualizar-processos-de-importacao',
  templateUrl: './visualizar-processos-de-importacao.component.html',
  styleUrls: ['./visualizar-processos-de-importacao.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({
          opacity: '0',
        }),
        animate('200ms ease-in', style({
          opacity: '1',
        }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({
          opacity: '0',
        }))
      ])
    ])
  ]
})
export class VisualizarProcessosDeImportacaoComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  form: FormGroup;
  dados = [];

  colunasSemAgrupamento = [
    { label: 'Data', col: 'data', date: true },
    { label: 'Processo Importação', col: 'processoImportacaoNome' },
    { label: 'Cliente Contratante', col: 'clienteContratanteRazaoSocial' },
    { label: 'Transação', col: 'tipo', format: value => value === 'E' ? 'Entrada' : 'Saída' },
    { label: 'Valor', col: 'valor', currency: true },
  ];

  colunasAgrupamentoPorProcesso = [
    { label: 'Data', col: 'data', date: true },
    { label: 'Transação', col: 'tipo', format: value => value === 'E' ? 'Entrada' : 'Saída' },
    { label: 'Valor', col: 'valor', currency: true },
  ];

  colunasAgrupamentoPorCliente = [
    { label: 'Processo Importação', col: 'processoImportacaoNome' },
    { label: 'Data', col: 'data', date: true },
    { label: 'Transação', col: 'tipo', format: value => value === 'E' ? 'Entrada' : 'Saída' },
    { label: 'Valor', col: 'valor', currency: true },
  ];

  constructor(
    private processoImportacaoService: ProcessoImportacaoService,
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      tipoVisualizacao: new FormControl('M'),
      dataInicio: new FormControl(null),
      dataFim: new FormControl(null),
      clienteContratanteID: new FormControl(null),
      suprimirSaldosZerados: new FormControl<boolean>(null),
    });

    this.form.get('tipoVisualizacao').valueChanges.subscribe(res => {
      this.dados = [];

      if (res === 'C' || res === 'CP') {
        this.form.get('clienteContratanteID').setValue(null);
      }

      if (res === 'M') {
        this.form.get('suprimirSaldosZerados').setValue(null);
      }
    })
  }

  async consultar() {
    try {
      this.loading['consulta'] = true;

      this.dados = [];
      this.dados = await firstValueFrom(this.processoImportacaoService.visualizarProcessosImportacao(this.form.getRawValue()));
    } catch (err) {
      this.messageService.error('Falha ao buscar movimentações: ' + getErrorMessage(err), 8000);
    } finally {
      this.loading['consulta'] = false;
    }
  }

  show() {
    this.modalCadastro.show();
  }

  destroyComponent() {
    this.closed.emit();
  }

  async imprimir() {
    try {
      this.loading['imprimir'] = true;

      const res = await firstValueFrom(this.processoImportacaoService.imprimirVisualizacaoProcessosImportacao(this.form.getRawValue()));
      saveAs(res, 'relatorio_processos_importacao.pdf');
    } catch (err) {
      this.messageService.error('Falha ao exportar: ' + getErrorMessage(err), 8000);
    } finally {
      this.loading['imprimir'] = false;
    }
  }
}
