import {ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector} from '@angular/core';
import {DimContextMenuComponent} from '../shared/dim-context-menu/dim-context-menu.component';
import {ContextMenu} from '../model/context-menu/contextMenu';

@Injectable({
  providedIn: 'root'
})
export class ContextMenuService {
  contextMenuComponentRef: ComponentRef<DimContextMenuComponent> = null;
  contextMenu: ContextMenu;
  onCloseEvent;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  private async appendContextComponent(parentElement: HTMLElement = document.body) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DimContextMenuComponent);
    const componentRef = componentFactory.create(this.injector);

    componentRef.instance.contextMenuServiceInstance = this;
    await componentRef.instance.createContextData(this.contextMenu);

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    parentElement.appendChild(domElem);

    this.contextMenuComponentRef = componentRef;
  }

  removeContextMenuFromBody() {
    if (this.contextMenuComponentRef) {
      this.appRef.detachView(this.contextMenuComponentRef.hostView);
      this.contextMenuComponentRef.destroy();
      this.contextMenuComponentRef = null;

      if (this.onCloseEvent) {
        this.onCloseEvent();
      }
    }
  }

  async openSubMenu(context: ContextMenu, element: HTMLElement) {
    this.contextMenu = context;

    if (this.contextMenuComponentRef !== null) {
      this.removeContextMenuFromBody();
    }

    await this.appendContextComponent(element);

    return this;
  }

  async open(context: ContextMenu, onCloseEvent = null) {
    this.contextMenu = context;

    if (this.contextMenuComponentRef !== null) {
      this.removeContextMenuFromBody();
    }

    this.onCloseEvent = onCloseEvent;

    await this.appendContextComponent();

    return this;
  }
}
