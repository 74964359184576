<div [formGroup]="form" style="position: relative">
  <i
    (click)="handleEyeClick()"
    class="fa fa-eye password-eye"
    [ngClass]="{ 'fa-eye-slash': !verSenha }"
  ></i>
  <input
    id="input-senha"
    [formControlName]="formControlName"
    (input)="onInputChange($event)"
    [type]="verSenha ? 'text' : 'password'"
    [placeholder]="placeholder"
    (blur)="onBlur()"
    [maxlength]="maxlength"
    [readonly]="disabled"
    [mask]="mascara"
    [class]="'form-control ' + customClasses"
  />
</div>
