import { Component, OnInit, ViewChild } from '@angular/core';
import { ParamConsulta } from '../../../general/models/paramConsulta';
import { ModalBasicComponent } from '../../../shared/modal-basic/modal-basic.component';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { RomaneiosCargaService } from '../../../services/romaneiosCarga.service';
import { MessageService } from '../../../services/message.service';
import { NotasFiscaisService } from '../../../services/notas-fiscais.service';

@Component({
  selector: 'app-buscar-romaneio',
  templateUrl: './buscar-romaneio.component.html',
  styleUrls: ['./buscar-romaneio.component.css']
})
export class BuscarRomaneioComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalConsulta', { static: false }) modalConsulta: ModalBasicComponent;
  paramConsulta = new ParamConsulta();
  transportadorId = null;
  selecionados = [];
  romaneios: any;
  colunas = [
    { label: 'Transportador', col: 'fornecedorNome', hide: false },
    { label: 'Descrição', col: 'descricao', hide: false },
  ];
  constructor(
    private notasFiscaisService: NotasFiscaisService,
    private romaneiosService: RomaneiosCargaService,
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit() {
    if (!this.prop) {
      return;
    }

    this.romaneios = this.prop;
    this.transportadorId = this.prop.transportadorId;
  }

  show() {
    this.modalConsulta.show();
  }

  onSort(paramConsulta) {
    this.paramConsulta = paramConsulta;
    this.carregarRomaneios();
  }

  async carregarRomaneios() {
    try {
      this.loading['table'] = true;
      this.romaneios = await this.romaneiosService.getPaginado({ paramConsulta: this.paramConsulta }).toPromise();
    } catch (error) {
      console.error(error);
    } finally {
      this.loading['table'] = false;
    }
  }

  async carregarItens() {
    this.loading['itens'] = true;

    try {
      let res = await this.notasFiscaisService
        .montarItensRomaneio(this.selecionados.map(x => x.id))
        .toPromise();
      res = res.map((item) => {
        return {
          produto: item.item ? item.item.id : 0,
          descricaoProduto: item.item ? item.item.nome : item.descricao,
          unidadeMedida: item.unidade,
          quantidade: item.quantidade,
          preco: item.preco,
          valorDesconto: item.percentualDesconto
            ? item.percentualDesconto
            : item.valorDesconto,
          tipoDesconto: item.percentualDesconto ? 1 : 0,
          valorTotalItem: item.quantidade * item.preco,
          referenciaItem: item.item.referenciaItem,
          itensFornecedores: item.item ? item.item.itensFornecedores : [],
        };
      });

      this.closed.emit(res);
    } catch (error) {
      console.error(error);
      this.messageService.error(
        'Ocorreu um erro ao carregar os itens das notas',
        5000,
        'Atenção!',
        true
      );
    } finally {
      this.loading['itens'] = false;
    }
  }

}
