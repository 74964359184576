<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader">
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
    <div class="row">
      <div class="form-group col-sm-6">
        <label class="font-weight-bold">Cep</label>
        <input [disableControl]="loading['registro'] || loading['endereco']" formControlName="cep" class="form-control" mask='00000-000'
          (change)="getEndereco()"/>
        <show-errors [control]="cadastroForm.controls.cep"></show-errors>
      </div>

      <div class="form-group col-sm-6">
        <label class="label-required">Estado</label>
        <ng-select (ngModelChange)="alteraEstado($event)" formControlName="estadoId"
          [disableControl]="loading['registro'] || loading['estados'] || loading['endereco']" placeholder="Selecione"
          [loading]="loading['registro'] || loading['estados'] || loading['endereco']" [items]="estados" [searchFn]="buscaEstadoNomeSigla"
          bindLabel="nome" bindValue="id">
        </ng-select>
        <show-errors [control]="cadastroForm.controls.estadoId"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6">
        <label class="label-required">Cidade</label>
        <ng-select [disableControl]="loading['registro'] || loading['cidades'] || loading['endereco']" 
          placeholder="Selecione" [loading]="loading['registro'] || loading['cidades'] || loading['endereco']" 
          [items]="cidades" bindLabel="nome" bindValue="id" formControlName="cidadeId">
        </ng-select>
        <show-errors [control]="cadastroForm.controls.cidadeId"></show-errors>
      </div>

      <div class="form-group col-sm-6">
        <label class="label-required">Bairro</label>
        <input [disableControl]="loading['registro'] || loading['endereco']" class="form-control" formControlName="bairro" />
        <show-errors [control]="cadastroForm.controls.bairro"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-6">
        <label class="label-required">Endereço</label>
        <input [disableControl]="loading['registro'] || loading['endereco']" type="text" class="form-control" formControlName="endereco" />
        <show-errors [control]="cadastroForm.controls.endereco"></show-errors>
      </div>

      <div class="form-group col-sm-6">
        <label class="label-required">Numero</label>
        <input [disableControl]="loading['registro'] || loading['endereco']" class="form-control" mask="0{20}" formControlName="numero" />
        <show-errors [control]="cadastroForm.controls.numero"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <label>Complemento</label>
        <input [disableControl]="loading['registro'] || loading['endereco']" class="form-control" formControlName="complemento" />
        <show-errors [control]="cadastroForm.controls.complemento"></show-errors>
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="errors">
      <span>{{ errors }}</span>
    </div>

    <button [disabled]='loading["submit"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'Salvar'" [loading]="loading['submit']"></app-loading>
    </button>
    <button [disabled]='loading["submit"]' type="button" class="btn btn-default ripple"
      (click)="modalCadastro.hide()">Cancelar</button>
  </form>
</app-temp-modal-cadastro>

<app-abrir-modal #abrirModal></app-abrir-modal>