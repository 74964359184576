import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { ConfiguracoesUsuario } from '../model/ConfiguracoesUsuario';
import { Observable } from 'rxjs';

@Injectable()
export class ConfiguracoesUsuarioService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getByUser(): Observable<ConfiguracoesUsuario> {
        return this.http.get(this.UrlService + 'ConfiguracoesUsuario')
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    atualizar(form: any) {
        return this.http.put(this.UrlService + `ConfiguracoesUsuario`, JSON.stringify(form))
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    naoExibirAvisoFaturasEmAberto() {
        return this.http
          .post(this.UrlService + 'configuracoesUsuario/NaoExibirAvisoFaturasEmAberto', {})
          .pipe(map(super.extractData), catchError(super.serviceError));
    }

    naoExibirAvisoFaturasVencidas() {
        return this.http
          .post(this.UrlService + 'configuracoesUsuario/NaoExibirAvisoFaturasVencidas', {})
          .pipe(map(super.extractData), catchError(super.serviceError));
    }
}
