export interface MovimentacoesVendedoresViewModel {
  id?: number;
  tipo?: TiposMovimentacoesEnum;
  representanteId?: number;
  representante?: any;
  data?: string;
  valor?: number;
  observacao?: string;
  mostrarDetalhe?: boolean;
}

export enum TiposMovimentacoesEnum {
  E = 'E', // Provento
  S = 'S', // Desconto
  T = 'T', // Transferência
}

export interface TipoMovimentacoes {
  value: string;
  label: string;
}

export const OpcoesTiposMovimentacoesVendedores: TipoMovimentacoes[] = [
  { value: 'E', label: 'Provento' },
  { value: 'S', label: 'Desconto' },
];

export interface AcoesLoadingMovimentacoesVendedores {
  request: boolean;
  excluindo?: boolean;
  salvando?: boolean;
  exportando?: boolean;
}
