export class TipoSituacaoTributariaIPI{
    tipo: string;
    descricao: string;

    constructor(tipo?: string, descricao?: string){
        this.tipo = tipo;
        this.descricao = descricao;
    }

    Listar(): TipoSituacaoTributariaIPI[]{
        let array = Array();

        array.push(new TipoSituacaoTributariaIPI("00", "IPI 00 - Entrada com recuperação de IPI crédito"));
        array.push(new TipoSituacaoTributariaIPI("01", "IPI 01 - Entrada tributada com alíquota zero"));
        array.push(new TipoSituacaoTributariaIPI("02", "IPI 02 - Entrada isenta"));
        array.push(new TipoSituacaoTributariaIPI("03", "IPI 03 - Entrada não-tributada"));
        array.push(new TipoSituacaoTributariaIPI("04", "IPI 04 - Entrada imune"));
        array.push(new TipoSituacaoTributariaIPI("05", "IPI 05 - Entrada com suspensão"));
        array.push(new TipoSituacaoTributariaIPI("49", "IPI 49 - Outras entradas"));
        array.push(new TipoSituacaoTributariaIPI("50", "IPI 50 - Saída tributada"));
        array.push(new TipoSituacaoTributariaIPI("51", "IPI 51 - Saída tributada com alíquota zero"));
        array.push(new TipoSituacaoTributariaIPI("52", "IPI 52 - Saída isenta"));
        array.push(new TipoSituacaoTributariaIPI("53", "IPI 53 - Saída não-tributada"));
        array.push(new TipoSituacaoTributariaIPI("54", "IPI 54 - Saída imune"));
        array.push(new TipoSituacaoTributariaIPI("55", "IPI 55 - Saída com suspensão"));
        array.push(new TipoSituacaoTributariaIPI("99", "IPI 99 - Outras saídas"));

        return array;
    }

    ListarSaida(): TipoSituacaoTributariaIPI[] {
        return this.Listar().filter(cst => parseInt(cst.tipo) >= 50);
    }
 }