import { MessageService } from './../../../services/message.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalBasicComponent } from '../../../shared/modal-basic/modal-basic.component';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-carta-correcao',
  templateUrl: './carta-correcao.component.html',
  styleUrls: ['./carta-correcao.component.css']
})
export class CartaCorrecaoComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalCarta', { static: true }) modalCarta: ModalBasicComponent;
  @Input() cartas: any;
  cartaCorrecao: any;

  constructor(
    private messageService: MessageService,
  ) {
    super();
  }

  ngOnInit() {
    this.cartas = this.prop.cartas;
    this.visualizarCartaCorrecao(this.cartas);
  }

  show() {
    this.modalCarta.show();
  }

  close() {
    this.modalCarta.hide();
  }
  onClose() {
    this.closed.emit(this.id);
  }

  visualizarCartaCorrecao(row: any) {
    let cartas = [];
    if (row && row.cartaCorrecao && row.cartaCorrecao.length > 0) {
      if (row.cartaCorrecao.some(x => x.status)) {
        cartas = row.cartaCorrecao.slice()
          .filter(cc => cc.status === 'A')
          .sort((a, b) => b.id - a.id);
      } else {
        cartas = row.cartaCorrecao.slice()
          .sort((a, b) => b.id - a.id);
      }
    }
    if (cartas.length === 0) {
      this.messageService.error('Não foram encontradas cartas de correção aprovadas para essa nota.', 5000);
      return;
    }
    this.cartaCorrecao = cartas[0];
  }
}
