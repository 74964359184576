import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { saveAs } from 'file-saver';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap, tap } from 'rxjs/operators';
import { EmailBoxComponent } from '../../../../dim-componentes/email-box/email-box.component';
import { convertB64ToBlob, getErrorMessage, mergeArray } from '../../../../general/utils';
import { ClientesService } from '../../../../services/clientes.service';
import { DownloadExportacaoService } from '../../../../services/downloadExportacaoController.service';
import { MessageService } from '../../../../services/message.service';
import { UnidadesEmpresaService } from '../../../../services/unidades-empresa.service';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-exportar-cte',
  templateUrl: './exportar-cte.component.html',
  styleUrls: ['./exportar-cte.component.css']
})
export class ExportarCTEComponent extends ModalCadastroComponent implements OnInit {
  constructor(
    private message: MessageService,
    private clientesService: ClientesService,
    private downloadExportacaoService: DownloadExportacaoService,
    private unidadesEmpresaService: UnidadesEmpresaService
  ) {
    super();
  }

  @ViewChild('modalRelatorio', { static: true }) modalRelatorio;
  @ViewChild('emailBox', { static: true }) emailBox: EmailBoxComponent;
  impressaoForm: FormGroup;
  @Output() closed = new EventEmitter();
  loading = {
    clientes: false,
    exportacao: false,
  };
  clienteInput = new Subject<string>();
  clientes = [];
  form: FormGroup;

  ngOnInit() {
    this.form = new FormGroup({
      emissao1: new FormControl(null),
      emissao2: new FormControl(null),
      cliente: new FormControl(''),
      status: new FormControl(['A', 'C', 'I', 'D', 'R', '0', 'U']),
      destinatarios: new FormControl(''),
      gerarDacte: new FormControl(false),
    });

    this.unidadesEmpresaService.getUnidadeEmpresa()
      .subscribe(res => {
        if (res.email) {
          this.emailBox.insertEmailString(res.email);
        }

        if (res.contabilidade && res.contabilidade.email) {
          this.emailBox.insertEmailString(res.contabilidade.email);
        }
      });

    this.loading.clientes = true;
    this.clientesService.getClientesPaginado(1, '')
      .pipe(finalize(() => this.loading.clientes = false))
      .subscribe(data => this.clientes = data);

    this.onSearchCliente();
  }

  show() {
    this.modalRelatorio.show();
  }

  hide() {
    this.modalRelatorio.hide();
  }

  onSearchCliente() {
    this.clienteInput.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => this.loading.clientes = true),
      switchMap((term: string) => this.clientesService.getClientesPaginado(1, term || '').pipe(
        tap(() => this.loading.clientes = false)
      ))
    ).subscribe(data => {
      this.clientes = data;
    });
  }

  fetchMoreClientes(term) {
    this.loading.clientes = true;
    this.clientesService.getClientesPaginado(((this.clientes.length / 20) + 1), term || '')
      .pipe(finalize(() => this.loading.clientes = false))
      .subscribe(result => {
        this.clientes = mergeArray(this.clientes, result);
      });
  }

  exportar() {
    this.loading.exportacao = true;

    const idToast = this.message.addToast({
      title: 'Exportando...',
      msg: '',
      showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
    });

    this.downloadExportacaoService.exportarCte(this.form.value)
      .pipe(finalize(() => this.loading.exportacao = false))
      .subscribe(response => {
        if (response) {
          saveAs(convertB64ToBlob(response.fileContents, response.contentType), response.fileDownloadName);

          this.message.clear(idToast);
          this.message.addSuccess({
            title: 'Sucesso!',
            msg: 'Documentos exportados com êxito.',
            timeout: 6000
          });
        }
      }, err => {
        this.message.clear(idToast);
        this.message.addError({
          title: 'Erro!',
          msg: getErrorMessage(err),
          showClose: true, timeout: 5000, theme: 'bootstrap', closeOther: true
        });
      });
  }
}
