import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { markFormGroupTouched } from '../../../general/utils';
import { RelatorioAtendimentosService } from '../../../services/relatorio-atendimentos.service';
import { saveAs } from 'file-saver';
import Swal from 'sweetalert2';
import { UsuariosService } from './../../../services/usuarios.service';
import { RotasService } from './../../../services/rotas.service';
import { Subject } from 'rxjs';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-atendimentos',
  templateUrl: './atendimentos.component.html',
  styleUrls: ['./atendimentos.component.css']
})
export class AtendimentosComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalRelatorio", { static: true }) modalRelatorio;
  @Output() closed: EventEmitter<any> = new EventEmitter();
  impressaoForm: FormGroup;

  constructor(private relatorioAtendimentos: RelatorioAtendimentosService,
    private rotasService: RotasService,
    private usuariosService: UsuariosService) {
      super();
    }

  relatorioOptions = [
    { id: 0, name: 'Atendimentos às Contabilidades', arquivo: 'contabilidades' },
    { id: 1, name: 'Atendimentos aos Clientes', arquivo: 'clientes' },
    { id: 2, name: 'Atendimentos aos Indicadores', arquivo: 'indicadores' },
    { id: 3, name: 'Atendimentos aos Indicados', arquivo: 'indicados' }
  ];

  usuariosOptions: any[];
  rotasMap = [];
  loadingRotas:boolean=false;
  rotaInput = new Subject<string>();

  ngOnInit() {
    this.usuariosService.fetch().subscribe(res => {this.usuariosOptions = res;});
    this.rotasService.getRotas(1, '').subscribe(results => this.rotasMap = results);

    this.impressaoForm = new FormGroup({
      periodoDe: new FormControl(null),
      periodoAte: new FormControl(null),
      opcao: new FormControl(null),
      usuarioId: new FormControl(null),
      rotaID: new FormControl(null),
      atencao: new FormControl(false)
    })
  }

  show(): void {
    this.ngOnInit();
    this.modalRelatorio.show();
  }

  validaForm() {
    markFormGroupTouched(this.impressaoForm);
  }

  submitForm() {
    if (this.impressaoForm.valid) {
      this.modalRelatorio.hide();
      this.closed.emit();
      this.relatorioAtendimentos.gerarRelatorioSelecionado(this.impressaoForm.value)
        .subscribe(res => {
          saveAs(res, 'relatorio_atendimento_' + this.relatorioOptions[this.impressaoForm.value.opcao].arquivo +'.pdf');
        }, (error) => {
          Swal("Erro ao emitir o relatório: " + this.relatorioOptions[this.impressaoForm.value.opcao].name, error.errors[0], "error");
        })
    }
  }

  isEnabled(campo) {
    if (campo == 1 && (this.impressaoForm.value.opcao==0 || this.impressaoForm.value.opcao==2)) {
      return false;
    }
    if (campo==2 && this.impressaoForm.value.opcao==1){
      return false;
    }
    return true;
  }

  onClose(){
    this.closed.emit();
  }
}
