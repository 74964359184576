<app-modal-basic #modal [dialogClass]="'modal-lg'" (closed)="fechar()" [allowClickOutside]="false">
  <div class="app-modal-header">
    <h4 class="modal-title">
      <i class="icofont icofont-warning-alt"></i> Existem pedidos vinculadas a unidade de consumo selecionado
    </h4>
  </div>

  <div class="app-modal-body">
    <div class="col-sm-12">
      <div class="row justify-content-center">
        <div class="col">
          <p style="font-size: 1rem;" class="mb-2 text-center">
            Será impossível efetuar a exclusão da unidade de consumo <b>{{unidadeConsumo.descricao}}</b> sem remover os
            vínculos dos seguintes pedidos:
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="table-responsive">
            <table class="table table-striped table-bordered full-data-table">
              <thead>
                <tr>
                  <th style="width: 1%;">Número</th>
                  <th>Cliente</th>
                  <th style="width: 1%;">Emissão</th>
                  <th style="width: 1%;">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let pedido of pedidos">
                  <td>{{pedido.numeroPedido}}</td>
                  <td>{{pedido.nomeCliente}}</td>
                  <td>{{pedido.emissao}}</td>
                  <td>{{getPedidoStatus(pedido.status)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col">
          <button [disabled]="loading['delete']" style="text-transform: none;" class="btn btn-danger"
            (click)="removerVinculos()">
            <app-loading texto="Remover Vínculos e Excluir Unidade de Consumo" textoLoading="Excluindo"
              [loading]="loading['delete']" icone='ti ti-trash'></app-loading>
          </button>
          <button [disabled]="loading['delete']" class="btn btn-info" (click)="fechar()">
            <i class="ti ti-back-left"></i> Cancelar
          </button>
        </div>
      </div>
    </div>
  </div>
</app-modal-basic>