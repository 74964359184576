<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" textHeader="Notas correspondentes" modalContentId="modal-listar-notas-correspondentes" customClass="modal-lg">
  <div *ngIf="loading['consulta']">
    <div class="row flex justify-content-center mt-2">
      <i class="fa fa-circle-o-notch fa-spin fa-lg"></i>
    </div>
  </div>
  <div *ngIf="!loading['consulta']">
    <div class="row mb-3">
      <div class="col">
        <strong>Fornecedor:</strong> {{ vm.fornecedor.cnpj }} - {{ vm.fornecedor.nome }}
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-sm">
        <strong>Nota de Entrada:</strong> {{ vm.entrada.numero }}
      </div>
      <div class="col-sm">
        <strong>Data:</strong> {{ vm.entrada.emissao | date:'dd/MM/yyyy HH:mm' }}
      </div>
      <div class="col-sm">
        <strong>Quantidade:</strong> {{ formatDecimal(vm.entrada.quantidade) }}
      </div>
    </div>
  
    <div class="row">
      <div class="col">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Status</th>
              <th>Nota Fiscal</th>
              <th>Série</th>
              <th>Data</th>
              <th>Quantidade</th>
              <th class="text-center">Ações</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="!vm.notas || vm.notas.length === 0">
              <td colspan="7">Não foram encontradas notas correspondentes.</td>
            </tr>
            <tr *ngFor="let nota of vm.notas">
              <td>{{ nota.tipo }}</td>
              <td>{{ getStatusType(nota) }}</td>
              <td>{{ nota.numeroNotaFiscal }}</td>
              <td>{{ nota.serie }}</td>
              <td>{{ nota.data | date:'dd/MM/yyyy HH:mm' }}</td>
              <td>{{ formatDecimal(nota.quantidade) }}</td>
              <td class="text-center">
                <button (click)="imprimirNfe(nota.notaFiscalID, nota.numeroNotaFiscal)"
                  class="btn btn-info btn-mini waves-effect waves-light copyCursor" placement="left" ngbTooltip="Imprimir Danfe"
                  [disabled]="loading['impressao' + nota.notaFiscalID]">
                  <app-loading [loading]="loading['impressao' + nota.notaFiscalID]" texto="" textoLoading="" icone="fa fa-print">
                  </app-loading>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col">
      <button type="button" class="btn btn-info ripple" (click)="modalCadastro.hide()">
        <i class="ti ti-close"></i> Fechar
      </button>
    </div>
  </div>
</app-temp-modal-cadastro>
