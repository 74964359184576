import { Component, Input, Output, EventEmitter, ContentChild, TemplateRef } from '@angular/core';
import { ParamConsulta } from '../../general/models/paramConsulta';
import { getDeviceType } from '../../general/utils';
import { MessageService } from '../../services/message.service';
import { ContextMenuService } from '../../services/context-menu.service';
import { ContextMenu, ContextMenuGroup } from '../../model/context-menu/contextMenu';
import { UnidadesEmpresaParametrosService } from '../../services/unidades-empresa-parametros.service';
import { DimDatatableColumn, DimDatatableColumnStyle } from '@models';

@Component({
  selector: 'app-dim-datatable',
  templateUrl: './dim-datatable.component.html',
  styleUrls: ['./dim-datatable.component.css']
})
export class DimDatatableComponent {

  constructor(
    private message: MessageService,
    private contextMenuService: ContextMenuService,
    private parametrosService: UnidadesEmpresaParametrosService) { }

  @Input() paramConsulta: ParamConsulta = new ParamConsulta();

  @ContentChild('buttonsMaxTmpl', { static: false }) buttonsMaxTmpl: TemplateRef<any>;
  @ContentChild('buttonMinTmpl', { static: false }) buttonMinTmpl: TemplateRef<any>;
  @ContentChild('customTmpl', { static: false }) customTmpl: TemplateRef<any>;

  @Input() selecionados: any[];
  @Input() showDetailsFn: Function = null;
  @Input() columns: any[];
  @Input() data: any[];
  @Input() rowsOnPage: number;
  @Input() centeredText: boolean = false;
  @Input() totalItems: number;
  @Output() sorting: EventEmitter<ParamConsulta> = new EventEmitter<ParamConsulta>();
  @Output() clickButton: EventEmitter<ParamConsulta> = new EventEmitter<ParamConsulta>();
  @Output() pageChanged: EventEmitter<ParamConsulta> = new EventEmitter<ParamConsulta>();
  @Output() onRowClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() currentPage = 1;
  sort = {
    prop: '',
    dir: ''
  };
  isExpanded = false;
  itemExpanded: any;
  pressedRow = null;
  mouseDownTimeout = null;
  @Input() route: any;
  @Input() acoes = false;
  @Input() loading = false;
  @Input() showInfoMobile = true;
  @Input() selectable = false;
  @Input() serverPaginated = true;
  @Input() showPagination = true;
  @Input() showAcoesMobile = false;
  @Input() acoesCustomName: String = null;
  @Input() contextable = false;
  @Input() contextCondition = null;
  @Input() contextGroups: ContextMenuGroup[] = [];
  @Input() multiple: boolean = true;
  @Input() lyneStyle = (row) => '';
  @Input() containerStyle = {
    overflow: 'auto',
    paddingBottom: '15px',
  };
  @Output() onSelect: EventEmitter<any> = new EventEmitter<any>()
  @Output() onSelectAll: EventEmitter<any> = new EventEmitter<any>();

  @Output() get hasItemSelected(): boolean {
    return (this.selecionados.length > 0);
  }

  precisaoPreco = 0;
  isPrice = false;

  ngOnInit() {
    this.parametrosService.getParametrosGerais().subscribe(params => {
      if (params.casasDecimaisPreco) {
        this.precisaoPreco = params.casasDecimaisPreco;
      } else {
        this.precisaoPreco = 2;
      }
    });
    this.procuraDecimaisParametrizados();
  }

  ngAfterViewChecked() {
    const { order = { prop: '', dir: '' } } = this.paramConsulta || {};
    this.sort.prop = order.prop;
    this.sort.dir = order.dir;
  }

  procuraDecimaisParametrizados() {
    for (let i = 0; i <= this.columns.length; i++) {
      try {
        this.columns[i].decimaisParametrizado ? this.isPrice = true : this.isPrice = false;
      } catch (e) {
        return;
      }
    }
  }

  get pipeDecimal() {
    if (this.isPrice) {
      return `1.${this.precisaoPreco}-${this.precisaoPreco}`
    }
  }

  getLyneStyle(row) {
    return this.lyneStyle(row);
  }

  collapse(e) {
    this.isExpanded = false;
    this.itemExpanded = null;
  }

  expand(e, item) {
    this.isExpanded = true;
    this.itemExpanded = item;
  }

  sorter(column) {
    if (column.source !== false) {
      if (this.sort.prop === column.col) {
        this.sort.dir = this.sort.dir === 'asc' ? 'desc' : 'asc';
      } else {
        this.sort = {
          prop: column.col,
          dir: 'asc'
        };
      }
      this.currentPage = 1;
      this.paramConsulta.index = 1;
      this.paramConsulta.order = this.sort;
      this.sorting.emit(this.paramConsulta);

      if (!this.serverPaginated) {
        if (this.paramConsulta.order.dir === 'asc') {
          this.data = this.data.sort((a, b) => {
            const firstValue = this.getNestedObject(a, this.paramConsulta.order.prop.split('.'));
            const secondValue = this.getNestedObject(b, this.paramConsulta.order.prop.split('.'));

            if (firstValue > secondValue) {
              return 1;
            }

            if (firstValue < secondValue) {
              return -1;
            }

            return 0;
          });
        } else {
          if (this.paramConsulta.order.dir === 'desc') {
            this.data = this.data.reverse();
          }
        }
      }
    }
  }

  getData() {
    if (this.serverPaginated) {
      return this.data;
    }

    return this.data.slice(((this.currentPage - 1) * this.rowsOnPage), (this.currentPage * this.rowsOnPage));
  }

  changePage(event) {
    this.currentPage = event;
    this.paramConsulta.index = event;
    this.pageChanged.emit(this.paramConsulta);
  }

  buttonClick(param) {
    this.clickButton.emit(param);
  }

  retornaValor(row: any, column: any, toolTip = false) {
    if (column.format != null && column.format !== undefined) {
      if (!toolTip) {
        if (column.getDescription) {
          return column.format(column.getDescription(row, column));
        }
        return column.format(column.col ? row[column.col] : row);
      } else {
        return '';
      }
    }

    let text;

    if (column.getDescription) {
      text = column.getDescription(row, column);
    } else {
      const elements: string[] = column.col.split('.');
      text = this.getNestedObject(row[elements[0]], elements.slice(1)) + '';
    }

    if (text === 'null' || text === 'undefined') {
      text = '';
    }

    if (column.limit > 0 && text.length > column.limit && toolTip) {
      return text;
    } else if (toolTip) {
      return '';
    }

    if (column.limit > 0 && text.length > column.limit) {
      return text.substr(0, column.limit) + '...';
    }
    return text;
  }

  getTextOfMethod(row, column) {
    return column.getText(row[column.col]);
  }

  getNestedObject(nestedObj, pathArr) {
    return pathArr.reduce((obj, key) =>
      (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
  }

  showDetails(row): boolean {
    if (this.showDetailsFn != null) {
      return this.showDetailsFn(row);
    }
    return true;
  }

  selectAll(event) {
    this.data.forEach((value) => {
      if (event === true) {
        if (!this.selecionado(value.id)) {
          this.selecionados.push(value);
        }
      } else {
        this.selecionados.splice(this.selecionados.findIndex(x => x.id === value.id), 1);
      }
    });
    this.onSelectAll.emit();
  }

  selecao(event) {
    if (!this.selecionado(event.id)) {
      if (!this.multiple) this.selecionados.splice(this.selecionados.findIndex(x => x.id !== event.id), 1)
      this.selecionados.push(event);
    } else {
      this.selecionados.splice(this.selecionados.findIndex(x => x.id === event.id), 1);
    }
  }

  selecionado(id) {
    if (this.selecionados.filter(x => x.id === id).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  get allSelected() {
    return this.data && this.data.length > 0 && ([10, this.data.length].includes(this.selecionados.filter(item => this.data.some(sel => sel.id === item.id)).length));
  }

  getClipboardValue(row: any, column: any) {
    if (this.showClipboardButton(column)) {
      return row[column.col];
    }

    return null;
  }

  showClipboardButton(column: any) {
    return column.clipboard;
  }

  successClipboard(event) {
    this.message.success('Texto copiado: ' + event.content);
  }

  rowClick(i) {
    this.onRowClick.emit(i);
  }

  openContext(e: MouseEvent, row) {
    if ((this.contextCondition !== null && this.contextCondition(row)) || this.contextCondition == null) {
      if (this.contextable && this.contextGroups && this.contextGroups.length > 0) {
        const context = new ContextMenu(e, [...this.contextGroups], row);
        this.contextMenuService.open(context, () => {
          this.pressedRow = null;
        });

        this.pressedRow = row;
      }
    }
  }

  mousePressHandle(row, e: MouseEvent) {
    this.mouseDownTimeout = setTimeout(() => {
      this.openContext(e, row);
    }, 300);
  }

  mouseUnpressHandle() {
    if (this.mouseDownTimeout) {
      clearTimeout(this.mouseDownTimeout);
    }
  }

  onSelectFn(param) {
    this.onSelect.emit(param);
  }

  colunaStyle(row: any, column: DimDatatableColumn = {}): DimDatatableColumnStyle {
    const { style: colunaStyle = undefined, tipo = undefined } = column;
    const style = { color: 'var(--base-text-color)' };
    if (!colunaStyle || !column) {
      return style;
    }

    if (tipo === 'S' || ['mobile', 'tablet'].includes(getDeviceType())) {
      if (column.color != null) {
        style.color = column.color;
        return style;
      }
    } else if (colunaStyle) {
      if (typeof colunaStyle === 'function') {
        const response = colunaStyle(row);
        if (typeof response === 'object' && !Array.isArray(colunaStyle)) {
          return response;
        }
        return style;
      } else if (typeof colunaStyle === 'object' && !Array.isArray(colunaStyle)) {
        return colunaStyle;
      } else if (typeof colunaStyle === 'string') {
        try {
          return JSON.parse(colunaStyle)
        } catch (_) {
          return style;
        }
      }
    }
    return style;
  }

  colunaTooltip(row: any, column: DimDatatableColumn = {}): string {
    const { tooltip = undefined } = column;
    if (!tooltip) {
      return;
    }

    if (typeof tooltip === 'function') {
      return tooltip(row);
    } else {
      return tooltip;
    }
  }
}
