import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-enviar-email',
  templateUrl: './enviar-email.component.html',
  styleUrls: ['./enviar-email.component.css']
})
export class EnviarEmailComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modal', { static: true }) modalConsultar;
  @ViewChild('emailBox', { static: true }) emailBox;
  form: FormGroup = new FormGroup({
    emails: new FormControl()
  });
  constructor() {
    super();
  }

  show(){
    this.modalConsultar.show();
  }

  onClose() {
    this.closed.emit();
  }

  enviar(){
    const ok = this.emailBox.finalizaDigitacao();
    if (ok) {
      this.emailBox.atualizarEmails();
      this.closed.emit(this.form.controls['emails'].value);
    }
  }

  cancelar(){
    this.closed.emit();
  }

  ngOnInit() {
    if(this.prop !== null){
      this.emailBox.setDefaultEmail(this.prop);
    }
  }
}
