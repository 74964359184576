<app-temp-modal-cadastro #modalConsultar modalContentId="modal-cadastro-itens-manifesto" (closed)="destroyComponent()" [textHeader]="'Cadastrar itens/Adicionar no estoque'" customClass="modal-lg">
  <div class="app-modal-body">
    <div class="row">
      <div class="form-group col-sm-6">
        <label style="font-weight: bold; position: relative;">Almoxarifado
          <i class="ti-help-alt helpIcon"
            placement="bottom"
            ngbTooltip="Utilizado para configurar os itens com o Almoxarifado selecionado."
            style="position: absolute; bottom: 2px; right: -22px;"
          ></i>
        </label>
        <ng-select appendTo="#modal-cadastro-itens-manifesto" [loading]="loading['almoxarifados']"
          placeholder="Selecione" [items]="almoxarifados" bindLabel="nome" bindValue="id"
          [disableControl]="loading['request'] || loading['disable']" [(ngModel)]="almoxarifadoPadraoId">
          <ng-template ng-option-tmp let-item="item">
            <app-temp-option-item [name]="item.nome"
              (onClick)="abrirCadastroAlmoxarifado(item.id, null)">
            </app-temp-option-item>
          </ng-template>

          <ng-template ng-notfound-tmp let-items="items" let-searchTerm="searchTerm">
            <app-temp-not-found-item [searchTerm]="searchTerm"
              (onClick)="abrirCadastroAlmoxarifado(null, searchTerm)">
            </app-temp-not-found-item>
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div class="text-center" *ngIf="loadingData"><i class="fa fa-circle-o-notch fa-spin fa-2x"></i></div>
    <ng-container>
      <app-dim-datatable *ngIf="itensNaoMovimentados && itensNaoMovimentados.length > 0" [data]="itensNaoMovimentados"  [columns]="columns"
        [showPagination]="false" [centeredText]='true' [loading]="loading"
        [selectable]="true" [selecionados]="selecionados"
        >
        <ng-template #customTmpl let-row="row" let-column="column" let-index="index">
          <ng-template [ngIf]="column == 'itemFornecedor.nome'">
            <i class="ti ti-info-alt"
              [ngClass]="'itens-manifesto__ti-info-alt--color-' + statusItemCor(row)"
              placement="right"
              [ngbTooltip]="statusItemNgbTooltip(row)"
            ></i>
            {{ row.itemFornecedor.nome }}
          </ng-template>
          <ng-template [ngIf]="column == 'itemFornecedor.item.unidade'">
            <ng-select appendTo="#modal-cadastro-itens-manifesto"
              [loading]="!unidadesMedida || !unidadesMedida.length" placeholder="Selecione"
              [items]="unidadesMedida" bindLabel="unidade" bindValue="id" [(ngModel)]="row.unidadeMedidaID" (change)="handleUnidadeMedidaChanged($event, row)">
            </ng-select>
          </ng-template>
          <ng-template [ngIf]="column == 'quantidade'">
            <input type="number" class="editable-input" [(ngModel)]="row.quantidade"/>
          </ng-template>
          <ng-template [ngIf]="column == 'precoVenda'">
            <input class="editable-input" currencyMask inputmode="numeric" [(ngModel)]="row.precoVenda"/>
          </ng-template>
        </ng-template>
      </app-dim-datatable>
    </ng-container>
    <div *ngIf="itensNaoMovimentados && itensNaoMovimentados.length === 0" class="text-center mt-1 mb-4">Todos os itens já existem como produtos de venda e o estoque foi movimentado</div>
    <div *ngIf="statusSelecionados() !== 'ok' && estoque.selecionado == false">
      <span style="font-size: 13px; padding-left: 5px;" [innerHTML]="statusSelecionadosError()"></span>
    </div>
    <div class="d-flex flex-flow mt-1" [ngStyle]="{'margin-top': (statusSelecionados() !== 'ok' && estoque.selecionado == false) ? '18px' : '0'}">
      <button type="button" class="btn btn-primary d-flex flex-flow itens-manifesto__footer-btn--align-icon"
        [disabled]="loading || possuiItensSelecionados" (click)="importar()"
        ><i class="ti ti-save" aria-hidden="true"></i> Salvar
      </button>
      <button type="button" class="btn btn-danger d-flex flex-flow itens-manifesto__footer-btn--align-icon"
        (click)="modalConsultar.hide()"
        ><i class="ti ti-close" aria-hidden="true"></i> Cancelar
      </button>
      <div class="form-control checkbox-fade fade-in-default text-truncate" style="border: unset; padding-left: 0px">
        <label style="margin-bottom: 0px; padding: 2px;" ngbTooltip="Os itens importados movimentarão o estoque">
          <input type="checkbox" [value]="estoque.id" [checked]="estoque.selecionado" (change)="handleCheckboxClick()"/>
          <span class="cr">
            <i class="cr-icon icofont icofont-ui-check txt-default"></i>
          </span>
          <span>Movimentar estoque</span>
        </label>
      </div>
    </div>
</div>
</app-temp-modal-cadastro>

<app-abrir-modal #abrirModal></app-abrir-modal>