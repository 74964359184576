import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';

import { MessageService } from '@services';
import { TipoComissaoServiceV2 } from '@servicesv2';
import { ComboboxPadrao } from 'models/combobox-padrao/combobox-padrao';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TipoComissaoViewModel } from 'models/tipo-comissao/TipoComissaoViewModel';

@Component({
  selector: 'app-combobox-tipo-comissao',
  templateUrl: './combobox-tipo-comissao.component.html',
  styleUrls: ['./combobox-tipo-comissao.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ComboboxTipoComissaoComponent),
      multi: true
    }
  ]
})
export class ComboboxTipoComissaoComponent extends ComboboxPadrao<TipoComissaoViewModel> implements ControlValueAccessor {
  override bindLabel = 'descricao';
  override label = 'Tipo de comissão';
  @Input() override required = false;
  @Input() override requiredText = 'O tipo de comissão é obrigatório';

  @Input() loading = false;
  @Input() disabled = false;
  @Input() notFoundPlusIcon = true;
  @Input() pencilIcon = true;
  @Output() changed = new EventEmitter();

  constructor(
    service: TipoComissaoServiceV2,
    message: MessageService,
  ) {
    super('combobox_tipo_comissao', message, service);
  }

  writeValue(value: number): void {
    this.selected.setValue(value);
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  handleOnChanged(value: number) {
    this.selected.setValue(value);
    this.changed.emit(value);
    this.onChange(value);
  }

  handleOnAdded(value: number) {
    this.selected.setValue(value);
    this.changed.emit(value);
    this.onChange(value);
  }

  handleOnEdited(value: number) {
    this.selected.setValue(value);
    this.changed.emit(value);
    this.onChange(value);
  }
}
