import { Component, OnInit, ViewChild } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { OcupacaoAgendaService } from '../../../services/ocupacaoAgenda.service';
import { setFormValue } from '../../../general/utils';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { TempModalCadastroComponent } from '../../../shared/templates/temp-modal-cadastro/temp-modal-cadastro.component';
import { finalize } from 'rxjs/operators';
@Component({
  selector: 'app-replica-horarios-agenda',
  templateUrl: './replica-horarios-agenda.component.html',
  styleUrls: ['./replica-horarios-agenda.component.css']
})
export class ReplicaHorariosAgendaComponent extends ModalCadastroComponent implements OnInit {

  constructor(
    private ocupacaoAgendaService: OcupacaoAgendaService
  ) { super(); }

  @ViewChild('modalReplicacao', { static: false }) modalReplicacao: TempModalCadastroComponent;
  form: FormGroup;
  ocupacoes = [];
  loading = [];
  availableDate = false;

  colunas = [
    { label: 'Datas', col: 'data', hide: false, date: true }
  ]

  ngOnInit() {
    this.loading['geral'] = false;

    this.form = new FormGroup({
      itemId: new FormControl(null, Validators.required),
      data: new FormControl(null, Validators.required),
      clienteId: new FormControl(null,  Validators.required),
      periodo: new FormControl(null,  Validators.required),
      novaData: new FormControl(null, Validators.required)
    });

    setFormValue(this.form, this.prop);
    this.buscaDadosCliente();
  }

  show() {
    this.modalReplicacao.show();
  }

  remover(id, index) {
    this.loading['geral'] = true;
    this.ocupacaoAgendaService.delete(id).subscribe(() => {
      this.ocupacoes.splice(index, 1);
      this.loading['geral'] = false;
    })
  }

  buscaDadosCliente() {
    this.loading['geral'] = true;
    this.ocupacaoAgendaService.getOcupacoesByClientAndPeriod(this.form.value)
      .subscribe(res => {
        if (res) {
          this.ocupacoes = res;
          this.loading['geral'] = false;
        }
      });
  }

  hourChanged() {
    this.novaDataChanged();
    this.buscaDadosCliente();
  }

  adicionar() {
    if (this.form.valid) {
      this.availableDate = false;
      this.loading['newDate'] = true;
      this.loading['global'] = true;

      this.ocupacaoAgendaService.salvar(this.form.value).subscribe(res => {
        this.buscaDadosCliente();
      }, error => {}, () => {
        this.loading['newDate'] = false;
        this.loading['global'] = false;
      });
    }
  }

  novaDataChanged() {
    const date = this.form.get('novaData').value;
    this.availableDate = false;

    if (date) {
      this.loading['date'] = true;

      if (date) {
        this.ocupacaoAgendaService.checkDisponibility(this.form.value)
          .pipe(finalize(() => this.loading['date'] = false))
          .subscribe(res => {
            if (res.value) {
              if (res.value.available) {
                this.availableDate = true;
              } else {
                this.availableDate = false;
              }
            }
          });
      }
    }
  }
}
