<app-modal-basic #modal (closed)="onClose()" [borderless]="true" [hideFooter]="true" [hideHeader]="true">
  <div class="app-modal-body">
    <div class="inside-container">
      <h3 [innerHTML]="title"></h3>
      <p [innerHTML]="message"></p>
      <ng-container *ngIf="[4449, 4445, 4447, 4451, 4453].includes(cidade)">
        <button class="btn btn-primary" (click)="openWhats()"><i class="fa fa-whatsapp"></i> Solicitar contato</button>
        <span>Ou ligue: (47) 3371-8920</span>
      </ng-container>
      <div class="logo-footer">
        <img src="../../../../assets/images/Dim-branquera.png" width="50px">
      </div>
    </div>
  </div>
</app-modal-basic>