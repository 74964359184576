import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { ParametrosSistema } from '../model/ParametrosSistema';
import { Observable } from 'rxjs';

@Injectable()
export class ParametrosSistemaService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get(): Observable<ParametrosSistema> {
    return this.http
      .get(this.UrlService + 'parametrosSistema')
      .pipe(map((res: any) => res.data), catchError(super.serviceError));
  }
}
