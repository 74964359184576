import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from '../../../../services/message.service';
import { markFormGroupTouched } from '../../../../general/utils';
import { NotasFiscaisService } from '../../../../services/notas-fiscais.service';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { finalize } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-emissoes-por-cfop',
  templateUrl: './emissoes-por-cfop.component.html',
  styleUrls: ['./emissoes-por-cfop.component.css']
})
export class EmissoesPorCfopComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modalRelatorio', { static: true }) modalRelatorio;
  impressaoForm: FormGroup;

  constructor(
    private notasFiscaisService: NotasFiscaisService,
    private messageService: MessageService) { super(); }

  ngOnInit() {
    this.impressaoForm = new FormGroup({
      dataInicial: new FormControl(null, [ Validators.required ]),
      dataFinal: new FormControl(null, [ Validators.required ]),
      clienteID: new FormControl(null),
      codigoFiscalID: new FormControl(null),
      formato: new FormControl('pdf'),
    });
  }

  destroyComponent() {
    this.closed.emit();
  }

  show(): void {
    this.modalRelatorio.show();
  }

  validaForm() {
    markFormGroupTouched(this.impressaoForm);
  }

  submitForm() {
    if (this.impressaoForm.valid) {
      const idToast = this.messageService.addToast({
        title: 'Emitindo Relatório',
        msg: 'Emissões por CFOP',
        showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
      });
      this.loading['consulta'] = true;
      this.modalRelatorio.hide();

      this.notasFiscaisService.imprimirEmissoesPorCfop(this.impressaoForm.value)
        .pipe(finalize(() => {
          this.loading['consulta'] = false;
          this.messageService.clear(idToast);
        }))
        .subscribe(res => {
          saveAs(res, `relatorio_emissoes_por_cfop.${this.impressaoForm.value.formato}`);
          this.destroyComponent();
        }, (error) => {
          this.messageService.addError({
            title: 'Falha ao gerar relatório',
            msg: error.errors[0],
            showClose: true, timeout: 20000, theme: 'bootstrap', closeOther: true
          });
          this.destroyComponent();
        });
    }
  }
}
