import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class TipoItensService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get() {
    return this.http.get(this.UrlService + 'TipoItens')
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  getMultiplosPorCodigo(list: string[]) {
    return this.http.post(this.UrlService + 'TipoItens/GetMultiplosCodigos', list)
      .pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  getPaginado(pagina: number, nome: string) {
    return this.http
      .get(this.UrlService + `TipoItens/paginado?pageIndex=` + pagina + '&search=' + encodeURIComponent(nome))
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

  getById(id: number) {
    if (!id) {
      return undefined;
    }
    return this.http
      .get(this.UrlService + 'TipoItens/' + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }

}
