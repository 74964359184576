import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { CondicoesPagamentoService } from '../../../services/condicoes-pagamento.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TempModalCadastroComponent } from '../../../shared/templates/temp-modal-cadastro/temp-modal-cadastro.component';
import { setFieldFormValue, setFormValue } from '../../../general/utils';
import { SeriesAlternativasService } from '../../../services/series-alternativas.service';
import { finalize } from 'rxjs/operators';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-condicao-pagamento',
  templateUrl: './serie-alternativa.component.html',
  styleUrls: ['./serie-alternativa.component.css']
})
export class SerieAlternativaComponent extends ModalCadastroComponent implements OnInit {

  constructor(
    private seriesAlterantivasService: SeriesAlternativasService,
    private messageService: MessageService
  ) {
    super();
  }

  @ViewChild("modalCadastro", { static: false }) modalCadastro: TempModalCadastroComponent;

  cadastroForm = new FormGroup({
    id: new FormControl(0),
    serieAlternativa: new FormControl(null, Validators.required),
    numeroUltimaNF: new FormControl(null, Validators.required)
  });

  ngOnInit() {
  }

  editar(id: number) {
    this.seriesAlterantivasService.getByID(id).subscribe(res => {
      setFormValue(this.cadastroForm, res);
      this.modalCadastro.show();
    });
  }

  cadastrar(searchParam) {
    this.cadastroForm.get('id').setValue(0);
    this.cadastroForm.get('serieAlternativa').setValue(searchParam);
    this.modalCadastro.show();
  }

  submitForm() {
    this.validaForm();

    if (this.cadastroForm.valid) {
      this.loading['registro'] = true;
      this.seriesAlterantivasService
        .salvar(this.cadastroForm.value)
        .pipe(finalize(() => this.loading['registro'] = false))
        .subscribe(res => {
          this.messageService.success('Registro salvo com sucesso', 5000);
          this.closed.emit(res.generatedId)
        }, err => {
          this.messageService.error('Ocorreu um erro ao atualizar o registro', 5000);
        })
    }
  }
}
