export class TipoFrete {
    id: string;
    descricao: string;

    constructor() { }

    ListarTiposFrete() {
        return [
            { id: '0', descricao: 'Contratação por conta do remetente' },
            { id: '1', descricao: 'Contratação por conta do destinatário' },
            { id: '2', descricao: 'Contratação por conta de terceiros' },
            { id: '3', descricao: 'Transporte próprio por conta do remetente' },
            { id: '4', descricao: 'Transporte próprio por conta do destinatário' },
            { id: '9', descricao: 'Sem Frete' }
        ];
    }

    ListarTiposFreteNFCe() {
        return [
            { id: '1', descricao: 'Contratação por conta do destinatário' },
            { id: '4', descricao: 'Transporte próprio por conta do destinatário' },
            { id: '9', descricao: 'Sem Frete' }
        ];
    }

    retornaDescricaoTipoFrete(tipoFrete): string {
        switch (tipoFrete) {
            case '0':
                return 'Contratação por conta do remetente';
            case '1':
                return 'Contratação por conta do destinatário';
            case '2':
                return 'Contratação por conta de terceiros';
            case '3':
                return 'Transporte próprio por conta do remetente';
            case '4':
                return 'Transporte próprio por conta do destinatário';
            case '9':
                return 'Sem Frete';
            default:
                return 'Não Encontrado';
        }
    }
}
