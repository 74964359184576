import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { MessageService } from './message.service';


@Injectable()
export class ClientesEnderecosService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getEnderecosByClienteTipo(cliente: number, tipo: number) {
        return this.http
            .get(this.UrlService + `clientesEnderecos/GetEnderecosByClienteTipo/${cliente}/${tipo}`)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    salvarEndereco(form: any) {
        return this.http
            .post(this.UrlService + 'clientesEnderecos/salvarEndereco', form)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }
}
