import { Component, OnInit, ViewChild, } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { GrupoDespesasService } from '../../../services/grupo-despesas.service';
import { setFieldFormValue, setFormValue } from '../../../general/utils';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-grupo-despesa',
  templateUrl: './grupo-despesa.component.html',
  styleUrls: ['./grupo-despesa.component.css']
})
export class GrupoDespesaComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalCadastro", { static: true }) modalCadastro;

  constructor(private grupoDespesaService: GrupoDespesasService) {
    super();
  }
  cadastroForm = new FormGroup({
    id: new FormControl(null),
    descricao: new FormControl(null, [Validators.required]),
    sequenciaVisualizacao: new FormControl(0, [Validators.required])
  })

  ngOnInit() {
  }

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    setFieldFormValue(this.cadastroForm, 'descricao', searchParam);
    this.modalCadastro.show();
  }

  carregaDados() {
    if (this.id) {
      this.alteraLoading(true);
      this.grupoDespesaService.getGrupoDespesaById(this.id)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe((res) => {
          setFormValue(this.cadastroForm, res);
        })
    }
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      this.alteraLoading(true);
      this.grupoDespesaService.post(this.cadastroForm.value)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe(res => {
          this.id = res.generatedId || null;
          this.modalCadastro.hide();
        }, (error) => {
          this.errors = error.msg.errors;
        })
    }
  }
}
