<app-modal-basic #modalRelatorio (closed)="destroyComponent()" [modalContentId]="'modal-mov-por-cfop'">
  <div class="app-modal-header">
    <h4 class="modal-title">Emissões por CFOP</h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="impressaoForm" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Período</label>
          <div class="input-group">
            <app-data [calendarPosition]="'left'" formControlName="dataInicial"></app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="dataFinal"></app-data>
          </div>
          <div class='row'>
            <div class='col-sm-6'>
              <show-errors [control]="impressaoForm.controls.dataInicial"></show-errors>
            </div>
            <div class='col-sm-6'>
              <show-errors [control]="impressaoForm.controls.dataFinal"></show-errors>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-12" appendTo="#modal-mov-por-cfop" rem5 combobox [type]="'cliente'" [(loading)]="loading" [(form)]="impressaoForm"
          formControlName="clienteID">
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-12" appendTo="#modal-mov-por-cfop" rem5 combobox [type]="'codigos-fiscais'" [(loading)]="loading" [(form)]="impressaoForm"
          formControlName="codigoFiscalID" label="CFOP">
          <ng-template #descTmpl let-row="row">
            <span>{{row.codigo}} - {{row.descricao}}</span>
          </ng-template>
        </div>
      </div>

      <div class="row form-group col-sm-12">
        <div>
          <span>Formato</span>
        </div>

        <div class="form-radio form-control" style="border: unset; padding-left: 0px;">
          <div class="radio radio-inline">
            <label style="margin-bottom: 0px;">
              <input type="radio" formControlName="formato" value='pdf' [disableControl]="loading['disable'] || loading['request']">
              <i class="helper"></i><span> PDF</span>
            </label>
          </div>
          <div class="radio radio-inline">
            <label style="margin-bottom: 0px;">
              <input type="radio" formControlName="formato" value='xlsx' [disableControl]="loading['disable'] || loading['request']">
              <i class="helper"></i><span> XLSX</span>
            </label>
          </div>
        </div>
      </div>

      <button type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
        <i class="icofont icofont-print"> </i> Imprimir
      </button>
      <button type="button" class="btn btn-danger ripple" (click)="modalRelatorio.hide()"><i class="ti ti-close"></i>
        Cancelar
      </button>
    </form>
  </div>
  <div class="app-modal-footer">
  </div>

</app-modal-basic>