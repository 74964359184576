import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-politica-de-privacidade',
  templateUrl: './politica-de-privacidade.component.html',
  styleUrls: ['./politica-de-privacidade.component.css'],
})
export class PoliticaDePrivacidadeComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modal', { static: true }) modalPoliticas;

  constructor() {
    super();
  }

  ngOnInit() {
    document.getElementsByClassName('modal-dialog')[0].classList.add('modal-dialog-pdf')
  }

  show() {
    this.modalPoliticas.show();
  }

  onClose() {
    this.closed.emit();
  }
}
