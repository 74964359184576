import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from "./base.service";
import { map, catchError, finalize } from 'rxjs/operators';
import { MessageService } from './message.service';


@Injectable()
export class ContabilidadesService extends BaseService {

    constructor(private http: HttpClient, private message: MessageService) { super(); }

    getContabilidades(pagina: number, nome: string) {
        return this.http
            .get(this.UrlService + "contabilidades?pageIndex=" + pagina + "&search=" + encodeURIComponent(nome))
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    getContabilidadesById(id: number) {
        if (!id) {
            return of(undefined);
        }
        return this.http
            .get(this.UrlService + "contabilidades/" + id)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    };

    getConsultaContabilidades(consulta: any) {
        return this.http
            .post(this.UrlService + "contabilidades/consulta/", consulta)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    };

    getUnidadeEmpresaByConsulta(consulta: any) {
        return this.http
            .post(this.UrlService + "unidadesEmpresa/consulta/", consulta)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    };

    update(data) {
        return this.http.put(this.UrlService + `contabilidades/${data.id}`, data)
            .pipe(map(super.extractData)
                , catchError(super.serviceError))
    }

    create(data) {
        return this.http.post(this.UrlService + `contabilidades`, data)
            .pipe(map(super.extractData)
                , catchError(super.serviceError))
    }

    delete(id: number) {
        return this.http.delete(this.UrlService + `contabilidades/${id}`)
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    getIndicadorPorContabilidade(id: number) {
        return this.http
            .get(this.UrlService + "contabilidades/Indicadores/" + id)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    };

    getIndicadoresDisponiveis(id: number) {
        return this.http
            .get(this.UrlService + "contabilidades/IndicadoresDisponiveis/" + id)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    };

    imprimirEtiquetaContabilidade(id: number) {
        return this.http
            .get(this.UrlService + "contabilidades/Etiqueta/" + id)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    };

    imprimirConclusaoAdesao(form) {
        let idToast = this.message.addToast({
            title: 'Emitindo Relatório',
            msg: 'Premiações',
            showClose: false, timeout: 0, theme: 'bootstrap', closeOther: false
        })
        return this.http
            .post(this.UrlService + 'contabilidades/imprimirConclusaoAdesao', JSON.stringify(form), { responseType: 'blob' }).pipe(finalize(() => this.message.clear(idToast))
                , catchError(super.parseErrorBlob))
    }
}