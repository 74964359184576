
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { API_URL } from '../general/constants';
import { ParamConsulta } from '../general/models/paramConsulta';
import { toCamel } from '../general/utils';

export abstract class BaseService {
    public Token = '';

    protected UrlService: string = API_URL;

    protected serviceError(error: Response | any) {
        let err: any;

        if (error instanceof HttpErrorResponse) {
            if (error.status === 0) { // server fora
                err = {
                    errors: ['Falha ao conectar no servidor'],
                    success: false
                };
            } else if (error.status === 403) {
                err = {
                    errors: ['Acesso negado!'],
                    success: false
                };
            } else {
                err = error.error;
            }
        } else {
            err = {
                success: false,
                errors: [error.message ? error.message : error.toString()]
            };
        }

        error.msg = err;
        if (!error.msg) { error.msg = { success: false, errors: [''] }; }
        return throwError(error);
    }

    protected getOrderAndIndexOData(form) {
        const paramConsulta = form.paramConsulta || ParamConsulta;
        const index = (paramConsulta.index - 1 || 0) * 10;
        const order = paramConsulta.order.prop ? `$orderby=${paramConsulta.order.prop.replace('.', '/')} ${paramConsulta.order.dir}&`
            : '$orderBy=id desc&';

        return `&${order}$skip=${index}&`;
    }

    protected extractData(response: any) {
        return response.data || response || {};
    }

    protected extractODataResult(response: any) {
        response.value = toCamel(response.value);
        return response;
    }

    protected extractODataSingleResult(response: any) {
        return toCamel(response);
    }

    protected parseErrorBlob(err: HttpErrorResponse): Observable<any> {
        const reader: FileReader = new FileReader();

        const obs = Observable.create((observer: any) => {
            reader.onloadend = (e) => {
                observer.error(JSON.parse(reader.result.toString()));
                observer.complete();
            };
        });
        reader.readAsText(err.error);
        return obs;
    }
}

export interface DimHttpServerResponse {
  success: boolean;
  data: unknown;
  token?: string;
  messages?: string[];
  errors?: string[];
}
