export class TipoServicoISSQN {
    tipo: number;
    descricao: string

    constructor(tipo?: number, descricao?: string) {
        this.tipo = tipo;
        this.descricao = descricao;
    }

    Listar() : TipoServicoISSQN[] {
        return [
            new TipoServicoISSQN(101, "ANALISE E DESENVOLVIMENTO DE SISTEMAS"),
            new TipoServicoISSQN(102, "PROGRAMACAO"),
            new TipoServicoISSQN(103, "PROCESSAMENTO, ARMAZENAMENTO OU HOSPEDAGEM DE DADOS, TEXTOS, IMAGENS, VIDEOS, PAGINAS ELETRONICAS, APLICATIVOS E SISTEMAS DE INFORMACAO, ENTRE OUTROS FORMATOS, E CONGENERES"),
            new TipoServicoISSQN(104, "ELABORACAO DE PROGRAMAS DE COMPUTADORES, INCLUSIVE DE JOGOS ELETRONICOS, INDEPENDENTEMENTE DA ARQUITETURA CONSTRUTIVA DA MAQUINA EM QUE O PROGRAMA SERA EXECUTADO, INCLUINDO TABLETS, SMARTPHONES E CONGENERES"),
            new TipoServicoISSQN(105, "LICENCIAMENTO OU CESSAO DE DIREITO DE USO DE PROGRAMAS DE COMPUTAÇAO "),
            new TipoServicoISSQN(106, "ASSESSORIA E CONSULTORIA EM INFORMATICA "),
            new TipoServicoISSQN(107, "SUPORTE TECNICO EM INFORMATICA, INCLUSIVE INSTALAÇAO, CONFIGURAÇAO E MANUTENÇAO DE PROGRAMAS DE COMPUTAÇAO E BANCO DE DADOS"),
            new TipoServicoISSQN(108, "PLANEJAMENTO, CONFECÇAO, MANUTENÇAO E ATUALIZAÇAO DE PAGINAS ELETRONICAS"),
            new TipoServicoISSQN(109, "DISPONIBILIZACAO, SEM CESSAO DEFINITIVA, DE CONTEUDOS DE AUDIO, VIDEO, IMAGEM E TEXTO POR MEIO DA INTERNET, RESPEITADA A IMUNIDADE DE LIVROS, JORNAIS E PERIODICOS (EXCETO A DISTRIBUICAO DE CONTEUDOS PELAS PRESTADORAS DE SERVICO DE ACESSO CONDICIONADO, DE QUE TRATA A LEI NO 12.485, DE 12 DE SETEMBRO DE 2011, SUJEITA AO ICMS)"),
            new TipoServicoISSQN(201, "SERVIÇOS DE PESQUISAS E DESENVOLVIMENTO DE QUALQUER NATUREZA"),
            new TipoServicoISSQN(301, "VETADO"),
            new TipoServicoISSQN(302, "CESSAO DE DIREITO DE USO DE MARCAS E DE SINAIS DE PROPAGANDA"),
            new TipoServicoISSQN(303, "EXPLORAÇAO DE SALOES DE FESTAS, CENTRO DE CONVENÇOES, ESCRITORIOS VIRTUAIS, STANDS, QUADRAS ESPORTIVAS, ESTADIOS, GINASIOS, AUDITORIOS, CASAS DE ESPETACULOS, PARQUE DE DIVERSOES, CANCHAS E CONGENERES, P/REALIZAÇAO DE EVENTOS OU NEGOCIOS DE QUALQUER NATUREZA."),
            new TipoServicoISSQN(304, "LOCAÇAO, SUBLOCAÇAO, ARRENDAMENTO, DIREITO DE PASSAGEM OU PERMISSAO DE USO, COMPARTILHADO OU NAO, DE FERROVIA , RODOVIA, POSTES, CABOS, DUTOS E CONDUTORES DE QUALQUER NATUREZA "),
            new TipoServicoISSQN(305, "CESSAO DE ANDAIMES, PALCOS, COBERTURAS E OUTRAS ESTRUTURAS DE USO TEMPORARIO "),
            new TipoServicoISSQN(401, "MEDICINA E BIOMEDICINA"),
            new TipoServicoISSQN(402, "ANALISES CLINICAS, PATOLOGIA, ELETRICIDADE MEDICA, RADIOTERAPIA, QUIMIOTERAPIA, ULTRA-SONOGRAFIA, RESSONANCIA MAGNETICA, RADIOLOGIA, TOMOGRAFIA E CONGENERES"),
            new TipoServicoISSQN(403, "HOSPITAIS, CLINICAS, LABORATORIOS, SANATORIOS, MANICOMIOS , CASAS DE SAUDE, PRONTOS-SOCORROS, AMBULATORIOS E CONGENERES"),
            new TipoServicoISSQN(404, "INSTRUMENTAÇAO CIRURGICA"),
            new TipoServicoISSQN(405, "ACUPUNTURA"),
            new TipoServicoISSQN(406, "ENFERMAGEM, INCLUSIVE SERVIÇOS AUXILIARES"),
            new TipoServicoISSQN(407, "SERVIÇOS FARMACEUTICOS"),
            new TipoServicoISSQN(408, "TERAPIA OCUPACIONAL, FISIOTERAPIA E FONAUDIOLOGIA "),
            new TipoServicoISSQN(409, "TERAPIAS DE QUALQUER ESPECIE DESTINADAS AO TRATAMENTO FISICO, ORGANICO E MENTAL"),
            new TipoServicoISSQN(410, "NUTRIÇAO "),
            new TipoServicoISSQN(411, "OBSTETRICIA "),
            new TipoServicoISSQN(412, "ODONTOLOGIA "),
            new TipoServicoISSQN(413, "ORTOPTICA "),
            new TipoServicoISSQN(414, "PROTESES SOB ENCOMENDA "),
            new TipoServicoISSQN(415, "PSICANALISE "),
            new TipoServicoISSQN(416, "PSICOLOGIA "),
            new TipoServicoISSQN(417, "CASAS DE REPOUSO E DE RECUPERAÇAO, CRECHES, ASILOS E CONGENERES "),
            new TipoServicoISSQN(418, "INSEMINAÇAO ARTIFICIAL, FERTILIZAÇAO IN VITRO E CONGENERES "),
            new TipoServicoISSQN(419, "BANCO DE SANGUE, LEITE, PELE, OLHOS, OVULOS, SEMEN E CONGENERES "),
            new TipoServicoISSQN(420, "COLETA DE SANGUE, LEITE, TECIDOS, SEMEN, ORGAOS E MATERIAIS BIOLOGICOS DE QUALQUER ESPECIE "),
            new TipoServicoISSQN(421, "UNIDADE DE ATENDIMENTO, ASSISTENCIA OU TRATAMENTO MOVEL E CONGENERES "),
            new TipoServicoISSQN(422, "PLANOS DE MEDICINA DE GRUPO OU INDIVIDUAL E CONVENIOS P/ PREST.DE ASSISTENCIA MEDICA, HOSPITALAR, ODONTOLOGICA E CONGENERES "),
            new TipoServicoISSQN(423, "OUTROS PLANOS DE SAUDE QUE SE CUMPRAM ATRAVES DE SERVIÇOS DE TERCEIROS CONTRATADOS, CREDENCIADOS, COOPERADOS OU APENAS PAGOS PELO OPERADOR DO PLANO MEDIANTE INDICAÇAO DO BENEFICIARIO "),
            new TipoServicoISSQN(501, "MEDICINA VETERINARIA E ZOOTECNIA "),
            new TipoServicoISSQN(502, "HOSPITAIS, CLINICAS, AMBULATORIOS, PRONTOS-SOCORROS E CONGENERES, NA AREA VETERINARIA "),
            new TipoServicoISSQN(503, "LABORATORIOS DE ANALISE NA AREA VETERINARIA "),
            new TipoServicoISSQN(504, "INSEMINAÇAO ARTIFICIAL, FERTILIZAÇAO IN VITRO E CONGENERES "),
            new TipoServicoISSQN(505, "BANCOS DE SANGUE E DE ORGAOS E CONGENERES "),
            new TipoServicoISSQN(506, "COLETA DE SANGUE, LEITE, TECIDOS, SEMEN, ORGAOS E MATERIAIS BIOLOGICOS DE QUALQUER ESPECIE "),
            new TipoServicoISSQN(507, "UNIDADE DE ATENDIMENTO, ASSISTENCIA OU TRATAMENTO MOVEL E CONGENERES "),
            new TipoServicoISSQN(508, "GUARDA, TRATAMENTO, AMESTRAMENTO, EMBELEZAMENTO, ALOJAMENTO E CONGENERES "),
            new TipoServicoISSQN(509, "PLANOS DE ATENDIMENTO E ASSISTENCIA MEDICO-VETERINARIA "),
            new TipoServicoISSQN(601, "BARBEARIA, CABELEIREIROS, MANICURES, PEDICURES E CONGENERES "),
            new TipoServicoISSQN(602, "ESTETICISTAS, TRATAMENTO DE PELE, DFEPILAÇAO E CONGENERES "),
            new TipoServicoISSQN(603, "BANHOS, DUCHAS, SAUNA, MASSAGENS E CONGENERES "),
            new TipoServicoISSQN(604, "GINASTICA, DANÇA, ESPORTES, NATAÇAO, ARTES MARCIAIS E DEMAIS ATIVIDADES FISICAS "),
            new TipoServicoISSQN(605, "CENTROS DE EMAGRECIMENTOS, SPA E CONGENERES "),
            new TipoServicoISSQN(606, "APLICACAO DE TATUAGENS, PIERCINGS E CONGENERES"),
            new TipoServicoISSQN(701, "ENGENHARIA, AGRONOMIA, AGRIMENSURA, ARQUITETURA, GEOLOGIA , URBANISMO, PAISAGISMO E CONGENERES "),
            new TipoServicoISSQN(702, "EXECUÇAO, POR ADMINISTRAÇAO, EMPREITADA OU SUBEMPREITEIRA, DE OBRAS DE CONSTR.CIVIL, HIDRAULICA OU ELETRICA E DE OUTRAS OBAS SEMELHANTES, INCLUSIVE SOLDAGEM, PERFURAÇAO DE POÇOS, ESCAVAÇAO, DRENAGEM E IRRIGAÇAO, TERRAPLENAGENM, PAVIMENTAÇAO, CONCRETAGEM E A INSTALAÇAO E MONTAGEM DE PRODUTOS, PEÇAS E EQUIPAMENTOS(EXETO O FORNECIMENTO DE MERCADORIAS PRODUZIDAS PELO PREST.DE SERV.FORA DO LOCAL DA PREST. DE SERV., QUE FICA SUJEITO AO ICMS) "),
            new TipoServicoISSQN(703, "ELABORAÇAO DE PLANOS DIRETORES, ESTUDOS DE VIABILIDADE, ESTUDOS ORGANIZACIONAIS E OUTROS, RELACIONADOS COM OBRAS E SERVIÇOS DE ENGENHARIA, ELABORAÇAO DE ANTEPROJETOS, PROJETOS BASICOS E PROJETOS EXECUTIVOS P/TRABALHOS DE ENGENHARIA."),
            new TipoServicoISSQN(704, "DEMOLIÇAO"),
            new TipoServicoISSQN(705, "REPARAÇAO, CONSERVAÇAO E REFORMA DE EDIFICIOS, ESTRADAS, PONTES, PORTOS E CONGENERES(EXETO O FORNECIMENTO DE MERCADORIAS PRODUZIDAS PELO PREST.DE SERV., FORA DO LOCAL DA PREST.DE SERV., QUE FICA SUJEITO AO ICMS) "),
            new TipoServicoISSQN(706, "COLOCAÇAO E INSTALAÇAO DE TAPETES, CARPETES, ASSOALHOS , CORTINAS, REVESTIMENTOS DE PAREDE, VIDROS DIVISORIAS, PLACAS DE GESSO E CONGENERES, COM MATERIAL FORNECIDO PELO TOMADOR DO SERVIÇO. "),
            new TipoServicoISSQN(707, "RECUPERAÇAO, RASPAGEM, POLIMENTO E LUSTRAÇAO DE PISOS E CONGENERES "),
            new TipoServicoISSQN(708, "CALAFETAÇAO "),
            new TipoServicoISSQN(709, "VARRIÇAO, COLETA, REMOÇAO, INCINERAÇAO, TRATAMENTO, RECICLAGEM, SEPARAÇAO E DESTINAÇAO FINAL DE LIXO, REJEITOS E OUTROS RESIDUOS QUAISQUER "),
            new TipoServicoISSQN(710, "LIMPEZA, MANUTENÇAO E CONSERVAÇAO DE VIAS E LOGRADOUROS PUBLICOS, IMOVEIS, CHAMINES, PISCINAS, PARQUES, JARDINS E CONGENERES "),
            new TipoServicoISSQN(711, "DECORAÇAO E JARDINAGEM, INCLUSIVE CORTE E PODA DE ARVORES "),
            new TipoServicoISSQN(712, "CONTROLE E TRATAMENTO DE EFLUENTES DE QUALQUER NATUREZA E DE AGENTES FISICOS, QUIMICOS E BIOLOGICOS "),
            new TipoServicoISSQN(713, "DEDETIZAÇAO, DESINFECÇAO, DESINSETIZAÇAO, IMUNIZAÇAO, HIGIENIZAÇAO, DESRATIZAÇAO, PULVERIZAÇAO E CONGENERES "),
            new TipoServicoISSQN(714, "VETADO "),
            new TipoServicoISSQN(715, "VETADO "),
            new TipoServicoISSQN(716, "FLORESTAMENTO, REFLORESTAMENTO, SEMEADURA, ADUBACAO, REPARACAO DE SOLO, PLANTIO, SILAGEM, COLHEITA, CORTE E DESCASCAMENTO DE ARVORES, SILVICULTURA, EXPLORACAO FLORESTAL E DOS SERVICOS CONGENERES INDISSOCIAVEIS DA FORMACAO, MANUTENCAO E COLHEITA DE FLORESTAS, PARA QUAISQUER FINS E POR QUAISQUER MEIOS"),
            new TipoServicoISSQN(717, "ESCORAMENTO, CONTENÇAO DE ENCOSTAS E SERVIÇOS CONGENERES "),
            new TipoServicoISSQN(718, "LIMPEZA E DRAGAGEM DE RIOS, PORTOS, CANAIS, BAIAS, LAGOS, LAGOAS, REPRESAS, AÇUDES E CONGENERES "),
            new TipoServicoISSQN(719, "ACOMPANHAMENTO E FISCALIZAÇAO DA EXECUÇAO DE OBRAS DE ENGENHARIA, ARQUITETURA E URBANISMO "),
            new TipoServicoISSQN(720, "AEROFOTOGRAMETRIA (INCLUSIVE INTERPRETAÇAO), CARTOGRAFIA, MAPEAMENTO, LEVANTAMENTO TOPOGRAFICOS, BATIMETRICOS, GEOGRAFICOS, GEODESICOS, GEOLOGICOS, GEOFISICOS E CONGENERES "),
            new TipoServicoISSQN(721, "PESQUISA, PERFURAÇAO, CIMENTAÇAO, MERGULHO, PERFILAGEM, CONCRETAÇAO, TESTEMUNHAGEM, PESCARIA, ESTIMULAÇAO E OUTROS SERVIÇOS RELACIONADOS COM A EXPLORAÇAO E EXPLOTAÇAO DE PETROLEO, GAS NATURAL E DE OUTROS RECURSOS MINERAIS "),
            new TipoServicoISSQN(722, "NUCLEAÇAO E BOMBARDEAMENTO DE NUVENS E CONGENERES "),
            new TipoServicoISSQN(801, "ENSINO REGULAR PRE-ESCOLAR, FUNDAMENTAL, MEDIO E SUPERIOR "),
            new TipoServicoISSQN(802, "INSTRUÇAO, TREINAMENTO, ORIENTAÇAO PEDAGOGICA E EDUCACIONAL, AVALIAÇAO DE CONHECIMENTOS DE QUALQUER NATUREZA "),
            new TipoServicoISSQN(901, "HOSPEDAGEM DE QUALQUER NATUREZA EM HOTEIS, APART-SERVICE CONDOMINIAIS, FLAT, APART-JOTEIS, HOTEIS RESIDENCIA, RESIDENCE-SERVICE, SUITE SERVICE, HOTELARIA MARITIMA, MOTEIS, PENSOES E CONGENERES;OCUPAÇAO POR TEMPORADA C/ FORNECIMENTO DE SERVIÇO (O VALOR DA ALIMENTAÇAO E GORJETA, QUANDO INCLUIDO NO PREÇO DA DIARIA, FICA SUJEITO AO IMPOSTO SOBRE SERVIÇO) "),
            new TipoServicoISSQN(902, "AGENCIAMENTO, ORGANIZAÇAO, PROMOÇAO, INTERMEDIAÇAO E EXECUÇAO DE PROGRAMAS DE TURISMO, PASSEIOS, VIAGENS, EXCURSOES, HOSPEDAGENS E CONGENERES "),
            new TipoServicoISSQN(903, "GUIAS DE TURISMO "),
            new TipoServicoISSQN(1001, "AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇAO DE CAMBIO, SEGUROS E CARTOES DE CREDITO, DE PLANO DE SAUDE E DE PREVIDENCIA PRIVADA "),
            new TipoServicoISSQN(1002, "AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇAO DE TITULOS EM GERAL, VALORES MOBILIARIOS E CONTRATOS QUAISQUER "),
            new TipoServicoISSQN(1003, "AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇAO DE DIREITOS DE PROPRIEDADE INDUSTRIAL, ARTISTICA OU LITERARIA "),
            new TipoServicoISSQN(1004, "AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇAO DE CONTRATOS DE ARRENDAMENTO MERCANTIL (LEASING), DE FRANQUIA (FRANCHISING) E DE FATURIZAÇAO (FACTORING)"),
            new TipoServicoISSQN(1005, "AGENCIAMENTO, CORRETAGEM OU INTERMEDIAÇAO DE BENS MOVEIS OU IMOVEIS, NAO ABRANGIDOS EM OUTROS ITENS OU SUBITENS, INCLUSIVE AQUELES REALIZADOS NO AMBITO DE BOLSAS DE MERCADORIAS E FUTUROS, POR QUAISQUER MEIOS"),
            new TipoServicoISSQN(1006, "AGENCIAMENTO MARITIMO"),
            new TipoServicoISSQN(1007, "AGENCIAMENTO DE NOTICIAS"),
            new TipoServicoISSQN(1008, "AGENCIAMENTO DE PUBLICIDADE E PROPAGANDA, INCLUSIVE O AGENCIAMENTO DE VEICULAÇAO POR QUAISQUER MEIOS"),
            new TipoServicoISSQN(1009, "REPRESENTAÇAO DE QUALQUER NATUREZA, INCLUSIVE COMERCIAL"),
            new TipoServicoISSQN(1010, "DISTRIBUIÇAO DE BENS DE TERCEIROS"),
            new TipoServicoISSQN(1101, "GUARDA E ESTACIONAMENTO DE VEICULOS TERRESTRE AUTOMOTORES, DE AERONAVES E DE EMBARCAÇOES"),
            new TipoServicoISSQN(1102, "VIGILANCIA, SEGURANCA OU MONITORAMENTO DE BENS, PESSOAS E SEMOVENTES"),
            new TipoServicoISSQN(1103, "ESCOLTA, INCLUSIVE DE VEICULOS E CARGAS "),
            new TipoServicoISSQN(1104, "ARMAZENAMENTO, DEPOSITO, CARGA, DESCARGA, ARRUMAÇAO E GUARDA DE BENS DE QUALQUER ESPECIE "),
            new TipoServicoISSQN(1201, "ESPETACULOS TEATRAIS "),
            new TipoServicoISSQN(1202, "EXIBIÇOES CINEMATOGRAFICAS "),
            new TipoServicoISSQN(1203, "ESPETACULOS CIRCENSES "),
            new TipoServicoISSQN(1204, "PROGRAMAS DE AUDITORIO "),
            new TipoServicoISSQN(1205, "PARQUE DE DIVERSOES, CENTROS DE LAZER E CONGENERES "),
            new TipoServicoISSQN(1206, "BOATES, TAXI-DANCING E CONGENERES "),
            new TipoServicoISSQN(1207, "SHOWS, BALLET, DANÇAS, DESFILES, BAILES, OPERAS, CONCERTOS , RECITAIS, FESTIVAIS E CONGENERES "),
            new TipoServicoISSQN(1208, "FEIRAS, EXPOSIÇOES, CONGRESSOS E CONGENERES "),
            new TipoServicoISSQN(1209, "BILHARES, BOLICHES E DIVERSOES ELETRONICAS OU NAO "),
            new TipoServicoISSQN(1210, "CORRIDAS E COMPETIÇOES DE ANIMAIS "),
            new TipoServicoISSQN(1211, "COMPETIÇOES ESPORTIVAS OU DE DESTREZA FISICA OU INTELECTUAL, COM OU SEM A PARTICIPAÇAO DO ESPECTADOR "),
            new TipoServicoISSQN(1212, "EXECUÇAO DE MUSICA "),
            new TipoServicoISSQN(1213, "PRODUÇAO, MEDIANTE OU SEM ENCOMENDA PREVIA, DE EVENTOS, ESPETACULOS, ENTREVISTAS, SHOWS, BALLET, DANÇAS, DESFILES , BAILES, TEATROS, OPERAS, CONCERTOS, RECITAIS, FESTIVAIS E CONGENERES"),
            new TipoServicoISSQN(1214, "FORNECIMENTO DE MUSICA PARA AMBIENTES FECHADOS OU NAO, MEDIANTE TRANSMISSAO POR QUALQUER PROCESSO"),
            new TipoServicoISSQN(1215, "DESFILES DE BLOCOS CARNAVALESCOS OU FOLCLORICOS, TRIOS ELETRICOS E CONGENERES"),
            new TipoServicoISSQN(1216, "EXIBIÇAO DE FILMES, ENTREVISTAS, MUSICAIS, ESPETACULOS, SHOWS, CONCERTOS, DESFILES, OPERAS, COMPETIÇOES ESPORTIVAS, DE DESTREZA INTELECTUAL OU CONGENERES "),
            new TipoServicoISSQN(1217, "RECREAÇAO E ANIMAÇAO, INCLUSIVE EM FESTAS E EVENTOS DE QUALQUER NATUREZA "),
            new TipoServicoISSQN(1301, "VETADO"),
            new TipoServicoISSQN(1302, "FONOGRAFIA OU GRAVAÇAO DE SONS, INCLUSIVE TRUCAGEM, DUBLAGEM, MIXAGEM E CONGENERES "),
            new TipoServicoISSQN(1303, "FOTOGRAFIA E CINEMATOGRAFIA, INCLUSIVE REVELAÇAO, AMPLIAÇAO, COPIA, REPRODUÇAO, TRUCAGEM E CONGENERES "),
            new TipoServicoISSQN(1304, "REPROGRAFIA, MICROFILMAGEM E DIGITILIZAÇAO "),
            new TipoServicoISSQN(1305, "COMPOSICAO GRAFICA, INCLUSIVE CONFECCAO DE IMPRESSOS GRAFICOS, FOTOCOMPOSICAO, CLICHERIA, ZINCOGRAFIA, LITOGRAFIA E FOTOLITOGRAFIA, EXCETO SE DESTINADOS A POSTERIOR OPERACAO DE COMERCIALIZACAO OU INDUSTRIALIZACAO, AINDA QUE INCORPORADOS, DE QUALQUER FORMA, A OUTRA MERCADORIA QUE DEVA SER OBJETO DE POSTERIOR CIRCULACAO, TAIS COMO BULAS, ROTULOS, ETIQUETAS, CAIXAS, CARTUCHOS, EMBALAGENS E MANUAIS TECNICOS E DE INSTRUCAO, QUANDO FICARAO SUJEITOS AO ICMS"),
            new TipoServicoISSQN(1401, "LUBRIFICAÇAO, LIMPEZA, LUSTRAÇAO, REVISAO, CARGA, RECARGA, CONSERTO, RESTAURAÇAO, BLINDAGEM, MANUTENÇAO E CONSERVAÇAO DE MAQUINAS, VEICULOS, APARELHOS, EQUIPAMENTOS, MOTORES, ELEVADORES OU DE QUALQUER OBJETO (EXETO PEÇAS E PARTES EMPREGADAS, QUE FICAM SUJEITAS AO ICMS) "),
            new TipoServicoISSQN(1402, "ASSISTENCIA TECNICA"),
            new TipoServicoISSQN(1403, "RECONDICIONAMENTO DE MOTERES (EXETO PEÇAS E PARTESEMPREGADAS, QUE FICAM SUJEITAS AO ICMS) "),
            new TipoServicoISSQN(1404, "RECAUCHUTAGEM OU REGENERAÇAO DE PNEUS "),
            new TipoServicoISSQN(1405, "RESTAURACAO, RECONDICIONAMENTO, ACONDICIONAMENTO, PINTURA, BENEFICIAMENTO, LAVAGEM, SECAGEM, TINGIMENTO, GALVANOPLASTIA, ANODIZACAO, CORTE, RECORTE, PLASTIFICACAO, COSTURA, ACABAMENTO, POLIMENTO E CONGENERES DE OBJETOS QUAISQUER"),
            new TipoServicoISSQN(1406, "INSTALAÇAO E MONTAGEM DE APARELHOS, MAQUINAS E EQUIPAMENTOS, INCLUSIVE MONTAGEM INDUSTRIAL, PRESTADOS AO USUARIO FINAL, EXCLUSIVAMENTE COM MATERIAL POR ELE FORNECIDO "),
            new TipoServicoISSQN(1407, "COLOCAÇAO DE MOLDURAS E CONGENERES"),
            new TipoServicoISSQN(1408, "ENCADERNAÇAO, GRAVAÇAO E DOURAÇAO DE LIVROS, REVISTAS E CONGENERES"),
            new TipoServicoISSQN(1409, "ALFAIATARIA E COSTURA, QUANDO O MATERIAL FOR FORNECIDO PELO USUARIO FINAL, EXETO AVIAMENTO"),
            new TipoServicoISSQN(1410, "TINTURARIA E LAVANDERIA"),
            new TipoServicoISSQN(1411, "TAPEÇARIA E REFORMA DE ESTOFAMENTOS EM GERAL "),
            new TipoServicoISSQN(1412, "FUNILARIA E LANTERNAGEM"),
            new TipoServicoISSQN(1413, "CARPINTARIA E SERRALHERIA"),
            new TipoServicoISSQN(1414, "GUINCHO INTRAMUNICIPAL, GUINDASTE E ICAMENTO"),
            new TipoServicoISSQN(1501, "ADMINISTRACAO DE FUNDOS QUAISQUER, DE CONSORCIO, DE CARTAO DE CREDITO OU DEBITO E CONGENERES, DE CARTEIRA DE CLIENTES, DE CHEQUES PRE-DATATOS E CONGENERES"),
            new TipoServicoISSQN(1502, "ABERTURA DE CONTAS EM GERAL, INCLUSIVE CONTA-CORRENTE , CONTA DE INVESTIMENTOS E APLICAÇAO E CADERNETA DE POUPANÇA, NO PAIS E NO EXTERIOR, BEM COMO A MANUTENÇAO DAS REFERIDAS CONTAS ATIVAS E INATIVAS."),
            new TipoServicoISSQN(1503, "LOCAÇAO E MANUTENÇAO DE COFRES PARTICULARES, DE TERMINAIS ELETRONICOS, DE TERMINAIS DE ATENDIMENTO E DE BENS E EQUIPAMENTOS EM GERAL."),
            new TipoServicoISSQN(1504, "FORNECIMENTO OU EMISSAO DE ATESTADOS EM GERAL, INCLUSIVE ATESTADO IDONEIDADE, ATESTADO DE CAPACIDADE FINANCEIRA E CONGENERES"),
            new TipoServicoISSQN(1505, "CADASTRO, ELABORAÇAO DE FICHA CADASTRAL, RENOVAÇAO CADASTRAL E CONGENERES, INCLUSAO OU EXCLUSAO NO CADASTRO DE EMITENTES DE CHEQUES SEM FUNDOS - CCF OU EM QUAISQUER OUTROS BANCOS CADASTRAIS "),
            new TipoServicoISSQN(1506, "EMISSAO, REEMISSAO E FORNECIMENTOS DE AVISOS, COMPROVANTES E DOCUMENTOS EM GERAL;ABONO DE FIRMAS, COLETA E ENTREGA DE DOCUMENTOS, BENS E VALORES;COMUNICAÇAO COM OUTRA AGENCIA OU COM ADMINISTRAÇAO CENTRAL; LICENCIAMENTO ELETRONICO DE VEICULOS;AGENCIAMENTO FIDUCIARIO OU DEPOSITARIO;DEVOLUÇAO DE BENS EM CUSTODIA"),
            new TipoServicoISSQN(1507, "ACESSO, MOVIMENTAÇAO, ATENDIMENTO E CONSULTAS A CONTAS EM GERAL, POR QUALQUER MEIO OU PROCESSO, INCLUSIVE POR TELEFONE, FAC-SIMILE, INTERNET E TELEX, ACESSO A TERMINAIS DE ATENDIMENTO, INCLUSIVE VINTE E QUATRO HORAS;ACESSO A OUTRO BANCO E A REDE COMPARTILHADA; FORNECIMENTO DE SALDO, EXTRATO E DEMAIS INFORMAÇOES RELATIVAS A CONTA EM GERAL, POR QUALQUER MEIO OU PROCESSO. "),
            new TipoServicoISSQN(1508, "EMISSAO, REMISSAO, ALTERAÇAO, CESSAO, SUBSTITUIÇAO, CANCELAMENTO E REGISTRO DE CONTRATO DE CREDITO;ESTUDO, ANALISE E AVALIAÇAO DE OPERAÇOES DE CREDITO;EMISSAO, CONCESSAO, ALTERAÇAO OU CONTRATAÇAO DE AVAL, FIANÇA, ANUENCIA E CONGENERES;SERVIÇO RELATIVO A ABERTURA DE CREDITO, PARA QUAISQUER FINS. "),
            new TipoServicoISSQN(1509, "ARRENDAMENTO MERCANTIL (LEASING) DE QUAISQUER BENS, INCLUSIVE CESSAO DE DIREITO E OBRIGAÇOES, SUBSTITUIÇAO DE GARANTIA, ALTERAÇAO, CANCELAMENTO E REGISTRO DE CONTRATO, E DEMAIS SERVIÇOS RELACIONADOS AO ARRENDAMENTO MERCANTIL (LEASING)"),
            new TipoServicoISSQN(1510, "SERVIÇOS RELACIONADOS A COBRANÇAS, RECEBIMENTOS OU PAGAMENTOS EM GERAL, DE TITULOS QUAISQUER, DE CONTAS OU CARNES, DE CAMBIO, DE TRIBUTOS E POR CONTA DE TERCEIROS , INCLUSIVE OS EFETUADOS POR MEIO ELETRONICO, AUTOMATICO OU POR MAQUINAS DE ATENDIMENTO;FORNECIMENTO DE POSIÇAO DE COBRANÇA, RECEBIMENTO OU PAGAMENTO;EMISSAO DE CARNES, FICHAS DE COMPENSAÇAO, IMPRESSOS E DOCTOS EM GERAL "),
            new TipoServicoISSQN(1511, "DEVOLUÇAO DE TITULOS, PROTESTOS DE TITULOS, SUSTAÇAO DE PROTESTO, MANUTENÇAO DE TITULOS, REPRESENTAÇAO DE TITULOS, E DEMAIS SERVIÇOS A ELES RELACIONADOS "),
            new TipoServicoISSQN(1512, "CUSTODIA EM GERAL, INCLUSIVE DE TITULOS E VALORES MOBILIARIOS "),
            new TipoServicoISSQN(1513, "SERVIÇOS RELACIONADOS A OPERAÇOES DE CAMBIO EM GERAL , EDIÇAO, ALTERAÇAO, PRORROGAÇAO, CANCELAMENTO E BAIXA DE CONTRATO DE CAMBIO;EMISSAO DE REGISTRO DE EXPORTAÇAO OU DE CREDITO;COBRANÇA OU DEPOSITO NO EXTERIOR , EMISSAO, FORNECIMENTO E CANCELAMENTO DE CHEQUES DE VIAGEM;FORNECIMENTO, TRANSFERENCIA, CANCELAMENTO E DEMAIS SERVIÇOS RELATIVOS A CARTA DE CREDITO DE IMPORTAÇAO, EXPORTAÇAO E GARANTIAS RECEBIDAS, ENVIO E RECEBIMENTO DE MENSAGENS EM GERAL RELACIONADAS A OPERAÇAO DE CAMBIO."),
            new TipoServicoISSQN(1514, "FORNECIMENTO, EMISSAO, REEMISSAO, RENOVAÇAO E MANUTENÇAO DE CARTAO DE CREDITO, CARTAO DE DEBITO , CARTAO MAGNETICO, CARTAO SALARIO E CONGENERES "),
            new TipoServicoISSQN(1515, "COMPENSAÇAO DE CHEQUES E TITULOS QUAISQUER, SERVIÇOS RELACIONADOS A DEPOSITO, INCLUSIVE DEPOSITO IDENTIFICADO, A SAQUE DE CONTAS QUAISQUER, POR QUALQUER MEIO OU PROCESSO, INCLUSIVE EM TERMINAIS ELETRONICOS E DE ATENDIMENTO"),
            new TipoServicoISSQN(1516, "EMISSAO, REEMISSAO, LIQUIDAÇAO, ALTERAÇAO, CANCELAMENTO E BAIXA DE ORDENS DE PAGAMENTO, ORDENS DE CREDITO E SIMILARES, POR QUALQUER MEIO OU PROCESSO, SERVIÇOS RELACIONADOS A TRANSFERENCIA DE VALORES, DADOS, FUNDOS , PAGAMENTOS E SIMILARES, INCLUSIVE ENTRE CONTAS EM GERAL. "),
            new TipoServicoISSQN(1517, "EMISSAO, FORNECIMENTO, DEVOLUÇAO, SUSTAÇAO, CANCELAMENTO E OPOSIÇAO DE CHEQUES QUAISQUER, AVULSOS OU POR TALAO "),
            new TipoServicoISSQN(1518, "SERVIÇOS RELACIONADOS A CREDITO IMOBILIARIO, AVALIAÇAO E VISTORIA DE IMOVEL OU OBRA, ANALISE TECNICA E JURIDICA, EMISSAO, REEMISSAO, ALTERAÇAO, TRANSFERENCIA E RENEGOCIAÇAO DE CONTRATO, EMISSAO E REEMISSAO DO TERMO DE QUITAÇAO E DEMAIS SERVIÇOS RELACIONADOS A CREDITO IMOBILIARIO "),
            new TipoServicoISSQN(1601, "SERVICOS DE TRANSPORTE COLETIVO MUNICIPAL RODOVIARIO, METROVIARIO, FERROVIARIO E AQUAVIARIO DE PASSAGEIROS"),
            new TipoServicoISSQN(1602, "OUTROS SERVICOS DE TRANSPORTE DE NATUREZA MUNICIPAL"),
            new TipoServicoISSQN(1701, "ASSESSORIA E CONSULTORIA DE QUALQUER NATUREZA, NAO CONTIDA EM OUTROS ITENS DESTA LISTA, ANALISE, EXAME, PESQUISA, COLETA, COMPILAÇAO E FORNECIMENTO DE DADOS E INFORMAÇOES DE QUALQUER NATUREZA, INCLUSIVE CADASTRO E SIMILARES. "),
            new TipoServicoISSQN(1702, "DATILOGRAFIA, DIGITAÇAO, ESTENOGRAFIA, EXPEDIENTE, SECRETARIA EM GERAL, RESPOSTA AUDIVEL, REDAÇAO, EDIÇAO , INTERPRETAÇAO, REVISAO, TRADUÇAO, APOIO E INFRAESTRUTURA ADMINISTRATIVA E CONGENERES "),
            new TipoServicoISSQN(1703, "PLANEJAMENTO, COORDENAÇAO, PROGRAMAÇAO OU ORGANIZAÇAO TECNICA, FINANCEIRA OU ADMINISTRATIVA "),
            new TipoServicoISSQN(1704, "RECRUTAMENTO, AGENCIAMENTO, SELEÇAO E COLOCAÇAO DE MAO-DE-OBRA "),
            new TipoServicoISSQN(1705, "FORNECIMENTO DE MAO-DE-OBRA, MESMO EM CARATER TEMPORARIO, INCLUSIVE DE EMPREGADOS OU TRABALHADORES, AVULSOS OU TEMPORARIOS, CONTRATADOS PELO PRESTADOR DE SERVIÇOS "),
            new TipoServicoISSQN(1706, "PROPAGANDA E PUBLICIDADE, INCLUSIVE PROMOÇAO DE VENDAS, PLANEJAMENTO DE CAMPANHAS OU SISTEMAS DE PUBLICIDADE, ELEBORAÇAO DE DESENHOS, TEXTOS E DEMAIS MATERIAIS PUBLICITARIOS "),
            new TipoServicoISSQN(1707, "VETADO "),
            new TipoServicoISSQN(1708, "FRANQUIA (FRANCHISING) "),
            new TipoServicoISSQN(1709, "PERICIAS, LAUDOS, EXAMES TECNICOS E ANALISES TECNICAS "),
            new TipoServicoISSQN(1710, "PLANEJAMENTO, ORGANIZAÇAO E ADMINISTRAÇAO DE FEIRAS, EXPOSIÇOES, CONGRESSOS E CONGENERES "),
            new TipoServicoISSQN(1711, "ORGANIZAÇAO DE FESTAS E RECEPÇOES;BUFE(EXETO O FORNECIMENTO DE ALIMENTAÇAO E BEBIDAS, QUE FICA SUJEITO AO ICMS) "),
            new TipoServicoISSQN(1712, "ADMINISTRAÇAO EM GERAL, INCLUSIVE DE BENS E NEGOCIOS DE TERCEIROS "),
            new TipoServicoISSQN(1713, "LEILAO E CONGENERES "),
            new TipoServicoISSQN(1714, "ADVOCACIA "),
            new TipoServicoISSQN(1715, "ARBITRAGEM DE QUALQUER ESPECIE, INCLUSIVE JURIDICA "),
            new TipoServicoISSQN(1716, "AUDITORIA "),
            new TipoServicoISSQN(1717, "ANALISE DE ORGANIZAÇOES E METODOS "),
            new TipoServicoISSQN(1718, "ATUARIA E CALCULOS TECNICOS DE QUALQUER NATUREZA "),
            new TipoServicoISSQN(1719, "CONTABILIDADE, INCLUSIVE SERVIÇOS TECNICOS AUXILIARES"),
            new TipoServicoISSQN(1720, "CONSULTORIA E ASSESSORIA ECONOMICA OU FINANCEIRA "),
            new TipoServicoISSQN(1721, "ESTATISTICA "),
            new TipoServicoISSQN(1722, "COBRANÇA EM GERAL "),
            new TipoServicoISSQN(1723, "ASSESSORIA, ANALISE, AVALIAÇAO, ATENDIMENTO, CONSULTA, CADASTRO, SELEÇAO, GERENCIAMENTO DE INFORMAÇOES, ADMINISTRAÇAO DE CONTAS A RECEBER OU A PAGAR E EM GERAL, RELACIONADOS A OPERAÇOES DE FATURIZAÇAO (FACTORING)"),
            new TipoServicoISSQN(1724, "APRESENTAÇAO DE PALESTRAS, CONFERENCIAS, SEMINARIOS E CONGENERES "),
            new TipoServicoISSQN(1725, "INSERCAO DE TEXTOS, DESENHOS E OUTROS MATERIAIS DE PROPAGANDA E PUBLICIDADE, EM QUALQUER MEIO (EXCETO EM LIVROS, JORNAIS, PERIODICOS E NAS MODALIDADES DE SERVIÇOS DE RADIODIFUSAO SONORA E DE SONS E IMAGENS DE RECEPCAO LIVRE E GRATUITA)"),
            new TipoServicoISSQN(1801, "SERVIÇO DE REGULAÇAO DE SINISTROS VINCULADOS A CONTRATOS DE SEGUROS;INSPEÇAO E AVALIAÇAO DE RISCOS PARA COBERTURA DE CONTRATOS DE SEGURO;PREVENÇAO E GERENCIA DE RISCOS SEGURAVEIS E CONGENERES"),
            new TipoServicoISSQN(1901, "SERVIÇOS DE DISTRIBUIÇAO E VENDA DE BILHETES E DEMAIS PRODUTOS DE LOTERIA, BINGOS, CARTOES, PULES OU CUPONS DE APOSTAS, SORTEIOS, PREMIOS, INCLUSIVE OS DECORRENTES DE TITULOS DE CAPITALIZAÇAO E CONGENERES "),
            new TipoServicoISSQN(2001, "SERVIÇOS PORTUARIOS, FERROPORTUARIOS, UTILIZAÇAO DE PORTO, MOVIMENTAÇAO DE PASSAGEIROS, REBOQUE DE EMBARCAÇOES, REBOCADOR ESCOTEIRO, ATRACAÇAO, DESATRACAÇAO, SERVIÇOS DE PRATICAGEM, CAPATAZIA, ARMAZENAGEM DE QUALQUER NATUREZA, SERVIÇOS ACESSORIOS , MOVIMENTAÇAO DE MERCADORIAS, SERVIÇOS DE APOIO MARITIMO, DE MOVIMENTAÇAO AO LARGO, SERVIÇO DE ARMADORES, ESTIVA, CONFERENCIA, LOGISTICA E CONGENERES"),
            new TipoServicoISSQN(2002, "SERVIÇOS AEROPORTUARIOS, UTILIZAÇAO DE AEROPORTO, MOVIMENTAÇAO DE PASSAGEIROS, ARMAZENAGEM DE QUALQUER NATUREZA, CAPATAZIA, MOVIMENTAÇAO DE AERONAVES, SERVIÇOS DE APOIO AEROPORTUARIOS, SERVIÇOS ACESSORIOS , MOVIMENTAÇAO DE MERCADORIAS, LOGISTICA E CONGENERES "),
            new TipoServicoISSQN(2003, "SERVIÇO DE TERMINAIS RODOVIARIOS, FERROVIARIOS, METROVIARIOS, MOVIMENTAÇAO DE PASSAGEIROS, MERCADORIAS, INCLUSIVE SUAS OPERAÇOES, LOGISTICA E CONGENERES"),
            new TipoServicoISSQN(2101, "SERVIÇOS DE REGISTROS PUBLICOS, CARTORARIOS E NOTARIAIS "),
            new TipoServicoISSQN(2201, "SERVIÇOS DE EXPLORAÇAO DE RODOVIA MEDIANTE COBRANÇA DE PREÇO OU PEDAGIO DOS USUARIOS, ENVOLVENDO EXECUÇOES DE SERVIÇOS DE CONSERVAÇAO, MANUTENÇAO, MELHORAMENTOS P/ ADEQUAÇAO DE CAPACIDADE E SEGURANÇA DE TRANSITO, OPERAÇAO, MONITORAÇAO, ASSISTENCIA AOS USUARIOS E OUTROS SERVIÇOS DEFENIDOS EM CONTRATO, ATOS DE CONCESSAO OU DE PERMISSAO OU EM NORMAS OFICIAIS"),
            new TipoServicoISSQN(2301, "SERVIÇOS DE PROGRAMAÇAO E COMUNICAÇAO VISUAL, DESENHO INDUSTRIAL E CONGENERES "),
            new TipoServicoISSQN(2401, "SERVIÇOS DE CHAVEIROS, CONFECÇAO DE CARIMBOS, PLACAS, SINALIZAÇAO VISUAL, BANNERS, ADESIVOS E CONGENERES "),
            new TipoServicoISSQN(2501, "FUNERARIAS, NCLUSIVE FORNECIMENTO DE CAIXAO, URNA OU ESQUIFE;ALUGUEL DE CAPELA;TRANSPORTE DO CORPO CADAVERICO;FORNECIMENTO DE FLORES, COROAS E OUTROS PARAMENTOS;DESEMBARAÇO DE CERTIDOES DE OBITO; FORNECIMENTO DE VEU, ESSA E OUTROS ADORNOS, EMBALSAMAMENTO, EMBELEZAMENTO, CONSERVAÇAO OU RESTAURAÇAO DE CADAVERES"),
            new TipoServicoISSQN(2502, "TRANSLADO INTRAMUNICIPAL E CREMACAO DE CORPOS E PARTES DE CORPOS CADAVERICOS"),
            new TipoServicoISSQN(2503, "PLANOS OU CONVENIO FUNERARIOS"),
            new TipoServicoISSQN(2504, "MANUTENÇAO E CONSERVAÇAO DE JAZIGOS E CEMITERIOS"),
            new TipoServicoISSQN(2505, "CESSAO DE USO DE ESPACOS EM CEMITERIOS PARA SEPULTAMENTO"),
            new TipoServicoISSQN(2601, "SERVIÇO DE COLETA, REMESSA OU ENTREGA DE CORRESPONDENCIAS, DOCUMENTOS, OBJETOS, BENS OU VALORES, INCLUSIVE PELOS CORREIOS E SUAS AGENCIAS FRANQUEADAS; COURRIER E CONGENERES"),
            new TipoServicoISSQN(2701, "SERVIÇOS DE ASSISTENCIA SOCIAL"),
            new TipoServicoISSQN(2801, "SERVIÇOS DE AVALIAÇAO DE BENS E SERVIÇOS DE QUALQUER NATUREZA"),
            new TipoServicoISSQN(2901, "SERVIÇOS DE BIBLIOTECONOMIA"),
            new TipoServicoISSQN(3001, "SERVIÇO DE BIOLOGIA, BIOTECNOLOGIA E QUIMICA"),
            new TipoServicoISSQN(3101, "SERVIÇOS TECNICOS EM EDIFICAÇOES, ELETRONICA, ELETRO- TECNICA, MECANICA, TELECOMUNICAÇOES E CONGENERES"),
            new TipoServicoISSQN(3201, "SERVIÇOS DE DESENHOS TECNICOS"),
            new TipoServicoISSQN(3301, "SERVIÇO DE DESEMBARAÇO ADUANEIRO, COMISSARIOS, DESPACHANTES E CONGENERES"),
            new TipoServicoISSQN(3401, "SERVIÇOS DE INVESTIGAÇOES PARTICULARES, DETETIVES E CONGENERES"),
            new TipoServicoISSQN(3501, "SERVIÇO DE REPORTAGEM, ASSESSORIA DE IMPRENSA, JORNALISMO E RELAÇOES PUBLICAS"),
            new TipoServicoISSQN(3601, "SERVIÇO DE METEOROLOGIA"),
            new TipoServicoISSQN(3701, "SERVIÇOS DE ARTISTAS, ATLETAS, MODELOS E MANEQUINS "),
            new TipoServicoISSQN(3801, "SERVIÇOS DE MUSEOLOGIA "),
            new TipoServicoISSQN(3901, "SERVIÇOS DE OURIVESARIA E LAPIDAÇAO (QUANDO O MATERIAL FOR FORNECIDO PELO TOMADOR DO SERVIÇO) "),
            new TipoServicoISSQN(4001, "OBRAS DE ARTE SOB ENCOMENDA "),

            new TipoServicoISSQN(10101, 'Análise e desenvolvimento de sistemas.'),
            new TipoServicoISSQN(10201, 'Programação.'),
            new TipoServicoISSQN(10301, 'Processamento de dados, textos, imagens, vídeos, páginas eletrônicas, aplicativos e sistemas de informação, entre outros formatos, e congêneres.'),
            new TipoServicoISSQN(10302, 'Armazenamento ou hospedagem de dados, textos, imagens, vídeos, páginas eletrônicas, aplicativos e sistemas de informação, entre outros formatos, e congêneres.'),
            new TipoServicoISSQN(10401, 'Elaboração de programas de computadores, inclusive de jogos eletrônicos, independentemente da arquitetura construtiva da máquina em que o programa será executado, incluindo tablets, smartphones e congêneres.'),
            new TipoServicoISSQN(10501, 'Licenciamento ou cessão de direito de uso de programas de computação.'),
            new TipoServicoISSQN(10601, 'Assessoria e consultoria em informática.'),
            new TipoServicoISSQN(10701, 'Suporte técnico em informática, inclusive instalação, configuração e manutenção de programas de computação e bancos de dados.'),
            new TipoServicoISSQN(10801, 'Planejamento, confecção, manutenção e atualização de páginas eletrônicas.'),
            new TipoServicoISSQN(10901, 'Disponibilização, sem cessão definitiva, de conteúdos de áudio por meio da internet (exceto a distribuição de conteúdos pelas prestadoras de Serviço de Acesso Condicionado, de que trata a Lei nº 12.485, de 12 de setembro de 2011, sujeita ao ICMS).'),
            new TipoServicoISSQN(10902, 'Disponibilização, sem cessão definitiva, de conteúdos de vídeo, imagem e texto por meio da internet, respeitada a imunidade de livros, jornais e periódicos (exceto a distribuição de conteúdos pelas prestadoras de Serviço de Acesso Condicionado, de que trata a Lei nº 12.485, de 12 de setembro de 2011, sujeita ao ICMS).'),
            new TipoServicoISSQN(20101, 'Serviços de pesquisas e desenvolvimento de qualquer natureza.'),
            new TipoServicoISSQN(30201, 'Cessão de direito de uso de marcas e de sinais de propaganda.'),
            new TipoServicoISSQN(30301, 'Exploração de salões de festas, centro de convenções, stands e congêneres, para realização de eventos ou negócios de qualquer natureza.'),
            new TipoServicoISSQN(30302, 'Exploração de escritórios virtuais e congêneres, para realização de eventos ou negócios de qualquer natureza.'),
            new TipoServicoISSQN(30303, 'Exploração de quadras esportivas, estádios, ginásios, canchas e congêneres, para realização de eventos ou negócios de qualquer natureza.'),
            new TipoServicoISSQN(30304, 'Exploração de auditórios, casas de espetáculos e congêneres, para realização de eventos ou negócios de qualquer natureza.'),
            new TipoServicoISSQN(30305, 'Exploração de parques de diversões e congêneres, para realização de eventos ou negócios de qualquer natureza.'),
            new TipoServicoISSQN(30401, 'Locação, sublocação, arrendamento, direito de passagem ou permissão de uso, compartilhado ou não, de ferrovia.'),
            new TipoServicoISSQN(30402, 'Locação, sublocação, arrendamento, direito de passagem ou permissão de uso, compartilhado ou não, de rodovia.'),
            new TipoServicoISSQN(30403, 'Locação, sublocação, arrendamento, direito de passagem ou permissão de uso, compartilhado ou não, de postes, cabos, dutos e condutos de qualquer natureza.'),
            new TipoServicoISSQN(30501, 'Cessão de andaimes, palcos, coberturas e outras estruturas de uso temporário.'),
            new TipoServicoISSQN(40101, 'Medicina.'),
            new TipoServicoISSQN(40102, 'Biomedicina.'),
            new TipoServicoISSQN(40201, 'Análises clínicas e congêneres.'),
            new TipoServicoISSQN(40202, 'Patologia e congêneres.'),
            new TipoServicoISSQN(40203, 'Eletricidade médica (eletroestimulação de nervos e musculos, cardioversão, etc) e congêneres.'),
            new TipoServicoISSQN(40204, 'Radioterapia, quimioterapia e congêneres.'),
            new TipoServicoISSQN(40205, 'Ultra-sonografia, ressonância magnética, radiologia, tomografia e congêneres.'),
            new TipoServicoISSQN(40301, 'Hospitais e congêneres.'),
            new TipoServicoISSQN(40302, 'Laboratórios e congêneres.'),
            new TipoServicoISSQN(40303, 'Clínicas, sanatórios, manicômios, casas de saúde, prontos-socorros, ambulatórios e congêneres.'),
            new TipoServicoISSQN(40401, 'Instrumentação cirúrgica.'),
            new TipoServicoISSQN(40501, 'Acupuntura.'),
            new TipoServicoISSQN(40601, 'Enfermagem, inclusive serviços auxiliares.'),
            new TipoServicoISSQN(40701, 'Serviços farmacêuticos.'),
            new TipoServicoISSQN(40801, 'Terapia ocupacional.'),
            new TipoServicoISSQN(40802, 'Fisioterapia.'),
            new TipoServicoISSQN(40803, 'Fonoaudiologia.'),
            new TipoServicoISSQN(40901, 'Terapias de qualquer espécie destinadas ao tratamento físico, orgânico e mental.'),
            new TipoServicoISSQN(41001, 'Nutrição.'),
            new TipoServicoISSQN(41101, 'Obstetrícia.'),
            new TipoServicoISSQN(41201, 'Odontologia.'),
            new TipoServicoISSQN(41301, 'Ortóptica.'),
            new TipoServicoISSQN(41401, 'Próteses sob encomenda.'),
            new TipoServicoISSQN(41501, 'Psicanálise.'),
            new TipoServicoISSQN(41601, 'Psicologia.'),
            new TipoServicoISSQN(41701, 'Casas de repouso e congêneres.'),
            new TipoServicoISSQN(41702, 'Casas de recuperação e congêneres.'),
            new TipoServicoISSQN(41703, 'Creches e congêneres.'),
            new TipoServicoISSQN(41704, 'Asilos e congêneres.'),
            new TipoServicoISSQN(41801, 'Inseminação artificial, fertilização in vitro e congêneres.'),
            new TipoServicoISSQN(41901, 'Bancos de sangue, leite, pele, olhos, óvulos, sêmen e congêneres.'),
            new TipoServicoISSQN(42001, 'Coleta de sangue, leite, tecidos, sêmen, órgãos e materiais biológicos de qualquer espécie.'),
            new TipoServicoISSQN(42101, 'Unidade de atendimento, assistência ou tratamento móvel e congêneres.'),
            new TipoServicoISSQN(42201, 'Planos de medicina de grupo ou individual e convênios para prestação de assistência médica, hospitalar, odontológica e congêneres.'),
            new TipoServicoISSQN(42301, 'Outros planos de saúde que se cumpram através de serviços de terceiros contratados, credenciados, cooperados ou apenas pagos pelo operador do plano mediante indicação do beneficiário.'),
            new TipoServicoISSQN(50101, 'Medicina veterinária'),
            new TipoServicoISSQN(50102, 'Zootecnia.'),
            new TipoServicoISSQN(50201, 'Hospitais e congêneres, na área veterinária.'),
            new TipoServicoISSQN(50202, 'Clínicas, ambulatórios, prontos-socorros e congêneres, na área veterinária.'),
            new TipoServicoISSQN(50301, 'Laboratórios de análise na área veterinária.'),
            new TipoServicoISSQN(50401, 'Inseminação artificial, fertilização in vitro e congêneres.'),
            new TipoServicoISSQN(50501, 'Bancos de sangue e de órgãos e congêneres.'),
            new TipoServicoISSQN(50601, 'Coleta de sangue, leite, tecidos, sêmen, órgãos e materiais biológicos de qualquer espécie.'),
            new TipoServicoISSQN(50701, 'Unidade de atendimento, assistência ou tratamento móvel e congêneres.'),
            new TipoServicoISSQN(50801, 'Guarda, tratamento, amestramento, embelezamento, alojamento e congêneres.'),
            new TipoServicoISSQN(50901, 'Planos de atendimento e assistência médico-veterinária.'),
            new TipoServicoISSQN(60101, 'Barbearia, cabeleireiros, manicuros, pedicuros e congêneres.'),
            new TipoServicoISSQN(60201, 'Esteticistas, tratamento de pele, depilação e congêneres.'),
            new TipoServicoISSQN(60301, 'Banhos, duchas, sauna, massagens e congêneres.'),
            new TipoServicoISSQN(60401, 'Ginástica, dança, esportes, natação, artes marciais e demais atividades físicas.'),
            new TipoServicoISSQN(60501, 'Centros de emagrecimento, spa e congêneres.'),
            new TipoServicoISSQN(60601, 'Aplicação de tatuagens, piercings e congêneres.'),
            new TipoServicoISSQN(70101, 'Engenharia e congêneres.'),
            new TipoServicoISSQN(70102, 'Agronomia e congêneres.'),
            new TipoServicoISSQN(70103, 'Agrimensura e congêneres.'),
            new TipoServicoISSQN(70104, 'Arquitetura, urbanismo e congêneres.'),
            new TipoServicoISSQN(70105, 'Geologia e congêneres.'),
            new TipoServicoISSQN(70106, 'Paisagismo e congêneres.'),
            new TipoServicoISSQN(70201, 'Execução, por administração, de obras de construção civil, hidráulica ou elétrica e de outras obras semelhantes, inclusive sondagem, perfuração de poços, escavação, drenagem e irrigação, terraplanagem, pavimentação, concretagem e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).'),
            new TipoServicoISSQN(70202, 'Execução, por empreitada ou subempreitada, de obras de construção civil, hidráulica ou elétrica e de outras obras semelhantes, inclusive sondagem, perfuração de poços, escavação, drenagem e irrigação, terraplanagem, pavimentação, concretagem e a instalação e montagem de produtos, peças e equipamentos (exceto o fornecimento de mercadorias produzidas pelo prestador de serviços fora do local da prestação dos serviços, que fica sujeito ao ICMS).'),
            new TipoServicoISSQN(70301, 'Elaboração de planos diretores, estudos de viabilidade, estudos organizacionais e outros, relacionados com obras e serviços de engenharia.'),
            new TipoServicoISSQN(70302, 'Elaboração de anteprojetos, projetos básicos e projetos executivos para trabalhos de engenharia.'),
            new TipoServicoISSQN(70401, 'Demolição.'),
            new TipoServicoISSQN(70501, 'Reparação, conservação e reforma de edifícios e congêneres (exceto o fornecimento de mercadorias produzidas pelo prestador dos serviços, fora do local da prestação dos serviços, que fica sujeito ao ICMS).'),
            new TipoServicoISSQN(70502, 'Reparação, conservação e reforma de estradas, pontes, portos e congêneres (exceto o fornecimento de mercadorias produzidas pelo prestador dos serviços, fora do local da prestação dos serviços, que fica sujeito ao ICMS).'),
            new TipoServicoISSQN(70601, 'Colocação e instalação de tapetes, carpetes, cortinas e congêneres, com material fornecido pelo tomador do serviço.'),
            new TipoServicoISSQN(70602, 'Colocação e instalação de assoalhos, revestimentos de parede, vidros, divisórias, placas de gesso e congêneres, com material fornecido pelo tomador do serviço.'),
            new TipoServicoISSQN(70701, 'Recuperação, raspagem, polimento e lustração de pisos e congêneres.'),
            new TipoServicoISSQN(70801, 'Calafetação.'),
            new TipoServicoISSQN(70901, 'Varrição, coleta e remoção de lixo, rejeitos e outros resíduos quaisquer.'),
            new TipoServicoISSQN(70902, 'Incineração, tratamento, reciclagem, separação e destinação final de lixo, rejeitos e outros resíduos quaisquer.'),
            new TipoServicoISSQN(71001, 'Limpeza, manutenção e conservação de vias e logradouros públicos, parques, jardins e congêneres.'),
            new TipoServicoISSQN(71002, 'Limpeza, manutenção e conservação de imóveis, chaminés, piscinas e congêneres.'),
            new TipoServicoISSQN(71101, 'Decoração.'),
            new TipoServicoISSQN(71102, 'Jardinagem, inclusive corte e poda de árvores.'),
            new TipoServicoISSQN(71201, 'Controle e tratamento de efluentes de qualquer natureza e de agentes físicos, químicos e biológicos.'),
            new TipoServicoISSQN(71301, 'Dedetização, desinfecção, desinsetização, imunização, higienização, desratização, pulverização e congêneres.'),
            new TipoServicoISSQN(71601, 'Florestamento, reflorestamento, semeadura, adubação, reparação de solo, plantio, silagem, colheita, corte e descascamento de árvores, silvicultura, exploração florestal e dos serviços congêneres indissociáveis da formação, manutenção e colheita de florestas, para quaisquer fins e por quaisquer meios.'),
            new TipoServicoISSQN(71701, 'Escoramento, contenção de encostas e serviços congêneres.'),
            new TipoServicoISSQN(71801, 'Limpeza e dragagem de rios, portos, canais, baías, lagos, lagoas, represas, açudes e congêneres.'),
            new TipoServicoISSQN(71901, 'Acompanhamento e fiscalização da execução de obras de engenharia, arquitetura e urbanismo.'),
            new TipoServicoISSQN(72001, 'Aerofotogrametria (inclusive interpretação), cartografia, mapeamento e congêneres.'),
            new TipoServicoISSQN(72002, 'Levantamentos batimétricos, geográficos, geodésicos, geológicos, geofísicos e congêneres.'),
            new TipoServicoISSQN(72003, 'Levantamentos topográficos e congêneres.'),
            new TipoServicoISSQN(72101, 'Pesquisa, perfuração, cimentação, mergulho, perfilagem, concretação, testemunhagem, pescaria, estimulação e outros serviços relacionados com a exploração e explotação de petróleo, gás natural e de outros recursos minerais.'),
            new TipoServicoISSQN(72201, 'Nucleação e bombardeamento de nuvens e congêneres.'),
            new TipoServicoISSQN(80101, 'Ensino regular pré-escolar, fundamental e médio.'),
            new TipoServicoISSQN(80102, 'Ensino regular superior.'),
            new TipoServicoISSQN(80201, 'Instrução, treinamento, orientação pedagógica e educacional, avaliação de conhecimentos de qualquer natureza.'),
            new TipoServicoISSQN(90101, 'Hospedagem em hotéis, hotelaria marítima e congêneres (o valor da alimentação e gorjeta, quando incluído no preço da diária, fica sujeito ao Imposto Sobre Serviços).'),
            new TipoServicoISSQN(90102, 'Hospedagem em pensões, albergues, pousadas, hospedarias, ocupação por temporada com fornecimento de serviços e congêneres (o valor da alimentação e gorjeta, quando incluído no preço da diária, fica sujeito ao Imposto Sobre Serviços).'),
            new TipoServicoISSQN(90103, 'Hospedagem em motéis e congêneres (o valor da alimentação e gorjeta, quando incluído no preço da diária, fica sujeito ao Imposto Sobre Serviços).'),
            new TipoServicoISSQN(90104, 'Hospedagem em apart-service condominiais, flat, apart-hotéis, hotéis residência, residence-service, suite service e congêneres (o valor da alimentação e gorjeta, quando incluído no preço da diária, fica sujeito ao Imposto Sobre Serviços).'),
            new TipoServicoISSQN(90201, 'Agenciamento e intermediação de programas de turismo, passeios, viagens, excursões, hospedagens e congêneres.'),
            new TipoServicoISSQN(90202, 'Organização, promoção e execução de programas de turismo, passeios, viagens, excursões, hospedagens e congêneres.'),
            new TipoServicoISSQN(90301, 'Guias de turismo.'),
            new TipoServicoISSQN(100101, 'Agenciamento, corretagem ou intermediação de câmbio.'),
            new TipoServicoISSQN(100102, 'Agenciamento, corretagem ou intermediação de seguros.'),
            new TipoServicoISSQN(100103, 'Agenciamento, corretagem ou intermediação de cartões de crédito.'),
            new TipoServicoISSQN(100104, 'Agenciamento, corretagem ou intermediação de planos de saúde.'),
            new TipoServicoISSQN(100105, 'Agenciamento, corretagem ou intermediação de planos de previdência privada.'),
            new TipoServicoISSQN(100201, 'Agenciamento, corretagem ou intermediação de títulos em geral e valores mobiliários.'),
            new TipoServicoISSQN(100202, 'Agenciamento, corretagem ou intermediação de contratos quaisquer.'),
            new TipoServicoISSQN(100301, 'Agenciamento, corretagem ou intermediação de direitos de propriedade industrial, artística ou literária.'),
            new TipoServicoISSQN(100401, 'Agenciamento, corretagem ou intermediação de contratos de arrendamento mercantil (leasing).'),
            new TipoServicoISSQN(100402, 'Agenciamento, corretagem ou intermediação de contratos de franquia (franchising).'),
            new TipoServicoISSQN(100403, 'Agenciamento, corretagem ou intermediação de faturização (factoring).'),
            new TipoServicoISSQN(100501, 'Agenciamento, corretagem ou intermediação de bens móveis ou imóveis, não abrangidos em outros itens ou subitens, por quaisquer meios.'),
            new TipoServicoISSQN(100502, 'Agenciamento, corretagem ou intermediação de bens móveis ou imóveis realizados no âmbito de Bolsas de Mercadorias e Futuros, por quaisquer meios.'),
            new TipoServicoISSQN(100601, 'Agenciamento marítimo.'),
            new TipoServicoISSQN(100701, 'Agenciamento de notícias.'),
            new TipoServicoISSQN(100801, 'Agenciamento de publicidade e propaganda, inclusive o agenciamento de veiculação por quaisquer meios.'),
            new TipoServicoISSQN(100901, 'Representação de qualquer natureza, inclusive comercial.'),
            new TipoServicoISSQN(101001, 'Distribuição de bens de terceiros.'),
            new TipoServicoISSQN(110101, 'Guarda e estacionamento de veículos terrestres automotores.'),
            new TipoServicoISSQN(110102, 'Guarda e estacionamento de aeronaves e de embarcações.'),
            new TipoServicoISSQN(110201, 'Vigilância, segurança ou monitoramento de bens, pessoas e semoventes.'),
            new TipoServicoISSQN(110301, 'Escolta, inclusive de veículos e cargas.'),
            new TipoServicoISSQN(110401, 'Armazenamento, depósito, guarda de bens de qualquer espécie.'),
            new TipoServicoISSQN(110402, 'Carga, descarga, arrumação de bens de qualquer espécie.'),
            new TipoServicoISSQN(120101, 'Espetáculos teatrais.'),
            new TipoServicoISSQN(120201, 'Exibições cinematográficas.'),
            new TipoServicoISSQN(120301, 'Espetáculos circenses.'),
            new TipoServicoISSQN(120401, 'Programas de auditório.'),
            new TipoServicoISSQN(120501, 'Parques de diversões, centros de lazer e congêneres.'),
            new TipoServicoISSQN(120601, 'Boates, taxi-dancing e congêneres.'),
            new TipoServicoISSQN(120701, 'Shows, ballet, danças, desfiles, bailes, óperas, concertos, recitais, festivais e congêneres.'),
            new TipoServicoISSQN(120801, 'Feiras, exposições, congressos e congêneres.'),
            new TipoServicoISSQN(120901, 'Bilhares.'),
            new TipoServicoISSQN(120902, 'Boliches.'),
            new TipoServicoISSQN(120903, 'Diversões eletrônicas ou não.'),
            new TipoServicoISSQN(121001, 'Corridas e competições de animais.'),
            new TipoServicoISSQN(121101, 'Competições esportivas ou de destreza física ou intelectual, com ou sem a participação do espectador.'),
            new TipoServicoISSQN(121201, 'Execução de música.'),
            new TipoServicoISSQN(121301, 'Produção, mediante ou sem encomenda prévia, de eventos, espetáculos, entrevistas, shows, ballet, danças, desfiles, bailes, teatros, óperas, concertos, recitais, festivais e congêneres.'),
            new TipoServicoISSQN(121401, 'Fornecimento de música para ambientes fechados ou não, mediante transmissão por qualquer processo.'),
            new TipoServicoISSQN(121501, 'Desfiles de blocos carnavalescos ou folclóricos, trios elétricos e congêneres.'),
            new TipoServicoISSQN(121601, 'Exibição de filmes, entrevistas, musicais, espetáculos, shows, concertos, desfiles, óperas, competições esportivas, de destreza intelectual ou congêneres.'),
            new TipoServicoISSQN(121701, 'Recreação e animação, inclusive em festas e eventos de qualquer natureza.'),
            new TipoServicoISSQN(130201, 'Fonografia ou gravação de sons, inclusive trucagem, dublagem, mixagem e congêneres.'),
            new TipoServicoISSQN(130301, 'Fotografia e cinematografia, inclusive revelação, ampliação, cópia, reprodução, trucagem e congêneres.'),
            new TipoServicoISSQN(130401, 'Reprografia, microfilmagem e digitalização.'),
            new TipoServicoISSQN(130501, 'Composição gráfica, inclusive confecção de impressos gráficos, fotocomposição, clicheria, zincografia, litografia e fotolitografia, exceto se destinados a posterior operação de comercialização ou industrialização, ainda que incorporados, de qualquer forma, a outra mercadoria que deva ser objeto de posterior circulação, tais como bulas, rótulos, etiquetas, caixas, cartuchos, embalagens e manuais técnicos e de instrução, quando ficarão sujeitos ao ICMS.'),
            new TipoServicoISSQN(140101, 'Lubrificação, limpeza, lustração, revisão, carga e recarga, conserto, restauração, blindagem, manutenção e conservação de máquinas, veículos, aparelhos, equipamentos, motores, elevadores ou de qualquer objeto (exceto peças e partes empregadas, que ficam sujeitas ao ICMS).'),
            new TipoServicoISSQN(140201, 'Assistência técnica.'),
            new TipoServicoISSQN(140301, 'Recondicionamento de motores (exceto peças e partes empregadas, que ficam sujeitas ao ICMS).'),
            new TipoServicoISSQN(140401, 'Recauchutagem ou regeneração de pneus.'),
            new TipoServicoISSQN(140501, 'Restauração, recondicionamento, acondicionamento, pintura, beneficiamento, lavagem, secagem, tingimento, galvanoplastia, anodização, corte, recorte, plastificação, costura, acabamento, polimento e congêneres de objetos quaisquer.'),
            new TipoServicoISSQN(140601, 'Instalação e montagem de aparelhos, máquinas e equipamentos, inclusive montagem industrial, prestados ao usuário final, exclusivamente com material por ele fornecido.'),
            new TipoServicoISSQN(140701, 'Colocação de molduras e congêneres.'),
            new TipoServicoISSQN(140801, 'Encadernação, gravação e douração de livros, revistas e congêneres.'),
            new TipoServicoISSQN(140901, 'Alfaiataria e costura, quando o material for fornecido pelo usuário final, exceto aviamento.'),
            new TipoServicoISSQN(141001, 'Tinturaria e lavanderia.'),
            new TipoServicoISSQN(141101, 'Tapeçaria e reforma de estofamentos em geral.'),
            new TipoServicoISSQN(141201, 'Funilaria e lanternagem.'),
            new TipoServicoISSQN(141301, 'Carpintaria.'),
            new TipoServicoISSQN(141302, 'Serralheria.'),
            new TipoServicoISSQN(141401, 'Guincho intramunicipal.'),
            new TipoServicoISSQN(141402, 'Guindaste e içamento.'),
            new TipoServicoISSQN(150101, 'Administração de fundos quaisquer e congêneres.'),
            new TipoServicoISSQN(150102, 'Administração de consórcio e congêneres.'),
            new TipoServicoISSQN(150103, 'Administração de cartão de crédito ou débito e congêneres.'),
            new TipoServicoISSQN(150104, 'Administração de carteira de clientes e congêneres.'),
            new TipoServicoISSQN(150105, 'Administração de cheques pré-datados e congêneres.'),
            new TipoServicoISSQN(150201, 'Abertura de conta-corrente no País, bem como a manutenção da referida conta ativa e inativa.'),
            new TipoServicoISSQN(150202, 'Abertura de conta-corrente no exterior, bem como a manutenção da referida conta ativa e inativa.'),
            new TipoServicoISSQN(150203, 'Abertura de conta de investimentos e aplicação no País, bem como a manutenção da referida conta ativa e inativa.'),
            new TipoServicoISSQN(150204, 'Abertura de conta de investimentos e aplicação no exterior, bem como a manutenção da referida conta ativa e inativa.'),
            new TipoServicoISSQN(150205, 'Abertura de caderneta de poupança no País, bem como a manutenção da referida conta ativa e inativa.'),
            new TipoServicoISSQN(150206, 'Abertura de caderneta de poupança no exterior, bem como a manutenção da referida conta ativa e inativa.'),
            new TipoServicoISSQN(150207, 'Abertura de contas em geral no País, não abrangida em outro subitem, bem como a manutenção das referidas contas ativas e inativas.'),
            new TipoServicoISSQN(150208, 'Abertura de contas em geral no exterior, não abrangida em outro subitem, bem como a manutenção das referidas contas ativas e inativas.'),
            new TipoServicoISSQN(150301, 'Locação de cofres particulares.'),
            new TipoServicoISSQN(150302, 'Manutenção de cofres particulares.'),
            new TipoServicoISSQN(150303, 'Locação de terminais eletrônicos.'),
            new TipoServicoISSQN(150304, 'Manutenção de terminais eletrônicos.'),
            new TipoServicoISSQN(150305, 'Locação de terminais de atendimento.'),
            new TipoServicoISSQN(150306, 'Manutenção de terminais de atendimento.'),
            new TipoServicoISSQN(150307, 'Locação de bens e equipamentos em geral.'),
            new TipoServicoISSQN(150308, 'Manutenção de bens e equipamentos em geral.'),
            new TipoServicoISSQN(150401, 'Fornecimento ou emissão de atestados em geral, inclusive atestado de idoneidade, atestado de capacidade financeira e congêneres.'),
            new TipoServicoISSQN(150501, 'Cadastro, elaboração de ficha cadastral, renovação cadastral e congêneres.'),
            new TipoServicoISSQN(150502, 'Inclusão no Cadastro de Emitentes de Cheques sem Fundos - CCF.'),
            new TipoServicoISSQN(150503, 'Exclusão no Cadastro de Emitentes de Cheques sem Fundos - CCF.'),
            new TipoServicoISSQN(150504, 'Inclusão em quaisquer outros bancos cadastrais.'),
            new TipoServicoISSQN(150505, 'Exclusão em quaisquer outros bancos cadastrais.'),
            new TipoServicoISSQN(150601, 'Emissão, reemissão e fornecimento de avisos, comprovantes e documentos em geral'),
            new TipoServicoISSQN(150602, 'Abono de firmas.'),
            new TipoServicoISSQN(150603, 'Coleta e entrega de documentos, bens e valores.'),
            new TipoServicoISSQN(150604, 'Comunicação com outra agência ou com a administração central.'),
            new TipoServicoISSQN(150605, 'Licenciamento eletrônico de veículos.'),
            new TipoServicoISSQN(150606, 'Transferência de veículos.'),
            new TipoServicoISSQN(150607, 'Agenciamento fiduciário ou depositário.'),
            new TipoServicoISSQN(150608, 'Devolução de bens em custódia.'),
            new TipoServicoISSQN(150701, 'Acesso, movimentação, atendimento e consulta a contas em geral, por qualquer meio ou processo, inclusive por telefone, fac-símile, internet e telex.'),
            new TipoServicoISSQN(150702, 'Acesso a terminais de atendimento, inclusive vinte e quatro horas.'),
            new TipoServicoISSQN(150703, 'Acesso a outro banco e à rede compartilhada.'),
            new TipoServicoISSQN(150704, 'Fornecimento de saldo, extrato e demais informações relativas a contas em geral, por qualquer meio ou processo.'),
            new TipoServicoISSQN(150801, 'Emissão, reemissão, alteração, cessão, substituição, cancelamento e registro de contrato de crédito.'),
            new TipoServicoISSQN(150802, 'Estudo, análise e avaliação de operações de crédito.'),
            new TipoServicoISSQN(150803, 'Emissão, concessão, alteração ou contratação de aval, fiança, anuência e congêneres.'),
            new TipoServicoISSQN(150804, 'Serviços relativos à abertura de crédito, para quaisquer fins.'),
            new TipoServicoISSQN(150901, 'Arrendamento mercantil (leasing) de quaisquer bens, inclusive cessão de direitos e obrigações, substituição de garantia, alteração, cancelamento e registro de contrato, e demais serviços relacionados ao arrendamento mercantil (leasing).'),
            new TipoServicoISSQN(151001, 'Serviços relacionados a cobranças em geral, de títulos quaisquer, de contas ou carnês, de câmbio, de tributos e por conta de terceiros, inclusive os efetuados por meio eletrônico, automático ou por máquinas de atendimento.'),
            new TipoServicoISSQN(151002, 'Serviços relacionados a recebimentos em geral, de títulos quaisquer, de contas ou carnês, de câmbio, de tributos e por conta de terceiros, inclusive os efetuados por meio eletrônico, automático ou por máquinas de atendimento.'),
            new TipoServicoISSQN(151003, 'Serviços relacionados a pagamentos em geral, de títulos quaisquer, de contas ou carnês, de câmbio, de tributos e por conta de terceiros, inclusive os efetuados por meio eletrônico, automático ou por máquinas de atendimento.'),
            new TipoServicoISSQN(151004, 'Serviços relacionados a fornecimento de posição de cobrança, recebimento ou pagamento.'),
            new TipoServicoISSQN(151005, 'Serviços relacionados a emissão de carnês, fichas de compensação, impressos e documentos em geral.'),
            new TipoServicoISSQN(151101, 'Devolução de títulos, protesto de títulos, sustação de protesto, manutenção de títulos, reapresentação de títulos, e demais serviços a eles relacionados.'),
            new TipoServicoISSQN(151201, 'Custódia em geral, inclusive de títulos e valores mobiliários.'),
            new TipoServicoISSQN(151301, 'Serviços relacionados a operações de câmbio em geral, edição, alteração, prorrogação, cancelamento e baixa de contrato de câmbio.'),
            new TipoServicoISSQN(151302, 'Serviços relacionados a emissão de registro de exportação ou de crédito.'),
            new TipoServicoISSQN(151303, 'Serviços relacionados a cobrança ou depósito no exterior.'),
            new TipoServicoISSQN(151304, 'Serviços relacionados a emissão, fornecimento e cancelamento de cheques de viagem.'),
            new TipoServicoISSQN(151305, 'Serviços relacionados a fornecimento, transferência, cancelamento e demais serviços relativos a carta de crédito de importação, exportação e garantias recebidas.'),
            new TipoServicoISSQN(151306, 'Serviços relacionados a envio e recebimento de mensagens em geral relacionadas a operações de câmbio.'),
            new TipoServicoISSQN(151401, 'Fornecimento, emissão, reemissão de cartão magnético, cartão de crédito, cartão de débito, cartão salário e congêneres.'),
            new TipoServicoISSQN(151402, 'Renovação de cartão magnético, cartão de crédito, cartão de débito, cartão salário e congêneres.'),
            new TipoServicoISSQN(151403, 'Manutenção de cartão magnético, cartão de crédito, cartão de débito, cartão salário e congêneres.'),
            new TipoServicoISSQN(151501, 'Compensação de cheques e títulos quaisquer.'),
            new TipoServicoISSQN(151502, 'Serviços relacionados a depósito, inclusive depósito identificado, a saque de contas quaisquer, por qualquer meio ou processo, inclusive em terminais eletrônicos e de atendimento.'),
            new TipoServicoISSQN(151601, 'Emissão, reemissão, liquidação, alteração, cancelamento e baixa de ordens de pagamento, ordens de crédito e similares, por qualquer meio ou processo.'),
            new TipoServicoISSQN(151602, 'Serviços relacionados à transferência de valores, dados, fundos, pagamentos e similares, inclusive entre contas em geral.'),
            new TipoServicoISSQN(151701, 'Emissão e fornecimento de cheques quaisquer, avulso ou por talão.'),
            new TipoServicoISSQN(151702, 'Devolução de cheques quaisquer, avulso ou por talão.'),
            new TipoServicoISSQN(151703, 'Sustação, cancelamento e oposição de cheques quaisquer, avulso ou por talão.'),
            new TipoServicoISSQN(151801, 'Serviços relacionados a crédito imobiliário, de avaliação e vistoria de imóvel ou obra.'),
            new TipoServicoISSQN(151802, 'Serviços relacionados a crédito imobiliário, de análise técnica e jurídica.'),
            new TipoServicoISSQN(151803, 'Serviços relacionados a crédito imobiliário, de emissão, reemissão, alteração, transferência e renegociação de contrato.'),
            new TipoServicoISSQN(151804, 'Serviços relacionados a crédito imobiliário, de emissão e reemissão do termo de quitação.'),
            new TipoServicoISSQN(151805, 'Demais serviços relacionados a crédito imobiliário.'),
            new TipoServicoISSQN(160101, 'Serviços de transporte coletivo municipal rodoviário de passageiros.'),
            new TipoServicoISSQN(160102, 'Serviços de transporte coletivo municipal metroviário de passageiros.'),
            new TipoServicoISSQN(160103, 'Serviços de transporte coletivo municipal ferroviário de passageiros.'),
            new TipoServicoISSQN(160104, 'Serviços de transporte coletivo municipal aquaviário de passageiros.'),
            new TipoServicoISSQN(160201, 'Outros serviços de transporte de natureza municipal.'),
            new TipoServicoISSQN(170101, 'Assessoria ou consultoria de qualquer natureza, não contida em outros itens desta lista.'),
            new TipoServicoISSQN(170102, 'Análise, exame, pesquisa, coleta, compilação e fornecimento de dados e informações de qualquer natureza, inclusive cadastro e similares.'),
            new TipoServicoISSQN(170201, 'Datilografia, digitação, estenografia e congêneres.'),
            new TipoServicoISSQN(170202, 'Expediente, secretaria em geral, apoio e infra-estrutura administrativa e congêneres.'),
            new TipoServicoISSQN(170203, 'Resposta audível e congêneres.'),
            new TipoServicoISSQN(170204, 'Redação, edição, revisão e congêneres.'),
            new TipoServicoISSQN(170205, 'Interpretação, tradução e congêneres.'),
            new TipoServicoISSQN(170301, 'Planejamento, coordenação, programação ou organização técnica.'),
            new TipoServicoISSQN(170302, 'Planejamento, coordenação, programação ou organização financeira.'),
            new TipoServicoISSQN(170303, 'Planejamento, coordenação, programação ou organização administrativa.'),
            new TipoServicoISSQN(170401, 'Recrutamento, agenciamento, seleção e colocação de mão-de-obra.'),
            new TipoServicoISSQN(170501, 'Fornecimento de mão-de-obra, mesmo em caráter temporário, inclusive de empregados ou trabalhadores, avulsos ou temporários, contratados pelo prestador de serviço.'),
            new TipoServicoISSQN(170601, 'Propaganda e publicidade, inclusive promoção de vendas, planejamento de campanhas ou sistemas de publicidade, elaboração de desenhos, textos e demais materiais publicitários.'),
            new TipoServicoISSQN(170801, 'Franquia (franchising).'),
            new TipoServicoISSQN(170901, 'Perícias, laudos, exames técnicos e análises técnicas.'),
            new TipoServicoISSQN(171001, 'Planejamento, organização e administração de feiras, exposições, e congêneres.'),
            new TipoServicoISSQN(171002, 'Planejamento, organização e administração de congressos e congêneres.'),
            new TipoServicoISSQN(171101, 'Organização de festas e recepções.'),
            new TipoServicoISSQN(171102, 'Bufê (exceto o fornecimento de alimentação e bebidas, que fica sujeito ao ICMS).'),
            new TipoServicoISSQN(171201, 'Administração em geral, inclusive de bens e negócios de terceiros.'),
            new TipoServicoISSQN(171301, 'Leilão e congêneres.'),
            new TipoServicoISSQN(171401, 'Advocacia'),
            new TipoServicoISSQN(171501, 'Arbitragem de qualquer espécie, inclusive jurídica.'),
            new TipoServicoISSQN(171601, 'Auditoria.'),
            new TipoServicoISSQN(171701, 'Análise de Organização e Métodos.'),
            new TipoServicoISSQN(171801, 'Atuária e cálculos técnicos de qualquer natureza.'),
            new TipoServicoISSQN(171901, 'Contabilidade, inclusive serviços técnicos e auxiliares.'),
            new TipoServicoISSQN(172001, 'Consultoria e assessoria econômica ou financeira.'),
            new TipoServicoISSQN(172101, 'Estatística.'),
            new TipoServicoISSQN(172201, 'Cobrança em geral.'),
            new TipoServicoISSQN(172301, 'Assessoria, análise, avaliação, atendimento, consulta, cadastro, seleção, gerenciamento de informações, administração de contas a receber ou a pagar e em geral, relacionados a operações de faturização (factoring).'),
            new TipoServicoISSQN(172401, 'Apresentação de palestras, conferências, seminários e congêneres.'),
            new TipoServicoISSQN(172501, 'Inserção de textos, desenhos e outros materiais de propaganda e publicidade, em qualquer meio (exceto em livros, jornais, periódicos e nas modalidades de serviços de radiodifusão sonora e de sons e imagens de recepção livre e gratuita).'),
            new TipoServicoISSQN(180101, 'Serviços de regulação de sinistros vinculados a contratos de seguros e congêneres.'),
            new TipoServicoISSQN(180102, 'Serviços de inspeção e avaliação de riscos para cobertura de contratos de seguros e congêneres.'),
            new TipoServicoISSQN(180103, 'Serviços de prevenção e gerência de riscos seguráveis e congêneres.'),
            new TipoServicoISSQN(190101, 'Serviços de distribuição e venda de bilhetes e demais produtos de loteria, cartões, pules ou cupons de apostas, sorteios, prêmios, inclusive os decorrentes de títulos de capitalização e congêneres.'),
            new TipoServicoISSQN(190102, 'Serviços de distribuição e venda de bingos e congêneres.'),
            new TipoServicoISSQN(200101, 'Serviços portuários, ferroportuários, utilização de porto, movimentação de passageiros, reboque de embarcações, rebocador escoteiro, atracação, desatracação, serviços de praticagem, capatazia, armazenagem de qualquer natureza, serviços acessórios, movimentação de mercadorias, serviços de apoio marítimo, de movimentação ao largo, serviços de armadores, estiva, conferência, logística e congêneres. (prestado em terra)'),
            new TipoServicoISSQN(200102, 'Serviços portuários, ferroportuários, utilização de porto, movimentação de passageiros, reboque de embarcações, rebocador escoteiro, atracação, desatracação, serviços de praticagem, capatazia, armazenagem de qualquer natureza, serviços acessórios, movimentação de mercadorias, serviços de apoio marítimo, de movimentação ao largo, serviços de armadores, estiva, conferência, logística e congêneres. (prestado em águas marinhas)'),
            new TipoServicoISSQN(200201, 'Serviços aeroportuários, utilização de aeroporto, movimentação de passageiros, armazenagem de qualquer natureza, capatazia, movimentação de aeronaves, serviços de apoio aeroportuários, serviços acessórios, movimentação de mercadorias, logística e congêneres.'),
            new TipoServicoISSQN(200301, 'Serviços de terminais rodoviários, ferroviários, metroviários, movimentação de passageiros, mercadorias, inclusive suas operações, logística e congêneres.'),
            new TipoServicoISSQN(210101, 'Serviços de registros públicos, cartorários e notariais.'),
            new TipoServicoISSQN(220101, 'Serviços de exploração de rodovia mediante cobrança de preço ou pedágio dos usuários, envolvendo execução de serviços de conservação, manutenção, melhoramentos para adequação de capacidade e segurança de trânsito, operação, monitoração, assistência aos usuários e outros serviços definidos em contratos, atos de concessão ou de permissão ou em normas oficiais.'),
            new TipoServicoISSQN(230101, 'Serviços de programação e comunicação visual e congêneres.'),
            new TipoServicoISSQN(230102, 'Serviços de desenho industrial e congêneres.'),
            new TipoServicoISSQN(240101, 'Serviços de chaveiros, confecção de carimbos e congêneres.'),
            new TipoServicoISSQN(240102, 'Serviços de placas, sinalização visual, banners, adesivos e congêneres.'),
            new TipoServicoISSQN(250101, 'Funerais, inclusive fornecimento de caixão, urna ou esquifes; aluguel de capela; transporte do corpo cadavérico; fornecimento de flores, coroas e outros paramentos; desembaraço de certidão de óbito; fornecimento de véu, essa e outros adornos; embalsamento, embelezamento, conservação ou restauração de cadáveres.'),
            new TipoServicoISSQN(250201, 'Translado intramunicipal de corpos e partes de corpos cadavéricos.'),
            new TipoServicoISSQN(250202, 'Cremação de corpos e partes de corpos cadavéricos.'),
            new TipoServicoISSQN(250301, 'Planos ou convênio funerários.'),
            new TipoServicoISSQN(250401, 'Manutenção e conservação de jazigos e cemitérios.'),
            new TipoServicoISSQN(250501, 'Cessão de uso de espaços em cemitérios para sepultamento.'),
            new TipoServicoISSQN(260101, 'Serviços de coleta, remessa ou entrega de correspondências, documentos, objetos, bens ou valores, inclusive pelos correios e suas agências franqueadas.'),
            new TipoServicoISSQN(260102, 'Serviços de courrier e congêneres.'),
            new TipoServicoISSQN(270101, 'Serviços de assistência social.'),
            new TipoServicoISSQN(280101, 'Serviços de avaliação de bens e serviços de qualquer natureza.'),
            new TipoServicoISSQN(290101, 'Serviços de biblioteconomia.'),
            new TipoServicoISSQN(300101, 'Serviços de biologia e biotecnologia.'),
            new TipoServicoISSQN(300102, 'Serviços de química.'),
            new TipoServicoISSQN(310101, 'Serviços técnicos em edificações e congêneres.'),
            new TipoServicoISSQN(310102, 'Serviços técnicos em eletrônica, eletrotécnica e congêneres.'),
            new TipoServicoISSQN(310103, 'Serviços técnicos em mecânica e congêneres.'),
            new TipoServicoISSQN(310104, 'Serviços técnicos em telecomunicações e congêneres.'),
            new TipoServicoISSQN(320101, 'Serviços de desenhos técnicos.'),
            new TipoServicoISSQN(330101, 'Serviços de desembaraço aduaneiro, comissários, despachantes e congêneres.'),
            new TipoServicoISSQN(340101, 'Serviços de investigações particulares, detetives e congêneres.'),
            new TipoServicoISSQN(350101, 'Serviços de reportagem e jornalismo.'),
            new TipoServicoISSQN(350102, 'Serviços de assessoria de imprensa.'),
            new TipoServicoISSQN(350103, 'Serviços de relações públicas.'),
            new TipoServicoISSQN(360101, 'Serviços de meteorologia.'),
            new TipoServicoISSQN(370101, 'Serviços de artistas, atletas, modelos e manequins.'),
            new TipoServicoISSQN(380101, 'Serviços de museologia.'),
            new TipoServicoISSQN(390101, 'Serviços de ourivesaria e lapidação (quando o material for fornecido pelo tomador do serviço).'),
            new TipoServicoISSQN(400101, 'Obras de arte sob encomenda.'),
            new TipoServicoISSQN(990101, 'Serviços sem a incidência de ISSQN e ICMS'),
        ]
    }
}