import { Component, OnInit, Input, ViewEncapsulation, OnChanges, Output, EventEmitter } from '@angular/core';
import { getDeviceType } from '@utils';

@Component({
  selector: 'app-modal-basic',
  templateUrl: './modal-basic.component.html',
  styleUrls: ['./modal-basic.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ModalBasicComponent implements OnInit, OnChanges {
  @Input() dialogClass: string;
  @Input() dialogClassMobile: string;
  @Input() dialogClassContent: string;
  @Input() dialogClassContentMobile: string;
  @Input() modalContentId = '';
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  @Input() showNow = false;
  @Input() allowClickOutside = true;
  @Input() public visible = false;
  @Input() borderless = false;
  public visibleAnimate = false;
  @Input() hideNow = false;

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() scrolled = new EventEmitter();

  ngOnChanges(e) {
    if (e.showNow !== undefined) {
      if (e.showNow.currentValue) {
        this.show();
      } else {
        this.hide();
      }
    }

    if (e.hideNow !== undefined) {
      if (e.hideNow.currentValue) {
        this.hide();
      }
    }
  }

  ngOnInit() {
    if (this.showNow) {
      this.show();
    }
  }

  onScroll(e) {
    this.scrolled.emit(e);
  }

  public show(): void {
    this.visible = true;
    setTimeout(() => this.visibleAnimate = true, 100);
    this.opened.emit();
  }

  public hide(): void {
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 300);
    this.closed.emit(this.visible);
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((<HTMLElement>event.target).classList.contains('modal') && this.allowClickOutside) {
      this.hide();
    }
  }

  get getClass() {
    if (getDeviceType() === 'mobile' && this.dialogClassMobile) {
      return this.dialogClassMobile;
    }

    return this.dialogClass;
  }

  get getClassContent() {
    if (getDeviceType() === 'mobile' && this.dialogClassContentMobile) {
      return this.dialogClassMobile;
    }

    return this.dialogClassContent;
  }

}
