import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseServiceV2 } from '@servicesv2';
import { PedidosVieWModel } from 'models/pedidos/PedidosViewModel';

@Injectable()
export class PedidosServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('Pedidos', http);
  }

  getPedidosCliente(clienteId: number) {
    return this.getAsPromise<PedidosVieWModel[]>(`GetPedidosCliente/${clienteId}`);
  }
}
