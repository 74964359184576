import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { TipoDespesaService } from '../../../services/tipo-despesa.service';
import { GrupoDespesasService } from '../../../services/grupo-despesas.service';
import { ContascorrentesService } from '../../../services/contascorrentes.service';
import { setFieldFormValue, setFormValue } from '../../../general/utils';
import { Subscription } from 'rxjs';
import { AbrirModalComponent } from '../../../shared/abrir-modal/abrir-modal.component';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-tipo-despesa',
  templateUrl: './tipo-despesa.component.html',
  styleUrls: ['./tipo-despesa.component.css']
})
export class TipoDespesaComponent extends ModalCadastroComponent implements OnInit {

  private modalSubscription: Subscription;
  @ViewChild('abrirModal', { static: true }) abrirModal: AbrirModalComponent;
  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  grupoDespesas = [];
  contas = [];
  id;

  @Output() closed: EventEmitter<any> = new EventEmitter();

  constructor(private tipoDespesaService: TipoDespesaService, private grupoDespesasService: GrupoDespesasService,
    private contasService: ContascorrentesService) {
    super();
  }

  cadastroForm = new FormGroup({
    id: new FormControl(0),
    descricao: new FormControl(null, [Validators.required]),
    grupoDespesaId: new FormControl(null),
    contaPagadoraId: new FormControl(null),
  });

  ngOnInit() {
    this.carregaContasCorrentes();
    this.carregaGrupoDespesas();
  }

  carregaGrupoDespesas() {
    this.loading['grupoDespesas'] = true;
    this.grupoDespesasService.get()
      .pipe(finalize(() => this.loading['grupoDespesas'] = false))
      .subscribe(res => {
        this.grupoDespesas = res;
      });
  }

  carregaContasCorrentes() {
    this.loading['contas'] = true;
    this.contasService.get()
      .pipe(finalize(() => this.loading['contas'] = false))
      .subscribe(res => {
        this.contas = res;
      });
  }

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    setFieldFormValue(this.cadastroForm, 'descricao', searchParam);
    this.modalCadastro.show();
  }

  carregaDados() {
    if (this.id) {
      this.alteraLoading(true);
      this.tipoDespesaService.getTipoDespesaById(this.id)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe((res) => {
          setFormValue(this.cadastroForm, res);
        });
    }
  }

  modalGrupoDespesaClosed(id) {
    this.modalSubscription.unsubscribe();
    this.carregaGrupoDespesas();
    setFieldFormValue(this.cadastroForm, 'grupoDespesaId', id);
  }

  modalContaCorrenteClosed(id) {
    this.modalSubscription.unsubscribe();
    this.carregaContasCorrentes();
    setFieldFormValue(this.cadastroForm, 'contaPagadoraId', id);
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      this.alteraLoading(true);
      this.tipoDespesaService.post(this.cadastroForm.value)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe(res => {
          this.id = res.generatedId || null;
          this.modalCadastro.hide();
        }, (error) => {
          this.errors = error.msg.errors;
        });
    }
  }

  subscribeModal(modal: string) {
    switch (modal) {
      case 'contaCorrente':
        this.modalSubscription = this.abrirModal.closed.subscribe(this.modalContaCorrenteClosed.bind(this));
        break;
      case 'grupoDespesa':
        this.modalSubscription = this.abrirModal.closed.subscribe(this.modalGrupoDespesaClosed.bind(this));
        break;
    }
  }
}
