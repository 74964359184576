import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import * as moment from 'moment';
import { TitulosCobrancaService } from '../../../services/titulos-cobranca.service';
import { MessageService } from '../../../services/message.service';
import swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-replica-parcelas-cobranca',
  templateUrl: './replica-parcelas-cobranca.component.html',
  styleUrls: ['./replica-parcelas-cobranca.component.css']
})
export class ReplicaParcelasCobrancaComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalReplicacao", { static: true }) modalReplicacao;

  @Input() primeiroVencimento;
  @Input() tituloBase: any = null;
  calendarPosition = "bottom";

  parcelamentos = [];
  quantidade: number;

  constructor(
    private titulosCobrancaService: TitulosCobrancaService,
    private messageService: MessageService
  ) {
    super();
  }

  ngOnInit() {
  }

  show() {
    this.modalReplicacao.show();
  }

  isLastItem(totalParcelamento: number, index: number) {
    return (index >= totalParcelamento - 3) ? 'top' : 'bottom';
  }
  atualizarParcelas(valor: number) {
    swal({
      html: '<small>Deseja replicar o valor às demais parcelas?</small><br />',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: '<small>Sim</small>',
      cancelButtonText: '<small>Não</small>'
    }).then((result) => {
      if (result.value) {
        this.gerarParcelas(this.quantidade, valor);
      }
    });
  }

  gerarParcelas(quantidade: number, valorBase = null) {
    this.parcelamentos = [];
    this.quantidade = quantidade;
    for (let i = 0; i < quantidade; i++) {
      const parcela = Object.assign({}, this.tituloBase);
      const sequenciaParcela = Number(this.tituloBase.parcela.toString().replace(/[^0-9]+/g, "")) || 1;
      parcela.parcela = sequenciaParcela + i;
      parcela.vencimento = moment(this.primeiroVencimento).add(i, 'months').startOf('day').toISOString();

      if (valorBase) {
        parcela.valorParcela = valorBase;
      }

      this.parcelamentos.push(parcela);
    }
  }

  salvarTitulos() {
    this.alteraLoading(true, 'request');
    this.titulosCobrancaService.postMultipleForm(this.parcelamentos)
      .pipe(finalize(() => this.alteraLoading(false, 'request')))
      .subscribe(() => {
        this.closed.emit(true);
        this.messageService.success();
        this.modalReplicacao.hide();
      }, error => {
        this.errors = error.msg.errors;
      });
  }

  get ocultarEmTelaMenor() {
    return "d-none d-lg-table-cell";
  }
}
