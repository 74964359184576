<div class="checkbox-fade fade-in-default">
  <label [container]="tooltipContainer" [placement]="tooltipPlacement" [ngbTooltip]="tooltip">
    <input type="checkbox" [checked]="checked" (change)="onCheckboxChange($event.target.checked)">
    <span class="cr">
      <i class="cr-icon icofont icofont-ui-check txt-default"></i>
    </span>
    <span [appDimBoldText]="required" class="text-inverse">
      {{ label }}
      <i *ngIf="tooltipIcon" [ngClass]="tooltipIcon"></i>
    </span>
  </label>
</div>
