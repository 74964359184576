<div id="pcoded" (window:resize)="onResize($event)" class="pcoded iscollapsed" theme-layout="vertical"
  vertical-placement="left" vertical-layout="wide" [attr.pcoded-device-type]="deviceType"
  [attr.vertical-nav-type]="verticalNavType" [attr.vertical-effect]="verticalEffect" vnavigation-view="view1">
  <div class="pcoded-overlay-box"></div>
  <div class="pcoded-container navbar-wrapper">
    <nav class="navbar header-navbar pcoded-header" style="position:fixed; z-index: 1000;" header-theme="theme4">
      <div class="navbar-wrapper">
        <div class="navbar-logo" navbar-theme="theme5" [ngClass]="deviceType">
          <a class="mobile-menu" id="mobile-collapse" href="javascript:;"
            (click)="toggleOpened($event); onMobileMenu(true)" [exclude]="'#main_navbar'"
            (clickOutside)="onClickedOutside($event)">
            <i class="ti-menu menu-item"></i>
          </a>
          <div class="logo-slogan">
            <img class="img-fluid img-140" src="assets/images/Dim-App_PNG.png" alt="Theme-Logo" (click)="navegacaoLogo()"
            style="width: 120px;"/>
            <p (click)="navegacaoSlogan()">Porque inovar é essencial!</p>
          </div>
            <a (click)="onMobileMenu()" class="mobile-options">
              <i class="ti-more menu-item"></i>
            </a>
          </div>
          
          <div class="navbar-container">
            <div>
              <ul class="nav-left">
                <li>
                  <a href="javascript:;" appToggleFullscreen>
                    <i class="feather icon-size-fullscreen menu-item"></i>
                </a>
              </li>
            </ul>

            <ul [@mobileMenuTop]="isCollapsedMobile" class="nav-right" [ngClass]="menuClass"
              style="padding-inline: 10px; align-items: flex-start; background-color: var(--navbar-bg-color);" appendTo="body">

              <li class="header-notification" [ngClass]="{ 'activeSearchSize': searchClick }">
                <i (click)="toggleSearch()" class="fa fa-search search-btn"> </i>
                <input [ngClass]=" { 'activeSearch': searchClick }" class="search-input" type="text"
                  placeholder="Buscar no app" (click)="hideDropDown()" (clickOutside)="handleClickOutside()"
                  (keyup)="onSearch($event)">
                <div *ngIf="this.showDropdown" class="state-container-dropdown">
                  <div class="result" *ngFor="let item of search">
                    <ng-container [ngSwitch]="item.type">

                      <ng-container *ngSwitchCase="1">
                        <div class="result-path">
                          <span>{{getPath(item.caminho)}}</span>
                        </div>
                        <div class="result-items">
                          <div class="result-item">
                            <span (click)="onClickItem(item)">{{ item.nome }}</span>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="2">
                        <div class="result-path">
                          <span>{{getPath(item.caminho)}}</span>
                        </div>
                        <div class="result-items">
                          <div class="result-solo-item" *ngFor="let filho of item.filhos">
                            <span *ngIf="filho && !(filho.filhos && filho.filhos.length > 0); else elseBlock"
                              (click)="onClickItem(filho)">{{ filho.nome }}</span>

                            <ng-template #elseBlock>
                              <ng-container
                                *ngTemplateOutlet="recursive; context: { pai: filho.nome, filhoss: filho.filhos}">
                              </ng-container>

                              <ng-template #recursive let-pai="pai" let-filhoss="filhoss">
                                <div class="result-path">
                                  <span>{{getPath(item.caminho)}} > {{pai}}</span>
                                </div>
                                <div class="result-items">
                                  <div class="result-item" *ngFor="let filhoo of filhoss">
                                    <span *ngIf="!(filhoo.filhos && filhoo.filhos.length > 0); else res"
                                      (click)="onClickItem(filhoo)">{{filhoo.nome}}</span>
                                    <ng-template #res>
                                      <ng-container
                                        *ngTemplateOutlet="recursive; context: { pai: filhoo.nome, filhoss: filhoo.filhos}">
                                      </ng-container>
                                    </ng-template>
                                  </div>
                                </div>
                              </ng-template>
                            </ng-template>

                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngSwitchCase="3">
                        <div class="result-path">
                          <span>{{ item.btn }}</span>
                        </div>
                        <div class="result-items">
                          <div class="result-item">
                            <span (click)="onClickItem(item)">{{ item.nome }}</span>
                          </div>
                        </div>
                      </ng-container>

                    </ng-container>
                  </div>
                </div>

              </li>

              <li class="user-profile header-notification" style="cursor: pointer;"
                (clickOutside)="notificationnOutsideClick()" (click)="toggleNotification()">
                <i class="fa fa-bell-o notification-icon"
                  [ngClass]="{'hasNotification': notificacoes && notificacoesNaoLidas > 0}"
                  [ngbTooltip]="getNotification()" placement="bottom"></i>

                <ul class="mb-0 small-screen show-notification profile-notification notifications" [@notificationBottom]="notification"
                  [ngClass]="notificationClass" (scroll)="handleNotificationScroll($event)">
                  <span *ngIf="notificacoes && notificacoes.length > 0">
                    <li class="notification" *ngFor="let notificacao of notificacoes" (click)="detalhar(notificacao)">
                      <div class="text-space">
                        <span class="notification-title"
                          *ngIf="notificacao.titulo && notificacao.titulo != ''">{{notificacao.titulo}}</span>
                        <span class="notification-message">{{retornaMensagemNotificacao(notificacao.mensagem)}}</span>
                        <span class="notification-date">{{notificacao.data | date: 'dd/MM/yyy'}}</span>
                      </div>
                      <div class="alert-space" *ngIf="!notificacao.visualizada">
                        <span class="visualized-alert"></span>
                      </div>
                    </li>
                  </span>
                  <li class="loading-space text-center">
                    <i class="fa fa-circle-o-notch fa-spin" *ngIf="searchingNotifications"></i>
                  </li>
                </ul>
              </li>


              <li class="user-profile header-notification" style="cursor: pointer; padding-left: 20px; display: flex !important; align-items: center; align-self: stretch"
                (clickOutside)="notificationOutsideClick()" (click)="toggleProfileNotification()" id="userOptions">
                <img src="assets/images/user.png" alt="User-Profile-Image">
                <div style="display: flex; flex-direction: column; align-items: center">
                  <span class="menu-item" style="line-height: normal">{{nome}}</span>
                  <span style="color: var(--fatura-vencida-color); line-height: normal; font-size: 12px" *ngIf="proximaFatura && proximaFatura.vencido">Fatura vencida: {{proximaFatura.vencimento | date:'dd/MM/yyyy'}}</span>
                  <span style="color: var(--proxima-fatura-color); line-height: normal; font-size: 12px" *ngIf="proximaFatura && !proximaFatura.vencido">Próx. Fatura: {{proximaFatura.vencimento | date:'dd/MM/yyyy'}}</span>
                </div>
                <i class="ti-angle-down menu-item"></i>

                <ul class="show-notification profile-notification user-profile-menu" [@notificationBottom]="profileNotification"
                  [ngClass]="profileNotificationClass">
                  <ng-container *ngIf="menuItens && menuItens.menuDropDown">
                    <span *ngFor="let item of menuItens.menuDropDown" class="user-profile-menu__option" (click)="onMobileMenu()">
                      <li *ngIf="item.tipoDeLink == 'rota'" class="user-profile-menu-option__li" (click)="redirect(item.rota)">
                        <a style="padding: 0px" class="user-profile-menu-option__li__a">
                          <i class="{{item.icone}}"></i> {{item.nome}}
                        </a>
                      </li>
                      <li *ngIf="item.tipoDeLink == 'link'" class="user-profile-menu-option__li">
                        <a [href]="item.rota" style="padding: 0px" class="user-profile-menu-option__li__a">
                          <i class="{{item.icone}}"></i> {{item.nome}}
                        </a>
                      </li>
                      <li *ngIf="item.tipoDeLink == 'funcao'" class="user-profile-menu-option__li"
                        (click)="executeFunction({funcao:item.rota, parametro:item.parametro})">
                        <a style="padding: 0px" class="user-profile-menu-option__li__a">
                          <i class="{{item.icone}}"></i> {{item.nome}}
                          <small *ngIf="item.nome === 'Boleto Dim' && boletoDimAtivo" class="boleto-dim-ativo">(Ativo)</small>
                        </a>
                      </li>
                    </span>
                  </ng-container>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>

    <div class="pcoded-main-container" style="margin-top: 60px;">
      <div class="pcoded-wrapper" style="position: relative;">
        <nav id="main_navbar" style="position: fixed; background-color: var(--menu-lateral-bg-color-1);" class="pcoded-navbar transition"
          navbar-theme="theme1" active-item-theme="theme5" sub-item-theme="theme2" active-item-style="style0"
          pcoded-navbar-position="absolute" (clickOutside)="onClickedOutside($event)" [exclude]="'#mobile-collapse'"
          (mouseenter)="hoverMenu($event,'entro')" (mouseleave)="hoverMenu($event,'saiu')">
          <ng-scrollbar #scrollable
            track="vertical"
            pointerEventsMethod="scrollbar"
            [autoHeightDisabled]="true"
            [disabled]="perfectDisable"
            [style.max-width]="'200px'"
            [style.max-height]="'calc(94vh - 56px)'"
          >
            <div class="pcoded-inner-navbar main-menu" style="width: 100%; float: left;" appAccordion width="100%"
              height="100%" size="4px" color="#fff" opacity="0.3" allowPageScroll="false">
              <div style="min-height: 100%;" id="fatherMenuItens" *ngIf="menuItens">
                <div *ngIf="!edicao || verticalNavType == 'collapsed'" id="itensMenu">

                  <app-menu-item [menuItens]="menuItens.menuLateral" [menuType]="'father'"
                    (functionResponse)="executeFunction($event)" (hideMobileMenu)="hideMobileMenu($event)">
                  </app-menu-item>

                  <div style="padding: 10px;">
                    <i class="menuEdit ti ti-pencil" (click)="editar()"
                      style="color: #b9b9b9; cursor: pointer; font-size: 15px; float: right;"
                      *ngIf="!edicao && verticalNavType != 'collapsed'"></i>
                  </div>
                </div>

                <div *ngIf="edicao && verticalNavType != 'collapsed'" id="itensMenu">
                  <app-editar-menu-item [menuItens]="menuItens.menuLateral" [menuType]="'father'">
                  </app-editar-menu-item>

                  <div style="padding: 10px;">
                    <i class="menuEdit fa fa-check"
                      style="color: #1abc9c; cursor: pointer; font-size: 21px; float: left;" (click)="salvar()"
                      *ngIf="edicao && verticalNavType != 'collapsed'"></i>
                    <i class="menuEdit fa fa-times"
                      style="color: #e74c3c; cursor: pointer; font-size: 21px; float: right;" (click)="cancelar()"
                      *ngIf="edicao && verticalNavType != 'collapsed'"></i>
                  </div>
                </div>
              </div>
            </div>
            </ng-scrollbar>
            <div style="width: 100%;" id="logoMenuLateral" class="absoluteLogoMenu">
              <div *ngIf="verticalNavType === 'expanded'" class="dark-mode-botao">
                <input type="checkbox" id="darkModeSwitch" [(ngModel)]="isDarkMode" (change)="trocarTema()" />
                <label for="darkModeSwitch" class="botao-label">
                  <span class="switch"></span>
                  <span class="sun-icon"><i class="fa fa-sun-o"></i></span>
                  <span class="moon-icon"><i class="fa fa-moon-o"></i></span>
                </label>
              </div>
              <div class="d-flex justify-content-center m-1 pt-2 o-hidden" style="gap: 30px;">
                <a class="fa fa-instagram text-white social-icon" href="http://www.instagram.com/dimensaosistemas/"
                target="_blank"></a>
                <a class="fa fa-facebook text-white social-icon" href="http://www.facebook.com/dimensaosistemas"
                target="_blank"></a>
                <a class="fa fa-linkedin text-white social-icon"
                href="http://www.linkedin.com/in/dimens%C3%A3o-sistemas/" target="_blank"></a>
              </div>
            </div>
        </nav>
        <div style="height: 0px; width: 0px; position: fixed; left: 0px; z-index: 999;"
          class="pcoded-content transition" pcoded-navbar-position="absolute">
          <div class="newMenu newMenuHover" id="changeMenu" *ngIf="deviceType == 'desktop'"
            (click)="toggleOpened($event, true)" pcoded-navbar-position="absolute">
            <a href="javascript:;" class="newMenuHover" pcoded-navbar-position="absolute">
              <i class="ti-menu f-18 menu-item newMenuHover" style="font-size: 13px;"></i>
            </a>
          </div>
        </div>
        <div class="pcoded-content transition">

          <div class="pcoded-inner-content transition">
            <div class="main-body">
              <div>
                <div class="page-wrapper">
                  <app-title></app-title>
                  <div class="page-body">
                    <router-outlet>
                      <spinner></spinner>
                    </router-outlet>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-abrir-modal #abrirModal></app-abrir-modal>
<ngx-toasta [position]="'bottom-right'"></ngx-toasta>

<ng-template #accountList>
  <mat-nav-list>
    <ng-container *ngIf="!loadingLogin; else login">
      <h1 class="account-list-title mt-2">Contas</h1>
      <div class="mb-2 loading text-center" *ngIf="loadingContas; else elseBlock">
        <i class="fa fa-circle-o-notch fa-spin fa-lg"></i>
      </div>
      <ng-template #elseBlock>
        <ng-container *ngIf="contas.length > 0">
          <a *ngFor="let conta of contas" class="mat-list-item" mat-list-item
            (click)="switchAccount(conta.usuarioTerceiros)">
            <span mat-line class="mat-line">{{conta.nome}}</span> <i class="fa fa-sign-in" aria-hidden="true"></i>
          </a>
        </ng-container>
        <div *ngIf="contas.length === 0" class="mb-2 mt-3 text-center">
          <span style="font-size: 1rem;">Não há contas vinculadas</span>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #login>
      <div class="mb-2 mt-2 text-center">
        <i class="fa fa-circle-o-notch fa-spin fa-lg"></i>
      </div>
      <div class="mb-2 text-center">
        <span style="font-size: 1rem;">Trocando conta</span>
      </div>
    </ng-template>
  </mat-nav-list>
</ng-template>
