import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseServiceV2 } from '../base/base-service-v2';

@Injectable()
export class UnidadesMedidaServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('UnidadesMedida', http);
  }

}
