import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AcessoTelasService } from '../services/acessoTelas.service';
import { ExibirVideosUsuarioService } from '../services/exibirVideosUsuario.service';

@Injectable()
export class UtilFunctions {

    constructor(private acessoTelasService: AcessoTelasService,
        private exibirVideosUsuarioService: ExibirVideosUsuarioService) { }

    ObterAcoesDeTela(acoes) {
        return new Promise((resolve) => {

            const idAcoes = [];
            acoes.forEach(element => {
                idAcoes.push(element['id']);
            });

            const acoesTela = [];
            if (idAcoes && idAcoes.length > 0) {

                this.acessoTelasService.ObterAcessoAcoesPorUsuario(idAcoes)
                    .subscribe(res => {
                        res.forEach(element => {
                            acoes.some((acao, index, object) => {
                                let achou = false;
                                if (acao['id'] === element.id) {
                                    acoesTela[acao['acao']] = { id: element.id, acesso: element.acesso, nome: element.nome };
                                    achou = true;
                                    object.splice(index, 1);
                                }
                                return achou === true;
                            });
                        });
                        resolve(acoesTela);
                    });
            }
        });
    }

    verificaAbreVideoSozinho(nomeVideo) {
        return new Promise((resolve) => {
            this.exibirVideosUsuarioService.getByVideoUser(nomeVideo)
                .subscribe(res => {
                    if (res === null || res.exibir) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
        });
    }
}
