import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RepresentantesServiceV2 } from '@servicesv2';
import { RepresentantesViewModel } from 'models/representantes/RepresentantesViewModel';
import { RepresentanteModalViewModel } from 'models/representantes/RepresentanteModalViewModel';
import { SaveResponseRepresentanteModalViewModel } from 'models/representantes/RepresentanteModalViewModel';

@Component({
  selector: 'app-representante',
  templateUrl: './representante.component.html',
  styleUrls: ['./representante.component.css']
})
export class RepresentanteComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalCadastro', { static: true }) modalCadastro;

  constructor(
    private representantesServiceV2: RepresentantesServiceV2
  ) {
    super();
  }

  prepostos: RepresentantesViewModel[] = [];
  cadastroForm = new FormGroup({
    id: new FormControl(null),
    codigo: new FormControl(null, [Validators.required]),
    nome: new FormControl(null, [Validators.required]),
    preposto: new FormControl(false),
    prepostoId: new FormControl(null),
    tipoComissaoVendedor: new FormControl(null),
    tipoComissaoPreposto: new FormControl(null),
  });

  ngOnInit() {
    this.carregaPrepostos();
  }

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    this.cadastroForm.get('nome').setValue(searchParam);
    this.getSequenciaCodigo();
    this.modalCadastro.show();
  }

  async getSequenciaCodigo() {
    // Se duas pessoas abrirem essa modal para cadastrar um novo representante, pode causar dois representantes possuirem o mesmo código.
    const sequenciaCodigo = await this.representantesServiceV2.getSequenciaCodigo();
    if (sequenciaCodigo) {
      this.cadastroForm.get('codigo').setValue(sequenciaCodigo);
    }
  }

  async carregaDados() {
    if (!this.id) {
      return;
    }

    try {
      this.alteraLoading(true);
      const representante = await this.representantesServiceV2.getAsPromise<RepresentantesViewModel>(`GetModalRepresentanteById/${this.id}`);
      this.cadastroForm.setValue({
        id: representante.id,
        codigo: representante.codigo,
        nome: representante.nome,
        preposto: representante.preposto || false,
        prepostoId: representante.prepostoId,
        tipoComissaoVendedor: representante.tipoComissaoId,
        tipoComissaoPreposto: representante.representantePreposto?.tipoComissaoId || null
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.alteraLoading(false);
    }
  }

  async submitForm() {
    if (this.cadastroForm.invalid) {
      return;
    }

    try {
      this.alteraLoading(true);
      const response = await this.representantesServiceV2
        .postAsPromise<RepresentanteModalViewModel, SaveResponseRepresentanteModalViewModel>(
          this.cadastroForm.value,
          'SalvarRepresentanteModal'
        );
      if (this.id === null && response.generatedId) {
        this.id = response.generatedId;
      }
      this.modalCadastro.hide();
      this.modalCadastro.closed.emit(this.cadastroForm.value);
    } catch (e) {
      console.error(e);
      this.errors = e.msg.errors;
    } finally {
      this.alteraLoading(false);
    }
  }

  async carregaPrepostos() {
    try {
      this.prepostos = await this.representantesServiceV2.getPrepostos(this.id || 0);
    } catch (error) {
      console.error(error);
    }
  }

  handleOnChangePreposto(preposto: RepresentantesViewModel) {
    this.cadastroForm.get('tipoComissaoPreposto').setValue(preposto.tipoComissaoId);
  }

  handleOnChangePrepostoCheckbox(event: Event) {
    const checked = (event.target as HTMLInputElement).checked;
    if (checked) {
      this.cadastroForm.get('prepostoId').setValue(null);
      this.cadastroForm.get('tipoComissaoPreposto').setValue(null);
    }
  }

}
