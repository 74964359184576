import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { markFormGroupTouched } from '../../../../general/utils';
import { ItensService } from '../../../../services/itens.service';
import { MessageService } from '../../../../services/message.service';
import swal from 'sweetalert2';
import { finalize } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Component({
	selector: 'app-relatorio-produtos-por-origem',
	templateUrl: './relatorio-produtos-por-origem.component.html',
	styleUrls: ['./relatorio-produtos-por-origem.component.css'],
})
export class RelatorioProdutosPorOrigemComponent
	extends ModalCadastroComponent
	implements OnInit
{
	constructor(private service: ItensService, private message: MessageService) {
		super();
	}

	@ViewChild('modalRelatorio', { static: true }) modalRelatorio;
	form: FormGroup;
	anos = [];

	ngOnInit() {
		this.setRangeAnos();
		this.form = new FormGroup({
			anoI: new FormControl(null, Validators.required),
			anoF: new FormControl(new Date().getFullYear(), Validators.required),
			formato: new FormControl(true),
		});
	}

	setRangeAnos() {
		for (let i = 0; i <= 10; i++) {
			this.anos.push(new Date().getFullYear() - i);
		}
	}

	show() {
		this.modalRelatorio.show();
	}

	gerarPDF() {
		markFormGroupTouched(this.form);

		if (this.form.valid) {
			this.message.wait('Gerando relatório...');
			this.alteraLoading(true, 'request');
			this.service
				.relatorioPorOrigem(this.form.value)
				.pipe(finalize(() => this.alteraLoading(false, 'request')))
				.subscribe(
					(response) => {
						this.destroyComponent();
						this.message.success('Relatório gerado com sucesso!');
						const formato = this.form.get('formato').value
							? 'pdf'
							: 'xlsx';

						saveAs(
							response,
							`relatorio_vendas_por_produto_pedido.${formato}`
						);
					},
					(error) => {
						this.message.error(
							error.errors[0],
							10000,
							'Erro ao emitir o relatório!'
						);
						this.destroyComponent();
					}
				);
		}
	}

	destroyComponent() {
		this.closed.emit();
	}
}
