<app-modal-basic #modal [hideHeader]='true' [hideFooter]='true' (closed)="onClose()" [allowClickOutside]="false">
  <div class="app-modal-body">
    <div class="modalheader">
      <img src="assets/images/gif/whatsapp.gif">
      <h1>Por favor, verifique seu WhatsApp</h1>
      <span style="text-align: center;">
        Enviaremos um código para o financeiro da sua empresa, pode demorar alguns instantes.
        <i class="fa fa-info-circle" #tooltip="ngbTooltip" ngbTooltip="Esse código expira em 10 minutos" [autoClose]="true"
          triggers="manual" (mouseleave)="tooltip.close()" (mouseenter)="tooltip.open()" (click)="tooltip.open()" container="body">
        </i>
      </span>
    </div>
    <form [formGroup]="form">
      <div class="otp-wrapper">
        <div class="otp-container">
          <input id="otp0" formControlName="otp" class="otp-input" mask="0" [disableControl]="loading['verificando']" (input)="onInput($event, 0)" (paste)="colar($event)">
          <input id="otp1" formControlName="otp1" class="otp-input" mask="0" [disableControl]="loading['verificando']" (keyup.backspace)="onBackSpace(1)" (input)="onInput($event, 1)" (paste)="colar($event)">
          <input id="otp2" formControlName="otp2" class="otp-input" mask="0" [disableControl]="loading['verificando']" (keyup.backspace)="onBackSpace(2)" (input)="onInput($event, 2)" (paste)="colar($event)">
        </div>
        <div class="otp-container">
          <input id="otp3" formControlName="otp3" class="otp-input" mask="0" [disableControl]="loading['verificando']" (keyup.backspace)="onBackSpace(3)" (input)="onInput($event, 3)" (paste)="colar($event)">
          <input id="otp4" formControlName="otp4" class="otp-input" mask="0" [disableControl]="loading['verificando']" (keyup.backspace)="onBackSpace(4)" (input)="onInput($event, 4)" (paste)="colar($event)">
          <input id="otp5" formControlName="otp5" class="otp-input" mask="0" [disableControl]="loading['verificando']" (keyup.backspace)="onBackSpace(5)" (input)="onInput($event, 5)" (paste)="colar($event)">
        </div>
      </div>
      <div class="d-flex justify-content-center pb-2">
        <button *ngIf="!isFireFox" type="button" hiddenMenu class="btn btn-outline-secondary btn-round" style="cursor: pointer;" (click)="colar()" [disabled]="loading['verificando']">
          <i class="fa fa-paste"></i> Colar
        </button>
      </div>
      <div class="modal-text-wrapper">
        <p *ngIf="errorMessage" class="modal-error">{{errorMessage}}</p>
        <p *ngIf="!errorMessage">O código não chegou?</p>
        <div class="d-flex">
          <p [ngClass]="{'link': !timerRunning}" (click)="enviarCodigo()">{{textoReceberCodigo}}</p>   
          <span *ngIf="!timerRunning" class="timer">({{remainingTime}}s)</span>
        </div>
      </div>
    </form>
    <div class="button-wrapper">
      <button type="button" hiddenMenu class="btn btn-info" (click)="voltar()" [disabled]="loading['verificando']">
        <i class="fa fa-arrow-left"></i> Voltar
      </button>
      <button type="button" hiddenMenu class="btn btn-primary mr-0" (click)="verificarCodigo()" [disabled]="loading['verificando']">
        <app-loading texto="Verificar" textoLoading="Verificando" icone="fa fa-check" [loading]="loading['verificando']"></app-loading>
      </button>
    </div>
  </div>
</app-modal-basic>