<app-modal-basic #modalRelatorio (closed)="onClose()">
  <div class="app-modal-header">
    <h4 class="modal-title">Notas Fiscais com Retenções de Impostos</h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="impressaoForm" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Emissão</label>
          <div class="input-group">
            <app-data [calendarPosition]="'left'" formControlName="emissaoDe"></app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="emissaoAte"></app-data>
          </div>
          <div class='row'>
            <div class='col-sm-6'>
              <show-errors [control]="impressaoForm.controls.emissaoDe"></show-errors>
            </div>
            <div class='col-sm-6'>
              <show-errors [control]="impressaoForm.controls.emissaoAte"></show-errors>
            </div>
          </div>
        </div>
      </div>

      <button type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
        <i class="icofont icofont-print"> </i> Imprimir
      </button>
      <button type="button" class="btn btn-danger ripple" (click)="modalRelatorio.hide()"><i class="ti ti-close"></i>
        Cancelar
      </button>
    </form>
  </div>
  <div class="app-modal-footer">

  </div>

</app-modal-basic>