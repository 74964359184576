import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { getErrorMessage } from '@utils';
import { debounceTime, firstValueFrom } from 'rxjs';
import { ParamConsulta } from '../../../general/models/paramConsulta';
import { MessageService } from '../../../services/message.service';
import { NFEntradaService } from '../../../services/nfentrada.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { TempModalCadastroComponent } from '../../../shared/templates/temp-modal-cadastro/temp-modal-cadastro.component';

@Component({
  selector: 'app-busca-itens-nota-entrada',
  templateUrl: './busca-itens-nota-entrada.component.html',
  styleUrls: ['./busca-itens-nota-entrada.component.css'],
})
export class BuscaItensNotaEntradaComponent extends ModalCadastroComponent {
  @ViewChild('modalConsultar', { static: true }) modalConsultar: TempModalCadastroComponent;
  notas = [];
  notasSelecionadas = [];
  itens = [];
  itensSelecionados = [];
  paramConsulta = new ParamConsulta();
  form: FormGroup;
  mostrarItens = false;
  loading = {
    notas: false,
    itens: false,
    importarItens: false,
  };

  colunas = [
    { label: 'Série', col: 'serie' },
    { label: 'Número', col: 'numeroNotaFiscal' },
    { label: 'Data Emissão', col: 'emissao', date: true },
    { label: 'Remetente', col: 'nome' },
    { label: 'Valor', col: 'valorTotal', currency: true },
  ];
  colunasItens = [
    { label: 'Nº Nota', col: 'numeroNotaFiscal', invisible: true },
    { label: 'Cód. Entrada', col: 'codigoEntrada' },
    { label: 'Cód. Interno', col: 'codigoInterno' },
    { label: 'Nome', col: 'nome' },
    { label: 'Quantidade', col: 'quantidade', format: (x: number) => this.formatNumber(x) },
    { label: 'Preço', col: 'preco', currency: true },
  ];

  constructor(
    private nfEntradaService: NFEntradaService,
    private messageService: MessageService
  ) {
    super();

    this.form = new FormGroup({
      search: new FormControl(''),
    });

    this.form.get('search').valueChanges.pipe(debounceTime(800)).subscribe(() => {
      if (!this.loading.notas) {
        this.carregarNotas();
      }
    });
  }

  formatNumber(val) {
    return (val || 0).toString().replace('.', ',');
  }

  show() {
    this.modalConsultar.show();
    this.carregarNotas();
  }

  onSort(paramConsulta) {
    this.paramConsulta = paramConsulta;
    this.carregarNotas();
  }

  async carregarNotas() {
    try {
      this.loading.notas = true;
      const search = this.form.get('search').value;
      this.notas = await firstValueFrom(this.nfEntradaService.buscarNotasEntrada(search, this.paramConsulta));
    } catch (error) {
      console.error(error);
      this.messageService.error('Falha ao carregar notas: ' + getErrorMessage(error), 8000);
    } finally {
      this.loading.notas = false;
    }
  }

  async carregarItens() {
    try {
      this.colunasItens.find(x => x.col === 'numeroNotaFiscal').invisible = this.notasSelecionadas.length <= 1;
      
      this.mostrarItens = true;
      this.loading.itens = true;
      const notas = this.notasSelecionadas.map(x => x.id);
      this.itens = await firstValueFrom(this.nfEntradaService.buscarItensNotasEntrada(notas));
    } catch (error) {
      console.error(error);
      this.messageService.error(
        'Ocorreu um erro ao carregar os itens das notas: ' + getErrorMessage(error),
        8000,
        'Atenção!',
        true
      );
    } finally {
      this.loading.itens = false;
    }
  }

  async importarItens() {
    try {
      this.loading.importarItens = true;
      const nfeItensIds = this.itensSelecionados.map(x => x.id);
      const itens = await firstValueFrom(this.nfEntradaService.importarItensNotaEntrada(nfeItensIds));

      const res = itens.map(item => {
        return {
          produto: item.itemID,
          descricaoProduto: item.descricaoProduto,
          unidadeMedida: item.unidadeMedida,
          quantidade: item.quantidade,
          preco: item.preco,
          valorDesconto: 0,
          tipoDesconto: 1,
          valorTotalItem: item.valorTotalItem,
          referenciaItem: item.referenciaItem,
        };
      });
      this.closed.emit(res);
    } catch (err) {
      console.error(err);
      this.messageService.error(getErrorMessage(err), 8000);
    } finally {
      this.loading.importarItens = false;
    }
  }

  voltar() {
    this.itensSelecionados = [];
    this.mostrarItens = false;
  }
}
