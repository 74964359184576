import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseServiceV2 } from '../base/base-service-v2';
import { TipoComissaoViewModel } from 'models/tipo-comissao/TipoComissaoViewModel';

@Injectable()
export class TipoComissaoServiceV2 extends BaseServiceV2 {

  constructor(http: HttpClient) {
    super('TipoComissao', http);
  }

  getTipoComissaoByCliente(clienteId: number) {
    return this.getAsPromise<TipoComissaoViewModel>(`GetTipoComissaoByClienteId/${clienteId}`);
  }

}
