<hr>
<h5>Cadastro de MVA Por Estado</h5>
<hr>
<form [formGroup]="form" (ngSubmit)="submitFormItem()">
  <div class="row">
    <div class="form-group col-sm-6">
      <label class="label-required">Estado</label>
      <ng-select [loading]="loading['estados']" placeholder="Selecione" [items]="estados" bindLabel="nome"
        bindValue="siglaEstado" formControlName="siglaEstado">
      </ng-select>
      <show-errors [control]="form.controls.siglaEstado"></show-errors>
    </div>
    <div class="form-group col-sm-6">
      <label class="label-required">Modalidade BC ICMS ST</label>
      <ng-select [loading]="loading['modBcStIcms']" placeholder="Selecione" [items]="modBcStIcms" bindLabel="descricao"
        bindValue="tipo" formControlName="modBcStIcms" (change)="changeModalidade($event)">
      </ng-select>
    </div>
  </div>

  <div class="row">
    <div class="form-group col-sm-6">
      <label>% Redutor</label>
      <input type="text" class="form-control" formControlName="redutor"
        [options]="{ prefix: '', thousands: '.', decimal: ',' }" currencyMask inputmode="numeric" />
    </div>
  </div>

  <div class="row">
    <div class="form-group col-sm-6">
      <label class="label-required">MVA</label>
      <input type="text" class="form-control" formControlName="mva"
        [options]="{ prefix: '', thousands: '.', decimal: ',' }" currencyMask inputmode="numeric" />
      <show-errors [control]="form.controls.mva"></show-errors>
    </div>
    <div class="checkbox-fade fade-in-default form-group col-sm-6 d-flex align-items-center">
      <label class="mva-ajustada">
        <input type="checkbox" formControlName="mvaAjustada">
        <span class="cr">
          <i class="cr-icon icofont icofont-ui-check" style="color: green"></i>
        </span>
        <span>Mva Ajustada</span>
      </label>
    </div>
  </div>

  <div class="form-button-row d-flex justify-content-end m-b-15 button-container">
    <button [disabled]="controle" (click)="validaForm()" class="btn btn-primary custom-form-button" type="submit"><i class="fa fa-plus"></i>
      Adicionar
    </button>

    <button (click)="cancelarDigitacao()" class="btn btn-warning custom-form-button"><i class="ti ti-close"></i>
      Cancelar
    </button>
  </div>
</form>
<hr>
