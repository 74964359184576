import { FormControl, FormGroup } from '@angular/forms';

import { PeriodoConsulta, PeriodoConsultaForm } from '@models';
import { IParamConsulta, TParamConsultaForm } from '@generals';
import { CreditoComissoesErrors, CreditoComissoesViewModel } from './CreditoComissoes';

export interface ICreditarComissoes {
  formCreditar: FormGroup;
  creditarData: CreditarComissoesRepresentantesResponse;

  getFormCreditar(): FormGroup;
  creditar(): void;
}

export interface ConsultarCreditoComissoesViewModel {
  periodo: PeriodoConsulta;
  paramConsulta: IParamConsulta;
}

export type ConsultarCreditoComissoesForm = {
  periodo: FormGroup<PeriodoConsultaForm>;
  paramConsulta: FormGroup<TParamConsultaForm>;
}

export interface CreditarComissoesRepresentantesViewModel {
  representantes: CreditoComissoesViewModel[];
  lancarContasAPagar: boolean;
  lancamento: Date;
  vencimento: Date;
  periodo: PeriodoConsulta;
}

export type CreditarComissoesRepresentantesForm = {
  representantes: FormControl<CreditoComissoesViewModel[]>;
  lancarContasAPagar: FormControl<boolean>;
  lancamento: FormControl<Date>;
  vencimento: FormControl<Date>;
  periodo: FormGroup<PeriodoConsultaForm>;
}

export interface CreditarComissoesRepresentantesResponse extends CreditarComissoesRepresentantesViewModel {
  representantesErrors: CreditoComissoesErrors[];
}

export interface CreditarComissoesRepresentantesDictMotivos {
  comissaoInsuficiente: DictMotivo;
  periodoConflito: DictMotivo;
  representanteSemFornecedor: DictMotivo;
}

interface DictMotivo {
  label: string;
  tooltip: string;
  tipo: string;
  acao?: () => void;
}
