import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { StorageFunctions } from '../../../general/storage';
import { ConfiguracaoItensNfeForm, ConfiguracaoItensNfeViewModel } from './model-configuracao-itens-nfe/ConfiguracaoItensNfeViewModel';

@Component({
  selector: 'app-configuracao-itens-nfe',
  templateUrl: './configuracao-itens-nfe.component.html',
  styleUrls: ['./configuracao-itens-nfe.component.scss']
})
export class ConfiguracaoItensNfeComponent {
  private storageKey = 'ConfiguracaoItensNfeComponent';

  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() applied = new EventEmitter<ConfiguracaoItensNfeViewModel>();

  @Input() open = false;
  @Input() tipoNfeID: number = null;
  @Input() cst: string = null;
  @Input() cBenefDefault: () => string = null;
  @Input() cstDefault: () => string = null;

  form = this.getFormDefault();

  constructor(
    private storage: StorageFunctions,
  ) { }

  getFormDefault(): FormGroup<ConfiguracaoItensNfeForm> {
    const f = new FormGroup({
      replicarParaNovosItens: new FormControl(false),
      aplicarCbenef: new FormControl(false),
      cBenef: new FormControl(null),
    });

    return f;
  }

  onOpened() {
    this.setDataFromStorage();
    this.setCstDefault();
  }

  onClosed() {
    this.closed.emit();
  }

  onSubmit() {
    this.setDataToStorage();
    this.applied.emit(this.form.getRawValue());
  }

  onCancel() {
    this.closed.emit();
  }

  private setCstDefault() {
    if (!this.cstDefault || typeof this.cstDefault !== 'function') {
      return;
    }

    this.cst = this.cstDefault();
  }

  private setCBenefDefault() {
    if (!this.cBenefDefault || typeof this.cBenefDefault !== 'function') {
      return;
    }

    const cBenef = this.cBenefDefault();
    if (cBenef) {
      this.form.get('cBenef').setValue(cBenef);
      this.setDataToStorage();
    }
  }

  private setDataFromStorage() {
    const storageMapData = this.storage.getDataFromLocalStorage(this.storageKey) || {};
    if (this.tipoNfeID === null || !(this.tipoNfeID in storageMapData)) {
      this.setCBenefDefault();
    } else {
      this.form.patchValue(storageMapData[this.tipoNfeID]);
      this.form.get('replicarParaNovosItens').setValue(false);
    }
  }

  private setDataToStorage() {
    const storageMapData = this.storage.getDataFromLocalStorage(this.storageKey) || {};
    storageMapData[this.tipoNfeID] = this.form.getRawValue();
    this.storage.saveDataToLocalStorage(this.storageKey, storageMapData);
  }
}
