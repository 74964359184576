import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ExibirVideosUsuarioService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getByVideoUser(nomeVideo: String) {
        return this.http.get(this.UrlService + `ExibirVideosUsuario/${nomeVideo}`)
            .pipe(map((res: any) => res.data)
                , catchError(super.serviceError));
    }

    salvar(form: any) {
        if (form.id) {
            return this.put(form);
        } else {
            return this.post(form);
        }
    }

    private put(form: any) {
        return this.http.put(this.UrlService + `ExibirVideosUsuario`, JSON.stringify(form))
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

    private post(form: any) {
        return this.http.post(this.UrlService + `ExibirVideosUsuario`, JSON.stringify(form))
            .pipe(map(super.extractData)
                , catchError(super.serviceError));
    }

}
