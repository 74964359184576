import { NgClickOutsideDirective } from 'ng-click-outside2';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { SharedModule } from './shared/shared.module';
import { BreadcrumbsComponent } from './layouts/admin/breadcrumbs/breadcrumbs.component';
import { TitleComponent } from './layouts/admin/title/title.component';
import { InterceptorService } from './interceptor/interceptor.service';
import { DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AuthService } from './services/auth.service';
import { NotFoundComponent } from './errors/not-found/not-found.component';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OrdenacaoMenu } from './services/ordenacaoMenu.service';
import { StorageFunctions } from './general/storage';
import { ModaisModule } from './modais/modais.module';
import { AcessoTelasService } from './services/acessoTelas.service';
import { ConfiguracoesUsuarioService } from './services/configuracoes-usuario.service';
import { ExibirVideosUsuarioService } from './services/exibirVideosUsuario.service';
import { MenuItemComponent } from './layouts/admin/menu-item/menu-item.component';
import { EditarMenuItemComponent } from './layouts/admin/editar-menu-item/editar-menu-item.component';
import { NotasFiscaisService } from './services/notas-fiscais.service';
import { NotificacoesService } from './services/notificacoes.service';
import { AdminLayoutEventBusService } from './services/admin-layout-event-bus.service';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { SegmentosService } from './services/segmentos.service';
import { UnidadesConsumoService } from './services/unidades-consumo.service';
import { ItensPedidoPagamentoService } from './services/itensPedidoPagamento.service';

registerLocaleData(localePt, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    BreadcrumbsComponent,
    TitleComponent,
    NotFoundComponent,
    MenuItemComponent,
    EditarMenuItemComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule.forRoot(AppRoutes),
    FormsModule,
    HttpClientModule,
    DragDropModule,
    ModaisModule,
    NgClickOutsideDirective,
    NgScrollbarModule,
    JwtModule.forRoot({ config: {} }),
  ],
  exports: [DragDropModule],
  providers: [
    AuthService,
    OrdenacaoMenu,
    StorageFunctions,
    AcessoTelasService,
    ConfiguracoesUsuarioService,
    ExibirVideosUsuarioService,
    NotasFiscaisService,
    NotificacoesService,
    AdminLayoutEventBusService,
    SegmentosService,
    UnidadesConsumoService,
    ItensPedidoPagamentoService,
    DatePipe,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
