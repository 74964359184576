import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { setFieldFormValue, setFormValue } from '../../../general/utils';
import { IntermediadorService } from '../../../services/intermediador.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { TempModalCadastroComponent } from '../../../shared/templates/temp-modal-cadastro/temp-modal-cadastro.component';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-intermediadores',
  templateUrl: './intermediadores.component.html',
  styleUrls: ['./intermediadores.component.css']
})
export class IntermediadoresComponent extends ModalCadastroComponent implements OnInit {
  constructor(private intermediadorService: IntermediadorService) {
    super();
  }

  @ViewChild("modalCadastro", { static: false }) modalCadastro: TempModalCadastroComponent;

  cadastroForm = new FormGroup({
    id: new FormControl(0),
    tag: new FormControl(null, Validators.required),
    cnpj: new FormControl(null, Validators.required),
    identificador: new FormControl(null, Validators.required),
    ativo: new FormControl(true)
  })

  ngOnInit() {
  }

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    setFieldFormValue(this.cadastroForm, 'tag', searchParam);
    this.modalCadastro.show();
  }

  carregaDados() {
    if (this.id) {
      this.alteraLoading(true);
      this.intermediadorService.getById(this.id)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe((res) => {
          setFormValue(this.cadastroForm, res);
        })
    }
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      this.alteraLoading(true);

      this.intermediadorService.salvar(this.cadastroForm.getRawValue())
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe(res => {
          this.id = res.generatedId || null;
          this.modalCadastro.hide();
        }, (error) => {
          this.errors = error.msg.errors;
        })
    }
  }
}
