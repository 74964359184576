import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from './base.service';

@Injectable()
export class NotificacoesService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  listarNotificacoes(index: number) {
	return this.http.get(this.UrlService + 'notificacoes/' + index).pipe(map(super.extractData)
        , catchError(super.serviceError));
  }

  visualizar(id) {
	return this.http.get(this.UrlService + 'notificacoes/visualizar/' + id).pipe(map(super.extractData)
        , catchError(super.serviceError));
  }


  adicionarNotificacao(notificacaoForm) {
    return this.http.post(this.UrlService + 'notificacoes', notificacaoForm);
  }

  consultarPaginado(consulta) {
    return this.http.post(this.UrlService + 'notificacoes/consulta', consulta);
  }

  deletar(id) {
    return this.http.delete(this.UrlService + 'notificacoes/' + id);
  }
}
