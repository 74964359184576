import { Component, ElementRef, Input, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Component({
  selector: 'app-dim-data-range',
  templateUrl: './dim-data-range.component.html',
  styleUrls: ['./dim-data-range.component.scss'],
})
export class DimDataRangeComponent {

  @ViewChild('dimDataRangeLabel') dimDataRangeLabel: ElementRef<any>;

  @Input() formGroup: FormGroup;
  @Input() disabled = false;
  @Input() controlNameMin = 'inicio';
  @Input() controlNameMax = 'fim';
  @Input() validacao = true;
  @Input() mostrarErro = true;
  @Input() validaDatasObrigatorias = false;
  @Input() validaDataMinimaMaxima = true;
  @Input() maxDate: Date = null;
  @Output() change = new EventEmitter();

  onDateChange(_: any) {
    this.change.emit(this.formGroup.value);
  }

  ngOnInit() {
    this.formGroup.addValidators(this.validatorMinMaxOverlap());

    if (this.validaDatasObrigatorias) {
      this.formGroup.addValidators(this.validatorDatesRequired());
    }
  }

  /**
   * Validador personalizado para verificar se a data de início é maior que a data de fim em um FormGroup.
   *
   * @returns {ValidatorFn} Uma função validadora que configura `ValidationErrors` na data de início se a validação falhar,
   *  ou `null` se a validação passar.
   *
   * @description
   * Este método privado cria um validador que compara os valores de dois controles específicos em um FormGroup,
   *  identificados pelas propriedades `controlNameMin` e `controlNameMax` do componente.
   */
  private validatorMinMaxOverlap(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      if (!this.validacao || !this.validaDataMinimaMaxima || !this.controlNameMin || !this.controlNameMax) {
        return null;
      }

      const min = group.controls[this.controlNameMin];
      const max = group.controls[this.controlNameMax];

      if (!min || !max || !min.value || !max.value) {
        return null;
      }

      const minDate = new Date(min.value);
      const maxDate = new Date(max.value);

      if (minDate > maxDate) {
        return { dataMinMaxOverlap: { message: 'O início do período deve anteceder ao fim do período.' } };
      }

      return null;
    }
  }

  /**
   * Validador personalizado para verificar se a data de início e a data de fim estão foram informadas em um FormGroup.
   *
   * @returns {ValidatorFn} Uma função validadora que configura `ValidationErrors` na data de início se a validação falhar,
   *  ou `null` se a validação passar.
   *
   * @description
   * Este método privado cria um validador que valida a configuração obrigatória dos controles específicos em um FormGroup,
   *  identificados pelas propriedades `controlNameMin` e `controlNameMax` do componente.
   */
  private validatorDatesRequired(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      if (!this.validacao || !this.validaDatasObrigatorias || !this.controlNameMin || !this.controlNameMax) {
        return null;
      }

      const min = group.controls[this.controlNameMin];
      const max = group.controls[this.controlNameMax];

      if (!min.value || !max.value) {
        return { dataMinMaxRequired: { message: 'O período inicial e final são obrigatórios.' } };
      }

      return null;
    }
  }

}
