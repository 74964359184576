import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../../shared/modal-cadastro/modal-cadastro.component';
import { ModalBasicComponent } from '../../../../shared/modal-basic/modal-basic.component';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-graficos',
  templateUrl: './graficos.component.html',
  styleUrls: ['./graficos.component.css']
})
export class GraficosComponent extends ModalCadastroComponent implements OnInit {

  constructor() {
    super();
   }

  @ViewChild('modal', { static: true }) modal: ModalBasicComponent;
  form: FormGroup;

  ngOnInit() {
    this.form = new FormGroup({
      relacaoFaturamento: new FormControl(false),
      receitasDespesas: new FormControl(false),
      vendasEfetivadas: new FormControl(false),
      contasReceber: new FormControl(false),
      distribuicaoRecursos: new FormControl(false),
      previsaoFinanceira: new FormControl(false)
    });
  }

  show() {
    this.modal.show();
  }

  hide() {
    this.modal.hide();
    this.closed.emit();
  }

  imprimir() {
    this.closed.emit(this.form.value)
  }
}
