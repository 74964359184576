<app-modal-basic modalContentId="modal-configuracao-itens-nfe"
  [showNow]="open"
  (opened)="onOpened()"
  (closed)="onClosed()"
>
  <div class="app-modal-header">
    <h4 class="modal-title">
      <i class="modal-title"></i>
      Configuração dos Itens
    </h4>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-12">
        <p>
          Ao aplicar pela primeira vez, as configurações ativas serão replicadas aos itens já presentes
          e também a quaisquer novos itens adicionados.
        </p>
      </div>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-12">
          <app-dim-checkbox
            label="Código de benefício fiscal"
            formControlName="aplicarCbenef"
          ></app-dim-checkbox>
          <app-combobox-cbenef-fiscais
            appendTo="#modal-configuracao-itens-nfe"
            formControlName="cBenef"
            label=""
            [notFoundPlusIcon]="false"
            [pencilIcon]="false"
            [customData]="{ cst: cst }"
            [disabled]="!form.get('aplicarCbenef').value"
          ></app-combobox-cbenef-fiscais>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <button type="submit" class="btn btn-primary">
            <app-loading icone="ti-save" textoLoading="Aplicando" texto="Aplicar"></app-loading>
          </button>
          <button type="button" class="btn btn-danger" (click)="onCancel()">
            <app-loading icone="ti-close" textoLoading="Cancelar" texto="Cancelar"></app-loading>
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="app-modal-footer"></div>
</app-modal-basic>
