import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalBasicComponent } from '../../../shared/modal-basic/modal-basic.component';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';

@Component({
  selector: 'app-aviso-certificado',
  templateUrl: './aviso-certificado.component.html',
  styleUrls: ['./aviso-certificado.component.css']
})
export class AvisoCertificadoComponent extends ModalCadastroComponent implements OnInit {

  constructor() {
    super();
  }

  @ViewChild('modal', { static: false }) modal: ModalBasicComponent;
  title: string;
  message: string;
  cidade: number;

  ngOnInit() {
    this.cidade = this.prop.cidade;

    if(this.prop.dias > 1) {
      this.title = `Atenção, cliente: seu Certificado Digital vencerá em ${this.prop.dias} dias.`
    } else {
      this.title = 'Atenção, cliente: seu Certificado Digital está vencido.'
    }

    if(this.cidade) {
      if ([4449, 4445, 4447, 4451, 4453].includes(this.cidade)) {
        this.message = 'Entre em contato com nosso parceiro <b>Sindicont</b> para renovação do seu Certificado.'
      } else {
        this.message = 'Entre em contato com sua contabilidade para saber como proceder com a renovação.'
      }
    }
  }

  show(){
    this.modal.show();
  }

  onClose() {
    this.closed.emit();
  }

  openWhats() {
    window.open('https://api.whatsapp.com/send?phone=5547991878367&text=Ol%C3%A1%2C%20venho%20atrav%C3%A9s%20da%20Dimens%C3%A3o%20Sistemas!%20', '_blank')
  }
}
