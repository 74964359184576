import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class IntermediadorService extends BaseService {

    constructor(private http: HttpClient) { super(); }

    getPaginadoConsulta(form) {
        return this.http
          .post(this.UrlService + 'Intermediador/GetPaginado', form)
          .pipe(map((res: any) => res.data)
            , catchError(super.serviceError));
    }

    getPaginado(pagina: number, nome: string) {
      return this.http
        .get(this.UrlService + 'Intermediador/GetPaginado?pageIndex=' + pagina + '&search=' + encodeURIComponent(nome))
        .pipe(map((res: any) => res.data)
          , catchError(super.serviceError));
    }

    getById(id: number) {
      return this.http
        .get(this.UrlService + 'Intermediador/GetById/' + id)
        .pipe(map((res: any) => res.data)
          , catchError(super.serviceError));
    }

    salvar(form) {
      if ((form.id || 0) === 0) {
        return this.http.post(this.UrlService + 'Intermediador/', form)
          .pipe(map((res: any) => res.data),
            catchError(super.serviceError));
      } else {
        return this.http.put(this.UrlService + 'Intermediador/', form)
          .pipe(map((res: any) => res.data),
            catchError(super.serviceError));
      }
    }
}
