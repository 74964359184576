<app-modal-basic #modalRelatorio [modalContentId]="'modal-rv'" (closed)="close()">
  <div class="app-modal-header">
    <h4 class="modal-title">Impressão de Romaneio de Carga</h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="form" (ngSubmit)="imprimir()">
      <div class="row">
        <div class="col-sm-12 form-group" style="margin-bottom: unset">
          <label>
            Orientação:
            <i class="fa fa-info-circle" ngbTooltip="Essa opção afeta apenas o formato PDF" placement="right"></i>
          </label>
        </div>
      </div>
      <div class="checkbox-space">
        <div class="row">
          <div class="form-radio col-sm-12">
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="orientacao" [value]="false" />
                <i class="helper"></i>Paisagem
              </label>
            </div>
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="orientacao" [value]="true" />
                <i class="helper"></i>Retrato
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 form-group" style="margin-bottom: unset">
          <label>Formato:</label>
        </div>
      </div>
      <div class="checkbox-space">
        <div class="row">
          <div class="form-radio col-sm-12">
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="formato" [value]="false" />
                <i class="helper"></i>PDF
              </label>
            </div>
            <div class="radio radio-inline">
              <label>
                <input type="radio" formControlName="formato" [value]="true" />
                <i class="helper"></i>XLSX
              </label>
            </div>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary">
        <i class="icofont icofont-print"> </i> Imprimir
      </button>
      <button type="button" class="btn btn-danger" (click)="modalRelatorio.hide()">
        <i class="ti ti-close"></i> Cancelar
      </button>
    </form>
  </div>
</app-modal-basic>
