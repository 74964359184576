import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent {

  @Input() loading: boolean = false;
  @Input() texto: string = "Consultar";
  @Input() textoLoading: string = "Carregando";
  @Input() icone: string = "";
  @Input() btnWrapper: boolean = true;
}
