<app-modal-basic #modal [hideHeader]="true" [hideFooter]="true" [allowClickOutside]='!onScreen'
  (closed)="onClose()" dialogClass="modal-dialog-pdf">
  <div class="app-modal-body">
    <div *ngIf="formaSelecionada === formasPagamento.pix">
      <div class="app-modal-title-return">
        <h1>PIX</h1>
      </div>
      <hr>
      <div class="text-center">
        <p>Evite pagamento em duplicidade. Em caso de dúvida consulte o seu extrato.</p>
      </div>
      <div class="app-modal-double-body">
        <div class="app-modal-left-side">
          <div *ngIf="!qrCodeUrl; else imgBlock">
            <i style="font-size: 1.5rem;" class="fa fa-circle-o-notch fa-spin"></i>
          </div>
          <ng-template #imgBlock>
            <img [src]="'data:image/png;base64,'+qrCodeUrl" alt="QRCode">
          </ng-template>
        </div>
        <div class="app-modal-content-side">
          <div class="dados">
            <h1>Confira os dados do Pix</h1>
            <span>Nome: <strong>SAFE2PAY</strong></span>
            <span>CNPJ: <strong>31.037.942/0001-78</strong></span>
          </div>

          <div class="app-modal-titulos" *ngIf="calculos">
            <h1 class="mt-4">Títulos a Pagar:</h1>
            <div class="d-flex space-between" *ngFor="let calculo of calculos">
              {{calculo.vencimento | date:'dd/MM/yyyy'}}
              <strong>{{calculo.valorParcela | currency:'BRL':'symbol':'1.2-2'}}</strong>
            </div>
            <div class="d-flex space-between" *ngIf="totalJuros > 0">
              Juros de mora
              <strong>{{totalJuros | currency:'BRL':'symbol':'1.2-2'}}</strong>
            </div>
            <div class="d-flex space-between" *ngIf="totalDespesasFinanceiras > 0">
              Despesas Financeiras
              <strong>{{totalDespesasFinanceiras | currency:'BRL':'symbol':'1.2-2'}}</strong>
            </div>
            <div class="d-flex space-between" *ngIf="totalDescontoPontualidade > 0">
              Desconto Pontualidade
              <strong>- {{totalDescontoPontualidade | currency:'BRL':'symbol':'1.2-2'}}</strong>
            </div>
            <div class="d-flex space-between" *ngIf="totalAbatimento > 0">
              Abatimento
              <strong>- {{totalAbatimento | currency:'BRL':'symbol':'1.2-2'}}</strong>
            </div>
            <div class="d-flex space-between" *ngIf="totalDescontoFormaPagamento > 0">
              Desconto PIX
              <strong>- {{totalDescontoFormaPagamento | currency:'BRL':'symbol':'1.2-2'}}</strong>
            </div>
              
            <p class="d-flex space-between pt-2">
              Total: <strong>{{ totalPagar | currency:'BRL':'symbol':'1.2-2'}}</strong>
            </p>
          </div>

          <p *ngIf="codigoPix && !errorMessage" ngbTooltip="Código pix copiado com sucesso!" 
            triggers="click:blur" class="mt-auto mb-0 pointer text-underline" (click)="copiarCodigoPix()">
            Toque aqui para copiar o código Pix
          </p>
          <p *ngIf="errorMessage" class="mt-auto pointer erro" (click)="pix()">
            {{ errorMessage }}
          </p>
        </div>
      </div>
      <hr>
      <div class="app-modal-footer space-between">
        <button class="btn btn-danger" [disabled]="disableCancel" (click)="cancelarPagamento()">
          <i class="fa fa-warning"></i>
          Desistir do pagamento
        </button>
        <p style="font-size: 1.1rem;" class="m-0" [ngClass]="statusPagamento">
          <i [ngClass]="iconeStatusPagamento"></i>
          {{ statusPagamentoFormatado }}
        </p>
      </div>
    </div>
    <div *ngIf="formaSelecionada === formasPagamento.boleto" class="text-center">
      <i class="fa fa-circle-o-notch fa-spin"></i>
    </div>
    <div *ngIf="formaSelecionada === formasPagamento.boletoNovo">
      <div class="app-modal-title-return">
        <h1>BOLETO</h1>
      </div>
      <hr>
      <div class="alert bg-warning">
        Priorize o pagamento por PIX e baixe sua fatura na hora.<br>
        Mais descontos, mais rapidez.
      </div>
      <div class="text-center">
        <p>
          Evite pagamento em duplicidade. Em caso de dúvida consulte o seu extrato.<br>
          Após visualizar o boleto, aguarde 5 minutos para efetuar o pagamento.<br>
          A confirmação do pagamento pode levar até 24 horas.
        </p>
      </div>
      <div [ngClass]="boletoNovoContentClass">
        <div *ngIf="!boletoUrl">
          <i style="font-size: 1.5rem;" class="fa fa-circle-o-notch fa-spin"></i>
        </div>
        <div class="app-modal-left-side">
          <div>
            <button class="btn btn-primary btn-block" (click)="abrirBoleto()" *ngIf="boletoUrl">
              <i class="fa fa-print"></i>
              Abrir boleto
            </button>
            <button class="btn btn-info btn-block" (click)="copiarLinhaDigitavel()" ngbTooltip="Linha digitável copiada com sucesso!" 
            triggers="click:blur" *ngIf="linhaDigitavel">
              <i class="fa fa-copy"></i>
              Copiar linha digitável
            </button>
          </div>
        </div>
        <div class="app-modal-content-side" *ngIf="calculos">
          <div class="app-modal-titulos">
            <h1>Títulos a pagar:</h1>
            <div class="d-flex space-between" *ngFor="let calculo of calculos">
              {{calculo.vencimento | date:'dd/MM/yyyy'}}
              <strong>{{calculo.valorParcela | currency:'BRL':'symbol':'1.2-2'}}</strong>
            </div>
            <div class="d-flex space-between" *ngIf="totalJuros > 0">
              Juros de mora
              <strong>{{totalJuros | currency:'BRL':'symbol':'1.2-2'}}</strong>
            </div>
            <div class="d-flex space-between" *ngIf="totalDespesasFinanceiras > 0">
              Despesas Financeiras
              <strong>{{totalDespesasFinanceiras | currency:'BRL':'symbol':'1.2-2'}}</strong>
            </div>
            <div class="d-flex space-between" *ngIf="totalDescontoPontualidade > 0">
              Desconto Pontualidade
              <strong>- {{totalDescontoPontualidade | currency:'BRL':'symbol':'1.2-2'}}</strong>
            </div>
            <div class="d-flex space-between" *ngIf="totalAbatimento > 0">
              Abatimento
              <strong>- {{totalAbatimento | currency:'BRL':'symbol':'1.2-2'}}</strong>
            </div>
            <div class="d-flex space-between" *ngIf="totalDescontoFormaPagamento > 0">
              Desconto Boleto
              <strong>- {{totalDescontoFormaPagamento | currency:'BRL':'symbol':'1.2-2'}}</strong>
            </div>
    
            <p class="d-flex space-between pt-2">
              Total: <strong>{{ totalPagarBoleto | currency:'BRL':'symbol':'1.2-2'}}</strong>
            </p>
          </div>
        </div>
      </div>
      <hr>
      <div class="app-modal-footer space-between">
        <button class="btn btn-info" [disabled]="disableCancel" (click)="cancelarPagamentoBoleto()">
          Fechar
        </button>
        <p class="erro mb-0 mr-auto" *ngIf="errorMessage">{{errorMessage}}</p>
      </div>
    </div>
    <div *ngIf="formaSelecionada === formasPagamento.credito && (!cartoes || cartoes?.length === 0)">
      <div class="app-modal-title-return">
        <h1>Dados do cartão</h1>
      </div>
      <hr>
      <form [formGroup]="creditForm">
        <div class="row">
          <div class="form-group col-sm-12">
            <label>Número do cartão</label>
            <input class="form-control" placeholder="0000 0000 0000 0000" mask="0000 0000 0000 0000"
              formControlName="cardNumber" (focusout)="getBandeiraCartao()" #cardNumberInput>
            <svg id="ccicon" class="ccicon" width="750" height="471" viewBox="0 0 750 471" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
            </svg>
            <show-errors [control]="creditForm.controls['cardNumber']"></show-errors>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12">
            <label>Nome do Titular</label>
            <input class="form-control" placeholder="EX: Pedro da Silva" formControlName="holder">
            <show-errors [control]="creditForm.controls['holder']"></show-errors>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-6">
            <label>Validade</label>
            <input class="form-control" placeholder="00/0000" mask="00/0000" formControlName="expirationDate">
            <show-errors [control]="creditForm.controls['expirationDate']"></show-errors>
          </div>
          <div class="form-group col-sm-6">
            <label>CVV</label>
            <app-input-senha
              [form]="creditForm" 
              formControlName="securityCode"
              placeholder="000"
              mascara="000">
            </app-input-senha>
            <show-errors [control]="creditForm.controls['securityCode']"></show-errors>
          </div>
        </div>
      </form>

      <hr>
      <p class="erro mb-0 mr-auto" *ngIf="errorMessage">{{errorMessage}}</p>
      <div class="app-modal-footer">
        <button type="button" class="btn btn-primary" [disabled]="loading['cadastrarCartao'] || creditForm.invalid" (click)="cadastrarCartao()">
          <app-loading [loading]="loading['cadastrarCartao']" texto="Confirmar" icone=""
            textoLoading="Confirmar"></app-loading>
        </button>
        <button type="button" class="btn btn-info" [disabled]="loading['cadastrarCartao']" (click)="fechar()">
          Fechar
        </button>
      </div>
    </div>
    <div [@fadeInOutTranslate] *ngIf="formaSelecionada === formasPagamento.credito && cartoes && cartoes?.length !== 0">
      <div class="app-modal-title-return">
        <h1>Cartão</h1>
      </div>
      <hr>
      <div>
        <p class="m-0">Evite pagamento em duplicidade. Em caso de dúvida consulte o seu extrato.</p>
      </div>

      <ng-container *ngIf="!cadastrandoCartao">
        <!-- Se acabou de cadastrar o cartão não precisa mostrar o número novamente -->
        <div class="row mt-3">
          <div class="form-group col-sm-12">
            <label>Número do cartão</label>
            <input class="form-control" [value]="cartoes[0].numeroCartao" disabled>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-sm-12">
            <div class="checkbox-fade fade-in-default" style="opacity: 0.6">
              <label>
                <input type="checkbox" disabled [checked]="cartoes[0].debitoAutomatico">
                <span class="cr" style="cursor: not-allowed">
                  <i class="cr-icon icofont icofont-ui-check txt-default"></i>
                </span> 
                <span>
                  Débito automático
                </span>
              </label>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="app-modal-content-side" *ngIf="calculos">
        <div class="app-modal-titulos">
          <h1 class="mt-2">Títulos a pagar:</h1>
          <div class="d-flex space-between" *ngFor="let calculo of calculos">
            {{calculo.vencimento | date:'dd/MM/yyyy'}}
            <strong>{{calculo.valorParcela | currency:'BRL':'symbol':'1.2-2'}}</strong>
          </div>
          <div class="d-flex space-between" *ngIf="totalJuros > 0">
            Juros de mora
            <strong>{{totalJuros | currency:'BRL':'symbol':'1.2-2'}}</strong>
          </div>
          <div class="d-flex space-between" *ngIf="totalDespesasFinanceiras > 0">
            Despesas Financeiras
            <strong>{{totalDespesasFinanceiras | currency:'BRL':'symbol':'1.2-2'}}</strong>
          </div>
          <div class="d-flex space-between" *ngIf="totalDescontoPontualidade > 0">
            Desconto Pontualidade
            <strong>- {{totalDescontoPontualidade | currency:'BRL':'symbol':'1.2-2'}}</strong>
          </div>
          <div class="d-flex space-between" *ngIf="totalAbatimento > 0">
            Abatimento
            <strong>- {{totalAbatimento | currency:'BRL':'symbol':'1.2-2'}}</strong>
          </div>
          <div class="d-flex space-between" *ngIf="totalDescontoFormaPagamento > 0">
            Desconto Cartão
            <strong>- {{totalDescontoFormaPagamento | currency:'BRL':'symbol':'1.2-2'}}</strong>
          </div>

          <p class="d-flex space-between pt-2">
            Total: <strong>{{ totalPagar | currency:'BRL':'symbol':'1.2-2'}}</strong>
          </p>
        </div>
      </div>

      <hr>
      <p class="erro mb-0 mr-auto" *ngIf="errorMessage">{{errorMessage}}</p>
      <div class="app-modal-footer">
        <button type="button" class="btn btn-primary" [disabled]="loading['credito']" (click)="pagamentoCredito()">
          <app-loading [loading]="loading['credito']" texto="Pagar" icone="fa fa-dollar"
            textoLoading="Realizando pagamento"></app-loading>
        </button>
        <button type="button" class="btn btn-info" (click)="fechar()" [disabled]="loading['credito']">
          Fechar
        </button>
      </div>
    </div>
  </div>
</app-modal-basic>
