import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalBasicComponent } from '../../../shared/modal-basic/modal-basic.component';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { MessageService } from '../../../services/message.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UnidadesEmpresaService } from '../../../services/unidades-empresa.service';
import { getErrorMessage, markFormGroupTouched } from '../../../general/utils';
import { EdicaoMascaras } from '../../../shared/EdicaoMascaras';
import { ContascorrentesService } from '../../../services/contascorrentes.service';
import swal from 'sweetalert2';
import { AdminLayoutEventBusService } from '../../../services/admin-layout-event-bus.service';
import { fadeInOutTranslate } from '../../../shared/elements/animation';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-contratar-intermediador-recebimento',
  templateUrl: './contratar-intermediador-recebimento.component.html',
  styleUrls: ['./contratar-intermediador-recebimento.component.css'],
  animations: [ fadeInOutTranslate ],
})
export class ContratarIntermediadorRecebimento extends ModalCadastroComponent implements OnInit {
  @ViewChild('modal', { static: true }) modal: ModalBasicComponent;
  form: FormGroup = null;
  edicaoMascaras = EdicaoMascaras;
  possuiIntegracaoSafe2Pay = false;
  passo: 'INTRODUCAO' | 'CONFIGURACAO' = 'INTRODUCAO';

  constructor(
    private messageService: MessageService,
    private unidadesEmpresaService: UnidadesEmpresaService,
    private contasService: ContascorrentesService,
    private adminLayoutEventBusService: AdminLayoutEventBusService,
  ) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      contaCorrenteId: new FormControl(null, [Validators.required]),
      responsavelNome: new FormControl("", [Validators.required]),
      responsavelCpf: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required]),
    });

    this.carregarDados();
  }

  async carregarDados() {
    try {
      this.loading['dados'] = true;

      const contas = await lastValueFrom(this.contasService.get());
      const contaSafe2Pay = contas.find(x => x.intermediadorRecebimentoID === 1);
      if (contaSafe2Pay) {
        this.passo = 'CONFIGURACAO';
        this.possuiIntegracaoSafe2Pay = true;
        this.form.get('contaCorrenteId').setValue(contaSafe2Pay.id);

        this.form.get('responsavelNome').setValidators([]);
        this.form.get('responsavelNome').updateValueAndValidity();
        this.form.get('responsavelCpf').setValidators([]);
        this.form.get('responsavelCpf').updateValueAndValidity();
        this.form.get('email').setValidators([]);
        this.form.get('email').updateValueAndValidity();
      } else {
        const empresa = await lastValueFrom(this.unidadesEmpresaService.getUnidadeEmpresa());
        this.form.get('responsavelNome').setValue(empresa.responsavel);

        const emails = empresa.email.replace(',', ';').split(';');
        const primeiroEmail = emails[0].trim();
        this.form.get('email').setValue(primeiroEmail);
      }
    } catch (err) {
      console.error(err);
      this.messageService.error(getErrorMessage(err), 5000);
    } finally {
      this.loading['dados'] = false;
    }
  }

  show() {
    this.modal.show();
  }

  onClose() {
    this.closed.emit();
  }

  async salvar() {
    markFormGroupTouched(this.form);
    if (!this.form.valid) {
      return;
    }

    try {
      this.loading['salvar'] = true;
      await this.contasService.contratarIntermediadorRecebimento(this.form.getRawValue()).toPromise();
      this.modal.hide();
      this.messageService.success('Boleto Dim ativado com sucesso.');
    } catch (err) {
      console.error(err);
      this.messageService.error(err.error.errors ? err.error.errors[0] : err.msg.errors[0], 5000);
    } finally {
      this.loading['salvar'] = false;
      this.adminLayoutEventBusService.atualizarStatusBoletoDim();
    }
  }

  async desativar() {
    swal({
      text: 'Deseja desativar o Boleto Dim?',
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não',
      allowOutsideClick: () => !swal.isLoading(),
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise((res, rej) => {
          this.contasService.desativarIntermediadorRecebimento()
            .subscribe(res => {
              swal('Sucesso', 'Boleto Dim foi desativado.', 'success');
              this.adminLayoutEventBusService.atualizarStatusBoletoDim();
              this.modal.hide();
            }, (res) => {
              swal('Falha ao desativar', res.error.errors[0], 'error');
            })
        });
      }
    });
  }

  irParaConfiguracao() {
    this.passo = 'CONFIGURACAO';
  }

  mostrar = {};
  mostrarInfo(id) {
    // this.mostrar = {};
    this.mostrar[id] = true;
  }
}
