import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class GrupoDespesasService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  get() {
    return this.http.get(this.UrlService + 'GrupoDespesas')
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  post(form) {
    return this.http.post(this.UrlService + 'GrupoDespesas', form)
      .pipe(map(super.extractData)
        , catchError(super.serviceError))
  }

  getGrupoDespesaById(id: number) {
    return this.http
      .get(this.UrlService + "GrupoDespesas/" + id)
      .pipe(map((res: any) => res.data)
        , catchError(super.serviceError));
  }
}
