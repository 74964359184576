import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TipoServicoISSQN } from '../../../model/TipoServicoISSQN';
import { MessageService } from '../../../services/message.service';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { ItensService } from '../../../services/itens.service';

@Component({
  selector: 'app-cadastro-servico',
  templateUrl: './cadastro-servico.component.html',
  styleUrls: ['./cadastro-servico.component.css']
})
export class CadastroServicoComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  servicosISSQN: Object[] = this.mapTipoDescricao(new TipoServicoISSQN().Listar());

  constructor(
    private messageService: MessageService,
    private itensService: ItensService,
  ) {
    super();
  }

  cadastroForm = new FormGroup({
    id: new FormControl(null),
    nome: new FormControl(null, [Validators.required]),
    descricaoComplementar: new FormControl(null),
    precoSaida: new FormControl(null),
    codigoIssqn: new FormControl(null, [Validators.required]),
  });

  ngOnInit() {
  }

  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    this.cadastroForm.get('nome').setValue(searchParam);
    this.modalCadastro.show();
  }

  async carregaDados() {
    if (!this.id) {
      return;
    }

    try {
      this.alteraLoading(true);
      const item = await this.itensService.getById(this.id).toPromise();
      this.cadastroForm.get('id').setValue(item.id);
      this.cadastroForm.get('nome').setValue(item.nome);
      this.cadastroForm.get('descricaoComplementar').setValue(item.descricaoComplementar);
      this.cadastroForm.get('precoSaida').setValue(item.precoSaida);
      this.cadastroForm.get('codigoIssqn').setValue(item.codigoissqn);
    } catch (err) {
      console.error(err);
      this.messageService.error(err.error.errors ? err.error.errors[0] : err.msg.errors[0], 0, '', true);
    } finally {
      this.alteraLoading(false);
    }
  }

  async submitForm() {
    if (!this.cadastroForm.valid) {
      return;
    }

    try {
      this.alteraLoading(true);
      const res = await this.itensService.salvarServico(this.cadastroForm.getRawValue()).toPromise();
      if (!res || !res.id) {
        this.messageService.error('Falha ao salvar o Serviço', 5000);
        return;
      }

      this.id = res.id;
      this.modalCadastro.hide();
    } catch (err) {
      console.error(err);
      this.messageService.error(err.error.errors ? err.error.errors[0] : err.msg.errors[0], 0, '', true);
    } finally {
      this.alteraLoading(false);
    }
  }

  mapTipoDescricao(situacoes) {
    return situacoes.filter(element => element.tipo >= 9999)
      .map(element => {
        return { id: element.tipo, name: element.tipo + ' - ' + element.descricao };
      });
  }
}
