import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { ContascorrentesService } from '../../../services/contascorrentes.service';
import { BancosService } from '../../../services/bancos.service';
import { setFieldFormValue, setFormValue } from '../../../general/utils';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-conta-corrente',
  templateUrl: './conta-corrente.component.html',
  styleUrls: ['./conta-corrente.component.css']
})
export class ContaCorrenteComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild('modalCadastro', { static: true }) modalCadastro;

  constructor(private contasService: ContascorrentesService, private bancosService: BancosService) {
    super();
  }

  bancos = [];

  cadastroForm = new FormGroup({
    id: new FormControl(null),
    descricao: new FormControl(null, [Validators.required]),
    banco: new FormControl(null),
    agencia: new FormControl(null),
    digitoAgencia: new FormControl(null),
    cedente: new FormControl(null),
    digitoCedente: new FormControl(null)
  });

  ngOnInit() {
    this.carregaBancos();
  }

  carregaBancos() {
    this.loading['bancos'] = true;
    this.bancosService.get()
      .pipe(finalize(() => this.loading['bancos'] = false))
      .subscribe(res => {
        this.bancos = res;
      });
  }


  editar(id: number) {
    this.id = id;
    this.carregaDados();
    this.modalCadastro.show();
  }

  cadastrar(searchParam) {
    this.id = null;
    setFieldFormValue(this.cadastroForm, 'descricao', searchParam);
    this.modalCadastro.show();
  }

  carregaDados() {
    if (this.id) {
      this.alteraLoading(true);
      this.contasService.getContaById(this.id)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe((res) => {
          setFormValue(this.cadastroForm, res);
        });
    }
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      this.alteraLoading(true);

      this.contasService.salvar(this.cadastroForm.getRawValue(), this.id)
        .pipe(finalize(() => this.alteraLoading(false)))
        .subscribe(res => {
          this.id = res.generatedId || null;
          this.modalCadastro.hide();
        }, (error) => {
          this.errors = error.msg.errors;
        });
    }
  }

}
