<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader" modalContentId="modal-cadastro-conta-corrente">
  <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">

    <div class="row">
      <div class="form-group col-sm-12">
        <label>Banco</label>
        <ng-select appendTo="#modal-cadastro-conta-corrente" [disableControl]="loading['registro'] || loading['bancos']"
          [loading]="loading['bancos']" placeholder="Selecione" [items]="bancos" bindLabel="nome" bindValue="id"
          formControlName="banco">
        </ng-select>
      </div>
      <div class="form-group col-sm-12">
        <label class="label-required">Descrição</label>
        <input appAutofocus [disableControl]="loading['registro']" type="text" class="form-control"
          formControlName="descricao" />
        <show-errors [control]="cadastroForm.controls.descricao"></show-errors>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-8 form-group col-8">
        <label>Agência</label>
        <input [disableControl]="loading['registro']" digitOnly class="form-control" formControlName="agencia" />
      </div>

      <div class="col-sm-4 form-group col-4">
        <label>
          Dig. / UA
          <i class="fa fa-info-circle tooltip-fechamento"
            ngbTooltip="Insira o Dígito Verificador da agência, ou a Unidade de Atendimento."
            container="body" [autoClose]="true" triggers="manual" #t="ngbTooltip" (mouseleave)="t.close()"
            (mouseenter)="t.open()" (click)="t.open()"></i>
        </label>
        <input class="form-control" digitOnly formControlName="digitoAgencia" [disableControl]="loading['registro']">
      </div>
    </div>

    <div class="row">
      <div class="col-sm-8 form-group col-8">
        <label>Conta</label>
        <input [disableControl]="loading['registro']" digitOnly class="form-control" formControlName="cedente" />
      </div>

      <div class="col-sm-4 form-group col-4">
        <label>Dig.</label>
        <input class="form-control" digitOnly formControlName="digitoCedente" [disableControl]="loading['registro']">
      </div>
    </div>

    <div class="alert alert-danger" *ngIf="errors">
      <span>{{ errors }}</span>
    </div>

    <button [disabled]='loading["registro"]' type="submit" (click)="validaForm()" class="btn btn-primary ripple light">
      <app-loading [texto]="'OK'" [loading]="loading['registro']"></app-loading>
    </button>
    <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
      (click)="modalCadastro.hide()">Cancelar</button>
  </form>
</app-temp-modal-cadastro>