import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class ObservacoesPadroesService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getAll() {
    return this.http
      .get(this.UrlService + 'ObservacoesPadroes')
      .pipe(map((res: any) => res.data),
      catchError(super.serviceError));
  }

  getObservacaoById(id) {
    return this.http.get(this.UrlService + `ObservacoesPadroes/${id}`)
      .pipe(map(super.extractData),
      catchError(super.serviceError));
  }

  postObservacoes(form) {
    if ((form.id || 0) === 0) {
      return this.http.post(this.UrlService + 'ObservacoesPadroes/', form)
        .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
    }else {
      return this.http.put(this.UrlService + 'ObservacoesPadroes/', form)
        .pipe(map((res: any) => res.data),
        catchError(super.serviceError));
    }
  }

  delete(id: number){
    return this.http.delete(this.UrlService + 'ObservacoesPadroes/' + id)
      .pipe(map((res: any) => res.data),
      catchError(super.serviceError));
  }
}


