<app-temp-modal-cadastro #modalCadastro (closed)="onClose()" [textHeader]="textHeader">
    <form [formGroup]="cadastroForm" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="form-group col-sm-12">
          <label class="label-required">Série Alternativa</label>
          <input appAutofocus [disableControl]="true" type="text" class="form-control" placeholder="Série Alternativa"
            formControlName="serieAlternativa" />
          <show-errors [control]="cadastroForm.controls.serieAlternativa"></show-errors>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-12">
          <label>Nº Última Nota</label>
          <input type="text" class="form-control" digitOnly formControlName="numeroUltimaNF" style="text-align: left;" placeholder="Nº Última Nota">
          <show-errors [control]="cadastroForm.controls.numeroUltimaNF"></show-errors>
        </div>
      </div>


      <button [disabled]='loading["registro"]' type="submit" class="btn btn-primary">
        <app-loading texto="Salvar" [loading]="loading['registro']"></app-loading>
      </button>
      <button [disabled]='loading["registro"]' type="button" class="btn btn-default ripple"
        (click)="modalCadastro.hide()">Cancelar</button>
    </form>
  
  </app-temp-modal-cadastro>