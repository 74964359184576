import { Component, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-email-box',
  templateUrl: './email-box.component.html',
  styleUrls: ['./email-box.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailBoxComponent),
      multi: true
    }
  ]
})
export class EmailBoxComponent implements OnInit, ControlValueAccessor {

  email;
  emailErro = false;
  emails: string[] = [];
  emailRegex = new RegExp('^[a-z0-9](([_.-]?[a-z0-9]+)*)@([a-z0-9]+)(([.-]?[a-z0-9]+)*)([.][a-z0-9]{2,4})$');
  initialEmails = null;
  disabled = false;

  propagateChange = (_: any) => { };
  registerOnTouched() { }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  writeValue(emails: any) {
    this.propagateChange(emails);
  }

  setDefaultEmail(emails){
    if (emails && emails.length !== 0) {
      this.initialEmails = emails.replace(/\s/g, '').split(';').filter(x => x !== '');
    }
  }

  deleteEmail(number: number){
    this.emails.splice(number, 1);
    this.propagateChange(this.emails);
  }
  validaEmail(email){
    email = email.replace(';', '').replace(' ', '');
    if (email.length !== 0 && this.emailRegex.exec(email) && !this.emails.includes(email)) {
      this.emails.push(email);
      this.propagateChange(this.emails);
      (<HTMLInputElement>document.getElementById('email-input')).value = ''
      this.emailErro = false;
    }else if(email == null || email == ''){
      this.emailErro = false;
    }else{
      this.emailErro = true;
    }
  }

  insertEmailString(emails: string) {
    const e = emails.split(';');
    e.forEach(email => {
      this.validaEmail(email);
    });
  }

  addEmail(event){
    let email: string = event.target.value;
    if (['Space', 'Slash', 'Enter'].includes(event.code)) {
      this.validaEmail(email);
    }
    event.preventDefault();
  }

  insertEmail() {
    let email = (document.querySelector("#email-input") as HTMLInputElement).value;
    this.validaEmail(email);
  }

  atualizarEmails(){
    this.propagateChange(this.emails);
  }

  ngOnInit() {
    this.disabled = true;
    setTimeout(() => {
      if(this.initialEmails !== null) {
        this.emails = [...this.emails, ...this.initialEmails];
        this.propagateChange(this.emails);
      }
      this.disabled = false;
    }, 1000);
  }

  finalizaDigitacao(): boolean {
    let email = (document.querySelector("#email-input") as HTMLInputElement).value;
    if (!email) {
      return true;
    }

    this.validaEmail(email);
    return !this.emailErro;
  }
}
