<div class="email-box">
  <div class="email" *ngFor="let email of emails; let number = index;">
    {{email}}
    <button type="button" class="close" aria-label="Close" (click)="deleteEmail(number)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="d-flex">
  <input (keyup)="addEmail($event)" id='email-input' [ngModel]="email" class="form-control" [disabled]="disabled">
  <button class="btn btn-primary" (click)="insertEmail()"><i class="fa fa-plus"></i></button>
</div>
<label *ngIf="emailErro" class="text-danger">E-mail inválido ou já inserido!</label>
<show-errors></show-errors>
