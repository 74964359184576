import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[currencyFocus]',
})
export class CurrencyFocusDirective {
  private hasBeenFocused = false;
  private isSelecting = false;

  constructor(private el: ElementRef) { }

  @HostListener('focus') onFocus() {
    if (!this.hasBeenFocused) {
      this.el.nativeElement.setSelectionRange(3, this.el.nativeElement.value.length);
      this.hasBeenFocused = true;
    }
  }

  @HostListener('blur') onBlur() {
    this.hasBeenFocused = false;
  }

  @HostListener('mousedown') onMouseDown() {
    this.isSelecting = true;
  }

  @HostListener('mouseup') onMouseUp() {
    if (this.isSelecting) {
      this.adjustSelection();
    }
    this.isSelecting = false;
  }

  @HostListener('click') onClick() {
    if (!this.isSelecting) {
      this.el.nativeElement.setSelectionRange(3, this.el.nativeElement.value.length);
    }
  }

  private adjustSelection() {
    const inputElement = this.el.nativeElement;
    const start = inputElement.selectionStart;
    const end = inputElement.selectionEnd;

    if (start < 3 && end > 3) {
      inputElement.setSelectionRange(3, end);
    } else if (start < 3 && end <= 3) {
      inputElement.setSelectionRange(3, 3);
    }
  }
}
