<app-modal-basic #modalRelatorio [modalContentId]="'modal-gerencial'" (closed)="onClose()">
  <div class="app-modal-header">
    <h4 class="modal-title">Relatório gerencial</h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Emissão</label>
          <div class="input-group">
            <app-data [calendarPosition]="'left'" formControlName="periodo1"></app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="periodo2"></app-data>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Ordenar por</label>
          <ng-select appendTo="#modal-gerencial" [items]="criteriosOrdenacao" bindLabel="value" bindValue="id"
            formControlName="ordem">
          </ng-select>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-12" appendTo="#modal-gerencial" rem5 combobox [type]="'cliente'" [(loading)]="loading" [(form)]="form"
          formControlName="clienteID">
        </div>
      </div>
      <div class="row">
        <span class="radio-space-title">Formato</span>
        <div class="form-radio col-sm-12 radio-space">
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="formato" value="pdf">
              <i class="helper"></i>PDF
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="formato" value="xlsx">
              <i class="helper"></i>XLSX
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <span class="radio-space-title">Tipo de nota</span>
        <div class="form-radio col-sm-12 radio-space">
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="imprimirNotasEntrada" [value]="false">
              <i class="helper"></i>Notas de saída
            </label>
          </div>
          <div class="radio radio-inline">
            <label>
              <input type="radio" formControlName="imprimirNotasEntrada" [value]="true">
              <i class="helper"></i>Notas de entrada
            </label>
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="loading['consulta']"><i class="ti ti-new-window"></i>
        Gerar
      </button>
      <button type="button" class="btn btn-danger ripple" (click)="modalRelatorio.hide()"><i class="ti ti-close"></i>
        Cancelar
      </button>
    </form>
  </div>
</app-modal-basic>
