import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class OrdenacaoMenu extends BaseService {

  constructor(private http: HttpClient) { super(); }

  salvarOrdenacao(menuItens, alteracao) {
    return this.http.post(this.UrlService + `OrdenacaoMenu/salvar/${alteracao}`, menuItens)
      .pipe(map(super.extractData)
        , catchError(e => throwError(e)));
  }
}
