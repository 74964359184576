<div (mousedown)="onContainerClicked($event)" class="basic modal fade" tabindex="-1" [ngClass]="{'in': visibleAnimate}"
  [ngStyle]="{'display': visible ? 'block' : 'none', 'opacity': visibleAnimate ? 1 : 0}" style="overflow: visible">
  <div class="modal-dialog" [ngClass]="getClass">
    <div class="modal-content" [ngClass]="getClassContent" [id]="modalContentId">
      <div *ngIf="!hideHeader" class="modal-header">
        <ng-content select=".app-modal-header"></ng-content>
      </div>
      <div class="modal-body" [ngClass]="{'borderless': borderless}" (scroll)="onScroll($event)">
        <ng-content select=".app-modal-body"></ng-content>
      </div>
      <div *ngIf="!hideFooter" class="modal-footer">
        <ng-content select=".app-modal-footer"></ng-content>
      </div>
    </div>
  </div>
</div>
