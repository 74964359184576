import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CodigosBeneficiosFiscaisService } from '../../../services/codigos-beneficios-fiscais.service';
import { TipoMotivoDesoneracao } from '../../../model/tipoMotivoDesoneracao';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-cadastro-fiscal-item-nfce',
  templateUrl: './cadastro-fiscal-item-nfce.component.html',
  styleUrls: ['./cadastro-fiscal-item-nfce.component.css']
})
export class CadastroFiscalItemNfceComponent extends ModalCadastroComponent implements OnInit {

  @ViewChild("modalFiscal", { static: true }) modalFiscal;

  constructor(private codigosBeneficiosFiscaisService: CodigosBeneficiosFiscaisService) { super(); }

  cadastroForm = new FormGroup({
    stVenda: new FormControl(''),
    codigoBeneficioFiscal: new FormControl(''),
    motivoDesoneracaoIcms: new FormControl(0),
    valorIcmsDesonerado: new FormControl(0),
  })

  codigosBeneficios = [];
  motivosDesoneracao = [];

  ngOnInit() {
    this.carregaCodigoBeneficios(this.prop.stVenda);
  }

  carregaCodigoBeneficios(stVenda: string) {
    this.loading['codigosBeneficios'] = true;
    this.codigosBeneficiosFiscaisService.getBeneficiosBySt(stVenda)
      .pipe(finalize(() => this.loading['codigosBeneficios'] = false))
      .subscribe(res => {
        this.codigosBeneficios = res;
      })
  }

  show() {
    this.cadastroForm.get('codigoBeneficioFiscal').setValue(this.prop.codigoBeneficioFiscal);
    this.cadastroForm.get('stVenda').setValue(this.prop.stVenda);

    if (this.prop.motivoDesoneracaoIcms) {
      this.cadastroForm.addControl('motivoDesoneracaoIcms', new FormControl(this.prop.motivoDesoneracaoIcms));
      this.cadastroForm.addControl('valorIcmsDesonerado', new FormControl(this.prop.valorIcmsDesonerado, [Validators.required]));
      this.motivosDesoneracao = TipoMotivoDesoneracao.listar(this.prop.stVenda);
    }

    this.modalFiscal.show();
  }

  submitForm() {
    if (this.cadastroForm.valid) {
      this.closed.emit(this.cadastroForm.value);
    }
  }
}
