import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-ajuste-numerico',
  templateUrl: './ajuste-numerico.component.html',
  styleUrls: ['./ajuste-numerico.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AjusteNumericoComponent),
      multi: true,
    },
  ],
})
export class AjusteNumericoComponent implements ControlValueAccessor, OnInit {
  @Input() form: FormGroup;
  @Input() formControlName: string;
  @Input() disabled = false;
  @Input() qtdSum = [1, 5, 10];
  @Input() qtdSub = [1, 5, 10];
  value: any;

  constructor() { }

  ngOnInit() { }

  get qtdTotal() {
    return this.form.get(this.formControlName).value;
  }

  sum(qtd: number) {
    this.form.get(this.formControlName).setValue(this.qtdTotal + qtd);
  }

  sub(qtd: number) {
    this.form.get(this.formControlName).setValue(this.qtdTotal - qtd);
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  onInputChange(event: any): void {
    this.onChange(event.target.value);
    this.onTouch();
  }

  private onChange: (value: any) => void;
  private onTouch: () => void;
}
