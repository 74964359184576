import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable()
export class PaisesService extends BaseService {

  constructor(private http: HttpClient) { super(); }

  getPaises() {
    return this.http.get(this.UrlService + 'paises').pipe(map(super.extractData)
      , catchError(super.serviceError));
  }
}
