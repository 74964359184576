import { Component, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ModalCadastroComponent } from '../../../shared/modal-cadastro/modal-cadastro.component';
import { FormControl, FormGroup } from '@angular/forms';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { MessageService } from '../../../services/message.service';
import { NotasFiscaisService } from '../../../services/notas-fiscais.service';
import { dyanmicDownloadByHtmlTag } from '../../../general/utils';
import { UnidadesEmpresaService } from '../../../services/unidades-empresa.service';
import { EstadosService } from '../../../services/estados.service';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-importar-nfe',
  templateUrl: './importar-nfe.component.html',
  styleUrls: ['./importar-nfe.component.css']
})
export class ImportarNfeComponent extends ModalCadastroComponent implements OnInit {
  @ViewChild('modalCadastro', { static: true }) modalCadastro;
  @ViewChild('abrirModal', { static: true }) abrirModal;

  constructor(
    private messageService: MessageService,
    private nfeService: NotasFiscaisService,
    private unidadeEmpresaService: UnidadesEmpresaService,
    private estadoService: EstadosService) {
    super();
  }
  maximoArquivos = 100;
  semCertificado = false;
  entradaSaida = 'S';
  certificadoVencido = false;
  modalSubscription: Subscription;
  loading = false;
  private filesControl = new FormControl(null,
    [
      FileUploadValidators.filesLimit(this.maximoArquivos),
      FileUploadValidators.accept(['.xml'])
    ]);

  public cadastroForm = new FormGroup({
    files: this.filesControl,
    cadastrarItensCadProduto: new FormControl(false),
  });

  ngOnInit() {
  }

  show(entradaSaida: string) {
    this.entradaSaida = entradaSaida;
    if (entradaSaida == 'R') {
      this.maximoArquivos = 1;
    }

    this.modalCadastro.show();
    this.verificaCertificadoDigital();
  }

  async importarNotas() {
    this.validaForm();
    const arquivos = this.cadastroForm.get('files');

    if (this.hasErrors(arquivos)) {
      return;
    }

    const envios = [];
    this.messageService.wait(`Importando ${arquivos.value.length == 1 ? 'nota fiscal!' : 'notas fiscais!'}`, 9999 * 100);
    this.loading = true;
    for (const file of arquivos.value) {
      const input = new FormData();
      input.append(file.name, file);
      input.append('entradaSaida', this.entradaSaida);
      input.append('cadastrarItensNoCadastroDeProdutos', this.cadastroForm.value.cadastrarItensCadProduto ? "true" : "false");
      try {
        const res = await this.nfeService.importarXmlNfe(input).toPromise();
        envios.push(res);
      } catch (err) {
        envios.push(err.error.errors ? err.error.errors[0] : err.msg.errors[0]);
      }
    }
    this.loading = false;
    this.montarLogResposta(envios);
    this.modalCadastro.hide();
  }

  hasErrors(arquivos): boolean {
    if (!arquivos.value) {
      this.messageService.error('Selecione pelo menos 1 arquivo!', 5000);
      return true;
    }

    if (arquivos.errors) {
      if (arquivos.errors.fileTypes) {
        this.messageService.error('Tipo de arquivo inválido!', 5000, '', false);
      } else if (arquivos.errors.filesLimit) {
        this.messageService.error('O máximo de arquivos é ' + this.maximoArquivos + '!', 5000, '', false);
      }
      return true;
    }
    return false;
  }

  montarLogResposta(value) {
    if (this.entradaSaida == 'E') {
      this.messageService.clearAll();
      this.messageService.info(value, 10000, '');
    }
    else {
      let string = '';

      value.forEach(element => {
        if (string.length > 0) { string += '\n'; }
        string += element;
      });

      dyanmicDownloadByHtmlTag({
        fileName: 'Log importação ' + value.length + ' Notas Fiscais.txt',
        text: string
      });
      this.messageService.clearAll();
      this.messageService.success('Importação concluída, confira o log de importação!', 5000);
    }
  }

  arquivosImportados() {
    const quantidade = this.cadastroForm.get('files').value === null ? 0 : this.cadastroForm.get('files').value.length;
    return quantidade + ' / ' + this.maximoArquivos;
  }

  verificaCertificadoDigital() {
    this.unidadeEmpresaService.getCertificado()
      .pipe(finalize(() => this.alteraLoading(false)))
      .subscribe(res => {
        this.semCertificado = res == null ? true : false;
        if (res) {
          const dataValidade = new Date(res.validade);
          this.estadoService.getDateFuso().toPromise()
            .then(dataServidor => {
              if (dataValidade < new Date(dataServidor)) {
                this.certificadoVencido = true;
              }
            });
        }
      });
  }

  abrirCertificadoDigital() {
    this.modalSubscription = this.abrirModal.closed.subscribe(this.verificaCertificadoDigital.bind(this));
    this.abrirModal.abrir('certificadoDigital');
  }
}
