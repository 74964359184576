export class TipoSituacaoTributariaPIS {
    tipo: string;
    descricao: string;

    constructor(tipo?: string, descricao?: string) {
        this.tipo = tipo;
        this.descricao = descricao;
    }

    List(): TipoSituacaoTributariaPIS[] {
        return [
            new TipoSituacaoTributariaPIS("01", "PIS 01 - Operação Tributável com Alíquota Básica"),
            new TipoSituacaoTributariaPIS("02", "PIS 02 - Operação Tributável com Alíquota Diferenciada"),
            new TipoSituacaoTributariaPIS("03", "PIS 03 - Operação Tributável com Alíquota por Unidade de Medida de Produto"),
            new TipoSituacaoTributariaPIS("04", "PIS 04 - Operação Tributável Monofásica - Revenda a Alíquota Zero"),
            new TipoSituacaoTributariaPIS("05", "PIS 05 - Operação Tributável por Substituição Tributária"),
            new TipoSituacaoTributariaPIS("06", "PIS 06 - Operação Tributável a Alíquota Zero"),
            new TipoSituacaoTributariaPIS("07", "PIS 07 - Operação Isenta da Contribuição"),
            new TipoSituacaoTributariaPIS("08", "PIS 08 - Operação sem Incidência da Contribuição"),
            new TipoSituacaoTributariaPIS("09", "PIS 09 - Operação com Suspensão da Contribuição"),
            new TipoSituacaoTributariaPIS("49", "PIS 49 - Outras Operações de Saída"),
            new TipoSituacaoTributariaPIS("50", "PIS 50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno"),
            new TipoSituacaoTributariaPIS("51", "PIS 51 - Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno"),
            new TipoSituacaoTributariaPIS("52", "PIS 52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação"),
            new TipoSituacaoTributariaPIS("53", "PIS 53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"),
            new TipoSituacaoTributariaPIS("54", "PIS 54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"),
            new TipoSituacaoTributariaPIS("55", "PIS 55 - Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"),
            new TipoSituacaoTributariaPIS("56", "PIS 56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação"),
            new TipoSituacaoTributariaPIS("60", "PIS 60 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Tributada no Mercado Interno"),
            new TipoSituacaoTributariaPIS("61", "PIS 61 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita Não-Tributada no Mercado Interno"),
            new TipoSituacaoTributariaPIS("62", "PIS 62 - Crédito Presumido - Operação de Aquisição Vinculada Exclusivamente a Receita de Exportação"),
            new TipoSituacaoTributariaPIS("63", "PIS 63 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno"),
            new TipoSituacaoTributariaPIS("64", "PIS 64 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas no Mercado Interno e de Exportação"),
            new TipoSituacaoTributariaPIS("65", "PIS 65 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação"),
            new TipoSituacaoTributariaPIS("66", "PIS 66 - Crédito Presumido - Operação de Aquisição Vinculada a Receitas Tributadas e Não- Tributadas no Mercado Interno, e de Exportação"),
            new TipoSituacaoTributariaPIS("67", "PIS 67 - Crédito Presumido - Outras Operações"),
            new TipoSituacaoTributariaPIS("70", "PIS 70 - Operação de Aquisição sem Direito a Crédito"),
            new TipoSituacaoTributariaPIS("71", "PIS 71 - Operação de Aquisição com Isenção"),
            new TipoSituacaoTributariaPIS("72", "PIS 72 - Operação de Aquisição com Suspensão"),
            new TipoSituacaoTributariaPIS("73", "PIS 73 - Operação de Aquisição a Alíquota Zero"),
            new TipoSituacaoTributariaPIS("74", "PIS 74 - Operação de Aquisição sem Incidência da Contribuição"),
            new TipoSituacaoTributariaPIS("75", "PIS 75 - Operação de Aquisição por Substituição Tributária"),
            new TipoSituacaoTributariaPIS("98", "PIS 98 - Outras Operações de Entrada"),
            new TipoSituacaoTributariaPIS("99", "PIS 99 - Outras Operações")
        ];
    }

}