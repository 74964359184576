<app-modal-basic #modalRelatorio [modalContentId]="'modal-rv'" (closed)="close()">
  <div class="app-modal-header">
    <h4 class="modal-title text-center">Relação de Notas Fiscais de Vendas por Cliente</h4>
  </div>
  <div class="app-modal-body">
    <form [formGroup]="form" (ngSubmit)="imprimir()">
      <div class="row">
        <div class="form-group col-sm-12">
          <label>Emissão</label>
          <div class="input-group">
            <app-data [calendarPosition]="'left'" formControlName="emissao1"></app-data>
            <span class="input-group-addon">até</span>
            <app-data formControlName="emissao2"></app-data>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-sm-6 check-form">
          <div class="checkbox-fade fade-in-default">
            <label>
              <input type="checkbox" formControlName="fabricacaoPropria" >
              <span class="cr">
                <i class="cr-icon icofont icofont-ui-check txt-default"></i>
              </span>
              <span>Itens de Fabricação Própria</span>
            </label>
          </div>
        </div>
      </div>

      <div class="alert alert-danger background-danger" *ngIf="errorMsg">
        <span>{{ errorMsg }}</span>
      </div>

      <button [disabled]="loading" type="submit" class="btn btn-primary ripple light">
        <app-loading
          [loading]="loading"
          texto="Imprimir"
          textoLoading="Aguarde"
          [icone]="'icofont icofont-print'"
        ></app-loading>
      </button>
      <button class="btn btn-danger ripple" (click)="modalRelatorio.hide()">
        <i class="ti ti-close"></i> Cancelar
      </button>
    </form>
  </div>
</app-modal-basic>
