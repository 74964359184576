<app-modal-basic #modal transparent [dialogClass]="'modal-lg'" (closed)="clicouFora()" [ngClass]="getOriantation()">
  <div class="app-modal-header" [ngClass]="getOriantation()">
    <img [src]="listaVideos[prop.video].image" class="helpImage" [ngClass]="getOriantation()">
  </div>
  <div class="app-modal-body">
    <button type="button" class="close hideMobile" data-dismiss="modal" aria-label="Close" [ngClass]="getDevice()">
      <span aria-hidden="true" (click)="hide()">×</span>
    </button>

    <div style="float: left; margin-right: 30px;" class="hideMobile" [ngClass]="getDevice()">
      <img [src]="listaVideos[prop.video].image" class="helpImage">
    </div>
    <div class="embed-responsive" [ngClass]="getOriantation()">
      <div id="player" class="embed-responsive-item"></div>
    </div>
  </div>
  <div class="app-modal-footer">
    <div class="checkbox-fade fade-in-default pull-right" *ngIf="!ocultarExibirNovamente">
      <label class="removeMargin">
        <input type="checkbox" [(ngModel)]="naoExibirNovamente">
        <span class="cr" style="margin-right: 3px; margin-top: 3px; height: 15px; width: 15px; border-color: #aeaeae;">
          <i class="cr-icon icofont icofont-ui-check txt-default" style="font-size: 8px; color: #c5c5c5;"></i>
        </span>
        <span style="font-size: 12px; color: #dedede;">Não Exibir Novamente</span>
      </label>
    </div>
  </div>
</app-modal-basic>