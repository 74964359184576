<div class="container">
  <div class="row">
    <div class="col-md-12">
      <div class="error-template">
        <h1>
          Oops! Erro 404
        </h1>
        <div class="error-details">
          A página que você tentou acessar não existe ou foi removida.
        </div>
        <div class="error-actions">
          <a [routerLink]="['/']" class="btn btn-primary btn-lg">
            <span class="glyphicon glyphicon-home"></span>
            Voltar </a>
        </div>
      </div>
    </div>
  </div>
</div>